import Grid from "@mui/material/Grid";
import { Item } from "../../styles/app-styles";
import { Delete } from "../icons";
import Stack from "@mui/material/Stack";
import { IdOption } from "../forms/types";
import { useContext, useEffect } from "react";
import SchemasProvider from "../../context/SchemasContext";
import { validateDeleteRow } from "./utils";
interface SchemaRowProps {
  row: any;
}

const SchemaRow = ({ row }: SchemaRowProps) => {
  const { schemaState, schemaDispatch } =
    useContext<React.ContextType<typeof SchemasProvider>>(SchemasProvider);
  const { schema } = schemaState;

  const handleDeleteFilter = () => {
    validateDeleteRow(schema, row, schemaDispatch, schemaState);
    schemaDispatch({
      type: "SET_SCHEMA",
      payload: schema.filter((schemaRow: any) => {
        return (
          schemaRow.pipeline.value !== row.pipeline.value &&
          schemaRow.pipeline.label !== row.pipeline.label
        );
      }),
    });
  };


  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Item  sx={{color: "rgba(0, 0, 0, 0.6) !important"}}>{row.pipeline.label}</Item>
      </Grid>
      <Grid item xs={4.5}>
        <Item sx={{color: row.sheets.some((sheet: any)=> sheet.value === null) ?"var(--red)" :"rgba(0, 0, 0, 0.6) !important"}}>
          {row &&
            row.sheets &&
            row.sheets
              .map(
                (sheet: IdOption, index: number) =>
                  `${sheet.label}${index !== row.sheets.length - 1 ? ", " : ""}`
              )
              .filter((sheet: string) => sheet !== ", ")}
          {(row.sheets === undefined || row.sheets.length === 0) && "Todas"}
        </Item>
      </Grid>
      <Grid item xs={2}>
        <Item sx={{color: "rgba(0, 0, 0, 0.6) !important"}}>{row.filterType.label}</Item>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default SchemaRow;
