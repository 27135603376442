import {  Stack, Typography } from "@mui/material";
import { CONFIGURATIONS_BY_SCHEMA_URL, SCHEMA_CONFIGURATION_BY_ID } from "../../api/axios";
import useApi from "../../hooks/useApi";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Dialog from "../dialog/Dialog";
import { Button } from "../buttons";
import Config from "../flowConfiguration/Modals/CalculationConfigs/Config";

interface CalculationConfigsProps {
  openConfigsModal: boolean;
  handleClose: any;
  dataConfigs: any;
  refetchConfigs: any;
  schemaSelectedOption: any;
}

const CalculationConfigs = ({
  openConfigsModal,
  refetchConfigs,
  dataConfigs,
  handleClose,
  schemaSelectedOption,
}: CalculationConfigsProps) => {
  /********************************* LLAMADAS ************************************************/

  const { callApi: deleteConfig, isLoading: isLoadingDeleteConfig } = useApi(
    SCHEMA_CONFIGURATION_BY_ID,
    "DELETE",
    texts.flows.deleteFlowConfigs.codes,
    undefined,
    () => refetchConfigs( CONFIGURATIONS_BY_SCHEMA_URL(schemaSelectedOption?.value as number)),
    undefined,
    true
  );

  /********************************* HANDLERS ************************************************/

  const handleDelete = (id: number) => {
    deleteConfig(
      SCHEMA_CONFIGURATION_BY_ID(
        schemaSelectedOption?.value as number,
        id
      )
    );
  };

  return (
      <Dialog
        open={openConfigsModal}
        handleClose={handleClose}
        title={"Gestionar configuraciones"}
        maxWidth="xl"
      >
        <SimpleBackdrop
          open={isLoadingDeleteConfig}
          message={texts.flows.deleteFlowConfigs.loading}
        />

        <Stack
          sx={{
            width: "800px",
            minHeight: "350px",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", width: "100%", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "19px",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Configuraciones guardadas
            </Typography>
          </Stack>
          {dataConfigs !== undefined &&
            dataConfigs?.length > 0 &&
            dataConfigs?.map((config: any) => {
              return (
                <Config
                  key={config.id}
                  config={config}
                  handleDelete={handleDelete}
                  isSchemeConfig={true}
                />
              );
            })}
          {dataConfigs !== undefined && dataConfigs?.length === 0 && (
            <Typography
              sx={{
                fontWeight: "bold",
                color: "var(--dark-grey)",
                margin: "auto",
                fontSize: "20px",
              }}
            >
              Aún no hay configuraciones
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
        </Stack>
      </Dialog>
  );
};

export default CalculationConfigs;
