import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import useDialog from "../../hooks/useDialog";
import { useContext, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import {
  FILE_BY_ID,
  FILE_INFO_URL,
  GET_OR_EDIT_SCHEMA_URL,
  GET_PIPELINES_URL,
  SCHEMAS_URL,
} from "../../api/axios";
import { texts } from "../../texts";
import SchemasProvider from "../../context/SchemasContext";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { TextField, Typography } from "@mui/material";
import { formatOptions } from "../../utils/util";
import SaveNameModal from "../dialog/SaveNameModal";
import ComponentLayout from "../layout/ComponentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useApiQuery } from "../../hooks/useApiQuery";
import SchemaRowTitles from "./SchemaRowTitles";
import SchemaRowSelects from "./SchemaRowSelects";
import SchemaRow from "./SchemaRow";
import InfoGrid from "../basicGrid/InfoGrid";
import {
  formatSchemaRow,
  isValidName,
  setSchemaSelects,
  validateSchemaRow,
} from "./utils";
import { ConfirmationModal } from "../dialog";
import Dialog from "../dialog/Dialog";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";

const ConfigSchemas = () => {
  const navigate = useNavigate();
  const [openConfirmCreateSchemaModal, toggleConfirmCreateSchemaModal] =
    useDialog();
  const { schemaState, schemaDispatch } =
    useContext<React.ContextType<typeof SchemasProvider>>(SchemasProvider);
  const schemaId = localStorage.getItem("schemaId")
    ? localStorage.getItem("schemaId")
    : undefined;
  const { schemaName, schema, message, file, pipelineSelectedOption } = schemaState;
  const [localFile, setLocalFile] = useState<any>("");
  const [schemaEditName, setSchemaEditName] = useState<string>("");
  const [dataFileState, setDataFileState] = useState<File[] | null>([]);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [openNonExistentSheetsModal, handleToggleOpenNonExistentSheetsModal] =
    useDialog();
  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>("");
  const [replaceFile, setReplaceFile] = useState<any>(false);

  const { data: dataSchemas } = useApiQuery(
    SCHEMAS_URL,
    true,
    texts.adminSchemas.getSchemas.error
  );

  /*************************** CARGA DE DATOS ***************************/

  /* Si en el localStorage hay un esquema, es decir si estamos en modo EDIT, hacemos el get del esquema*/
  useEffect(() => {
    if (schemaId) {
      getSchema(GET_OR_EDIT_SCHEMA_URL(parseInt(schemaId)));
    }
  }, [schemaId]);

  useEffect(() => {
    getPipelines();
  }, []);

  useEffect(() => {
  if(pipelineSelectedOption?.value && !schemaToEdit){
    const pipelineInfo = dataPipelines.find((pipeline: any)=>{return pipeline.id === pipelineSelectedOption.value})
    if(pipelineInfo.archivo?.url){
      setSelectedFile(
        pipelineInfo.archivo.url.substring(
          pipelineInfo.archivo.url.lastIndexOf("/") + 1
        )
      );
      setSelectedFileFolder(pipelineInfo?.archivo?.url.substring(
        0,
        pipelineInfo?.archivo?.url.lastIndexOf("/")
      ))
    }
    if(pipelineInfo.archivo?.id){
      getFilesInfoById(FILE_BY_ID(pipelineInfo.archivo?.id));
    }
  }
  }, [pipelineSelectedOption?.value]);



  /*************************** LLAMADAS ***************************/

  const onSuccessGetSchema = (data: any) => {
    setSchemaEditName(data.nombre);
    const rows = data.esquema_criterios.map((row: any) => {
      return formatSchemaRow(
        { value: row.criterio_id, label: row.criterio_nombre },
        row.hojas,
        row.flag_incluir
          ? { value: 1, label: "INCLUIR" }
          : { value: 2, label: "EXCLUIR" }
      );
    });
    if (
      data.esquema_criterios.some((sheet: any) =>
        sheet.hojas.find((hoja: any) => hoja.value === null)
      )
    ) {
      schemaDispatch({
        type: "SET_MESSAGE",
        payload: texts.adminSchemas.addSchemaRow.deletedSheets,
      });
    }
    schemaDispatch({
      type: "SET_SCHEMA",
      payload: rows,
    });
    schemaDispatch({
      type: "SET_SCHEMA_NAME",
      payload: data.nombre,
    });
    data.archivo.url && setLocalFile(data.archivo.url);
    schemaDispatch({
      type: "SET_FILE_DATA",
      payload: {
        url: data.archivo.url,
        id: data.archivo.id,
      },
    });
    schemaDispatch({
      type: "SET_SHEETS_LIST",
      payload: data.hojas,
    });
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: data.hojas,
    });
  };

  const {
    isLoading: isLoadingSchema,
    callApi: getSchema,
    data: schemaToEdit,
  } = useApi(
    undefined,
    "GET",
    texts.adminSchemas.getSchema.codes,
    undefined,
    onSuccessGetSchema,
    undefined,
    false
  );

  const onSuccessGetPipelines = (data: any) => {
    schemaDispatch({
      type: "SET_PIPELINES_REST_LIST",
      payload: formatOptions(data),
    });
    schemaDispatch({
      type: "SET_ALL_PIPELINES",
      payload: formatOptions(data),
    });
  };

  const { data: dataPipelines, callApi: getPipelines } = useApi(
    GET_PIPELINES_URL,
    "GET",
    texts.adminPipeline.getPipelines.codes,
    undefined,
    onSuccessGetPipelines,
    undefined,
    false
  );

  useEffect(() => {
    if (
      dataPipelines?.length > 0 &&
      schemaToEdit?.esquema_criterios.length > 0
    ) {
      setSchemaSelects(
        schemaToEdit.esquema_criterios,
        schemaDispatch,
        schemaState
      );
    }
  }, [schemaToEdit, dataPipelines]);

  const onSuccessGetFileInfo = (data: any) => {
    if (schemaToEdit) {
      const rows = schemaToEdit.esquema_criterios.map((row: any) => {
        return validateSchemaRow(
          { value: row.criterio_id, label: row.criterio_nombre },
          row.hojas,
          row.flag_incluir
            ? { value: 1, label: "INCLUIR" }
            : { value: 2, label: "EXCLUIR" },
          data.hojas
        );
      });

      schemaDispatch({
        type: "SET_SCHEMA",
        payload: rows,
      });
    } else {
      handleCleanSchema();
    }
    schemaDispatch({
      type: "SET_FILE_DATA",
      payload: {
        name: data.nombre,
        id: data.id,
      },
    });
    schemaDispatch({
      type: "SET_SHEETS_LIST",
      payload: data.hojas.map((h: any) => {
        return { value: h.id, label: h.nombre };
      }),
    });
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: data.hojas.map((h: any) => {
        return { value: h.id, label: h.nombre };
      }),
    });
    setDataFileState([data]);
  };

  const onErrorGetFileInfo = (data: any) => {
    //handleCleanSchema();
    schemaDispatch({
      type: "SET_FILE_DATA",
      payload: {
        name: "",
        id: null,
      },
    });
    schemaDispatch({
      type: "SET_SHEETS_LIST",
      payload: [],
    });
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: [],
    });
  };

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: selectedFileFolder,
      directorio: false,
      tipo: "azure",
      actualizar_archivo: true,
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );

  const onSuccessSendSchema = () => {
    toggleConfirmCreateSchemaModal();
    navigate("/consolidacion-archivos/administracion-esquemas");
  };

  const onSuccessEditSchema = () => {
    localStorage.removeItem("schemaId");
    navigate("/consolidacion-archivos/administracion-esquemas");
  };

  const infoToSend = () => {
    return {
      nombre: schemaId ? schemaEditName : schemaName,
      archivo: file.id,
      esquema_criterios: schema.map((item: any) => {
        return {
          criterio: item?.pipeline?.value,
          flag_incluir: item?.filterType?.label === "INCLUIR" ? true : false,
          hojas: item?.sheets
            ? item?.sheets.map((sheet: any) => sheet.value)
            : [],
        };
      }),
    };
  };

  const { isLoading: isLoadingPostSchema, callApi: sendSchema } = useApi(
    SCHEMAS_URL,
    "POST",
    texts.adminSchemas.sendSchema.codes,
    infoToSend(),
    onSuccessSendSchema,
    undefined,
    true
  );

  const confirmSendSchema = () => {
    sendSchema();
  };

  const { isLoading: isLoadingPutSchema, callApi: editSchema } = useApi(
    GET_OR_EDIT_SCHEMA_URL(schemaId),
    "PUT",
    texts.adminSchemas.sendSchema.codes,
    infoToSend(),
    onSuccessEditSchema,
    undefined,
    true
  );

  /*************************** HANLDERS ***************************/

  // Se ejecuta sólo en caso de Edit del esquema
  const handleAcceptConfirmationModal = () => {
    schemaId && editSchema(GET_OR_EDIT_SCHEMA_URL(parseInt(schemaId)));
    toggleConfirmationModal();
  };

  const handleEditSchema = () => {
    if (dataFile && dataFile.hojas) {
      if (checkIfSheetsExistsInFile().length === 0) {
        toggleConfirmationModal();
      } else {
        handleToggleOpenNonExistentSheetsModal();
      }
    } else {
      toggleConfirmationModal();
    }
  };

  //Esta función chequea si hay alguna hoja seleccionada en el esquema que no exista en el archivo ingresado (en el caso de que el esquema haya sido creado previamente)
  const checkIfSheetsExistsInFile = () => {
    const sheets = dataFile.hojas.map((hoja: any) => hoja.nombre.toLowerCase());

    const schemaSheets = schema.flatMap((item: any) =>
      item.sheets.map((sheet: any) => sheet.label.toLowerCase())
    );

    const nonExistentSheets = schemaSheets.filter(
      (sheet: any) => !sheets.includes(sheet)
    );

    return nonExistentSheets;
  };

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile(fileState);
    // handleCleanSchema();
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo(undefined, {
              path_archivo:
                clickedFile === "chooseFile"
                  ? selectedFile
                  : `${selectedFileFolder}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              actualizar_archivo: true,
            });
          } else {
            getFilesInfoById(FILE_BY_ID(clickedFile.id));
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const handleReplaceFile = () => {
    setReplaceFile(true);
    setSelectedFile(
      schemaToEdit.archivo.url.substring(
        schemaToEdit.archivo.url.lastIndexOf("/") + 1
      )
    );
    setSelectedFileFolder(schemaToEdit?.archivo?.url.substring(
      0,
      schemaToEdit?.archivo?.url.lastIndexOf("/")
    ))
  };

  const handleCleanSchema = () => {
    schemaDispatch({
      type: "SET_SCHEMA",
      payload: [],
    });
    onSuccessGetPipelines(dataPipelines);
  };

  return (
    <ComponentLayout
      title="Configuración de esquemas"
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={() => {
            navigate("/consolidacion-archivos/administracion-esquemas");
            localStorage.removeItem("schemaId");
          }}
        />
      }
    >
      <Stack width="100%" height="80vh" alignItems="center">
        <SimpleBackdrop
          open={isLoadingFileInfo || isLoadingFileInfoById}
          message="Cargando archivo..."
        />
        <SimpleBackdrop
          open={isLoadingSchema}
          message={texts.adminSchemas.getSchema.loading}
        />
        <SimpleBackdrop
          open={isLoadingPostSchema}
          message={texts.adminSchemas.sendSchema.loading}
        />
        <Stack width="88%" alignItems={"center"} gap={2}>
          {schemaId && (
            <Stack width="93%" alignItems={"center"}>
              <TextField
                id="filled-textarea"
                label={"Nombre del esquema"}
                placeholder={"Nombre del esquema"}
                size="small"
                multiline
                variant="outlined"
                helperText={
                  !isValidName(dataSchemas, schemaEditName, true, schemaName)
                    ? "Ya existe un esquema con este nombre"
                    : ""
                }
                error={
                  schemaEditName === "" ||
                  !isValidName(dataSchemas, schemaEditName, true, schemaName)
                }
                fullWidth
                value={schemaEditName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSchemaEditName(event.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </Stack>
          )}
          {schemaId && (
            <Stack width="91.5%" alignItems={"center"} direction={"row"}>
              <TextField
                value={localFile}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLocalFile(event.target.value);
                }}
                label={"Url del archivo"}
                placeholder={"Url del archivo"}
                disabled={true}
                size="small"
                multiline
                variant="outlined"
                fullWidth
              />
              <Button
                title="Reemplazar archivo"
                color="blue"
                type="button"
                onClick={handleReplaceFile}
              />
            </Stack>
          )}
          {replaceFile && (
            <Stack
              width="90%"
              height="80vh"
              alignItems="center"
              marginBottom="15px"
            >
              <UploadFileContent
                areMultipleFiles={false}
                selectedFile={selectedFile}
                setSelectedFile={handleSelectFile}
                dataFileState={dataFileState}
                setDataFileState={setDataFileState}
                setSelectedFileFolder={setSelectedFileFolder}
                onSuccessChargeLocalFiles={onSuccessGetFileInfo}
                customInitialRoute={selectedFileFolder}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            marginTop: "15px",
            marginBottom: "50px",
            width: "90%",
            alignItems: "center",
          }}
        >
          <Stack width="91.5%" height="20px" alignItems={"center"}>
            <Typography
              variant="subtitle1"
              color="error"
              sx={{ fontWeight: "bold" }}
            >
              {message}
            </Typography>
          </Stack>
          {
            /*((dataFileState && dataFileState?.length > 0) || schemaToEdit) &&*/ <InfoGrid
              info={schema}
              RowTitles={SchemaRowTitles}
              RowSelects={SchemaRowSelects}
              RowInfo={SchemaRow}
            />
          }
        </Stack>

        {!schemaId && (
          <Stack
            width="90%"
            height="80vh"
            alignItems="center"
            marginBottom="15px"
          >
            {file?.id ? <Typography sx={{marginRight:"auto", color:"var(--blue)", fontWeight:"bold"}}>
            Se muestra el archivo utilizado en el pipeline seleccionado
          </Typography> : ""}
          
            <UploadFileContent
              areMultipleFiles={false}
              selectedFile={selectedFile}
              setSelectedFile={handleSelectFile}
              dataFileState={dataFileState}
              setDataFileState={setDataFileState}
              setSelectedFileFolder={setSelectedFileFolder}
              onSuccessChargeLocalFiles={onSuccessGetFileInfo}
              customInitialRoute={selectedFileFolder}

            />
          </Stack>
        )}

        <SaveNameModal
          open={openConfirmCreateSchemaModal}
          handleClose={toggleConfirmCreateSchemaModal}
          handleAccept={confirmSendSchema}
          title={"Guardar esquema"}
          text={"¿Confirma que desea guardar el esquema?"}
          label={"Ingrese el nombre del esquema"}
          placeholder={"Escribir nombre del esquema"}
          dispatchFunction={schemaDispatch}
          dispatchType={"SET_SCHEMA_NAME"}
          valueState={schemaName}
          namesList={dataSchemas}
        />
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={handleAcceptConfirmationModal}
          message="editar el esquema"
          title="Confirmar"
        />
        <Stack direction="row" justifyContent={"center"}>
          <Button
            title={schemaToEdit ? "Guardar esquema" : "Crear esquema"}
            color="blue"
            type="button"
            onClick={
              schemaToEdit ? handleEditSchema : toggleConfirmCreateSchemaModal
            }
            disabled={
              !schema ||
              schema.length === 0 ||
              schema?.some((row: any) =>
                row.sheets.find((hoja: any) => hoja.value === null)
              ) ||
              (schemaId !== undefined && schemaEditName.length === 0) ||
              (schemaId !== undefined &&
                !isValidName(dataSchemas, schemaEditName, true, schemaName))
            }
          />
        </Stack>
      </Stack>
      <Dialog
        open={openNonExistentSheetsModal}
        handleClose={handleToggleOpenNonExistentSheetsModal}
        title={"No es posible crear el esquema"}
      >
        <Stack sx={{ width: "450px", alignItems: "center" }}>
          <Typography sx={{ color: "#172D40", width: "85%", fontSize: "18px" }}>
            No es posible guardar el esquema porque algunas de las hojas
            seleccionadas no existen en el archivo que está siendo utilizado. Es
            necesario que sean eliminadas antes de guardar el esquema
          </Typography>
        </Stack>
      </Dialog>
    </ComponentLayout>
  );
};

export default ConfigSchemas;
