import React, { useContext, useEffect, useRef } from "react";
import useSingleSelect from "../../hooks/useSingleSelect";
import SummaryProvider from "../../context/SummaryContext";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DeleteForever from "../icons/DeleteForever";
import Select from "../forms/Select";
import { selectMultiStyles, selectStyles } from "../../styles/select.styles";
import AddButton from "../buttons/AddButton";
import { CATEGORIC_FUNCTIONS, NUMERIC_FUNCTIONS } from "./constants";
import {
  findTypeByColumn,
  formatRow, 
  validateSummaryRow,
} from "./utils";
interface SummaryRowSelectsProps {
  columns?: any;
  setMessage?: any;
}

const SummaryRowSelects = ({}: SummaryRowSelectsProps) => {
  const columnSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const { summaryState, summaryDispatch } =
    useContext<React.ContextType<typeof SummaryProvider>>(SummaryProvider);
  const { summaryRows, columns, columnsWithType } = summaryState;

  const onColumnChange = () => {
    setFunctionSelectedOption(undefined);
    functionSelectRef.current?.clearValue();
  };

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect(
    () => onColumnChange(),
    () => onColumnChange()
  );

  const [
    functionSelectedOption,
    setFunctionSelectedOption,
    functionOptionsChangeHandler,
  ] = useSingleSelect();

  const cleanSelects = () => {
    setColumnSelectedOption(undefined);
    setFunctionSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
  };

  useEffect(() => {
    if (columns?.length === 0) {
      cleanSelects();
    }
  }, [columns]);

  const handleAddSummaryRow = () => {
    if (
      validateSummaryRow(
        columnSelectedOption,
        functionSelectedOption,
        summaryRows,
        summaryDispatch
      )
    ) {
      const newRow = formatRow(
        columnSelectedOption,
        functionSelectedOption
      );
      const newSummaryRows = summaryRows;
      newSummaryRows.push(newRow);
      summaryDispatch({
        type: "SET_SUMMARY_ROWS",
        payload: newSummaryRows,
      });
      summaryDispatch({
        type: "SET_MESSAGE",
        payload: "",
      });
      cleanSelects();
    } 
  };

  const handleDeleteAllRows = () => {
    summaryDispatch({
      type: "SET_SUMMARY_ROWS",
      payload: [],
    });
    summaryDispatch({
      type: "SET_MESSAGE",
      payload: "",
    });
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Select
          reference={columnSelectRef}
          styles={selectStyles(columnSelectedOption)}
          options={columns}
          onChange={columnOptionsChangeHandler}
          closeMenuOnSelect
          placeholder="Elegir columna..."
          isClearable
          isDisabled={
            columns === undefined || columns === null || columns.length === 0
          }
        />
      </Grid>
      <Grid item xs={5}>
        <Select
          id="function"
          reference={functionSelectRef}
          styles={selectMultiStyles(functionSelectedOption)}
          options={
            findTypeByColumn(columnSelectedOption, columnsWithType) ===
            "CATEGORICA"
              ? CATEGORIC_FUNCTIONS
              : NUMERIC_FUNCTIONS
          }
          className="cc_select"
          name="function"
          onChange={functionOptionsChangeHandler}
          closeMenuOnSelect
          placeholder="Elegir función..."
          isDisabled={
            columns === undefined ||
            columns === null ||
            columns.length === 0 ||
            columnSelectedOption === undefined
          }
          isClearable
        />
      </Grid>
      <Grid item xs={1}>
        <AddButton onClick={() => handleAddSummaryRow()} />
      </Grid>
    </>
  );
};

export default SummaryRowSelects;
