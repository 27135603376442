import { Stack } from "@mui/material";
import ComponentLayout from "../layout/ComponentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FlowCanva from "./FlowCanva/FlowCanva";
import ToolsBar from "./ToolsBar/ToolsBar";
import { useContext, useEffect } from "react";
import FlowsProvider from "../../context/FlowsContext";
import { texts } from "../../texts";
import useApi from "../../hooks/useApi";
import { GET_FLOW_URL } from "../../api/axios";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { handleSetItemBarStatus } from "./utils";
import { useNavigate } from "react-router";

const ConfigFlows = () => {
  const navigate = useNavigate();
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { itemsBarStatus, active_stepper } = flowState;
  const flowId = localStorage.getItem("flowId")
    ? localStorage.getItem("flowId")
    : undefined;

  useEffect(() => {
    if (flowId) {
      getFlow(GET_FLOW_URL(parseInt(flowId)));
    }
  }, [flowId]);

  const onSuccessGetFlow = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: data,
    });
    handleSetItemBarStatus(flowDispatch, data, active_stepper, itemsBarStatus);
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  const handleCloseConfigFlows = () => {
    localStorage.removeItem("flowId");
    navigate("/administracion-flujos");
  };

  return (
    <ComponentLayout title="Configuración de Flujos" icon={<ArrowBackIcon
      sx={{
        fontSize: "35px",
        marginRight: "10px",
        "& :hover": { cursor: "pointer", color: "#f90d4a" },
      }}
      onClick={handleCloseConfigFlows}
    />}>
      <SimpleBackdrop
        open={isLoadingFlow}
        message={texts.flows.getFlow.loading}
      />
      <Stack sx={{ width: "99%", ml: "10px", mb: "20px" }}>
        <ToolsBar />
        <FlowCanva isLoadingFlow={isLoadingFlow}/>
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigFlows;
