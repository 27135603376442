import React, { useContext } from "react";
import SummaryProvider from "../../context/SummaryContext";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Delete from "../icons/Delete";
import { Item } from "../basicGrid/BasicGrid";
interface SummaryRowProps {
  row: any;
}

const RowSummary = ({ row }: SummaryRowProps) => {
  const { summaryState, summaryDispatch } =
    useContext<React.ContextType<typeof SummaryProvider>>(SummaryProvider);
  const { summaryRows } = summaryState;

  const handleDeleteFilter = () => {
    const newSummaryRows = [...summaryRows];
    summaryDispatch({
      type: "SET_SUMMARY_ROWS",
      payload: newSummaryRows.filter((summaryRow: any) => {
        return !(
          summaryRow.column.value === row?.column?.value &&
          summaryRow.function.value === row?.function?.value
        );
      }),
    });
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Item sx={{color: "rgba(0, 0, 0, 0.6) !important"}}>{row.column.label}</Item>
      </Grid>
      <Grid item xs={5}>
        <Item sx={{color: "rgba(0, 0, 0, 0.6) !important"}}>{row.function.label}</Item>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default RowSummary;
