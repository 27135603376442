import { Box, FormControlLabel, InputLabel, ListItemAvatar, Radio, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import Select from "../../../../forms/Select";
import { optionalSelectStyles, selectStyles } from "../../../../../styles/select.styles";
import { form_label } from "../../../../../styles/app-styles";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../../forms/types";
import ShadowBox from "../../../../flowConfiguration/Modals/ShadowBox";
import ToggleButton from "../../../../flowConfiguration/Modals/NewColumn/ToggleButton";
import CustomButton from "../../../../buttons/Button";
import Filter from "./Filter";
import { FiltersTabOperatorOptions, functionOptions } from "../../../../../utils/util";

interface FiltersTabProps {
  columns: any;
  itemConditions: any[];
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
  confToEdit?: any
}

const FiltersTab = ({
  columns,
  itemConditions,
  setConditions,
  confToEdit
}: FiltersTabProps) => {

  const [conditionalValue, setConditionalValue] = useState<any>(confToEdit && itemConditions?.length && itemConditions[0]?.valor?.tipo !== "columna"?itemConditions[0].valor.valor_comparar :undefined);
  const [operatorType, setOperator] = useState<string>(confToEdit && itemConditions?.length ? itemConditions[0]?.operador_filtro:"O");

  const [valueType, setValueType] = useState<any>(confToEdit && itemConditions?.length? itemConditions[0]?.valor?.tipo :"columna");

  const FiltersTabRef: any = useRef(null);
  const operatorRef: any = useRef(null);
  const valueColumnRef: any = useRef(null);
  const functionColumnRef: any = useRef(null);

  
  const [
    conditionalColumnSelectedOption,
    setConditionalColumnSelectedOption,
    conditionalColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit && itemConditions?.length ?itemConditions[0].columna_condicional :undefined);

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit && itemConditions?.length ?itemConditions[0].operador :undefined);

  const [
    valueColumnSelectedOption,
    setValueColumnSelectedOption,
    valueColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit && itemConditions?.length ? (itemConditions[0].valor.tipo === "columna"
  ? itemConditions[0].valor.valor_comparar
  : undefined) :undefined );

  const [
    functionColumnSelectedOption,
    setFunctionColumnSelectedOption,
    functionColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit && itemConditions?.length  && itemConditions[0]?.funcion? {value: itemConditions[0].funcion, label: itemConditions[0].funcion} : undefined);

/************************************* HANDLERS ****************************************/
  const onCustomChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    type: string
  ) => {
    switch (type) {
      case "filterColumn":
        conditionalColumnChangeHandler(newValue, actionMeta);
        if (itemConditions?.length ) {
          const newCondition = {
            ...itemConditions[0],
            columna_condicional: newValue,
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        } else {
          const newCondition = {
            id: Math.random(),
            operador_filtro: operatorType,
            columna_condicional: newValue,
            operador: undefined,
            valor: {
              tipo: valueType,
              valor_comparar: undefined,
            },
          };
          setConditions([newCondition]);
        }
        return;
      case "operator":
        operatorChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            operador: newValue,
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "conditionalValueColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: valueType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "function":
        functionColumnChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            funcion: newValue?.value,
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onInputChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setConditionalValue(value);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: valueType,
              valor_comparar: value,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onToggleChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setValueType(value);
        if (value === "columna") {
          setConditionalValue("");
        } else {
          setValueColumnSelectedOption(undefined);
        }
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: value,
              valor_comparar: undefined,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  

  const handleAddCondition = () => {
    itemConditions && itemConditions?.length > 0 &&
      setConditions([
        ...itemConditions,
        {
          id: Math.random(),
          operador_filtro: operatorType,
          columna_condicional: undefined,
          operador: undefined,
          valor: {
            tipo: valueType,
            valor_comparar: undefined,
          },
        },
      ]);
  };

  const handleChangeOperator = (operator: any)=>{
    setOperator(operator)
    setConditions(itemConditions.map((c: any)=> {return {...c, operador_filtro: operator}}))
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ShadowBox margin>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ marginRight: "8px", width: "35%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Columna</InputLabel>
            <Select
              reference={FiltersTabRef}
              styles={selectStyles(conditionalColumnSelectedOption)}
              options={columns}
              name="Columna"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "filterColumn");
              }}
              closeMenuOnSelect
              placeholder="Seleccionar columna"
              isClearable
              defaultValue={conditionalColumnSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "30%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Operador</InputLabel>
            <Select
              reference={operatorRef}
              styles={selectStyles(operatorSelectedOption)}
              options={FiltersTabOperatorOptions}
              name="Operador"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "operator");
              }}
              closeMenuOnSelect
              placeholder="Operador"
              isClearable
              isDisabled={!conditionalColumnSelectedOption}
              defaultValue={operatorSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "35%", marginTop: "auto" }}>
            <Box sx={{ display: "flex" }}>
              <InputLabel sx={form_label}>Valor</InputLabel>
              <ToggleButton
                value={valueType}
                setValue={onToggleChange}
                value1="columna"
                value2="valor"
                type="conditionalValue"
              />
            </Box>
            {valueType === "columna" ? (
              <Select
                reference={valueColumnRef}
                styles={selectStyles(valueColumnSelectedOption)}
                options={columns}
                name="Valor"
                onChange={(newValue: any, action: any) => {
                  onCustomChange(newValue, action, "conditionalValueColumn");
                }}
                isDisabled={!conditionalColumnSelectedOption}
                closeMenuOnSelect
                placeholder="Seleccionar columna"
                isClearable
                defaultValue={valueColumnSelectedOption}
              />
            ) : (
              <TextField
                id="value"
                label={"Valor"}
                placeholder={"Valor"}
                size="small"
                multiline
                disabled={!conditionalColumnSelectedOption}
                variant="outlined"
                error={conditionalValue === ""}
                fullWidth
                value={conditionalValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange(event.target.value, "conditionalValue");
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            )}
          </Box>
        </Box>
        <Box sx={{ marginRight: "8px", width: "35%", marginTop: "10px" }}>
            <InputLabel sx={form_label}>Aplicar función a columna (opcional)</InputLabel>
            <Select
              reference={functionColumnRef}
              styles={optionalSelectStyles(functionColumnSelectedOption)}
              options={functionOptions}
              name="Function"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "function");
              }}
              closeMenuOnSelect
              placeholder="Función"
              isClearable
              isDisabled={!conditionalColumnSelectedOption}
              defaultValue={functionColumnSelectedOption}
            />
          </Box>
      {itemConditions &&
        itemConditions.length > 1 &&
        itemConditions.slice(1).map((condition: any) => {
          return (
            <Filter
              key={condition.id}
              columns={columns}
              condition={condition}
              setConditions={setConditions}
              conditions={itemConditions}
              operatorType={operatorType}
            />
          );
        })}
        <Stack direction="row" sx={{ alignItems: "center", margin: "0" }}>
        <Typography sx={{ color: "var(--blue)", marginRight: "5px" }}>
          Tipo de operador:
        </Typography>
        <FormControlLabel
          control={<Radio color="primary" sx={{ mr: "2px" }} />}
          label={
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={form_label}>O</Typography>
            </Stack>
          }
          value={"O"}
          checked={operatorType === "O"}
          onChange={(e: any) => {
            handleChangeOperator(e.target.value);
          }}
        />
        <FormControlLabel
          control={<Radio color="primary" sx={{ mr: "2px" }} />}
          label={
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={form_label}>Y</Typography>
            </Stack>
          }
          value={"Y"}
          checked={operatorType === "Y"}
          onChange={(e: any) => {
            handleChangeOperator(e.target.value);
          }}
        />
        <Box sx={{ marginLeft: "auto" }}>
          <CustomButton
            title="Agregar condición"
            type="button"
            onClick={handleAddCondition}
            color="blue-greeny"
          />
        </Box>
      </Stack>
      </ShadowBox>
    </Box>
  );
};

export default FiltersTab;
