import { createContext, useReducer } from "react";
import { FlowAction, FlowState } from "./types";
import { categorias } from "../components/flowConfiguration/ToolsBar/tools";

const FlowsContext = createContext<any>({});

export const FlowsProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialFlowState: FlowState = {
    active_stepper: "",
    itemsBarStatus: categorias,
    data_flow: {
      nombre: "",
      id: "",
      steppers: [],
    },
    groupByRows: [],
    itemToEdit: undefined,
    newDuplicateStepper: ""
  };

  function flowReducer(flowState: FlowState, action: any) {
    switch (action.type) {
      case "SET_DATA_FLOW":
        return {
          ...flowState,
          data_flow: action.payload,
        };
      case "SET_ACTIVE_STEPPER":
        return {
          ...flowState,
          active_stepper: action.payload,
        };
      case "SET_FLOW_NAME":
        return {
          ...flowState,
          data_flow: {
            ...flowState.data_flow,
            nombre: action.payload.nombre,
            id: action.payload.id,
          },
        };
      case "SET_ITEMS_BAR_STATUS":
        return {
          ...flowState,
          itemsBarStatus: action.payload,
        };
      case "SET_GROUP_BY_ROWS":
        return {
          ...flowState,
          groupByRows: action.payload,
        };
      case "SET_ITEM_TO_EDIT":
        return {
          ...flowState,
          itemToEdit: action.payload,
        };
        case "SET_NEW_DUPLICATE_STEPPER_NAME":
          return {
            ...flowState,
            newDuplicateStepper: action.payload,
          };
      default:
        throw new Error();
    }
  }

  const [flowState, flowDispatch] = useReducer<
    React.Reducer<FlowState, FlowAction>
  >(flowReducer, initialFlowState);

  return (
    <FlowsContext.Provider
      value={{
        initialFlowState,
        flowReducer,
        flowState,
        flowDispatch,
      }}
    >
      {children}
    </FlowsContext.Provider>
  );
};

export default FlowsContext;
