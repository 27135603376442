import ListItem from "@mui/material/ListItem";
import { isOptionSelected, redirectMenuItem } from "./utils";
import {
  list_item_button_styles,
  list_item_icon_styles,
  list_item_selected_styles,
  list_item_text_styles,
} from "../../styles/app-styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "./MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CollapseList from "./CollapseList";

interface ListItemComponentProps {
  item: any;
  navigate: any;
  open: boolean;
  handleClickOpenMenu: any;
  handleOpenStates: any;
  handleCollapseList: any;
  handleDrawerOpen: any;
  handleDrawerClose: any
}

const ListItemComponent = ({
  item,
  navigate,
  open,
  handleClickOpenMenu,
  handleOpenStates,
  handleCollapseList,
  handleDrawerOpen,
  handleDrawerClose
}: ListItemComponentProps) => {

  const handleClickItemMenu = () => {
    if (item.url === "") {
      handleDrawerOpen();
      handleClickOpenMenu(item.title);
    } 
    if (window.location.pathname !== "/administracion-flujos/configuracion-flujos") {
        localStorage.removeItem("flowId");
    } 
    if (window.location.pathname !== "/consolidacion-archivos/configuracion-esquemas") {
      localStorage.removeItem("schemaId");
    };
    if (window.location.pathname !== "/reporte-base/configuracion-resumenes") {
      localStorage.removeItem("summaryId");
    };
    if (window.location.pathname !== "/reporte-base/configuracion-validaciones") {
      localStorage.removeItem("validationId");
    };
    if (window.location.pathname !== "/consolidacion-archivos/configuracion-pipelines") {
      localStorage.removeItem("pipelineId");
    };
  };

  return (
    <div key={item.title}>
      <ListItem
        onClick={() => redirectMenuItem(item.url, navigate, handleDrawerClose)}
        disablePadding
        sx={list_item_selected_styles(isOptionSelected(item.title))}
      >
        <ListItemButton sx={list_item_button_styles(open)}>
          <ListItemIcon sx={list_item_icon_styles(open)}>
            <MenuItem MenuIcon={item.icon} title={item.title} handleClickItemMenu={handleClickItemMenu} />
          </ListItemIcon>
          {item.url !== "" ? (
            <ListItemText
              primary={item.title}
              sx={list_item_text_styles(open)}
            />
          ) : (
            open && (
              <ListItemButton
                onClick={() => handleClickOpenMenu(item.title)}
                sx={{ padding: "0 !important", margin: "0 !important" }}
              >
                <ListItemText
                  primary={item.title}
                  sx={list_item_text_styles(open)}
                />
                {handleOpenStates(item.title) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )
          )}
        </ListItemButton>
      </ListItem>
      {open && item.url === "" && (
        <CollapseList
          openMenuList= {handleOpenStates(item.title)}
          optionsList={() => handleCollapseList(item.title)}        
          handleDrawerClose={handleDrawerClose}
        />
      )}
    </div>
  );
};

export default ListItemComponent;
