import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { texts } from "../../../texts.js";
import FlowsProvider from "../../../context/FlowsContext";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { ITEM_URL, POST_ITEM_URL, VIEW_RESULTS_URL } from "../../../api/axios";
import {
  findBeforeElementPosition,
  initialFilterData,
  searchStepper,
  transformDataToSend,
} from "../utils";
import useDialog from "../../../hooks/useDialog";
import FilterTable from "../../tablesTools/FilterTable";
import React from "react";
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";
import BaseModal from "./BaseModal";
interface FilterProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const Filter = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: FilterProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemToEdit } = flowState;
  const [openConfirmFilterModal, toggleConfirmFilterModal] = useDialog();
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(
    initialFilterData(dataItem)
  );
  const [hasCalledGetViewResults, setHasCalledGetViewResults] = useState(false);

  /************************************* CARGA DE DATOS ********************************/
  useEffect(() => {
    if (open && !hasCalledGetViewResults) {
      setHasCalledGetViewResults(true);
      getTableData();
    }
  }, [open]);

  useEffect(() => {
    if (open && dataItem) {
      setFilterModel(initialFilterData(dataItem));
    }
    
  }, [open, dataItem]);

  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
    setData: setTableData,
  } = useApi(
    VIEW_RESULTS_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem && itemToEdit?.id
      )
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    undefined,
    undefined,
    false  );

  /************************************ POSTEAR ITEM **********************************/

  const handleApplyFilters = () => {
    toggleConfirmFilterModal();
    const completedInfo = transformDataToSend(filterModel);
    if (dataItem) {
      putItem(
        ITEM_URL(data_flow.id, active_stepper, itemToEdit.id),
        completedInfo
      );
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), completedInfo);
    }
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setHasCalledGetViewResults(false);
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    setFilterModel({
      items: [
        {
          id: "",
          columnField: "",
          operatorValue: "",
          value: [],
        },
      ],
      linkOperator: GridLinkOperator.And,
    });
    setTableData([]);
    handleClose();
  };

  const validateData = () => {
    const result = filterModel.items.some((item: any) => {
      const keys = Object.keys(item);
      return keys.find((key)=>key === "value") === undefined
    })
    if(result){
      return true
    }
    if (filterModel.items.every((item: any) => {
      return item.value === ""
    })) {
      return true
    }
    if (filterModel.items.length < 1) {
      return true
    }
    return false
  }

  function modificarClaves(obj: Record<string, string>): Record<string, string> {
    const nuevoObj: Record<string, string> = {}; // Creamos un nuevo objeto para almacenar las claves modificadas
  
    for (const clave in obj) {
      nuevoObj["id"] = obj["id"];
      if (obj.hasOwnProperty(clave) && clave.includes("(")) {
        const nuevaClave = clave.substring(0, clave.lastIndexOf("(")); // Modificamos la clave
        nuevoObj[nuevaClave] = obj[clave]; // Asignamos el valor original a la nueva clave
      }
    }
  
    return nuevoObj; // Devolvemos el nuevo objeto con las claves modificadas
  }  

  return (
    <BaseModal
      open={open}
      title="Filtrar"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmFilterModal}
      toggleConfirmationModal={toggleConfirmFilterModal}
      handleAccept={handleApplyFilters}
      confirmationModalMessage={"aplicar los filtros permanentemente"}
      disabledAcceptButton={validateData()}
      width="1300px"
      height="445px"
    >
      <SimpleBackdrop
        open={isLoadingTableData}
        message={texts.flows.getFilterData.loading}
      />
      <Stack alignItems="center" justifyContent={"space-between"}>
        <Stack sx={{ minWidth: "1300px", height: "450px", overflowY: "auto" }}>
          {tableData && (
            <FilterTable
              columns={transformDataSimpleTable(tableData?.map(
                (item: any) => modificarClaves(item)
              ), undefined, undefined, undefined, tableData).columns}
              rows={transformDataSimpleTable(tableData?.map(
                (item: any) => modificarClaves(item)
              )).rows}
              toolbar={true}
              exportButton={false}
              filterButton={true}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
            />
          )}
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default Filter;
