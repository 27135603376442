import { Stack, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts.js";
import {
  FILE_BY_ID,
  FILE_INFO_URL,
  ITEM_URL,
  POST_STEPPER_URL,
} from "../../../../api/axios";
import FlowsProvider from "../../../../context/FlowsContext";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import {
  handleSetItemBarStatus,
  isFileNameValid,
  isStepNameRepeated,
} from "../../utils";
import useDialog from "../../../../hooks/useDialog";
import ShadowBox from ".././ShadowBox";
import BaseModal from "../BaseModal";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import NewFont from "./NewFont";
import UploadFileContent from "../../../dataManagement/UploadFileModal/UploadFileContent";
import { cutUrl } from "./utils";
import { Button } from "../../../buttons";
import ReplaceFont from "../../../flowExecution/ReplaceFont";

interface AddFontProps {
  open: boolean;
  handleClose: () => void;
  putItem: (url?: any, data?: any) => void;
  setIsLoadingPostItemAddFonts: (isLoading: boolean) => void;
  dataItem?: any;
}

const AddFont = ({
  open,
  handleClose,
  setIsLoadingPostItemAddFonts,
  putItem,
  dataItem,
}: AddFontProps) => {
  const [file, setFile] = useState<any>("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<any>("");
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [replaceFont, setReplaceFont] = useState<any>(false);
  const [editFile, setEditFile] = useState<any>("");
  const sheetSelectRef: any = useRef(null);
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { itemsBarStatus, data_flow, active_stepper, itemToEdit } = flowState;
  const getFileDatacontroller = new AbortController();

  /*************************** CARGA DE DATOS ***************************/
  useEffect(() => {
    if (dataItem) {
      if(dataItem.url !== null){
        setFile(dataItem.url);
        setEditFile(cutUrl(dataItem.url));
        setDataFileState([
          {
            id: dataItem.id,
            nombre: dataItem.nombre,
            dataSize: dataItem.cantidad_muestra,
            firstLastRows: dataItem.primeras_ultimas_filas,
            isRandomData: dataItem.muestra_aleatoria,
            rowsNumber:
              dataItem.cantidad_filas === null
                ? undefined
                : dataItem.cantidad_filas,
            hoja: dataItem.hoja,
            hojas: dataItem.hojas,
            isEditItem: true,
            cabecera: dataItem?.cabecera
          },
        ]);
      }
    }
  }, [dataItem]);

  useEffect(() => {
    sheetSelectRef?.current?.clearValue();
  }, [file]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        //  setDelayHelper(true);
      }, 2);
    } else {
      getFileDatacontroller.abort();
    }
  }, [open]);

  /*************************** LLAMADAS ***************************/

  const onSuccessGetFileInfo = (data: any) => {
    if (!dataItem) {
      setDataFileState((prevState: any) =>
        prevState ? [...prevState, data] : [data]
      );
    } else {
      // Si estamos editando el item mantenemos toda la configuracion de numero de filas, muestra aleatoria o predeterminada etc hasta que el user lo cambie
      setDataFileState([{ ...dataFileState[0], nombre: dataItem.nombre, id: data.id, hojas:data.hojas, hoja: undefined }]);

    }
  };

  const onErrorGetFileInfo = () => {
    setDataFileState([]);
  };

  const {
    data: dataFile,
    isLoading: isLoadingFilesInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: selectedFileFolder,
      directorio: true,
      tipo: "azure",
      origen_descarga: "flow",
      actualizar_archivo: true
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    getFileDatacontroller,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );

  /******************************* VALIDACIONES ********************************/

  const areMultipleFiles = () => {
    return dataFileState?.length > 1;
  };

  const areFilesNameDuplicated = () => {
    const names = dataFileState?.map((file: any) => file.nombre);
    return names?.length !== new Set(names).size;
  };

  const isFormCompleted = () => {
    if (areMultipleFiles()) {
      return (
        !areFilesNameDuplicated() &&
        !dataFileState.some((file: any) => {
          return (
            (dataItem?.nombre !== file.nombre &&
              isStepNameRepeated(file.nombre, data_flow)) ||
            file.nombre === ""
          );
        }) &&
        !dataFileState.some((file: any) => {
          return (
            file?.dataSize === "filas" &&
            (file?.rowsNumber === "" || file?.rowsNumber === undefined)
          );
        })
      );
    } else {
      return (
        dataFileState !== null &&
        dataFileState?.length > 0 &&
        dataFileState[0]?.nombre?.length > 0 &&
        (dataFileState[0]?.dataSize === "filas"
          ? dataFileState[0]?.rowsNumber !== "" &&
            dataFileState[0]?.rowsNumber !== undefined
          : true) &&
        isFileNameValid(dataFileState[0]?.nombre) &&
        !(
          isStepNameRepeated(dataFileState[0]?.nombre, data_flow) &&
          dataItem?.nombre !== dataFileState[0]?.nombre
        )
      );
    }
  };

  /******************************** HANDLERS ******************************************/
  const handleChangeFileSheet = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    sheetInfoOptionsChangeHandler: any,
    fileInfo: any
  ) => {
    sheetInfoOptionsChangeHandler(newValue, actionMeta);
    const selectedFile = dataFileState.find((file: any) => {
      return file.id === fileInfo.id;
    });
    if (selectedFile) {
      setDataFileState([
        ...dataFileState.filter((file: any) => {
          return file.id !== fileInfo.id;
        }),
        { ...selectedFile, hoja: newValue },
      ]);
    }
  };

  const handleChangeFilesState = (row: any) => {
    setDataFileState(
      dataFileState.map((file: any) => {
        return file.id !== row.id ? file : row;
      })
    );
  };

  const handleChangeFilesStateReplaceFont = (label: string, data: any) => {
    setDataFileState([{id: data.id, nombre: dataItem.nombre, hojas:data.hojas}])
  };

  const handleChangeFileSheetReplaceFont = (sheet: string | number | undefined) => {
    dataFileState?.length > 0 ? setDataFileState([{...dataFileState[0], hoja: sheet}]) : setDataFileState([{ hoja: sheet}])
  };

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFiles(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            if (clickedFile !== "all") {
              getFilesInfo(undefined, {
                path_archivo: clickedFile === "chooseFile" ? selectedFiles :`${selectedFileFolder}${clickedFile.nombre}`,
                directorio: false,
                tipo: "azure",
                actualizar_archivo: true

              });
            } else {
              fileState
                .filter((file: any) =>
                  !dataFileState.map((f: any) => f.nombre).includes(file.nombre)
                )
                .forEach((file: any) => {
                  if (file.id === null || file.id === undefined) {
                    getFilesInfo(undefined, {
                      path_archivo: `${selectedFileFolder}${file.nombre}`,
                      directorio: false,
                      tipo: "azure",
                      actualizar_archivo: true

                    });
                  } else {
                    getFilesInfoById(FILE_BY_ID(file.id));
                  }
                });
            }
          } else {
            getFilesInfoById(FILE_BY_ID(clickedFile.id));
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const handleReplaceFile = () => {
    setReplaceFont(true);
    if (editFile !== dataItem?.url) {
      getFilesInfo(undefined, {
        path_archivo: editFile,
        directorio: false,
        tipo: "azure",
        origen_descarga: "flow",
        actualizar_archivo: true
      });
    }
  };

  /******************************** POST STEPPER Y POST ITEM ***********************************/

  const saveAddFont = () => {
    if (dataItem) {
      putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), getBody());
    } else {
      postStepper(undefined, getBody());
    }
    cleanAndClose();
  };

  const getBody = () => {
    return {
      lista_archivos: dataFileState.map((file: any) => {
        return {
          id: file.id,
          hoja:
            file.hoja === undefined
              ? file.hojas[0].id
              : file.hoja.id === undefined
              ? file.hoja.value
              : file.hoja.id,
            nombre: file.isExternalConnection && dataItem ? dataItem.nombre : file.nombre,
          muestra_aleatoria:
            file.isRandomData !== undefined ? file.isRandomData : false,
          cantidad_muestra:
            file.dataSize !== undefined ? file.dataSize : "predeterminada",
          cantidad_filas: file.dataSize === "filas" ? parseInt(file.rowsNumber) : "",
          primeras_ultimas_filas:
            file.firstLastRows !== undefined ? file.firstLastRows : false,
            cabecera: file.cabecera !== undefined &&  file.cabecera !== "" ? parseInt(file.cabecera) : null
        };
      }),
    };
  };

  const onSuccessPostStepper = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: data,
    });

    if (areMultipleFiles()) {
      handleSetItemBarStatus(
        flowDispatch,
        data,
        active_stepper,
        itemsBarStatus
      );
    } else {
      flowDispatch({
        type: "SET_ACTIVE_STEPPER",
        payload: data.id,
      });
      handleSetItemBarStatus(
        flowDispatch,
        data,
        active_stepper,
        itemsBarStatus
      );
    }
  };

  const { isLoading: isLoadingPostStepper, callApi: postStepper } = useApi(
    POST_STEPPER_URL(data_flow.id),
    "POST",
    texts.flows.postStepper.codes,
    undefined,
    onSuccessPostStepper,
    undefined,
    false
  );

  useEffect(() => {
    setIsLoadingPostItemAddFonts(isLoadingPostStepper);
  }, [isLoadingPostStepper]);

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    getFileDatacontroller.abort();
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    setFile("");
    setDataFileState([]);
    setSelectedFileFolder("");
    setSelectedFiles([]);
    setEditFile("");
    setReplaceFont(false);
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
  };

  return (
    <BaseModal
      open={open}
      title="Nueva fuente"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveAddFont}
      confirmationModalMessage={
        dataItem ? "editar la fuente" : "agregar la fuente"
      }
      disabledAcceptButton={!isFormCompleted()}
      height="430px"
    >
      <SimpleBackdrop
        open={isLoadingPostStepper}
        message={texts.flows.postItem.loading}
      />
      <SimpleBackdrop
        open={isLoadingFilesInfo || isLoadingFileInfoById}
        message={texts.adminPipeline.getFileUrl.loading}
      />
      <ShadowBox margin>
        {dataItem && dataItem?.url !== null && (
          <Stack
            sx={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "var(--very-very-light-grey)",
              borderRadius: "10px",
              padding: "8px",
              alignItems: "center",
            }}
          >
            <TextField
              id="url"
              label={"Archivo original"}
              placeholder={"Archivo original"}
              size="small"
              multiline
              variant="outlined"
              fullWidth
              value={editFile}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEditFile(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                  width: "70%",
                },
              }}
              sx={{
                marginBottom: "8px",
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  height: "0.18rem",
                },
              }}
            />
            <Button
              title="Reemplazar archivo"
              color="blue"
              type="button"
              onClick={handleReplaceFile}
            />
          </Stack>
        )}
        {(!dataItem || replaceFont) && (
          <UploadFileContent
            selectedFile={selectedFiles}
            setSelectedFile={handleSelectFile}
            dataFileState={dataFileState}
            setDataFileState={setDataFileState}
            setSelectedFileFolder={setSelectedFileFolder}
            areMultipleFiles={!dataItem}
            notUnselectFilesOnFolderChange
            dataItem={dataItem}
          />
        )}

        <Stack sx={{ marginTop: "10px" }}>
          {dataFileState && dataFileState.length > 0  && dataItem?.url !== null && (
            <Stack marginTop="15px">
              {dataFileState.map((row: any) => {
                return (
                  <NewFont
                    key={row.id}
                    row={row.id}
                    dataFileState={dataFileState}
                    handleChangeFileSheet={handleChangeFileSheet}
                    handleChangeFilesState={handleChangeFilesState}
                    dataItem={dataItem}
                    editName
                  />
                );
              })}
            </Stack>
          )}
        </Stack>
        {dataItem && dataItem?.url === null &&
           <ReplaceFont
           label={""}
           setFont={(font: string, chau: any)=>{console.log(font)}}
           error={true}
           setSheet={handleChangeFileSheetReplaceFont}
           setDataFont={handleChangeFilesStateReplaceFont}
           isAddFont={true}
         />}
           {dataFileState && dataFileState.length > 0  && dataItem?.url === null && (
            <Stack marginTop="15px">
              {dataFileState.map((row: any) => {
                return (
                  <NewFont
                    key={row.id}
                    row={row.id}
                    dataFileState={dataFileState}
                    handleChangeFileSheet={handleChangeFileSheet}
                    handleChangeFilesState={handleChangeFilesState}
                    dataItem={dataItem}
                    editName
                  />
                );
              })}
            </Stack>
          )}
      </ShadowBox>
    </BaseModal>
  );
};

export default AddFont;
