import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Select from "../../../forms/Select";
import { selectStyles } from "../../../../styles/select.styles";
import { form_label } from "../../../../styles/app-styles";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import ToggleButton from "./ToggleButton";
import ShadowBox from "../ShadowBox";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import Delete from "../../../icons/Delete";

interface ConditionProps {
  columns: any;
  condition?: any;
  conditions: any[];
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
  operatorType: string;
}

const Condition = ({
  columns,
  condition,
  conditions,
  setConditions,
  operatorType,
}: ConditionProps) => {
  const [conditionalValue, setConditionalValue] = useState<any>(
    condition
      ? condition?.valor.tipo === "valor"
        ? condition.valor.valor_comparar
        : ""
      : ""
  );
  const [outcomeValue, setOutcomeValue] = useState<any>(
    condition
      ? condition?.salida.tipo === "valor"
        ? condition.salida.valor_comparar
        : ""
      : ""
  );
  const [valueType, setValueType] = useState<any>(
    condition ? condition.valor.tipo : "columna"
  );
  const [outcomeType, setOutcomeType] = useState<any>(
    condition ? condition.salida.tipo : "columna"
  );
  const conditionalColumnRef: any = useRef(null);
  const operatorRef: any = useRef(null);
  const valueColumnRef: any = useRef(null);
  const outcomeColumnRef: any = useRef(null);
  const isLastCondition = condition.id === conditions[conditions.length -1].id
  
  const onCustomChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    type: string
  ) => {
    switch (type) {
      case "conditionalColumn":
        conditionalColumnChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            columna_condicional: newValue,
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "operator":
        operatorChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            operador: newValue,
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "conditionalValueColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            valor: {
              tipo: valueType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "outcomeColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            salida: {
              tipo: outcomeType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onInputChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setConditionalValue(value);
        if (conditions) {
          const newCondition = {
            ...condition,
            valor: {
              tipo: valueType,
              valor_comparar: value,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "outcomeValue":
        setOutcomeValue(value);
        if (conditions) {
          const newCondition = {
            ...condition,
            salida: {
              tipo: outcomeType,
              valor_comparar: value,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const [
    conditionalColumnSelectedOption,
    setConditionSelectedOption,
    conditionalColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    condition ? condition.columna_condicional : undefined
  );

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    condition ? condition.operador : undefined
  );

  const [
    valueColumnSelectedOption,
    setValueColumnSelectedOption,
    valueColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    condition
      ? condition.valor.tipo === "columna"
        ? condition.valor.valor_comparar
        : undefined
      : undefined
  );

  const [
    outcomeColumnSelectedOption,
    setOutcomeColumnSelectedOption,
    outcomeColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    condition
      ? condition.salida.tipo === "columna"
        ? condition.salida.valor_comparar
        : undefined
      : undefined
  );

  const conditionalColumnOperatorOptions = [
    { value: "ES_IGUAL_A", label: "es igual a" },
    { value: "NO_ES_IGUAL_A", label: "no es igual a" },
    { value: "COMIENZA_POR", label: "comienza por" },
    { value: "NO_COMIENZA_POR", label: "no comienza por" },
    { value: "TERMINA_CON", label: "termina con" },
    { value: "NO_TERMINA_CON", label: "no termina con" },
    { value: "CONTIENE", label: "contiene" },
    { value: "NO_CONTIENE", label: "no contiene" },
    { value: "ES_MAYOR_QUE", label: "es mayor que" },
    { value: "ES_MAYOR_O_IGUAL_QUE", label: "es mayor o igual que " },
    { value: "ES_MENOR_QUE", label: "es menor que" },
    { value: "ES_MENOR_O_IGUAL_QUE", label: "es menor o igual que " },
  ];

  const onToggleChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        if (value === "columna") {
          setConditionalValue("");
        } else {
          setValueColumnSelectedOption(undefined);
        }
        setValueType(value);
        const newCondition = {
          ...condition,
          valor: {
            tipo: value,
            valor_comparar: undefined,
          },
        };
        setConditions(
          conditions.map((c: any) =>
            c.id === newCondition.id ? newCondition : c
          )
        );

        return;
      case "outcomeValue":
        if (value === "columna") {
          setOutcomeValue("");
        } else {
          setOutcomeColumnSelectedOption(undefined);
        }
        setOutcomeType(value);
        if (conditions) {
          const newCondition = {
            ...condition,
            salida: {
              tipo: value,
              valor_comparar: undefined,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const handleDeleteCondition = () => {
    setConditions(
      conditions.filter((c: any) => {
        return c.id !== condition.id;
      })
    );
  };

  return (
    <ShadowBox margin>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Typography
            sx={{
              backgroundColor: "var(--blue-greeny)",
              fontWeight: "bold",
              padding: "8px 8px 5px 8px",
              borderRadius: "5px",
              marginRight: "5px",
              marginTop: "auto",
              fontSize: "14px",
              width: "48px",
              color: "white",
            }}
          >
            {operatorType === "O" ? "O si" : "Y si"}
          </Typography>
          <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Columna</InputLabel>
            <Select
              reference={conditionalColumnRef}
              styles={selectStyles(conditionalColumnSelectedOption)}
              options={columns}
              name="Columna"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "conditionalColumn");
              }}
              closeMenuOnSelect
              placeholder="Seleccionar columna"
              isClearable
              defaultValue={conditionalColumnSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "20%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Operador</InputLabel>
            <Select
              reference={operatorRef}
              styles={selectStyles(operatorSelectedOption)}
              options={conditionalColumnOperatorOptions}
              name="Operador"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "operator");
              }}
              closeMenuOnSelect
              placeholder="Operador"
              isClearable
              defaultValue={operatorSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
            <Box sx={{ display: "flex" }}>
              <InputLabel sx={form_label}>Valor</InputLabel>

              <ToggleButton
                value={valueType}
                setValue={onToggleChange}
                value1="columna"
                value2="valor"
                type="conditionalValue"
              />
            </Box>
            {valueType === "columna" ? (
              <Select
                reference={valueColumnRef}
                styles={selectStyles(valueColumnSelectedOption)}
                options={columns}
                name="Valor"
                onChange={(newValue: any, action: any) => {
                  onCustomChange(newValue, action, "conditionalValueColumn");
                }}
                closeMenuOnSelect
                placeholder="Seleccionar columna"
                isClearable
                defaultValue={valueColumnSelectedOption}
              />
            ) : (
              <TextField
                id="value"
                label={"Valor"}
                placeholder={"Valor"}
                size="small"
                multiline
                variant="outlined"
                error={conditionalValue === ""}
                fullWidth
                value={conditionalValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange(event.target.value, "conditionalValue");
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            )}
          </Box>
          {(operatorType === "O" || isLastCondition) && (
            <>
              <Typography
                sx={{
                  backgroundColor: "var(--blue-greeny)",
                  fontWeight: "bold",
                  padding: "8px 10px 5px 10px",
                  borderRadius: "5px",
                  marginRight: "5px",
                  marginTop: "auto",
                  color: "white",
                }}
              >
                Entonces{" "}
              </Typography>
              <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
                <Box sx={{ display: "flex" }}>
                  <InputLabel sx={form_label}>Salida</InputLabel>

                  <ToggleButton
                    value={outcomeType}
                    setValue={onToggleChange}
                    value1="columna"
                    value2="valor"
                    type="outcomeValue"
                  />
                </Box>
                {outcomeType === "columna" ? (
                  <Select
                    reference={outcomeColumnRef}
                    styles={selectStyles(outcomeColumnSelectedOption)}
                    options={columns}
                    name="Salida"
                    onChange={(newValue: any, action: any) => {
                      onCustomChange(newValue, action, "outcomeColumn");
                    }}
                    closeMenuOnSelect
                    placeholder="Seleccionar columna"
                    isClearable
                    defaultValue={outcomeColumnSelectedOption}
                  />
                ) : (
                  <TextField
                    id="outcome"
                    label={"Valor"}
                    placeholder={"Valor"}
                    size="small"
                    multiline
                    variant="outlined"
                    error={outcomeValue === ""}
                    fullWidth
                    value={outcomeValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange(event.target.value, "outcomeValue");
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                )}
              </Box>
            </>
          )}

          <Box sx={{ marginTop: "auto" }}>
            <Delete
              onClick={handleDeleteCondition}
              tooltipPlacement="right-start"
            />
          </Box>
        </Box>
      </Box>
    </ShadowBox>
  );
};

export default Condition;
