import { Checkbox, ListItem, ListItemText } from "@mui/material";

interface IListItem {
  item: any;
  handleChangeItem: (item: any) => void;
  selectedItems: any[];
}

const Listitem = ({
  item,
  handleChangeItem,
  selectedItems,
}: IListItem) => {

  return (
    <ListItem
      divider={true}
      sx={{
        marginLeft: "0px",
        paddingLeft: "0px",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div
        style={{
          color: "#172D40",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={selectedItems.map((item: any)=>{return item.label}).indexOf(item.label) > -1}
          sx={{
            color: "#172D40",
            "& svg": { stroke: "#172D40" },
          }}
          onChange={() => {
            handleChangeItem(item);
          }}
        />
        <ListItemText
          primary={<p>{item.label}</p>}
          primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
        />
      </div>
    </ListItem>
  );
};

export default Listitem;
