import { Box, InputLabel, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "../../../../forms/Select";
import { form_label } from "../../../../../styles/app-styles";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import { useRef, useState } from "react";
import { selectStyles } from "../../../../../styles/select.styles";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../../forms/types";

interface AggregationProps {
  columns: any;
  aggregation: any;
  aggregations: any[];
  setAggregations: React.Dispatch<React.SetStateAction<any[]>>;
}

const Aggregation = ({
  aggregation,
  columns,
  aggregations,
  setAggregations,
}: AggregationProps) => {
  const [newColumn, setNewColumn] = useState<string>(aggregation?.columna_nueva ? aggregation.columna_nueva : "");

  const aggregationColumnRef: any = useRef(null);
  const operatorRef: any = useRef(null);

  const [
    aggregationColumnSelectedOption,
    setaggregationColumnSelectedOption,
    aggregationColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, aggregation?.columna_valor_funcion ? {value: aggregation.columna_valor_funcion,label:aggregation.columna_valor_funcion }: undefined );

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(undefined, undefined,  (aggregation?.funcion? {value: aggregation.funcion,label:aggregation.funcion }: undefined) );

  const operatorOptions = [
    { value: "ABS", label: "ABS" },
    { value: "AVG", label: "AVG" },
    { value: "COUNT", label: "COUNT" },
    { value: "MAX", label: "MAX" },
    { value: "MIN", label: "MIN" },
    { value: "ROUND", label: "ROUND" },
    { value: "SUM", label: "SUM" },
    { value: "MONTH", label: "MONTH" },
    { value: "DAY", label: "DAY" },
    { value: "YEAR", label: "YEAR" },
  ];

 /*************************************** HANDLERS ******************************************** */ 

  const handleChangeAggregationColumn = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    aggregationColumnChangeHandler(newValue, actionMeta);
    const newAggregation = {
      ...aggregation,
      columna_valor_funcion: newValue?.value,
    };
    setAggregations(
      aggregations.map((a: any) =>
        a.id === newAggregation.id ? newAggregation : a
      )
    );
  };

  const handleChangeOperator = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    operatorChangeHandler(newValue, actionMeta);
    const newAggregation = {
      ...aggregation,
      funcion: newValue?.value,
    };
    setAggregations(
      aggregations.map((a: any) =>
        a.id === newAggregation.id ? newAggregation : a
      )
    );
  };

  const handleChangeNewColumn = (column: string) => {
    setNewColumn(column);
    const newAggregation = {
      ...aggregation,
      columna_nueva: column,
    };
    setAggregations(
      aggregations.map((a: any) =>
        a.id === newAggregation.id ? newAggregation : a
      )
    );
  };

  const deleteAggregation = (aggregationId: number) => {
    setAggregations(aggregations?.filter((ag: any) => ag.id !== aggregationId));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
        <InputLabel sx={form_label}>Función</InputLabel>
        <Select
          reference={operatorRef}
          styles={selectStyles(operatorSelectedOption)}
          options={operatorOptions}
          name="Operador"
          onChange={handleChangeOperator}
          closeMenuOnSelect
          placeholder="Operador"
          isClearable
          defaultValue={operatorSelectedOption}
        />
      </Box>
      <Box sx={{ marginRight: "8px", width: "40%", marginTop: "auto" }}>
        <Box sx={{ display: "flex" }}>
          <InputLabel sx={form_label}>Columna</InputLabel>
        </Box>

        <Select
          reference={aggregationColumnRef}
          styles={selectStyles(aggregationColumnSelectedOption)}
          options={columns}
          name="Columna"
          onChange={handleChangeAggregationColumn}
          closeMenuOnSelect
          placeholder="Seleccionar columna"
          isClearable
          defaultValue={aggregationColumnSelectedOption}
        />
      </Box>
      <Box sx={{ width: "35%", marginTop: "auto" }}>
        <TextField
          id="newColumn"
          label={"Nombre de la nueva columna"}
          placeholder={"Nombre de la nueva columna"}
          size="small"
          multiline
          disabled={!aggregationColumnSelectedOption}
          variant="outlined"
          fullWidth
          value={newColumn}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeNewColumn(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
        />
      </Box>
      <DeleteIcon
        onClick={() => deleteAggregation(aggregation.id)}
        sx={{
          fontSize: "25px",
          color: "var(--blue)",
          cursor: "pointer",
          marginBottom: "5px",
          marginLeft: "3px",
        }}
      />
    </Box>
  );
};

export default Aggregation;
