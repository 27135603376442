import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import MergeIcon from '@mui/icons-material/Merge';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CategoryIcon from '@mui/icons-material/Category';
import LinkIcon from '@mui/icons-material/Link';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import TableViewIcon from '@mui/icons-material/TableView';
import TableChartIcon from '@mui/icons-material/TableChart';

export const icons: { [index: string]: React.ReactElement } = {
    nueva_fuente: <CreateNewFolderIcon sx={{fontSize: "28px"}}/>,
    merge: <MergeIcon sx={{transform: 'rotate(90deg)', fontSize: "32px"}} />,
    concat: <LinkIcon sx={{fontSize: "32px"}}/>,
    group_by: <CategoryIcon sx={{fontSize: "30px"}}/>,
    nueva_columna: <PlaylistAddIcon sx={{fontSize: "34px", transform: 'rotate(90deg) scaleY(-1)'}} />,
    eliminar_columna: <PlaylistRemoveIcon sx={{transform: 'rotate(90deg) scaleY(-1)', fontSize: "34px"}} />,
    modificar_columna: <BorderColorIcon sx={{fontSize: "28px", transform: 'rotate(90deg)', marginLeft:"5px"}}/>,
    renombrar_columnas: <SpellcheckIcon sx={{fontSize: "28px"}}/>,
    eliminar_filas: <DeleteSweepIcon sx={{fontSize: "28px"}}/>,
    filtrar: <FilterAltIcon sx={{fontSize: "28px"}}/>,
    dinamizar: <PivotTableChartIcon sx={{fontSize: "28px"}}/>,
    desdinamizar: <TableViewIcon sx={{fontSize: "28px"}}/>,
    setear_cabecera: <TableChartIcon sx={{fontSize: "28px"}}/>,
  };