import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

//Auth URLs
export const LOGIN_URL = "auth/login";
export const REFRESH_URL = "auth/refresh";
export const VERIFICATION_URL = "auth/verificar";
export const RESEND_VERIFICATION_URL = "auth/reenviar_codigo";
export const LOGOUT_URL = "auth/logout";

//Empresa URLs
export const GET_ALL_COMPANIES_URL = "empresa/?todos=true";
export const GET_ALL_COMPANIES_ADMIN_URL = "empresa/?admin=true";
export const COMPANY_URL = "empresa/";
export const PERIODS_URL = "empresa/filter/periodos";
export const GET_COMPANY_URL = (id: number | string | undefined) =>
  `flow/empresa/${id}`;

//Archivo Pipelines URLs
export const GET_FILE_INFO_URL = (
  archivo_id: number | undefined,
  hoja_id: number | undefined,
  no_header: boolean
) => `archivo/${archivo_id}/hoja/${hoja_id}?sin_cabecera=${no_header}`;
export const FILE_INFO_URL = "archivo/";
export const FILE_BY_ID = (id?: number) => `archivo/${id}`;
export const PIPELINE_URL = (id?: number) =>
  id ? `criterio/hoja?id=${id}` : "criterio/hoja";
export const GET_PIPELINES_URL = "criterios/";
export const POST_PIPELINE_URL = "criterios/";
export const PIPELINE_BY_ID_URL = (id: number | undefined) =>
  `criterios/${id}`;

//Esquemas URLs
export const SCHEMAS_URL = "esquema/";
export const EXECUTE_SCHEMA_URL = (id: number | undefined | string) =>
  `esquema/${id}/archivos`;
export const DELETE_SCHEMA_URL = (id: number | undefined) => `esquema/${id}`;
export const GET_OR_EDIT_SCHEMA_URL = (
  id: number | string | undefined | null
) => `esquema/${id}`;
export const COPY_SCHEMA_URL = (id_schema: number | undefined) =>
  `esquema/${id_schema}/duplicar`;
export const CONFIGURATIONS_BY_SCHEMA_URL = (id_schema: number | undefined) =>
  `esquema/${id_schema}/configuracion`;
export const SCHEMA_CONFIGURATION_BY_ID = (id_schema: number | undefined, id_conf: number | undefined) =>
  `esquema/${id_schema}/configuracion/${id_conf}`;


//Gestor de tareas
export const GET_TASKS_URL = "/tareas/?tipo=esquema";
export const GET_FLOW_TASKS_URL = "/tareas/?tipo=flow";
export const GET_REPORT_TASKS_URL = "/tareas/?tipo=informe";
export const GET_TASK_ERRORS_URL = (task_id: number) =>
  `tareas/${task_id}/informe`;
export const DELETE_TASK_URL = (task_id: number) =>
  `tareas/${task_id}/cancelar`;
export const GET_SCHEME_TASK_REPORT = (task_id: number) =>
  `tareas/${task_id}/informe?tipo=esquema`;

//Resumen de la información
export const REPORT_URL = "/informe/";
export const GET_REPORT_BY_TYPE = (type: string) => `/informe/?tipo=${type}`;
export const REPORT_BY_ID = (id: number | undefined) => `/informe/${id}`;
export const SHEET_INFO_BY_ID = (
  file_id: number | undefined,
  sheet_id: number | undefined
) => `/archivo/${file_id}/hoja/${sheet_id}/columnas`;

//Validación de codigos
export const GET_FILE_VALIDATION_PAGE = "informe/match/archivo";
export const POST_GENERATE_CODE_VALIDATION = "informe/match/generar";

//Flows
export const FLOW_URL = "flow/";
export const PUT_NAME_FLOW_URL = (id_flow: number) => `/flow/${id_flow}`;
export const POST_STEPPER_URL = (id_flow: number) => `flow/${id_flow}/stepper`;
export const STEPPER_URL = (id_flow: number, id_stepper: number) =>
  `flow/${id_flow}/stepper/${id_stepper}`;
export const GET_COLUMNS_FLOW_URL = (
  id_flow: number,
  id_stepper: number,
  id_item: number
) => `/flow/${id_flow}/stepper/${id_stepper}/item/${id_item}/columnas`;
export const POST_ITEM_URL = (id_flow: number, id_stepper: number) =>
  `/flow/${id_flow}/stepper/${id_stepper}/item`;
export const GET_FLOW_URL = (id: number | string | undefined) => `flow/${id}`;
export const CALC_FLOW_URL = (id: number | string | undefined) =>
  `flow/${id}/calcular`;
export const ITEM_URL = (
  id_flow: number,
  id_stepper: number,
  id_item: number | undefined
) => `flow/${id_flow}/stepper/${id_stepper}/item/${id_item}`;
export const DELETE_FLOW_URL = (id: number | undefined) => `flow/${id}`;
export const DELETE_STEPPER_URL = (id_flow: number, id_stepper: number | "") =>
  `flow/${id_flow}/stepper/${id_stepper}`;
export const VIEW_RESULTS_URL = (
  id_flow: number,
  id_stepper: number,
  id_tem: number
) => `flow/${id_flow}/stepper/${id_stepper}/item/${id_tem}/preview`;
export const COPY_FLOW_URL = (id_flow: number | undefined) =>
  `flow/${id_flow}/duplicar`;
export const CHANGE_FLOW_FONTS_URL = (id_flow: number | undefined) =>
  `flow/${id_flow}/cambiar-fuentes`;
export const DUPLICATE_STEPPER_URL = (id_flow: number, id_stepper: number) =>
  `flow/${id_flow}/stepper/${id_stepper}/duplicar`;
export const FLOW_CONFIGURATION = (id_flow: number) =>
  `flow/${id_flow}/configuracion`;
export const FLOW_CONFIGURATION_BY_ID = (id_flow: number, id_conf: number) =>
  `flow/${id_flow}/configuracion/${id_conf}`;

// Data Management
export const DATA_URL = "recursos/";
export const DOWNLOAD_FILE = "recursos/descargar";
export const RESOURCE_LABEL_URL = "empresa/etiqueta";
export const RESOURCES_BY_LABEL_URL = (id_label: number) =>
  `recursos/etiquetas/id?=${id_label}`;
export const RESOURCE_LABEL_BY_ID_URL = (id_label: number) =>`empresa/etiqueta/${id_label}`;
export const GET_CONNECTIONS_URL = "recursos/externos";
export const EXTERNAL_CONNECTIONS_BY_ID = (id_resource: number) =>`/recursos/externos/${id_resource}`
export const POST_CONNECTION_URL = "recursos/externos/conexion";
export const POST_EXTERNAL_CONNECTION_RESOURCE_URL = "recursos/externos/carga";
export const ALLOWED_FILES_URL = "recursos/archivos-permitidos";
export const GET_EXTERNAL_CONNECTION_COLUMNS = (id_resource: number, table: string, catalog?: string, schema?: string) =>
  `recursos/externos/databricks/${id_resource}/columnas?tabla=${table}${catalog ? `&catalogo=${catalog}` : ''}${schema ? `&esquema=${schema}` : ''}`; 

export const EXTERNAL_CONNECTION_CONFIGURATION = `recursos/externos/databricks/configuracion`;
export const GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS = (id_resource: number) =>`/recursos/externos/databricks/${id_resource}/configuraciones`
export const EXTERNAL_CONNECTION_CONFIGURATION_BY_ID = (id_conf: number) => `recursos/externos/databricks/configuracion/${id_conf}`;
export const RELOAD_EXTERNAL_RESOURCE = (id_resource: number) =>`/recursos/${id_resource}/externos/actualizar`

//axios instance
export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
