import "./styles.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import logoPhi from "../../assets/logoPhi.png";
import useApi from "../../hooks/useApi";
import { RESEND_VERIFICATION_URL, VERIFICATION_URL } from "../../api/axios";
import {texts} from "../../texts";
import useInfoContext from "../../hooks/useInfoContext";
import useAuth from "../../hooks/useAuth";

/**
 * @component
 * @name Verification
 * @description - Componente que muestra el formulario para el two-factor authentication.
 * @returns {JSX.Element}
 * @category Login
 */

const Verification = () => {
  const passwordRef: any = useRef();
  const navigate = useNavigate();
  const { auth, setAuth }: any = useAuth();
  const [
    logout,
    isLoadingLogout,
    openLogoutSnackbar,
    handleToggleLogoutSnackbar,
    messageLogoutSnackbar,
    severityLogout,
  ] = useLogout();
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const { loggedIn, setLoggedIn }: any = useInfoContext();

  const handleSuccesAuthCode = () => {
    setCode("");
    navigate("/empresa");
  };

  const handleSendAuthCode = (e: any) => {
    e.preventDefault();
    submitCode();
  };

  const { isLoading: isLoadingPostCode, callApi: submitCode } = useApi(
    VERIFICATION_URL,
    "POST",
    texts.verificationCode.codes,  
    { codigo: code },
    handleSuccesAuthCode,
    undefined,
    false
  );

  const {
    data: postResendCodeResponse, 
    error: errorResendPostCode,  //deberíamos gestionar este error
    isLoading: isLoadingResendPostCode,
    callApi: resendCode,
  } = useApi(
    RESEND_VERIFICATION_URL,
    "POST",
    texts.verificationCode.resend.codes,    
    undefined,
    undefined,
    undefined,
    true
  );

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    passwordRef.current.focus();
  }, []);

  const handlePassword = () => {
    passwordRef.current.type = showPassword ? "password" : "text";
    setShowPassword((prev) => !prev);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setAuth({});
    navigate("/login/");
    //logout();  Por ahora lo dejamos comentado porque el logout pide credenciales y éstas aún no están
  };

  return (
    <div className="verification-container">
      <div className="verification_form">
        <div className="verification_logo-container">
          <img className="verification_logo" src={logoPhi} alt="logo" />
        </div>

        <h2 className="verification_title">Verificación de 2 pasos</h2>
        <p className="verification_description">
          Por favor, completar con el código que se ha enviado a su correo
          electrónico. No comparta este código con nadie.
        </p>
        <form
          className="verification_form_box"
          onSubmit={handleSendAuthCode}
          method="POST"
        >
          <div className="verification_password_container">
            <input
              className="verification_form_input"
              id="password"
              type="text"
              ref={passwordRef}
              required
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter the code"
            />
            {showPassword ? (
              <VisibilityIcon
                className="verification_password_icon"
                onClick={handlePassword}
              />
            ) : (
              <VisibilityOffIcon
                className="verification_password_icon"
                onClick={handlePassword}
              />
            )}
          </div>
          {isLoadingPostCode && (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
          <p className="verification_successmsg">
            Se envió el código a su casilla de correo electrónico.
          </p>
          <p className="verification_resend" onClick={() => resendCode()}>
            No recibí el código, volver a enviar.
          </p>
          <Stack direction="row" mb="15px">
            <Button
              title="Cancelar"
              color="grey"
              type="button"
              onClick={handleLogout}
            />
            <Button
              title="Ingresar"
              color="blue"
              type="submit"
              disabled={isLoadingPostCode}
            />
          </Stack>
        </form>
      </div>
      <SimpleBackdrop open={isLoadingLogout} message="Cerrando sesión..." />
      <CustomSnackbar
        open={openLogoutSnackbar}
        handleClose={handleToggleLogoutSnackbar}
        message={messageLogoutSnackbar}
        severity={severityLogout}
      />
    </div>
  );
};

export default Verification;
