import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import Select from "../../../../forms/Select";
import { selectStyles } from "../../../../../styles/select.styles";
import { form_label } from "../../../../../styles/app-styles";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../../forms/types";
import ShadowBox from "../../../../flowConfiguration/Modals/ShadowBox";
import ToggleButton from "../../../../flowConfiguration/Modals/NewColumn/ToggleButton";
import { AddButton, Button } from "../../../../buttons";
import Aggregation from "./Aggregation";

interface OperationsTabProps {
  columns: any;
  aggregations: any[];
  setAggregations: React.Dispatch<React.SetStateAction<any[] >>;
  confToEdit?: any
}

const OperationsTab = ({
  columns,
  aggregations,
  setAggregations,
  confToEdit
}: OperationsTabProps) => {
  const [newColumn, setNewColumn] = useState<string>(confToEdit ? aggregations[0]?.columna_nueva:"");

  const aggregationColumnRef: any = useRef(null);
  const operatorRef: any = useRef(null);

  const handleChangeAggregationColumn = (newValue: SingleValue<IdOption>, actionMeta: ActionMeta<IdOption>)=> {
    aggregationColumnChangeHandler(newValue, actionMeta)
    const newAggregation = {
      ...aggregations[0],
      columna_valor_funcion: newValue?.value,
    };
    setAggregations(
      aggregations.map((a: any) =>
        a.id === newAggregation.id ? newAggregation : a
      )
    );
  }

  const handleChangeOperator = (newValue: SingleValue<IdOption>, actionMeta: ActionMeta<IdOption>)=> {
    operatorChangeHandler(newValue, actionMeta)
    if(aggregations?.length > 0){
      const newAggregation = {
        ...aggregations[0],
        funcion: newValue?.value,
      };
      setAggregations(
        aggregations.map((a: any) =>
          a.id === newAggregation.id ? newAggregation : a
        )
      );
    }else{
      const newAggregation = {
        id: Math.random(),
        funcion: newValue?.value,
        columna_valor_funcion: "",
        columna_nueva: "",
      };
      setAggregations([newAggregation]);
    }
  }

  const handleChangeNewColumn = (column: string)=> {
    setNewColumn(column)
    const newAggregation = {
      ...aggregations[0],
      columna_nueva: column,
    };
    setAggregations(
      aggregations.map((a: any) =>
        a.id === newAggregation.id ? newAggregation : a
      )
    );
  }

  const [
    aggregationColumnSelectedOption,
    setaggregationColumnSelectedOption,
    aggregationColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit ? (aggregations?.length > 0 ? {value: aggregations[0].columna_valor_funcion,label:aggregations[0].columna_valor_funcion }: undefined) :undefined);

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(undefined, undefined, confToEdit ? (aggregations?.length > 0 ? {value: aggregations[0].funcion,label:aggregations[0].funcion }: undefined) :undefined);

  const operatorOptions = [
    { value: "ABS", label: "ABS" },
    { value: "AVG", label: "AVG" },
    { value: "COUNT", label: "COUNT" },
    { value: "MAX", label: "MAX" },
    { value: "MIN", label: "MIN" },
    { value: "ROUND", label: "ROUND" },
    { value: "SUM", label: "SUM" },
    { value: "MONTH", label: "MONTH" },
    { value: "DAY", label: "DAY" },
    { value: "YEAR", label: "YEAR" },
  ];

  const addAggregation = () => {
   aggregations && aggregations?.length > 0 && setAggregations(
     [
            ...aggregations,
            {
              id: Math.random(),
              funcion: undefined,
              columna_valor_funcion: "",
              columna_nueva: "",
            },
          ]
    );
  };

  const isFormIncompleted = () => {
    return (
      aggregationColumnSelectedOption === undefined ||
      operatorSelectedOption === undefined
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ShadowBox margin>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Función</InputLabel>
            <Select
              reference={operatorRef}
              styles={selectStyles(operatorSelectedOption)}
              options={operatorOptions}
              name="Operador"
              onChange={handleChangeOperator}
              closeMenuOnSelect
              placeholder="Operador"
              isClearable
              defaultValue={operatorSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "40%", marginTop: "auto" }}>
            <Box sx={{ display: "flex" }}>
              <InputLabel sx={form_label}>Columna</InputLabel>
            </Box>

            <Select
              reference={aggregationColumnRef}
              styles={selectStyles(aggregationColumnSelectedOption)}
              options={columns}
              name="Columna"
              onChange={handleChangeAggregationColumn}
              closeMenuOnSelect
              placeholder="Seleccionar columna"
              isClearable
              defaultValue={aggregationColumnSelectedOption}
              isDisabled={aggregations?.length === 0}
            />
          </Box>
          <Box sx={{ width: "35%", marginTop: "auto" }}>
            <TextField
              id="newColumn"
              label={"Nombre de la nueva columna"}
              placeholder={"Nombre de la nueva columna"}
              size="small"
              multiline
              disabled={aggregations?.length === 0}
              variant="outlined"
              fullWidth
              value={newColumn}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeNewColumn(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{  marginLeft:"auto", width:"169px"}}>
          <Button
            onClick={addAggregation}
            disabled={isFormIncompleted()}
            title={"Agregar operación"}
            type="button"
            color="blue-greeny"
          />
        </Box>
        <Box sx={{ marginTop: "10px" }}>
          {aggregations && aggregations.length > 1 && aggregations?.slice(1).map((aggregation: any) => {
            return (
              <Aggregation
                key={aggregation.id}
                aggregation={aggregation}
                aggregations={aggregations}
                setAggregations={setAggregations}
                columns={columns}
              />
            );
          })}
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default OperationsTab;
