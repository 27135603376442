import Stack from "@mui/material/Stack";
import CustomNoRowsOverlay from "./NoRows";
import CustomToolbar from "./CustomToolbar";
import {
  GridFilterInputValueProps,
  GridFilterOperator,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import { getUniqueValues } from "../../utils/util";

//Este input lo utilizamos para pasarselo a los filtros personalizados
const FilterTextField = (props: GridFilterInputValueProps) => {
  const { item, applyValue, focusElementRef } = props;

  const inputRef: React.Ref<any> = React.useRef(null);

  const handleFilterChange: any = (event: any, newValue: any) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <TextField
      id="filterInput"
      sx={{ margin: "auto" }}
      label="Valor del filtro"
      ref={inputRef}
      placeholder="Valor del filtro"
      size="small"
      variant="standard"
      value={item.value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleFilterChange(event, event.target.value);
      }}
    />
  );
};

interface SimpleTableProps {
  rows: any;
  columns: any;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  filterModel?: any;
  setFilterModel?: any;
}

const FilterTable = ({
  rows,
  columns,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  filterModel,
  setFilterModel,
}: SimpleTableProps) => {
  const AutoCompleteFilterTextField =  memo((props: any) => {
    const { item, applyValue, focusElementRef } = props;
    const [inputValue, setInputValue] = useState<string>(item.value);

    useEffect(() => {
      if (focusElementRef && focusElementRef.current) {
        focusElementRef.current.focus();
      }
    }, [focusElementRef]);

    // Obtener valores únicos de la columna
    const uniqueValues: any = getUniqueValues(item.columnField, rows);

      // Filtrar los valores únicos basados en el texto del input
  const filteredUniqueValues = inputValue ? uniqueValues.filter((value: any) =>
    value?.toLowerCase().includes(inputValue?.toLowerCase()) 
  ) : uniqueValues;

    const handleChange = (event: any, newValue: string | null) => {
      if (newValue !== null && newValue !== item.value) {
        applyValue({ ...item, value: newValue });
        setInputValue(newValue)
      }
    };

    return (
      <Autocomplete
        options={filteredUniqueValues}
        value={inputValue}
        onInputChange={(handleChange)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Valor del filtro"
            variant="standard"
            size="small"
            placeholder="Valor del filtro"
            inputRef={focusElementRef}
          />
        )}
      />
    );
  });



  const operators: GridFilterOperator[] = [
    //...getGridStringOperators(),
    ...getGridStringOperators().filter((operator)=> operator.value !== "isAnyOf").map((operator) => ({
      ...operator,
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    })),
    ...getGridStringOperators().filter((operator)=> operator.value === "isAnyOf"),
    {
      label: "no es igual",
      value: "no_es_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return params.value.toString() !== filterItem.value.toString();
        };
      },
      InputComponent: AutoCompleteFilterTextField

    },
    {
      label: "no contiene",
      value: "no_contiene",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value.toString().includes(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no comienza con",
      value: "no_comienza_con",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value
            .toString()
            .startsWith(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no termina con",
      value: "no_termina_con",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return !params.value.toString().endsWith(filterItem.value.toString());
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
  ];

  const numericOperators: GridFilterOperator[] = [
    {
      label: "es mayor",
      value: "es_mayor",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) > parseInt(filterItem.value);
        };
      },
      InputComponent: AutoCompleteFilterTextField
    },
    {
      label: "es mayor o igual",
      value: "es_mayor_o_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) >= parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "es menor",
      value: "es_menor",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) < parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "es menor o igual",
      value: "es_menor_o_igual",
      getApplyFilterFn: (filterItem: any) => {
        if (
          !filterItem.columnField ||
          !filterItem.value ||
          !filterItem.operatorValue
        ) {
          return null;
        }
        return (params: any): boolean => {
          return parseInt(params.value) <= parseInt(filterItem.value);
        };
      },
      InputComponent: (props: any) => (
        <AutoCompleteFilterTextField {...props} />
      ),
    },
    {
      label: "no es cero",
      value: "no_es_cero",
      getApplyFilterFn: (filterItem: any) => {
        return (params: any): boolean => {
          return parseInt(params.value) !== 0;
        };
      },
      requiresFilterValue: false,
    },
    ...operators,
  ];

  return (
    <Stack style={{ height: "480px", width: "100%" }}>
      <DataGridPro
        rows={rows}
        columns={columns.map((col: any) => {
          return {
            ...col,
            filterOperators:
              col.type !== "decimal" && col.type != "entero"
                ? operators
                : numericOperators,
          };
        })}
        components={{
          Toolbar: () => (
            <CustomToolbar
              toolbar={toolbar}
              columnsButton={columnsButton}
              filterButton={filterButton}
              densitySelector={densitySelector}
              exportButton={exportButton}
            />
          ),
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={true}
        checkboxSelection={false}
        disableSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
        }}
      />
    </Stack>
  );
};

export default FilterTable;
