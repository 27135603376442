import { InputLabel, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import Dialog from "../../../dialog/Dialog";
import { Button } from "../../../buttons";
import { form_label } from "../../../../styles/app-styles";
import useApi from "../../../../hooks/useApi";
import {  GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS } from "../../../../api/axios";
import { texts } from "../../../../texts";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { selectStyles } from "../../../../styles/select.styles";
import Select from "../../../forms/Select";
import CreateIngestionConfigurationModal from "./CreateIngestionConfigurationModal/CreateIngestionConfigurationModal";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import IngestionConfigurationResume from "./IngestionConfigurationResume";
import ManageIngestionConfigurations from "./ManageIngestionConfigurations";

interface IngestionConfigurationModalProps {
  resource_id: number,
  table: string,
  schema?:string,
  catalog?:string,
  connectionType: string;
  open: boolean;
  handleClose: (resourceTabType?: string) => void;
  handleAcceptConf: (conf_id: number | string) =>void
}

const IngestionConfigurationModal = ({
  open,
  handleClose,
  handleAcceptConf,
  resource_id,
  table,
  schema,
  catalog,
  connectionType
}: IngestionConfigurationModalProps) => {

  /*************************** CARGA DE DATOS ***************************/
  const ingestionConfigurationRef: any = useRef(null);

  const [
    ingestionConfigurationSelectedOption,
    setIngestionConfigurationSelectedOption,
    ingestionConfigurationChangeHandler,
  ] = useSingleSelect();

  
  /**************************** LLAMADAS ************************************************/ 
  
  const {
    data: configurations,
    isLoading: isLoadingConfigurations,
    callApi: getConfigs,
    error: errorGetConfigs,
  } = useApi(
    GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS,
    "GET",
    texts.dataManagement.getExternalConnectionConfigurations.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if(open){
      getConfigs(GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS(resource_id))
    }
   }, [open]);

   const configurationsOptions = configurations?.length > 0 ? [{value:"Default", label: "Default (todas las filas, todas las columnas)"}, ...configurations.filter((config: any)=>config.tabla === table).map((conf: any)=>{return {value: conf.id, label:conf.nombre}})] :  [{value:"Default", label: "Default (todas las filas, todas las columnas)"}] 
  /**************************** LIMPIEZA DE DATOS Y CIERRE *******************************/

  const handleAccept = () => {
    handleAcceptConf(ingestionConfigurationSelectedOption?.value as number)
    cleanAndClose();
  };

  const cleanAndClose = () => {
    setIngestionConfigurationSelectedOption(undefined)
    handleClose();
  };

  const isFormCompleted = () => {
    return (
      ingestionConfigurationSelectedOption !== undefined &&
      ingestionConfigurationSelectedOption !== null
    );
  };

  return (
    <Dialog
      open={open}
      title={"Elegir configuración de ingesta"}
      handleClose={cleanAndClose}
      maxWidth="xl"
    >
        <SimpleBackdrop
          open={isLoadingConfigurations}
          message={texts.dataManagement.getExternalConnectionConfigurations.loading}
        />
      <Stack sx={{ width: "50vw", height: "30vw" }}>
        <Stack marginLeft={"auto"} direction={"row"} >
          <ManageIngestionConfigurations configurations={configurations} refetchConfigurations={getConfigs} resource_id ={resource_id} table={table} catalog={catalog} schema={schema} connectionType={connectionType}/>
          <CreateIngestionConfigurationModal configurationsNames={configurations} refetchConfigurations={getConfigs} resource_id ={resource_id} table={table} catalog={catalog} schema={schema} connectionType={connectionType}/>
        </Stack>
        <InputLabel sx={form_label}>
          Elija la configuración de ingesta
        </InputLabel>
        <Select
          reference={ingestionConfigurationRef}
          styles={selectStyles(ingestionConfigurationSelectedOption)}
          options={configurationsOptions}
          name="Configuracion"
          onChange={ingestionConfigurationChangeHandler}
          closeMenuOnSelect={true}
          placeholder="Seleccionar configuración de ingesta"
          isClearable
          defaultValue={ingestionConfigurationSelectedOption}
        />
       {ingestionConfigurationSelectedOption && ingestionConfigurationSelectedOption?.value !== "Default"  && <IngestionConfigurationResume configuration={configurations.find((conf: any)=> conf.id === ingestionConfigurationSelectedOption?.value)}/>}
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={cleanAndClose}
            color="light-grey"
          />
          <Button
            title="Cargar en datalake"
            type="button"
            onClick={handleAccept}
            color="blue"
            disabled={!isFormCompleted()}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default IngestionConfigurationModal;
