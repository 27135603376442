import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ConstructionIcon from "@mui/icons-material/Construction";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import LaunchIcon from '@mui/icons-material/Launch';
import EditNoteIcon from '@mui/icons-material/EditNote';

interface SpeedDialTooltipOpenProps {
  id: number
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  handleInfo?: (e: any, id: number) => void;
  handleDelete?: (e: any, id: number) => void;
  handleExport?: (e: any, id: number) => void;
  handleEditFile?: (e: any, id: number) => void;
}

export default function SpeedDialTooltipOpen({
  id,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  handleExport,
  handleEditFile
}: SpeedDialTooltipOpenProps) {
  const actions: any[] = [];

  const buildActions = () => {
    const actionOptions = [
      {
        icon: <EditIcon sx={{ fontSize: "24px" }} />,
        name: "Editar",
        action: handleEdit,
        active: true,
      },
      {
        icon: <FileCopyIcon sx={{ fontSize: "24px" }} />,
        name: "Duplicar",
        action: handleCopy,
        active: handleCopy !== undefined
      },
      {
        icon: <ZoomInIcon sx={{ fontSize: "24px" }} />,
        name: "Ver más",
        action: handleInfo,
        active: handleInfo !== undefined
      },
      {
        icon: <DeleteForeverIcon sx={{ fontSize: "24px" }} />,
        name: "Eliminar",
        action: handleDelete,
        active: handleDelete !== undefined
      },
      {
        icon: <LaunchIcon sx={{ fontSize: "24px" }} />,
        name: "Exportar",
        action: handleExport,
        active: handleExport !== undefined
      },
      {
        icon: <EditNoteIcon sx={{ fontSize: "24px" }} />,
        name: "Editar archivo",
        action: handleEditFile,
        active: handleEditFile !== undefined
      },
    ];
    handleEdit && actions.push(actionOptions[0]);
    handleEditFile && actions.push(actionOptions[5]);
    handleCopy && actions.push(actionOptions[1]);
    handleInfo && actions.push(actionOptions[2]);
    handleDelete && actions.push(actionOptions[3]);
    handleExport && actions.push(actionOptions[4]);

  };

  buildActions();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 0.5 }}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        icon={<ConstructionIcon sx={{ fontSize: "30px" }} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="left"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          "& .MuiFab-sizeLarge": {
            backgroundColor: "var(--blue-greeny)",
            width: "45px",
            height: "45px",
            marginLeft: "0px",
          },
          "& .MuiFab-sizeSmall": {
            backgroundColor: "#cccfd9",
            margin: "4px",
            width: "34px",
            height: "28px",
          },
          "& .MuiFab-sizeLarge:hover": { backgroundColor: "#16738a" },
          "& .MuiSpeedDial-actions": {
            backgroundColor: "white"
          },
          "& .MuiSpeedDial-actionsClosed": {
            backgroundColor: "transparent"
          }
        }}
      >
        {actions.map((action) => (
          action.active &&
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={(e: any) => action.action?.(e, id)}
            tooltipPlacement="top-start"
            sx={{ margin: 0 }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
