import { useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import {
  EXTERNAL_CONNECTION_CONFIGURATION_BY_ID,
  GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS,
} from "../../../../api/axios";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import { Button } from "../../../buttons";
import Dialog from "../../../dialog/Dialog";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useDialog from "../../../../hooks/useDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { ConfirmationModal } from "../../../dialog";
import IngestionConfigurationDetail from "./IngestionConfigurationDetail";
import EditConfigurationModal from "./EditConfigurationModal";

interface ManageIngestionConfigurationModalProps {
  resource_id: number;
  refetchConfigurations: (url: string) => void;
  configurations: any[];
  connectionType: string;
  table: string;
  schema?: string;
  catalog?: string;
}

const ManageIngestionConfigurationModal = ({
  resource_id,
  refetchConfigurations,
  configurations,
  table,
  schema,
  catalog,
  connectionType,
}: ManageIngestionConfigurationModalProps) => {
  const [openConfigurationModal, toggleConfigurationModal] = useDialog();
  const [openConfigurationDetail, toggleConfigurationDetail] = useDialog();
  const [openEditConfiguration, toggleEditConfigurationModal] = useDialog();
  const [confToDelete, setConfToDelete] = useState<any>();
  const [confToEdit, setConfToEdit] = useState<any>();
  const [confDetail, setConfDetail] = useState<any>();
  const [openConfirmDeleteModal, toggleConfirmDeleteModal] = useDialog();

  /*************************** LLAMADAS ***************************/

  const {
    isLoading: isLoadingDeleteConfiguration,
    callApi: deleteConfiguration,
    error: errorDeleteConfiguration,
  } = useApi(
    EXTERNAL_CONNECTION_CONFIGURATION_BY_ID(confToDelete?.id),
    "DELETE",
    texts.dataManagement.deleteExternalConnectionConfiguration.codes,
    undefined,
    () =>
      refetchConfigurations(
        GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS(resource_id)
      ),
    undefined,
    true
  );

  /*************************** HANDLERS **************************/

  const handleEditConf = (conf: any) => {
    setConfToEdit(conf);
    toggleEditConfigurationModal();
  };

  const handleCloseEditConfModal = () => {
    toggleEditConfigurationModal();
    setConfToEdit(undefined)
  };

  const handleDeleteConfirmation = (conf: any) => {
    setConfToDelete(conf);
    toggleConfirmDeleteModal();
  };

  const handleClickOnConf = (conf: any) => {
    setConfDetail(conf);
    toggleConfigurationDetail();
  };

  const handleAcceptDeleteConfirmation = () => {
    toggleConfirmDeleteModal();
    deleteConfiguration();
  };
  return (
    <>
      <Stack direction={"row"} alignItems="center">
        <Button
          title={"Gestionar configuraciones"}
          type="button"
          onClick={toggleConfigurationModal}
          color="blue-greeny"
          width={190}
        />
      </Stack>

      <Dialog
        open={openConfigurationModal}
        handleClose={toggleConfigurationModal}
        maxWidth="xl"
        title={"Gestionar configuraciones"}
      >
        <SimpleBackdrop
          open={isLoadingDeleteConfiguration}
          message={
            texts.dataManagement.deleteExternalConnectionConfiguration.loading
          }
        />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50vw",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Configuraciones de ingesta
            </Typography>
            {configurations?.map((conf: any) => {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0"
                  padding="0"
                  width={"97%"}
                  key={conf.id}
                  sx={{
                    border: "1px solid rgb(0 0 0 / 20%)",
                    marginBottom: "5px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", marginLeft: "5px" }}>
                    {conf.nombre}
                  </Typography>
                  <Box>
                    <Tooltip title={"Ver detalle de configuración"}>
                      <SearchIcon
                        onClick={() => {
                          handleClickOnConf(conf);
                        }}
                        sx={{
                          color: "var(--blue) !important",
                          cursor: "pointer",
                          transition: "all 1s linear",
                          "& :hover": { color: "var(--icon-error)" },
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={"Editar configuración"}>
                      <EditIcon
                        onClick={() => {
                          handleEditConf(conf);
                        }}
                        sx={{
                          color: "var(--blue) !important",
                          cursor: "pointer",
                          transition: "all 1s linear",
                          "& :hover": { color: "var(--icon-error)" },
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={"Eliminar configuración"}>
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteConfirmation(conf);
                        }}
                        sx={{
                          color: "var(--blue) !important",
                          cursor: "pointer",
                          transition: "all 1s linear",
                          "& :hover": { color: "var(--icon-error)" },
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Stack
            direction="row"
            sx={{
              alignSelf: "center",
              marginTop: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              title="Cerrar"
              type="button"
              onClick={toggleConfigurationModal}
              color="light-grey"
            />
          </Stack>
        </Box>
        <IngestionConfigurationDetail
          open={openConfigurationDetail}
          handleClose={toggleConfigurationDetail}
          configuration={confDetail}
        />
        <EditConfigurationModal
          open={openEditConfiguration}
          handleClose={handleCloseEditConfModal}
          resource_id={resource_id}
          refetchConfigurations={refetchConfigurations}
          configurations={configurations}
          connectionType={connectionType}
          table={table}
          schema={schema}
          catalog={catalog}
          confToEdit={confToEdit}
        />
        <ConfirmationModal
          open={openConfirmDeleteModal}
          handleClose={toggleConfirmDeleteModal}
          handleAccept={handleAcceptDeleteConfirmation}
          message={`eliminar la configuración ${confToDelete?.nombre}`}
          title={"Eliminar configuración de ingesta"}
        />
      </Dialog>
    </>
  );
};

export default ManageIngestionConfigurationModal;
