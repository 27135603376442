import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import { Tooltip } from "@mui/material";
import { convertArrayToText } from "../../utils/util";
import { truncateString } from "../pipelines/utils";

const SummaryCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}: any) => {
  return (
    <>
      <Stack sx={{ padding: "5px 10px 10px 10px", minHeight: "240px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item?.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--blue)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "18px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 35)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "5px 0" }} />
        {showInfo && (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--blue)"
            >
              Archivo:{" "}
              <span style={{ color: "var(--light-grey)" }}>
                {item?.archivo?.nombre}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--blue)"
            >
              Hoja:{" "}
              <span style={{ color: "var(--light-grey)" }}>
              {item.hoja.label}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--blue)"
            >
              Agrupar por columnas:{" "}
              <span style={{ color: "var(--light-grey)" }}>
              {`${convertArrayToText(item.columnas_agrupar.map((col: any)=>{return col.label}))}`}
              </span>
            </Typography>
            
          </>
        )}
 
      </Stack>
      <SpeedDialTooltipOpen
        id={item.id}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        handleInfo={handleInfo} 
        handleDelete={handleDelete}
      />
    </>
  );
};

export default SummaryCard;
