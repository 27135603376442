export const glossaryTexts = [
  {
    title: "Pipeline",
    summary: "Conjunto de transformaciones que se aplican en una hoja.",
    detail:
      "Un pipeline es un conjunto de transformaciones determinadas que se pueden aplicar en una hoja de un archivo, y que más adelante se pueden replicar en otras hojas creando un esquema. Las transformaciones posibles en un pipeline son: Setear cabecera (se define qué fila de la tabla se convertirá en la nueva cabecera), renombrar columnas (permite cambiar el nombre de cada una de las columnas) y eliminar filas (permite seleccionar de la tabla las filas que se desean eliminar).  Para crear un pipeline, ir a la opción de menú Consolidación de archivos -> administración de pipelines -> click en el ícono '+'. En primer lugar se debe pegar la url de un archivo y elegir una hoja, la cual se tomará como base de ejemplo para crear el pipeline.  Tener en cuenta que la creación de un pipeline NO lo ejecutará, sino que se guardará dicha configuración para que luego se pueda utilizar en la creación de un esquema. Al crear el pipeline se puede visualizar un resumen de la configuración realizada y se deberá colocar un nombre al guardar para luego poder identificarlo en la creación de un esquema.  Desde la vista de administración de pipelines se puede editar o eliminar un pipeline.",
  },
  {
    title: "Esquema",
    summary:
      "Configuración en la que se especifican las hojas sobre las cuales se ejecutará cada pipeline.",
    detail:
      "Un esquema almacena una configuración en la que se especifican las hojas sobre las cuales se ejecutará cada pipeline seleccionado.  Además, permite elegir si se desean incluir o excluir dichas hojas para ese pipeline.  Para crear un esquema, ir a la opción de menú Consolidación de archivos -> administración de esquemas -> click en el ícono '+'. En primer lugar se debe pegar la url de un archivo, el cual se tomará como base de ejemplo para crear el esquema.  Tener en cuenta que la creación de un esquema NO lo ejecutará, sino que se guardará dicha configuración para que luego se pueda utilizar en la ejecución de un esquema. Al crear se deberá colocar un nombre al guardar para luego poder identificarlo en la ejecución de un esquema.  Desde la vista de administración de esquemas se puede editar o eliminar un esquema.",
  },
  {
    title: "Ejecución de esquema",
    summary: "Aplica un esquema sobre un archivo o varios archivos de un directorio.",
    detail: "Para ejecutar un esquema, ir a la opción de menú Consolidación de archivos -> ejecución de esquema. Primero, se deberá elegir si el esquema se aplicará sobre un archivo o un directorio.  Si se opta por la opción directorio, se desplegará una tabla con los archivos contenidos en dicho directorio.  A continuación, se debe seleccionar sobre qué archivos se aplicarán las transformaciones contenidas en los pipelines del esquema a ejecutar.",
  },
  {
    title: "Ítem",
    summary: "Cada una de las transformaciones que es posible aplicar en un paso.",
    detail: "Un ítem es un elemento de un paso, es decir una transformación.  Hay distintos tipos de ítems, según su categoría. Entre ellos están: Nueva fuente, Nueva columna, eliminar columna, modificar columna, eliminar filas, filtrar, agrupar, combinar y concatenar. Cada ítem se puede agregar y configurar según la necesidad haciendo click en el ítem de la barra superior; también se puede volver a editar, eliminar y ver su vista previa (donde sólo se mostrarán 25 filas, pero NO implica el cálculo completo) selecionando los íconos que aparecen al hacer click en el nombre del ítem ubicado en el paso. El cálculo parcial para cada ítem se genera cada vez que éste se guarda o edita; si surge algún error en este cálculo, el ítem se mostrará en rojo y no se podrán agregar más items hasta haber solucionado el problema."
  },
  {
    title: "Paso",
    summary: "Conjunto de transformaciones aplicadas a un archivo o combinación de éstos, dentro de un flujo. Es decir, cada una de las etapas de un flujo.",
    detail: "Cuando las transformaciones que se desean aplicar son más complejas y/o se desean aplicar transformaciones sobre distintos archivos o combinación de éstos a la vez, se procede a crear un flujo.  Luego dentro del flujo se crearán los pasos. Para crear un flujo, ir a la opción de menú Administración de flujos -> click en el ícono '+'.  Colocar un nombre para el flujo, y hacer click en la opción del menú superior 'Nueva fuente'. Esto creará un paso, dentro del cual se podrán ir agregando distintas transformaciones (ítems).  La opciones del menú 'Nueva fuente', 'Merge' y 'Concat' son las que crearán nuevos pasos.  Para modificar o eliminar los ítems de un paso, o bien eliminar el paso, éste debe estar seleccionado.  Para seleccionar un paso, se debe hacer click en el ícono de editar (lápiz), y para eliminarlo, en el ícono de 'Eliminar' (cesto de basura), ambos ubicados a la izquierda del paso. Otro aspecto a tener en cuenta es que para crear un nuevo paso 'Merge' o 'Concat', deben haber como mínimo dos pasos creados.  La creación de un nuevo paso implica nombrarlo para poder identificarlo en algunas configuraciones.",
  },
  {
    title: "Flujo",
    summary: "Configuración en la que se especifican los pasos que se aplican sobre archivos, o combinación de ellos, y sus configuraciones.",
    detail: "Un flujo es un conjunto de pasos con sus transformaciones y la configuración de cada una de ellas.  Un flujo puede contener ilimitada cantidad de pasos. Para crear un flujo, ir a la opción de menú Administración de flujos -> click en el ícono '+'. Al colocar un nombre para el flujo, ya queda guardado. El flujo se va autoguardando cada vez que se crea o edita un ítem.  Para volver a la vista de administración, hacer click en el botón 'Cerrar' ubicado en la parte superior derecha. Si el flujo no se cierra, quedará abierto para poder seguir modificándolo cada vez que se vuelva a esa pantalla.  Para calcular el flujo completo, hacer click en el botón 'Calcular flujo'. Este botón redirige a la página 'Gestor de tareas' donde se podrá visualizar el estado del cálculo. Desde la vista de administración de flujos se puede editar o eliminar un flujo."    
}
];
