import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ColorTabsProps } from "./types";

export default function ColorTabs({
  value,
  handleChange,
  tabs,
  marginBottom,
  fontSize
}: ColorTabsProps) {
  return (
    <Box sx={{ width: "100%", marginBottom: marginBottom || "60px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs"
        sx={{
          "& .MuiTab-textColorPrimary": { color: "var(--light-blue)" },
          "& .MuiTabs-indicator": { backgroundColor: "#f90d4a" },
          "& .MuiTab-textColorPrimary.MuiTab-root.Mui-selected": {
            color: "#f90d4a",
          },
        }}
      >
        {tabs.map((tab: any) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            sx={{ fontWeight: "bold", fontSize: fontSize ? fontSize : "16px" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
