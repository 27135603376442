import List from "@mui/material/List";
import DatabricksCatalogOption from "./DatabricksCatalogOption";

interface DatabricksCatalogListProps {
  catalogsData: any;
  selectedTable: string;
  setSelectedTable: (table: string, catalog: string, schema:string) => void;
}

const DatabricksCatalogList = ({
  catalogsData,
  selectedTable,
  setSelectedTable
}: DatabricksCatalogListProps) => {

  return (
    <List
      sx={{ width: "100%", maxWidth: 560, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {catalogsData &&
        catalogsData?.length > 0 &&
        catalogsData.map((catalog: any) => {
          return (
          < DatabricksCatalogOption key={catalog.nombre} type="catalog" item={catalog}  setSelectedTable={setSelectedTable}
          selectedTable={selectedTable} catalogName={catalog.nombre}/>
          );
        })}
    </List>
  );
};

export default DatabricksCatalogList;
