import { MultiValue, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import { FlowAction, StepperType } from "../../context/types";
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";
import { categorias } from "./ToolsBar/tools";

/**
 * @description La función validateGroupByRow realiza una validación para determinar si una fila debe ser agregada a un grupo o no, evitando filas repetidas en un contexto de agrupación.
 *
 * @param {SingleValue<IdOption> | undefined} columnSelectedOption - La opción de columna seleccionada.
 * @param {SingleValue<IdOption> | undefined} functionSelectedOption - La opción de función seleccionada.
 * @param {any[]} groupByRows - Array de filas existentes en el grupo.
 * @param {any} setMessage - Función para establecer mensajes de estado.
 * @returns {boolean} Retorna true si la validación es exitosa y la fila puede ser agregada al grupo. Retorna false si la validación falla y la fila no debe ser agregada.
 */

export const validateGroupByRow = (
  columnSelectedOption: MultiValue<IdOption> | undefined,
  functionSelectedOption: SingleValue<IdOption> | undefined,
  groupByRows: any[],
  setMessage: any
) => {
  let result = false;
  if (columnSelectedOption && functionSelectedOption) {
    const repeatedRows =
      groupByRows !== undefined
        ? groupByRows?.filter((row) => {
            return (
              columnSelectedOption.some((col: any)=>{ return col.value === row.column.value}) && row.function.value === functionSelectedOption?.value
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage("No se pueden agregar filas repetidas");
    } else {
      result = true;
    }
  } else {
    setMessage("Se deben completar todos los campos");
  }

  return result;
};

export const searchStepper = (id_stepper: number, data_flow: any) => {
  const stepper = data_flow?.steppers?.find(
    (stepper: StepperType) => stepper.id === id_stepper
  );
  return stepper;
};

/**
 * @name findBeforeElementPosition
 * @description Encuentra la posición del item anterior dentro de un array.
 *
 * @param {any[]} array - El array en el que se busca el item.
 * @param {any} idItem - (Opcional) El índice o identificador del item de referencia.
 * @returns {any} Retorna el id del item anterior si se encuentra, o undefined si el array está vacío o el item no tiene anterior.
 */

export const findBeforeElementPosition = (array: any[], idItem?: any) => {
  if (array && array.length != 0) {
    if (idItem === undefined) {
      return array[array?.length - 1].id;
    } else {
      const index = array?.findIndex((item) => item.id === idItem);
      return array[index - 1]?.id;
    }
  } else {
    return undefined;
  }
};

/**
 * @name getItemsOptions
 * @description Genera opciones de elementos a partir de un flujo de datos.
 *
 * Esta función toma un flujo de datos y crea un array de opciones basado en los steppers presentes en él. Las opciones
 * representan los steppers sin errores, y cada opción tiene un valor y una etiqueta que combina el nombre del stepper con
 * el tipo del primer item.
 *
 * @param {any} data_flow - El flujo de datos del cual se generarán las opciones.
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const getItemsOptions = (data_flow: any) => {
  const options: any[] = [];
  if (data_flow?.steppers?.length > 0) {
    const steppersWithoutError = data_flow?.steppers?.filter(
      (stepper: any) => !stepper?.items[stepper.items.length - 1]?.posee_error
    );
    steppersWithoutError.forEach((stepper: StepperType) =>
      options.push({
        value: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
      })
    );
  }
  return options;
};

/**
 * @name isFileNameValid
 * @description IMPORTANTE: FUNCIONALIDAD COMENTADA POR PEDIDO DE FER: Verifica si un nombre de archivo cumple con el formato válido.
 *
 * Esta función toma un nombre de archivo y utiliza una expresión regular para verificar si cumple con el formato válido.
 * El formato válido permite letras, números y ciertos caracteres especiales (espacio, guión bajo, guión y punto).
 * El nombre de archivo debe tener al menos 1 caracter y no más de 50 caracteres.
 *
 * @param {string} name - El nombre de archivo a ser verificado.
 * @returns {boolean} Verdadero si el nombre de archivo cumple con el formato válido, falso de lo contrario.
 */

export const isFileNameValid = (name: string) => {
 /* const validFormatRegExp = new RegExp("^[a-zA-Z0-9 _.&|:-]*$");
  const validFormat =
    validFormatRegExp.test(name) && name?.length < 50 && name?.length >= 1;
  return validFormat;*/
  return true
};

/**
 * @name isStepNameRepeated
 * @description Verifica si un nombre de paso está repetido en el flujo de datos.
 *
 * Esta función toma un nombre de paso y un flujo de datos, y verifica si el nombre del paso está repetido en los steppers
 * presentes en el flujo de datos. La verificación es insensible a mayúsculas y espacios en blanco al principio y final del nombre.
 *
 * @param {string} name - El nombre de paso a ser verificado.
 * @param {any} data_flow - El flujo de datos que contiene los steppers para verificar la repetición del nombre.
 * @returns {boolean} Verdadero si el nombre de paso está repetido, falso de lo contrario.
 */

export const isStepNameRepeated = (name: string, data_flow: any) => {
  let isRepeated = false;
  data_flow?.steppers?.forEach((step: any) => {
    if (step.nombre?.toUpperCase().trim() === name?.toUpperCase().trim()) {
      isRepeated = true;
      return;
    }
  });
  return isRepeated;
};

export const tranformDataGroupByToSend = (data: any) => {
  return data.map((row: any) => {
    return {
      columna: row.column.label,
      operacion: row.function.value,
      renombre: row.renombre && row.renombre !== "" ? row.renombre : undefined
    };
  });
};

/**
 * @name isColumnNameValid
 * @description Verifica si un nombre de columna cumple con el formato válido.
 *
 * Esta función toma un nombre de columna y utiliza una expresión regular para verificar si cumple con el formato válido.
 * El formato válido permite letras, números y ciertos caracteres especiales (punto, guión bajo y guión).
 * El nombre de columna debe tener al menos 1 caracter y no más de 25 caracteres.
 *
 * @param {string} name - El nombre de columna a ser verificado.
 * @returns {boolean} Verdadero si el nombre de columna cumple con el formato válido, falso de lo contrario.
 */

export const isColumnNameValid = (name: string) => {
  const validFormatRegExp = new RegExp("^[^,]+$");
  const validFormat =
    validFormatRegExp?.test(name) && name?.length <= 40 && name?.length >= 1;
  return validFormat;
};
interface Operators {
  [key: string]: string;
}

export const operators: Operators = {
  contains: "CONTIENE",
  equals: "ES_IGUAL",
  startsWith: "COMIENZA_CON",
  endsWith: "TERMINA_CON",
  isEmpty: "ESTA_VACIO",
  isNotEmpty: "NO_ESTA_VACIO",
  isAnyOf: "ES_CUALQUIERA_DE",
  es_mayor: "ES_MAYOR",
  es_mayor_o_igual: "ES_MAYOR_O_IGUAL",
  es_menor: "ES_MENOR",
  es_menor_o_igual: "ES_MENOR_O_IGUAL",
  no_es_cero: "NO_ES_CERO",
  no_es_igual: "NO_ES_IGUAL",
  no_contiene: "NO_CONTIENE",
  no_comienza_con: "NO_COMIENZA_CON",
  no_termina_con: "NO_TERMINA_CON",
};

export const operadores: Operators = {
  CONTIENE: "contains",
  ES_IGUAL: "equals",
  COMIENZA_CON: "startsWith",
  TERMINA_CON: "endsWith",
  ESTA_VACIO: "isEmpty",
  NO_ESTA_VACIO: "isNotEmpty",
  ES_CUALQUIERA_DE: "isAnyOf",
  ES_MAYOR: "es_mayor",
  ES_MAYOR_O_IGUAL: "es_mayor_o_igual",
  ES_MENOR: "es menor",
  ES_MENOR_O_IGUAL: "es_menor_o_igual",
  NO_ES_CERO: "no_es_cero",
  NO_ES_IGUAL: "no_es_igual",
  NO_CONTIENE: "no_contiene",
  NO_COMIENZA_CON: "no_comienza_con",
  NO_TERMINA_CON: "no_termina_con",
};

type Item = {
  columnField: string;
  operatorValue: string;
  value: string | string[];
};

export type Info = {
  items: Item[];
  linkOperator: GridLinkOperator | undefined;
};

export const transformDataToSend = (info: GridFilterModel) => {
  const filtros = info?.items?.map((item: any) => {
    const columna =
      item.columnField; /* .substring(0, item.columnField.lastIndexOf("(")) */
    const operador = operators[item.operatorValue];
    const valor = typeof item.value === "string" ? [item.value] : item.value;

    return {
      columna,
      operador,
      valor,
    };
  });

  return {
    tipo: "filtrar",
    filtros,
    operador_nexo: info.linkOperator === GridLinkOperator.And ? "Y" : "O",
  };
};

export const isExcelFile = (file: any) => {
  if(file){
    return (
      file.trim().endsWith(".xlsx") ||
      file.trim().endsWith(".xlsm") ||
      file.trim().endsWith(".xlsb") ||
      file.trim().endsWith(".xltx") ||
      file.trim().endsWith(".xltm") ||
      file.trim().endsWith(".xls") ||
      file.trim().endsWith(".xlt") ||
      file.trim().endsWith(".xml")
    );
  }
};

export const initialFilterData = (dataItem?: any) => {
  return dataItem
    ? {
        items: dataItem?.filtros?.map((filtro: any, index: number) => {
          return {
            id: index,
            columnField: filtro.columna,
            operatorValue: operadores[filtro.operador],
            value:
              filtro.operador === "ES_CUALQUIERA_DE"
                ? filtro.valor
                : filtro.valor[0],
          };
        }),
        linkOperator:
          dataItem?.operador_nexo === "Y"
            ? GridLinkOperator.And
            : GridLinkOperator.Or,
      }
    : {
        items: [
          {
            id: "",
            columnField: "",
            operatorValue: "",
            value: [],
          },
        ],
        linkOperator: GridLinkOperator.And,
      };
};

export const isColumnNameRepeated = (name: string, dataColumns: any) => {
  let isRepeated = false;
  dataColumns?.forEach((column: any) => {
    if (column.columna.toUpperCase().trim() === name.toUpperCase().trim()) {
      isRepeated = true;
      return;
    }
  });
  return isRepeated;
};

export const filterIdFromColumns = (dataColumns: any) => {
  return dataColumns?.filter((column: any) => column.columna != "id" && column?.nombre !== "id");
};

/**
 * @name handleSetItemBarStatus
 * @description Maneja el estado de la barra de herramientas de elementos.
 *
 * Esta función toma varios parámetros y manipula el estado de la barra de herramientas de elementos en función del flujo de datos,
 * el stepper activo y el estado de los elementos en el flujo. Habilita o deshabilita las herramientas de acuerdo a condiciones
 * específicas para permitir o restringir ciertas acciones en la interfaz.
 *
 * @param {React.Dispatch<FlowAction>} flowDispatch - El despachador de acciones para el flujo de datos.
 * @param {any} data_flow - El flujo de datos actual.
 * @param {number} active_stepper - El identificador del stepper activo.
 * @param {typeof categorias} itemsBarStatus - El estado de la barra de herramientas de elementos.
 */

export const handleSetItemBarStatus = (
  flowDispatch: React.Dispatch<FlowAction>,
  data_flow: any,
  active_stepper: number,
  itemsBarStatus: typeof categorias
) => {
  const habilitarNuevaFuenteMergeConcat = () => {
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.tipo === "nueva_fuente" ||
          tool.tipo === "merge" ||
          tool.tipo === "concat" ||
          tool.tipo === "cambiar_fuentes"
            ? (tool.disabled = false)
            : (tool.disabled = true);
          return tool;
        });
        return item;
      }),
    });
  };

  const habilitarNuevaFuente = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          (tool.tipo === "nueva_fuente" && data_flow?.nombre != "") ||
          (tool.tipo === "cambiar_fuentes" &&
            data_flow?.nombre != "" &&
            data_flow?.steppers?.length > 0)
            ? (tool.disabled = false)
            : (tool.disabled = true);
          return tool;
        });
        return item;
      }),
    });
  const habilitarTodos = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.disabled = false;
          return tool;
        });
        return item;
      }),
    });
  const habilitarTodosMenosMergeConcat = () =>
    flowDispatch({
      type: "SET_ITEMS_BAR_STATUS",
      payload: itemsBarStatus.map((item: any) => {
        item.tools.map((tool: any) => {
          tool.tipo === "merge" || tool.tipo === "concat"
            ? (tool.disabled = true)
            : (tool.disabled = false);
          return tool;
        });
        return item;
      }),
    });

  const haySteppers = data_flow?.steppers.length !== 0;

  const activeStepper = data_flow?.steppers?.find(
    (stepper: any) => stepper.id === active_stepper
  );

  const activeStepperTieneError =
    activeStepper?.items[activeStepper.items.length - 1]?.posee_error;

  const hayMasDeUnStepperSinError =
    data_flow?.steppers?.filter(
      (stepper: any) =>
        stepper?.items[stepper.items.length - 1]?.posee_error === false
    ).length > 1;

  if (haySteppers) {
    if (activeStepper) {
      if (activeStepperTieneError) {
        if (hayMasDeUnStepperSinError) {
          habilitarNuevaFuenteMergeConcat();
        } else {
          habilitarNuevaFuente();
        }
      } else {
        if (hayMasDeUnStepperSinError) {
          habilitarTodos();
        } else {
          habilitarTodosMenosMergeConcat();
        }
      }
    } else {
      if (hayMasDeUnStepperSinError) {
        habilitarNuevaFuenteMergeConcat();
      } else {
        habilitarNuevaFuente();
      }
    }
  } else {
    habilitarNuevaFuente();
  }
};

export const steppersHaveAnyError = (data_flow: any) => {
  return data_flow?.steppers?.some(
    (step: any) => step.items[step.items.length - 1]?.posee_error
  );
};

export const getItemById = (id: number, array: any) => {
  return array?.find((item: any) => item.id === id);
};

/**
 * @name formatConcatOptions
 * @description Formatea los items seleccionados para el concat al formar {value:1, label:"chau"}
 *
 * Esta función se utiliza en el edit del concat para formatear los items seleccionados al formato necesario para el select
 *
 * @param {any[]} ids - Array de ids de los items seleccionados
 * @param {any} data_flow - El flujo de datos del cual se generarán las opciones.
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const formatConcatOptions = (ids: any[], data_flow: any) => {
  const options: any[] = [];
  if (data_flow?.steppers?.length > 0) {
    const steppersWithoutError = data_flow?.steppers?.filter(
      (stepper: any) =>
        !stepper?.items[stepper.items.length - 1]?.posee_error &&
        ids.includes(stepper.id)
    );
    steppersWithoutError.forEach((stepper: StepperType) =>
      options.push({
        value: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
      })
    );
  }
  return options;
};

export const formatMergeOption = (id: number, data_flow: any) => {
  if (data_flow?.steppers?.length > 0) {
    const stepper = data_flow?.steppers?.find((stepper: any) => {
      return stepper?.id === id;
    });
    return stepper ? {
      value: stepper?.id,
      label: `${stepper?.nombre} - ${stepper?.items[0]?.tipo}`,
    } : undefined
  }
};

/**
 * @name getSteppersOptions
 * @description Genera opciones de elementos con el formato necesario para el checkmark select
 *
 * Esta función toma un flujo de datos y crea un array de opciones basado en los steppers presentes en él. Las opciones
 * representan los steppers sin errores, y cada opción tiene un valor y una etiqueta que combina el nombre del stepper con
 * el tipo del primer item.
 *
 * @param {any} data_flow - El flujo de datos del cual se generarán las opciones.
 * @returns {any[]} Array de opciones de elementos generadas a partir del flujo de datos.
 */

export const getSteppersOptions = (data_flow: any) => {
  const options: any[] = [];
  if (data_flow?.steppers?.length > 0) {
    const steppersWithoutError = data_flow?.steppers?.filter(
      (stepper: any) => !stepper?.items[stepper.items.length - 1]?.posee_error
    );
    steppersWithoutError.forEach((stepper: StepperType) =>
      options.push({
        id: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
        steppers_asociados:
          stepper.items[0]?.tipo === "merge" ||
          stepper.items[0]?.tipo === "concat"
            ? stepper.items[0]?.steppers_asociados
            : undefined,
      })
    );
  }
  return options;
};

/**
 * @name getItemsById
 * @description Devuelve los items a partir de los ids que recibe como parametro
 *
 * Esta función recibe el data_flow y los ids asociados a un items especifico y devuelve un array de objetos con los items buscados

 * @param {any} data_flow - El flujo de datos.
 *  * @param {number[]} ids - Array con los ids de los items buscados.
 * @returns {any[]} Array de objetos con los items buscados.
 */

export const getItemsById = (data_flow: any, ids: number[]) => {
  const items: any = [];
  data_flow?.steppers?.forEach((stepper: StepperType) => {
    if (ids.includes(stepper.id as number)) {
      items.push({
        id: stepper.id,
        label: `${stepper.nombre} - ${stepper.items[0]?.tipo}`,
        steppers_asociados:
          stepper.items[0]?.tipo === "merge" ||
          stepper.items[0]?.tipo === "concat"
            ? stepper.items[0]?.steppers_asociados
            : undefined,
      });
    }
  });
  return items;
};

/**
 * @name deleteDuplicatedItems
 * @description Elimina los items duplicados del array
 *
 * Esta función recibe un array con los items seleccionado y elimina los duplicados

 * @param {any} items - El array de items
 * @returns {any[]} Array de items sin duplicados.
 */

export function deleteDuplicatedItems(items: any) {
  const idsSet = new Set(); // Utilizamos un conjunto para mantener un registro de los IDs únicos
  const resultado = [];

  for (const item of items) {
    if (!idsSet.has(item.id)) {
      resultado.push(item);
      idsSet.add(item.id);
    }
  } 
  return resultado;
}


export function getFileById(id: number, dataSheets: any[]) {
  const fileInfo = dataSheets.find((file)=>{return file.id === id})
  return fileInfo
}

/**
 * @name formatMergeColumnControlOptions
 * @description Elimina los items duplicados del array
 *
 * Esta función recibe como primer parametro un objeto con el id del stepper seleccionado como archivo 1 para el merge y sos columnas, y como segundo parametro otro objeto con el id del stepper seleccionado como archivo 2 para el merge y sos columnas, y devuelve un array con todas las columnas de ambos archivos especificando a cuál stepper pertenecen

 * @param {any} font1 - El objeto con el id del stepper 1 y sus columnas
 * @param {any} font2 - El objeto con el id del stepper 2 y sus columnas
 * @returns {any[]} Array de opciones para el select de columna-control.
 */

export const formatMergeColumnControlOptions = (font1: any, font2: any) => {
  const columns1WithInfo = font1.columnas.map((col: any)=>{return {
    value: col.id,
    stepper: font1.value, 
    label: `${col.columna} - ${font1.label.replace(/^(.*)\s-\s[^-]*$/, '$1')}`, // esto es para obtener solo el nombre de la fuente ya que el label viene como 'nombreSTEPPER - nueva fuente'
  };})
  const columns2WithInfo = font2.columnas.map((col: any)=>{return {
    value: col.id,
    stepper: font2.value,
    label: `${col.columna} - ${font2.label.replace(/^(.*)\s-\s[^-]*$/, '$1')}`, // esto es para obtener solo el nombre de la fuente ya que el label viene como 'nombreSTEPPER - nueva fuente'
  };})
  return columns1WithInfo.concat(columns2WithInfo)
};


/**
 * @name checkIfAllColumnNotExists
 * @description Verifica si todas las columans seleccionadas, no existen en la lista de columnas actuales.
 *
 * Esta función recibe dos arreglos, uno que contiene las columnas seleccionadas y otro que contiene las columnas actuales. Devuelve true si todas las columnas seleccionadas no existen en la lista de columnas actuales, de lo contrario, devuelve false.
 *
 * @param {any[]} selectedColumns - Arreglo que contiene las columnas seleccionadas.
 * @param {any[]} actualColumns - Arreglo que contiene las columnas actuales.
 * @returns {boolean} true si todas las columnas seleccionadas no existen en la lista de columnas actuales, false de lo contrario.
 */
export const checkIfAllColumnNotExists = (selectedColumns: any[], actualColumns: any[]) => {
  return selectedColumns.every((selectedCol) => {
    return !actualColumns.some((col) => col.columna === selectedCol.label);
  });
};

/**
 * @name checkIfColumnNotExists
 * @description Verifica si una columna específica no existe en la lista de columnas actuales.
 *
 * Esta función recibe el nombre de una columna y un arreglo que contiene las columnas actuales. Devuelve true si la columna específica no existe en la lista de columnas actuales, de lo contrario, devuelve false.
 *
 * @param {string} column - El nombre de la columna que se va a verificar.
 * @param {any[]} actualColumns - Arreglo que contiene las columnas actuales.
 * @returns {boolean} true si la columna específica no existe en la lista de columnas actuales, false de lo contrario.
 */
export const checkIfColumnNotExists = (column: string, actualColumns: any[]) => {
  return !actualColumns.some((col) => {
    return col.label === column;
  });
};

export const formatFonts = (data: any) => {
  return data?.steppers
    .filter((step: any) => {
      return step?.items[0]?.tipo === "nueva_fuente";
    })
    .map((step: any) => {
      return {
        nombre: step.nombre,
        id: step.items[0].id,
        idStepper: step.id,
        dataFile: "original",
        error: step.items[0].posee_error
      };
    });
};

export const areFontsWithError = (data_flow: any) => {
  if (data_flow?.steppers?.length > 0) {
    return data_flow?.steppers?.some(
      (stepper: any) => stepper?.items[0]?.posee_error
    );
  }else{
    return true
  }
};

export const stepperHasAnyItemWithError = (items: any) => {
    return items?.some(
      (item: any) => item?.posee_error
    );
};