import {
  Box,
  Checkbox,
  Collapse,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useDialog from "../../../../../hooks/useDialog";
import { texts } from "../../../../../texts";
import { useApiQuery } from "../../../../../hooks/useApiQuery";
import { isValidName } from "../../../../schemas/utils";
import { form_label } from "../../../../../styles/app-styles";
import Select from "../../../../forms/Select";
import { selectStyles } from "../../../../../styles/select.styles";
import FiltersTab from "./FiltersTab";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShadowBox from "../../../../flowConfiguration/Modals/ShadowBox";
import CheckmarkSelect from "../../../../forms/CheckmarkSelect";
import { GET_EXTERNAL_CONNECTION_COLUMNS } from "../../../../../api/axios";
import InfoIcon from "@mui/icons-material/Info";
import SQLQueryIndicationsModal from "./SQLQueryIndicationsModal";
import OperationsTab from "./OperationsTab";
import {
  fileIngestionFormatOptions,
  getOperatorLabel,
  orderTypeOptions,
  validateSQLQuery,
} from "../../../../../utils/util";
import { MultiValue, SingleValue } from "react-select";
import { IdOption } from "../../../../forms/types";

interface IngestionConfigurationProps {
  resource_id: number;
  table: string;
  schema?: string;
  catalog?: string;
  configurationsNames: any[];
  connectionType: string;
  tabValue: string;
  configurationName: string;
  setConfigurationName: React.Dispatch<React.SetStateAction<string>>;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  fileFormatRef: any;
  fileFormatSelectedOption: SingleValue<IdOption> | undefined;
  fileFormatChangeHandler: any;
  selectedColumns: number[];
  setSelectedColumns: React.Dispatch<React.SetStateAction<number[]>>;
  orderByColumnRef: any;
  orderByColumnSelectedOption: SingleValue<IdOption> | undefined;
  orderByColumnChangeHandler: any;
  groupByColumnRef: any;
  groupByColumnsSelectedOption: MultiValue<IdOption> | undefined;
  groupByColumnChangeHandler: any;
  orderTypeRef: any;
  orderTypeSelectedOption: SingleValue<IdOption> | undefined;
  orderTypeChangeHandler: any;
  SQLQuery: string;
  setSQLQuery: React.Dispatch<React.SetStateAction<string>>;
  distinct: boolean;
  setDistinct: React.Dispatch<React.SetStateAction<boolean>>;
  filterCondition: any[];
  setFilterCondition: React.Dispatch<React.SetStateAction<any[]>>;
  aggregations: any[];
  setAggregations: React.Dispatch<React.SetStateAction<any[]>>;
  limitValue: string;
  setLimitValue: React.Dispatch<React.SetStateAction<string>>;
  confToEdit?: any;
}

const IngestionConfiguration = ({
  resource_id,
  table,
  schema,
  catalog,
  configurationsNames,
  connectionType,
  tabValue,
  configurationName,
  setConfigurationName,
  selectedColumns,
  setSelectedColumns,
  fileName,
  setFileName,
  fileFormatRef,
  fileFormatSelectedOption,
  fileFormatChangeHandler,
  orderByColumnRef,
  orderByColumnSelectedOption,
  orderByColumnChangeHandler,
  groupByColumnRef,
  groupByColumnsSelectedOption,
  groupByColumnChangeHandler,
  orderTypeRef,
  orderTypeSelectedOption,
  orderTypeChangeHandler,
  SQLQuery,
  setSQLQuery,
  distinct,
  setDistinct,
  filterCondition,
  setFilterCondition,
  aggregations,
  setAggregations,
  limitValue,
  setLimitValue,
  confToEdit,
}: IngestionConfigurationProps) => {
  const [openIndicationsModal, toggleIndicationsModal] = useDialog();
  const [openFilterSection, setOpenFilterSection] = useState(false);
  const [openAggregationsSection, setOpenAggregationsSection] = useState(false);
  const [openOrderBySection, setOpenOrderBySection] = useState(false);
  const [openLimitSection, setOpenLimitSection] = useState(false);
  const [openGroupBySection, setOpenGroupBySection] = useState(false);

  /*************************** LLAMADAS ***************************/

  const { data: columns, isLoading: isLoadingColumns } = useApiQuery(
    GET_EXTERNAL_CONNECTION_COLUMNS(resource_id, table, catalog, schema),
    false,
    texts.dataManagement.getExternalConnectionColumns.error
  );

  /*************************** VALIDACIONES **************************/

  const validateTableSchemaAndCatalogInSQLQuery = (query: string) => {
    let regexString = `^(?=.*\\bFROM\\b.*\\b${catalog}\\.${schema}\\.${table}\\b)(?!.*(?:CREATE|DROP|UPDATE|TRUNCATE|CONSTRAINT|TRIM|INSERT|ALTER|DELETE|ATTACH|DETACH|INTO|SET|GRANT)).*$`;
    if (connectionType === "cluster") {
      regexString = `^(?=.*\\bFROM\\b.*\\b${table}\\b)(?!.*(?:CREATE|DROP|UPDATE|TRUNCATE|CONSTRAINT|TRIM|INSERT|ALTER|DELETE|ATTACH|DETACH|INTO|SET|GRANT)).*$`;
    }

    const regex = new RegExp(regexString, "i");

    // Verificar si el nombre de archivo cumple con el formato
    const cleanedQuery = query.replace(/[\r\n\t]+/g, " ");

    return regex.test(cleanedQuery);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <TextField
          id="configurationName"
          label={"Nombre de la configuración:"}
          placeholder={"Nombre de la configuración..."}
          size="small"
          multiline
          variant="outlined"
          helperText={
            !isValidName(
              configurationsNames,
              configurationName,
              confToEdit,
              confToEdit?.nombre
            )
              ? "Ya existe una configuración con este nombre"
              : ""
          }
          error={
            !isValidName(
              configurationsNames,
              configurationName,
              confToEdit,
              confToEdit?.nombre
            )
          }
          fullWidth
          value={configurationName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setConfigurationName(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          required
        />
        <TextField
          id="fileName"
          label={"Nombre del archivo:"}
          placeholder={"Nombre del archivo..."}
          size="small"
          multiline
          variant="outlined"
          fullWidth
          value={fileName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFileName(event.target.value);
          }}
          sx={{ marginTop: "10px" }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
        />
        <Box sx={{ width: "50%", marginRight: "auto", marginTop: "10px" }}>
          <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
            Formato del archivo:
          </InputLabel>
          <Select
            reference={fileFormatRef}
            styles={selectStyles(fileFormatSelectedOption)}
            options={fileIngestionFormatOptions}
            name="fileFormat"
            onChange={fileFormatChangeHandler}
            closeMenuOnSelect
            placeholder="Seleccionar formato del archivo"
            isClearable
            defaultValue={fileFormatSelectedOption}
          />
        </Box>
        {tabValue === "CONSULTA SQL" && (
          <Stack sx={{ width: "100%", marginTop: "20px" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <InputLabel
                sx={{
                  ...form_label,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Consulta SQL
              </InputLabel>
              <Tooltip title="Ver indicaciones">
                <InfoIcon
                  sx={{
                    color: "var(--blue)",
                    fontSize: "21px",
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginBottom: "2px",
                  }}
                  onClick={toggleIndicationsModal}
                />
              </Tooltip>
            </Stack>

            <TextField
              id="SQL"
              label={"Escriba la consulta SQL:"}
              placeholder={"Consulta SQL..."}
              size="small"
              multiline
              variant="outlined"
              helperText={
                SQLQuery.length > 0 && !validateSQLQuery(SQLQuery)
                  ? "La consulta SQL no es valida"
                  : SQLQuery.length > 14 &&
                    !validateTableSchemaAndCatalogInSQLQuery(SQLQuery)
                  ? connectionType === "cluster"
                    ? "La consulta debe incluir la tabla"
                    : "La consulta debe incluir la tabla, el catalogo y el esquema"
                  : ""
              }
              error={
                !validateSQLQuery(SQLQuery) ||
                !validateTableSchemaAndCatalogInSQLQuery(SQLQuery)
              }
              fullWidth
              value={SQLQuery}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSQLQuery(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />
          </Stack>
        )}
        {tabValue === "MANUAL" && (
          <>
            <Stack sx={{ width: "100%", marginTop: "20px" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "10px" }}>
                Elija las columnas a conservar
              </InputLabel>
              <CheckmarkSelect
                label="Seleccionar columnas"
                selectedItems={selectedColumns}
                setSelectedItems={setSelectedColumns}
                items={columns?.map((item: any) => {
                  return {
                    id: item?.nombre,
                    label: `${item?.nombre} (${item?.tipo_dato})`,
                  };
                })}
                width="100%"
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} marginRight={"auto"}>
              <InputLabel id="credentials" sx={form_label}>
                Distinct
              </InputLabel>
              <Checkbox
                checked={distinct}
                sx={{
                  color: "#172D40",
                  "& svg": { stroke: "#172D40" },
                }}
                onChange={() => {
                  setDistinct((prev) => !prev);
                }}
              />
            </Stack>

            <Typography
              sx={{
                ...form_label,
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "15px",
              }}
              onClick={() => {
                setOpenFilterSection(!openFilterSection);
              }}
            >
              Filtros
              {openFilterSection ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse
              in={openFilterSection}
              timeout={10}
              sx={{ width: "100%" }}
            >
              <FiltersTab
                itemConditions={filterCondition}
                setConditions={setFilterCondition}
                columns={columns?.map((col: any) => {
                  return { value: col.nombre, label: col.nombre };
                })}
                confToEdit={confToEdit}
              />
            </Collapse>

            <Typography
              sx={{
                ...form_label,
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "15px",
              }}
              onClick={() => {
                setOpenGroupBySection(!openGroupBySection);
              }}
            >
              Agrupar
              {openGroupBySection ? <ExpandLess /> : <ExpandMore />}
              <Tooltip title="Debes seleccionar todas las columnas elegidas en columnas a conservar">
                <InfoIcon
                  sx={{
                    color: "var(--blue)",
                    fontSize: "21px",
                    cursor: "pointer",
                    marginLeft: "1px",
                    marginBottom: "2px",
                  }}
                />
              </Tooltip>
            </Typography>
            <Collapse
              in={openGroupBySection}
              timeout={10}
              sx={{ width: "100%" }}
            >
              <ShadowBox margin>
                <Box sx={{ width: "50%" }}>
                  <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                    Agrupar por:
                  </InputLabel>
                  <Select
                    reference={groupByColumnRef}
                    styles={selectStyles(groupByColumnsSelectedOption)}
                    options={selectedColumns
                      ?.filter((col: any) => col.id !== "todas")
                      ?.map((col: any) => {
                        return { value: col.id, label: col.id };
                      })}
                    name="Columna groupby"
                    onChange={groupByColumnChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas"
                    isClearable
                    isMulti
                    defaultValue={groupByColumnsSelectedOption}
                  />
                </Box>
              </ShadowBox>
            </Collapse>
            {groupByColumnsSelectedOption &&
              groupByColumnsSelectedOption.length > 0 && (
                <>
                  <Typography
                    sx={{
                      ...form_label,
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "100%",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      setOpenAggregationsSection(!openAggregationsSection);
                    }}
                  >
                    Operaciones sobre columnas
                    {openAggregationsSection ? <ExpandLess /> : <ExpandMore />}
                  </Typography>
                  <Collapse
                    in={openAggregationsSection}
                    timeout={10}
                    sx={{ width: "100%" }}
                  >
                    <OperationsTab
                      aggregations={aggregations}
                      setAggregations={setAggregations}
                      columns={columns?.map((col: any) => {
                        return { value: col.nombre, label: col.nombre };
                      })}
                      confToEdit={confToEdit}
                    />
                  </Collapse>
                </>
              )}

            <Typography
              sx={{
                ...form_label,
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "15px",
              }}
              onClick={() => {
                setOpenOrderBySection(!openOrderBySection);
              }}
            >
              Ordenar por
              {openOrderBySection ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse
              in={openOrderBySection}
              timeout={10}
              sx={{ width: "100%" }}
            >
              <ShadowBox margin>
                <Stack
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "25px",
                      marginTop: "auto",
                      width: "50%",
                    }}
                  >
                    <InputLabel sx={form_label}>Columna</InputLabel>
                    <Select
                      reference={orderByColumnRef}
                      styles={selectStyles(orderByColumnSelectedOption)}
                      options={selectedColumns
                        ?.filter((col: any) => col.id !== "todas")
                        ?.map((col: any) => {
                          return { value: col.id, label: col.id };
                        })}
                      name="Columna orderby"
                      onChange={orderByColumnChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar columna"
                      isClearable
                      defaultValue={orderByColumnSelectedOption}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginRight: "8px",
                      marginTop: "auto",
                      width: "40%",
                    }}
                  >
                    <InputLabel sx={form_label}>Tipo de orden</InputLabel>
                    <Select
                      reference={orderTypeRef}
                      styles={selectStyles(orderTypeSelectedOption)}
                      options={orderTypeOptions}
                      name="OrderType"
                      onChange={orderTypeChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar tipo de orden"
                      isClearable
                      defaultValue={orderTypeSelectedOption}
                    />
                  </Box>
                </Stack>
              </ShadowBox>
            </Collapse>
            <Typography
              sx={{
                ...form_label,
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "100%",
                marginTop: "15px",
              }}
              onClick={() => {
                setOpenLimitSection(!openLimitSection);
              }}
            >
              Límite
              {openLimitSection ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse in={openLimitSection} timeout={10} sx={{ width: "100%" }}>
              <ShadowBox margin>
                <Box sx={{ width: "50%" }}>
                  <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                    Límite de filas
                  </InputLabel>
                  <TextField
                    id="value"
                    label={"Valor"}
                    placeholder={"Valor"}
                    size="small"
                    multiline
                    type="number"
                    variant="outlined"
                    error={limitValue === ""}
                    fullWidth
                    value={limitValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const regex = /^[0-9\b]+$/;
                      if (
                        !event.target.value.startsWith("0") &&
                        (event.target.value === "" ||
                          regex.test(event.target.value))
                      ) {
                        setLimitValue(event.target.value);
                      }
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Box>
              </ShadowBox>
            </Collapse>
          </>
        )}
      </Box>
      <SQLQueryIndicationsModal
        open={openIndicationsModal}
        handleClose={toggleIndicationsModal}
        connectionType={connectionType}
        table={table}
        schema={schema}
        catalog={catalog}
      />
    </>
  );
};

export default IngestionConfiguration;
