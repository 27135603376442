import { Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";

interface SelectViewTypeProps {
  viewType: "list" | "cards";
  setViewType: (view: "list" | "cards") => void;
}

const SelectViewType = ({ viewType, setViewType }: SelectViewTypeProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setViewType(viewType === "list" ? "cards" : "list");
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems="center"
        width="100px"
        sx={{ cursor: "pointer", marginLeft:"auto" }}
        onClick={handleOpenUserMenu}
      >
        {viewType === "list" ? (
          <ViewHeadlineIcon
            sx={{
              fontSize: "34px",
              color: "var(--blue)",
            }}
          />
        ) : (
          <ViewModuleIcon
            sx={{
              fontSize: "34px",
              color: "var(--blue)",
            }}
          />
        )}

        <Typography
          sx={{
            fontSize: "14px",
            marginLeft: "2px",
            color: "var(--blue)",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Vista
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            fontSize: "26px",
            color: "var(--blue)",
          }}
        />
      </Stack>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        {anchorElUser && (
          <MenuItem sx={{ padding: "0px" }} onClick={handleCloseUserMenu}>
            <Typography
              textAlign="center"
              sx={{
                color: "var(--greeny)",
                display: "inline-flex",
                padding: "6px",
                alignItems: "center",
                width: "100px",
                justifyContent: "center",
              }}
            >
              {viewType === "list" ? "Iconos" : "Lista"}
              {viewType === "cards" ? (
                <ViewHeadlineIcon
                  sx={{
                    fontSize: "22px",
                    color: "var(--blue)",
                  }}
                />
              ) : (
                <ViewModuleIcon
                  sx={{
                    fontSize: "22px",
                    color: "var(--blue)",
                  }}
                />
              )}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SelectViewType;
