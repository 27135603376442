import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

/**
 * @name RequireAuth
 * @description Componente que requiere autenticación para poder luego renderizar a los children.  Recibe como parámetros los roles o secciones permitidas, y los compara con los roles del usuario.  Si el usuario tiene acceso, se renderiza el children, sino, se pregunta por el token de seguridad.  Si el token de seguridad es válido, se renderiza la página de Unauthorized, sino, se redirige a la página de login.
 * @param props
 * @returns {JSX | void}
 * @category Authentication
 */

const RequireAuth = ({ allowedRoles }: any) => {
  const { auth }: any = useAuth();
  const location = useLocation();

  const roles: any = auth?.roles || [];
  
  return roles?.find((role: any) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.access_token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default RequireAuth;