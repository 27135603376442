import Toolbar from "@mui/material/Toolbar";
import {
  StyledAppBar,
  appBar_buttons_container_styles,
  menu_header_company,
  menu_header_option,
  menu_header_option_container,
  menu_items_container,
  menu_logo_alpha_styles,
  menu_logo_container,
  menu_logo_user_container,
} from "../../styles/app-styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import logoAlpha from "../../assets/AlphaWhite.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserAvatar from "./Avatar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Stack from "@mui/material/Stack";
import CustomizedDialogs from "../dialog/Dialog";
import useDialog from "../../hooks/useDialog";
import HelpContent from "../helpContent/HelpContent";
import { useContext, useEffect } from "react";
import { useApiQuery } from "../../hooks/useApiQuery";
import { DATA_URL } from "../../api/axios";
import { texts } from "../../texts";
import DataManagementProvider from "../../context/DataManagementContext";

interface AppBarComponentProps {
  open: boolean;
  handleDrawerOpen: () => void;
  goHome: () => void;
  dataGetUserCompany: any;
  handleOpenBasicModal: () => void;
}

const AppBarComponent = ({
  open,
  handleDrawerOpen,
  goHome,
  dataGetUserCompany,
  handleOpenBasicModal,
}: AppBarComponentProps) => {
  const [openHelp, toggleModalHelp] = useDialog();
  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);
  const { searchInfo } = DataState;

  /*const {
    data: dataResources,
    isLoading: isLoadingDataResources,
    isFetching: isFetchingDataResources,
    isError: isErrorDataResources,
    refetch: refetchResources,
  } = useApiQuery(
    `${DATA_URL}?all=True`,
    false,
    texts.dataManagement.getResources.error
  );

  /* Ya que este componente se renderiza en toda la app, la idea es que en cuanto la página se renderice se haga este llamado asincrono que demora unos minutos y ya quede la data guardada*/
  /*useEffect(() => {
    if (dataResources && searchInfo === undefined) {
      dataDispatch({
        type: "SET_SEARCH_INFO",
        payload: dataResources,
      });
    }
  }, [dataResources]);

  useEffect(() => {
      dataDispatch({
        type: "SET_IS_LOADING_SEARCH_INFO",
        payload: isLoadingDataResources,
      });
  }, [isLoadingDataResources])*/

  return (
    <StyledAppBar
      color="primary"
      sx={{ background: "var(--greeny)" }}
      position="fixed"
      open={open}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
            color: "white",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={menu_items_container}>
          <Box sx={menu_logo_user_container}>
            {!open && (
              <Box sx={menu_logo_container} onClick={goHome}>
                <img
                  style={menu_logo_alpha_styles}
                  src={logoAlpha}
                  alt="logo"
                />
              </Box>
            )}
            {dataGetUserCompany && dataGetUserCompany.nombre_visual && (
              <Box
                sx={menu_header_option_container}
                onClick={handleOpenBasicModal}
              >
                <p style={menu_header_company}>
                  {dataGetUserCompany.nombre_visual}
                </p>
                <ExpandMoreIcon fontSize={"medium"} />
              </Box>
            )}
            <p style={menu_header_option}>admin</p>
          </Box>
          <Stack direction="row" alignItems={"center"}>
            <Stack
              sx={appBar_buttons_container_styles}
              onClick={toggleModalHelp}
            >
              AYUDA
              <HelpOutlineIcon sx={{ fontSize: "26px", fontWeight: 50 }} />
            </Stack>
            <UserAvatar />
          </Stack>
        </Box>
        <CustomizedDialogs
          open={openHelp}
          handleClose={toggleModalHelp}
          title={"Ayuda"}
          maxWidth={"lg"}
        >
          <HelpContent />
        </CustomizedDialogs>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBarComponent;
