export const categorias = [
  {
    categoria: "Fuente",
    tools: [
      {
        label: "Nueva fuente",
        tipo: "nueva_fuente",
        newStepper: true,
        disabled: true
      },
      {
        label: "Cambiar fuentes",
        tipo: "cambiar_fuentes",
        newStepper: false,
        disabled: false
      },
    ],  
  },
  {
    categoria: "Métodos",
    color: "var(--very-very-light-blue)",
    tools: [
      {
        label: "Setear cabecera",
        tipo: "setear_cabecera",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Nueva columna",
        tipo: "nueva_columna",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Eliminar columna",
        tipo: "eliminar_columna",
        newStepper: false,
        disabled: true,
      },

      {
        label: "Modificar columna",
        tipo: "modificar_columna",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Renombrar columnas",
        tipo: "renombrar_columnas",
        newStepper: false,
        disabled: true,
      },    
      {
        label: "Separador_1",
        separator: true,
      },
      {
        label: "Dinamizar columnas",
        tipo: "dinamizar",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Des- dinamizar columnas",
        tipo: "desdinamizar",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Eliminar filas",
        tipo: "eliminar_filas",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Filtrar",
        tipo: "filtrar",
        newStepper: false,
        disabled: true,
      },
      {
        label: "Separador_2",
        separator: true,
      },
      {
        label: "Group by",
        tipo: "group_by",
        newStepper: false,
        disabled: true,
      },
    ],
  },
  {
    categoria: "Transformaciones",
    color: "var(--very-light-magenta)",
    tools: [
      {
        label: "Merge",
        tipo: "merge",
        newStepper: false,
        disabled: true,
      },

      {
        label: "Concat",
        tipo: "concat",
        newStepper: true,
        disabled: true,
      },
    ],
  },
];