import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Delete from "../../../icons/Delete";
import { Item } from "../../../basicGrid/BasicGrid";
import { useContext } from "react";
import FlowsProvider from "../../../../context/FlowsContext";
interface GroupByRowProps {
  row: any;
}

const RowGroupBy = ({ row }: GroupByRowProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { groupByRows } = flowState;
  const handleDeleteFilter = () => {
    const newGroupByRows = groupByRows;

    flowDispatch({
      type: "SET_GROUP_BY_ROWS",
      payload: newGroupByRows.filter((groupByRow: any) => {
        return !(
          groupByRow.column.value === row?.column?.value &&
          groupByRow.function.value === row?.function?.value
        );
      }),
    });
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={5.5} >
        <Item sx={{color: "var(--blue) !important"}}>{row?.column?.label}</Item>
      </Grid>
      <Grid item xs={5}>
        <Item sx={{color: "var(--blue) !important"}}>{row.function.label}</Item>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default RowGroupBy;
