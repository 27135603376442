import { Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import {
  RESOURCE_LABEL_BY_ID_URL,
  RESOURCE_LABEL_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useDialog from "../../../hooks/useDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useApi from "../../../hooks/useApi";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CreateLabelModal from "./CreateLabelModal";
import { ConfirmationModal as DeleteLabelConfirmationModal } from "../../dialog";
import { Label } from "./types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ResourceLabelsConfig = () => {
  const [openCreateLabelModal, toggleCreateLabelModal] = useDialog();
  const [openConfigModal, toggleConfigModal] = useDialog();
  const [openDeleteLabelModal, toggleDeleteLabelModal] = useDialog();
  const [isLoadingPostLabel, setIsLoadingPostLabel] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState<Label | undefined>(
    undefined
  );
  const [labelToEdit, setLabelToEdit] = useState<Label | undefined>(undefined);

  /********************************* LLAMADAS ************************************************/

  const {
    data: dataLabels,
    isLoading: isLoadingGetLabels,
    refetch: refetchLabels,
    error: errorLabels,
  } = useApiQuery(
    RESOURCE_LABEL_URL,
    false,
    texts.dataManagement.getLabels.error
  );

  const { isLoading: isLoadingDeleteLabel, callApi: deleteLabel } = useApi(
    undefined,
    "DELETE",
    texts.dataManagement.deleteLabel.codes,
    undefined,
    refetchLabels,
    undefined
  );

  /******************************* HANDLERS *******************************/

  const handleCloseDeleteModal = () => {
    toggleDeleteLabelModal();
    setLabelToDelete(undefined);
  };

  const handleDeleteLabel = () => {
    deleteLabel(RESOURCE_LABEL_BY_ID_URL(labelToDelete?.id as number));
    handleCloseDeleteModal();
  };

  const handleClickOnDeleteButton = (label: Label) => {
    setLabelToDelete(label);
    toggleDeleteLabelModal();
  };

  const handleClickOnEditButton = (label: Label) => {
    setLabelToEdit(label);
    toggleCreateLabelModal();
  };

  const handleCloseCreateLabelModal = () => {
    toggleCreateLabelModal();
    setLabelToEdit(undefined);
  };

  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/

  return (
    <>
      <SimpleBackdrop
        open={isLoadingGetLabels}
        message={texts.dataManagement.getLabels.loading}
      />
      <SimpleBackdrop
        open={isLoadingDeleteLabel}
        message={texts.dataManagement.deleteLabel.loading}
      />
      <Button
        title="Gestionar etiquetas"
        type="button"
        onClick={toggleConfigModal}
        disabled={isLoadingGetLabels}
        color="blue"
      />
      <Dialog
        open={openConfigModal}
        handleClose={toggleConfigModal}
        title={"Gestionar etiquetas"}
        maxWidth="xl"
      >
        <SimpleBackdrop
          open={isLoadingPostLabel}
          message={texts.dataManagement.postLabel.loading}
        />
        <SimpleBackdrop
          open={isLoadingPostLabel}
          message={texts.dataManagement.putLabel.loading}
        />
        <Stack sx={{ width: "800px", minHeight: "250px" }}>
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "19px",
              textTransform: "uppercase",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Etiquetas
          </Typography>
          <Stack sx={{ flexDirection: "row", width: "100%" }}>
            {dataLabels !== undefined &&
              dataLabels.length > 0 &&
              dataLabels.map((label: Label) => {
                return (
                  <Stack
                    sx={{
                      flexDirection: "row",
                      backgroundColor: label?.color,
                      borderRadius: "14px",
                      padding: "2px 8px",
                      marginRight: "10px",
                      marginBottom: "10px",
                      alignItems: "center",
                    }}
                    key={label.id}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "white",
                        marginRight: "5px",
                      }}
                    >
                      {label.nombre}
                    </Typography>
                    <Tooltip title="Editar etiqueta">
                      <EditIcon
                        onClick={() => {
                          handleClickOnEditButton(label);
                        }}
                        sx={{
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "white",
                          margin: "0px 2px 3px 5px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Eliminar etiqueta">
                      <DeleteIcon
                        onClick={() => {
                          handleClickOnDeleteButton(label);
                        }}
                        sx={{
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "white",
                          marginBottom: "3px",
                        }}
                      />
                    </Tooltip>
                  </Stack>
                );
              })}
            <Tooltip title="Crear nueva etiqueta">
              <Stack
                sx={{
                  flexDirection: "row",
                  backgroundColor: "var(--very-light-grey)",
                  borderRadius: "14px",
                  padding: "2px 8px",
                  marginRight: "10px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "var(--grey)",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    toggleCreateLabelModal();
                  }}
                >
                  Crear etiqueta
                </Typography>
                <AddCircleOutlineIcon
                  sx={{
                    fontSize: "20px",
                    color: "var(--grey)",
                    transition: "all 0.4s",
                    marginLeft: "4px",
                    marginBottom: "3px",
                    "&:hover": {
                      transform: "scale(1.15)",
                      transition: "0.3s all",
                    },
                  }}
                  onClick={toggleCreateLabelModal}
                />
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={() => {
              toggleConfigModal();
            }}
            color="light-grey"
          />
        </Stack>
      </Dialog>
      <CreateLabelModal
        open={openCreateLabelModal}
        handleClose={handleCloseCreateLabelModal}
        dataLabels={dataLabels}
        onSuccessSendLabel={refetchLabels}
        isEdit={labelToEdit !== undefined}
        labelToEdit={labelToEdit}
        setIsLoadingPostLabel={setIsLoadingPostLabel}
      />
      <DeleteLabelConfirmationModal
        open={openDeleteLabelModal}
        handleClose={handleCloseDeleteModal}
        handleAccept={handleDeleteLabel}
        message="eliminar la etiqueta"
        title="Confirmar"
      />
    </>
  );
};

export default ResourceLabelsConfig;
