import { useEffect, useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import {
  EXTERNAL_CONNECTION_CONFIGURATION_BY_ID,
  GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS,
  GET_EXTERNAL_CONNECTION_COLUMNS,
} from "../../../../api/axios";
import { Box, Stack } from "@mui/material";
import { Button } from "../../../buttons";
import Dialog from "../../../dialog/Dialog";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import {
  getOperator,
  getOperatorLabel,
  orderTypeOptions,
  validateSQLQuery,
} from "../../../../utils/util";
import IngestionConfiguration from "./CreateIngestionConfigurationModal/IngestionConfiguration";
import { ConfirmationModal } from "../../../dialog";
import useDialog from "../../../../hooks/useDialog";
import { isValidName } from "../../../schemas/utils";

interface EditConfigurationModalProps {
  resource_id: number;
  refetchConfigurations: (url: string) => void;
  configurations: any[];
  connectionType: string;
  table: string;
  schema?: string;
  catalog?: string;
  open: boolean;
  handleClose: () => void;
  confToEdit: any;
}

const EditConfigurationModal = ({
  resource_id,
  refetchConfigurations,
  configurations,
  table,
  schema,
  catalog,
  connectionType,
  open,
  handleClose,
  confToEdit,
}: EditConfigurationModalProps) => {
  const [configurationName, setConfigurationName] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [SQLQuery, setSQLQuery] = useState<string>("");
  const [distinct, setDistinct] = useState(false);
  const [filterCondition, setFilterCondition] = useState<any[]>([]);
  const [aggregations, setAggregations] = useState<any[]>([]);
  const [limitValue, setLimitValue] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<string>("");
  const [openConfirmEditConfModal, toggleConfirmEditConfModal] = useDialog();
  const [delayHelper, setDelayHelper] = useState<boolean>(false);

  const fileFormatRef: any = useRef(null);
  const orderByColumnRef: any = useRef(null);
  const groupByColumnRef: any = useRef(null);
  const orderTypeRef: any = useRef(null);

  const [
    fileFormatSelectedOption,
    setFileFormatSelectedOption,
    fileFormatChangeHandler,
  ] = useSingleSelect();

  const [
    orderByColumnSelectedOption,
    setOrderByColumnSelectedOption,
    orderByColumnChangeHandler,
  ] = useSingleSelect();

  const [
    groupByColumnsSelectedOption,
    setGroupByColumnSelectedOption,
    groupByColumnChangeHandler,
  ] = useMultiSelect();

  const [
    orderTypeSelectedOption,
    setOrderTypeSelectedOption,
    orderTypeChangeHandler,
  ] = useSingleSelect();

  // Seteamos todos los datos de la configuracion a ditar
  useEffect(() => {
    if (confToEdit) {
      setConfigurationName(confToEdit.nombre);
      setFileName(confToEdit.nombre_archivo);
      setFileFormatSelectedOption({
        value: confToEdit.extension,
        label: confToEdit.extension,
      });
      setTabValue(confToEdit.tipo === "manual" ? "MANUAL" : "CONSULTA SQL");
      if (confToEdit.tipo !== "manual") {
        setSQLQuery(confToEdit.consulta);
      } else {
        const selectedColumns = confToEdit?.operaciones?.find(
          (op: any) => op.tipo === "select"
        )?.columnas_incluir;
        const filters = confToEdit?.operaciones?.filter(
          (op: any) => op.tipo === "filtro"
        );
        const orderBy = confToEdit?.operaciones?.find(
          (op: any) => op.tipo === "orden"
        );
        const groupBy = confToEdit?.operaciones
          ?.find((op: any) => op.tipo === "group_by")
          ?.columnas_agrupar?.map((col: any) => col.columna);
        const operations = confToEdit?.operaciones?.filter(
          (op: any) => op.tipo === "function"
        );

        formatSelectedColumns(selectedColumns);
        if (filters?.length > 0) {
          setFilterCondition(
            filters.map((filter: any) => {
              return {
                columna_condicional: {
                  value: filter.columna_inicio,
                  label: filter.columna_inicio,
                },
                operador_filtro: filter.condicion_logica === "OR" ? "O" : "Y",
                funcion: filter.funcion ?  filter.funcion : undefined,
                id: Math.random(),
                operador: getOperatorLabel(filter.operador),
                valor: {
                  tipo: filter.tipo_comparacion,
                  valor_comparar:
                    filter.tipo_comparacion === "columna"
                      ? {
                          value: filter.columna_valor_final,
                          label: filter.columna_valor_final,
                        }
                      : filter.columna_valor_final,
                },
              };
            })
          );
        }
        if (orderBy) {
          setOrderByColumnSelectedOption({
            value: orderBy.columna,
            label: orderBy.columna,
          });
          setOrderTypeSelectedOption(
            orderBy.tipo_orden === "ASC"
              ? orderTypeOptions[0]
              : orderTypeOptions[1]
          );
        }
        if (groupBy) {
          setGroupByColumnSelectedOption(
            groupBy.map((col: string) => {
              return { value: col, label: col };
            })
          );
        }
        if (operations?.length > 0) {
          setAggregations(
            operations.map((op: any) => {
              return {
                id: Math.random(),
                funcion: op.funcion,
                columna_nueva: op.columna_nueva,
                columna_valor_funcion: op.columna_valor_funcion,
              };
            })
          );
        }
        setDistinct(confToEdit.distinct);
        setLimitValue(confToEdit.cantidad_filas);
      }
      setTimeout(() => {
        setDelayHelper(true);
      }, 2);
    }
  }, [confToEdit]);

  /*************************** LLAMADAS ***************************/

  const {
    isLoading: isLoadingEditConfiguration,
    callApi: editConfiguration,
    error: errorEditConfiguration,
  } = useApi(
    EXTERNAL_CONNECTION_CONFIGURATION_BY_ID(confToEdit?.id),
    "PUT",
    texts.dataManagement.postExternalConnectionConfiguration.codes,
    undefined,
    () =>
      refetchConfigurations(
        GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS(resource_id)
      ),
    undefined,
    true
  );

  const { data: columns, isLoading: isLoadingColumns } = useApiQuery(
    GET_EXTERNAL_CONNECTION_COLUMNS(resource_id, table, catalog, schema),
    false,
    texts.dataManagement.getExternalConnectionColumns.error
  );

  /*************************** HANLDERS  ****************************/

  const handleAccept = () => {
    editConfiguration(undefined, {
      nombre: configurationName,
      nombre_archivo: fileName,
      extension: fileFormatSelectedOption?.value,
      tipo: tabValue === "MANUAL" ? "manual" : "consulta_sql",
      distinct: distinct,
      recurso_databricks: resource_id,
      catalogo: catalog,
      esquema: schema,
      tabla: table,
      operaciones: tabValue === "MANUAL" ? getOperations() : undefined,
      consulta: tabValue !== "MANUAL" ? SQLQuery : undefined,
    });
    cleanAndClose();
  };

  const cleanAndClose = () => {
    if (openConfirmEditConfModal) {
      toggleConfirmEditConfModal();
    }
    setDelayHelper(false);
    setTabValue("");
    setSQLQuery("");
    setConfigurationName("");
    setFileName("");
    setLimitValue("");
    setFilterCondition([]);
    setFileFormatSelectedOption(undefined);
    setOrderByColumnSelectedOption(undefined);
    setOrderTypeSelectedOption(undefined);
    setSelectedColumns([]);
    setGroupByColumnSelectedOption(undefined);
    setAggregations([]);
    setDistinct(false);
    fileFormatRef?.current?.clearValue();
    orderTypeRef?.current?.clearValue();
    orderByColumnRef?.current?.clearValue();
    groupByColumnRef?.current?.clearValue();
    handleClose();
  };

  /*************************** TRANSFORMACIONES  ****************************/

  const getOperations = () => {
    const operations: any[] = [
      {
        tipo: "select",
        columnas_incluir:
          selectedColumns
            ?.map((col: any) => col.id)
            .filter((col: string) => col !== "todas")?.length ===
          columns?.length
            ? []
            : selectedColumns
                ?.map((col: any) => col.id)
                .filter((col: string) => col !== "todas"),
      },
      {
        tipo: "limite",
        valor: limitValue ? parseInt(limitValue) : "",
      },
    ];
    if (
      filterCondition &&
      filterCondition.length > 0 &&
      filterCondition[0]?.columna_condicional?.value !== undefined
    ) {
      filterCondition.forEach((condition: any) => {
        if (
          condition.columna_condicional &&
          condition.operador &&
          condition.valor.valor_comparar
        ) {
          return operations.push({
            tipo: "filtro",
            funcion: condition.funcion ? condition.funcion : undefined,
            columna_inicio: condition.columna_condicional?.value,
            operador: getOperator(condition.operador?.label),
            tipo_comparacion: condition.valor.tipo,
            condicion_logica: condition.operador_filtro === "Y" ? "AND" : "OR",
            columna_valor_final:
              condition.valor.tipo === "columna"
                ? condition.valor.valor_comparar.value
                : condition.valor.valor_comparar,
          });
        }
      });
    }
    if (orderByColumnSelectedOption?.value) {
      operations.push({
        tipo: "orden",
        columna: orderByColumnSelectedOption?.value,
        tipo_orden:
          orderTypeSelectedOption?.value === "Ascendente" ? "ASC" : "DESC",
      });
    }
    if (
      groupByColumnsSelectedOption &&
      groupByColumnsSelectedOption?.length > 0
    ) {
      operations.push({
        tipo: "group_by",
        columnas_agrupar: groupByColumnsSelectedOption.map(
          (col: any) => col.value
        ),
      });
    }
    if (
      aggregations &&
      aggregations?.length > 0 &&
      groupByColumnsSelectedOption &&
      groupByColumnsSelectedOption.length > 0
    ) {
      aggregations.forEach((aggregation: any) => {
        if (aggregation.funcion && aggregation.columna_valor_funcion) {
          return operations.push({
            tipo: "function",
            funcion: aggregation.funcion,
            columna_valor_funcion: aggregation.columna_valor_funcion,
            columna_nueva: aggregation.columna_nueva,
          });
        }
      });
    }
    return operations;
  };

  columns?.map((item: any) => {
    return {
      id: item?.nombre,
      label: `${item?.nombre} (${item?.tipo_dato})`,
    };
  });

  const formatSelectedColumns = (selectedColumns: any) => {
    if (!selectedColumns) {
      setSelectedColumns(
        columns?.map((item: any) => {
          return {
            id: item?.nombre,
            label: `${item?.nombre} (${item?.tipo_dato})`,
          };
        })
      );
    } else {
      setSelectedColumns(transformColumns(selectedColumns));
    }
  };

  const transformColumns = (selectedColumns: any) =>
    selectedColumns.map((columna: any) => ({
      id: columna.columna,
      label: `${columna.columna} (${
        columns.find((dt: any) => dt.nombre === columna.columna)?.tipo_dato ||
        "unknown"
      })`,
    }));

  /*************************** VALIDACIONES **************************/

  const isFormCompleted = () => {
    if (tabValue === "MANUAL") {
      return (
        configurationName?.length > 0 &&
        fileFormatSelectedOption !== undefined &&
        isValidName(
          configurations,
          configurationName,
          true,
          confToEdit?.nombre
        ) &&
        selectedColumns.length > 0 &&
        (orderByColumnSelectedOption?.value
          ? orderByColumnSelectedOption?.value &&
            orderTypeSelectedOption?.value !== undefined
          : true) &&
        (filterCondition &&
        filterCondition?.length > 0 &&
        filterCondition[0]?.columna_condicional?.value !== undefined
          ? filterCondition[0]?.columna_condicional?.value !== undefined &&
            filterCondition[0]?.operador?.label !== undefined &&
            filterCondition[0]?.valor.valor_comparar !== undefined
          : true) && (groupByColumnsSelectedOption && groupByColumnsSelectedOption?.length > 0 ? groupByColumnsSelectedOption?.length ===  selectedColumns
            ?.map((col: any) => col.id)
            .filter((col: string) => col !== "todas")?.length: true)
      );
    } else {
      return (
        configurationName.length > 0 &&
        fileFormatSelectedOption !== undefined &&
        isValidName(
          configurations,
          configurationName,
          true,
          confToEdit?.nombre
        ) &&
        validateSQLQuery(SQLQuery) &&
        SQLQuery.includes(table)
      );
    }
  };
  return (
    <>
      <SimpleBackdrop
        open={isLoadingEditConfiguration}
        message={
          texts.dataManagement.postExternalConnectionConfiguration.loading
        }
      />
      {delayHelper && (
        <Dialog
          open={open}
          handleClose={cleanAndClose}
          maxWidth="xl"
          title={"Editar configuración"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50vw",
              alignItems: "center",
            }}
          >
            {confToEdit && delayHelper && (
              <IngestionConfiguration
                resource_id={resource_id}
                table={table}
                schema={schema}
                catalog={catalog}
                configurationsNames={configurations}
                connectionType={connectionType}
                tabValue={tabValue}
                configurationName={configurationName}
                setConfigurationName={setConfigurationName}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                fileName={fileName}
                setFileName={setFileName}
                fileFormatRef={fileFormatRef}
                fileFormatSelectedOption={fileFormatSelectedOption}
                fileFormatChangeHandler={fileFormatChangeHandler}
                orderByColumnRef={orderByColumnRef}
                orderByColumnSelectedOption={orderByColumnSelectedOption}
                orderByColumnChangeHandler={orderByColumnChangeHandler}
                groupByColumnRef={groupByColumnRef}
                groupByColumnsSelectedOption={groupByColumnsSelectedOption}
                groupByColumnChangeHandler={groupByColumnChangeHandler}
                orderTypeRef={orderTypeRef}
                orderTypeSelectedOption={orderTypeSelectedOption}
                orderTypeChangeHandler={orderTypeChangeHandler}
                SQLQuery={SQLQuery}
                setSQLQuery={setSQLQuery}
                distinct={distinct}
                setDistinct={setDistinct}
                filterCondition={filterCondition}
                setFilterCondition={setFilterCondition}
                aggregations={aggregations}
                setAggregations={setAggregations}
                limitValue={limitValue}
                setLimitValue={setLimitValue}
                confToEdit={confToEdit}
              />
            )}

            <Stack
              direction="row"
              sx={{
                alignSelf: "center",
                marginTop: "auto",
                justifyContent: "center",
              }}
            >
              <Button
                title="Cerrar"
                type="button"
                onClick={cleanAndClose}
                color="light-grey"
              />
              <Button
                title="Aceptar"
                type="button"
                onClick={toggleConfirmEditConfModal}
                color="blue"
                disabled={!isFormCompleted()}
              />
            </Stack>
          </Box>
          <ConfirmationModal
            open={openConfirmEditConfModal}
            handleClose={toggleConfirmEditConfModal}
            handleAccept={handleAccept}
            message={`editar la configuración ${confToEdit?.nombre}`}
            title={"Editar configuración"}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditConfigurationModal;
