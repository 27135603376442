import Grid from '@mui/material/Grid'
import InputTitle from '../forms/InputTitle'

const SummaryRowTitles = () => {
  return (
    <>
    <Grid item xs={0.5}></Grid>
    <Grid item xs={5.5}>
      <InputTitle title="Columna" />
    </Grid>
    <Grid item xs={5}>
      <InputTitle title="Función" />
    </Grid>
    <Grid item xs={1}>
     
     </Grid>
  </>
  )
}

export default SummaryRowTitles