import Tooltip from "@mui/material/Tooltip";

interface MenuItemProps {
  MenuIcon: any;
  title: string;
  handleClickItemMenu: any;
}

const MenuItem = ({ MenuIcon, title, handleClickItemMenu }: MenuItemProps) => {
  return (
    <Tooltip disableFocusListener disableTouchListener title={title}>
      <MenuIcon sx={{ color: "white" }} onClick={handleClickItemMenu}/>
    </Tooltip>
  );
};

export default MenuItem;
