import { useContext, useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../api/axios";
import FlowsProvider from "../../../context/FlowsContext";
import {
  checkIfAllColumnNotExists,
  checkIfColumnNotExists,
  filterIdFromColumns,
  findBeforeElementPosition,
  searchStepper,
} from "../utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import BaseModal from "./BaseModal";
import ShadowBox from "./ShadowBox";
import useDialog from "../../../hooks/useDialog";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
import { Typography } from "@mui/material";
interface DeleteColumnProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: any;
  putItem: any;
}

const DeleteColumn = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: DeleteColumnProps) => {
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemToEdit } = flowState;
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);

  /********************************* CARGA DE DATOS ***********************************/

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow?.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      getColumns();
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && dataItem?.length > 0) {
      setSelectedColumns(
        dataItem?.map((c: any) => {
          return { id: c.value, label: c.label };
        })
      );
    }
  }, [dataItem]);

  /******************************* POSTEAR ITEM *********************************************/
  const saveDeleteInfo = () => {
    const columnsToDelete: any = [];
    selectedColumns
      .filter((col: any) => {
        return (
          col.id !== "todas" &&
          !checkIfColumnNotExists(
            col.label,
            dataColumns.map((col: any) => {
              return { label: col.columna };
            })
          )
        );
      })
      .map((column: any) => {
        columnsToDelete.push(column.label);
      });
    const completedInfo = {
      tipo: "eliminar_columna",
      columna: columnsToDelete,
    };
    if (dataItem) {
      putItem(
        ITEM_URL(data_flow.id, active_stepper, itemToEdit.id),
        completedInfo
      );
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), completedInfo);
    }
    cleanAndClose();
    toggleConfirmationModal();
  };

  /******************************* VALIDACIONES *********************************************/

  const isFormInCompleted = () => {
    return selectedColumns === undefined || selectedColumns?.length === 0
      ? true
      : false ||
          (selectedColumns &&
            dataColumns &&
            checkIfAllColumnNotExists(selectedColumns, dataColumns));
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setSelectedColumns([]);
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    handleClose();
  };

  return (
    <BaseModal
      open={open}
      title="Eliminar columnas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveDeleteInfo}
      confirmationModalMessage={
        selectedColumns && selectedColumns.length > 1
          ? "eliminar las columnas"
          : "eliminar la columna"
      }
      disabledAcceptButton={isFormInCompleted()}
      height="400px"
      hasResultsTable
    >
      <SimpleBackdrop open={isLoadingColumns} message={"Cargando datos..."} />
      <ShadowBox margin>
        {selectedColumns &&
          dataColumns &&
          selectedColumns.filter((col: any)=> col.id !== "todas").some((col: any) =>
            checkIfColumnNotExists(
              col.label,
              dataColumns.map((item: any) => {
                return { id: item.id, label: item.columna };
              })
            )
          ) &&     <Typography sx={{ color: "var(--red)", marginLeft: "10px", marginBottom:"10px" }}>
          Las columnas no existentes en la fuente actual no se tendrán en cuenta
          y se borrarán en pasos posteriores.
        </Typography>}
    
        <CheckmarkSelect
          label="Seleccionar columnas"
          selectedItems={selectedColumns}
          setSelectedItems={setSelectedColumns}
          items={filterIdFromColumns(dataColumns)?.map((item: any) => {
            return { id: item.id, label: item.columna };
          })}
          width="100%"
        />
      </ShadowBox>
    </BaseModal>
  );
};

export default DeleteColumn;
