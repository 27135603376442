import { Stack, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import Button from "../../buttons/Button";

interface NotAllowedToDeleteModalProps {
  open: boolean;
  handleClose: () => void;
}

const NotAllowedToDeleteModal = ({
  open,
  handleClose,
}: NotAllowedToDeleteModalProps) => {
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="xl" title={"No es posible eliminar el stepper"}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "#172D40",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
         No es posible eliminar el stepper ya que la fuente está siendo utilizada en un Concat o Merge
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Aceptar"
            type="button"
            onClick={handleClose}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default NotAllowedToDeleteModal;
