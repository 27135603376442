import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import { COMPANY_URL, GET_ALL_COMPANIES_URL } from "../../api/axios";
import {
  formatCompanyOptions,
  handleMenuAndNavigation,
  updateAuthAndCompany,
} from "./utils";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import logoAlpha from "../../assets/logoAlpha2.png";
import Select from "../forms/Select";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useLogout from "../../hooks/useLogout";
import useSingleSelect from "../../hooks/useSingleSelect";
import { useLocation } from "react-router-dom";
import {
  company_buttons_container_styles,
  company_container_styles,
  company_form_box_styles,
  company_form_container_styles,
  company_logo_container_styles,
  company_logo_styles,
  company_title_styles,
  container_100_styles,
  error_text_styles,
} from "../../styles/app-styles";
import { selectStyles } from "../../styles/select.styles";
/**
 * @component
 * @name CompanySelector
 * @description - Componente que muestra el formulario para elegir la compañia
 * @param {Object} props - Props del componente
 * @returns {JSX.Element}
 * @category Login
 */
interface CompanySelectorProps {
  menu?: boolean;
  handleCloseBasicModal?: any;
}

const CompanySelectorComponent = ({
  menu,
  handleCloseBasicModal,
}: CompanySelectorProps) => {
  const { t } = useLanguage();
  const { setAuth, auth }: any = useAuth();
  const navigate = useNavigate();
  const companySelectRef: any = useRef(null);
  const { setCompany: setCompanyContext, company: companyContext }: any =
    useInfoContext();
  const [company, setCompany] = useState<any>(companyContext);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const location = useLocation();
  const [
    logout,
    isLoadingLogout,
    openLogoutSnackbar,
    handleToggleLogoutSnackbar,
    messageLogoutSnackbar,
    severityLogout,
  ] = useLogout();

  /****************************** LLAMADAS AL BACK ******************************/

  const {
    data: dataGetAllCompanies,
    isLoading: isLoadingAllCompanies,
    isError: isErrorAllCompanies,
  } = useApiQuery(GET_ALL_COMPANIES_URL, true, t("company.getCompaniesError"));

  const {
    data: dataGetUserCompany,
    isLoading: isLoadingGetUserCompany,
    isError: isErrorGetUserCompany,
  } = useApiQuery(COMPANY_URL, true, t("company.getCompanyError"));

  const [
    companySelectedOption,
    setCompanySelectedOption,
    companyOptionChangeHandler,
  ] = useSingleSelect(undefined, undefined, company ? company : undefined);

  /*************************** CARGA DE DATOS ***********************************/

  useEffect(() => {
    if (dataGetUserCompany) {
      if (dataGetUserCompany.id) {
        const companyObj = {
          value: dataGetUserCompany.id,
          label: dataGetUserCompany.nombre_visual,
        };
        setCompany(companyObj);
        setCompanySelectedOption(companyObj);
        setCompanyContext(companyObj);
      } else {
        setCompany(null);
        setCompanyContext(null);
      }
      setDelayHelper(true);
    }
  }, [dataGetUserCompany]);



  /********************************** ENVÍO DE FORMULARIO *********************************/

  const onSuccessChangeCompany = () => {
    localStorage.removeItem("flowId");
    localStorage.removeItem("schemaId");
    localStorage.removeItem("summaryId");
    localStorage.removeItem("validationId");
    localStorage.removeItem("pipelineId");
    localStorage.removeItem("pipelineEditFile");
    updateAuthAndCompany(companySelectedOption, setAuth, setCompanyContext);
    handleMenuAndNavigation(menu, auth, handleCloseBasicModal, navigate, location.pathname.includes("administracion-datos/carpeta") ? location.pathname.includes("administracion-datos/carpeta") :undefined);
  };

  const { isLoading: isLoadingChangeCompany, callApi: changeCompany } = useApi(
    COMPANY_URL,
    "PUT",
    t("company.codes"),
    undefined,
    onSuccessChangeCompany,
    undefined,
    false
  );

  const handleConfirmCompany = () => {
    if (companySelectedOption) {
      const data = {
        id: companySelectedOption?.value,
      };
      changeCompany(COMPANY_URL, data);
    }
  };

  /********************************* DESLOGUEO *********************************/

  const handleLogout = () => {
    logout();
  };


  return (
    <>
      <SimpleBackdrop
        open={
          isLoadingAllCompanies ||
          isLoadingGetUserCompany ||
          isLoadingChangeCompany
        }
        message={t("company.loadingCompanies")}
      />
      <Stack sx={menu ? undefined : company_container_styles}>
        <Stack sx={company_form_container_styles}>
          <Box sx={company_logo_container_styles}>
            <img style={company_logo_styles} src={logoAlpha} alt="logo" />
          </Box>
          <h2 style={company_title_styles}>{t("company.selectCompany")}</h2>
          <Stack sx={company_form_box_styles}>
            {!isErrorAllCompanies &&
              !isLoadingGetUserCompany && delayHelper /*&&
              company &&
              typeof company !== "string"*/ && (
                <Box sx={container_100_styles}>
                  <Select
                    options={formatCompanyOptions(dataGetAllCompanies)}
                    styles={selectStyles(companySelectedOption)}
                    reference={companySelectRef}
                    onChange={companyOptionChangeHandler}
                    isClearable
                    closeMenuOnSelect
                    isSearchable
                    placeholder={t("company.chooseCompany")}
                    defaultValue={company}
                  />
                  {isErrorGetUserCompany && (
                    <Typography variant="body1" sx={error_text_styles}>
                      {t("company.userCompanyError")}
                    </Typography>
                  )}
                </Box>
              )}
            {isErrorAllCompanies && (
              <Typography variant="body1" sx={error_text_styles}>
                {t("company.companiesError")}
              </Typography>
            )}
            <Stack sx={company_buttons_container_styles}>
              <Button
                title={t("company.cancel")}
                color="grey"
                type="button"
                onClick={menu ? handleCloseBasicModal : handleLogout}
              />
              <Button
                title={!menu ? t("company.start") : "Aceptar"}
                color="blue"
                type="submit"
                onClick={handleConfirmCompany}
                disabled={!company && !companySelectedOption}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <SimpleBackdrop
        open={isLoadingLogout}
        message={t("company.closingSession")}
      />
      <CustomSnackbar
        open={openLogoutSnackbar}
        handleClose={handleToggleLogoutSnackbar}
        message={messageLogoutSnackbar}
        severity={severityLogout}
      />
    </>
  );
};

export default CompanySelectorComponent;
