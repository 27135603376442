import { createContext, useState } from "react";

const InfoContext = createContext({});

export const InfoProvider = ({ children }: { children: React.ReactNode }) => {

  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [company, setCompany] = useState<any>();

  return (
    <InfoContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        company,
        setCompany
      }}
    >
      {children}
    </InfoContext.Provider>
  );
};

export default InfoContext;
