import { texts } from "../../texts";
import { typeOptions } from "./constants";

export const formatSchemaRow = (
  pipelineSelectedOption: any,
  sheetsSelectedOptions: any,
  filterTypeSelectedOption: any
) => {
  return {
    pipeline: pipelineSelectedOption,
    sheets: sheetsSelectedOptions?.length > 0 ? sheetsSelectedOptions.map((sheet: any) => {
      return sheet?.value !== null
        ? sheet
        : {
            value: null,
            label: `Hoja: "${sheet.label}" eliminada, reemplazar.`,
          };
    }): [],
    filterType: filterTypeSelectedOption,
  };
};

export const validateSchemaRow = (
  pipelineSelectedOption: any,
  sheetsSelectedOptions: any,
  filterTypeSelectedOption: any,
  actualSheets: any
) => {
  return {
    pipeline: pipelineSelectedOption,
    sheets: sheetsSelectedOptions?.length > 0 ? sheetsSelectedOptions.map((sheet: any) => {
      // Encuentra la hoja en actualSheets que tiene el mismo label
      const matchingSheet = actualSheets.find((actualSheet: any) => actualSheet.nombre === sheet.label);

      // Si se encuentra una hoja coincidente
      if (matchingSheet) {
        return {
          ...sheet,
          value: matchingSheet.id, // Usa el id de la hoja coincidente
        };
      }

      // Si no se encuentra una hoja coincidente
      return {
        value: null,
        label: `Hoja: "${sheet.label}" no existente, reemplazar.`,
      };
    }) : [],
    filterType: filterTypeSelectedOption,
  };
};

export const validateRow = (
  schema: any,
  row: any,
  schemaDispatch: any,
  schemaState: any
) => {
  let validate = true;
  const isSheetTodas =
    row.sheets === undefined ||
    row.sheets?.length === 0 ||
    row.sheets.length === schemaState.sheetsList.length;
  //Evitar que pipeline y filterType estén vacíos
  if (row.pipeline === undefined) {
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: texts.adminSchemas.addSchemaRow.errorPipelineEmpty,
    });
    validate = false;
  } else if (row.filterType === undefined) {
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: texts.adminSchemas.addSchemaRow.errorFilterTypeEmpty,
    });
    validate = false;
  }

  //Evitar la combinación Todas - Excluir
  else if (isSheetTodas && row.filterType.label === "EXCLUIR") {
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: texts.adminSchemas.addSchemaRow.errorTodasExcluir,
    });
    validate = false;
  }

  //Permitir la opción Todas solo en la primer linea
  else if (isSheetTodas && schema?.length > 0) {
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: texts.adminSchemas.addSchemaRow.errorTodasFirst,
    });
    validate = false;
  }

  //Si se elije todas no se pueden agregar más filas
  else if (
    schema.some(
      (row: any) => row.sheets === undefined || row.sheets?.length === 0
    )
  ) {
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: texts.adminSchemas.addSchemaRow.errorTodasUsed,
    });
    validate = false;
  } else {
    //Cuando uso un pipeline, debo sacarlo del listado de pipelines
    if (row.pipeline !== undefined) {
      let newPipelinesOptions = schemaState.pipelinesRestList;

      newPipelinesOptions = newPipelinesOptions.filter(
        (option: any) => option.value !== row.pipeline.value
      );

      schemaDispatch({
        type: "SET_PIPELINES_REST_LIST",
        payload: newPipelinesOptions,
      });
    }

    //Ir sacando del select las hojas seleccionadas cuando es INCLUIR
    if (row.filterType.label === "INCLUIR" && row.sheets !== undefined) {
      let newSheetsOptions = schemaState.sheetsRestList;
      row.sheets.forEach((sheet: any) => {
        newSheetsOptions = newSheetsOptions.filter(
          (option: any) => option.value !== sheet.value
        );
      });
      schemaDispatch({
        type: "SET_SHEETS_REST_LIST",
        payload: newSheetsOptions,
      });
    }

    //Cuando es excluir, sacar del select todas las demás sheets, excepto las seleccionadas
    if (row.filterType.label === "EXCLUIR" && !isSheetTodas) {
      let newSheetsOptions = schemaState.sheetsRestList;
      newSheetsOptions = newSheetsOptions.filter((option: any) =>
        row.sheets?.some((sheet: any) => sheet.value === option.value)
      );
      schemaDispatch({
        type: "SET_SHEETS_REST_LIST",
        payload: newSheetsOptions,
      });
    }

    //Cuando uso excluir una vez, debo sacarlo como opción del select
    if (row.filterType.label === "EXCLUIR" && !isSheetTodas) {
      schemaDispatch({
        type: "SET_FILTER_TYPE_REST_LIST",
        payload: [{ value: 1, label: "INCLUIR" }],
      });
    }
  }
  return validate;
};

export const validateDeleteRow = (
  schema: any,
  row: any,
  schemaDispatch: any,
  schemaState: any
) => {
  const isSheetTodas = row.sheets === undefined || row.sheets?.length === 0;
  //Si elimino un pipeline, debo restituirlo en la lista de opciones de pipelines
  if (row.pipeline !== undefined) {
    const newPipelinesOptions = schemaState.pipelinesRestList;
    newPipelinesOptions.push(row.pipeline);
    schemaDispatch({
      type: "SET_PIPELINES_REST_LIST",
      payload: newPipelinesOptions,
    });
  }

  //Si el filterType de la row es INCLUIR, restituir las sheets al listado de sheets
  if (row.filterType.label === "INCLUIR" && !isSheetTodas) {
    const newSheetsOptions = schemaState.sheetsRestList;
    row.sheets?.forEach((sheet: any) => {
      if (sheet.value !== null) {
        newSheetsOptions.push(sheet);
      }
    });
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: newSheetsOptions,
    });
  }

  //Si el filterType de la row es EXCLUIR, restituir las sheetRestList agregando todas las del listado original pero sacando las que estén en schema con filterType "INCLUIR". Además debo volver a agregarlo en el listado de filterTypeOptions.
  if (row.filterType.label === "EXCLUIR") {
    const newSheetsOptions = schemaState.sheetsList;

    const sheetsInSchema = schema
      .filter((row: any) => row.filterType.label !== "EXCLUIR")
      .map((row: any) => row.sheets)
      .flat();

    const newFilteredOptions = newSheetsOptions.filter((sheet: any) =>
      sheetsInSchema.every(
        (sheetInSchema: any) => sheet.value !== sheetInSchema.value
      )
    );
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: newFilteredOptions,
    });
    schemaDispatch({
      type: "SET_FILTER_TYPE_REST_LIST",
      payload: typeOptions,
    });
  }

  return true;
};

export const isValidName = (
  listToCheck: any[] | undefined,
  value: string,
  isEdit?: boolean,
  editName?: string
) => {
  return listToCheck
    ? !listToCheck.some((item: any) =>
        isEdit
          ? item.nombre.toLowerCase() === value.toLowerCase() &&
            value !== editName
          : item.nombre.toLowerCase() === value.toLowerCase()
      )
    : true;
};

export const setSchemaSelects = (
  schema: any,
  schemaDispatch: any,
  schemaState: any
) => {
  let newPipelinesOptions = schemaState.pipelinesRestList;
  let newSheetsOptions = schemaState.sheetsRestList;
  schema.forEach((row: any) => {
    // Filtramos de la lista de pipelines los pipelines seleccionados previamente
    newPipelinesOptions = newPipelinesOptions.filter(
      (option: any) => option.value !== row.criterio_id
    );

    //Ir sacando del select las hojas seleccionadas cuando es INCLUIR
    if (
      row.flag_incluir &&
      (row.hojas !== undefined || row.hojas.length === 0)
    ) {
      row.hojas.forEach((sheet: any) => {
        newSheetsOptions = newSheetsOptions.filter(
          (option: any) => option.value !== sheet.value
        );
      });
    }
    //Cuando es excluir, sacar del select todas las demás sheets, excepto las seleccionadas
    if (!row.flag_incluir && row.hojas.length > 0) {
      let newSheetsOptions = schemaState.sheetsRestList;
      newSheetsOptions = newSheetsOptions.filter((option: any) =>
        row.sheets?.some((sheet: any) => sheet.value === option.value)
      );
    }
  });

  if (
    schema.some((row: any) => {
      return !row.flag_incluir;
    })
  ) {
    schemaDispatch({
      type: "SET_FILTER_TYPE_REST_LIST",
      payload: [{ value: 1, label: "INCLUIR" }],
    });
  }
  schemaDispatch({
    type: "SET_PIPELINES_REST_LIST",
    payload: newPipelinesOptions,
  });
  schemaDispatch({
    type: "SET_SHEETS_REST_LIST",
    payload: newSheetsOptions,
  });
};
