import { useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import axios from "../../api/axios";
import { LOGOUT_URL } from "../../api/axios";
import Stack from "@mui/material/Stack";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import logoAlpha from "../../assets/AlphaWhite.png";

/**
 * @component
 * @name PersistLogin
 * @description Ni bien se renderiza este componente, hace un llamado a la función refresh().  Esta función hará un llamado al endpoint de refresh token enviando las credenciales en la Cookie. Cuando envía esa cookie al endpoint de Refresh, nos envía de regreso un nuevo Access Token, el cual se utiliza antes de que el componente RequireAuth desloguee al usuario de la aplicación. Dado que no se quiere hacer un refresh cada vez que se solicita una página protegida, sino únicamente cuando se perdió el access token por refrescar la página o ir a otra página y volver, se chequea primero si ya existe el accessToken o no, y en caso de no existir ahí se llama la función refresh().
 * @returns {JSX.Element}
 * @category Authentication
 */

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const { auth, persist }: any = useAuth();
  const [
    logout,
    isLoadingLogout,
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    severity,
  ] = useLogout();

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        logout();
      } finally {
        //esto es para no caer en un ciclo infinito de loading.
        isMounted && setIsLoading(false);
      }
    };

    if (!auth?.access_token && persist) {
      verifyRefreshToken();
    } else if (!auth?.access_token && !persist) {
      axios.post(
        LOGOUT_URL,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );
    } else if (auth?.access_token && location.pathname.startsWith("/login")) {
      navigate("/consolidacion-archivos/administracion-pipelines");
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {!persist ? (
        <Outlet />
      ) : isLoading ? (
        <Stack
          sx={{
            width: "100vw",
            height: "100vh",
            background: "var(--blue)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logoAlpha} alt="logo" width={100} />
        </Stack>
      ) : (
        <Outlet />
      )}
      <SimpleBackdrop open={isLoadingLogout} message="Cerrando sesión..." />
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleToggleSnackbar}
        message={messageSnackbar}
        severity={severity}
      />
    </>
  );
};

export default PersistLogin;
