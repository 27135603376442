import Stack from "@mui/material/Stack";
import React, { useEffect, useRef, useState } from "react";
import ColorTabs from "../colorTabs/ColorTabs";
import { TabValue } from "../colorTabs/types";
import { transformDataTaskManager } from "./transformData";
import useDialog from "../../hooks/useDialog";
import ConfirmationModal from "../dialog/ConfirmationModal";
import "./styles.css";
import { formatDate, tabs, truncateText } from "./utils";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ComponentLayout from "../layout/ComponentLayout";
import { useApiQuery } from "../../hooks/useApiQuery";
import {
  DELETE_TASK_URL,
  GET_FLOW_TASKS_URL,
  GET_REPORT_TASKS_URL,
  GET_SCHEME_TASK_REPORT,
  GET_TASKS_URL,
  GET_TASK_ERRORS_URL,
} from "../../api/axios";
import { texts as textsjs } from "../../texts.js";
import useApi from "../../hooks/useApi";
import DataGridWrapper from "./DataGridWrapper";
import Dialog from "../dialog/Dialog";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import { Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { useLocation } from "react-router-dom";
import ViewSchemeReportModal from "./ViewSchemeReportModal";
import ViewReportModal from "./ViewReportModal";
import ViewFlowReportModal from "./ViewFlowReportModal";

const TaskManager = () => {
  const location = useLocation();
  const [tabValue, setTabValue] = useState<TabValue>("FLUJOS");
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(6);
  const [openDeleteModal, toggleDeleteModal] = useDialog();
  const [idToDelete, setIdToDelete] = useState<number | undefined>();
  const [openDetailsModal, handleToggleDetailsModal] = useDialog();
  const [openSchemeReportModal, handleToggleSchemeReportModal] = useDialog();
  const [openReportModal, handleToggleReportModal] = useDialog()
  const [openFlowReportModal, handleToggleFlowReportModal] = useDialog();
  const [errorText, setErrorText] = useState<string>("");
  const [linkFile, setLinkFile] = useState<string>("");
  const [report, setReport] = useState<any>();
  const [flowReport, setFlowReport] = useState<any>();
  const [isIconClicked, setIsIconClicked] = useState(false);
  const {
    data: dataEsquemas,
    isLoading: isLoadingEsquemas,
    error: errorEsquemas,
    refetch: refetchEsquemas,
  } = useApiQuery(GET_TASKS_URL, true, textsjs.taskManager.getTasks.error);

  const {
    data: dataFlujos,
    isLoading: isLoadingFlujos,
    error: errorFlujos,
    refetch: refetchFlujos,
  } = useApiQuery(GET_FLOW_TASKS_URL, true, textsjs.taskManager.getTasks.error);

  const {
    data: dataInformes,
    isLoading: isLoadingInformes,
    error: errorInformes,
    refetch: refetchInformes,
  } = useApiQuery(
    GET_REPORT_TASKS_URL,
    true,
    textsjs.taskManager.getTasks.error
  );
  
  const {
    data: taskDetail,
    isLoading: isLoadingTaskDetail,
    callApi: getTaskDetail,
  } = useApi(
    undefined,
    "GET",
    textsjs.taskManager.getTaskDetails.codes,
    undefined,
    handleToggleDetailsModal,
    undefined,
    false
  );

  const {
    data: schemeTaskReport,
    isLoading: isLoadingSchemeTaskReport,
    callApi: getSchemeTaskReport,
  } = useApi(
    undefined,
    "GET",
    textsjs.taskManager.getTaskDetails.codes,
    undefined,
    handleToggleSchemeReportModal,
    undefined,
    false
  );

  useEffect(() => {
    if (location.state && location.state.tab === "ESQUEMAS") {
      setTabValue("ESQUEMAS");
    }
  }, [location]);

  const onSuccessCancelTask = () => {
    tabValue === "ESQUEMAS" && refetchEsquemas();
    tabValue === "FLUJOS" && refetchFlujos();
    tabValue === "INFORMES" && refetchInformes();
    setIdToDelete(undefined);
  };

  const { isLoading: isLoadingCancelTask, callApi: cancelTask } = useApi(
    undefined,
    "PUT",
    textsjs.taskManager.cancelTask.codes,
    undefined,
    onSuccessCancelTask,
    undefined,
    false
  );

  // ************** CONFIGURACIONES DE TABS *****************

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const tabConfigurations = {
    ESQUEMAS: {
      isLoading: isLoadingEsquemas,
      error: errorEsquemas,
    },
    FLUJOS: {
      isLoading: isLoadingFlujos,
      error: errorFlujos,
    },
    INFORMES: {
      isLoading: isLoadingInformes,
      error: errorInformes,
    },
  };

  // ************** TRANSFORMACIÓN DE DATOS *****************

  const transformData = (data: any, tabValue: string) => {
    const { columns, rows } = transformDataTaskManager(
      data,
      tabValue,
      buttonsActions
    );
    setColumns(columns);
    setRows(rows);
  };

  useEffect(() => {
    
    switch (tabValue) {
      case "ESQUEMAS":
        transformData(dataEsquemas, tabValue);
        break;
      case "FLUJOS":
        if (dataFlujos) {
          transformData(dataFlujos, tabValue);
        }
        break;
      case "INFORMES":
        if (dataInformes) {
          transformData(dataInformes, tabValue);
        }
        break;
      default:
        break;
    }
  }, [tabValue, dataEsquemas, dataFlujos, dataInformes]);

  const transformColumnDate = (rows: any[]) => {
    return rows.map((row: any) => {
      row.fecha = formatDate(row.fecha);
      return row;
    });
  };

  // ************** FUNCIONES PARA LAS ACCIONES *****************

  const saveDeleteTask = (id: number) => {
    setIdToDelete(id);
    toggleDeleteModal();
  };

  const handleGetTaskDetail = (id: number) => {
    getTaskDetail(GET_TASK_ERRORS_URL(id));
  };

  const handleDeleteTask = () => {
    if (idToDelete) {
      cancelTask(DELETE_TASK_URL(idToDelete));
    }
    toggleDeleteModal();
  };

  const saveLinkButton = (link: string) => {
    setLinkFile(link);
    handleToggleDetailsModal();
  };

  const saveTaskError = (error: string) => {
    setErrorText(error);
    handleToggleDetailsModal();
  };

  const viewFileReportModal = (id: number) => {
    getSchemeTaskReport(GET_SCHEME_TASK_REPORT(id));
  };

  const viewReportModal = (report: any) => {
    setReport(report);
    handleToggleReportModal();
  };

  const viewFlowReportModal = (report: any) => {
    setFlowReport(report);
    handleToggleFlowReportModal();
  };

  const buttonsActions = {
    saveDeleteTask,
    handleGetTaskDetail,
    saveTaskError,
    saveLinkButton,
    viewFileReportModal,
    viewFlowReportModal,
    viewReportModal 
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(linkFile);
      setIsIconClicked(true);
      setTimeout(() => setIsIconClicked(false), 2000);
    } catch (error) {
      console.error("Error al copiar al portapapeles:", error);
    }
  };

  const handleCloseDetailModal = () => {
    handleToggleDetailsModal()
    setErrorText("")
    setLinkFile("")
  }

  return (
    <ComponentLayout title="Gestor de Tareas" icon={<AssignmentIcon />}>
      <SimpleBackdrop
        open={isLoadingCancelTask}
        message={textsjs.taskManager.cancelTask.loading}
      />
      <SimpleBackdrop
        open={isLoadingTaskDetail}
        message={textsjs.taskManager.getTaskDetails.loading}
      />
      <SimpleBackdrop
        open={isLoadingSchemeTaskReport}
        message={textsjs.taskManager.getTaskDetails.loading}
      />
      <ColorTabs
        value={tabValue}
        handleChange={handleChange}
        tabs={tabs}
        marginBottom="20px"
      />
      <Stack width="100%">
        <DataGridWrapper
          tabValue={tabValue}
          tabConfigurations={tabConfigurations}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Stack>
      <ConfirmationModal
        open={openDeleteModal}
        handleClose={toggleDeleteModal}
        handleAccept={handleDeleteTask}
        message={"cancelar esta tarea"}
        title="Cancelar Tarea"
      />
      <ViewSchemeReportModal
        openReportModal={openSchemeReportModal}
        handleToggleReportModal={handleToggleSchemeReportModal}
        report={schemeTaskReport}
      />
       <ViewReportModal
        openReportModal={openReportModal}
        handleToggleReportModal={handleToggleReportModal}
        report={report}
      />
      <ViewFlowReportModal
        openReportModal={openFlowReportModal}
        handleToggleReportModal={handleToggleFlowReportModal}
        report={flowReport}
      />
      <Dialog
        open={openDetailsModal}
        handleClose={handleCloseDetailModal}
        title={"Detalle de tarea"}
        maxWidth="md" //si se vuelve a la tabla volver a xl
      >
        {taskDetail && (
          <Stack sx={{ minWidth: "75vw" }}>
            <SimpleTable
              columns={transformDataSimpleTable(taskDetail).columns}
              rows={
                transformDataSimpleTable(transformColumnDate(taskDetail)).rows
              }
              toolbar={true}
              exportButton={false}
              filterButton={true}
            />
          </Stack>
        )}
        {linkFile && (
          <Stack
            direction="column"
            sx={{
              minWidth: "500px",
              minHeight: "100px",
              alignItems: "center",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"subtitle1"}
              sx={{
                fontWeight: "bold",
              }}
            >
              Ubicación del archivo generado:
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontWeight: "bold",
                  wordBreak:"break-word"
                }}
              >
                {linkFile}
              </Typography>
              <Tooltip
                title={isIconClicked ? "Copiado!" : "Copiar"}
                placement={"right"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                {isIconClicked ? (
                  <DoneOutlineIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    color={"primary"}
                  />
                ) : (
                  <ContentCopyIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleCopyClick}
                    color={"primary"}
                  />
                )}
              </Tooltip>
            </Stack>
          </Stack>
        )}
        {errorText && (
          <Stack direction="row" sx={{ minWidth: "500px", color: "black" }}>
            <p>{errorText}</p>
          </Stack>
        )}
      </Dialog>
    </ComponentLayout>
  );
};

export default TaskManager;
