import { StyledAddButton } from "./styled";

interface AddButtonProps {
  onClick?: (() => void) | ((e: any) => void) | undefined;
  disabled?: boolean;
  align?: boolean;
  width?: "little" | "big"
}

const AddButton = ({ onClick, disabled, align, width}: AddButtonProps) => {
  return (
    <StyledAddButton type="button" onClick={onClick} disabled={disabled} align={align} width={width}>
      +
    </StyledAddButton>
  );
};

export default AddButton;
