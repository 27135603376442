import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../../forms/types";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import ShadowBox from "../../../../flowConfiguration/Modals/ShadowBox";
import { form_label } from "../../../../../styles/app-styles";
import Select from "../../../../forms/Select";
import { optionalSelectStyles, selectStyles } from "../../../../../styles/select.styles";
import ToggleButton from "../../../../flowConfiguration/Modals/NewColumn/ToggleButton";
import { Delete } from "../../../../icons";
import { functionOptions } from "../../../../../utils/util";


interface ConditionProps {
  columns: any;
  condition: any;
  conditions: any[];
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
  operatorType: string;
}

const Condition = ({
  columns,
  condition,
  conditions,
  setConditions,
  operatorType,
}: ConditionProps) => {
  const [conditionalValue, setConditionalValue] = useState<any>(condition?.valor?.tipo && condition.valor?.tipo !== "columna"?condition.valor.valor_comparar :undefined);

  const [valueType, setValueType] = useState<any>(condition?.valor?.tipo ? condition?.valor?.tipo :"columna");

  const conditionalColumnRef: any = useRef(null);
  const operatorRef: any = useRef(null);
  const valueColumnRef: any = useRef(null);
  const functionColumnRef: any = useRef(null);


  const [
    conditionalColumnSelectedOption,
    setConditionSelectedOption,
    conditionalColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, condition?.columna_condicional ? condition?.columna_condicional :undefined);

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(undefined, undefined, condition?.operador ? condition?.operador :undefined);

  const [
    valueColumnSelectedOption,
    setValueColumnSelectedOption,
    valueColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, (condition?.valor?.tipo === "columna"
  ? condition?.valor?.valor_comparar
  : undefined) );

  const [
    functionColumnSelectedOption,
    setFunctionColumnSelectedOption,
    functionColumnChangeHandler,
  ] = useSingleSelect(undefined, undefined, condition?.funcion ? {value: condition.funcion, label: condition.funcion} : undefined);

  const conditionalColumnOperatorOptions = [
    { value: "ES_IGUAL_A", label: "es igual a" },
    { value: "NO_ES_IGUAL_A", label: "no es igual a" },
    { value: "CONTIENE", label: "contiene" },
    { value: "NO_CONTIENE", label: "no contiene" },
    { value: "ES_MAYOR_QUE", label: "es mayor que" },
    { value: "ES_MAYOR_O_IGUAL_QUE", label: "es mayor o igual que " },
    { value: "ES_MENOR_QUE", label: "es menor que" },
    { value: "ES_MENOR_O_IGUAL_QUE", label: "es menor o igual que " },
  ];

  /************************************* HANDLERS ****************************************/

    
  const onCustomChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    type: string
  ) => {
    switch (type) {
      case "conditionalColumn":
        conditionalColumnChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            columna_condicional: newValue,
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "operator":
        operatorChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            operador: newValue,
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "conditionalValueColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (conditions) {
          const newCondition = {
            ...condition,
            valor: {
              tipo: valueType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
        case "function":
          functionColumnChangeHandler(newValue, actionMeta);
          if (conditions) {
            const newCondition = {
              ...condition,
              funcion: newValue?.value,
            };
            setConditions(
              conditions.map((c: any) =>
                c.id === newCondition.id ? newCondition : c
              )
            );
          }
          return;
      default:
        return false;
    }
  };

  const onInputChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setConditionalValue(value);
        if (conditions) {
          const newCondition = {
            ...condition,
            valor: {
              tipo: valueType,
              valor_comparar: value,
            },
          };
          setConditions(
            conditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onToggleChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        if (value === "columna") {
          setConditionalValue("");
        } else {
          setValueColumnSelectedOption(undefined);
        }
        setValueType(value);
        const newCondition = {
          ...condition,
          valor: {
            tipo: value,
            valor_comparar: undefined,
          },
        };
        setConditions(
          conditions.map((c: any) =>
            c.id === newCondition.id ? newCondition : c
          )
        );
        return;
      default:
        return false;
    }
  };

  const handleDeleteCondition = () => {
    setConditions(
      conditions.filter((c: any) => {
        return c.id !== condition.id;
      })
    );
  };

  return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin:"12px 0px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Typography
            sx={{
              backgroundColor: "var(--blue-greeny)",
              fontWeight: "bold",
              padding: "8px 4px 5px 8px",
              borderRadius: "5px",
              marginRight: "5px",
              marginTop: "auto",
              fontSize: "14px",
              width: "30px",
              color: "white",
            }}
          >
            {operatorType}
          </Typography>
          <Box sx={{ marginRight: "8px", width: "35%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Columna</InputLabel>
            <Select
              reference={conditionalColumnRef}
              styles={selectStyles(conditionalColumnSelectedOption)}
              options={columns}
              name="Columna"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "conditionalColumn");
              }}
              closeMenuOnSelect
              placeholder="Seleccionar columna"
              isClearable
              defaultValue={conditionalColumnSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "30%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Operador</InputLabel>
            <Select
              reference={operatorRef}
              styles={selectStyles(operatorSelectedOption)}
              options={conditionalColumnOperatorOptions}
              name="Operador"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "operator");
              }}
              closeMenuOnSelect
              placeholder="Operador"
              isClearable
              defaultValue={operatorSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "35%", marginTop: "auto" }}>
            <Box sx={{ display: "flex" }}>
              <InputLabel sx={form_label}>Valor</InputLabel>

              <ToggleButton
                value={valueType}
                setValue={onToggleChange}
                value1="columna"
                value2="valor"
                type="conditionalValue"
              />
            </Box>
            {valueType === "columna" ? (
              <Select
                reference={valueColumnRef}
                styles={selectStyles(valueColumnSelectedOption)}
                options={columns}
                name="Valor"
                onChange={(newValue: any, action: any) => {
                  onCustomChange(newValue, action, "conditionalValueColumn");
                }}
                closeMenuOnSelect
                placeholder="Seleccionar columna"
                isClearable
                defaultValue={valueColumnSelectedOption}
              />
            ) : (
              <TextField
                id="value"
                label={"Valor"}
                placeholder={"Valor"}
                size="small"
                multiline
                variant="outlined"
                error={conditionalValue === ""}
                fullWidth
                value={conditionalValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange(event.target.value, "conditionalValue");
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            )}
          </Box>
          
          <Box sx={{ marginTop: "auto" }}>
            <Delete
              onClick={handleDeleteCondition}
              tooltipPlacement="right-start"
            />
          </Box>
        </Box>
        <Box sx={{ marginRight: "8px", width: "35%", marginTop: "10px" }}>
            <InputLabel sx={form_label}>Aplicar función a columna (opcional)</InputLabel>
            <Select
              reference={functionColumnRef}
              styles={optionalSelectStyles(functionColumnSelectedOption)}
              options={functionOptions}
              name="Function"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "function");
              }}
              closeMenuOnSelect
              placeholder="Función"
              isClearable
              isDisabled={!conditionalColumnSelectedOption}
              defaultValue={functionColumnSelectedOption}
            />
          </Box>
      </Box>
  );
};

export default Condition;
