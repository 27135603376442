import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import {
  form_label,
  modal_selects_container_styles,
} from "../../../styles/app-styles";
import {
  ITEM_URL,
  POST_ITEM_URL,
  POST_STEPPER_URL,
  STEPPER_URL,
} from "../../../api/axios";
import FlowsProvider from "../../../context/FlowsContext";
import useMultiSelect from "../../../hooks/useMultiSelect";
import {
  formatConcatOptions,
  getItemsOptions,
  isFileNameValid,
  isStepNameRepeated,
  searchStepper,
} from "../utils";
import useDialog from "../../../hooks/useDialog";
import ShadowBox from "./ShadowBox";
import BaseModal from "./BaseModal";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { ConfirmationModal } from "../../dialog";
interface ConcatProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const Concat = ({
  open,
  handleClose,
  postItem,
  putItem,
  dataItem,
}: ConcatProps) => {
  const [name, setName] = useState<any>("");
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const stepsToConcatSelectRef: any = useRef(null);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;

  useEffect(() => {
    if (dataItem && open) {
      setName(searchStepper(active_stepper, data_flow).nombre);
      setstepsToConcatSelectedOption(
        formatConcatOptions(dataItem.steps_a_concatenar, data_flow)
      );
    }
  }, [dataItem]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 2);
    }
  }, [open]);

  const [
    stepsToConcatSelectedOption,
    setstepsToConcatSelectedOption,
    stepsToConcatChangeHandler,
  ] = useMultiSelect();

  /*************************** POST Y PUT STEPPER Y ITEM ***************************/

  const saveConcat = () => {
    if (dataItem) {
      if (searchStepper(active_stepper, data_flow).nombre === name) {
        putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
          tipo: "concat",
          steps_a_concatenar: stepsToConcatSelectedOption?.map(
            (column: any) => {
              return column.value;
            }
          ),
        });
        cleanAndClose();
      } else {
        putStepper(undefined, {
          nombre: name,
        });
      }
    } else {
      postStepper(undefined, {
        flow: data_flow.id,
        nombre: name,
      });
    }
  };

  const onSuccessPostStepper = (data: any) => {
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: data.id,
    });
    postItem(POST_ITEM_URL(data_flow.id, data.id), {
      tipo: "concat",
      steps_a_concatenar: stepsToConcatSelectedOption?.map((column: any) => {
        return column.value;
      }),
    });
    cleanAndClose();
  };

  const onSuccessPutStepper = (data: any) => {
    putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
      tipo: "concat",
      steps_a_concatenar: stepsToConcatSelectedOption?.map((column: any) => {
        return column.value;
      }),
    });
    cleanAndClose();
  };

  const { isLoading: isLoadingPostStepper, callApi: postStepper } = useApi(
    POST_STEPPER_URL(data_flow.id),
    "POST",
    texts.flows.postStepper.codes,
    undefined,
    onSuccessPostStepper,
    undefined,
    false
  );

  const { isLoading: isLoadingPutStepper, callApi: putStepper } = useApi(
    STEPPER_URL(data_flow.id, searchStepper(active_stepper, data_flow)?.id),
    "PUT",
    texts.flows.postStepper.codes,
    undefined,
    onSuccessPutStepper,
    undefined,
    false
  );

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setName("");
    setDelayHelper(false);
    setstepsToConcatSelectedOption(undefined);
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

  return (
    <>
      {getItemsOptions(data_flow).length > 1 ? (
        <BaseModal
          open={open}
          title="Concatenación"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
          handleAccept={saveConcat}
          confirmationModalMessage={
            !dataItem ? "agregar la concatenación" : "editar la concatenación"
          }
          disabledAcceptButton={
            !stepsToConcatSelectedOption ||
            stepsToConcatSelectedOption?.length < 2 ||
            stepsToConcatSelectedOption === undefined ||
            !isFileNameValid(name)
          }
          height="400px"
        >
          <SimpleBackdrop
            open={isLoadingPostStepper}
            message={texts.flows.postItem.loading}
          />
          <ShadowBox margin>
            <TextField
              id="fontName"
              label={"Nombre del paso"}
              placeholder={"Nombre del paso"}
              size="small"
              multiline
              variant="outlined"
              helperText={
                isFileNameValid(name) || name === ""
                ? isStepNameRepeated(name, data_flow) && (dataItem === undefined || searchStepper(active_stepper, data_flow)?.nombre !== name)
                  ? texts.flows.concat.helperText.repeatedName
                  : ""
                : texts.flows.concat.helperText.invalidName
              }
              error={!isFileNameValid(name) || name === ""}
              fullWidth
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />
          </ShadowBox>

          <Stack sx={modal_selects_container_styles}>
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Selecciona los pasos a concatenar
                  </InputLabel>
                  <Select
                    isMulti
                    reference={stepsToConcatSelectRef}
                    styles={selectStyles(stepsToConcatSelectedOption)}
                    options={getItemsOptions(data_flow).filter(
                      (option: any) => {
                        return dataItem
                          ? option.value !==
                              searchStepper(active_stepper, data_flow).id
                          : option;
                      }
                    )}
                    name="Columnas a incluir"
                    onChange={stepsToConcatChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar pasos a concatenar"
                    isClearable
                    defaultValue={stepsToConcatSelectedOption}
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>
        </BaseModal>
      ) : (
        <ConfirmationModal
          open={open}
          handleClose={cleanAndClose}
          handleAccept={cleanAndClose}
          customMessage
          title={"No es posible agregar el item"}
          message={
            "No es posible agregar esta transformación al flow. Es necesario contar con al menos dos steps sin errores para poder agregarla."
          }
        />
      )}
    </>
  );
};

export default Concat;