import Stack from "@mui/material/Stack";
import ItemBar from "../itemBar/ItemBar";
import DeleteColumn from "../Modals/DeleteColumn";
import useDialog from "../../../hooks/useDialog";
import DeleteRow from "../Modals/DeleteRow";
import FlowsProvider from "../../../context/FlowsContext";
import { useContext, useEffect, useState } from "react";
import ModifyColumn from "../Modals/ModifyColumn";
import { Tool } from "./types.js";
import GroupBy from "../Modals/GroupBy/GroupBy";
import Merge from "../Modals/Merge";
import Concat from "../Modals/Concat";
import NewColumn from "../Modals/NewColumn/NewColumn";
import { Divider } from "@mui/material";
import Filter from "../Modals/Filter";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import {
  CALC_FLOW_URL,
  GET_FLOW_TASKS_URL,
  ITEM_URL,
} from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import {
  bar_container_styles,
  bar_items_container_styles,
  item_container_styles,
  toolsbar_buttons_container_styles,
} from "../../../styles/app-styles";
import { Button } from "../../buttons";
import { useNavigate } from "react-router-dom";
import { handleSetItemBarStatus, steppersHaveAnyError } from "../utils";
import { useApiQuery } from "../../../hooks/useApiQuery";
import ChangeFonts from "../Modals/ChangeFonts";
import RenameColumns from "../Modals/RenameColumns";
import DynamizeRows from "../Modals/DynamizeRows";
import DesdynamizeRows from "../Modals/DesdynamizeRows";
import SetHeader from "../Modals/SetHeader";
import AddFont from "../Modals/AddFont/AddFont";
import FileFormatModal from "../Modals/CalculationConfigs/FileFormat";

const ToolsBar = () => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { itemsBarStatus, itemToEdit, data_flow, active_stepper } = flowState;
  const [openDeleteColumn, handleToggleDeleteColumnModal] = useDialog();
  const [openFontModal, handleToggleFontModal] = useDialog();
  const [openChangeFontsModal, handleToggleChangeFontsModal] = useDialog();
  const [openNewColumnModal, handleToggleNewColumnModal] = useDialog();
  const [openModifyColumnModal, handleToggleModifyColumnModal] = useDialog();
  const [openMergeModal, handleToggleMergeModal] = useDialog();
  const [openGroupByModal, handleToggleGroupByModal] = useDialog();
  const [openConcatModal, handleToggleConcatModal] = useDialog();
  const [openDeleteRowsModal, handleToggleDeleteRowsModal] = useDialog();
  const [openDynamizeRowsModal, handleToggleDynamizeRowsModal] = useDialog();
  const [openDesdynamizeRowsModal, handleToggleDesdynamizeRowsModal] =
    useDialog();
  const [openFilterModal, handleToggleFilterModal] = useDialog();
  const [openConfirmCalcFlow, toggleConfirmCalcFlow] = useDialog();
  const [openRenameColumnsModal, handleToggleRenameColumnsModal] = useDialog();
  const [openSetHeaderModal, handleToggleSetHeaderModal] = useDialog();
  const [isLoadingPostItemAddFonts, setIsLoadingPostItemAddFonts] =
    useState<boolean>(false);

  /***************************** FUNCIONES MODALES Y BAR *****************************/

  type ToolFunctions = {
    [key: string]: () => void;
  };

  const toolFunctions: ToolFunctions = {
    nueva_fuente: handleToggleFontModal,
    cambiar_fuentes: handleToggleChangeFontsModal,
    nueva_columna: handleToggleNewColumnModal,
    eliminar_columna: handleToggleDeleteColumnModal,
    modificar_columna: handleToggleModifyColumnModal,
    merge: handleToggleMergeModal,
    group_by: handleToggleGroupByModal,
    concat: handleToggleConcatModal,
    eliminar_filas: handleToggleDeleteRowsModal,
    filtrar: handleToggleFilterModal,
    renombrar_columnas: handleToggleRenameColumnsModal,
    dinamizar: handleToggleDynamizeRowsModal,
    desdinamizar: handleToggleDesdynamizeRowsModal,
    setear_cabecera: handleToggleSetHeaderModal,
  };

  const getToolFunction = (tool: Tool) => toolFunctions[tool];

  const everyToolIsDisabled = (category: any) => {
    return category.tools.every(
      (tool: any) => tool.disabled === true || tool.separator === true
    );
  };

  // Esta función es pasada como callback al componente Addfonts para poder mostrar el backdrop de cargando
  const handleChangeIsLoading = (isLoading: boolean) => {
    setIsLoadingPostItemAddFonts(isLoading);
  };

  /********************************* POSTEAR ITEM *********************************/

  const onSuccessPostItem = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: data,
    });
    handleSetItemBarStatus(flowDispatch, data, active_stepper, itemsBarStatus);
  };

  const { isLoading: isLoadingPostItem, callApi: postItem } = useApi(
    undefined,
    "POST",
    texts.flows.postItem.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const { isLoading: isLoadingChangeFonts, callApi: postChangeFonts } = useApi(
    undefined,
    "PUT",
    texts.flows.changeFonts.codes,
    undefined,
    onSuccessPostItem
  );

  /********************************* EDITAR ITEM **********************************/

  const onSuccessGetItem = (data: any) => {
    getToolFunction(itemToEdit.tipo)();
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const { isLoading: isLoadingPutItem, callApi: putItem } = useApi(
    ITEM_URL,
    "PUT",
    texts.flows.postItem.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    true
  );

  useEffect(() => {
    if (itemToEdit) {
      getDataItem(ITEM_URL(data_flow?.id, active_stepper, itemToEdit?.id));
    } else {
      setDataItem(undefined);
    }
  }, [itemToEdit]);

  /************************************* BUTTONS ************************************/

  const { data: dataFlujos } = useApiQuery(
    GET_FLOW_TASKS_URL,
    true,
    texts.taskManager.getTasks.error
  );

  return (
    <>
      {data_flow?.nombre != "" && (
        <Stack sx={bar_container_styles}>
          <SimpleBackdrop
            open={isLoadingPostItemAddFonts}
            message={texts.flows.postItem.loading}
          />
          <SimpleBackdrop
            open={isLoadingPostItem || isLoadingPutItem}
            message={texts.flows.postItem.loading}
          />
          <SimpleBackdrop
            open={isLoadingChangeFonts}
            message={texts.flows.changeFonts.loading}
          />
          {itemsBarStatus.map((categoria: any) => {
            return (
              <Stack
                key={categoria.categoria}
                sx={bar_items_container_styles(everyToolIsDisabled(categoria))}
              >
                <Stack sx={item_container_styles}>
                  {categoria.tools.map((tool: any) => {
                    if (tool.separator) {
                      return (
                        <Divider
                          key={tool.label}
                          orientation="vertical"
                          flexItem
                          sx={{
                            bgColor: "var(--blue)",
                          }}
                        />
                      );
                    }
                    return (
                      <ItemBar
                        key={tool.label}
                        title={tool.label}
                        onClick={getToolFunction(tool.tipo)}
                        disabled={tool.disabled === true}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
          <Stack>
            <DeleteColumn
              open={openDeleteColumn}
              handleClose={handleToggleDeleteColumnModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <SetHeader
              open={openSetHeaderModal}
              handleClose={handleToggleSetHeaderModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <AddFont
              open={openFontModal}
              handleClose={handleToggleFontModal}
              putItem={putItem}
              dataItem={itemToEdit ? dataItem : undefined}
              setIsLoadingPostItemAddFonts={handleChangeIsLoading}
            />
            <ChangeFonts
              open={openChangeFontsModal}
              handleClose={handleToggleChangeFontsModal}
              postItem={postChangeFonts}
            />
            <GroupBy
              open={openGroupByModal}
              handleClose={handleToggleGroupByModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <DeleteRow
              open={openDeleteRowsModal}
              handleClose={handleToggleDeleteRowsModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <DynamizeRows
              open={openDynamizeRowsModal}
              handleClose={handleToggleDynamizeRowsModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <DesdynamizeRows
              open={openDesdynamizeRowsModal}
              handleClose={handleToggleDesdynamizeRowsModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <ModifyColumn
              open={openModifyColumnModal}
              handleClose={handleToggleModifyColumnModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <RenameColumns
              open={openRenameColumnsModal}
              handleClose={handleToggleRenameColumnsModal}
              dataItem={itemToEdit ? dataItem : undefined} //
              postItem={postItem}
              putItem={putItem}
            />
            <Merge
              open={openMergeModal}
              dataItem={itemToEdit ? dataItem : undefined}
              handleClose={handleToggleMergeModal}
              postItem={postItem}
              putItem={putItem}
            />
            <Concat
              open={openConcatModal}
              dataItem={itemToEdit ? dataItem : undefined}
              handleClose={handleToggleConcatModal}
              postItem={postItem}
              putItem={putItem}
            />
            <NewColumn
              open={openNewColumnModal}
              handleClose={handleToggleNewColumnModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
            <Filter
              open={openFilterModal}
              handleClose={handleToggleFilterModal}
              dataItem={itemToEdit ? dataItem : undefined}
              postItem={postItem}
              putItem={putItem}
            />
          </Stack>
          <Stack sx={toolsbar_buttons_container_styles}>
            <Button
              title="Calcular flujo"
              color="blue"
              type="button"
              margin={true}
              onClick={toggleConfirmCalcFlow}
              disabled={data_flow && steppersHaveAnyError(data_flow)}
            />
            <FileFormatModal
              dataFlujos={dataFlujos}
              open={openConfirmCalcFlow}
              handleClose={toggleConfirmCalcFlow}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ToolsBar;
