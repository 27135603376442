import { createTheme } from "@mui/material";
import { esES } from "@mui/x-data-grid";

export const theme = createTheme(
  {
    typography: {
      fontFamily: "Roboto, sans-serif", 
    },
    palette: {
      primary: {
        main: "#0d3940",
        light: "#8eddeb",
        dark: "#8f8fab",
      },
      secondary: {
        main: "#167282",
      },
      error: {
        main: "#E0438F",
      },
      warning: {
        main: "#ffa600",
      },
      info: {
        main: "#26bfd8",
      },
      success: {
        main: "#24A2B5",
      },
    },
  },

  esES
);
