import Stack from "@mui/material/Stack";
import { useState } from "react";
import Dialog from "../dialog/Dialog";
import { Box, Typography } from "@mui/material";
import VennChart from "../charts/VennChart";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";

interface ViewReportModalProps {
  openReportModal: boolean;
  handleToggleReportModal: () => void;
  report: any;
}

const ViewReportModal = ({
  openReportModal,
  handleToggleReportModal,
  report,
}: ViewReportModalProps) => {

  return (
    <Dialog
      open={openReportModal}
      handleClose={handleToggleReportModal}
      title={`Reporte de la ejecución`}
      maxWidth="xl"
    >
      <Stack
        sx={{
          minWidth: "1000px",
          minHeight: "100px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "var(--dark-blue)",
            marginBottom: "15px",
          }}
        >
          Resultados{" "}
          {report?.tipo === "validacion de codigos"
            ? " de la validación de códigos"
            : " del resumen de información"}
        </Typography>
        {report && (report?.tipo === "resumen" ? (
          <SimpleTable
            columns={transformDataSimpleTable(report.body).columns}
            rows={transformDataSimpleTable(report.body).rows}
            toolbar={true}
            exportButton={true}
          />
        ) : (
         
            <VennChart data={report?.body} customWidth="100%" isReport={true}/>
        ))}
      
      </Stack>
    </Dialog>
  );
};

export default ViewReportModal;
