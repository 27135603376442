import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { CompanySelector } from "../company";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { getUserCompany } from "../company/api";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import logoAlpha from "../../assets/AlphaWhite.png";
import {
  Drawer,
  DrawerHeader,
  companySelectorStyle,
  company_box_styles,
  menu_logo_alpha_styles,
  menu_logo_container_header,
} from "../../styles/app-styles";
import {
  fileConsolidationCollapseList,
  flowsCollapseList,
  menuItemsOptions,
  reportBaseCollapseList,
} from "./utils";
import AppBarComponent from "./AppBarComponent";
import ListItemComponent from "./ListItemComponent";
import useInfoContext from "../../hooks/useInfoContext";


export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const goHome = () => {
    navigate("/");
  };

  const axiosPrivate = useAxiosPrivate();
  const [openBasicModal, setOpenBasicModal] = useState(false);
  const handleOpenBasicModal = () => setOpenBasicModal(true);
  const handleCloseBasicModal = () => setOpenBasicModal(false);
  const [openMenuList, setOpenMenuList] = useState({
    openFileConsolidation: false,
    openBaseReport: false,
    openFlows: false
  });
  const [dataGetUserCompany, setDataGetUserCompany] = useState<any>(null);
  const [isLoadingUserCompany, setIsLoadingUserCompany] = useState(false);
  const [isErrorUserCompany, setIsErrorUserCompany] = useState(false);
  const { setCompany: setCompanyContext, company: companyContext }: any =
  useInfoContext();

  const handleGetCompanyResponse = (status: number, data: any) => {
    if (status === 200) {
      setDataGetUserCompany(data);
      setIsLoadingUserCompany(false);
      setCompanyContext(data);
    } else {
      setIsLoadingUserCompany(false);
      setIsErrorUserCompany(true);
    }
  };

  useEffect(() => {
    setIsErrorUserCompany(false);
    setIsLoadingUserCompany(true);
    getUserCompany(axiosPrivate, handleGetCompanyResponse);
  }, []);

  const handleOpenStates = (listOption: string) => {
    switch (listOption) {
      case "Consolidación de archivos":
        return openMenuList.openFileConsolidation;
      case "Reporte de base":
        return openMenuList.openBaseReport;
        case "Flujos":
          return openMenuList.openFlows;
      default:
        return false;
    }
  };

  const handleCollapseList = (listOption: string) => {
    switch (listOption) {
      case "Consolidación de archivos":
        return fileConsolidationCollapseList;
      case "Reporte de base":
        return reportBaseCollapseList;
        case "Flujos":
          return flowsCollapseList;
      default:
        return [];
    }
  };

  const handleClickOpenMenu = (listOption: string) => {
    switch (listOption) {
      case "Consolidación de archivos":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openFileConsolidation: !prev.openFileConsolidation,
        }));
        break;
      case "Reporte de base":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openBaseReport: !prev.openBaseReport,
        }));
        break;
        case "Flujos":
          setOpenMenuList((prev: typeof openMenuList) => ({
            ...prev,
            openFlows: !prev.openFlows,
          }));
          break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        goHome={goHome}
        dataGetUserCompany={dataGetUserCompany}
        handleOpenBasicModal={handleOpenBasicModal}
      />
      <Drawer
        variant="permanent"
        open={open}
      >
        <DrawerHeader
          sx={{
            background: "var(--greeny)",
            "& .MuiPaper-root": { backgroundColor: "var(--greeny)" },
          }}
        >
          <Box sx={menu_logo_container_header} onClick={goHome}>
            <img style={menu_logo_alpha_styles} src={logoAlpha} alt="logo" />
          </Box>
  
          <IconButton onClick={handleDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ backgroundColor: "var(--greeny)" }}>
          {menuItemsOptions.map((item, index) => (
            <ListItemComponent
              key={index}
              item={item}
              navigate={navigate}
              open={open}
              handleClickOpenMenu={handleClickOpenMenu}
              handleOpenStates={handleOpenStates}
              handleCollapseList={handleCollapseList}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
          ))}
        </List>
      </Drawer>
      <div>
        <Modal
          open={openBasicModal}
          onClose={handleCloseBasicModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={company_box_styles}>
            <CompanySelector
              menu={true}
              handleCloseBasicModal={handleCloseBasicModal}
            />
          </Box>
        </Modal>
      </div>
      <Box>
        <Modal
          open={openBasicModal}
          onClose={handleCloseBasicModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={companySelectorStyle}>
            <CompanySelector
              menu={true}
              handleCloseBasicModal={handleCloseBasicModal}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
