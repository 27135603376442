import { Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "../../../dialog/Dialog";
import { Button } from "../../../buttons";
import { texts } from "../../../../texts";
import useDialog from "../../../../hooks/useDialog";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import { FLOW_CONFIGURATION, FLOW_CONFIGURATION_BY_ID } from "../../../../api/axios";
import useApi from "../../../../hooks/useApi";
import Config from "./Config";

interface CalculationConfigsProps {
    dataConfigs: any;
    dataFlow: any;
    refetchConfigs: any
  }

const CalculationConfigs = ({dataConfigs, refetchConfigs, dataFlow}:CalculationConfigsProps) => {
  const [openConfigModal, toggleConfigModal] = useDialog();


  /********************************* LLAMADAS ************************************************/

  const { callApi: deleteConfig, isLoading: isLoadingDeleteConfig } = useApi(
    FLOW_CONFIGURATION_BY_ID,
    "DELETE",
    texts.flows.deleteFlowConfigs.codes,
    undefined,
    ()=>refetchConfigs(FLOW_CONFIGURATION(dataFlow?.id)),
    undefined,
    true
  );

  /********************************* HANDLERS ************************************************/

  const handleDelete = (id: number)=>{
    deleteConfig(FLOW_CONFIGURATION_BY_ID(
      dataFlow?.id,
     id
    ),)
  }

  return (
    <>
   
      <Typography
        sx={{
          fontWeight: "600",
          color: "var(--blue)",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          marginBottom: "0 !important",
          marginLeft: "auto",
          cursor: "pointer",
        }}
        onClick={toggleConfigModal}
      >
        Gestionar configuraciones
      </Typography>
      <Dialog
        open={openConfigModal}
        handleClose={toggleConfigModal}
        title={"Gestionar configuraciones"}
        maxWidth="xl"
      >
           <SimpleBackdrop
        open={isLoadingDeleteConfig}
        message={texts.flows.deleteFlowConfigs.loading}
      />

        <Stack
          sx={{
            width: "800px",
            minHeight: "350px",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", width: "100%", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "19px",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Configuraciones guardadas
            </Typography>
          
          </Stack>
          {dataConfigs !== undefined &&
            dataConfigs?.length > 0 &&
            dataConfigs?.map((config: any) => {
              return <Config key={config.id} config={config} handleDelete={handleDelete}/>
            })}
          {dataConfigs !== undefined &&
            dataConfigs?.length === 0 && (
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "var(--dark-grey)",
                  margin: "auto",
                  fontSize: "20px",
                }}
              >
                Aún no hay configuraciones
              </Typography>
            )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={() => {
              toggleConfigModal();
            }}
            color="light-grey"
          />
        </Stack>
      </Dialog>
     
    </>
  );
};

export default CalculationConfigs;
