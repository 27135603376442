import { Stack,  Typography } from "@mui/material";
import TableChartIcon from '@mui/icons-material/TableChart';


interface TableCardProps {
  table: string;
  onClick: (table: string) => void;
  isSelected?: boolean
}

const TableCard = ({ table, onClick, isSelected }: TableCardProps) => {
  return (
    <Stack
    sx={{
      backgroundColor: "var(--very-very-light-grey)",
      borderRadius: "10px",
      cursor: "pointer",
      padding: "5px",
      width: "125px",
      border: isSelected ? "solid 2px var(--blue)": undefined,
      "&:hover": {
        backgroundColor: "var(--very-light-blue)",
      },
    }}
    onClick={() => {
        onClick(table);
    }}
  >
    <Stack
      sx={{
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <TableChartIcon
        style={{
          fontSize: 30,
          color: "var(--dark-blue)",
        }}
      />

      <Typography
        sx={{
          fontSize: table.length < 20 ? "14px" : "12px",
          color: "var(--blue)",
          wordBreak: "break-all",
        }}
      >
        {table}
      </Typography>
    </Stack>
  </Stack>
  );
};

export default TableCard;
