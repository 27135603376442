
export const formatSheets = (data: any[]) => {
    const sheets = data.map((item: any) => { 
        return {
            value: item.id,
            label: item.hoja,
          };
    })
      return sheets;
}

export const formatSimpleSheets = (data: any[]) => {
  const sheets = data.map((item: any) => { 
      return {
          value: item.id,
          label: item.nombre,
        };
  })
    return sheets;
}

export const formatColumns = (data: any[]) => {  
  const sheets = data?.map((item: any) => { 
      return {
          value: item.id,
          label: item.columna,
        };
  })
    return sheets;
}

export const formatColumnsGroupBy = (data: any[]) => {  
  const sheets = data?.map((item: any) => { 
      return {
          value: item.columna,
          label: item.columna,
        };
  })
    return sheets;
}

export const getSheetColumns = (data: any[], sheet: any, isEditType?: boolean) =>{
 const sheetInfo = data.find((item: any) => { 
  return isEditType ? item.id === sheet.value : item.hoja === sheet.label 
})
const columns = formatColumns(sheetInfo.columnas)
return columns;
}

export const getSheetColumnsWithType = (data: any[], sheet: any,isEditType?: boolean ) =>{
  const sheetInfo = data.find((item: any) => { 
   return isEditType ? item.id === sheet.value : item.hoja === sheet.label 
 })
 return sheetInfo.columnas;
 }