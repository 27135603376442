import { Box, Typography, Button } from "@mui/material";

interface ToggleButtonProps {
  value: string;
  setValue:((value: string, type: string) => false | undefined) | ((value: string) => void);
  value1: string,
  value2: string,
  type: string,
  customColor?:string
}

const ToggleButton = ({
    value,
    setValue,
    value1,
    value2,
    type,
    customColor
}: ToggleButtonProps) => {


  return (
          <Box sx={{ display: "flex", marginLeft:"auto", marginBottom:"5px" }}>
                  <Box
                    sx={{
                      borderRadius: "20px",
                      border: `1px solid ${customColor? customColor : "var(--blue-greeny)"}`,
                      height: "25px",
                      position: "relative",
                      boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
                    }}
                  >
                    <Box
                      sx={{
                        width: "75px",
                        borderRadius: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: `${customColor? customColor : "var(--blue-greeny)"}`,
                        height: "23px",
                        position: "absolute",
                        boxShadow: "1px 0 2px black",
                        transition: "all 0.5s ease",
                        transform: `translateX(${value === value1 ? 0 : "75px"})`
                      }}
                    />
                    <Button
                      sx={{
                        color:
                          value === value1
                            ? "#ffffff"
                            : customColor ? customColor :"var(--blue-greeny)",
                        borderRadius: "10px",
                        width: "80px",
                        fontWeight: "bold",
                        height: "25px",
                        transition: "all 0.2s 0.1s ease",
                      }}
                      onClick={() => setValue(value1, type)}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          display: "inline",
                          fontSize: "10px",
                          fontWeight: "bold",
                          letterSpacing: "2px",
                          "&:hover": {
                            transition: "all 0.4s",
                            fontSize: "12px",
                          },
                        }}
                      >
                        {value1}
                      </Typography>
                    </Button>
                    <Button
                      sx={{
                        color:
                          value === value2
                            ? "#ffffff"
                           : customColor ? customColor : "var(--blue-greeny)",
                        borderRadius: "30px",
                        width: "70px",
                        fontWeight: "bold",
                        height: "25px",
                        transition: "all 0.2s 0.1s ease",
                      }}
                      onClick={() => setValue(value2, type)}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          display: "inline",
                          fontSize: "10px",
                          letterSpacing: "2px",
                          "&:hover": {
                            transition: "all 0.4s",
                            fontSize: "12px",
                          },
                        }}
                      >
                        {value2}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
 
  );
};

export default ToggleButton;
