import { Checkbox, InputLabel, Stack, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { form_label } from "../../../styles/app-styles";
import { EXTERNAL_CONNECTIONS_BY_ID, POST_CONNECTION_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import { isNameRepeated } from "../../pipelines/utils";
import useApi from "../../../hooks/useApi";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";

interface CreateConnectionModalProps {
  open: boolean;
  handleClose: () => void;
  dataConnections: any[];
  onSuccessSendConnection: () => void;
  setIsLoadingPostConnection?: (isLoading: boolean) => void
  isEdit?: boolean;
  connectionToEdit?: any
  onSuccessPutConnection?: any
}

const CreateConnectionModal = ({
  open,
  handleClose,
  dataConnections,
  onSuccessSendConnection,
  setIsLoadingPostConnection,
  isEdit,
  connectionToEdit,
  onSuccessPutConnection,
}: 
CreateConnectionModalProps) => {
  const [connectionName, setConnectionName] = useState<string>("");
  const [serverHostName, setServerHostName] = useState<string>("");
  const [httpRoute, setHttpRoute] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [rememberKey, setRememberKey] = useState(true);

  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const connectionTypeRef: any = useRef(null);
  const databricksConnectionTypeRef: any = useRef(null);


  const [
    connetionTypeSelectedOption,
    setConnectionTypeSelectedOption,
    connectionTypeOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    databricksConnetionTypeSelectedOption,
    setDatabricksConnectionTypeSelectedOption,
    databricksConnectionTypeOptionsChangeHandler,
  ] = useSingleSelect();

  const connectionSelectOptions = [
    { value: "databricks", label: "Databricks" },
    //{ value: "drive", label: "Google Drive" },
  ];

  const databricksConnectionSelectOptions = [
    { value: "cluster", label: "Cluster" },
    { value: "sql_warehouse", label: "SQL Warehouse" },

    //{ value: "drive", label: "Google Drive" },
  ];

  /********************************* LLAMADAS ************************************************/

  const {
    data: dataTablesPutConnection,
    error: errorPutConnection,
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
  } = useApi(
    EXTERNAL_CONNECTIONS_BY_ID(connectionToEdit?.recurso),
    "PUT",
    texts.dataManagement.postConnection.codes,
    {
      nombre_conexion: connectionName,
      tipo: connetionTypeSelectedOption?.value,
      tipo_conexion: connetionTypeSelectedOption?.value === "databricks" && databricksConnetionTypeSelectedOption?.value,
      server_hostname: serverHostName,
      http_path: httpRoute,
      access_token: accessToken,
      almacenar_credenciales: /*rememberKey*/ true,
    },
    onSuccessPutConnection,
    undefined,
    false
  );

  const {
    data: dataPostConnection,
    error: errorPostConnection,
    isLoading: isLoadingPostConnection,
    callApi: sendConnection,
  } = useApi(
    POST_CONNECTION_URL,
    "POST",
    texts.dataManagement.postConnection.codes,
    {
      nombre_conexion: connectionName,
      tipo: connetionTypeSelectedOption?.value,
      tipo_conexion: connetionTypeSelectedOption?.value === "databricks" && databricksConnetionTypeSelectedOption?.value,
      server_hostname: serverHostName,
      http_path: httpRoute,
      access_token: accessToken,
      almacenar_credenciales: /*rememberKey*/ true,
    },
    onSuccessSendConnection,
    undefined,
    false
  );

  useEffect(() => {
    setIsLoadingPostConnection && setIsLoadingPostConnection(isLoadingPostConnection);
  }, [isLoadingPostConnection]);

  useEffect(() => {
    open && setTimeout(() => {
      setDelayHelper(true);
    }, 3);
    if(open && isEdit){
      setConnectionName(connectionToEdit.nombre_conexion)
      //Por ahora elegimos la opcion uno porque todas las conexiones son de tipo databricks
      setConnectionTypeSelectedOption(connectionSelectOptions[0])
      setDatabricksConnectionTypeSelectedOption(databricksConnectionSelectOptions.find((option: any)=> option.value === connectionToEdit.tipo_conexion))
      //En un futuro cuando haya otro tipo de conexiones agregar condicional para que solo se rellene lo siguiente cuando la conexion es de tipo databricks
      setServerHostName(connectionToEdit.server_hostname)
      setHttpRoute(connectionToEdit.http_path)
    }
  }, [open]);
  /******************************* HANDLERS *******************************/

  const handleSendConnection = () => {
    if(isEdit){
      putConnection()
    }else{
      sendConnection();
      cleanAndClose();
    }
  };

  const handlePassword = () => {
    setShowPassword((prev) => !prev);
  };
  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/
  const isFormIncomplete = () => {
    return (
      connectionName.length < 1 ||
      (dataConnections !== undefined &&
        isNameRepeated(
          dataConnections.map((connections: any) => connections.nombre),
          connectionName
        )) ||
      connetionTypeSelectedOption === undefined ||
      connetionTypeSelectedOption === null ||
      !serverHostName.includes("databricks") ||
      !httpRoute.includes("sql") ||
      httpRoute.length < 6 ||
      accessToken.length < 6
    );
  };

  const cleanAndClose = () => {
    handleClose();
    setConnectionName("");
    setConnectionTypeSelectedOption(undefined);
    setAccessToken("");
    setHttpRoute("");
    setServerHostName("");
    setDelayHelper(false);
  };

  return (
    <>
      <Dialog
        open={open}
        handleClose={cleanAndClose}
        title={isEdit ? "Editar credenciales de la conexión":"Crear conexión"}
        maxWidth="xl"
      >
            <SimpleBackdrop
        open={isLoadingPutConnection}
        message={texts.dataManagement.postConnection.loading}
      />
        {dataConnections !== undefined && (
          <Stack sx={{ width: "500px", minHeight: "260px" }}>
            <Stack sx={{ marginTop: "5px", marginBottom: "5px" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Nombre de la conexión
              </InputLabel>
              <TextField
                id="connection-name"
                size="small"
                variant="outlined"
                error={
                  isNameRepeated(
                    dataConnections.map(
                      (connections: any) => connections.nombre
                    ),
                    connectionName
                  ) || connectionName.length < 1
                }
                helperText={
                  isNameRepeated(
                    dataConnections.map(
                      (connections: any) => connections.nombre
                    ),
                    connectionName
                  ) && "Ya existe una conexión con este nombre"
                }
                label=""
                value={connectionName}
                onChange={(e) => setConnectionName(e.target.value)}
                placeholder="Nombre de la conexión"
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "var(--blue)",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    margin: 0,
                    marginTop: "5px",
                  },
                }}
              />
            </Stack>
            <Stack sx={{ marginTop: "5px" }}>
              <InputLabel sx={form_label}>Conexión</InputLabel>
              {delayHelper && 
              <Select
                reference={connectionTypeRef}
                styles={selectStyles(connetionTypeSelectedOption)}
                options={connectionSelectOptions}
                name="Color"
                onChange={connectionTypeOptionsChangeHandler}
                closeMenuOnSelect
                placeholder="Seleccionar tipo de conexión"
                isClearable
                defaultValue={connetionTypeSelectedOption}
              />}
            </Stack>
            {connetionTypeSelectedOption?.value === "databricks" && (
              <>
                <Stack sx={{ marginTop: "10px" }}>
                  <InputLabel sx={form_label}>Tipo de conexión</InputLabel>
                  {delayHelper &&            <Select
                    reference={databricksConnectionTypeRef}
                    styles={selectStyles(databricksConnetionTypeSelectedOption)}
                    options={databricksConnectionSelectOptions}
                    name="Color"
                    onChange={databricksConnectionTypeOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar tipo de conexión"
                    isClearable
                    defaultValue={databricksConnetionTypeSelectedOption}
                  />}
       
                </Stack>
                <Stack sx={{ marginTop: "10px", marginBottom: "10px" }}>
                  <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                    Nombre del host del servidor
                  </InputLabel>
                  <TextField
                    id="server-hostname"
                    size="small"
                    variant="outlined"
                    label=""
                    value={serverHostName}
                    error={!serverHostName.includes("databricks")}
                    helperText={
                      !serverHostName.includes("databricks") &&
                      serverHostName?.length > 1 &&
                      "El nombre del servidor debe incluir 'databricks'"
                    }
                    onChange={(e) => setServerHostName(e.target.value)}
                    placeholder="Nombre del host del servidor"
                    autoComplete="off"
                    InputLabelProps={{
                      style: {
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        color: "var(--blue)",
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                        margin: 0,
                        marginTop: "5px",
                      },
                    }}
                  />
                </Stack>
                <Stack sx={{ marginTop: "5px", marginBottom: "10px" }}>
                  <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                    Ruta HTTP
                  </InputLabel>
                  <TextField
                    id="http"
                    size="small"
                    variant="outlined"
                    label=""
                    value={httpRoute}
                    error={!httpRoute.includes("sql") || httpRoute.length < 6}
                    helperText={
                      httpRoute.length > 1 &&
                      (!httpRoute.includes("sql") || httpRoute.length < 6) &&
                      "El formato de la ruta HTTP es incorrecto, debe incluir la palabra 'sql' y ser mayor a 6 caracteres"
                    }
                    onChange={(e) => setHttpRoute(e.target.value)}
                    placeholder="Ruta HTTP"
                    autoComplete="off"
                    InputLabelProps={{
                      style: {
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        color: "var(--blue)",
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                        margin: 0,
                        marginTop: "5px",
                      },
                    }}
                  />
                </Stack>
                <Stack sx={{ marginTop: "5px", marginBottom: "10px" }}>
                  <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                    Token de accesso personal
                  </InputLabel>
                  <Stack direction="row" width="100%" alignItems="center">
                    <TextField
                      id="personal-token"
                      size="small"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      label=""
                      placeholder="Token de accesso personal"
                      fullWidth
                      value={accessToken}
                      onChange={(e) => setAccessToken(e.target.value)}
                      autoComplete="off"
                      error={accessToken.length < 6}
                      helperText={
                        accessToken.length > 1 &&
                        accessToken.length < 6 &&
                        "El formato del token es incorrecto, debe ser mayor a 6 caracteres"
                      }
                      InputLabelProps={{
                        style: {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                          color: "var(--blue)",
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          color: "var(--magenta)",
                          margin: 0,
                          marginTop: "5px",
                        },
                      }}
                    />
                    {showPassword ? (
                      <VisibilityIcon
                        sx={{ color: "var(--grey)", marginLeft: "5px" }}
                        onClick={handlePassword}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ color: "var(--grey)", marginLeft: "5px" }}
                        onClick={handlePassword}
                      />
                    )}
                  </Stack>
                </Stack>
                {/* 
                  <Stack
                  sx={{
                    width: "100%",
                    marginTop: "0px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InputLabel id="credentials" sx={form_label}>
                    Recodar credenciales
                  </InputLabel>
                  <Checkbox
                    checked={rememberKey}
                    sx={{
                      color: "#172D40",
                      "& svg": { stroke: "#172D40" },
                    }}
                    onChange={() => {
                      setRememberKey((prev) => !prev);
                    }}
                  />
                </Stack>
                */}
              </>
            )}
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={cleanAndClose}
            color="light-grey"
          />
          <Button
            title={isEdit ? "Aceptar y validar":"Aceptar"}
            type="button"
            onClick={() => {
              handleSendConnection();
            }}
            disabled={isFormIncomplete()}
            color="blue"
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateConnectionModal;
