import {
  Stack,
  Typography,
} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from "../buttons";
import { useNavigate } from "react-router";

const Error = () => {
  const navigate = useNavigate();
  
  return (
    <Stack sx={{ width: "100%", height: "90vh", marginTop: "10px", alignItems: "center", justifyContent: "center" }}>
     <WarningAmberIcon sx={{color:"var(--dark-grey)", fontSize:"140px"}}/>
     <Typography sx={{fontSize:"25px", fontWeight:"600", color:"var(--dark-grey)", marginTop:"30px"}}>
      Esta página no existe
     </Typography>
     <Button title="Volver al inicio" color="blue" type="button" onClick={() => navigate("/")} />
    </Stack>
  );
};

export default Error;