import { InputLabel, Stack, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { form_label } from "../../../styles/app-styles";
import { RESOURCE_LABEL_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { useApiQuery } from "../../../hooks/useApiQuery";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import { formatOptions } from "../../../utils/util";
import useDialog from "../../../hooks/useDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useApi from "../../../hooks/useApi";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CreateLabelModal from "./CreateLabelModal";

interface SelectResourceLabelProps {
  setLabel: (label: SingleValue<IdOption> | undefined) => void;
  selectedLabel?: any;
  customMargin?: string;
  customFontSize?: string;
  customHandleChange?: (label: SingleValue<IdOption> | undefined) => void;
}

const SelectResourceLabel = ({
  selectedLabel,
  setLabel,
  customMargin,
  customFontSize,
  customHandleChange,
}: SelectResourceLabelProps) => {
  const [labelName, setLabelName] = useState<string>("");
  const [openCreateLabelModal, toggleCreateLabelModal] = useDialog();

  const labelSelectRef: any = useRef(null);
  const [
    labelSelectedOption,
    setLabelSelectedOption,
    labelOptionsChangeHandler,
  ] = useSingleSelect(undefined, undefined, selectedLabel);

  const [
    labelColorSelectedOption,
    setLabelColorSelectedOption,
    labelColorOptionsChangeHandler,
  ] = useSingleSelect();

  const colorSelectOptions = [
    { color: "#F26B9C", value: "#F26B9C", label: "Rosa apagado" },
    { color: "#24A68E", value: "#24A68E", label: "Turquesa" },
    { color: "#BF0637", value: "#BF0637", label: "Rojo oscuro" },
    { color: "#147588", value: "#147588", label: "Azul profundo" },
    { color: "#4D4E6A", value: "#4D4E6A", label: "Gris azulado" },
    { color: "#166C62", value: "#166C62", label: "Verde esmeralda" },
    { color: "#82D6EE", value: "#82D6EE", label: "Celeste claro" },
    { color: "#272731", value: "#272731", label: "Gris carbón" },
    { color: "#083844", value: "#083844", label: "Azul petróleo" },
  ];

  /*************************** HANDLERS ***************************/

  const hanldeChangeLabel = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    labelOptionsChangeHandler(newValue, actionMeta);
    setLabel(newValue);
    customHandleChange && customHandleChange(newValue);
  };
  /********************************* LLAMADAS ************************************************/

  const {
    data: dataLabels,
    isLoading: isLoadingGetLabels,
    refetch: refetchLabels,
    error: errorLabels,
  } = useApiQuery(
    RESOURCE_LABEL_URL,
    false,
    texts.dataManagement.getLabels.error
  );

  const onSuccesSendLabel = () => {
    refetchLabels();
  };

  const {
    data: dataPostLabel,
    error: errorPostLabel,
    isLoading: isLoadingPostLabel,
    callApi: sendLabel,
  } = useApi(
    RESOURCE_LABEL_URL,
    "POST",
    texts.dataManagement.postLabel.codes,
    {
      nombre: labelName,
      color: labelColorSelectedOption?.value,
    },
    onSuccesSendLabel,
    undefined,
    false
  );

  return (
    <Stack
      sx={{
        width: "100%",
        marginBottom: "10px",
        marginTop: customMargin ? customMargin : "20px",
      }}
    >
      <SimpleBackdrop
        open={isLoadingGetLabels}
        message={texts.dataManagement.getLabels.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostLabel}
        message={texts.dataManagement.postLabel.loading}
      />
      <Stack direction="row" sx={{ width: "100%", alignItems: "center" }}>
        <InputLabel
          sx={{
            ...form_label,
            fontSize: customFontSize ? customFontSize : undefined,
          }}
        >
          Etiqueta
        </InputLabel>
        <Tooltip title={"Crear nueva etiqueta"}>
          <AddCircleOutlineIcon
            sx={{
              color: "var(--blue)",
              transition: "all 0.4s",
              fontSize: "22px",
              marginLeft: "4px",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.15)",
                transition: "0.3s all",
              },
            }}
            onClick={toggleCreateLabelModal}
          />
        </Tooltip>
      </Stack>

      {!isLoadingGetLabels && !errorLabels && (
        <Select
          options={formatOptions(dataLabels)}
          styles={selectStyles(labelSelectedOption)}
          reference={labelSelectRef}
          onChange={hanldeChangeLabel}
          isClearable
          closeMenuOnSelect
          isSearchable
          placeholder={"Seleccione una etiqueta"}
          defaultValue={labelSelectedOption}
        />
      )}
      <CreateLabelModal
        open={openCreateLabelModal}
        handleClose={toggleCreateLabelModal}
        dataLabels={dataLabels}
        onSuccessSendLabel={refetchLabels}
      />
    </Stack>
  );
};

export default SelectResourceLabel;
