import { useState } from "react";
import { ActionMeta, MultiValue } from "react-select";
import { IdOnChange, IdOption } from "../components/forms/types";

const useMultiSelect = (
  defaultValues?: IdOption[]
  ): [
  MultiValue<IdOption> | undefined,
  React.Dispatch<React.SetStateAction<MultiValue<IdOption> | undefined>>,
  IdOnChange
] => {
  const [selectedOptions, setSelectedOptions] =
    useState<MultiValue<IdOption> | undefined>(defaultValues);

  const selectedOptionsChangeHandler: IdOnChange = (
    newValue: MultiValue<IdOption>,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        setSelectedOptions(newValue);
        break;
      case "clear":
        setSelectedOptions([]);
        break;
      case "remove-value":
        setSelectedOptions(
          selectedOptions?.filter(
            (option) => option.value !== action.removedValue.value
          )
        );
        break;
      case "deselect-option":
        setSelectedOptions(
          selectedOptions?.filter(
            (option) => option.value !== action.removedValue.value
          )
        );
        break;
      default:
        break;
    }
  };

  return [selectedOptions, setSelectedOptions, selectedOptionsChangeHandler];
};

export default useMultiSelect;
