import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

interface CustomStepperProps {
  activeStep: number;
  data: {
    steps: string[];
  };
}

const CustomStepper = ({ activeStep, data }: CustomStepperProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "15px",
        "& .MuiStepper-root .MuiStep-root span.MuiStepLevel-root span.MuiStepLevel-iconContainer svg path":
          { fill: "#32648e" },
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {data.steps.map((label) => (
          <Step
            key={label}
            sx={{
              "& svg": { width: "30px", height: "30px" },
              "& svg path": { fill: "#32648e" },
              "& svg circle": { fill: "#f90d4a" },
              "& .Mui-active circle": { fill: "#16738a" },
              "& .Mui-active text": { fontWeight: "bold" },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
