import ComponentLayout from "../layout/ComponentLayout";
import LanIcon from "@mui/icons-material/Lan";
import { PIPELINE_BY_ID_URL, GET_PIPELINES_URL } from "../../api/axios";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import FixedAddButton from "../buttons/FixedAddButton";
import { useNavigate } from "react-router-dom";
import SearchInput from "../forms/SearchInput";
import { useState } from "react";
import BasicGrid from "../basicGrid/BasicGrid";
import PipelineCard from "./PipelineCard";
import { ConfirmationModal as DeleteCriteriaConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useApiQuery } from "../../hooks/useApiQuery";
import useApi from "../../hooks/useApi";
import Stack from "@mui/material/Stack";
import { filterDataByName } from "./utils";

const PipelineAdmin = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [pipelineToDelete, setPipelineToDelete] = useState<number>();
  const [
    openDeleteCriteriaConfirmationModal,
    handleToggleDeleteCriteriaConfirmationModal,
  ] = useDialog();
  const {
    isLoading: loadingPipelines,
    data: dataPipelines,
    refetch: refetchPipelines,
  } = useApiQuery(
    GET_PIPELINES_URL,
    true,
    texts.adminPipeline.getPipelines.error
  );

  const goToConfigPipeline = () => {
    localStorage.removeItem("pipelineId");
    localStorage.removeItem("pipelineEditFile");
    navigate("/consolidacion-archivos/configuracion-pipelines");
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataPipelines, e.target.value, setFilteredData);
  };
 
  const handleEdit = (e: any, id: string) => {
    localStorage.setItem("pipelineId", id);
    navigate("/consolidacion-archivos/configuracion-pipelines");
  };

  const handleEditFile = (e: any, id: string) => {
    localStorage.setItem("pipelineId", id);
    localStorage.setItem("pipelineEditFile", id);
    navigate("/consolidacion-archivos/configuracion-pipelines");
  };

  const handleCopy = (id: string) => {
    console.log("Copiando pipeline con id: ", id);
  };

  const handleInfo = (id: string) => {
    console.log("Mostrando info de pipeline con id: ", id);
  };

  const { isLoading: isLoadingDeletePipeline, callApi: deletePipelineById } =
    useApi(
      PIPELINE_BY_ID_URL(pipelineToDelete),
      "DELETE",
      texts.adminPipeline.deletePipeline.codes,
      undefined,
      refetchPipelines
    );

  const handleDelete = () => {
    deletePipelineById();
    handleToggleDeleteCriteriaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setPipelineToDelete(id);
    handleToggleDeleteCriteriaConfirmationModal();
  };

  return (
    <ComponentLayout title="Administración de pipelines" icon={<LanIcon />}>
      <SimpleBackdrop open={loadingPipelines} message="Buscando pipelines..." />
      <SimpleBackdrop
        open={isLoadingDeletePipeline}
        message={texts.adminPipeline.deletePipeline.loading}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder="Búsqueda por nombre"
        />
      </Stack>
      {filteredData?.length || dataPipelines?.length ? (
        <BasicGrid
          data={filteredData ? filteredData : dataPipelines}
          Card={PipelineCard}
          
          handleEdit={handleEdit} 
          //handleCopy={handleCopy}
          /* handleInfo={handleInfo} */
          handleEditFile={handleEditFile}
          handleDelete={handleClickOnDelete}
          showInfo={true}
        />
      ) : (
        !loadingPipelines && (
          <Box
            sx={{
              width: "95%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
            >
              Aún no hay pipelines disponibles.
            </Typography>
          </Box>
        )
      )}
      <FixedAddButton handleClick={goToConfigPipeline} />
      <DeleteCriteriaConfirmationModal
        open={openDeleteCriteriaConfirmationModal}
        handleClose={handleToggleDeleteCriteriaConfirmationModal}
        handleAccept={handleDelete}
        message="eliminar el pipeline"
        title="Confirmar"
      />
    </ComponentLayout>
  );
};

export default PipelineAdmin;
