import { Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import {
  EXTERNAL_CONNECTIONS_BY_ID,
  GET_CONNECTIONS_URL,
  POST_CONNECTION_URL,
  POST_EXTERNAL_CONNECTION_RESOURCE_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useDialog from "../../../hooks/useDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { useEffect, useState } from "react";
import Connection from "../ExternalConnections/Connection";
import CreateConnectionModal from "../ExternalConnections/CreateConnectionModal";
import useApi from "../../../hooks/useApi";
import TableCard from "../ExternalConnections/TableCard";
import DatabricksCatalogList from "../ExternalConnections/DatabricksCatalogList";
import IngestionConfigurationModal from "./IngestionConfiguration/IngestionConfigurationModal";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";

interface ExternalConnectionsTabProps {
  setDataFileState: (table: any[]) => void;
}

const ExternalConnectionsTab = ({
  setDataFileState,
}: ExternalConnectionsTabProps) => {
  const [openCreateConnectionModal, toggleCreateConnectionModal] = useDialog();
  const [isLoadingPostCreateConnection, setIsLoadingPostCreateConnection] =
    useState(false);
  const [selectedConnection, setSelectedConnection] = useState<any>(undefined);
  const [editConnection, setEditConnection] = useState<any>(false);
  const [selectedTable, setSelectedTable] = useState<any>();
  const [selectedSchema, setSelectedSchema] = useState<string | undefined>("");
  const [selectedCatalog, setSelectedCatalog] = useState<string | undefined>("");
  const [dataTables, setDataTables] = useState<any>(undefined);
  const [openConfigurationModal, toggleConfigurationModal] =
    useDialog();
  /********************************* LLAMADAS ************************************************/

  const {
    data: dataConnections,
    isLoading: isLoadingGetConnections,
    refetch: refetchConnections,
    error: errorConnections,
  } = useApiQuery(
    GET_CONNECTIONS_URL,
    false,
    texts.dataManagement.getConnections.error
  );

  const onSuccessSendConnectionResource = (data: any) => {
    setDataFileState([{...data, isExternalConnection: true}]);
  };

  const {
    data: dataPostResourceConnection,
    error: errorPostResourceConnection,
    isLoading: isLoadingPostConnectionResource,
    callApi: sendConnectionResource,
  } = useApi(
    POST_EXTERNAL_CONNECTION_RESOURCE_URL,
    "POST",
    texts.dataManagement.postConnectionResource.codes,
    undefined,
    onSuccessSendConnectionResource
  );

  const onSuccessGetTables = (data: any) => {
    setDataTables(data);
  };

  const onErrorPostConnection = (data: any) => {
    setEditConnection(true)
  }

 useEffect(() => {
  if(editConnection){
    toggleCreateConnectionModal()
  }
 }, [editConnection]);

  const {
    data: dataTablesPostConnection,
    error: errorPostConnection,
    isLoading: isLoadingPostConnection,
    callApi: sendConnection,
  } = useApi(
    POST_CONNECTION_URL,
    "POST",
    texts.dataManagement.postConnection.codes,
    undefined,
    onSuccessGetTables,
    onErrorPostConnection,
    false
  );

  /******************************* HANDLERS *******************************/
  const handleSelectConnection = (connection: any) => {
    setSelectedConnection(connection);
    sendConnection(undefined, {
      recurso_id: connection.recurso,
      tipo: "databricks",
    });
  };

  const handleSelectTable = (
    table: string,
    catalog?: string,
    schema?: string
  ) => {
    setSelectedTable(table);
    setSelectedCatalog(catalog)
    setSelectedSchema(schema)
    toggleConfigurationModal()
   /* sendConnectionResource(undefined, {
      tipo: "databricks",
      ruta_destino: decodeURIComponent(getRoute(location.pathname)),
      id_config ---> "D"// si default true se manda null
      default: true // o false,
    });*/
  };

  const handleAcceptConf = (id_config: string | number) => {
    sendConnectionResource(undefined, {
      tipo: "databricks",
      ruta_destino: decodeURIComponent(getRoute(location.pathname)),
      id_config: id_config === "Default" ? null : id_config, 
      default: id_config === "Default", 
      id_recurso_databricks: selectedConnection?.id, 
      tipo_conexion: selectedConnection.tipo_conexion,
      recurso_id: selectedConnection.recurso,
      tabla: selectedTable,
      catalogo: selectedCatalog,
      esquema: selectedSchema
    })
  }

  const handleCloseConnectionModal = ()=>{
    toggleCreateConnectionModal()
    setEditConnection(false)
  }

  const onSuccessPutConnection = ()=>{
    sendConnection(undefined, {
      recurso_id: selectedConnection.recurso,
      tipo: "databricks",
    });
    toggleCreateConnectionModal()
    setEditConnection(false)
  }



  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPostConnectionResource}
        message={texts.dataManagement.postConnectionResource.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostConnection}
        message={texts.dataManagement.loadConnectionTable.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostCreateConnection}
        message={texts.dataManagement.postConnection.loading}
      />
      <Stack
        sx={{
          width: "100%",
          minHeight: "350px",
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      >
        {dataConnections !== undefined && dataConnections.length > 0 && (
          <>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Seleccione una conexión:
            </Typography>
            {dataConnections.map((connection: any) => (
              <Connection
                key={connection.id}
                connection={connection}
                dataConnections={dataConnections}
                onClick={handleSelectConnection}
                isSelected={selectedConnection?.id === connection.id}
              />
            ))}
          </>
        )}
        <Tooltip title="Agregar conexión">
          <Stack
            sx={{
              flexDirection: "row",
              backgroundColor: "var(--blue)",
              marginBottom: "15px",
              borderRadius: "14px",
              padding: "2px 8px",
              cursor: "pointer",
              width: "190px",
              alignItems: "center",
            }}
            onClick={toggleCreateConnectionModal}
          >
            <Typography
              sx={{
                fontSize: "18px",
                color: "white",
                marginRight: "5px",
              }}
            >
              Agregar conexión
            </Typography>
            <AddCircleOutlineIcon
              sx={{
                fontSize: "20px",
                color: "white",
                transition: "all 0.4s",
                marginLeft: "4px",
                marginBottom: "3px",
                "&:hover": {
                  transform: "scale(1.15)",
                  transition: "0.3s all",
                },
              }}
            />
          </Stack>
        </Tooltip>
        {dataTables !== undefined &&
          dataTables !== null &&
          !isLoadingPostConnection && (
            <>
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Seleccione la tabla a cargar:{" "}
                <Typography
                  sx={{
                    color: "var(--blue)",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  (la tabla seleccionada se cargará en la carpeta raíz de los
                  archivos)
                </Typography>
              </Typography>

              {selectedConnection?.tipo_conexion === "cluster" ? (
                <Stack
                  gap={3}
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {dataTables.data?.map((table: any, index: number) => (
                    <TableCard
                      key={index}
                      table={table}
                      onClick={handleSelectTable}
                      isSelected={selectedTable === table}
                    />
                  ))}
                </Stack>
              ) : (
                <DatabricksCatalogList
                  catalogsData={dataTables.data}
                  selectedTable={selectedTable}
                  setSelectedTable={handleSelectTable}
                />
              )}
            </>
          )}
        {dataTables !== undefined &&
          dataTables !== null &&
          dataTables.data?.length === 0 && (
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "18px",
                marginTop: "auto",
                fontWeight: "bold",
              }}
            >
              No existen tablas
            </Typography>
          )}
      </Stack>
      <CreateConnectionModal
        open={openCreateConnectionModal}
        dataConnections={dataConnections}
        handleClose={handleCloseConnectionModal}
        onSuccessSendConnection={refetchConnections}
        setIsLoadingPostConnection={setIsLoadingPostCreateConnection}
        isEdit={editConnection}
        connectionToEdit={selectedConnection}
        onSuccessPutConnection={onSuccessPutConnection}
      />
      <IngestionConfigurationModal open={openConfigurationModal} handleClose={toggleConfigurationModal} table={selectedTable} resource_id={selectedConnection?.id} schema={selectedSchema} catalog={selectedCatalog} handleAcceptConf={handleAcceptConf} connectionType={selectedConnection?.tipo_conexion}/>
    </>
  );
};

export default ExternalConnectionsTab;
