import { Stack, Typography } from "@mui/material";

interface StepLayoutI {
  stepTitle: string;
  children: React.ReactNode;
}

const StepLayout = ({ stepTitle, children }: StepLayoutI) => {
  return (
    <Stack width="100%" height="100%" alignItems="center">
      <Typography
        variant="h5"
        color="primary"
        align="center"
        fontWeight="Verdana"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          padding: "25px 0",
        }}
      >
        {stepTitle}
      </Typography>
      {children}
    </Stack>
  );
};

export default StepLayout;
