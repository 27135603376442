import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import { Tooltip } from "@mui/material";
import { convertArrayToText } from "../../utils/util";
import { truncateString } from "../pipelines/utils";
import useDialog from "../../hooks/useDialog";
import ExportModal from "../exportItemModal/ExportModal";
import useApi from "../../hooks/useApi";
import { texts } from "../../texts";
import { COPY_SCHEMA_URL } from "../../api/axios";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";

const SchemaCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
  handleExport
}: any) => {

  const [openModal, handleToggleOpenModal] = useDialog();

  const { isLoading: isLoadingExportSchema, callApi: exportSchema } = useApi(
    COPY_SCHEMA_URL(item.id),
    "POST",
    texts.adminSchemas.exportSchema.codes
  );

  const handleAccept = (name: string, company: any) => {
    exportSchema(undefined, { nombre: name, empresa: company });
    handleToggleOpenModal();
  };
  
  return (
    <>
      <SimpleBackdrop
        open={isLoadingExportSchema}
        message={texts.adminSchemas.exportSchema.loading
        }
      />
      <Stack sx={{ padding: "5px 10px 10px 10px", minHeight: "240px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--blue)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "18px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 35)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "10px 0" }} />
        {showInfo && item.esquema_criterios && (
          <>
            <Tooltip
              title={item?.esquema_criterios.map(
                (criterio: any, index: number) => {
                  return (
                    <div key={index}>
                      {`${criterio.criterio_nombre} ${
                        criterio.flag_incluir ? "(incluye" : "(excluye"
                      } ${
                        criterio.hojas.length === 1 ? "hoja" : "hojas"
                      } ${convertArrayToText(criterio.hojas.map((hoja: any)=>{return hoja.label}))})`}{" "}
                    </div>
                  );
                }
              )}
            >
              <span>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "left",
                    fontWeight: "700",
                    letterSpacing: "-0.5px",
                  }}
                  color="var(--blue)"
                >
                  CRITERIOS:{" "}
                  <span style={{ color: "var(--light-grey)", wordBreak: "break-word",
 }}>
                    {item?.esquema_criterios.map(
                      (criterio: any, index: number) => {
                        return (
                          <div key={index}>
                            {`${criterio.criterio_nombre} ${
                              criterio.flag_incluir ? "(incluye" : "(excluye"
                            } ${
                              criterio.hojas.length === 1 ? "hoja" : (criterio.hojas.length === 0? "todas las hojas":"hojas")
                            } ${convertArrayToText(criterio.hojas.map((hoja: any)=>{return hoja.label}))})`}{" "}
                          </div>
                        );
                      }
                    )}
                  </span>
                </Typography>
              </span>
            </Tooltip>
          </>
        )}
      </Stack>
      <SpeedDialTooltipOpen
        id={item.id}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        handleInfo={handleInfo} 
        handleDelete={handleDelete}
        handleExport={handleToggleOpenModal}
      />
        <ExportModal open={openModal} handleClose={handleToggleOpenModal} handleAccept={handleAccept} item={item} itemType="esquema"/>
    </>
  );
};

export default SchemaCard;
