import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
interface ComponentLayoutProps {
  title: string;
  icon: JSX.Element;
  rightComponent?: JSX.Element;
  children: any;
}

const ComponentLayout = ({
  title,
  icon,
  rightComponent,
  children,
}: ComponentLayoutProps) => {
  return (
    <Stack justifyContent="center" alignItems="center" width="95%" mt="35px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={rightComponent ? "space-between" : "flex-start"}
        width="100%"
        mb="20px"
        sx={{ borderBottom: "1px var(--very-light-grey) solid" }}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{color: "var(--blue)"}}>
            {icon}
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "600",
              color: "var(--blue)",
              textTransform: "uppercase",
              fontFamily: "Verdana",
              marginBottom: "8px",
            }}
          >
            {title}
          </Typography>
        </Stack>
        {rightComponent}
      </Stack>
      {children}
    </Stack>
  );
};

export default ComponentLayout;
