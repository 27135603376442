import { Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";


interface SelectedPeriodProps {
    period: string
    onClick: ((period: string) => void) 
  }
  
  const SelectedPeriod = ({ onClick, period}: SelectedPeriodProps) => {
    return (
    <Stack sx={{flexDirection:"row", alignItems:"center"}}>
    <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold"}}>{period}</Typography>
    <DeleteIcon
                onClick={() => onClick(period)}
                sx={{
                  fontSize: "25px",
                  color: "var(--blue)",
                  cursor: "pointer",
                  marginBottom: "5px",
                  marginLeft:"3px"
                }}
              />    </Stack>
    );
  };
  
  export default SelectedPeriod;