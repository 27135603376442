import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../layout/ComponentLayout";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import { useContext, useEffect, useRef, useState } from "react";
import useDialog from "../../hooks/useDialog";
import {
  FILE_BY_ID,
  FILE_INFO_URL,
  GET_REPORT_BY_TYPE,
  REPORT_BY_ID,
  REPORT_URL,
  SHEET_INFO_BY_ID,
} from "../../api/axios";
import { texts } from "../../texts";
import InfoGrid from "../basicGrid/InfoGrid";
import { Button } from "../buttons";
import SummaryRowTitles from "./SummaryRowTitles";
import SummaryRowSelects from "./SummaryRowSelects";
import RowSummary from "./RowSummary";
import SummaryProvider from "../../context/SummaryContext";
import useMultiSelect from "../../hooks/useMultiSelect";
import useSingleSelect from "../../hooks/useSingleSelect";
import { form_label } from "../../styles/app-styles";
import {
  Avatar,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import { ConfirmationModal } from "../dialog";
import SimpleTable from "../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../tablesTools/transformDataSimpleTable";
import {
  formatSheets,
  formatSimpleSheets,
  getSheetColumns,
  getSheetColumnsWithType,
} from "../fileColumns/utils";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { handleClickVariant } from "../../utils/util";
import { useSnackbar } from "notistack";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";
import { isValidName } from "../schemas/utils";
import { useApiQuery } from "../../hooks/useApiQuery";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { useNavigate } from "react-router";
import OriginalFont from "../dataManagement/OriginalFont";

const InformationSummary = () => {
  const [openConfirmCreateSummaryModal, toggleConfirmCreateSummaryModal] =
    useDialog();
  const [openConfirmResetSummaryModal, toggleConfirmResetSummaryModal] =
    useDialog();
  const { summaryState, summaryDispatch } =
    useContext<React.ContextType<typeof SummaryProvider>>(SummaryProvider);
  const { summary, message, summaryRows } = summaryState;
  const [file, setFile] = useState<any>("");
  const columnSelectRef: any = useRef(null);
  const [
    columnSelectedOptions,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useMultiSelect();
  const sheetRef: any = useRef(null);
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [sheetsOptions, setSheetsOptions] = useState<any>([]);
  const [columnsOptions, setColumnsOptions] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>("");
  const [summaryName, setSummaryName] = useState<string>("");
  const [replaceFile, setReplaceFile] = useState<boolean>(false);
  const [fileToReplaceWith, setFileToReplaceWith] = useState<any>(undefined);
  const [delayHelper, setDelayHelper] = useState<boolean>(true);
  const summaryId = localStorage.getItem("summaryId")
    ? localStorage.getItem("summaryId")
    : undefined;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /*************************** CARGA DE DATOS ***************************/

  /* Si en el localStorage hay un id, es decir si estamos en modo EDIT, hacemos el get del resumen*/
  useEffect(() => {
    if (summaryId) {
      getSummary(REPORT_BY_ID(parseInt(summaryId)));
    }
    return () => {
      summaryDispatch({
        type: "SET_SUMMARY",
        payload: [],
      });
      summaryDispatch({
        type: "SET_SUMMARY_ROWS",
        payload: [],
      });
    };
  }, [summaryId]);

  const onSuccessGetSummary = (data: any) => {
    setSummaryName(data.nombre);
    summaryDispatch({
      type: "SET_SUMMARY_ROWS",
      payload: data.columnas_operaciones.map((col: any) => {
        return {
          column: col.columna,
          function: { value: col.operacion, label: col.operacion },
        };
      }),
    });
    setColumnSelectedOption(data.columnas_agrupar);
    setSheetSelectedOption(
      data.hoja.value !== null
        ? data.hoja
        : {
            value: null,
            label: `Hoja: "${data.hoja.label}" eliminada, reemplazar.`,
          }
    );
    if (data.archivo.existe) {
      getFilesInfoById(`${FILE_BY_ID(data.archivo.id)}?columnas=True`);
      data.hoja.value !== null &&
        getSheetsInfo(SHEET_INFO_BY_ID(data.archivo.id, data.hoja.value));
    }
  };

  const {
    isLoading: isLoadingGetSummary,
    callApi: getSummary,
    data: summaryToEdit,
  } = useApi(
    undefined,
    "GET",
    texts.summary.getSummary.codes,
    undefined,
    onSuccessGetSummary,
    undefined,
    false
  );

  const { data: dataSummaries } = useApiQuery(
    GET_REPORT_BY_TYPE("resumen"),
    false,
    texts.summary.getSummaries.error
  );

  /*
  const onSheetChange = (newValue?: SingleValue<IdOption> | undefined) => {
    summaryDispatch({
      type: "SET_SUMMARY_ROWS",
      payload: [],
    });
    setColumnSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    summaryDispatch({
      type: "SET_COLUMNS",
      payload: [],
    });
  };*/

  const [
    sheetSelectedOption,
    setSheetSelectedOption,
    sheetOptionsChangeHandler,
  ] = useSingleSelect();

  useEffect(() => {
    setTimeout(() => {
      if (
        sheetSelectedOption !== undefined &&
        sheetSelectedOption !== null &&
        dataFileState
      ) {
        if (dataFileState[0]?.contenido) {
          setDelayHelper(true)
          setColumnsOptions(
            getSheetColumns(dataFileState[0]?.contenido, sheetSelectedOption)
          );
          setColumns(
            getSheetColumns(dataFileState[0]?.contenido, sheetSelectedOption),
            getSheetColumnsWithType(
              dataFileState[0]?.contenido,
              sheetSelectedOption
            )
          );
        }
      }
      if (!summaryId) {
        setColumnSelectedOption(undefined);
        columnSelectRef?.current?.clearValue();
      }
    }, 15);
  }, [sheetSelectedOption]);

  const setColumns = (columns: any[], columnsWithType: any[]) => {
    summaryDispatch({
      type: "SET_COLUMNS",
      payload: columns,
    });
    summaryDispatch({
      type: "SET_COLUMNS_WITH_TYPE",
      payload: columnsWithType,
    });
  };

  const infoToSend = (calculate: boolean) => {
    return {
      nombre: summaryName,
      tipo: "resumen",
      archivo:
        dataFileState && dataFileState?.length > 0 && dataFileState[0]?.id,
      hoja: sheetSelectedOption?.value,
      calcular: calculate,
      columnas_agrupar: columnSelectedOptions?.map((column: any) => {
        return column.label;
      }),
      columnas_operaciones: summaryRows.map((summaryRow: any) => {
        return {
          columna: summaryRow.column.label,
          operacion: summaryRow.function.value,
        };
      }),
    };
  };

  const onSuccessSendSummary = (data: any) => {
    if (data.tipo !== undefined) {
      if (data.tipo === "tarea") {
        handleClickVariant(
          `Se ha generado la tarea con id ${data.body.id_tarea} exitosamente, en cuanto finalice podras visualizar el resultado en la pestaña Informes del Gestor de tareas`,
          "success",
          enqueueSnackbar,
          closeSnackbar
        );
        summaryDispatch({
          type: "SET_SUMMARY",
          payload: [],
        });
      } else {
        summaryDispatch({
          type: "SET_SUMMARY",
          payload: data.body,
        });
      }
    } else {
      onSuccessSaveSummary();
    }
  };

  const onErrorSendSummary = (data: any) => {
    summaryDispatch({
      type: "SET_SUMMARY",
      payload: [],
    });
  };

  const {
    error: errorPostSummary,
    isLoading: isLoadingPostSummary,
    callApi: sendSummary,
  } = useApi(
    REPORT_URL,
    "POST",
    texts.summary.sendSummary.codes,
    infoToSend(false),
    onSuccessSendSummary,
    onErrorSendSummary,
    true
  );

  const { isLoading: isLoadingPutSummary, callApi: putSummary } = useApi(
    REPORT_BY_ID(parseInt(summaryId as string)),
    "PUT",
    texts.summary.sendSummary.codes,
    infoToSend(false),
    () => {
      onSuccessSaveSummary();
    },
    undefined,
    true
  );

  const confirmSendSchema = () => {
    toggleConfirmCreateSummaryModal();
    summaryToEdit ? putSummary() : sendSummary();
  };

  const calculateSummary = () => {
    sendSummary(undefined, infoToSend(true));
  };

  const handleResetData = () => {
    summaryDispatch({
      type: "SET_SUMMARY",
      payload: [],
    });
    setFile("");
    setSheetSelectedOption(undefined);
    sheetRef.current?.clearValue();
    setColumnSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    summaryDispatch({
      type: "SET_COLUMNS",
      payload: [],
    });
    summaryDispatch({
      type: "SET_COLUMNS_WITH_TYPE",
      payload: [],
    });
    summaryDispatch({
      type: "SET_SUMMARY_ROWS",
      payload: [],
    });
    setFileToReplaceWith(undefined)
    toggleConfirmResetSummaryModal();
  };

  const onSuccessGetFileInfo = (data: any) => {
    if (
      !summaryId /*||
    (summaryToEdit !== null && summaryToEdit?.archivo?.id !== data.id)*/
    ) {
      setColumns([], []);
      setFile("");
      setSheetsOptions([]);
      setColumnsOptions([]);
      summaryDispatch({
        type: "SET_COLUMNS",
        payload: [],
      });
      summaryDispatch({
        type: "SET_MESSAGE",
        payload: "",
      });
    } else {
      // Cuando estamos editando un resumen y reemplazamos el archivo, vamos a setear por defecto la hoja que tenga el
      // mismo nombre que la hoja original del resumen, y si no la encontramos vamos a setear la primera hoja del archivo
      if (summaryToEdit !== null) {
  
        setDelayHelper(false)
        if (data.contenido !== undefined) {
          const sheetWithOriginalSheetName = data.contenido.find(
            (sheet: any) => sheet.hoja === summaryToEdit.hoja.label
          );
          setSheetSelectedOption(
            sheetWithOriginalSheetName !== undefined
              ? {value: sheetWithOriginalSheetName.id,  label:sheetWithOriginalSheetName.nombre}
              : formatSheets(data.contenido)[0]
          );
        } else {
          const sheetWithOriginalSheetName = data.hojas.find(
            (sheet: any) => sheet.nombre === summaryToEdit.hoja.label
          );
          setSheetSelectedOption(
            sheetWithOriginalSheetName !== undefined
              ? {value: sheetWithOriginalSheetName.id,  label:sheetWithOriginalSheetName.nombre}
              : {value: data.hojas[0].id,  label: data.hojas[0].nombre}
          );
        }
      }
    }
    replaceFile && setFileToReplaceWith(data)
    if (data.contenido !== undefined) {
      setDataFileState([data]);
      setSheetsOptions(formatSheets(data.contenido));
      checkIfInfoExists(data.contenido);
    } else {
      setDataFileState([
        {
          ...data,
          contenido: data.hojas.map((sheet: any) => {
            return { ...sheet, hoja: sheet.nombre };
          }),
        },
      ]);
      checkIfInfoExists(data.hojas);
      setSheetsOptions(formatSimpleSheets(data.hojas));
    }
  };

  const checkIfInfoExists = (dataSheets: any) => {
    if (sheetSelectedOption?.label.endsWith("eliminada, reemplazar.")) {
      const initialIndex = sheetSelectedOption?.label.indexOf('"');
      // Buscar la posición de la siguiente comilla doble a partir de inicio
      const finalIndex = sheetSelectedOption?.label.indexOf(
        '"',
        initialIndex + 1
      );
      // Extraer el texto entre las comillas
      const sheetName = sheetSelectedOption?.label.substring(
        initialIndex + 1,
        finalIndex
      );
      if (!replaceFile && !dataSheets.map((sheet: any) => sheet.nombre).includes(sheetName)) {
        setColumnSelectedOption(undefined);
        setSheetSelectedOption(undefined);
        summaryDispatch({
          type: "SET_SUMMARY_ROWS",
          payload: [],
        });
        columnSelectRef.current?.clearValue();
        sheetRef?.current?.clearValue();
      }
    } else {
      if (
        sheetSelectedOption && !replaceFile &&
        !dataSheets
          .map((sheet: any) => sheet.nombre)
          .includes(sheetSelectedOption?.label)
      ) {
        setColumnSelectedOption(undefined);
        setSheetSelectedOption(undefined);
        summaryDispatch({
          type: "SET_SUMMARY_ROWS",
          payload: [],
        });
        columnSelectRef.current?.clearValue();
        sheetRef?.current?.clearValue();
      }
    }
  };

  const onErrorGetFileInfo = (data: any) => {
    setFileToReplaceWith(undefined)
    setColumnSelectedOption(undefined);
    setSheetSelectedOption(undefined);
    columnSelectRef?.current?.clearValue();
    sheetRef?.current?.clearValue();
  };

  const onSuccesGetSheetsInfo = (data: any) => {
    setColumnsOptions(data);
    setColumns(data, data);
  };

  const onErrorGetSheetsInfo = (data: any) => {
    setColumnsOptions([]);
    setColumns([], []);
  };

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: selectedFileFolder,
      directorio: false,
      tipo: "azure",
      origen_descarga: "flow",
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );

  const {
    data: dataSheet,
    isLoading: isLoadingSheetInfo,
    callApi: getSheetsInfo,
    error: errorGetSheet,
  } = useApi(
    SHEET_INFO_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccesGetSheetsInfo,
    onErrorGetSheetsInfo,
    false,
    undefined,
    undefined
  );

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile(fileState);
    setSheetSelectedOption(undefined);
    sheetRef?.current?.clearValue()
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo(undefined, {
              path_archivo:
                clickedFile === "chooseFile"
                  ? selectedFile
                  : `${selectedFileFolder}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              origen_descarga: "flow",
              columnas: true,
              actualizar_archivo: true

            });
          } else {
            getFilesInfoById(`${FILE_BY_ID(clickedFile.id)}?columnas=True`);
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const onSuccessSaveSummary = () => {
    localStorage.removeItem("summaryId");
    navigate("/reporte-base/administracion-resumenes");
  };

 const handleReplaceFile = () => {
  setReplaceFile(true)
 } 

  return (
    <ComponentLayout
      title="Resumen de la información"
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={() => {
            navigate("/reporte-base/administracion-resumenes");
            localStorage.removeItem("summaryId");
          }}
        />
      }
    >
      <SimpleBackdrop
        open={isLoadingFileInfo || isLoadingSheetInfo || isLoadingFileInfoById}
        message="Cargando archivo..."
      />
      <SimpleBackdrop
        open={isLoadingGetSummary}
        message={texts.summary.getSummary.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostSummary || isLoadingPutSummary}
        message="Guardando resumen"
      />
      <Stack width="100%" height="80vh" alignItems="center">
        <Stack width="80%" alignItems={"center"} gap={2}>
          <TextField
            id="filled-textarea"
            label={"Nombre del resumen"}
            placeholder={"Nombre del resumen"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              !isValidName(
                dataSummaries,
                summaryName,
                summaryId ? true : false,
                summaryToEdit?.nombre
              )
                ? "Ya existe un resumen con este nombre"
                : ""
            }
            error={
              summaryName === "" ||
              !isValidName(
                dataSummaries,
                summaryName,
                summaryId ? true : false,
                summaryToEdit?.nombre
              )
            }
            fullWidth
            value={summaryName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSummaryName(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
          {summaryToEdit && (
            <OriginalFont label={"Archivo original"} fileName={summaryToEdit?.archivo?.nombre} fileError={!summaryToEdit?.archivo?.existe} setReplaceFile={handleReplaceFile}/>
       
          )}
            {fileToReplaceWith && (
            <OriginalFont label={"Nuevo archivo"} fileName={fileToReplaceWith.nombre} />
       
          )}
          {(!summaryId || replaceFile) && (
            <UploadFileContent
              areMultipleFiles={false}
              selectedFile={selectedFile}
              setSelectedFile={handleSelectFile}
              dataFileState={dataFileState}
              setDataFileState={setDataFileState}
              setSelectedFileFolder={setSelectedFileFolder}
              onSuccessChargeLocalFiles={onSuccessGetFileInfo}
              customCallProperties={[{ columnas: true }]}
            />
          )}
          {dataFileState && dataFileState.length > 0 && (
            <Stack
              direction="row"
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2}>
                {
                  <Grid item xs={6}>
                    <InputLabel sx={form_label}>Hoja </InputLabel>
                    {delayHelper &&      <Select
                      reference={sheetRef}
                      styles={selectStyles(sheetSelectedOption)}
                      options={sheetsOptions}
                      name="Hoja"
                      onChange={sheetOptionsChangeHandler}
                      closeMenuOnSelect
                      defaultValue={sheetSelectedOption}
                      placeholder="Seleccionar hoja del archivo"
                      isClearable
                    />}
               
                  </Grid>
                }
                <Grid item xs={6}>
                  <InputLabel sx={form_label}>Columnas</InputLabel>
                  <Select
                    reference={columnSelectRef}
                    isMulti
                    styles={selectStyles(columnSelectedOptions)}
                    options={columnsOptions}
                    name="Columna"
                    onChange={columnOptionsChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas"
                    defaultValue={columnSelectedOptions}
                    isClearable
                  />
                </Grid>
              </Grid>
            </Stack>
          )}

          <Stack width="100%" height="20px" alignItems={"center"}>
            <Typography
              variant="subtitle1"
              color="error"
              sx={{ fontWeight: "bold" }}
            >
              {message}
            </Typography>
          </Stack>
          <InfoGrid
            info={summaryRows}
            RowTitles={SummaryRowTitles}
            RowSelects={SummaryRowSelects}
            RowInfo={RowSummary}
          />
        </Stack>
        <Stack direction="row" justifyContent={"center"}>
          <Button
            title="Resetear"
            color="grey"
            type="button"
            onClick={toggleConfirmResetSummaryModal}
            /* disabled={!summaryRows || summaryRows.length === 0} */
          />
          <Button
            title="Guardar resumen"
            color="blue-greeny"
            type="button"
            onClick={toggleConfirmCreateSummaryModal}
            disabled={
              !summaryRows ||
              summaryRows.length === 0 ||
              !isValidName(
                dataSummaries,
                summaryName,
                summaryId ? true : false,
                summaryToEdit?.nombre
              ) ||
              summaryName === "" ||
              sheetSelectedOption?.value === null
            }
          />
          <Button
            title="Calcular"
            color="blue"
            type="button"
            onClick={calculateSummary}
            disabled={
              !summaryRows ||
              summaryRows.length === 0 ||
              sheetSelectedOption?.value === null
            }
          />
        </Stack>
        <Stack width="100%">
          {isLoadingPostSummary ? (
            <CustomSkeleton height={6} />
          ) : errorPostSummary ? (
            <p>
              Se produjo un error y no fue posible descargar la información.
            </p>
          ) : (
            summary &&
            summary.length > 0 && (
              <SimpleTable
                columns={transformDataSimpleTable(summary).columns}
                rows={transformDataSimpleTable(summary).rows}
                toolbar={true}
                exportButton={true}
              />
            )
          )}
        </Stack>
        <ConfirmationModal
          open={openConfirmResetSummaryModal}
          handleClose={toggleConfirmResetSummaryModal}
          handleAccept={handleResetData}
          title="Resetear datos"
          message="borrar todos los datos"
        />
        <ConfirmationModal
          open={openConfirmCreateSummaryModal}
          handleClose={toggleConfirmCreateSummaryModal}
          handleAccept={confirmSendSchema}
          title={summaryId ? "Guardar resumen" : "Crear Resumen"}
          message={
            summaryId
              ? "guardar el resumen"
              : "crear un resumen con estos datos"
          }
        />
      </Stack>
    </ComponentLayout>
  );
};

export default InformationSummary;
