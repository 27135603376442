import { useEffect, useRef, useState } from "react";
import ComponentLayout from "../layout/ComponentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useSingleSelect from "../../hooks/useSingleSelect";
import {
  Avatar,
  Box,
  Grid,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../buttons/Button";
import VennChart from "../charts/VennChart";
import useMultiSelect from "../../hooks/useMultiSelect";
import useApi from "../../hooks/useApi";
import {
  FILE_BY_ID,
  FILE_INFO_URL,
  GET_REPORT_BY_TYPE,
  REPORT_BY_ID,
  REPORT_URL,
  SHEET_INFO_BY_ID,
} from "../../api/axios";
import { texts } from "../../texts.js";
import { useSnackbar } from "notistack";
import {
  formatSheets,
  formatSimpleSheets,
  getSheetColumns,
} from "../fileColumns/utils";
import { form_label } from "../../styles/app-styles";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { handleClickVariant } from "../../utils/util";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { isValidName } from "../schemas/utils";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useNavigate } from "react-router";
import OriginalFont from "../dataManagement/OriginalFont";

const CodeValidation = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [validationName, setValidationName] = useState<string>("");
  const [dataFileStateFile1, setDataFileStateFile1] = useState<any>(null);
  const [sheetsFile1Options, setSheetsFile1Options] = useState<any>([]);
  const [columnsFile1Options, setColumnsFile1Options] = useState<any>([]);
  const [selectedFile1, setSelectedFile1] = useState<string[]>([]);
  const [selectedFileFolder1, setSelectedFileFolder1] = useState<string>("");
  const [replaceFile1, setReplaceFile1] = useState<boolean>(false);
  const [file1ToReplaceWith, setFile1ToReplaceWith] = useState<any>(undefined);
  const [file2ToReplaceWith, setFile2ToReplaceWith] = useState<any>(undefined);
  const navigate = useNavigate();

  const column1SelectRef: any = useRef(null);
  const [
    column1SelectedOption,
    setColumn1SelectedOption,
    column1OptionsChangeHandler,
  ] = useMultiSelect();
  const sheet1Ref: any = useRef(null);
  const [
    sheet1SelectedOption,
    setSheet1SelectedOption,
    sheet1OptionsChangeHandler,
  ] = useSingleSelect();

  const [selectedFile2, setSelectedFile2] = useState<string[]>([]);
  const [selectedFileFolder2, setSelectedFileFolder2] = useState<string>("");
  const [dataFileStateFile2, setDataFileStateFile2] = useState<any>(null);
  const [sheetsFile2Options, setSheetsFile2Options] = useState<any>([]);
  const [columnsFile2Options, setColumnsFile2Options] = useState<any>([]);
  const [replaceFile2, setReplaceFile2] = useState<boolean>(false);
  const validationId = localStorage.getItem("validationId")
    ? localStorage.getItem("validationId")
    : undefined;

  const column2SelectRef: any = useRef(null);
  const [
    column2SelectedOption,
    setColumn2SelectedOption,
    column2OptionsChangeHandler,
  ] = useMultiSelect();
  const sheet2Ref: any = useRef(null);
  const [
    sheet2SelectedOption,
    setSheet2SelectedOption,
    sheet2OptionsChangeHandler,
  ] = useSingleSelect();

  /*************************** CARGA DE DATOS ***************************/

  /* Si en el localStorage hay un id, es decir si estamos en modo EDIT, hacemos el get del resumen*/
  useEffect(() => {
    if (validationId) {
      getValidation(REPORT_BY_ID(parseInt(validationId)));
    }
  }, [validationId]);

  const onSuccessGetValidation = (data: any) => {
    setValidationName(data.nombre);
    setSheet1SelectedOption(
      data.izquierda.hoja.value !== null && data.izquierda.archivo.nombre
        ? data.izquierda.hoja
        : {
            value: null,
            label: `Hoja: "${data.izquierda.hoja.label}" eliminada, reemplazar.`,
          }
    );
    setColumn1SelectedOption(data.izquierda.columnas);
    setSheet2SelectedOption(
      data.derecha.hoja.value !== null && data.derecha.archivo.nombre
        ? data.derecha.hoja
        : {
            value: null,
            label: `Hoja: "${data.derecha.hoja.label}" eliminada, reemplazar.`,
          }
    );
    setColumn2SelectedOption(data.derecha.columnas);
    if (data.izquierda.archivo.existe) {
      getFilesInfoEdit1(
        `${FILE_BY_ID(data.izquierda.archivo.id)}?columnas=True&posicion=left`
      );
      if (data.izquierda.hoja.value !== null) {
        getSheetsInfo1(
          SHEET_INFO_BY_ID(data.izquierda.archivo.id, data.izquierda.hoja.value)
        );
      }
    }
    if (data.derecha.archivo.existe) {
      getFilesInfoEdit2(
        `${FILE_BY_ID(data.derecha.archivo.id)}?columnas=True&posicion=right`
      );
      if (data.derecha.hoja.value !== null) {
        getSheetsInfo2(
          SHEET_INFO_BY_ID(data.derecha.archivo.id, data.derecha.hoja.value)
        );
      }
    }
  };

  const {
    isLoading: isLoadingGetValidation,
    callApi: getValidation,
    data: validationToEdit,
  } = useApi(
    undefined,
    "GET",
    texts.codeValidation.getValidation.codes,
    undefined,
    onSuccessGetValidation,
    undefined,
    false
  );

  const { data: dataValidations } = useApiQuery(
    GET_REPORT_BY_TYPE("validacion_codigos"),
    false,
    texts.codeValidation.getValidations.error
  );

  useEffect(() => {
    if (
      sheet1SelectedOption !== undefined &&
      sheet1SelectedOption !== null &&
      dataFileStateFile1
    ) {
      if (dataFileStateFile1[0]?.contenido) {
        setColumnsFile1Options(
          getSheetColumns(
            dataFileStateFile1[0]?.contenido,
            sheet1SelectedOption
          )
        );
      }
    }
    if (
      !validationId ||
      (validationToEdit &&
        sheet1SelectedOption !== undefined &&
        validationToEdit.izquierda.hoja.value !== sheet1SelectedOption?.value)
    ) {
      setColumn1SelectedOption(undefined);
      column1SelectRef?.current?.clearValue();
    }
  }, [sheet1SelectedOption]);

  useEffect(() => {
    if (
      sheet2SelectedOption !== undefined &&
      sheet2SelectedOption !== null &&
      dataFileStateFile2
    ) {
      if (dataFileStateFile2[0]?.contenido) {
        setColumnsFile2Options(
          getSheetColumns(
            dataFileStateFile2[0]?.contenido,
            sheet2SelectedOption
          )
        );
      }
    }
    if (
      !validationId ||
      (validationToEdit &&
        sheet2SelectedOption !== undefined &&
        validationToEdit.derecha.hoja.value !== sheet2SelectedOption?.value)
    ) {
      setColumn2SelectedOption(undefined);
      column2SelectRef?.current?.clearValue();
    }
  }, [sheet2SelectedOption]);

  const onSuccessGenerateReport = (data: any) => {
    if (data.tipo !== undefined) {
      if (data.tipo === "tarea") {
        handleClickVariant(
          `Se ha generado la tarea con id ${data.body.id_tarea} exitosamente, en cuanto finalice podras visualizar el resultado en la pestaña Informes del Gestor de tareas`,
          "success",
          enqueueSnackbar,
          closeSnackbar
        );
      }
    } else {
      onSuccessSendValidation();
    }
  };

  const {
    data: dataReport,
    error: errorReport,
    isLoading: isLoadingReport,
    callApi: sendReport,
  } = useApi(
    REPORT_URL,
    "POST",
    texts.codeValidation.postGenerateReport.codes,
    {
      tipo: "validacion_codigos",
      nombre: validationName,
      calcular: false,
      izquierda: {
        archivo: dataFileStateFile1 && dataFileStateFile1[0]?.id,
        hoja: sheet1SelectedOption?.value,
        columnas: column1SelectedOption?.map((column: any) => {
          return column.label;
        }),
      },
      derecha: {
        archivo: dataFileStateFile2 && dataFileStateFile2[0]?.id,
        hoja: sheet2SelectedOption?.value,
        columnas: column2SelectedOption?.map((column: any) => {
          return column.label;
        }),
      },
    },
    onSuccessGenerateReport,
    undefined,
    false
  );

  const {
    error: errorReportPut,
    isLoading: isLoadingReportPut,
    callApi: editReport,
  } = useApi(
    REPORT_BY_ID(parseInt(validationId as string)),
    "PUT",
    texts.codeValidation.postGenerateReport.codes,
    {
      tipo: "validacion_codigos",
      nombre: validationName,
      calcular: false,
      izquierda: {
        archivo: dataFileStateFile1 && dataFileStateFile1[0]?.id,
        hoja: sheet1SelectedOption?.value,
        columnas: column1SelectedOption?.map((column: any) => {
          return column.label;
        }),
      },
      derecha: {
        archivo: dataFileStateFile2 && dataFileStateFile2[0]?.id,
        hoja: sheet2SelectedOption?.value,
        columnas: column2SelectedOption?.map((column: any) => {
          return column.label;
        }),
      },
    },
    () => {
      onSuccessSendValidation();
    },
    undefined,
    false
  );

  const onSuccessGetFileInfo1 = (data: any) => {
    if (
      !validationId ||
      (validationId !== null &&
        validationToEdit?.izquierda.archivo?.id !== data.id)
    ) {
      setColumn1SelectedOption(undefined);
      setSheet1SelectedOption(undefined);
      column1SelectRef?.current?.clearValue();
      sheet1Ref?.current?.clearValue();
    }
    setDataFileStateFile1([data]);
    replaceFile1 && setFile1ToReplaceWith(data)

    setSheetsFile1Options(formatSheets(data.contenido));
  };

  const onErrorGetFileInfo1 = (data: any) => {
    setColumn1SelectedOption(undefined);
    setSheet1SelectedOption(undefined);
    column1SelectRef?.current?.clearValue();
    sheet1Ref?.current?.clearValue();
    setDataFileStateFile1([]);
    setFile1ToReplaceWith(undefined)
  };

  const onSuccessGetFileInfo2 = (data: any) => {
    if (
      !validationId ||
      (validationId !== null &&
        validationToEdit?.derecha.archivo?.id !== data.id)
    ) {
      setColumn2SelectedOption(undefined);
      setSheet2SelectedOption(undefined);
      column2SelectRef?.current?.clearValue();
      sheet2Ref?.current?.clearValue();
    }
    replaceFile2 && setFile2ToReplaceWith(data)
    setDataFileStateFile2([data]);
    setSheetsFile2Options(formatSheets(data.contenido));
  };

  const onErrorGetFileInfo2 = (data: any) => {
    setColumn2SelectedOption(undefined);
    setSheet2SelectedOption(undefined);
    column2SelectRef?.current?.clearValue();
    sheet2Ref?.current?.clearValue();
    setDataFileStateFile2([])
    setFile2ToReplaceWith(undefined)
  };

  const {
    data: dataFile1,
    isLoading: isLoadingFileInfo1,
    callApi: getFilesInfo1,
    error: errorFile1,
    status: statusFile1,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo1,
    onErrorGetFileInfo1,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFile2,
    isLoading: isLoadingFileInfo2,
    callApi: getFilesInfo2,
    error: errorFile2,
    status: statusFile2,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo2,
    onErrorGetFileInfo2,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const onSuccessGetFileEditInfo1 = (data: any) => {
    setDataFileStateFile1([
      {
        ...data,
        contenido: data.hojas.map((sheet: any) => {
          return { ...sheet, hoja: sheet.nombre };
        }),
      },
    ]);
    setSheetsFile1Options(formatSimpleSheets(data.hojas));
    replaceFile1 && setFile1ToReplaceWith(data)
  };

  const onErrorGetFileEditInfo1 = (data: any) => {
    setDataFileStateFile1([]);
    setSheetsFile1Options([]);
    setFile1ToReplaceWith(undefined)
  };

  /*  const {
    data: dataFileEdit1,
    isLoading: isLoadingFileInfoEdit1,
    callApi: getFilesInfoEdit1,
    error: errorFileEdit1,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileEditInfo1,
    onErrorGetFileEditInfo1,
    false,
    undefined,
    undefined
  );*/

  const {
    data: dataFileById1,
    isLoading: isLoadingFileInfoById1,
    callApi: getFilesInfoEdit1,
    error: errorFileInfoById1,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileEditInfo1,
    onErrorGetFileEditInfo1,
    false,
    undefined,
    undefined
  );

  const onSuccessGetFileEditInfo2 = (data: any) => {
    setDataFileStateFile2([
      {
        ...data,
        contenido: data.hojas.map((sheet: any) => {
          return { ...sheet, hoja: sheet.nombre };
        }),
      },
    ]);
    setSheetsFile2Options(formatSimpleSheets(data.hojas));
    replaceFile2 && setFile2ToReplaceWith(data)

  };

  const onErrorGetFileEditInfo2 = (data: any) => {
    setDataFileStateFile2([]);
    setSheetsFile2Options([]);
  };

  const {
    data: dataFileEdit2,
    isLoading: isLoadingFileInfoEdit2,
    callApi: getFilesInfoEdit2,
    error: errorFileEdit2,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileEditInfo2,
    onErrorGetFileEditInfo2,
    false,
    undefined,
    undefined
  );

  const onSuccesGetSheetsInfo1 = (data: any) => {
    setColumnsFile1Options(data);
  };

  const onErrorGetSheetsInfo1 = (data: any) => {
    setColumnsFile1Options([]);
  };

  const {
    data: dataSheet1,
    isLoading: isLoadingSheetInfo1,
    callApi: getSheetsInfo1,
    error: errorGetSheet1,
  } = useApi(
    SHEET_INFO_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccesGetSheetsInfo1,
    onErrorGetSheetsInfo1,
    false,
    undefined,
    undefined
  );

  const onSuccesGetSheetsInfo2 = (data: any) => {
    setColumnsFile2Options(data);
  };

  const onErrorGetSheetsInfo2 = (data: any) => {
    setColumnsFile2Options([]);
  };

  const {
    data: dataSheet2,
    isLoading: isLoadingSheetInfo2,
    callApi: getSheetsInfo2,
    error: errorGetSheet2,
  } = useApi(
    SHEET_INFO_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccesGetSheetsInfo2,
    onErrorGetSheetsInfo2,
    false,
    undefined,
    undefined
  );

  const handleSendReport = (saveReport: boolean) => {
    if (column1SelectedOption?.length !== column2SelectedOption?.length) {
      handleClickVariant(
        "Es necesario que la cantidad de columnas seleccionadas en cada archivo sea la misma",
        "error",
        enqueueSnackbar,
        closeSnackbar
      );
    } else {
      if (validationId && !saveReport) {
        editReport(undefined, {
          tipo: "validacion_codigos",
          nombre: validationName,
          calcular: saveReport,
          izquierda: {
            archivo: dataFileStateFile1 && dataFileStateFile1[0]?.id,
            hoja: sheet1SelectedOption?.value,
            columnas: column1SelectedOption?.map((column: any) => {
              return column.label;
            }),
          },
          derecha: {
            archivo: dataFileStateFile2 && dataFileStateFile2[0]?.id,
            hoja: sheet2SelectedOption?.value,
            columnas: column2SelectedOption?.map((column: any) => {
              return column.label;
            }),
          },
        });
      } else {
        sendReport(undefined, {
          tipo: "validacion_codigos",
          nombre: validationName,
          calcular: saveReport,
          izquierda: {
            archivo: dataFileStateFile1 && dataFileStateFile1[0]?.id,
            hoja: sheet1SelectedOption?.value,
            columnas: column1SelectedOption?.map((column: any) => {
              return column.label;
            }),
          },
          derecha: {
            archivo: dataFileStateFile2 && dataFileStateFile2[0]?.id,
            hoja: sheet2SelectedOption?.value,
            columnas: column2SelectedOption?.map((column: any) => {
              return column.label;
            }),
          },
        });
      }
    }
  };

  /*  const handleSelectFile1 = (
    fileState: any[],
    clickedFile: string,
    deletedFile?: boolean
  ) => {
    setSelectedFile1(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileStateFile1((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile)
        );
      } else {
        if (clickedFile) {
          getFilesInfo1(undefined, {
            path_archivo: `${selectedFileFolder1}${clickedFile}`,
            directorio: false,
            tipo: "azure",
            columnas: true,
          });
        } else {
          setDataFileStateFile1([]);
        }
      }
    }
  };*/

  const handleSelectFile1 = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile1(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileStateFile1((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo1(undefined, {
              path_archivo: clickedFile === "chooseFile" ?selectedFile1 :`${selectedFileFolder1}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              columnas: true,
              posicion: "left",
              actualizar_archivo: true
            });
          } else {
            getFilesInfoEdit1(
              `${FILE_BY_ID(clickedFile.id)}?columnas=True&posicion=left`
            );
          }
        } else {
          clickedFile !== "typingFile" && setDataFileStateFile1([]);
        }
      }
    }
  };

  /*
  const handleSelectFile2 = (
    fileState: any[],
    clickedFile: string,
    deletedFile?: boolean
  ) => {
    setSelectedFile2(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileStateFile2((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile)
        );
      } else {
        if (clickedFile) {
          getFilesInfo2(undefined, {
            path_archivo: `${selectedFileFolder2}${clickedFile}`,
            directorio: false,
            tipo: "azure",
            columnas: true,
          });
        } else {
          setDataFileStateFile2([]);
        }
      }
    }
  };
*/

  const handleSelectFile2 = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile2(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileStateFile2((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo2(undefined, {
              path_archivo: clickedFile === "chooseFile" ?selectedFile2 :`${selectedFileFolder2}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              columnas: true,
              posicion: "right",
              actualizar_archivo: true
            });
          } else {
            getFilesInfoEdit2(
              `${FILE_BY_ID(clickedFile.id)}?columnas=True&posicion=right`
            );
          }
        } else {
          clickedFile !== "typingFile" && setDataFileStateFile2([]);
        }
      }
    }
  };

  const onSuccessSendValidation = () => {
    localStorage.removeItem("validationId");
    navigate("/reporte-base/administracion-validaciones");
  };

  return (
    <ComponentLayout
      title="Validación de códigos"
      icon={
        <ArrowBackIcon
          sx={{
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={onSuccessSendValidation}
        />
      }
    >
      <SimpleBackdrop
        open={isLoadingReport}
        message={texts.codeValidation.postGenerateReport.loading}
      />
      <SimpleBackdrop
        open={isLoadingGetValidation}
        message={texts.codeValidation.getValidation.loading}
      />
      <SimpleBackdrop
        open={
          isLoadingFileInfo1 ||
          isLoadingFileInfo2 ||
          isLoadingFileInfoById1 ||
          isLoadingFileInfoEdit2
        }
        message="Cargando archivo..."
      />
      <SimpleBackdrop
        open={isLoadingReportPut}
        message="Guardando validación..."
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "92%",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="filled-textarea"
            label={"Nombre de la validación"}
            placeholder={"Nombre de la validación"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              !isValidName(
                dataValidations,
                validationName,
                validationId ? true : false,
                validationToEdit?.nombre
              )
                ? "Ya existe una validación con este nombre"
                : ""
            }
            error={
              validationName === "" ||
              !isValidName(
                dataValidations,
                validationName,
                validationId ? true : false,
                validationToEdit?.nombre
              )
            }
            fullWidth
            value={validationName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValidationName(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "98%",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              width: "48%",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                color: "var(--blue)",
                margin: "10px",
                fontWeight: "600",
                marginLeft: "0px !important",
              }}
            >
              Archivo 1:{" "}
            </Typography>
                 {validationToEdit && (
              <Stack width="100%" marginBottom="10px">
               <OriginalFont fileName={validationToEdit?.izquierda.archivo.nombre} fileError={!validationToEdit?.izquierda.archivo.existe} setReplaceFile={() => {
                      setReplaceFile1(true);
                    }} label="Archivo original"/>
              </Stack>
            )}
            {file1ToReplaceWith && (
            <OriginalFont label={"Nuevo archivo"} fileName={file1ToReplaceWith.nombre_archivo} />
          )}
            {(!validationId || replaceFile1) && (
              <UploadFileContent
                areMultipleFiles={false}
                selectedFile={selectedFile1}
                setSelectedFile={handleSelectFile1}
                dataFileState={dataFileStateFile1}
                setDataFileState={setDataFileStateFile1}
                setSelectedFileFolder={setSelectedFileFolder1}
                onSuccessChargeLocalFiles={onSuccessGetFileInfo1}
                customCallProperties={[{ columnas: true }]}
                customWidth="600px"
              />
            )}

            {
              <>
                {dataFileStateFile1 && (
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel sx={form_label}>Hoja </InputLabel>
                        <Select
                          reference={sheet1Ref}
                          styles={selectStyles(sheet1SelectedOption)}
                          options={sheetsFile1Options}
                          name="Hoja"
                          onChange={sheet1OptionsChangeHandler}
                          closeMenuOnSelect
                          placeholder="Seleccionar hoja del archivo"
                          isClearable
                          defaultValue={sheet1SelectedOption}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel sx={form_label}>Columnas</InputLabel>
                        <Select
                          reference={column1SelectRef}
                          isMulti
                          styles={selectStyles(column1SelectedOption)}
                          options={columnsFile1Options}
                          name="Columna"
                          onChange={column1OptionsChangeHandler}
                          closeMenuOnSelect={false}
                          placeholder="Seleccionar columnas"
                          isClearable
                          defaultValue={column1SelectedOption}
                          isDisabled={sheet1SelectedOption === undefined}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                )}
              </>
            }
          </Box>
          <Box
            sx={{
              width: "48%",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                color: "var(--blue)",
                margin: "10px",
                fontWeight: "600",
                marginLeft: "0px !important",
              }}
            >
              Archivo 2:{" "}
            </Typography>
            {validationToEdit && (
              <Stack width="100%" marginBottom="10px">
               <OriginalFont fileName={validationToEdit?.derecha.archivo.nombre} fileError={!validationToEdit?.derecha.archivo.existe} setReplaceFile={() => {
                      setReplaceFile2(true);
                    }} label="Archivo original"/>
              </Stack>
            )}
             {file2ToReplaceWith && (
            <OriginalFont label={"Nuevo archivo"} fileName={file2ToReplaceWith.nombre_archivo} />
       
          )}
            {(!validationId || replaceFile2) && (
              <UploadFileContent
                areMultipleFiles={false}
                selectedFile={selectedFile2}
                setSelectedFile={handleSelectFile2}
                dataFileState={dataFileStateFile2}
                setDataFileState={setDataFileStateFile2}
                setSelectedFileFolder={setSelectedFileFolder2}
                onSuccessChargeLocalFiles={onSuccessGetFileInfo2}
                customCallProperties={[{ columnas: true }]}
                customWidth="600px"
              />
            )}
            {dataFileStateFile2 && (
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel sx={form_label}>Hoja </InputLabel>
                    <Select
                      reference={sheet2Ref}
                      styles={selectStyles(sheet2SelectedOption)}
                      options={sheetsFile2Options}
                      name="Hoja"
                      onChange={sheet2OptionsChangeHandler}
                      closeMenuOnSelect
                      defaultValue={sheet2SelectedOption}
                      placeholder="Seleccionar hoja del archivo"
                      isClearable
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel sx={form_label}>Columnas</InputLabel>
                    <Select
                      reference={column2SelectRef}
                      isMulti
                      styles={selectStyles(column2SelectedOption)}
                      options={columnsFile2Options}
                      name="Columna"
                      onChange={column2OptionsChangeHandler}
                      closeMenuOnSelect={false}
                      placeholder="Seleccionar columnas"
                      isClearable
                      isDisabled={sheet2SelectedOption === undefined}
                      defaultValue={column2SelectedOption}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            marginTop: "25px",
            marginRight: "58px",
          }}
        >
          <Button
            title="Guardar validación"
            color="blue-greeny"
            type="button"
            margin={true}
            onClick={() => {
              handleSendReport(false);
            }}
            disabled={
              column1SelectedOption === undefined ||
              column1SelectedOption?.length === 0 ||
              column2SelectedOption === undefined ||
              column2SelectedOption?.length === 0 ||
              sheet1SelectedOption?.value === null ||
              sheet2SelectedOption?.value === null ||
              !isValidName(
                dataValidations,
                validationName,
                validationId ? true : false,
                validationToEdit?.nombre
              ) ||
              validationName === ""
            }
          />
          <Button
            title="Calcular"
            color="blue"
            type="button"
            margin={true}
            onClick={() => {
              handleSendReport(true);
            }}
            disabled={
              column1SelectedOption === undefined ||
              column1SelectedOption?.length === 0 ||
              column2SelectedOption === undefined ||
              column2SelectedOption?.length === 0 ||
              sheet1SelectedOption?.value === null ||
              sheet2SelectedOption?.value === null ||
              !isValidName(
                dataValidations,
                validationName,
                validationId ? true : false,
                validationToEdit?.nombre
              ) ||
              validationName === ""
            }
          />
        </Box>
        {dataReport &&
          dataReport !== undefined &&
          dataReport?.tipo === "validacion_codigos" &&
          !isLoadingReport && (
            <Box
              sx={{
                marginTop: "10px",
                marginBottom: "50px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#f2f2f2",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <VennChart data={dataReport?.body} />
              </Box>
            </Box>
          )}
      </Box>
    </ComponentLayout>
  );
};

export default CodeValidation;
