import ComponentLayout from "../layout/ComponentLayout";
import { useLocation, useNavigate } from "react-router-dom";
import FolderCard from "./FolderCard";
import Grow from "@mui/material/Grow";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SearchInput from "../forms/SearchInput";
import { Item } from "../basicGrid/BasicGrid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FixedAddButton from "../buttons/FixedAddButton";
import UploadFileModal from "./UploadFileModal/UploadFileModal";
import { useContext } from "react";
import DataManagementProvider from "../../context/DataManagementContext";
import useApi from "../../hooks/useApi";
import { ALLOWED_FILES_URL, DATA_URL } from "../../api/axios";
import { texts } from "../../texts";
import { getRoute } from "../flowConfiguration/Modals/AddFont/utils";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { filterDataByResourceName, isResourceSelected, orderResourcesByName } from "../../utils/util";
import Filters from "./Filters";
import SelectFiles from "./SelectFiles";
import SelectViewType from "./SelectViewType";
import ResourcesListHeader from "./ResourcesListHeader";
import ReloadResources from "./ReloadResources";
import Configs from "./Configs";
import AcceptedFiles from "./AcceptedFiles";
import { useApiQuery } from "../../hooks/useApiQuery";

const Folder = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [viewType, setViewType] = useState<"list" | "cards">("cards");
  const [filteredData, setFilteredData] = useState<any[]>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [isSelectedModeActive, setIsSelectedModeActive] =
    useState<boolean>(false);
  const [isLoadingPostFolder, setIsLoadingPostFolder] =
    useState<boolean>(false);
  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);
  const { openUploadModal } = DataState;
  const navigate = useNavigate();
  const location = useLocation();

  /*********************  CARGA DE DATOS *********************************/
  const onSuccessGetData = (data: any) => {
    // Ordenamos la data de forma ascendente
    setFilteredData(orderResourcesByName(data));
    dataDispatch({
      type: "SET_FOLDER_FILES",
      payload: data,
    });
  };

  const {
    data: dataResources,
    error: isErrorDataResources,
    isLoading: isLoadingDataResources,
    callApi: getResources,
  } = useApi(
    `${DATA_URL}?ruta=${encodeURIComponent(getRoute(location.pathname))}/`,
    "GET",
    texts.dataManagement.getResources.error,
    undefined,
    onSuccessGetData,
    undefined,
    false
  );

  useEffect(() => {
    getResources(
      `${DATA_URL}?ruta=${encodeURIComponent(getRoute(location.pathname))}/`
    );
    setSelectedFiles([]);
  }, [location.pathname]);

  const refetchResources = () => {
    getResources(
      `${DATA_URL}?ruta=${encodeURIComponent(
        getRoute(location.pathname)
      )}&cache=false/`
    );
  };

  const {
    isLoading: isLoadingFilesNames,
    callApi: getAllowedFileNames,
    data: allowedFileNames,
  } = useApi(
    ALLOWED_FILES_URL,
    "GET",
    texts.dataManagement.getAllowedFileNames.codes,
    undefined,
    undefined,
    undefined,
    false
  );
  /************************** HANDLERS ***********************************/

  const handleClickOnResource = (file: any) => {
    if (selectedFiles.map((file: any) => file.name).includes(file.name)) {
      setSelectedFiles(
        selectedFiles.filter(
          (selectedFile: any) => selectedFile.name !== file.name
        )
      );
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      filterDataByResourceName(dataResources, e.target.value, setFilteredData);
    } else {
      setFilteredData(dataResources);
    }
  };

  const handleToggleUploadModal = () => {
    dataDispatch({
      type: "SET_OPEN_UPLOAD_MODAL",
      payload: !openUploadModal,
    });
  };

  const handleCloseModal = (tab: string | undefined) => {
    if (tab === "CARGAR ARCHIVOS") {
      refetchResources();
    }
    handleToggleUploadModal();
  };

  const goBack = () => {
    // Paso 1: Remover cualquier barra al final de la carpeta seleccionada
    const cleanedFolders = location.pathname.replace(/\/$/, "");

    // Paso 2: Formatear las carpetas en un array
    const formattedFolders = cleanedFolders.split("/");

    // Paso 3: Remover la última carpeta
    formattedFolders.pop();
    formattedFolders.pop();

    // Paso 4: Reconstruir la carpeta seleccionada y navegar a
    const newSelectedFolder = formattedFolders.join("/");

    // Paso 5: Navegar a la nueva ruta
    navigate(newSelectedFolder);
  };

  return (
    <>
      <ComponentLayout
        title={`Administración de datos - ${decodeURIComponent(location.pathname
          .split("/")
          .pop() as string)
          ?.replace(/%20/g, " ")}`}
        icon={
          <ArrowBackIcon
            sx={{
              "& :hover": { cursor: "pointer", color: "#f90d4a" },
            }}
            onClick={goBack}
          />
        }
        rightComponent={<Configs />}
      >
        <SimpleBackdrop
          open={isLoadingDataResources}
          message={texts.dataManagement.getResources.loading}
        />
        <SimpleBackdrop
          open={isLoadingPostFolder}
          message={texts.dataManagement.postFolder.loading}
        />
        <Stack width="100%">
          {/*     
            <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder="Búsqueda por nombre"
            />
          </Stack>
         */}

          <Stack direction={"row"} width="100%">
            <SelectFiles
              selectedFiles={selectedFiles}
              setIsSelectedModeActive={setIsSelectedModeActive}
              setSelectedFiles={setSelectedFiles}
              isSelectedModeActive={isSelectedModeActive}
              refreshResources={refetchResources}
              resources={filteredData}

            />
            <Filters
              setFilteredData={setFilteredData}
              initialData={dataResources}
              valueToCleanValues={location.pathname}
              filteredData={filteredData}
            />
          </Stack>

          <Stack sx={{ flexDirection: "row", cursor: "pointer" }}>
            <ReloadResources refetchResources={refetchResources} />

            <SelectViewType viewType={viewType} setViewType={setViewType} />
            {`${location.pathname
              .split("/")
              .pop()
              ?.replace(/%20/g, " ")
              .toUpperCase()}` === "PHI" && (
              <AcceptedFiles
                isButton={true}
                refetchAllowedFiles={getAllowedFileNames}
              />
            )}
          </Stack>
          {!isLoadingDataResources && !isErrorDataResources && (
            <>
              {filteredData !== undefined && filteredData?.length === 0 && (
                <Box
                  sx={{
                    width: "95%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "700",
                      color: "var(--dark-grey)",
                    }}
                  >
                    No hay recursos disponibles.
                  </Typography>
                </Box>
              )}

              {filteredData &&
                filteredData?.length > 0 &&
                filteredData.filter((resource: any) => {
                  return resource.tipo === "carpeta";
                }).length > 0 && (
                  <>
                    <Stack width={"100%"} direction={"row"}>
                      <Typography
                        sx={{
                          color: "var(--blue)",
                          fontSize: "19px",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Carpetas
                      </Typography>
                    </Stack>
                    <Stack
                      width={"100%"}
                      direction={viewType === "list" ? "column" : "row"}
                      gap={viewType === "list" ? 0 : 3}
                      flexWrap="wrap"
                      marginBottom="30px"
                    >
                      {viewType === "list" && <ResourcesListHeader />}
                      {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData.filter((resource: any) => {
                        return resource.tipo === "carpeta";
                      }).length > 0 ? (
                        filteredData
                          .filter((resource: any) => {
                            return resource.tipo === "carpeta";
                          })
                          .map((folder: any, index: number) => {
                            return (
                              <Grow
                                in={true}
                                {...{
                                  timeout:
                                    1000 + (index < 50 ? index : 50) * 500,
                                }}
                                key={folder.nombre}
                              >
                                <Item
                                  id="gridItem"
                                  elevation={0}
                                  sx={{
                                    padding:
                                      viewType === "list"
                                        ? "3px 0px"
                                        : undefined,
                                  }}
                                >
                                  <FolderCard
                                    name={folder.nombre}
                                    type={folder.tipo}
                                    id={folder.id}
                                    folderLocation={folder.ubicacion}
                                    label={folder?.etiqueta}
                                    refreshResources={refetchResources}
                                    date={folder.fecha_creacion}
                                    setSelectedResource={handleClickOnResource}
                                    viewType={viewType}
                                    isSelectedModeActive={isSelectedModeActive}
                                    isSelectedGlobal={isResourceSelected(selectedFiles, folder)}

                                  />
                                </Item>
                              </Grow>
                            );
                          })
                      ) : (
                        <Box
                          sx={{
                            width: "95%",
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "700",
                              color: "var(--dark-grey)",
                            }}
                          >
                            No hay carpetas disponibles.
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </>
                )}
              {filteredData &&
                filteredData?.length > 0 &&
                filteredData.filter((resource: any) => {
                  return resource.tipo === "archivo";
                }).length > 0 && (
                  <>
                    {" "}
                    <Typography
                      sx={{
                        color: "var(--blue)",
                        fontSize: "19px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Archivos
                    </Typography>
                    {viewType === "list" && <ResourcesListHeader size={true} />}
                    <Stack
                      width={"100%"}
                      gap={viewType === "list" ? 0 : 3}
                      marginBottom="30px"
                      direction={viewType === "list" ? "column" : "row"}
                      flexWrap="wrap"
                    >
                      {filteredData &&
                      filteredData?.length > 0 &&
                      filteredData.filter((f: any) => {
                        return f.tipo === "archivo";
                      }).length > 0 ? (
                        filteredData
                          .filter((f: any) => {
                            return f.tipo === "archivo";
                          })
                          .map((file: any, index: number) => {
                            return (
                              <Grow
                                in={true}
                                {...{
                                  timeout:
                                    1000 + (index < 50 ? index : 50) * 500,
                                }}
                                key={file.nombre}
                              >
                                <Item
                                  id="gridItem"
                                  elevation={0}
                                  sx={{
                                    padding:
                                      viewType === "list"
                                        ? "3px 0px"
                                        : undefined,
                                  }}
                                >
                                  <FolderCard
                                    name={file.nombre}
                                    id={file.id}
                                    type={file.tipo}
                                    folderLocation={file.ubicacion}
                                    label={file?.etiqueta}
                                    refreshResources={refetchResources}
                                    date={file.fecha_creacion}
                                    size={file.peso}
                                    setSelectedResource={handleClickOnResource}
                                    isSelectedModeActive={isSelectedModeActive}
                                    viewType={viewType}
                                    isSelectedGlobal={isResourceSelected(selectedFiles, file)}
                                    isExternalResource={file.externo}
                                    resourceId={file.externo ? file.recurso_id : undefined}
                                  />
                                </Item>
                              </Grow>
                            );
                          })
                      ) : (
                        <Box
                          sx={{
                            width: "95%",
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "700",
                              color: "var(--dark-grey)",
                            }}
                          >
                            No hay archivos disponibles.
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </>
                )}
            </>
          )}
          {location.pathname.split("/").pop()?.replace(/%20/g, " ") !==
            "PHI" && (
            <FixedAddButton
              handleClick={handleToggleUploadModal}
              customColor="var(--blue)"
            />
          )}

          <UploadFileModal
            open={openUploadModal}
            handleClose={handleCloseModal}
            isDataManagementPage={true}
            onSuccessPostFolder={refetchResources}
            setIsLoadingPostFolder={setIsLoadingPostFolder}
            folders={dataResources?.filter((resource: any) => {
              return resource.tipo === "carpeta";
            })}
          />
        </Stack>
      </ComponentLayout>
    </>
  );
};

export default Folder;
