import { Stack, Typography } from "@mui/material";
import ControlledAccordions from "../accordion/ControlledAccordion";
import { glossaryTexts } from "../helpContent/glossaryTexts";
import { FAQs } from "./FAQs";

const HelpContent = () => {
  return (
    <Stack sx={{ minWidth: "1000px" }}>
      <Typography variant="caption" sx={{ fontSize: "24px", color:"var(--blue)" }} gutterBottom>
        Glosario
      </Typography>
      <ControlledAccordions helpTexts={glossaryTexts} />

      <Typography
        variant="caption"
        sx={{ fontSize: "24px", mt: "20px", color:"var(--blue)" }}
        gutterBottom
      >
        Preguntas frecuentes
      </Typography>
      <ControlledAccordions helpTexts={FAQs} />

      <Typography
        variant="caption"
        sx={{ fontSize: "24px", mt: "20px", color:"var(--blue)" }}
        gutterBottom
      >
        Contacto
      </Typography>
      <Typography variant="body1" sx={{ color:"var(--blue)" }}>soporte@phisolutions.co</Typography>
    </Stack>
  );
};

export default HelpContent;
