import { height } from "@mui/system";

export const selectStyles = (selectedOptions: any, width?: string, minWidth?: string, maxWidth?: string ) => {
  return {
    control: (styles: any, { isFocused, isDisabled }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: isDisabled
        ? "1px solid #9A999E"
        : !selectedOptions || selectedOptions.length === 0
        ? isFocused
          ? "3px solid var(--magenta)"
          : "1px solid var(--magenta)"
        : isFocused
        ? "2px solid #16738a"
        : "1px solid #d7d7d7",
      width: width || "100%",
      minWidth: minWidth || "100px",
      maxWidth: maxWidth || "2000px",
      color: isDisabled ? "#9A999E" : "var(--blue-greeny)",
    }),
    group: () => ({
      borderBottom: "1px dotted var(--light-grey)",
      padding: "5px",
      margin: "5px",
    }),
    groupHeading: () => ({
      fontSize: "24px",
      fontWeight: "bold",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      opacity: "1",
      color: "var(--blue-greeny)",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgb(222, 235, 255)",
      },
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 100,
    }),
  };
};

export const selectSchemaStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border:
        !selectedOptions || selectedOptions.length === 0
          ? isFocused
            ? "3px solid #f90d4a"
            : "1px solid #f90d4a"
          : isFocused
          ? "2px solid #16738a"
          : "1px solid #d7d7d7",
    }),
    /*
    menu: () => ({
      "overflow-y": "scroll"
    })*/
  };
};

export const selectMultiStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused, isDisabled }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border:
        !selectedOptions || selectedOptions.length === 0
          ? isFocused
            ? "2px solid #16738a"
            : "1px solid #c9c9c9"
          : isFocused
          ? "2px solid #16738a"
          : "1px solid #c9c9c9",
      backgroundColor: isDisabled ? "#f5f5f5" : "#fff",
    }),
  };
};

export const selectDisabledStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: "1px solid #9A999E",
      backgroundColor: "#d7d7d7",
    }),
  };
};

export const optionalSelectStyles = (selectedOptions: any) => {
  return {
    control: (styles: any, { isFocused }: any) => ({
      display: "flex",
      borderRadius: "5px",
      border: isFocused ? "2px solid #16738a" : "1px solid #c9c9c9",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      opacity: "1",
      color: "var(--blue-greeny)",
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgb(222, 235, 255)",
      },
    }),
  };
};
