import { Box, Stack, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { isNameRepeated } from "../pipelines/utils";
import { isColumnNameValid } from "./utils";
import { texts } from "../../texts";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

interface CustomColumnInputProps {
  column: any;
  setColumnsError: React.Dispatch<React.SetStateAction<any[]>>;
  newObjectNames: any;
  setNewObjectNames: React.Dispatch<React.SetStateAction<any>>;
  saveValueInObject: (object: any, param: any, value: any) => any;
  isDeleted: boolean;
  setDeletedColumns: (col: string) => any;
  nonExistentColumn: boolean,
  nonExistentColumns: any;
}

const CustomColumnInputFlow = ({
  column,
  setColumnsError,
  newObjectNames,
  setNewObjectNames,
  saveValueInObject,
  isDeleted,
  setDeletedColumns,
  nonExistentColumn,
  nonExistentColumns
}: CustomColumnInputProps) => {
  const [inputValue, setInputValue] = useState(column.nombre_nuevo);
  const [error, setError] = useState<boolean | string>(false);

  /**
   * @description Esta función se utiliza para manejar cambios en el valor de una columna. Actualiza el valor de entrada, realiza cambios en un objeto de nombres, y gestiona errores relacionados con la columna.
   *
   * @param {string} value - El nuevo valor que se desea asignar a la columna.
   * @returns {void} - Esta función no devuelve un valor explícito, ya que opera directamente sobre el estado interno y los errores de la columna.
   */

  const handleChange = (value: string) => {
    setInputValue(value);
    // Actualiza el objeto de nombres con el nuevo valor asignado a la columna
    setNewObjectNames(
      saveValueInObject(newObjectNames, column.nombre_viejo, value)
    );
    if (value.length < 1) {
      setError("El nombre de la columa no puede estar vacío");
      setColumnsError((prev: any[]) => {
        return [...prev, column.nombre_viejo];
      });
    } else {
      if (!isColumnNameValid(value)) {
        setError(texts.flows.newColumn.helperText.invalidName);
        setColumnsError((prev: string[]) => {
          return [...prev, column.nombre_viejo];
        });
        return;
      }
      // Filtrar las claves que no están en nonExistentColumns
      const newObjectNamesWithOutNonExistentColumns = Object.keys(newObjectNames).filter(key => !nonExistentColumns.includes(key)) 
      .map(key => newObjectNames[key]);
      const isDuplicatedName = isNameRepeated(
        newObjectNamesWithOutNonExistentColumns,
        value
      );
      if (isDuplicatedName) {
        setError("Ya existe una columna con este nombre");
        setColumnsError((prev: any[]) => {
          return [...prev, column.nombre_viejo];
        });
      } else {
        setError(false);
        setColumnsError((prev: any[]) => {
          return prev.filter((columna: any) => {
            return columna !== column.nombre_viejo;
          });
        });
      }
    }
  };

  return (
    <Stack
      sx={{
        alignItems: "stretch",
        flexDirection: "row",
        margin: "13px 0px",
        width: "90%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: (isDeleted ? "var(--light-grey)" :  (nonExistentColumn ? "var(--red)" : "var(--blue)")),
          borderRadius: "4px",
          color: "white",
          display: "flex",
          fontSize: column.nombre_viejo.length > 17 ? "14px" : "16px",
          justifyContent: "center",
          marginRight: "8px",
          minWidth: "350px",
          textAlign: "center",
          width: "50%",
          wordBreak: "break-all",
        }}
      >
        {`${column.nombre_viejo} ${nonExistentColumn ? " (no existente en fuente actual)": isDeleted ? " (eliminada)" : ""}`}
      </Box>
      <Tooltip
        title={nonExistentColumn?"" : isDeleted ? "Restaurar columna" : "Eliminar columna"}
        placement={"right"}
        sx={{
          pointerEvents: "fill",
        }}
      >
        {!isDeleted ? (
          <DeleteIcon
            sx={{
              color: "var(--light-grey) !important",
              cursor: "pointer",
              fontSize: "28px",
              margin: "auto",
              marginRight: "5px",
              transition: "all 1s linear",
              "& :hover": { color: "var(--magenta)" },
            }}
            onClick={() => {
             setDeletedColumns(column.nombre_viejo);
            }}
          />
        ) : (
          <SettingsBackupRestoreIcon
            sx={{
              color: "var(--light-grey) !important",
              cursor: "pointer",
              fontSize: "28px",
              margin: "auto",
              marginRight: "5px",
              transition: "all 1s linear",
              "& :hover": { color: "var(--magenta)" },
            }}
            onClick={() => {
              setDeletedColumns(column.nombre_viejo);
            }}
          />
        )}
      </Tooltip>

      <TextField
        sx={{
          height: "100%",
          minWidth: "300px",
          padding: "0 !important",
          width: "50%",
        }}
        size="small"
        multiline
        variant="outlined"
        disabled={nonExistentColumn}
        helperText={error}
        error={!!error}
        fullWidth
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value);
        }}
        FormHelperTextProps={{
          sx: {
            color: "var(--magenta)",
            margin: 0,
          },
        }}
        required
      />
    </Stack>
  );
};

export default CustomColumnInputFlow;