import { ColorlibStepIconRoot } from "../../../styles/app-styles";
import { icons } from "./icons";

const ColorlibStepIcon = (props: any) => {
  const { active, completed, className, isStepperActive} = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, isStepperActive }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

export default ColorlibStepIcon;
