import { Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { GET_CONNECTIONS_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useDialog from "../../../hooks/useDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CreateConnectionModal from "./CreateConnectionModal";
import { useState } from "react";
import Connection from "./Connection";

const Connections = () => {
  const [openConfigModal, toggleConfigModal] = useDialog();
  const [openCreateConnectionModal, toggleCreateConnectionModal] = useDialog();
  const [isLoadingPostConnection, setIsLoadingPostConnection] = useState(false);

  /********************************* LLAMADAS ************************************************/

  const {
    data: dataConnections,
    isLoading: isLoadingGetConnections,
    refetch: refetchConnections,
    error: errorConnections,
  } = useApiQuery(
    GET_CONNECTIONS_URL,
    false,
    texts.dataManagement.getConnections.error
  );

  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/

  return (
    <>
      <SimpleBackdrop
        open={isLoadingGetConnections}
        message={texts.dataManagement.deleteLabel.loading}
      />

      <Stack direction={"row"} alignItems="center">
        <Button
          title="Gestionar conexiones"
          type="button"
          onClick={toggleConfigModal}
          color="light-blue"
        />
      </Stack>
      <Dialog
        open={openConfigModal}
        handleClose={toggleConfigModal}
        title={"Gestionar conexiones"}
        maxWidth="xl"
      >
        <SimpleBackdrop
          open={isLoadingPostConnection}
          message={texts.dataManagement.postConnection.loading}
        />
        <Stack
          sx={{
            width: "800px",
            minHeight: "350px",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", width: "100%", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "19px",
                textTransform: "uppercase",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Conexiones
            </Typography>
            <Tooltip title="Agregar conexión">
              <Stack
                sx={{
                  flexDirection: "row",
                  backgroundColor: "var(--blue)",
                  marginBottom: "15px",
                  borderRadius: "14px",
                  padding: "2px 8px",
                  marginLeft: "auto",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => {
                  toggleCreateConnectionModal();
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "white",
                    marginRight: "5px",
                  }}
                >
                  Agregar conexión
                </Typography>
                <AddCircleOutlineIcon
                  sx={{
                    fontSize: "20px",
                    color: "white",
                    transition: "all 0.4s",
                    marginLeft: "4px",
                    marginBottom: "3px",
                    "&:hover": {
                      transform: "scale(1.15)",
                      transition: "0.3s all",
                    },
                  }}
                />
              </Stack>
            </Tooltip>
          </Stack>
          {dataConnections !== undefined &&
            dataConnections.length > 0 &&
            dataConnections.map((connection: any) => {
              return <Connection key={connection.id} connection={connection} dataConnections={dataConnections} isDeletable={true} refetchConnections={refetchConnections} isEditable={true}/>;
            })}
          {dataConnections !== undefined && dataConnections.length === 0 && (
            <Typography
              sx={{ fontWeight: "bold", color: "var(--dark-grey)", margin:"auto", fontSize:"20px" }}
            >
              Aún no hay conexiones
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={() => {
              toggleConfigModal();
            }}
            color="light-grey"
          />
        </Stack>
      </Dialog>
      <CreateConnectionModal
        open={openCreateConnectionModal}
        dataConnections={dataConnections}
        handleClose={toggleCreateConnectionModal}
        onSuccessSendConnection={refetchConnections}
        setIsLoadingPostConnection={setIsLoadingPostConnection}
      />
    </>
  );
};

export default Connections;
