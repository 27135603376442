import { createContext, useReducer } from "react";
import { SummaryAction, SummaryState } from "./types";

const SummaryContext = createContext<any>({});

export const SummaryProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialSummaryState: SummaryState = {
    file: {
      name: "",
      id: 0,
      url: "",
    },
    message: "",
    summaryName: "",
    summary: [],
    summaryRows: [],
    columns: [],
    columnsWithType: [],
  };

  function summaryReducer(summaryState: SummaryState, action: any) {
    switch (action.type) {
      case "SET_URL":
        return {
          ...summaryState,
          file: { ...summaryState.file, url: action.payload },
        };
      case "SET_FILE_DATA":
        return {
          ...summaryState,
          file: {
            ...summaryState.file,
            name: action.payload.name,
            id: action.payload.id,
          },
        };
        case "SET_MESSAGE":
        return {
          ...summaryState,
          message: action.payload,
        };
      case "SET_SUMMARY_NAME":
        return {
          ...summaryState,
          summaryName: action.payload,
        };   
      case "SET_COLUMNS":
        return {
          ...summaryState,
          columns: action.payload,
        };        
      case "SET_SUMMARY":
        return {
          ...summaryState,
          summary: action.payload,
        };
      case "SET_SUMMARY_ROWS":
        return {
          ...summaryState,
          summaryRows: action.payload,
        };
      case "SET_COLUMNS_WITH_TYPE":
        return {
          ...summaryState,
          columnsWithType: action.payload,
        };
      default:
        throw new Error();
    }
  }

  const [summaryState, summaryDispatch] = useReducer<
    React.Reducer<SummaryState, SummaryAction>
  >(summaryReducer, initialSummaryState);

  return (
    <SummaryContext.Provider
      value={{
        initialSummaryState,
        summaryReducer,
        summaryState,
        summaryDispatch,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryContext;
