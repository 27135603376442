import { useContext, useEffect, useState } from "react";
import FlowsProvider from "../../../context/FlowsContext";
import { Box, Stack, TextField, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import { ITEM_URL, POST_ITEM_URL, VIEW_RESULTS_URL } from "../../../api/axios";
import { searchStepper, findBeforeElementPosition } from "../utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import SimpleTable from "../../tablesTools/SimpleTable";
import BaseModal from "./BaseModal";
import useDialog from "../../../hooks/useDialog";
import { modal_typography_title_styles } from "../../../styles/app-styles";
interface SetHeaderProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const SetHeader = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: SetHeaderProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemToEdit } = flowState;
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [inputValueRangeTo, setInputValueRangeTo] = useState<
    number | string | undefined
  >(undefined);
  const [tableDataState, setTableDataState] = useState<any>(undefined);

  /********************************* CARGA DE DATOS ***********************************/

  const onSuccesGetData = (data: any) => {
    setTableDataState(data);
  };

  useEffect(() => {
    if (dataItem) {
      setInputValueRangeTo(dataItem.indice);
    }
  }, [dataItem]);

  useEffect(() => {
    if (open) {
      getTableData();
    }
  }, [open]);

  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
  } = useApi(
    VIEW_RESULTS_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem && itemToEdit?.id
      )
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );
  /********************************* VALIDACIÓN DE DATOS ******************************/

  const validateAccept = () => {
    return inputValueRangeTo === undefined || inputValueRangeTo === "";
  };

  /******************************* POSTEAR ITEM *********************************************/

  const saveSetHeader = () => {
    const completedInfo = {
      tipo: "setear_cabecera",
      indice: inputValueRangeTo,
    };
    toggleConfirmationModal();
    if (dataItem) {
      if (inputValueRangeTo?.toString() !== dataItem.indice.toString()) {
        putItem(
          ITEM_URL(data_flow.id, active_stepper, itemToEdit.id),
          completedInfo
        );
      }
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), completedInfo);
    }

    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setInputValueRangeTo(undefined);
    setTableDataState(undefined);
    handleClose();
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

  return (
    <BaseModal
      open={open}
      title="Setear cabecera"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveSetHeader}
      confirmationModalMessage={"setear la cabecera"}
      disabledAcceptButton={validateAccept()}
      width="1300px"
    >
      <SimpleBackdrop
        open={isLoadingTableData}
        message={texts.flows.getFilterData.loading}
      />
      <Stack
        justifyContent={"space-between"}
        sx={{
          height: "480px",
        }}
      >
        <Stack direction={"row"} gap={8}>
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  p: 0,
                  m: 0,
                  color: "var(--blue)",
                }}
              >
                Índice de la cabecera
              </Typography>

              <TextField
                type="number"
                id="rangeToRows"
                label={""}
                placeholder={"Índice de la cabecera"}
                size="small"
                multiline
                variant="outlined"
                fullWidth
                value={inputValueRangeTo}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = /^[0-9\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setInputValueRangeTo(event.target.value);
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>

        <Typography sx={modal_typography_title_styles}>
          Se muestran las primeras y las últimas 500 filas del archivo
        </Typography>
        {open === false ? (
          <Box sx={{ width: "100%" }}>
            <CustomSkeleton height={5} />
          </Box>
        ) : (
          tableDataState && (
            <SimpleTable
              columns={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).rows
              }
              toolbar={false}
            />
          )
        )}
      </Stack>
    </BaseModal>
  );
};

export default SetHeader;
