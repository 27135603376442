import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
} from "notistack";
import { Messages } from "../hooks/useApi";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { es } from "date-fns/locale"; // Importa el locale de español

/**
 * Formatea una fecha en formato ISO 8601 en el formato deseado.
 * @param {string} rawDate - La fecha en formato ISO 8601 (por ejemplo, "2024-04-30T19:35:57Z").
 * @returns {string} La fecha formateada en el formato "dd 'de' MMMM 'de' yyyy 'a las' HH:mm".
 */
export const formatDate = (rawDate: string) => {
  // Parsea la fecha en formato ISO 8601
  const date = new Date(rawDate);

  // Utiliza la función format para formatear la fecha, pasando el locale de español
  const formatted = format(date, "dd 'de' MMMM 'de' yyyy 'a las' HH:mm", {
    locale: es,
  });

  return formatted;
};

/**
 * Abrevia un número grande en su forma legible.
 * @param {number} num - El número que se va a abreviar.
 * @returns {string} El número abreviado con su respectivo sufijo de escala (K, M, B).
 */
function abbreviateNumber(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
}

/**
 * Convierte el valor numérico proporcionado en el sistema de moneda internacional con la cantidad de dígitos especificada.
 * @param {number} num - El valor numérico que se va a convertir.
 * @param {number} digits - La cantidad de dígitos decimales a mostrar.
 * @returns {string} El valor numérico convertido al sistema de moneda internacional.
 */
export const convertUnit = (unit: string, value: number) => {
  switch (unit) {
    case "moneda":
      const amount = convertToInternationalCurrencySystem(value, 2);
      return amount.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    case "porcentaje":
      return value.toLocaleString("es-ES", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    case "unidades":
      return abbreviateNumber(value);
    default:
      return value;
  }
};

export const convertToInternationalCurrencySystem = (
  num: number,
  digits: number
) => {
  const lookup = [
    { value: 1e3, symbol: " K" },
    { value: 1e6, symbol: " M" },
    { value: 1e9, symbol: " G" },
    { value: 1e12, symbol: " T" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? "$" + (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : num;
};

/**
 * Formatea las opciones de selección proporcionadas en el formato requerido.
 * @param {any[]} options - Las opciones de selección que se van a formatear.
 * @returns {any[]} Las opciones de selección formateadas en el formato requerido.
 */
export const formatOptions = (options: any[] | undefined) => {
  return options
    ? options.map((option: any) => {
        return {
          value: option.id,
          label: option.nombre,
        };
      })
    : [];
};

/**
 * Obtiene los mensajes para códigos de estado HTTP específicos.
 * @param {object} obj - Objeto que contiene los mensajes para códigos de estado HTTP.
 * @returns {Messages} Objeto que contiene los mensajes para códigos de estado HTTP.
 */
export function getMessages(obj: { [key: number]: string }): Messages {
  // Creamos un objeto del tipo Messages con todas las claves y valores vacíos
  const messages: Messages = {
    200: "",
    201: "",
    204: "",
    400: "",
    401: "",
    404: "",
    500: "",
  };

  // Recorremos el objeto que se ha pasado como parámetro
  for (const key in obj) {
    // Si la clave existe en el objeto messages, asignamos el valor correspondiente
    if (key in messages) {
      messages[key] = obj[key];
    }
  }

  // Devolvemos el objeto messages completo
  return messages;
}

/**
 * Convierte un array de elementos en un texto concatenado.
 * @param {string[] | number[]} array - El array de elementos que se va a convertir en texto.
 * @returns {string} El texto concatenado de los elementos del array.
 */
export const convertArrayToText = (array: string[] | number[]) => {
  let text = "";
  array.forEach((item, index) => {
    text += item + (index !== array.length - 1 ? ", " : "");
  });
  return text;
};

export const fileFormatOptions = [
  {
    value: "csv",
    label: "csv",
  },
  {
    value: "xlsx",
    label: "xlsx",
  },
  {
    value: "parquet",
    label: "parquet",
  },
];

export const fileIngestionFormatOptions = [
  {
    value: "csv",
    label: "csv",
  },
  {
    value: "txt",
    label: "txt",
  },
  {
    value: "parquet",
    label: "parquet",
  },
];

// AÑOS : 2020 A 2030
export const yearOptions = [
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2024",
    label: "2024",
  },
  {
    value: "2025",
    label: "2025",
  },
  {
    value: "2026",
    label: "2026",
  },
  {
    value: "2027",
    label: "2027",
  },
  {
    value: "2028",
    label: "2028",
  },
  {
    value: "2029",
    label: "2029",
  },
  {
    value: "2030",
    label: "2030",
  }
];

export const monthOptions = [
  {
    value: "01",
    label: "01",
  },
  {
    value: "02",
    label: "02",
  },
  {
    value: "03",
    label: "03",
  },
  {
    value: "04",
    label: "04",
  },
  {
    value: "05",
    label: "05",
  },
  {
    value: "06",
    label: "06",
  },
  {
    value: "07",
    label: "07",
  },
  {
    value: "08",
    label: "08",
  },
  {
    value: "09",
    label: "09",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
];

export const FiltersTabOperatorOptions = [
  { value: "ES_IGUAL_A", label: "es igual a" },
  { value: "NO_ES_IGUAL_A", label: "no es igual a" },
  { value: "CONTIENE", label: "contiene" },
  { value: "NO_CONTIENE", label: "no contiene" },
  { value: "ES_MAYOR_QUE", label: "es mayor que" },
  { value: "ES_MAYOR_O_IGUAL_QUE", label: "es mayor o igual que " },
  { value: "ES_MENOR_QUE", label: "es menor que" },
  { value: "ES_MENOR_O_IGUAL_QUE", label: "es menor o igual que " },
];

export const functionOptions = [
  {value:"MONTH", label:"MONTH"},
  {value:"YEAR", label:"YEAR"},
  {value:"DAY", label:"DAY"},
  {value:"DATE", label:"DATE"},
  {value:"HOUR", label:"HOUR"},
  {value:"MINUTE", label:"MINUTE"},
  {value:"SECOND", label:"SECOND"},
  {value:"UPPER", label:"UPPER"},
  {value:"LOWER", label:"LOWER"},
];


export const orderTypeOptions = [
  { value: "Ascendente", label: "Ascendente" },
  { value: "Descendente", label: "Descendente" },
];

export const handleClickVariant = (
  message: string,
  variant: VariantType,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  closeSnackbar: (key?: SnackbarKey | undefined) => void
) => {
  enqueueSnackbar(message, {
    variant,
    action: (key) => (
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
    ),
  });
};

/**
 * Filtra los datos por el nombre del recurso.
 * @param {any} data - Los datos que se van a filtrar.
 * @param {string} value - El valor por el cual se va a filtrar.
 * @param {(value: any) => void} setFilteredData - Función para establecer los datos filtrados.
 */
export const filterDataByResourceName = (
  data: any,
  value: string,
  setFilteredData: (value: any) => void
) => {
  const foundItems: any[] = [];
  for (const item of data) {
    if (item.nombre.toLowerCase().includes(value.toLowerCase())) {
      foundItems.push(item);
    }
  }
  setFilteredData(foundItems);
};

/**
 * Filtra los datos por el valor proporcionado.
 * @param {any} data - Los datos que se van a filtrar.
 * @param {string | undefined} value - El valor por el cual se va a filtrar.
 * @param {(value: any) => void} setFilteredData - Función para establecer los datos filtrados.
 */
export const filterData = (
  data: any,
  value: string | undefined,
  setFilteredData: (value: any) => void
) => {
  const foundItems: any[] = [];
  function searchInItems(currentItems: any[]) {
    for (const item of currentItems) {
      if (item.nombre === value) {
        foundItems.push(item);
      }
      if (item.items.length > 0) {
        searchInItems(item.items);
      }
    }
  }
  if (value === "" || value === undefined) {
    setFilteredData(data);
  } else {
    searchInItems(data);
    setFilteredData(foundItems);
  }
};

/**
 * Busca un elemento por su ubicación y nombre dentro de una estructura de datos jerárquica.
 * @param {any} data - Los datos en los cuales se va a buscar el elemento.
 * @param {string} location - La ubicación del elemento a buscar.
 * @param {string} name - El nombre del elemento a buscar.
 * @returns {any} El elemento encontrado, si existe; de lo contrario, null.
 */
export const findItemByLocationAndName = (
  data: any,
  location: string,
  name: string
): any => {
  for (const item of data) {
    if (item.ubicacion === location && item.nombre === name) {
      return item;
    }
    if (item.items.length > 0) {
      const foundItem = findItemByLocationAndName(item.items, location, name);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return null;
};

/**
 * Elimina la extensión de un nombre de archivo.
 * @param {string} fileName - El nombre del archivo del cual se va a eliminar la extensión.
 * @returns {string} El nombre del archivo sin la extensión.
 */
export const cutFileExtension = (fileName: string) => {
  const pointIndex = fileName.lastIndexOf(".");
  if (pointIndex !== -1) {
    return fileName.substring(0, pointIndex);
  } else {
    return fileName;
  }
};

/** *
 *  Obtiene la extensión de un nombre de archivo.
* @param {string} fileName - El nombre del archivo del cual se va a obtener la extensión.
* @returns {string} La extensión del archivo, si existe; de lo contrario, una cadena vacía.
*/
export const getFileExtension = (fileName: string) => {
  const pointIndex = fileName?.lastIndexOf(".");
  if (pointIndex !== -1) {
    return fileName?.substring(pointIndex);
  } else {
    return "";
  }
};

/**
 * Formatea la info de donde están siendo utilizados los recursos.
 * @param {Object} data - Los lugares donde estan siendo utilizados los recursos obtenidos del backend.
 * @returns {string} Una cadena formateada que contiene los nombres de los recursos agrupados por tipo, con saltos de línea entre cada tipo.
 */
export const formatResourceUsesData = (data: any) => {
  const result = [];
  for (const key in data) {
      if (data.hasOwnProperty(key)) {
          const value = data[key];
          const formattedName = value.nombre.length > 1 ? key + "s" : key;
          const names = value.nombre.join(", ");
          result.push(`${formattedName.charAt(0).toUpperCase() + formattedName.slice(1)}: ${names}`);
      }
  }
  return result.join(". \n");
}

/** 
 *  Obtiene el nombre de archivo permitido segun el nombre que viene en la configuracion del TEMPLATE-PHI.
 * @param {string} name - El nombre con la fecha por ejemplo: "VENTAS-01-2020"
 * @returns {string} El nombre sin la fecha por ejemplo "VENTAS".
 */
export const formatTemplatePhiName = (name: string) => {
  // Encuentra el índice del primer guion que sigue a una secuencia de dígitos
  const parts = name.split('-');
  
  // La parte antes de la fecha es todo lo que está antes del penúltimo segmento
      return parts.slice(0, -2).join('-');
  
  
}

/** 
 *  Obtiene el mes segun el nombre que viene en la configuracion del TEMPLATE-PHI.
 * @param {string} name - El nombre con la fecha por ejemplo: "VENTAS-01-2020"
 * @returns {string} El mes de dos digitos por ejemplo "01".
 */
export const getMonthFromTemplatePhiName = (name: string) => {
  const parts = name.split('-');
  
      return parts[parts.length -2]
}

/** 
 *  Obtiene el año segun el nombre que viene en la configuracion del TEMPLATE-PHI.
 * @param {string} name - El nombre con la fecha por ejemplo: "VENTAS-01-2020"
 * @returns {string} El año de 4 digitos por ejemplo "2020".
 */
export const getYearFromTemplatePhiName = (name: string) => {
  // Encuentra el índice del primer guion que sigue a una secuencia de dígitos
  const parts = name.split('-');
  
  // La parte antes de la fecha es todo lo que está antes del penúltimo segmento
      return parts[parts.length -1]
}

/** 
 *  Ordena la data según el nombre de cada recurso de forma alfabeticamente ascendente.
 * @param {data} any - El nombre con la fecha por ejemplo: "VENTAS-01-2020"
 * @returns {any} La data ordenada de forma alfabeticamente segun el nombre de cada recurso.
 */
export const orderResourcesByName = ( data: any) => {
  if (data?.length) {
    const resourcesCopy = [...data];

    // Ordena los recursos por el atributo "nombre"
    resourcesCopy.sort((a: any, b: any) => {
      const nameA = a.nombre.toUpperCase();
      const nameB = b.nombre.toUpperCase();

        return nameA.localeCompare(nameB);
      
    });
    return resourcesCopy
  }
};

/**
 * Retorna true si el recurso está dentro de los recursos seleccionados.
 * @param {selectedFiles} any[] - Los recursos seleccionados (objetos)
 * @param {resource} any - El recurso por el cual queremos consultar si está seleccionado
 * @returns {boolean} Retorna true o false.
 */
export const isResourceSelected = (selectedFiles: any[], resource: any): boolean => {
  // Crear un Set con los nombres de los archivos seleccionados
  const selectedFileNames = new Set(selectedFiles.map((file: any) => file.name));
  
  // Verificar si el nombre del recurso está en el Set
  return selectedFileNames.has(resource.nombre);
}

/**
 * Retorna true si la consulta SQL cuenta con el formar adecuado (no incluir palabras reservadas que puedan alterar la integridad de la db).
 * @param {query} string- La consulta SQL
 * @returns {boolean} Retorna true o false.
 */
export const validateSQLQuery = (query: string) => {
  const regexString = `^(?=.*SELECT.*FROM)(?!.*(?:CREATE|DROP|UPDATE|TRUNCATE|CONSTRAINT|TRIM|INSERT|ALTER|DELETE|ATTACH|DETACH|INTO|SET|GRANT)).*$`;
  const regex = new RegExp(regexString, "i");

  const cleanedQuery = query.replace(/[\r\n\t]+/g, " ");

  return regex.test(cleanedQuery);
};

/**
 * Retorna el operador utilizado en el formato que necesita el backend segun un string dado
 * @param {operator} string- El string del operador
 * @returns {string} Retorna el string en el formato que recibe el backend.
 */
export const getOperator = (operator: string) => {
  switch (operator) {
    case "es igual a":
      return "=";
    case "no es igual a":
      return "!=";
    case "contiene":
      return "LIKE";
    case "no contiene":
      return "NOT LIKE";
    case "es mayor que":
      return ">";
    case "es mayor o igual que ":
      return ">=";
    case "es menor que ":
      return "<";
    case "es menor o igual que ":
      return "<=";
    default:
      return "";
  }
};

/**
 * Retorna el operador utilizado en el formato que necesita el componente filters
 * @param {operator} string- El string del operador como lo manda el backend
 * @returns {object} Retorna el objeto en el formato que necesita el componente filter
 */
export const getOperatorLabel = (operator: string) => {
  switch (operator) {
    case "=":
      return {value: "ES_IGUAL_A", label: "es igual a"};
    case "!=":
      return  { value: "NO_ES_IGUAL_A", label: "no es igual a" };
    case "LIKE":
      return  { value: "CONTIENE", label: "contiene" }
    case "NOT LIKE":
      return { value: "NO_CONTIENE", label: "no contiene" }
    case ">":
      return {Value: "ES_MAYOR_QUE", label: "es mayor que" }
    case ">=":
      return  { value: "ES_MAYOR_O_IGUAL_QUE", label: "es mayor o igual que " }
    case "<":
      return { value: "ES_MENOR_QUE", label: "es menor que" }
    case "<=":
      return   { value: "ES_MENOR_O_IGUAL_QUE", label: "es menor o igual que " }
    default:
      return "";
  }
};

/**
 * Función para obtener valores únicos de una propiedad en un array de objetos.
 * @param property - El nombre de la propiedad para extraer los valores únicos.
 * @param row - El array de objetos del que extraer los valores.
 * @returns Un array de valores únicos de la propiedad especificada.
 */
export const getUniqueValues = (property: string, row: any[]): string[] => {
  // Usa un Set para almacenar valores únicos
  const uniqueValues = new Set(row.map(item => item[property]));
  
  // Convierte el Set a un array y lo devuelve
  return Array.from(uniqueValues);
};

/**
 * Formatea la ubicacion para sacarle el prefijo "carpera raíz"
 * @param {location} string- El string de la location
 * @param {replaceString} string- El string a reemplazar
 * @returns {string} Retorna la location formateada sin el "carpeta raíz"
 */

export const formatLocation = (location: string, replaceString: string) => {
  return location.replace(replaceString, '')
}
