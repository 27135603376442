import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  ColorlibConnector,
  pin_buttons_container_styles,
  stepper_container_styles,
} from "../../../styles/app-styles";
import ColorlibStepIcon from "./ColorlibStepIcon";
import { labels } from "./labels";
import { FlowItem, StepperType } from "../../../context/types";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import {
  Fade,
  Popper,
  Typography,
  ClickAwayListener,
  Button,
  TextField,
  Box,
} from "@mui/material";
import PinButton from "./PinButton";
import { useContext, useState } from "react";
import FlowsProvider from "../../../context/FlowsContext";
import React from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import useDialog from "../../../hooks/useDialog";
import useApi from "../../../hooks/useApi";
import {
  ITEM_URL,
  DELETE_STEPPER_URL,
  GET_FLOW_URL,
  STEPPER_URL,
  DUPLICATE_STEPPER_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import ViewModal from "./ViewModal";
import {
  findBeforeElementPosition,
  getItemById,
  handleSetItemBarStatus,
  isStepNameRepeated,
  stepperHasAnyItemWithError,
} from "../utils";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CustomizedDialogs from "../../dialog/Dialog";
import ViewResultsTableAccordion from "./ViewResultsTableAccordion";
import NotAllowedToDeleteModal from "./NotAllowedToDeleteModal";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SaveNameModal from "../../dialog/SaveNameModal";

interface FlowStepperProps {
  stepper: StepperType;
  activeStepper: number;
}

const FlowStepper = ({ stepper, activeStepper }: FlowStepperProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { itemsBarStatus, data_flow, active_stepper, newDuplicateStepper } =
    flowState;
  const stepItems = stepper.items.map((item: FlowItem) => item);
  const isActive = activeStepper === stepper.id;
  const [openConfirmDeleteItem, toggleConfirmDeleteItem] = useDialog();
  const [openConfirmDeleteStepper, toggleConfirmDeleteStepper] = useDialog();
  const [openNotAllowedToDeleteModal, toggleNotAllowedToDeleteModal] =
    useDialog();
  const [itemToDelete, setItemToDelete] = useState<any>(undefined);
  const [openViewModal, handleToggleViewModal] = useDialog();
  const [openDuplicateStepperModal, toggleDuplicateStepperModal] = useDialog();
  const [itemToPreview, setItemIdToPreview] = useState<any>(undefined);
  const [openErrorModal, handleErrorModal] = useDialog();
  const [openInputChangeName, setOpenInputChangeName] =
    useState<boolean>(false);
  const [stepperName, setStepperName] = useState<string>("");

  const previewModal = (item: FlowItem) => {
    handleToggleViewModal();
    setItemIdToPreview(item.id);
  };

  const editStepper = () => {
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: stepper.id,
    });
    const activeStepperInfo = data_flow?.steppers?.find(
      (step: any) => step.id === stepper.id
    );
    if (activeStepperInfo) {
      handleSetItemBarStatus(
        flowDispatch,
        data_flow,
        active_stepper,
        itemsBarStatus
      );
    }
  };

  const [popperState, setPopperState] = React.useState({
    anchorEl: [],
    open: [],
    placement: [],
  });

  const handleClickPopper =
    (placement: string, id: string) => (event: React.MouseEvent<any>) => {
      setPopperState((prev: any) => ({
        anchorEl: {
          ...prev.anchorEl,
          [id]: event.currentTarget,
        },
        open: {
          ...prev.open,
          [id]: !prev.open[id],
        },
        placement: {
          ...prev.placement,
          [id]: placement,
        },
      }));
    };

  const handleClickClosePopper = (id: string) => {
    setPopperState((prev: any) => ({
      anchorEl: [],
      open: {
        ...prev.open,
        [id]: false,
      },
      placement: [],
    }));
  };

  const onSuccessGetFlow = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: data,
    });
    handleSetItemBarStatus(flowDispatch, data, active_stepper, itemsBarStatus);
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  const onSuccessGetStepper = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: {...data_flow, steppers: data_flow.steppers.map((s: any)=>{return s.id === data.id ? data : s})},
    });
    handleSetItemBarStatus(flowDispatch, {...data_flow, steppers: data_flow.steppers.map((s: any)=>{return s.id === data.id ? data : s})}, active_stepper, itemsBarStatus);
  };

  const { isLoading: isLoadingGetStepper, callApi: getStepper, data: dataStepper } =
  useApi(
    STEPPER_URL(data_flow?.id, active_stepper),
    "GET",
    texts.flows.putStepper.codes,
    undefined,
    onSuccessGetStepper,
    undefined,
    false
  );

  const onSuccessPutStepperName = (data: any) => {
    getStepper(STEPPER_URL(data_flow?.id, active_stepper)).then(() => {
      setOpenInputChangeName(false);
      setStepperName("");
    });
  };

  const { isLoading: isLoadingPutNewFlowName, callApi: putNewFlowName } =
    useApi(
      STEPPER_URL(data_flow?.id, activeStepper),
      "PUT",
      texts.flows.putStepper.codes,
      { nombre: stepperName },
      onSuccessPutStepperName
    );

  const onSuccessDeleteItem = () => {
    if (
      itemToDelete?.tipo === "nueva_fuente" ||
      itemToDelete?.tipo === "merge" ||
      itemToDelete?.tipo === "concat"
    ) {
      deleteStepper();
    }
    setItemToDelete(undefined);
    getStepper(STEPPER_URL(data_flow?.id, active_stepper))
  };

  const onSuccessDeleteStepper = () => {
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: "",
    });
    if (data_flow.id) {
      setTimeout(() => {
        getFlow(GET_FLOW_URL(parseInt(data_flow?.id)));
      }, 500);
    }
  };

  const onSuccessDuplicateStepper = (data: any) => {
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: "",
    });
    flowDispatch({
      type: "SET_NEW_DUPLICATE_STEPPER_NAME",
      payload:"",
    });
    if (data_flow.id && !data.mensaje && data.stepper_id) {
      setTimeout(() => {
        getFlow(GET_FLOW_URL(parseInt(data_flow?.id)));
      }, 500);
    }
  };

  const { isLoading: isLoadingDeleteItem, callApi: deleteItem } = useApi(
    ITEM_URL(data_flow?.id, activeStepper, itemToDelete?.id),
    "DELETE",
    texts.flows.deleteItem.codes,
    undefined,
    onSuccessDeleteItem,
    undefined,
    true
  );

  const { isLoading: isLoadingDeleteStepper, callApi: deleteStepper } = useApi(
    DELETE_STEPPER_URL(data_flow?.id, stepper.id),
    "DELETE",
    texts.flows.deleteStepper.codes,
    undefined,
    onSuccessDeleteStepper,
    undefined,
    true
  );

  const handleConfirmDeleteItem = (item: any) => {
    toggleConfirmDeleteItem();
    setItemToDelete(item);
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: stepper.id,
    });
  };

  const {
    isLoading: isLoadingDuplicateStepper,
    callApi: callDuplicateStepper,
  } = useApi(
    DUPLICATE_STEPPER_URL(data_flow?.id, stepper.id as number),
    "POST",
    texts.flows.duplicateStepper.codes,
    { nombre: newDuplicateStepper },
    onSuccessDuplicateStepper,
    undefined,
    false
  );

  const handleDeleteItem = () => {
    if (itemToDelete) {
      const activeStepperInfo = data_flow?.steppers?.find(
        (step: any) => step.id === active_stepper
      );
      if (activeStepperInfo.items.length === 1) {
        deleteStepper();
      } else {
        deleteItem();
      }
      toggleConfirmDeleteItem();
    }
  };

  const handleDeleteStepper = () => {
    deleteStepper();
    toggleConfirmDeleteStepper();
  };

  const handleEditItem = (item: FlowItem) => {
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: item,
    });
  };

  const handleChangeInputFlowName = () => {
    setOpenInputChangeName(!openInputChangeName);
    setStepperName("");
  };

  const duplicateStepper = () => {
    callDuplicateStepper();
    toggleDuplicateStepperModal();
  };

  const handleCloseDuplicateStepperModal = () => {
    toggleDuplicateStepperModal();
    flowDispatch({
      type: "SET_NEW_DUPLICATE_STEPPER_NAME",
      payload:"",
    });
  }

  return (
    <Stack sx={stepper_container_styles(isActive)}>
      <SimpleBackdrop
        open={isLoadingDeleteItem}
        message={texts.flows.deleteItem.loading}
      />
      <SimpleBackdrop
        open={isLoadingDeleteStepper}
        message={texts.flows.deleteStepper.loading}
      />
       <SimpleBackdrop
        open={isLoadingDuplicateStepper}
        message={texts.flows.duplicateStepper.loading}
      />
      <SimpleBackdrop
        open={isLoadingFlow}
        message={texts.flows.getFlow.loading}
      />
      <SimpleBackdrop
        open={isLoadingPutNewFlowName || isLoadingGetStepper}
        message={texts.flows.putStepper.loading}
      />
      <Stack
        sx={{
          width: "100%",
        }}
      >
        {openInputChangeName ? (
          <Stack
            sx={{
              width: "100%",
              color: "var(--blue)",
              minHeight: "45px",
              borderRadius: "10px 10px 0px 0px",
              gap: 2,
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "var(--blue-greeny)",
            }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              error={isStepNameRepeated(stepperName, data_flow)}
              helperText={
                isStepNameRepeated(stepperName, data_flow) &&
                "Ya existe un stepper con este nombre"
              }
              sx={{
                width: "40%",
                marginLeft: "5px",
                backgroundColor: "white",
                borderRadius: "5px",
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  height: "0.1rem",
                },
              }}
              value={stepperName}
              onChange={(e) => setStepperName(e.target.value)}
              placeholder={"Nombre del stepper"}
              autoComplete="off"
              InputLabelProps={{
                style: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  color: "white !important",
                  lineHeight: 1.5,
                  margin: 0,
                  backgroundColor: "var(--blue-greeny)",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 1,
                mt: "4px",
                alignSelf: isStepNameRepeated(stepperName, data_flow)
                  ? "flex-start"
                  : "center",
              }}
            >
              <PinButton
                Icon={CheckCircleRoundedIcon}
                tooltipTitle={"Confirmar"}
                size={"30px"}
                onClick={
                  isStepNameRepeated(stepperName, data_flow) ||
                  stepperName.length < 1
                    ? () => <></>
                    : () => putNewFlowName()
                }
                tooltipPlacement={"bottom"}
              />
              <PinButton
                Icon={CancelRoundedIcon}
                tooltipTitle={"Cancelar"}
                size={"30px"}
                onClick={handleChangeInputFlowName}
                backgroundColor="var(--light-magenta)"
                tooltipPlacement={"bottom"}
              />
            </Box>
          </Stack>
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              backgroundColor: isActive
                ? "var(--blue-greeny)"
                : "var(--very-light-blue)",
              borderRadius: "10px 10px 0px 0px",
              p: 1,
              color: "white",
              height: "45px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {stepper.nombre}
            {isActive && (
              <Tooltip
                title={"Editar nombre"}
                placement={"right"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                <EditIcon
                  onClick={() => {
                    setOpenInputChangeName(!openInputChangeName);
                  }}
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    color: "white",
                    margin: "0px 0px 3px 5px",
                  }}
                />
              </Tooltip>
            )}
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          padding: "20px 16px 20px 16px",
          width: "100%",
        }}
      >
        <Stack sx={pin_buttons_container_styles}>
          <PinButton
            Icon={EditIcon}
            tooltipTitle={"Editar"}
            onClick={editStepper}
            backgroundColor={"var(--blue-greeny)"}
          />
            <PinButton
            Icon={ControlPointDuplicateIcon}
            tooltipTitle={stepperHasAnyItemWithError(stepItems) ? "No es posible duplicar el stepper porque posee errores":"Duplicar"}
            onClick={ !stepperHasAnyItemWithError(stepItems) ? toggleDuplicateStepperModal : () => <></>}
            backgroundColor={!stepperHasAnyItemWithError(stepItems) ? "var(--blue-greeny)" : undefined}
          />
        
          <PinButton
            Icon={DeleteForeverIcon}
            tooltipTitle={"Eliminar"}
            onClick={
              isActive
                ? stepper.permite_eliminar
                  ? toggleConfirmDeleteStepper
                  : toggleNotAllowedToDeleteModal
                : () => <></>
            }
            backgroundColor={isActive ? "var(--blue-greeny)" : undefined}
          />
        </Stack>
        <Stack
          sx={{
            width: "100%",
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={stepItems.length}
            connector={<ColorlibConnector />}
            sx={{
              width:
                stepItems.length < 10 ? stepItems.length * 11 + "%" : "98%",
              overflowX: "auto",
            }}
          >
            {stepItems.map((item: FlowItem) => (
              <Step key={item.id}>
                <ClickAwayListener
                  onClickAway={() =>
                    popperState.open[item.id] === true &&
                    handleClickClosePopper(String(item.id))
                  }
                >
                  <StepLabel
                    StepIconComponent={() => (
                      <ColorlibStepIcon
                        icon={item.tipo}
                        active={true}
                        isStepperActive={isActive}
                        completed={item.posee_error}
                      />
                    )}
                  >
                    <Button
                      disabled={!isActive}
                      sx={{
                        width: "128px",
                        p: 0,
                        pt: "2px",
                        pb: "2px",
                        fontSize:
                          labels[String(item.tipo)].length < 17
                            ? "11px"
                            : "10px",
                        fontWeight: "bold",
                        border: isActive
                          ? "0.5px solid var(--blue-greeny)"
                          : "1px solid #999999",
                        backgroundColor: isActive
                          ? "var(--blue-greeny)"
                          : "#f3f3f3",
                        boxShadow: isActive
                          ? "3px 3px 6px 2px rgba(153, 153, 153)"
                          : "-2px -2px -2px -2px rgba(153, 153, 153)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "var(--blue-greeny)",
                        },
                        "&:active": {
                          transform: "scale(0.95)",
                          transition: "0.3s all",
                        },
                      }}
                      onClick={handleClickPopper("top", String(item.id))}
                    >
                      {labels[String(item.tipo)]}
                    </Button>
                    <Popper
                      id={String(item.id)}
                      disablePortal
                      open={popperState.open[item.id] || false}
                      anchorEl={popperState.anchorEl[item.id] || null}
                      placement={popperState.placement[item.id] || "top"}
                      transition
                      sx={{
                        zIndex: "100",
                      }}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={500}>
                          <Stack direction="row" gap={0.5}>
                            {item?.posee_error &&
                            getItemById(
                              findBeforeElementPosition(stepItems, item.id),
                              stepItems
                            )?.posee_error ? (
                              <></>
                            ) : (
                              <PinButton
                                Icon={EditIcon}
                                tooltipTitle={"Editar"}
                                onClick={() => handleEditItem(item)}
                                tooltipPlacement={"bottom"}
                              />
                            )}
                            {!item.posee_error ? (
                              <PinButton
                                Icon={ZoomInIcon}
                                tooltipTitle={"Ver resultados"}
                                onClick={() => previewModal(item)}
                                tooltipPlacement={"bottom"}
                              />
                            ) : (
                              <PinButton
                                Icon={ReportProblemIcon}
                                tooltipTitle={"Ver error"}
                                onClick={() => handleErrorModal()}
                                tooltipPlacement={"bottom"}
                              />
                            )}
                            <CustomizedDialogs
                              open={openErrorModal}
                              handleClose={handleErrorModal}
                              title="Error"
                            >
                              <Typography
                                sx={{ minWidth: "500px", color: "var(--blue)" }}
                              >
                                {item.mensaje_error}
                              </Typography>
                            </CustomizedDialogs>
                            <PinButton
                              Icon={DeleteForeverIcon}
                              tooltipTitle={"Eliminar"}
                              onClick={() => handleConfirmDeleteItem(item)}
                              tooltipPlacement={"bottom"}
                            />
                          </Stack>
                        </Fade>
                      )}
                    </Popper>
                  </StepLabel>
                </ClickAwayListener>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Stack>
      {isActive && <ViewResultsTableAccordion />}
      <ConfirmationModal
        open={openConfirmDeleteItem}
        handleClose={toggleConfirmDeleteItem}
        handleAccept={handleDeleteItem}
        title="Eliminar item"
        message={texts.flows.deleteItem.confirmation}
        customMessage={true}
      />
      <ConfirmationModal
        open={openConfirmDeleteStepper}
        handleClose={toggleConfirmDeleteStepper}
        handleAccept={handleDeleteStepper}
        title="Eliminar step"
        message={texts.flows.deleteStepper.confirmation}
        customMessage={true}
      />
      <ViewModal
        open={openViewModal}
        handleClose={handleToggleViewModal}
        id={itemToPreview}
      />
      <NotAllowedToDeleteModal
        open={openNotAllowedToDeleteModal}
        handleClose={toggleNotAllowedToDeleteModal}
      />
      {data_flow?.steppers?.length > 0 && (
        <SaveNameModal
          open={openDuplicateStepperModal}
          handleClose={handleCloseDuplicateStepperModal}
          handleAccept={duplicateStepper}
          title={"Nombre del nuevo stepper"}
          text={"¿Confirma que desea duplicar el stepper?"}
          label={"Ingrese el nombre del stepper"}
          placeholder={"Escribir nombre del stepper"}
          dispatchFunction={flowDispatch}
          dispatchType={"SET_NEW_DUPLICATE_STEPPER_NAME"}
          valueState={newDuplicateStepper}
          namesList={data_flow.steppers}
        />
      )}
    </Stack>
  );
};

export default FlowStepper;
