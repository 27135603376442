import axios from "../api/axios";
import useAuth from "./useAuth";
import { REFRESH_URL } from "../api/axios";
import { useNavigate } from "react-router-dom";

/**
 * @name useRefreshToken
 * @description Hook que devuelve una función que refresca el token de autenticación y lo guarda en el estado
 * @returns {function}
 * @category Hooks
 */

const useRefreshToken = () => {
  const { setAuth }: any = useAuth();
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      const response = await axios.post(
        REFRESH_URL,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );
      if (response.status !== 200) {
        return null;
      } else {
        setAuth((prev: any) => {
          return {
            ...prev,
            user: response.data.email,
            roles: [response.data.rol],
            access_token: response.data.access_token,
            empresa: response.data.empresa_asignada
          };
        });
        if (location.pathname.startsWith("/login")) {
          navigate("/consolidacion-archivos/administracion-pipelines");
        }
        return response.data.access_token;
      }
    } catch (error) {
      setAuth({});
    }
  };

  return refresh;
};

export default useRefreshToken;
