import React from "react";
import Select, { components, OptionProps } from "react-select";
import SquareIcon from "@mui/icons-material/Square";
import { Stack } from "@mui/material";

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
}

const Option = (props: OptionProps<ColourOption>) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems:"center",
        alignContent:"center"
      }}
    >
      <SquareIcon
        sx={{
          color: props.data.color,
          fontSize: "26px",
        }}
      />
      <components.Option {...props} />
    </Stack>
  );
};

export default Option;
