import Grow from "@mui/material/Grow";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function BasicGrid({
  data,
  Card,
  handleEdit,
  handleEditFile,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
  handleExport,
  refetchData
}: any) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3}>
        {data &&
          data.map((item: any, index: number) => (
            <Grid item xs={2.4} key={item.id}>
              <Grow in={true} {...{ timeout: 1000 + (index < 50 ? index : 50) * 500 }}>
                <Item
                  id="gridItem"
                  elevation={12}
                  sx={{
                    backgroundColor: "white",
                    color: "#172D40",
                    borderRadius: "20px",
                    padding: "10px",
                  }}
                >
                  <Card
                    item={item}
                    handleEdit={handleEdit}
                    handleEditFile={handleEditFile}
                    handleCopy={handleCopy}
                    handleInfo={handleInfo}
                    handleDelete={handleDelete}
                    showInfo={showInfo}
                    refetchData={refetchData}
                  />
                </Item>
              </Grow>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
