export const ptTranslations = {
  administratorPanel: {
    sendCode: {
      codes: {
        200: "O código foi enviado com sucesso",
        400: "Ocorreu um erro e não foi possível enviar o código",
      },
    },
    postCode: {
      codes: {
        200: "A senha foi atualizada com sucesso",
        400: "O código está incorreto",
      },
    },
    companies: {
      createCompany: {
        codes: {
          200: "A empresa foi criada com sucesso",
          201: "A empresa foi criada com sucesso",
          400: "Ocorreu um erro e não foi possível criar a empresa",
        },
      },
      editCompany: {
        codes: {
          200: "A empresa foi editada com sucesso",
          400: "Ocorreu um erro e não foi possível editar a empresa",
        },
      },
      loadingCompanies: "Carregando empresas...",
      searchCompanyPlaceholder: "Pesquisa pelo nome da empresa",
      activeCompanies: "Empresas ativas",
      activeCompany: "Empresa ativa",
      inactiveCompanies: "Empresas inativas",
      noActiveCompanies: "Não há empresas ativas",
      noInactiveCompanies: "Não há empresas inativas",
      switchLabelActive: "Ativo",
      switchLabelInactive: "Inativo",
      duplicatedCompanyName: "Já existe uma empresa com esse nome",
      companyNameFormat:
        "O nome só pode conter números, letras e sublinhados (_)",
      newCompany: "Cadastro de nova empresa",
      editCompanyLabel: "Editar empresa",
      createCompanyLabel: "Criar empresa",
      creatingCompany: "Criando empresa...",
      updatingCompany: "Atualizando empresa...",
      copyingCompany: "Copiando empresa...",
      companyInformation: "Informações da empresa",
      name: "Nome",
      visualName: "Nome visual",
      editCompanyAction: "editar a empresa",
      createCompanyAction: "criar a empresa",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
    },
    users: {
      createUser: {
        codes: {
          200: "O usuário foi criado com sucesso",
          201: "O usuário foi criado com sucesso",
          400: "Ocorreu um erro e não foi possível criar o usuário",
        },
      },
      editUser: {
        codes: {
          200: "O usuário foi editado com sucesso",
          400: "Ocorreu um erro e não foi possível editar o usuário",
        },
      },
      editPassword: {
        codes: {
          200: "A senha foi atualizada com sucesso",
          400: "Ocorreu um erro e não foi possível atualizar a senha",
        },
      },
      incorrectFormat: "O formato não está correto",
      incorrectPasswordFormat: "O formato da senha não está correto",
      duplicatedEmail: "Já existe um usuário com este e-mail",
      newPassword: "Nova senha",
      repeatPassword: "Repetir senha",
      notMatchingPasswords: "As senhas não coincidem",
      passwordFormatLabel:
        "*A senha deve ter no mínimo 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula e pelo menos um número ou caractere especial.",
      authCode: "Código de autenticação",
      authCodeSentToEmail:
        "Enviamos um código de autenticação para o seu e-mail para validar sua identidade.",
      incorrectAuthCode: "O código de autenticação não está correto",
      rol: "Função",
      active: "Ativo",
      inactive: "Inativo",
      notPossibleToDisableUserLabel:
        "Não é possível desativar o seu próprio usuário",
      notPossibleToDisableUserError:
        "Não é possível desativar o seu próprio usuário. Somente outro usuário administrador pode realizar esta ação.",
      accept: "Aceitar",
      administrator: "Administrador",
      consultant: "Consultor",
      client: "Cliente",
      editUserLabel: "Editar usuário",
      newUserLabel: "Cadastrar novo usuário",
      userInformation: "Informações do usuário",
      optionalName: "Nome (opcional)",
      optionalLastName: "Sobrenome (opcional)",
      name: "Nome",
      lastName: "Sobrenome",
      email: "E-mail",
      generatePassword: "Gerar senha",
      companiesLabel: "Empresas",
      userEnabledCompanies: "Empresas habilitadas para o usuário",
      multipleOptions: "Escolha uma ou mais opções...",
      assignedCompany: "Empresa atribuída",
      chooseCompanyLabel: "Escolha a empresa selecionada...",
      enableUser: "Habilitar usuário",
      activeUser: "Usuário ativo",
      editPasswordLabel: "Editar senha",
      editUserAction: "editar o usuário",
      createUserAction: "cadastrar o usuário",
      createUserLabel: "Cadastrar usuário",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      errorLoadingUsers:
        "Ocorreu um erro ao carregar os usuários. Por favor, tente novamente mais tarde.",
      loadingUsers: "Carregando usuários...",
      searchUserLabel: "Pesquisa por nome ou e-mail do usuário",
      activeUsers: "Usuários ativos",
      inactiveUsers: "Usuários inativos",
      noActiveUsers: "Não há usuários ativos",
      noInactiveUsers: "Não há usuários inativos",
    },
    usersLabel: "USUÁRIOS",
    companiesLabel: "EMPRESAS",
    administratorPanel: "Painel de administrador",
  },
  appBarComponent: {
    adminDashboard: "Painel de administrador",
  },
  avatar: {
    logoutLoading: "Encerrando sessão...",
    logout: "Encerrar sessão",
  },
  basicGrid: {
    noLinesLabel: "Ainda não adicionou linhas",
    column: "Coluna",
    function: "Função",
  },
  buttons: {
    speedDialTooltipOpen: {
      edit: "Editar",
      duplicate: "Duplicar",
      seeMore: "Ver mais",
      delete: "Excluir",
    },
  },
  calculation: {
    getCompletedStages: {
      loading: "Obtendo etapas...",
      error: "Ocorreu um erro e não foi possível carregar as etapas.",
    },
  },
  charts: {
    allCharts: {
      groupsError:
        "Ocorreu um erro e não foi possível carregar os grupos. Por favor, tente novamente mais tarde.",
      chartsLabel: "Gráficos",
      periodsLabel: "Períodos",
      downloadingGroups: "Baixando grupos...",
      downloadingCharts: "Baixando gráficos...",
      groupsDownloadingError: "Ocorreu um erro ao baixar os grupos.",
      chartsDownloadingError: "Ocorreu um erro ao baixar os gráficos.",
      noActiveChartsError: "Ainda não existem gráficos criados ou ativos.",
    },
    barInfiniteDrilldownChart: {
      originalExpensePercent: "Percentual em relação à despesa original",
    },
  },
  chartsManagement: {
    addChart: {
      codes: {
        201: "O gráfico foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o gráfico",
      },
    },
    editChart: {
      codes: {
        200: "O gráfico foi atualizado com sucesso",
        400: "Ocorreu um erro e não foi possível editar o gráfico",
      },
    },
    deleteChart: {
      codes: {
        200: "O gráfico foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o gráfico",
      },
    },
    putState: {
      codes: {
        200: "O estado foi alterado com sucesso",
        201: "O estado foi alterado com sucesso",
        400: "Ocorreu um erro e não foi possível alterar o estado",
      },
    },
    getGroup: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
    },
  },
  codeVerification: {
    twoStepsVerification: "Verificação de 2 passos",
    completeWithTheCodeSentToEmail:
      "Por favor, complete com o código enviado para o seu e-mail. Não compartilhe este código com ninguém.",
    codeSentToEmail: "O código foi enviado para o seu endereço de e-mail.",
    cancel: "Cancelar",
    enter: "Inserir",
  },
  company: {
    getCompanyError: "Não foi possível carregar a empresa",
    getCompaniesError: "Não foi possível carregar as empresas",
    loadingCompanies: "Carregando empresas...",
    selectCompany: "Selecione a sua empresa",
    chooseCompany: "Escolher empresa",
    userCompanyError: "Não foi possível obter a empresa para este usuário",
    companiesError: "Não foi possível obter as empresas",
    changeCompanyError:
      "Ocorreu um erro ao trocar de empresa. Por favor, tente novamente.",
    closingSession: "Encerrando sessão...",
    cancel: "Cancelar",
    start: "Iniciar",
    codes: {
      200: "A empresa foi alterada com sucesso",
      400: "Ocorreu um erro e não foi possível alterar a empresa",
      404: "Ocorreu um erro e não foi possível alterar a empresa",
    },
  },
  columns: {
    action: "ação",
    apportionment_criteria: "critério_rateio",
    bucket: "bucket",
    cause_error: "causa_erro",
    column: "Coluna",
    criteria: "Critério",
    date: "data",
    destination_receiver: "receptor_destino",
    destination: "destino",
    expenses_transferred: "despesas_transferidas",
    file: "arquivo",
    iteration: "iteração",
    mount: "montante",
    period: "período",
    previous_destination: "destino_anterior",
    receiver_criteria: "receptor_critério",
    stage: "etapa",
    stages: "etapas",
    state: "estado",
    values: "Valores",
  },
  configCriteria: {
    addTargetModal: {
      repeatedTargetNameError: "Já existe um alvo com esse nome",
      emptyFieldsError: "Os campos não podem ficar vazios",
      succesCreatedTarget: "O receptor de despesa foi criado com sucesso",
      errorCreatedTarget:
        "Ocorreu um erro ao criar o receptor. Tente novamente.",
      loadingPostTarget: "Criando receptor de despesa...",
      newTarget: "Novo receptor",
      cancel: "Cancelar",
      accept: "Aceitar",
      name: "Nome",
      target: "Receptor de despesa",
      newTargetName: "Nome do novo receptor",
      targetPlaceholder: "Nome da coluna do arquivo (ex: COD_RUTA)",
      validatePreviousDestinity: "Validar destino anterior",
    },
    configCriteriaComponent: {
      criteriaAdmin: "Administração do critério",
      nonAppliedFilters:
        "Existem filtros não adicionados. Confirma que deseja criar o critério mesmo assim?",
      nonAppliedFilterWarning:
        "Existem filtros não adicionados. Deseja continuar mesmo assim?",
      previous: "Anterior",
      resetSteps: "Redefinir todas as etapas do critério",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
      editCriteria: "editar o critério",
      createCriteria: "criar o critério",
      editCriteriaTitle: "Editar critério",
      createCriteriaTitle: "Criar critério",
      noFilters: "Não há filtros",
      goBack: "Voltar",
      continue: "Continuar",
      incomingConditionOptions: ["e", "ou"],
      incomingFilterTypeOptionsCriteria: [
        "INCLUIR",
        "EXCLUIR",
        "MENOR A",
        "MENOR O IGUAL A",
        "IGUAL A",
        "MAYOR A",
        "MAYOR O IGUAL A",
        "DIFERENTE A",
      ],
      incomingFilterTypeOptions: ["INCLUIR", "EXCLUIR"],
      incomingValuesOptions: [
        { valor: "CROSSDOCKS" },
        { valor: "PLANTAS" },
        { valor: "T1" },
      ],
      steps: [
        "Parametrização inicial dos critérios",
        "Filtros do arquivo",
        "Validação do arquivo GASTOS com arquivo",
        "Filtros do arquivo com informações externas",
      ],
      filterMessages: {
        emptyError:
          "Todos os campos devem ser preenchidos para adicionar um filtro.",
        duplicatedError: "Já existem filtros com esses critérios de validação.",
        conditionTooltipInfo:
          "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
        duplicatedCriteriaNameError: "Já existe um critério com esse nome.",
      },
      tooltips: {
        conditionTooltip:
          "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
      },
    },
    filterRow: {
      filterWarning:
        "Só é possível aplicar uma das opções para todos os filtros. Modificar esta opção afetará todos os filtros selecionados.",
    },
    step1: {
      apportionment: "RATEIO",
      description: "Descrição",
      criteriaName: "Nome do critério",
      applyIn: "Aplicar em",
      bucket: "Bucket",
      all: "Todos",
      expenseTargets: "Receptores de despesas",
      calcParameterization: "Parametrização de cálculo",
      file: "Arquivo",
      selectFile: "Selecionar arquivo...",
      column: "Coluna",
      selectColumn: "Selecionar coluna...",
      function: "Função",
      selectFunction: "Selecionar função...",
      countFunction: "CONTAR",
      countUniqueFunction: "CONTAR.ÚNICO",
      sumUniqueFunction: "SUMA.UNICO",
      sumProductFunction: "SOMA.PRODUTO",
      sumProductFunctionLabel: "Soma de produtos",
      sumUniqueFunctionLabel: "Soma única",
      criteriaUniqueColumn: "Coluna de critério único",
      notModifyValues: "Não modificar valores negativos",
      convertNegativesToPositives: "Converter valores negativos em positivos",
      convertNegativesToZero: "Transformar valores negativos em zero",
      columnToMultiply: "Coluna a multiplicar",
      selectOptions: "Selecionar uma ou mais opções...",
      changeFileWarning:
        "Confirma que deseja editar o arquivo do critério? Esta ação redefinirá as etapas 2, 3 e 4 do critério, se forem utilizadas.",
      editFileLabel: "Editar arquivo do critério",
      targetExpenses: "Receptores de Despesas",
    },
    step2: {
      repeatedFilterError:
        "Já existe um filtro para esta coluna. Caso o adicione, o filtro anterior será substituído.",
      include: "INCLUIR",
      exclude: "EXCLUIR",
      previousFilter: "Filtros anteriores",
      in: " em ",
      condition: "Condição",
      filterType: "Tipo de filtro",
      type: "Tipo...",
      values: "Valores",
      value: "Valor",
      valuePlaceholder: "Insira um valor numérico",
      noFiltersWarning: "Ainda não foram adicionados filtros",
    },
    step3: {
      expensesColumn: "Coluna de despesas",
      noValidationsWarning: "Ainda não foram adicionadas validações",
    },
    step4: {
      crossoverValues: "Cruzamento de Valores",
      validationFile: "Arquivo de Validação",
      columnValidationFile: "Coluna do Arquivo de Validação",
      columnFile: "Coluna do Arquivo",
    },
    virtualSelect: {
      loading: "Carregando...",
      noAvailableOptions: "Não há opções disponíveis",
      selectValues: "Selecione um ou mais valores...",
    },
    getCriteriaBucket: {
      error: "Ocorreu um erro e não foi possível baixar os buckets do critério",
    },
    getCriteriaTarget: {
      error:
        "Ocorreu um erro e não foi possível baixar os receptores do critério.",
    },
    getCriteriaFileColumn: {
      error:
        "Ocorreu um erro e não foi possível baixar os arquivos do critério.",
    },
    getCriteriaFunctions: {
      error:
        "Ocorreu um erro e não foi possível baixar as funções do critério.",
    },
    getExpensesColumn: {
      error:
        "Ocorreu um erro e não foi possível baixar as despesas do critério.",
    },
    sendCriteria: {
      loading: "Criando critério...",
      codes: {
        200: "O critério foi criado com sucesso",
        201: "O critério foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o critério",
      },
    },
    putCriteria: {
      loading: "Modificando critério...",
      codes: {
        200: "O critério foi modificado com sucesso",
        201: "O critério foi modificado com sucesso",
        400: "Ocorreu um erro e não foi possível modificar o critério",
      },
    },
  },
  copyGenericText: "_cópia",
  criteriaManagement: {
    deleteCriteria: {
      loading: "Excluindo critério...",
      codes: {
        200: "O critério foi excluído com sucesso",
        201: "O critério foi excluído com sucesso",
        203: "O critério foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o critério",
      },
    },
    getCriteria: {
      loading: "Obtendo critérios...",
      error: "Ocorreu um erro e não foi possível baixar os critérios.",
    },
  },
  criteriaManagementTexts: {
    criteriaCard: {
      receivers: "Receptores",
      filters: "Filtros",
      validations: "Validações",
      inUseBuckets: "Em uso, associado aos buckets",
      inUseBucket: "Em uso, associado ao bucket",
      notUsed: "Não utilizado",
    },
    criteriaManagement: {
      lookingCriteria: "Procurando critérios...",
      deletingCriteria: "Excluindo critério...",
      criteriaManagement: "Administração de Critérios",
      sortAlphabet: "CLASSIFICAR POR ALFABETO",
      sortDate: "CLASSIFICAR POR DATA",
      filter: "Filtro",
      searchByCriteriaBucket: "Buscar por nome de critério ou bucket",
      noCriteriaAvailable: "Ainda não há critérios disponíveis.",
      notGetCriteria:
        "Não foi possível obter os critérios. Tente novamente mais tarde.",
      deleteCriteria: "excluir o critério",
      noActiveCriteria: "Critérios Inativos",
      activeCriteria: "Critérios Ativos",
      noFilters: "Sem filtros",
    },
    viewInfoModal: {
      criteriaInfo: "Informações gerais do critério",
      criteriaBase: "Base para critério:",
      criteriaColumn: "Coluna de critério:",
      aplicatedFunction: "Função aplicada:",
      convertNegativesToPositives: " Converter valores negativos em positivos:",
      convertNegativesToZero: "Transformar valores negativos em zero:",
      columnsToValidate: "Coluna a validar exclusivamente:",
      yes: "Sim",
      no: "Não",
      columnsToMultiply: "Colunas para multiplicar",
      filterInFile: "Filtros no arquivo",
      expensesCrossover: "Cruzamento de valores de GASTOS com",
      filterBy: "Filtragem de ",
      withInfo: "com informações de",
      previousFilters: "Filtros anteriores no arquivo",
      crossoverValues: "Cruzamento de valores de",
      with: "com",
      previousDestinitiesValidations: "Validação de destinos anteriores",
    },
  },
  dashboard: {
    duplicated: "Já existe um painel com este nome.",
    invalidLink: "O link deve ser um link válido do Power BI",
    noData: "Não há painéis para mostrar.",
    get: {
      loading: "Obtendo painéis...",
      error: "Ocorreu um erro ao obter os painéis, tente novamente.",
    },
    post: {
      codes: {
        200: "O painel foi adicionado com sucesso.",
        201: "O painel foi adicionado com sucesso.",
        400: "Ocorreu um erro ao adicionar o painel, tente novamente.",
        500: "Ocorreu um erro ao adicionar o painel, tente novamente.",
      },
    },
    delete: {
      success: "O painel foi excluído com sucesso.",
      error: "Ocorreu um erro ao excluir o painel, tente novamente.",
      codes: {
        200: "O painel foi excluído com sucesso.",
        400: "Ocorreu um erro ao excluir o painel, tente novamente.",
        500: "Ocorreu um erro ao excluir o painel, tente novamente.",
      },
    },
  },
  dashboardTexts: {
    dashboard: {
      addDashboard: "Editar Painel",
      addNewDashboard: "Adicionar novo Painel",
    },
    newDashboardModalContent: {
      deletingDashboard: "Excluindo painel...",
      name: "Nome:",
      dashboardName: "Nome do painel...",
      linkDashboard: "Link do painel...",
      deleteDashboardFromListing: "Excluir painel da listagem.",
      close: "Fechar",
      deleteDashboard: "Excluir Painel",
    },
  },
  dataManagement: {
    downloadFile: {
      loading: "Baixando arquivo...",
      codes: {
        200: "O arquivo foi baixado com sucesso",
        201: "O arquivo foi baixado com sucesso",
        400: "Ocorreu um erro e não foi possível baixar o arquivo",
      },
      empty: "Não há arquivos para baixar",
    },
    getRowsPreview: {
      loading: "Obtendo visualização...",
      codes: {
        200: "A visualização foi obtida com sucesso",
        400: "Ocorreu um erro e não foi possível obter a visualização",
      },
    },
    getColumnsFiles: {
      loading: "Obtendo colunas...",
      error: "Ocorreu um erro e não foi possível baixar as colunas.",
    },
    getRowsFiles: {
      loading: "Obtendo linhas...",
      error: "Ocorreu um erro e não foi possível baixar as linhas.",
    },
    getAllowedFileNames: {
      loading: "Obtendo nomes de arquivos permitidos...",
      error:
        "Ocorreu um erro e não foi possível obter os nomes de arquivos permitidos.",
    },
    getCompletedStages: {
      codes: {
        400: "Ocorreu um erro e não foi possível obter as etapas.",
      },
      error: "Ocorreu um erro e não foi possível obter as etapas.",
    },
    deleteFile: {
      codes: {
        200: "O arquivo foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o arquivo",
        500: "Ocorreu um erro e não foi possível excluir o arquivo",
      },
      loading: "Excluindo arquivo...",
    },
    deletePeriod: {
      codes: {
        200: "O período foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o período",
        500: "Ocorreu um erro e não foi possível excluir o período",
      },
    },
    uploadModal: {
      restartCalculation: "Reiniciar o cálculo",
    },
  },
  dataManagementTexts: {
    allowFilesModalContent: {
      fileNameExists: "Já existe um arquivo com este nome",
      nameRules:
        "O nome só pode conter letras, números, hífens e ter até 30 caracteres",
      nameFileDeleted: "O nome do arquivo foi excluído com sucesso.",
      errorAllowFileDeleting:
        "Ocorreu um erro ao excluir o arquivo permitido, tente novamente.",
      successAllowFileDeleting:
        "O nome do arquivo permitido foi adicionado com sucesso.",
      errorAddAllowFile:
        "Ocorreu um erro ao adicionar o arquivo permitido, tente novamente",
      addNewAllowFile: "Adicionar novo nome de arquivo permitido",
      fileName: "Nome do arquivo...",
      allowedFiles: "Arquivos permitidos",
      deleteAllowedFile: "Excluir arquivo permitido",
      close: "Fechar",
      delete: "excluir",
      offAllowdFileNames: "dos nomes de arquivos permitidos",
      deleteFile: "Excluir arquivo",
    },
    notAllowedToAddFileModal: {
      cannotAddName: "Não é possível adicionar o nome",
      notPossibleExpensesName:
        "Não é possível adicionar o nome PRE_GASTOS aos nomes de arquivos permitidos",
      accept: "Aceitar",
    },
    notAllowedToDeleteFileModal: {
      cannotAddName: "Não é possível adicionar o nome",
      notPossibleDelete_1: "Não é possível excluir ",
      notPossibleDelete_2:
        "dos nomes de arquivos permitidos, pois existem arquivos carregados com este nome. É necessário excluí-los primeiro",
      accept: "Aceitar",
    },
    dataManagement: {
      taskManager: "/gerenciador-de-tarefas",
      fileStillLoading:
        "Este arquivo ainda está sendo carregado, aguarde alguns instantes e tente novamente.",
      cannotGetPreView:
        "Não foi possível obter a pré-visualização deste arquivo",
      deletingPeriodFiles: "Excluindo arquivos do período...",
      downloadingFile: "Baixando arquivo...",
      dataManagement: "Gestão de Dados",
      errorCouldNotDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      uploadFiles: "Enviar Arquivos",
      allowedFiles: "Arquivos Permitidos",
      cannotGetAllowedFiles: "Não foi possível obter os arquivos permitidos",
      fileUpload: "Upload de Arquivos",
      close: "Fechar",
      deleteSelectedPeriod: "excluir o período selecionado",
      deletePeriod: "Excluir Período",
      confirmDeleteFile_1: "Confirma a exclusão do arquivo",
      confirmDeleteFile_2:
        "? Isso também excluirá as tarefas relacionadas a este arquivo.",
      deleteFile: "Excluir Arquivo",
      preview: "Pré-visualização",
      uploading: "Enviando",
      files: "arquivos",
      closingWindowStopUpload:
        "Se fechar a janela, interromperá o processo de upload e os arquivos que ainda não iniciaram o processo de upload serão perdidos. Deseja interromper o upload?",
      closeFileUpload: "Fechar Envio de Arquivos",
      fileDownload: "Download de Arquivos",
      goDownloads: "Ir para Downloads",
      downloadProccesStarted: "O processo de download foi iniciado",
      viewDownloadStatus: `Para ver o status do download e baixar o arquivo, clique no botão "Ir para Downloads". Você também pode visualizá-lo na opção Downloads do "Gerenciador de Tarefas".`,
    },
    linearDeterminate:{
      loading: "Carregando",
      completedLoading: "Carregamento concluído"
    },
    successUploadModalContent: {
      connectionError:
        "Houve um erro na conexão e não foi possível enviar o arquivo.",
      stopLoad:
        "Atualizar a página, fechar esta janela ou o navegador interromperá o upload e os arquivos que ainda não iniciaram o processo de upload serão perdidos.",
      charge: "enviar",
      reTry: "Tentar Novamente",
      cancel: "Cancelar",
      chargeAbort: "Envio Cancelado",
      errorFileNotLoaded: "Houve um erro e não foi possível carregar o arquivo",
      loading: "Carregando...",
      waiting: "Aguardando",
    },
    transformDataManagement: {
      downloadAllFilesFrom: "Baixar todos os arquivos de",
      preview: "Pré-visualizar",
      download: "Baixar",
      delete: "Excluir",
      validated: "Validado",
      notValidated: "Não Validado",
      notFound: "Não Encontrado",
      downloadAllFiles: "Baixar todos os arquivos",
      downloadAllPeriodFiles: "Baixar todos os arquivos do período",
      deletePeriod: "Excluir Período",
    },
    uploadModalContent: {
      fileNameFormat_1: "Os nomes dos arquivos devem seguir o formato",
      fileNameFormat_2: "NOME-MÊS-ANO.csv ou NOME-MÊS-ANO.parquet",
      fileNameFormat_3: "ex. VENDAS-02-2022.csv",
      allowedName: "Os nomes permitidos são:",
      cannotUploadNames:
        "Os nomes dos arquivos permitidos não foram carregados",
      dragSelectFiles: "Arraste os arquivos aqui ou clique para selecionar",
      filesToUpload: "Arquivos para enviar:",
      addAllowedFilesName: "Adicionar nome de arquivo permitido.",
      deleteFileFromListing: "Excluir arquivo da lista.",
      fileAlreadyExistsRestatedCalculation:
        " - Este arquivo já existe e substituirá o anterior. ",
      itWillBeRestarted: "O cálculo do período será reiniciado.",
      fileAlreadyIncluded: " - Este arquivo já está incluído na lista",
      fileNameNotAllowed: " - Nome de arquivo não permitido",
      cannotGetAllowedFiles: "Não foi possível obter os arquivos permitidos",
      cancel: "Cancelar",
      filledIllegalNames: "Existem arquivos com nome não permitido.",
      accept: "Aceitar",
    },
  },
  dataModel: {
    getMeasures: {
      error: "Ocorreu um erro e não foi possível obter as medidas",
      codes: {
        200: "As medidas foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as medidas",
        404: "Ocorreu um erro e não foi possível obter as medidas",
      },
    },
    getMeasure: {
      error: "Ocorreu um erro e não foi possível obter a medida",
      codes: {
        200: "A medida foi obtida",
        400: "Ocorreu um erro e não foi possível obter a medida",
        404: "Ocorreu um erro e não foi possível obter a medida",
      },
    },
    getGroups: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
      codes: {
        200: "Os grupos foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os grupos",
        404: "Ocorreu um erro e não foi possível obter os grupos",
      },
    },
    getGroup: {
      error: "Ocorreu um erro e não foi possível obter o grupo",
      codes: {
        200: "O grupo foi obtido",
        400: "Ocorreu um erro e não foi possível obter o grupo",
        404: "Ocorreu um erro e não foi possível obter o grupo",
      },
    },
    deleteGroup: {
      loading: "Excluindo grupo...",
      codes: {
        200: "O grupo foi excluído com sucesso",
        204: "O grupo foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o grupo",
      },
    },
    deleteMeasure: {
      loading: "Excluindo medida...",
      codes: {
        200: "A medida foi excluída com sucesso",
        400: "Ocorreu um erro e não foi possível excluir a medida",
      },
    },
    getAnalysis: {
      error: "Ocorreu um erro e não foi possível obter as análises",
      codes: {
        200: "As análises foram obtidas",
        400: "Ocorreu um erro e não foi possível obter as análises",
        404: "Ocorreu um erro e não foi possível obter as análises",
      },
    },
    postNewAnalysis: {
      error: "Ocorreu um erro e não foi ",
    },
    deleteAnalysis: {
      loading: "Criando análise...",
      codes: {
        200: "A análise foi criada com sucesso",
        201: "A análise foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a análise",
      },
    },
    postCalculate: {
      codes: {
        200: "O cálculo foi iniciado com sucesso",
        201: "O cálculo foi iniciado com sucesso",
        400: "Ocorreu um erro e não foi possível iniciar o cálculo",
      },
    },
    postNewMeasure: {
      loading: "Criando medida...",
      codes: {
        200: "A medida foi criada com sucesso",
        201: "A medida foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a medida",
      },
    },
    putMeasure: {
      loading: "Modificando medida...",
      codes: {
        200: "A medida foi modificada com sucesso",
        201: "A medida foi modificada com sucesso",
        400: "Ocorreu um erro e não foi possível modificar a medida",
      },
    },
    postNewGrouper: {
      loading: "Criando agrupação...",
      codes: {
        200: "A agrupação foi criada com sucesso",
        201: "A agrupação foi criada com sucesso",
        400: "Ocorreu um erro e não foi possível criar a agrupação",
      },
    },
    putGrouper: {
      loading: "Editando agrupação...",
      codes: {
        200: "A agrupação foi editada com sucesso",
        201: "A agrupação foi editada com sucesso",
        400: "Ocorreu um erro e não foi possível editar a agrupação",
      },
    },
    postDownloadAnalysis: {
      loading: "Baixando relatório...",
      codes: {
        200: "O download foi iniciado com sucesso",
        201: "O download foi iniciado com sucesso",
        400: "Ocorreu um erro e não foi possível iniciar o download da análise",
      },
    }
  },
  dataModelText: {
    analysisExecution: {
      analysisExecution: "Execução de análise",
      startingCalculation: "Iniciando cálculo...",
      anaylsis: "Análise",
      selectAnalysisToRun: "Selecionar análises para executar",
      periods: "Períodos",
      selectPeriods: "Selecionar períodos",
      runSelectedAnalysisPlural: "executar as análises selecionadas",
      runSelectedAnalysisSingular: "executar a análise selecionada",
      deleteData: "Apagar dados",
      runAnalysis: "Executar análise",
      noPeriods: "Não há períodos com buckets totalmente rateados.",
    },
    analysisManagement: {
      configAnalysis: {
        creatingAnalysis: "Criando análise...",
        editingAnalysis: "Editando análise...",
        analysisAlreadyCalculated:
          "Esta análise já foi calculada. Ao editá-la, a atualização de cálculo será removida.",
        analysisName: "Nome da análise",
        analysisAlreadyName: "Já existe uma medida com esse nome",
        groupers: "Agrupamentos:",
        chooseGrouper: "Escolher agrupamento...",
        measures: "Medidas:",
        cancel: "Cancelar",
        accept: "Aceitar",
        modifyAnalysis: "modificar a análise",
        createNewAnalysis: "criar uma nova análise",
        newAnalysis: "Nova análise",
      },
      tranferList: {
        measureDisabled:
          "A medida está desativada porque nem todas as medidas primárias necessárias foram selecionadas.",
      },
      analysisAdmin: {
        searchingAnalysis: "Procurando análises...",
        analysisAdmin: "Administração de análises",
        periods: "Períodos",
        searchByName: "Pesquisa por nome",
        noAnalysisYet: "Ainda não há análises disponíveis.",
        deleteAnalysisConfirmCalculate:
          "Tem certeza de que deseja excluir a análise? Essa ação também excluirá o cálculo associado.",
        deleteAnalysisConfirm: "Tem certeza de que deseja excluir a análise?",
        confirm: "Confirmar",
        writeDelete: "Escreva a palavra 'excluir'",
        delete: "excluir",
      },
      analysisCard: {
        grouper: "Agrupamento:",
        measures: "MEDIDAS:",
        calculated: "Calculado",
        noCalculated: "Não calculado",
      },
      viewInfoAnalysisModal: {
        grouper: "AGRUPAMENTO:",
        measures: "MEDIDAS:",
        analysisInfo: "Informações gerais da análise",
      },
    },
    downloadReport:{
      aggregationOperatorsLabels:{
        sum: "ADIÇÃO",
        average: "MÉDIA",
        maximum: "MÁXIMO",
        minimum: "MÍNIMO",
        count: "CONTAR",
        count_unique: "CONTAR ÚNICO",
        first: "PRIMEIRO",
        last: "DURAR",
        unique_values: "VALORES ÚNICOS",
      },
      year: "ANO",
      month: "MÊS",
      monthly: "MENSAL",
      quarterly: "TRIMESTRE",
      biannual: "SEM-ANNUAL",
      annual: "ANUAL",
      downloadReport: "Baixar relatório",
      loading: "Carregando...",
      referenceName: "Nome de referencia",
      notAnalysisExecuted: "Nenhuma análise foi executada ainda",
      analysis: "Análise",
      chooseAnalysisToDownload: "Selecione a análise para download...",
      filters: "Filtros",
      columns: "Colunas",
      chooseColumns: "Escolher colunas...",
      periodicity: "Periodicidade",
      choosePeriodicity: "Escolha a periodicidade...",
      measures: "Medidas",
      chooseMeasures: "Escolher medidas...",
      aggregationOperators: "Operadores de agregação",
      chooseAggregationOperators: "Escolha uma operadora...",
      deleteData: "Excluir dados",
      download: "Baixar",
      downloadAnalysisSelected: "baixar a análise selecionada"
    },
    groupManagement: {
      step1: {
        addBaseButton: {
          newBase: "Nova Base",
        },
        mergeModal: {
          firstOcurrence: "primeira-ocorrência",
          lastOcurrence: "última-ocorrência",
          dontDelete: "não-excluir",
          editMerge: "editar mesclagem",
          addMerge: "adicionar mesclagem",
          file: "Arquivo",
          selectFile: "Selecionar arquivo",
          alreadyExistBaseWithFile:
            "Já existe uma base com o arquivo selecionado",
          columns: "Colunas",
          columnsMergePreviousBase: "Colunas mescladas - Base anterior",
          chooseColumns: "Escolher colunas...",
          columnsMergeNewBase: "Colunas mescladas - Nova base",
          removeDuplicates: "Remover duplicados:",
          keepFirstOcurrence: "Manter primeira ocorrência",
          keepLastOcurrence: "Manter última ocorrência",
          doNotDelete: "Não excluir",
          notRecommended:
            "(Não recomendado) Não excluir duplicados pode resultar em valores duplicados.",
        },
        newBaseFile: {
          columnBeingUsed:
            "A coluna excluída está sendo usada em uma mesclagem, reconfigure as bases com erro para continuar",
          file: "Arquivo",
          chooseFile: "Escolher arquivo...",
          columns: "Colunas",
          chooseColumns: "Escolher colunas...",
          removeBase: "Remover base",
          confirmRemoveBase:
            "Tem certeza de que deseja remover a base? Essa ação também removerá as bases seguintes e você precisará reconfigurar a etapa 2 se alguma delas estiver sendo usada.",
          editBaseFile: "Editar arquivo da base",
          confirmEditFiles:
            "Tem certeza de que deseja editar o arquivo escolhido para a base? Essa ação redefinirá a etapa 2 e precisará ser reconfigurada.",
          editBaseColumns: "Editar colunas da base",
          confirmEditColumns:
            "Tem certeza de que deseja editar as colunas escolhidas? Essa ação redefinirá a etapa 2 e precisará ser reconfigurada.",
        },
        step1: {
          grouperName: "Nome do agrupamento",
          grouperNameAlreadyExists: "Já existe um agrupamento com esse nome",
          nameRules: "O nome deve ter entre 3 e 60 caracteres",
        },
      },
      step2: {
        customColumnInput: {
          nameColumnEmpty: "O nome da coluna não pode estar vazio",
          columnsNameAlreadyExists: "Já existe uma coluna com esse nome",
        },
        modifyColumnsModal: {
          renameColumns: "Renomear colunas",
          resetColumns: "Redefinir colunas",
          columnsOf: "Colunas de",
          cancel: "Cancelar",
          accept: "Aceitar",
        },
        step2: {
          columnToJoinResults: "Coluna para unir com resultados",
          file: "Arquivo",
          chooseFile: "Escolher arquivo...",
          column: "Coluna",
          chooseColumn: "Escolher coluna...",
          columnsToUse: "Colunas a serem usadas",
          chooseColumns: "Escolher colunas...",
          renameColumns: "Renomear Colunas",
          preview: "Visualização Prévia",
          resetColumnTitle: "Redefinir colunas",
          resetColumnMessage: "redefinir colunas",
        },
      },
      baseModal: {
        cancel: "Cancelar",
        accept: "Aceitar",
      },
      configGroup: {
        newGroup: "Novo grupo",
        previous: "Anterior",
        nonPossibleContinueTwoDataBases:
          "Não é possível continuar pois existem duas bases com o mesmo arquivo",
        finish: "Finalizar",
        next: "Próximo",
        editGroup: "Editar grupo",
        createGroup: "Criar grupo",
      },
      groupAdmin: {
        groupsAdmin: "Administração de grupos",
        searchByName: "Pesquisar por nome",
        nonGroupsYet: "Ainda não existem grupos disponíveis.",
        removeGroup: "remover o grupo",
        confirm: "Confirmar",
      },
      groupCard: {
        usedColumns: "Colunas utilizadas:",
      },
      viewGrouperInfo: {
        name: "Nome:",
        base: "Base:",
        columns: "Colunas:",
        column: "Coluna:",
        generalInfoGroup: "Informações gerais do grupo",
        mergeColumnsPreviousBase: "Colunas de mesclagem - base anterior:",
        mergeColumnsCurrentBase: "Colunas de mesclagem - base atual:",
        removeDuplicates: "Remover duplicados:",
        finalColumns: "Colunas finais",
        usedColumns: "Colunas utilizadas:",
      },
    },
    measureManagement: {
      configMeasure: {
        configCompounds: {
          operatorsCalculator: {
            operators: "Operadores",
          },
        },
        configCompoundsMeasure: {
          parenthesesMatches:
            "Certifique-se de que todos os parênteses estejam corretamente correspondidos",
          formulaFinishInOperator:
            "Certifique-se de que a fórmula não termine com um operador",
          measureName: "Nome da medida",
          measureNameAlreadyExists: "Já existe uma medida com esse nome",
          formula: "Fórmula",
          enterFormula: "Insira a fórmula",
          measureExpenses: "Medidas de despesas",
          measureSales: "Medidas de vendas",
          measureCompounds: "Medidas compostas",
          cancel: "Cancelar",
          accept: "Aceitar",
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
        },
        configExpensesMeasure: {
          newMeasureName: "Nome da nova medida",
          cancel: "Cancelar",
          accept: "Aceitar",
          searchByBucketGrouper: "Pesquisa por Bucket ou Agrupador",
          editMeasure: "Editar medida",
          newMeasure: "Nova medida",
        },
        configSalesMeasure: {
          tabs: {
            sales: "VENDAS",
            otherBase: "OUTRA FONTE",
          },
        },
        groupByRowSelects: {
          chooseColumn: "Escolher coluna...",
          chooseFunction: "Escolher função...",
        },
        otherFontStep1: {
          file: "Arquivo",
          selectFile: "Selecionar arquivo",
          mergeColumnsCurrentBase: "Colunas de mesclagem - Base atual",
          chooseColumns: "Escolher colunas",
          mergeColumnsSalesBase: "Colunas de mesclagem - Base VENDAS",
          numberColumnsSelectedEqualSalesBase:
            "O número de colunas selecionadas na base atual deve ser igual ao da base VENDAS",
          columnsToAddFunctionAggregation:
            "Colunas a adicionar e função de agregação",
          avoidDuplicatingValues:
            "Para evitar duplicação de valores, as informações adicionadas serão agrupadas previamente, usando as colunas escolhidas para mesclagem",
          reset: "Redefinir",
          resetData: "Redefinir dados",
          deleteAllData: "excluir todos os dados",
        },
        otherMeasureFont: {
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
          previous: "Anterior",
          finish: "Finalizar",
          next: "Próximo",
        },
        salesMeasure: {
          loading: "Carregando...",
          measureName: "Nome da Medida",
          newMeasureName: "Nome da nova medida",
          measureNameAlreadyExists: "Já existe uma medida com esse nome",
          operation: "Operação",
          chooseOperation: "Escolher operação...",
          numerators: "Numeradores",
          chooseColumns: "Escolher colunas...",
          chooseColumn: "Escolher coluna...",
          denominators: "Denominadores",
          columnsAddInfo:
            "As colunas adicionadas em cada entrada serão somadas automaticamente.",
          columns: "Colunas",
          column: "Coluna",
          modifyMeasure: "modificar a medida",
          createNewMeasure: "criar uma nova medida",
          newMeasure: "Nova medida",
          cancel: "Cancelar",
          accept: "Aceitar",
        },
      },
      measureAdmin: {
        tabs: {
          expenses: "DESPESAS",
          sales: "VENDAS",
          compounds: "COMPOSTOS",
        },
        cannotDeleteMeasureAsBeingUsed:
          "Não é possível excluir esta medida, pois está sendo usada por uma medida composta.",
        searchingMeasures: "Procurando medidas...",
        loadingMeasures: "Carregando medida...",
        measureAdmin: "Administração de medidas",
        searchByName: "Pesquisar por nome",
        notMeasuresYet: "Ainda não há medidas disponíveis.",
        removeMeasure: "remover a medida",
        confirm: "Confirmar",
      },
      viewInfoCompoundsModal: {
        generalInfoMeasure: "Informações gerais da medida",
        formula: "Fórmula",
      },
      viewInfoSalesModal: {
        file: "Arquivo",
        currentBaseColumns: "Colunas da base atual:",
        baseColumnsSales: "Colunas da base de Vendas:",
        operation: "Operação:",
        columns: "Colunas:",
        denominatorColumns: "Colunas do denominador:",
        groupers: "Agrupamentos",
      },
    },
  },
  dialog: {
    cofirmThat: "Confirma que deseja ",
    cancel: "Cancelar",
    accept: "Aceitar",
    confirmTitle: "Confirmar",
    filter: "Filtrar",
  },
  errorTexts: {
    pageNotExist: "Esta página não existe",
    backToHome: "Voltar para a página inicial",
  },
  expensesSettings: {
    sendTransformations: {
      loading: "Salvando transformações...",
      codes: {
        200: "As transformações foram aplicadas com sucesso.",
        201: "As transformações foram aplicadas com sucesso.",
        400: "Ocorreu um erro e não foi possível aplicar as transformações",
        500: "Ocorreu um erro e não foi possível aplicar as transformações",
      },
    },
    getExpenses: {
      error: "Ocorreu um erro e não foi possível obter os gastos",
    },
    getView: {
      error: "Ocorreu um erro e não foi possível obter a visualização",
    },
    saveView: {
      loading: "Salvando visualização...",
      codes: {
        200: "A visualização foi salva com sucesso.",
        201: "A visualização foi salva com sucesso.",
        400: "Ocorreu um erro e não foi possível salvar a visualização.",
      },
    },
    expensesSettingsTexts: {
      expensesConfig: "Configurações de Despesas",
      applyingTrans: "Aplicando transformações...",
      savingView: "Salvando visualização...",
      filteredExpense: "Despesa Filtrada",
      totalExpense: "Despesa Total",
      noRowsSelected: "Nenhuma linha selecionada",
      modify: "Modificar",
      errorDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      unaddedTransf:
        "Existem transformações não adicionadas. Confirma mesmo assim que deseja aplicar as transformações?",
      wantToApply: "Confirma que deseja aplicar essas transformações?",
    },
    modalContent: {
      valueExists: "*O valor já existe",
      emptyFieldsCannotAdded: "*Campos vazios não podem ser adicionados",
      chooseColumn: "Escolher coluna...",
      add: "Adicionar",
      chooseAddValue: "Escolher ou adicionar um valor...",
      newValue: "Novo valor",
      deleteRow: "Excluir linha",
      noTransToApply: "Não há transformações para aplicar.",
      changeWillAffect: "Essa alteração afetará ",
      rows: "linhas",
      cancel: "Cancelar",
      accept: "Aceitar",
    },
  },
  forms: {
    text: "Texto...",
    select: {
      loading: "Carregando...",
      noOptionsAvailable: "Não há opções disponíveis",
    },
  },
  general: {
    emptyInputs: "Todos os campos devem ser preenchidos.",
    all: "Todos",
    seeMore: "ver mais",
    searchByName: "Pesquisar por nome",
    cancel: "Cancelar",
    accept: "Aceitar",
  },
  generatedDownloads: {
    downloads: "Descarregas"
  },
  header: {
    settings: "Configurações",
  },
  highCharts: {
    analysis: "Análise",
    loading: "Loading...",
    filterLoading: "Filtrando a análise...",
    chooseAnalysis: "Escolher análise...",
    filters: "Filtros",
  },
  icons: {
    delete: "Excluir linha",
    deleteAll: "Excluir todos",
  },
  indicators: {
    getGroups: {
      error:
        "Ocorreu um erro e não foi possível obter os grupos de indicadores.",
    },
  },
  indicatorsManagement: {
    addGroup: {
      error: "Ocorreu um erro e não foi possível obter os grupos",
      codes: {
        200: "O grupo foi adicionado com sucesso",
        201: "O grupo foi adicionado com sucesso",
        400: "Ocorreu um erro e não foi possível adicionar o grupo",
      },
    },
    addIndicator: {
      codes: {
        201: "O indicador foi criado com sucesso",
        400: "Ocorreu um erro e não foi possível criar o indicador",
      },
    },
    editIndicator: {
      codes: {
        200: "O indicador foi atualizado com sucesso",
        400: "Ocorreu um erro e não foi possível editar o indicador",
      },
    },
    deleteIndicator: {
      codes: {
        200: "O indicador foi excluído com sucesso",
        400: "Ocorreu um erro e não foi possível excluir o indicador",
      },
    },
    putState: {
      codes: {
        200: "O estado foi modificado com sucesso",
        201: "O estado foi modificado com sucesso",
        400: "Ocorreu um erro e não foi possível modificar o estado",
      },
    },
  },
  indicatorsManagementTexts: {
    addGroupModal: {
      groupNameAlreadyExists: "Já existe um grupo com esse nome",
      fieldCannotEmpty: "O campo não pode ficar vazio",
      newGroup: "Novo grupo",
      cancel: "Cancelar",
      accept: "Aceitar",
      name: "Nome",
      newGroupName: "Nome do novo grupo",
    },
    chartCard: {
      modifyingChartState: "Modificando estado do gráfico...",
      baseFile: "Arquivo base",
      axisY: "Eixo Y",
      of: "de",
      graphicType: "Tipo de gráfico",
    },
    configCharts: {
      none: "Nenhum",
      creatingGraphic: "Criando gráfico...",
      modifyingGraphic: "Modificando gráfico...",
      editGraphic: "Editar gráfico",
      createGraphic: "Criar gráfico",
      editTheGraphic: "editar o gráfico",
      createTheGraphic: "criar o gráfico",
      resetAllGraphSteps: "Redefinir todos os passos do gráfico",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
    },
    configIndicators: {
      exclude: "EXCLUIR",
      include: "INCLUIR",
      totalInExpense: "Total em despesas",
      totalInResult: "Total em resultados",
      totalTransferred: "Total transferido",
      bucketPeriodsWithError: "Bucket-períodos com erro",
      nameIndicatorExists: "Já existe um indicador com este nome",
      creatingIndicator: "Criando indicador...",
      modifyingIndicator: "Modificando indicador...",
      editIndicator: "Editar indicador",
      createIndicator: "Criar indicador",
      editTheIndicator: "editar o indicador",
      createTheIndicator: "criar o indicador",
      former: "Anterior",
      resetAllIndicatorSteps: "Redefinir todos os passos do indicador",
      cleanAll: "Limpar tudo",
      finish: "Finalizar",
      next: "Próximo",
    },
    indicatorCard: {
      unitOfMeasurement: "Unidade de medida",
      group: "Grupo",
      active: "Ativo",
      inactive: "Inativo",
    },
    indicatorsManagement: {
      tabs: {
        indicators: "INDICADORES",
        graphics: "GRÁFICOS",
      },
      indicatorConfig: "Configuração de Indicador",
      graphicConfig: "Configuração de Gráfico",
      indicatorAdmin: "Administração de Indicadores",
      uploadingIndicators: "Carregando indicadores...",
      uploadingGráficos: "Carregando gráficos...",
      deletingIndicator: "Excluindo indicador...",
      deletingGraphics: "Excluindo gráfico...",
      searchByNameIndicatorGroup: "Pesquisa por nome de indicador ou grupo",
      activeIndicators: "INDICADORES ATIVOS",
      nonActiveIndicators: "Não há indicadores ativos.",
      inactiveIndicators: "INDICADORES INATIVOS",
      nonInactiveIndicators: "Não há indicadores inativos.",
      nonPossibleGetIndicators:
        "Não foi possível obter os indicadores. Tente novamente mais tarde.",
      searchByNameGraphicCoord: "Pesquisa por nome de gráfico ou coordenadas",
      activeGraphics: "GRÁFICOS ATIVOS",
      nonActiveGraphics: "Não há gráficos ativos.",
      inactiveGraphics: "GRÁFICOS INATIVOS",
      nonInactiveGraphics: "Não há gráficos inativos.",
      nonPossibleGetGraphics:
        "Não foi possível obter os gráficos. Tente novamente mais tarde.",
      deleteTheIndicator: "excluir o indicador",
      deleteTheGraphic: "excluir o gráfico",
      confirm: "Confirmar",
    },
    step1: {
      coin: "Moeda",
      percentage: "Porcentagem",
      units: "Unidades",
      creatingIndicatorGroup: "Criando grupo de indicadores...",
      description: "Descrição",
      indicatorName: "Nome do Indicador",
      unitOfMeasurement: "Unidade de Medida",
      chooseUnit: "Escolher unidade...",
      chooseCreateGroup: "Escolher ou criar grupo...",
      parameterizationIndicator: "Parametrização do indicador",
      file: "Arquivo",
      chooseFile: "Escolher arquivo...",
      column: "Coluna",
      chooseColumn: "Escolher coluna...",
      function: "Função",
      chooseFunction: "Escolher função...",
      inactiveIndactor: "Indicador Inativo",
      singleSum: "Soma única",
      uniqueCriteriaColumn: "Coluna de critério único",
      productSum: "Soma de produto",
      columnToMultiply: "Coluna para multiplicar",
      chooseOneOrMoreOptions: "Escolher uma ou mais opções...",
    },
    step1Charts: {
      aplicationOptions: "OPÇÕES DE APLICAÇÃO",
      periods: "períodos",
      fileColumns: "COLUNAS DE ARQUIVO",
      creatingIndicatorGroup: "Criando grupo de indicador...",
      graphParameterization: "Parametrização do gráfico",
      graphicTitle: "Título do gráfico",
      writeGraphicName: "Escrever nome do gráfico",
      graphicNameExists: "Já existe um gráfico com este nome",
      file: "Arquivo",
      chooseFile: "Escolher arquivo...",
      axisY: "Eixo Y",
      chooseColumn: "Escolher coluna...",
      baseColumn: "Coluna base",
      axisYFunction: "Função eixo Y",
      baseColumnFunction: "Função coluna base",
      chooseFunction: "Escolher função...",
      axisYNameOptional: "Nome do eixo Y (opcional)",
      writeAxisYName: "Escrever nome do eixo Y",
      axisX: "Eixo X",
      chooseOption: "Escolher uma opção...",
      axisXNameOptional: "Nome do eixo X (opcional)",
      writeAxisXName: "Escrever nome do eixo X",
      drillDownColumn: "Coluna de detalhamento",
      optional: "opcional",
      serieColumnOptional: "Coluna de séries (opcional)",
      none: "Nenhum",
      group: "Grupo",
      chooseCreateGroup: "Escolher ou criar grupo...",
      activeGraphic: "Gráfico Ativo",
    },
    viewInfoModal: {
      columns: {
        column: "Coluna",
        values: "Valores",
        criteria: "Critério",
      },
      exlude: "Excluir",
      include: "Incluir",
      fileColumn: "Coluna de arquivo",
      externFileColumn: "Coluna de arquivo externo",
      criteriaBaseColumn: "Coluna de Base de Critério",
      generalInfoGraph: "Informações gerais do gráfico",
      generalInfoIndicator: "Informações gerais do indicador",
      unitOfMeasurement: "Unidade de medida",
      graphType: "Tipo de gráfico",
      group: "Grupo",
      baseFile: "Arquivo base",
      baseColumn: "Coluna base",
      axisY: "Eixo Y",
      axisYName: "Nome do eixo Y",
      appliedFunction: "Função aplicada",
      validateUniqueColumn: "Coluna para validar únicos",
      multiplyColumn: "Colunas para multiplicar",
      axisX: "Eixo X",
      axisXName: "Nome do eixo X",
      drilldownColumn: "Colunas de detalhamento",
      serialColumns: "Colunas de séries",
      filtersOnFile: "Filtros no arquivo",
      filterOf_one: "Filtrado de ",
      filterOf_two: "com informações de",
      prefilterOnFile: "Filtros prévios no arquivo",
      crossoverValues: "Valores de interseção de",
      with: "com",
    },
  },
  indicatorsView: {
    getIndicators: {
      error: "Ocorreu um erro e não foi possível obter os indicadores",
      loading: "Obtendo indicadores...",
    },
    getIndicatorChart: {
      codes: {
        200: "Foi possível obter o gráfico",
        400: "Ocorreu um erro e não foi possível obter o gráfico do indicador",
      },
    },
    indicators: "Indicadores",
    periods: "Períodos",
    nonPossibleGetIndicators:
      "Não foi possível obter os indicadores. Tente novamente mais tarde.",
    noIndicatorsCreated: "Ainda não há indicadores criados.",
  },
  language: {
    languages: "Línguas",
  },
  login: {
    loginLoading: "Iniciando sessão...",
    getInto: "Entrar",
    login: "Iniciar Sessão",
    email: "E-mail",
    password: "Senha",
    loginError: "O usuário ou a senha estão incorretos.",
    loginProblem: "Ocorreu um erro ao iniciar sessão. Tente novamente",
    rememberAuth: "Lembrar código de autenticação",
    storeAuth:
      "Armazenar o código de autenticação de dois fatores por uma semana",
    trust: "Confiar neste computador",
    trustLogin:
      "Mantém a sessão iniciada neste computador por 24 horas. Use somente em computadores não públicos.",
  },
  menuItemsOptions: {
    dataManagement: {
      title: "Gestão de Dados",
      url: "administracion-datos",
    },
    validations: {
      title: "Validações",
      adminValidations: {
        title: "Administração de validações",
        url: "administracion-validaciones",
      },
      configCodeMatching: {
        title: "Configurar cruzamento de códigos",
        subtitleColumns: "Columnas",
      },
      matchesDashboard: {
        title: "Painel de Controle de Correspondências",
        url: "config/cruce-codigos",
      },
    },
    criteriaManagement: {
      title: "Gestão de Critérios",
      url: "administracion-criterios",
    },
    preProrationParameterization: {
      title: "Parametrização Pré-proração",
      url: "parametrizacion-preprorrateo",
    },
    configPreProratedExpenses: {
      title: "Configurar Despesas Pré-proração",
      url: "configurar-gastos-preprorrateo",
    },
    configProRataExpenses: {
      title: "Configurar Despesas Prorata",
      url: "configuracion-gastos",
    },
    modelParameterization: {
      title: "Parametrização do Modelo",
    },
    controlPanel: {
      title: "Painel de Controle",
      url: "tablero-control",
    },
    taskManager: {
      title: "Gerenciador de Tarefas",
      url: "gestor-tareas",
    },
    indicatorManagement: {
      title: "Gestão de Indicadores",
      url: "administracion-indicadores",
    },
    simulator: {
      title: "Simulador",
    },
    simulatorCollapseList: {
      simulatorAdmin: {
        title: "Administração de simulação",
        url: "/administracion-simulacion",
      },
    },
    generatedDownloads: {
      title: "Descarregas",
      url: "/descargas-generadas",
    },
    parameterModelCollapseList: {
      assignStages: {
        title: "Atribuir Estágios",
        url: "/parametrizacion-modelo/asignacion-etapas",
      },
      criteriaCalculation: {
        title: "Critérios e Cálculo",
        url: "/parametrizacion-modelo/calculo",
      },
      calculationReport: {
        title: "Relatório de Cálculo",
        url: "/parametrizacion-modelo/reporte",
      },
    },
    dashboardCollapseList: {
      indicators: {
        title: "Indicadores",
        url: "/dashboard/indicadores",
      },
      graphics: {
        title: "Gráficos",
        url: "/dashboard/graficos",
      },
    },
    dataExportModellingCollapseList: {
      measureManagement: {
        title: "Gestão de Medidas",
        url: "/data-modelling/administracion-medidas",
      },
      groupingsManagement: {
        title: "Gestão de Agrupamentos",
        url: "/data-modelling/administracion-agrupaciones",
      },
      analysisManagement: {
        title: "Gestão de Análises",
        url: "/data-modelling/administracion-analisis",
      },
      analysisExecution: {
        title: "Execução de Análises",
        url: "/data-modelling/ejecucion-analisis",
      },
      downloadReport: {
        title: "Baixar relatório",
        url: "/data-modelling/descargar-reporte",
      },
    },
  },
  modelParameterization: {
    sendStages: {
      loading: "Salvando estágios...",
      codes: {
        200: "Os estágios foram atribuídos com sucesso.",
        201: "Os estágios foram atribuídos com sucesso.",
        400: "Ocorreu um erro e não foi possível atribuir os estágios.",
      },
    },
    postCalculateProrration: {
      codes: {
        200: "O cálculo foi iniciado com sucesso.",
      },
    },
    postResetCalculation: {
      codes: {
        200: "O cálculo foi redefinido com sucesso.",
        400: "Ocorreu um erro e não foi possível redefinir o cálculo.",
      },
    },
    propagateCriteria: {
      codes: {
        200: "O critério foi propagado com sucesso.",
        400: "Ocorreu um erro e não foi possível propagar o critério.",
      },
    },
    deleteCriteria: {
      singularCodes: {
        200: "O critério foi excluído com sucesso.",
        400: "Ocorreu um erro e não foi possível excluir o critério.",
      },
      pluralCodes: {
        200: "Os critérios foram excluídos com sucesso.",
        400: "Ocorreu um erro e não foi possível excluir os critérios.",
      },
    },
    dataForDummy: {
      codes: {
        200: "Os dados foram carregados com sucesso.",
        400: "Ocorreu um erro e não foi possível carregar os dados.",
      },
    },
    getGrouperRows: {
      loading: "Obtendo informações...",
      codes: {
        400: "Ocorreu um erro e não foi possível baixar as linhas.",
      },
    },
    getCriteriaData: {
      loading: "Obtendo informações...",
      codes: {
        400: "Ocorreu um erro e não foi possível baixar as informações do critério.",
      },
    },
    getProrationResults: {
      codes: {
        200: "Os resultados foram obtidos com sucesso.",
        400: "Ocorreu um erro e não foi possível baixar os resultados.",
        500: "Ocorreu um erro e não foi possível baixar os resultados.",
      },
      loading: "Obtendo resultados...",
    },
  },
  modelParameterizationTexts: {
    calculateBucketButton: {
      rowsWithoutAssignedCriteria:
        "É necessário que todas as linhas tenham um critério atribuído para iniciar o cálculo do Bucket.",
      amountIsZero:
        "Não é possível iniciar o cálculo do bucket se a despesa for $0.",
      calculated: "Calculado",
      notCalculated: "Calcular bucket",
      calculateActualBucket: "calcular o bucket atual",
      calculateBucketWithNotTransferred:
        "calcular bucket com despesas não transferidas",
    },
    calculations: {
      title: "Parametrização do modelo: Atribuição de critério e cálculo",
      periods: "Períodos",
    },
    charts: {
      origin: "Origem",
      percentage: "Porcentagem",
      stage: "Estágio",
      coin: "Moeda",
      formatterTitle: "Porcentagem em relação à despesa de origem",
    },
    criteriaModelContent: {
      propagatingCriteria: "Propagando critérios...",
      selectReceiver: "Selecionar receptor",
      selectCriteria: "Selecionar critério",
      apportionmentCriteria: "Critério de Prorrateamento",
      destinationCriteria: "Atribuir critérios diferenciados por destino",
      only50rows: "Válido apenas para até 50 linhas",
      completeFields: "Preencha todos os campos",
      finalRecipientOfExpense: "RECEPTOR FINAL DA DESPESA",
      chooseReceiver: "Escolher receptor...",
      confirm: "Confirmar",
      cancel: "Cancelar",
      confirmationMessage:
        "Confirma a atribuição dos critérios? Essa ação irá redefinir o cálculo se já tiver sido iniciado.",
    },
    customModelCell: {
      errorDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
    },
    customToolbar: {
      confirmationWithRowsFirstPart: "Confirma a exclusão dos critérios de ",
      confirmationWithRowsSecondPart:
        "linhas? Essa ação irá redefinir o cálculo.",
      confirmationsWithoutRows:
        "Confirma a exclusão dos critérios da linha? Essa ação irá redefinir o cálculo.",
      downloadingCSV: "Baixando arquivo CSV...",
      deletenCriteria: "Excluindo critério",
      exportCSV: "Exportar para CSV",
      totalCost: "Despesa Total",
      noCostDifInStageZero: "Não há despesas diferenciadas no estágio 0.",
      noCostDif: "Não há agrupadores com despesas diferenciadas.",
      difByDestiny: "Diferenciar por destino",
      noRowsSelectedWithCriterias:
        "Nenhuma linha selecionada com critérios para exclusão.",
      deleteCriterias: "Excluir critérios",
      noRowsSelected: "Nenhuma linha selecionada.",
      difReceivers:
        "Não é possível atribuir critérios a agrupadores com receptores diferentes.",
      noCriteriaStage0: "Não é possível atribuir critérios ao estágio 0.",
      assingCriteria: "Atribuir Critérios",
      confirm: "Confirmar",
      close: "Fechar",
      accept: "Aceitar",
    },
    customTypographyTableText: {
      mount: "montante",
    },
    dummyGrouperGroup: {
      groupers: "Agrupadores",
      expenseColumn: "Coluna de Despesa",
      salesFileColumn: "Coluna de Arquivo de Vendas",
    },
    iterationsButtons: {
      iterations: "ITERAÇÕES",
    },
    getGroupingColDef: {
      grouper: "Agrupador",
      collapse: "Colapsar",
      expand: "Expandir",
    },
    calculationTabValue: {
      nonTransferredExpenses: "DESPESAS NÃO TRANSFERIDAS",
    },
    modelTable: {
      succesEditName: "O nome foi editado com sucesso.",
      errorEditName: "Ocorreu um erro ao editar o nome.",
      grouper: "agrupador",
      all: "todos",
      withoutCritera: "sem critério",
      editName: "Editar nome",
      newName: "Novo nome...",
      apply: "Aplicar",
      editingName: "Editando nome...",
    },
    onChangeSelectValue: {
      stage: "etapa",
      column: "coluna",
      doNotSend: "não enviar",
    },
    useRadioGroup: {
      doNotValidate: "não validar",
      doNotValidateLabel: "Não Validar",
      flaw: "defeito",
      flawLabel: "Padrão",
      other: "outro",
      otherLabel: "Outra coluna",
      file: "Arquivo",
      column: "Coluna",
      chooseColumn: "Escolher coluna",
    },
    resetButton: {
      toReset: "Redefinir",
      resetOptions: "OPÇÕES DE REDEFINIÇÃO",
      currentStageCalculation: "o cálculo da etapa atual",
      resetCurrentStageCalculation: "Redefinir cálculo da etapa atual",
      allCalculation: "todo o cálculo",
      resetAllCalculation: "Redefinir todo o cálculo",
      calculationStageBucket: "o cálculo e etapas do bucket",
      resetCalculationStageBucket: "Redefinir cálculo e etapas do bucket",
      allCalculationBucketStages: "todos os cálculos e etapas",
      resetAllCalculationStages: "Redefinir todos os cálculos e etapas",
      currentIterationCalculation: "o cálculo da iteração atual",
      resetCurrentIterationCalculation: "Redefinir cálculo da iteração atual",
    },
    stagesButtons: {
      stage: "ETAPA",
    },
    step2: {
      tabValueExpenses: "DESPESAS",
      tabValueExpensesNotTransferred: "DESPESAS NÃO TRANSFERIDAS",
      calculateEverything: "calcular tudo",
      calculateEverythingTitle: "Calcular Tudo",
      startingCalculation: "Iniciando cálculo...",
      resetting: "Redefinindo...",
      checkPreviousStageCalculationError:
        "Ocorreu um erro e há agrupadores duplicados. Verifique o cálculo da etapa anterior.",
      assignCriteria: "Atribuir Critério",
      resetCalcultatonStageBucket: "redefinir o cálculo e etapas do bucket",
      resetAllCalculationsStages: "redefinir todos os cálculos e etapas",
      confirmPartOne: "Confirma que deseja ",
      confirmPartTwo:
        " Essa ação o redirecionará de volta para a atribuição de etapas.",
      confirm: "Confirmar",
      stillExpensesNotTransferredStage:
        "Ainda há despesas não transferidas na etapa",
      confirmStartCalculationAnyway:
        "Confirma que deseja iniciar o cálculo mesmo assim?",
      confirmStartCalculation: "Confirmar início do cálculo",
      editCriteria: "Editar Critério",
      apportionment: "PRORRATEAMENTO",
      reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria:
        "reatribuir o novo critério em todos os agrupadores com o mesmo receptor e critério antigo",
      reassingCriteriaToRows: "Reatribuir critério às linhas",
      close: "Fechar",
      resetCalculationCurrentIteration: "redefinir o cálculo da iteração atual",
      all: "todos",
      calculateCurrentBucket: "calcular o bucket atual",
    },
    switchListSecondary: {
      onlyGroupersWithDifferentiatedExpenses:
        "Apenas os agrupadores com despesas diferenciadas são mostrados.",
      recordedInGrouperMessagePartOne:
        "registros neste agrupador. Por questões de desempenho, o limite para diferenciar despesas por destino é de ",
      recordedInGrouperMessagePartTwo: "registros",
      noGroupersWithDifferentiatedExpenses:
        "Não há agrupadores com despesas diferenciadas.",
    },
    totalAmount: {
      expense: "Despesa",
    },
    transformData: {
      destination: "destino",
      oldReceiverCode: "código receptor antigo",
      totalExpense: "Despesa total",
    },
    nestedList: {
      flaw: "defeito",
      relatedGroupers: "Agrupadores relacionados:",
    },
    viewDummyModal: {
      expensesColumn: "Coluna de Despesas",
      fileSalesColumn: "Coluna de Arquivo de Vendas",
      stage: "etapa",
      dummyDirectTransfer: "Transferência Direta Dummy",
      criteriaInfo: "Informações do Critério",
      finalRecieverExpense: "Receptor Final de Despesas",
    },
    columnsTreeData: {
      getColumns: {
        grouper: "agrupador",
        amount: "monto",
        stages: "etapas",
        amountExpense: "MONTO GASTO",
      },
      getGroupingColDef: {
        bucketExpense: "MONTANTE DO BUCKET",
        collapse: "Colapsar",
        expand: "Expandir",
      },
    },
    formattedRows: {
      missingAssingStages: "Faltam etapas para atribuir",
      assignStages: "Etapas atribuídas",
    },
    stages: {
      stages: "Etapas",
      nowRowsSelected: "Nenhuma linha selecionada",
      assignToSelected: "Atribuir aos selecionados",
      stage: "etapa",
    },
    stagesSettings: {
      stagesSettingTitle: "Parametrização do modelo: Atribuição de etapas",
      periods: "Períodos",
    },
    step1: {
      stagesGreaterThan10: "O número de etapas não pode ser maior que 10",
      stagesGreaterThan0: "O número de etapas deve ser maior que 0",
      missingToAssingStages: "Faltam etapas para alguns agrupadores.",
      editingCauseCleared:
        "Editar o número de etapas de um agrupador irá apagar sua atribuição de critério se houver uma.",
      assigningStages: "Atribuindo etapas",
    },
    treeDataCustomGroupingColumn: {
      successfullyEditName: "O nome foi editado com sucesso.",
      errorEditName: "Ocorreu um erro ao editar o nome.",
      all: "todas",
      noRows: "sem linhas",
      errorNotPossibleToDownload:
        "Ocorreu um erro e não foi possível baixar as informações.",
      editName: "Editar nome",
      newName: "Novo nome...",
      apply: "Aplicar",
      unifiedBucketsGroupers:
        "Se o novo nome do bucket coincidir com um nome de bucket já existente, eles e seus agrupadores serão unificados. Essa ação não pode ser desfeita.",
      editingName: "Editando nome...",
    },
    customCheckbox: {
      all: "Todas",
      withoutCriteria: "sem critério",
      rowsWithoutCriteria: "Linhas sem critério atribuído",
      withoutRow: "sem linha",
      rowsWithoutStages: "Linhas sem etapa atribuída",
    },
    customGridTreeDataGroupingCell: {
      editName: "Editar nome:",
    },
    dummyTransfer: "TRANSFERÊNCIA DUMMY",
    validationDropdown: {
      codeValidation: "Validação de Códigos",
      stage: "Etapa",
    },
  },
  pageinProgress: {
    underDevelopment: "Esta página ainda está em desenvolvimento.",
  },
  periods: {
    multipleSelectCheckmarks: {
      periods: "Períodos",
    },
    enabledPeriodsTable: {
      year: "Ano",
      enabledPeriods: "Períodos habilitados",
      notEnabledPeriods: "Períodos não habilitados",
      nonExistingPeriods: "Períodos não existentes",
    },
    periodsDialogContent: {
      filterPeriodsSuccess:
        "Os filtros para períodos foram aplicados com sucesso.",
      errorSendingPeriods: "Erro ao enviar os períodos",
      applyingPeriods: "Aplicando períodos...",
      filter: "Filtrar",
      noPeriodsExported: "Nenhum período exportado",
      selectAll: "Selecionar Todos",
      cleanAll: "Limpar Todos",
      enabledPeriods: "Períodos habilitados",
      onlyWithExpenses: "mostrar apenas os que têm despesas",
      noPeriodsAvailableYet: "Ainda não há períodos disponíveis",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
    periodsFilter: {
      periods: "Períodos",
    },
  },
  persistLogin: {
    logoutLoading: "Fechando sessão...",
    role: "Cliente",
  },
  preprorration: {
    preprorrationCalculate: {
      loading: "Iniciando cálculo...",
      codes: {
        200: "O cálculo foi iniciado com sucesso",
        201: "O cálculo foi iniciado com sucesso",
      },
    },
  },
  preprorationParameterization: {
    getPreprorationBuckets: {
      loading: "Obtendo buckets...",
      error: "Ocorreu um erro e não foi possível baixar os buckets.",
    },
    getPreprorationGrouper: {
      loading: "Obtendo agrupadores...",
      error: "Ocorreu um erro e não foi possível baixar os agrupadores.",
    },
    schemaInfoModal: {
      bucketExpenseUnderscore: "BUCKET_DESPESA",
      bucketExpense: "BUCKET DESPESA",
      grouper: "AGRUPADOR",
      percentage: "Porcentagem",
      criteriaOutline: "Esquema de Critérios",
    },
    api: {
      getPreprorationCriterias: {
        errorReceiveCriteria: "Erro ao receber os critérios",
      },
    },
    newSchemaContent: {
      criteria: "critério",
      percentage: "Porcentagem",
      schemaWithNameAlreadyExists: "Já existe um esquema com este nome",
      necessaryCompleteFields: "Todos os campos precisam ser preenchidos",
      alreadyCriteriaApplied:
        "Já existe um critério aplicado no destino selecionado",
      percentageCannotLessOne: "A porcentagem não pode ser menor que 1%",
      alreadyPercentageApplied:
        "Já existe uma porcentagem aplicada no destino selecionado",
      percentageCannotExceedOneHundred:
        "A porcentagem total não pode ser maior que 100%",
      necessaryCompletFieldsForSchema:
        "Todos os campos precisam ser preenchidos para criar um esquema",
      totalPercentageMustBeOneHundred:
        "A porcentagem total dos critérios deve ser 100%",
      schemaName: "Nome do Esquema",
      bucketExpense: "Bucket Despesa",
      bucketDestination: "bucket destino",
      grouperDestination: "agrupador destino",
      add: "Adicionar",
      chooseAddValue: "Escolher ou adicionar valor...",
      chooseCriteria: "Escolher critério...",
      cancel: "Cancelar",
      save: "Salvar",
      choosePercentage: "Escolher porcentagem...",
    },
    preprorationParameterization: {
      tabs: {
        bucketExpense: "Bucket Despesa",
        grouper: "Agrupador",
        noExpensesToPreprorate: "Não há despesas para preprorratear em",
      },
      schemaTabs: {
        selectSchema: "Selecionar Esquema",
        createSchema: "Criar Esquema",
      },
      criteria: "critério",
      successApplySchemas: "Esquemas aplicados com sucesso.",
      successApplySchema: "Esquema aplicado com sucesso.",
      errorApplySchema: "Erro ao aplicar o esquema",
      successCreateSchema: "Esquema criado com sucesso",
      errorCreateSchema: "Erro ao criar o esquema",
      bucketExpensesUnderscore: "BUCKET_DESPESA",
      successReversedCalculation: "Cálculo revertido com sucesso.",
      errorReversedCalculation: "Erro ao reverter o cálculo",
      schemasToApplyMissing:
        "O cálculo não pode ser realizado, faltam esquemas a serem aplicados",
      noDataToCalculation: "Não há dados para calcular",
      applyingSchemas: "Aplicando esquemas...",
      applyingSchema: "Aplicando esquema...",
      creatingSchema: "Criando esquema...",
      reversingCalculation: "Revertendo cálculo...",
      settingUpPreproration: "Configuração de Preproração",
      periods: "Períodos",
      allRowsNeedSchemas:
        "Todas as linhas precisam ter esquemas aplicados para iniciar o cálculo",
      calculate: "Calcular",
      reverseCalculation: "Reverter cálculo",
      assingSchema: "Atribuir esquema",
      errorDownloadingInfo:
        "Ocorreu um erro e não foi possível baixar as informações.",
      noExpensesToPreprorate: "Não há despesas para preprorratear em",
      criteriaOutline: "Esquema de Critérios",
      startCalculation: "iniciar o cálculo de preproração",
      resetCalculation: "reiniciar o cálculo de preproração",
      confirmCalculation: "Confirmar cálculo",
      confirmReset: "Confirmar reinício",
    },
    selectSchemaContent: {
      bucketExpense: "Bucket Despesa",
      bucketExpenseUnderscore: "BUCKET_DESPESA",
      grouper: "Agrupador",
      criteria: "Critério",
      chooseExistingSchema: "Escolher um esquema existente",
      selectSchema: "Selecionar esquema...",
      detail: "Detalhe",
      schemaWillAplly: "O esquema será aplicado a",
      row: "linha",
      selected: "selecionada",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
  },
  report: {
    percentage: "Porcentagem",
    incorrectCalculation: "Cálculo incorreto",
    expensesTotal: "Total em despesas",
    resultsTotal: "Total em resultados",
    totalTransferred: "Total trasladado",
    bucketPeriodsError: "Bucket-períodos com erro",
    getPercentages: {
      loading: "Obtendo dados de períodos...",
      error: "Ocorreu um erro ao obter os dados de períodos, tente novamente.",
    },
    getKpis: {
      loading: "Obtendo KPIs...",
      error: "Ocorreu um erro ao obter os KPIs, tente novamente.",
    },
  },
  reportTexts: {
    graphicsTab: {
      failedDownload: "Não foi possível baixar informações",
      downloadingIndicators: "Baixando indicadores...",
      resultsIndicators: "INDICADORES DE RESULTADOS",
      period: "Período",
    },
    preprorrationErrorsTable: {
      failedDownload: "Não foi possível baixar informações",
      preProrrated: "PREPRORRATEO",
    },
    preprorrationReportTab: {
      tabs: {
        transferredPercentage: "PORCENTAGEM TRASLADADA",
        destinationsByGroup: "DESTINOS POR GRUPO",
      },
    },
    preprorrationSumTable: {
      failedDownload: "Não foi possível baixar informações",
      preProrrated: "PREPRORRATEO",
    },
    transformDataForSumTable: {
      columns: {
        group: "GRUPO",
        destination: "DESTINO",
        period: "PERÍODO",
        amountExpense: "MONTO_DESPESA",
        destinationType: "TIPO_DESTINO",
        percentage: "PORCENTAGEM",
        grouper: "agrupador",
        transferred: "trasladado",
        amountExpenseResult: "MONTO_DESPESA_RESULTADO",
        amountExpenseOrigin: "MONTO_DESPESA_ORIGEM",
        relativeError: "ERRO_RELATIVO",
        correctCalculation: "CÁLCULO_CORRETO",
      },
      coin: "moeda",
    },
    prorrationDifTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationErrorTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationIterationTable: {
      failedDownload: "Não foi possível baixar informações",
      apportionment: "PRORRATEO",
    },
    prorrationReportTab: {
      tabs: {
        percentageTransfer: "PORCENTAGEM DE TRASLADO",
        notTransferredExpenses: "DESPESAS NÃO TRASLADADAS",
        transferredPerIterationExpenses: "DESPESAS TRASLADADAS POR ITERAÇÃO",
      },
    },
    transformDataForDifTable: {
      columns: {
        period: "período",
        bucketExpense: "bucket-despesa",
        grouper: "agrupador",
        mount: "montante",
        result: "resultado",
        difference: "diferença",
        transferred: "trasladado",
      },
    },
    transformDataForErrorsTable: {
      destination1: "DESTINO_1",
      destination: "DESTINO",
      expenseMount: "MONTO_DESPESA",
      coin: "moeda",
    },
    getColumnsForErrorsTable: {
      columns: {
        bucket: "BUCKET",
        destination: "DESTINO",
        stage: "ETAPA",
        period: "PERÍODO",
      },
    },
    transformDataForIterationTable: {
      columns: {
        solved: "SOLUCIONADO",
        expenseMount: "MONTO_DESPESA",
        coin: "moeda",
      },
    },
    getColumnsForIterationTable: {
      columns: {
        mount: "MONTO",
        grouper: "AGRUPADOR",
        channel: "CANAL",
        period: "PERÍODO",
        stage: "ETAPA",
        bucket: "BUCKET",
        iteration: "ITERAÇÃO",
      },
    },
    calculationReport: {
      tabs: {
        graphics: "GRÁFICOS",
        approrateReport: "REPORTE DE PRORRATEO",
        preProrateReport: "REPORTE DE PREPRORRATEO",
      },
      calculationReport: "Reporte de cálculo",
    },
    kpi: {
      coin: "moeda",
      units: "unidades",
      totalInExpenses: "Total em despesas",
      totalInResults: "Total em resultados",
      totalTransferred: "Total trasladado",
      bucketPeriodsError: "Bucket-períodos com erro",
    },
    kpiError: {
      incorrectCalculation: "Cálculo incorreto",
    },
    periodChart: {
      periods: "Períodos",
      percentageExchange: "% de cambio",
      percentage: "porcentaje",
    },
  },
  simulator: {
    simulationAdmin: {
      simulationAdmin: "Administração de Simulação",
      searchByName: "Pesquisar por nome",
      noSimulationsYet: "Nenhuma simulações ainda disponível.",
    },
    simulationCard: {
      analysisToUse: "Análise a ser usada:",
      measures: "Medidas:",
    },
  },
  stagesSettings: {
    withoutStages: "Existem agrupadores sem etapas atribuídas.",
    noFiles: "Não há arquivos carregados.",
  },
  tablesTools: {
    errorRows: "Ocorreu um erro e não foi possível carregar os dados.",
    noRows: "Não há dados.",
    transformDataSimpleTable: {
      message: "Mensagem",
    },
  },
  tabs: {
    preproration: "PREPRORRATAÇÃO",
    proration: "PRORRATAÇÃO",
    downloads: "DOWNLOADS",
    datamodel: "DATAMODEL",
  },
  taskManager: {
    loadingCancelTask: "Cancelando tarefa...",
    loadingDownloadFile: "Baixando...",
    error: "Ocorreu um erro e não foi possível baixar as informações.",
    errorDetail: "Detalhes do erro",
    noDetailYet: "Ainda não temos informações detalhadas disponíveis...",
    cancelTitle: "Cancelar Tarefa",
    cancelThisTask: "Cancelar esta tarefa",
    saveViewButtonTooltip:
      "Salva a configuração de largura e visibilidade de colunas para este usuário.",
    getTasks: {
      error: "Ocorreu um erro e não foi possível obter as tarefas",
    },
    getDownloads: {
      error: "Ocorreu um erro e não foi possível obter os downloads",
    },
    cancelTask: {
      codes: {
        200: "A tarefa foi cancelada com sucesso",
        201: "A tarefa foi cancelada com sucesso",
        204: "A tarefa foi cancelada com sucesso",
        400: "Ocorreu um erro e não foi possível cancelar a tarefa",
        500: "Ocorreu um erro e não foi possível cancelar a tarefa",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Os detalhes da tarefa foram obtidos",
        400: "Ocorreu um erro e não foi possível obter os detalhes da tarefa",
      },
      loading: "Obtendo detalhes da tarefa...",
    },
    states: {
      pending: "Pendente",
      running: "Rodando",
      finishedWithErrors: "Finalizada com erros",
      finishedSuccessfully: "Finalizada com sucesso",
      error: "Erro ao calcular",
      downloadError: "Erro ao baixar",
      canceled: "Cancelada",
    },
    actions: {
      viewDetail: "Ver detalhe",
      notViewYet: "Ainda não há detalhes",
      cancel: "Cancelar",
      download: "Baixar",
    },
  },
  titles: {
    taskManager: "Gerenciador de Tarefas",
  },
  validations: {
    validationsAdmin: {
      title: "Administração de Validações",
      tabs: {
        codeMatching: "COINCIDÊNCIA DE CÓDIGOS",
      },
      notCodeMatchingYet: "Ainda não há coincidência de códigos.",
      getCodeMatching: {
        error:
          "Ocorreu um erro e não foi possível obter a coincidência de códigos",
        loading: "Obtendo coincidência de códigos...",
      },
      codeMatchingCard: {
        file1: "Arquivo 1:",
        file2: "Arquivo 2:",
        matchIndicator: "Indicador:",
        completeMatchIndicator: "Indicador de coincidência:",
      },
      viewInfoModal: {
        title: "Informações Gerais de Validação",
      },
      deleteValidation: {
        codes: {
          200: "Validação excluída com sucesso",
          400: "Ocorreu um erro e a validação não pôde ser excluída",
        },
        loading: "Excluindo validação...",
        confirmationModal: {
          title: "Excluir validação",
          message: "Você confirma que deseja excluir a validação?",
        },
      },
    },
    configValidations: {
      subtitleColumns: "Colunas",
      titleIndicatorMatchSelect: "Selecionar indicador de coincidência",
      matchName: "Nome de coincidência de Códigos",
      errorEmptyName: "O nome não pode estar vazio",
      errorDuplicatedName: "O nome já existe",
      textIndicatorMatchSelect: {
        percentaje: "% de códigos de",
        in: "em",
      },
      placeholderIndicatorMatchSelect: "Selecionar indicador de cruzamento...",
      codes: {
        200: "Validação salva com sucesso.",
        201: "Validação salva com sucesso.",
        400: "Ocorreu um erro e não foi possível salvar a validação.",
      },
      calculate: {
        codes: {
          200: "O cálculo foi iniciado com sucesso",
          201: "O cálculo foi iniciado com sucesso",
          400: "Ocorreu um erro e não foi possível iniciar o cálculo",
        },
      },
      loading: "Salvando validação...",
      calculating: "Calculando...",
      confirmationModal: {
        title: "Salvar validação",
        message: "Você confirma que deseja salvar a validação?",
      },
      notEqualColumnsLength:
        "O número de colunas selecionadas deve ser igual para ambos os arquivos.",
      getValidation: {
        codes: {
          200: "Validação obtida com sucesso",
          400: "Ocorreu um erro e não foi possível obter a validação",
        },
        loading: "Obtendo validação...",
      },
    },
    matchCodeResults: {
      getValidations: {
        error: "Ocorreu um erro e não foi possível obter as validações",
      },
      getVennModalInfo: {
        codes: {
          200: "Informações obtidas com sucesso",
          400: "Ocorreu um erro e não foi possível obter as informações",
        },
        loading: "Obtendo informações...",
      },
    },
  },
  verificationCode: {
    codes: {
      200: "O código está correto",
      400: "O código está incorreto",
      500: "Ocorreu um erro e não foi possível enviar o código, tente novamente",
    },
    loading: "Enviando o código de autenticação...",
    resend: {
      codes: {
        200: "O código foi reenviado com sucesso",
        400: "O código não pôde ser reenviado",
        500: "Ocorreu um erro e não foi possível reenviar o código, tente novamente",
      },
    },
  },
  virtualizedTable: {
    saveViewButton: "Salvar visualização",
    transformData: {
      appliedSchema: "ESQUEMA_APLICADO",
      totalExpense: "GASTO_TOTAL",
    },
    toolbars: {
      columns: "Colunas",
      selectColumns: "Selecionar colunas",
      findColumn: "Encontrar coluna",
      columnTitle: "Título da coluna",
      reorderColum: "Reordenar coluna",
      colunasPanelShowAllButton: "Mostrar tudo",
      colunasPanelHideAllButton: "Ocultar tudo",
      filters: "filtros",
      filterPlaceHolder: "Filtrar valores",
      addFilter: "Adicionar filtro",
      filterHide: "Ocultar filtros",
      filterShow: "Mostrar filtros",
      filterOperator: "Operador",
      andFilter: "E",
      orFilter: "Ou",
      filterInputLabel: "Valor",
      density: "densidade",
      compact: "Compacto",
      standard: "Padrão",
      comfortable: "Confortável",
      export: "exportar",
      print: "Imprimir",
      asCSV: "Baixar como CSV",
    },
  },
  unauthorized: {
    title: "Não autorizado",
    message: "Você não tem permissão para acessar esta página",
    goBack: "Voltar para o início",
  },
  useLogout: {
    errorLogout: "Erro ao sair",
  },
};
