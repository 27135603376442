import ComponentLayout from "../layout/ComponentLayout";
import LanIcon from "@mui/icons-material/Lan";
import { COPY_FLOW_URL, DELETE_FLOW_URL, FLOW_URL } from "../../api/axios";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import FixedAddButton from "../buttons/FixedAddButton";
import { useNavigate } from "react-router-dom";
import SearchInput from "../forms/SearchInput";
import { useContext, useEffect, useState } from "react";
import BasicGrid from "../basicGrid/BasicGrid";
import {
  ConfirmationModal,
  ConfirmationModal as DeleteCriteriaConfirmationModal,
} from "../dialog";
import useDialog from "../../hooks/useDialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useApiQuery } from "../../hooks/useApiQuery";
import useApi from "../../hooks/useApi";
import Stack from "@mui/material/Stack";
import FlowCard from "./FlowCard";
import { filterDataByName } from "../pipelines/utils";
import FlowsProvider from "../../context/FlowsContext";
import FormSelectLayout from "../forms/FormSelectLayout";
import TextField from "@mui/material/TextField";

const FlowAdmin = () => {
  const navigate = useNavigate();
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [flowToDelete, setFlowToDelete] = useState<number>();
  const [flowToCopy, setFlowToCopy] = useState<number>();
  const [openCopyConfirmationModal, toggleCopyConfirmationModal] = useDialog();
  const [newFlowName, setNewFlowName] = useState<string>("");
  const [
    openDeleteCriteriaConfirmationModal,
    handleToggleDeleteCriteriaConfirmationModal,
  ] = useDialog();
  const {
    isLoading: loadingFlows,
    data: dataFlows,
    refetch: refetchFlows,
  } = useApiQuery(FLOW_URL, true, texts.flows.getFlows.error);

  useEffect(() => {  
    if (
      window.location.pathname !== "/administracion-flujos/configuracion-flujos"
    ) {
      localStorage.removeItem("flowId");
    }
  }, []);

  const goToConfigFlow = () => {
    localStorage.removeItem("flowId");
    localStorage.removeItem("configMode");
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: {
        nombre: "",
        id: "",
        steppers: [],
      },
    });
    navigate("/administracion-flujos/configuracion-flujos");
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataFlows, e.target.value, setFilteredData);
  };

  const handleEdit = (e: any, id: string) => {
    localStorage.setItem("flowId", id);
    navigate(`/administracion-flujos/configuracion-flujos`);
  };

  const onSuccessPostFlowToCopy = (data: any) => {
    localStorage.setItem("flowId", data.id);
    navigate(`/administracion-flujos/configuracion-flujos`);
  };

  const { isLoading: isLoadingPostFlowToCopy, callApi: postFlowToCopy } =
    useApi(
      COPY_FLOW_URL(flowToCopy),
      "POST",
      texts.flows.postFlowName.codes,
      undefined,
      onSuccessPostFlowToCopy
    );

  const handleAcceptCopyConfirmationModal = () => {
    toggleCopyConfirmationModal();
    postFlowToCopy(COPY_FLOW_URL(flowToCopy), {
      nombre_flow: newFlowName,
    });
  };

  const handleCopy = (e: any, id: number | undefined) => {
    //Se manda un post para copiar el flow con este id que se recibe, y el back me devuelve un id de flujo nuevo, y con este nuevo id, en el onSuccessCopy, se debe navegar a la configuracion del flujo
    setFlowToCopy(id);
    toggleCopyConfirmationModal();
  };

  const { isLoading: isLoadingDeleteFlow, callApi: deleteFlowById } = useApi(
    DELETE_FLOW_URL(flowToDelete),
    "DELETE",
    texts.flows.deleteFlow.codes,
    undefined,
    refetchFlows
  );

  const handleDelete = () => {
    deleteFlowById();
    handleToggleDeleteCriteriaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setFlowToDelete(id);
    handleToggleDeleteCriteriaConfirmationModal();
  };


  return (
    <ComponentLayout title="Administración de flujos" icon={<LanIcon />}>
      <SimpleBackdrop open={loadingFlows} message="Buscando flujos..." />
      
      <SimpleBackdrop
        open={isLoadingDeleteFlow}
        message={texts.flows.deleteFlow.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostFlowToCopy}
        message={texts.flows.postFlowToCopy.loading}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder="Búsqueda por nombre"
        />
      </Stack>
      {filteredData?.length || dataFlows?.length ? (
        <BasicGrid
          data={filteredData ? filteredData : dataFlows}
          Card={FlowCard}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          /* handleInfo={handleInfo} */
          handleDelete={handleClickOnDelete}
          showInfo={true}
        />
      ) : (
        !loadingFlows && (
          <Box
            sx={{
              width: "95%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
            >
              Aún no hay flujos disponibles.
            </Typography>
          </Box>
        )
      )}
      <FixedAddButton handleClick={goToConfigFlow} />
      <DeleteCriteriaConfirmationModal
        open={openDeleteCriteriaConfirmationModal}
        handleClose={handleToggleDeleteCriteriaConfirmationModal}
        handleAccept={handleDelete}
        message="eliminar el flujo"
        title="Confirmar"
      />
      <ConfirmationModal
        open={openCopyConfirmationModal}
        handleClose={toggleCopyConfirmationModal}
        handleAccept={handleAcceptCopyConfirmationModal}
        message="copiar el flujo"
        title="Confirmar"
        disabledAcceptButton={
          (dataFlows &&
            dataFlows?.find((flow: any) => flow.nombre === newFlowName) &&
            true) ||
          newFlowName === ""
        }
      >
        <FormSelectLayout title="Nombre del nuevo flujo" required={true}>
          <TextField
            type="text"
            placeholder={"Nuevo nombre"}
            variant="outlined"
            fullWidth
            value={newFlowName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewFlowName(event.target.value);
            }}
            error={
              dataFlows &&
              !!dataFlows?.find(
                (flow: any) =>
                  flow.nombre.toLowerCase().trim() ===
                  newFlowName.toLowerCase().trim()
              )
            }
            helperText={
              dataFlows &&
              dataFlows?.find(
                (flow: any) =>
                  flow.nombre.toLowerCase().trim() ===
                  newFlowName.toLowerCase().trim()
              ) &&
              "Ya existe un flujo con ese nombre"
            }
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
                margin: 0,
              },
            }}
          />
        </FormSelectLayout>
      </ConfirmationModal>
    </ComponentLayout>
  );
};

export default FlowAdmin;
