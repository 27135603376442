import { useContext, useEffect, useRef, useState } from "react";
import FlowsProvider from "../../../context/FlowsContext";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
  VIEW_RESULTS_URL,
} from "../../../api/axios";
import {
  searchStepper,
  findBeforeElementPosition,
  filterIdFromColumns,
  checkIfAllColumnNotExists,
  checkIfColumnNotExists,
} from "../utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import SimpleTable from "../../tablesTools/SimpleTable";
import BaseModal from "./BaseModal";
import useDialog from "../../../hooks/useDialog";
import {
  form_label,
  modal_typography_title_styles,
} from "../../../styles/app-styles";
import Select from "../../forms/Select";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { selectStyles } from "../../../styles/select.styles";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
interface DeleteRowProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const DeleteRow = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: DeleteRowProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemToEdit } = flowState;
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [inputValueRangeTo, setInputValueRangeTo] = useState<
    number | string | undefined
  >(undefined);
  const [inputValueRangeFrom, setInputValueRangeFrom] = useState<
    number | string | undefined
  >(undefined);

  const [disabledRangeFrom, setDisabledRangeFrom] = useState(false);
  const [disabledRangeTo, setDisabledRangeTo] = useState(false);
  const [tableDataState, setTableDataState] = useState<any>(undefined);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    dataItem ? (dataItem.tipo !== "rangos" ? dataItem.columnas : []) : []
  );
  const [hasCalledGetViewResults, setHasCalledGetViewResults] = useState(false);

  const deleteTypeRef: any = useRef(null);
  const ocurrenceTypeRef: any = useRef(null);


  const [
    deleteTypeSelectedOption,
    setDeleteTypeSelectedOption,
    deleteTypeChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    undefined
  );

  const [
    ocurrenceSelectedOption,
    setOcurrenceSelectedOption,
    ocurrenceChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open
      ? dataItem.tipo === "duplicados"
        ? {
            value: dataItem.ocurrencia,
            label: `Mantener ${dataItem.ocurrencia} ocurrencia`,
          }
        : undefined
      : { value: "primera", label: "Mantener primera ocurrencia" }
  );

  const deleteOptions = [
    { value: "duplicados", label: "Eliminar filas duplicadas" },
    { value: "rangos", label: "Eliminar filas por rango" },
    { value: "nulos", label: "Eliminar filas con valores nulos" },
  ];

  const ocurrenceOptions = [
    { value: "primera", label: "Mantener primera ocurrencia" },
    { value: "ultima", label: "Mantener última ocurrencia" },
  ];

  /********************************* CARGA DE DATOS ***********************************/

  const handleDataItemUpdate = (data: any) => {
    if(data.tipo === "duplicados"){
      setDeleteTypeSelectedOption(deleteOptions[0]);
      setSelectedColumns(data.columnas?.map((item: any)=>{return {id:item.value, label: item.label}}));
      setOcurrenceSelectedOption({
        value: data.ocurrencia,
        label: `Mantener ${data.ocurrencia} ocurrencia`,
      })
    }
    if(data.tipo === "rangos"){
      setDeleteTypeSelectedOption(deleteOptions[1]);
      data?.valores.forEach((item: any) => {
        if (item.value === "valor_rango_inicial") {
          if (item.label === null) {
            setDisabledRangeFrom(false);
          } else {
            setInputValueRangeFrom(item.label);
            setDisabledRangeFrom(true);
          }
        } else if (item.value === "valor_rango_final") {
          if (item.label === null) {
            setDisabledRangeTo(true);
          } else {
            setInputValueRangeTo(item.label.toString());
            setDisabledRangeTo(true);
          }
        }
      });
    }
    if(data.tipo === "nulos"){
      setDeleteTypeSelectedOption(deleteOptions[2]);
      setSelectedColumns(data.columnas?.map((item: any)=>{return {id: item.value, label: item.label}}));
    }
  };

  const onSuccesGetData = (data: any) => {
    setTableDataState(data);
  };

  useEffect(() => {
    if (dataItem && open) {
      handleDataItemUpdate(dataItem.data);
    }
  }, [dataItem]);

  useEffect(() => {
    if (open && !hasCalledGetViewResults) {
      setHasCalledGetViewResults(true);
      getTableData();
      setTimeout(() => {
        setDelayHelper(true);
      }, 10);
      getColumns();
    }
  }, [open]);

  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
  } = useApi(
    VIEW_RESULTS_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem && itemToEdit?.id
      )
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );
  /********************************* VALIDACIÓN DE DATOS ******************************/

  const isInvalidRange = () => {
    if (inputValueRangeTo && inputValueRangeFrom) {
      const rangeTo = parseInt(String(inputValueRangeTo));
      const rangeFrom = parseInt(String(inputValueRangeFrom));
      return rangeTo < rangeFrom;
    }
    return false;
  };

  const handleChangeDisabledFrom = () => {
    setDisabledRangeFrom(!disabledRangeFrom);
  };

  useEffect(() => {
    !disabledRangeFrom && setInputValueRangeFrom("");
    !disabledRangeTo && setInputValueRangeTo("");
  }, [disabledRangeFrom, disabledRangeTo]);

  const handleChangeDisabledTo = () => {
    setDisabledRangeTo(!disabledRangeTo);
  };

  const validateAccept = () => {
    if (deleteTypeSelectedOption?.value === "rangos") {
      if (
        (inputValueRangeFrom === "" && inputValueRangeTo === "") ||
        (inputValueRangeFrom === undefined && inputValueRangeTo === undefined)
      ) {
        return true;
      }
      if (
        ((inputValueRangeFrom === "0" ||
          inputValueRangeFrom === undefined ||
          inputValueRangeFrom === "") &&
          tableData &&
          parseInt(inputValueRangeTo as string) >=
            tableData[tableData.length - 1].id) ||
        (tableData &&
          parseInt(inputValueRangeTo as string) >
            tableData[tableData.length - 1].id)
      ) {
        return true;
      }
      if (disabledRangeFrom) {
        if (inputValueRangeFrom === undefined) {
          return true;
        }
        if (inputValueRangeFrom === "") {
          return true;
        }
      }
      if (disabledRangeTo) {
        if (inputValueRangeTo === undefined) {
          return true;
        }
        if (inputValueRangeTo === "") {
          return true;
        }
      }
      return isInvalidRange();
    }
    if (deleteTypeSelectedOption?.value === "duplicados") {
      return selectedColumns?.length === 0 || (ocurrenceSelectedOption === undefined || (selectedColumns && dataColumns && checkIfAllColumnNotExists(selectedColumns, dataColumns)))
    }
    if (deleteTypeSelectedOption?.value === "nulos") {
      return selectedColumns?.length === 0 || (selectedColumns && dataColumns && checkIfAllColumnNotExists(selectedColumns, dataColumns))
    }
    return false;
  };

  /******************************* POSTEAR ITEM *********************************************/

  const saveDeleteRows = () => {
    const completedInfo = getBody();
    toggleConfirmationModal();
    if (dataItem) {
      putItem(
        ITEM_URL(data_flow.id, active_stepper, itemToEdit.id),
        completedInfo
      );
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), completedInfo);
    }
    cleanAndClose();
  };

  const getBody = () => {
    switch (deleteTypeSelectedOption?.value) {
      case "duplicados":
        return {
          tipo: "eliminar_filas",
          tipo_eliminacion: {
            tipo: "duplicados",
            ocurrencia: ocurrenceSelectedOption?.value,
            columnas: selectedColumns.filter((col: any)=> col.label !== "Seleccionar todas las columnas" && !checkIfColumnNotExists(col.label, dataColumns.map((col: any)=>{return {label: col.columna}}))).map((col: any) => {
              return col.label;
            }),
          },
        };
      case "rangos":
        return {
          tipo: "eliminar_filas",
          tipo_eliminacion: {
            tipo: "rangos",
            valor_rango_inicial:
              inputValueRangeFrom != "" ? inputValueRangeFrom : undefined,
            valor_rango_final:
              inputValueRangeTo != "" ? inputValueRangeTo : undefined,
          },
        };
      case "nulos":
        return {
          tipo: "eliminar_filas",
          tipo_eliminacion: {
            tipo: "nulos",
            columnas: selectedColumns.filter((col: any)=> col.label !== "Seleccionar todas las columnas" && !checkIfColumnNotExists(col.label, dataColumns.map((col: any)=>{return {label: col.columna}}))).map((col: any) => {
              return col.label;
            }),
          },
        };
      default:
        break;
    }
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setInputValueRangeFrom(undefined);
    setInputValueRangeTo(undefined);
    setDisabledRangeFrom(false);
    setDisabledRangeTo(false);
    setTableDataState(undefined);
    setDeleteTypeSelectedOption(undefined);
    setHasCalledGetViewResults(false);
    setSelectedColumns([]);
    setDelayHelper(false);
    setOcurrenceSelectedOption({
      value: "primera",
      label: "Mantener primera ocurrencia",
    });
    handleClose();
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

  return (
    <BaseModal
      open={open}
      title="Eliminar Filas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveDeleteRows}
      confirmationModalMessage={"eliminar las filas"}
      disabledAcceptButton={validateAccept()}
      width="1300px"
    >
      <SimpleBackdrop
        open={isLoadingTableData}
        message={texts.flows.getFilterData.loading}
      />
      <Stack
        justifyContent={"space-between"}
        sx={{
          height: "480px",
        }}
      >
        <Stack mb="10px">
          {delayHelper && (
            <Box sx={{ width: "100%", marginBottom: "8px" }}>
              <InputLabel sx={form_label}>Eliminar filas</InputLabel>
              <Select
                reference={deleteTypeRef}
                styles={selectStyles(deleteTypeSelectedOption)}
                options={deleteOptions}
                name="eliminar-filas"
                onChange={deleteTypeChangeHandler}
                closeMenuOnSelect
                placeholder="Selecciona una opción"
                isClearable
                isDisabled={false}
                defaultValue={deleteTypeSelectedOption}
              />
            </Box>
          )}
        </Stack>
        {deleteTypeSelectedOption?.value === "rangos" && (
          <>
            <Stack direction={"row"} gap={8}>
              <Stack
                direction="row"
                sx={{
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label=""
                  checked={disabledRangeFrom}
                  onChange={handleChangeDisabledFrom}
                />
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 0,
                      m: 0,
                      color: "var(--blue)",
                    }}
                  >
                    Desde fila nº... (por defecto es 0)
                  </Typography>
                  <TextField
                    type="number"
                    id="rangefromRows"
                    label={""}
                    placeholder={"Desde fila nº..."}
                    size="small"
                    multiline
                    variant="outlined"
                    value={inputValueRangeFrom}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const regex = /^[0-9\b]+$/;
                      if (
                        event.target.value === "" ||
                        regex.test(event.target.value)
                      ) {
                        setInputValueRangeFrom(event.target.value);
                      }
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    disabled={!disabledRangeFrom}
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label=""
                  checked={disabledRangeTo}
                  onChange={handleChangeDisabledTo}
                />
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 0,
                      m: 0,
                      color: "var(--blue)",
                    }}
                  >
                    Hasta fila nº... (inclusive, por defecto es la última)
                  </Typography>

                  <TextField
                    type="number"
                    id="rangeToRows"
                    label={""}
                    placeholder={"Hasta fila nº..."}
                    size="small"
                    multiline
                    variant="outlined"
                    fullWidth
                    value={inputValueRangeTo === 0 ? "" : inputValueRangeTo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const regex = /^[0-9\b]+$/;
                      if (
                        event.target.value === "" ||
                        regex.test(event.target.value)
                      ) {
                        setInputValueRangeTo(event.target.value);
                      }
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    disabled={!disabledRangeTo}
                  />
                </Box>
              </Stack>
            </Stack>
            {isInvalidRange() && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "0.8rem",
                  lineHeight: "1.66",
                  color: "var(--magenta)",
                  textAlign: "center",
                  pt: "10px",
                  mb: "-10px",
                }}
              >
                Asegúrate de que el rango inicial no sea mayor al rango final.
              </Typography>
            )}
          </>
        )}
        {(deleteTypeSelectedOption?.value === "duplicados" ||
          deleteTypeSelectedOption?.value === "nulos") && (
          <Stack>
                {selectedColumns &&
          dataColumns &&
          selectedColumns.filter((col: any)=> col.id !== "todas").some((col: any) =>
            checkIfColumnNotExists(
              col.label,
              dataColumns.map((item: any) => {
                return { id: item.id, label: item.columna };
              })
            )
          ) &&     <Typography sx={{ color: "var(--red)", marginLeft: "10px", marginBottom:"10px" }}>
          Las columnas no existentes en la fuente actual no se tendrán en cuenta
          y se borrarán en pasos posteriores.
        </Typography>}
            <InputLabel sx={{ ...form_label, marginBottom: "8px" }}>
              {"Columnas"}
            </InputLabel>

            {delayHelper && (
              <CheckmarkSelect
                label="Seleccionar columnas"
                selectedItems={selectedColumns}
                setSelectedItems={setSelectedColumns}
                items={filterIdFromColumns(dataColumns)?.map((item: any) => {
                  return { id: item.id, label: item.columna };
                })}
                width="100%"
              />
            )}
          </Stack>
        )}
        {delayHelper && deleteTypeSelectedOption?.value === "duplicados" && (
          <Box sx={{ width: "100%", marginBottom: "8px", marginTop: "10px" }}>
            <InputLabel sx={form_label}>Mantener ocurrencia</InputLabel>
            <Select
              reference={ocurrenceTypeRef}
              styles={selectStyles(ocurrenceSelectedOption)}
              options={ocurrenceOptions}
              name="mantener-ocurrencia"
              onChange={ocurrenceChangeHandler}
              closeMenuOnSelect
              placeholder="Selecciona una opción"
              isClearable
              isDisabled={false}
              defaultValue={ocurrenceSelectedOption}
            />
          </Box>
        )}
        <Typography sx={modal_typography_title_styles}>
          Se muestran las primeras y las últimas 500 filas del archivo - No es
          posible eliminar todo.
        </Typography>
        {open === false ? (
          <Box sx={{ width: "100%" }}>
            <CustomSkeleton height={5} />
          </Box>
        ) : (
          tableDataState && (
            <SimpleTable
              columns={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).rows
              }
              toolbar={false}
            />
          )
        )}
      </Stack>
    </BaseModal>
  );
};

export default DeleteRow;
