import { useContext, useEffect, useState } from "react";
import PipelinesStepperProvider from "../../context/PipelinesStepperProvider";
import CustomStepper from "../stepper/CustomStepper";
import data from "./dataSteps.json";
import { Button } from "../buttons";
import Tooltip from "@mui/material/Tooltip";
import { Box, Stack } from "@mui/material";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import {
  formatNullValidations,
  formatPipelineToEditHeader,
  formatPipelineToEditHeaderRename,
  formatRowsToDelete,
  isFileUrlExtensionValidXlsCsvParquet,
  prepareRowsToDelete,
} from "./utils";
import Step3 from "./Step3";
import useApi from "../../hooks/useApi";
import {
  PIPELINE_BY_ID_URL,
  GET_PIPELINES_URL,
  POST_PIPELINE_URL,
} from "../../api/axios";
import { texts } from "../../texts.js";
import useDialog from "../../hooks/useDialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import SaveNameModal from "../dialog/SaveNameModal";
import ComponentLayout from "../layout/ComponentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useApiQuery } from "../../hooks/useApiQuery";
import { transformData } from "../virtualizedTable/transformData";

const ConfigPipelines = () => {
  const navigate = useNavigate();
  const { pipelineState, pipelineDispatch } = useContext(
    PipelinesStepperProvider
  );
  const {
    file,
    selected_sheet,
    header,
    step1Complete,
    pipeline_name,
    rows_to_delete,
    data: dataContext,
    validations,
    no_header_sheet,
    selectedColumns,
    undo_all,
    replacedFile,
  } = pipelineState;
  const [activeStep, setActiveStep] = useState(1);
  const [openSavePipelineModal, toggleSavePipelineModal] = useDialog();
  const pipelineId = localStorage.getItem("pipelineId")
    ? localStorage.getItem("pipelineId")
    : undefined;
  const editFile = localStorage.getItem("pipelineEditFile")
    ? localStorage.getItem("pipelineId")
    : undefined;

  const { data: dataPipelines } = useApiQuery(
    GET_PIPELINES_URL,
    true,
    texts.adminPipeline.getPipelines.error
  );

  const onSuccesGetPipeline = (data: any) => {
    if (editFile) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
    pipelineDispatch({
      type: "SET_STEP1_COMPLETE",
      payload: true,
    });
    pipelineDispatch({
      type: "SET_FILE_DATA",
      payload: {
        name: data?.archivo?.url,
        id: data?.archivo?.id,
      },
    });
    pipelineDispatch({
      type: "SET_SELECTED_SHEET",
      payload: {
        label: data.hoja?.label,
        value: data.hoja?.value,
      },
    });

    pipelineDispatch({
      type: "SET_NO_HEADER_SHEET",
      payload: data.sin_cabecera,
    });
    pipelineDispatch({
      type: "SET_PIPELINE_NAME",
      payload: data.nombre,
    });
    pipelineDispatch({
      type: "SET_SELECTED_COLUMNS",
      payload: data.lista_columnas_guardar
        ? data.lista_columnas_guardar.map((col: any) => {
            return { value: col, label: col };
          })
        : [],
    });
    pipelineDispatch({
      type: "SET_HEADER",
      payload: {
        id: data.indice_cabecera,
        data: formatPipelineToEditHeader(
          data.indice_cabecera,
          data.renombre_cabecera,
          data?.muestra
        ),
        renombre: formatPipelineToEditHeaderRename(data.renombre_cabecera),
      },
    });
    pipelineDispatch({
      type: "SET_ROWS_TO_DELETE",
      payload: {
        ...rows_to_delete,
        rows_to_delete_after_header_setting: data.criterio_eliminacion,
      },
    });
    pipelineDispatch({
      type: "SET_DATA",
      payload: data?.muestra,
    });
    pipelineDispatch({
      type: "SET_NEW_DATA",
      payload:
        data?.muestra_transformada?.length > 0
          ? data?.muestra_transformada
          : data?.muestra,
    });
    pipelineDispatch({
      type: "SET_ACTUAL_ROWS",
      payload:
        data?.muestra_transformada?.length > 0
          ? data?.muestra_transformada
          : data?.muestra,
    });
    if (data?.muestra_transformada?.length > 0) {
      const { columns: newColumns } = transformData([
        data?.muestra_transformada[0],
      ]);
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: newColumns,
      });
    } else {
      if (data?.muestra?.length > 0) {
        const { columns: newColumns } = transformData([data?.muestra[0]]);
        pipelineDispatch({
          type: "SET_ACTUAL_COLUMNS",
          payload: newColumns,
        });
      }
    }
  };

  const prepareValidations = (
    val_Empty_columns: any[],
    val_numeric_columns: string[]
  ) => {
    const transformedArray: any[] = [];

    // Iterar sobre validacion_columnas_vacias
    val_Empty_columns.forEach((item) => {
      const { columna, valor } = item;

      // Determinar el tipo de columna
      const columnType = val_numeric_columns.includes(columna)
        ? "Numérica"
        : "Texto";

      // Construir objeto transformado
      const transformedItem = {
        column: columna,
        columnType: columnType,
        noEmptyValues: {
          value: valor,
          label:
            valor === "permitir-vacios"
              ? "Permitir valores vacíos"
              : valor === "no-permitir-valores-vacios"
              ? "No permitir valores vacíos"
              : "No permitir columna vacía",
        },
      };

      // Agregar objeto al array transformado
      transformedArray.push(transformedItem);
    });

    return transformedArray;
  };

  const {
    isLoading: isLoadingGetPipeline,
    callApi: getPipeline,
    data: pipelineToEdit,
  } = useApi(
    undefined,
    "GET",
    texts.adminPipeline.getPipeline.codes,
    undefined,
    onSuccesGetPipeline,
    undefined,
    false
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRestart = (origin: "restart" | "sendPipeline") => {
    if (origin === "sendPipeline") {
      localStorage.removeItem("pipelineId");
      localStorage.removeItem("pipelineEditFile");
      pipelineDispatch({
        type: "SET_PIPELINE_NAME",
        payload: "",
      });
    }
    if (origin === "restart") {
      pipelineDispatch({
        type: "SET_UNDO_ALL",
        payload: true,
      });
    }
    setActiveStep(2);
    pipelineDispatch({
      type: "SET_NEW_DATA",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_HEADER",
      payload: {
        id: null,
        data: [],
        renombre: [],
      },
    });
    pipelineDispatch({
      type: "SET_ROWS_TO_DELETE",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_ACTUAL_COLUMNS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_ACTUAL_ROWS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_VALIDATIONS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_SELECTED_COLUMNS",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_REPLACED_FILE",
      payload: false,
    });
  };

  useEffect(() => {
    if (pipelineId) {
      getPipeline(PIPELINE_BY_ID_URL(parseInt(pipelineId)));
    }
  }, [pipelineId]);

  useEffect(() => {
    if (
      ((file.url && isFileUrlExtensionValidXlsCsvParquet(file.url)) ||
        (file.id && file.name)) &&
      (!pipelineToEdit ? selected_sheet.value && selected_sheet.label : true)
    ) {
      pipelineDispatch({
        type: "SET_STEP1_COMPLETE",
        payload: true,
      });
    } else {
      pipelineDispatch({
        type: "SET_STEP1_COMPLETE",
        payload: false,
      });
    }
  }, [file, selected_sheet.value, selected_sheet.label]);

  const onSuccessSendPipeline = () => {
    handleRestart("sendPipeline");
    navigate("/consolidacion-archivos/administracion-pipelines");
  };

  // **************************** Format Functions *********************************************

  const formatHeader = (rows: any) => {
    const result = [];

    for (const key in rows) {
      if (rows.hasOwnProperty(key)) {
        result.push({
          nombre_viejo: key,
          nombre_nuevo: rows[key],
        });
      }
    }

    return result;
  };

  const formatWithNoHeader = (rows: any) => {
    const result = [];

    for (const key in rows) {
      if (rows.hasOwnProperty(key) && key !== "id") {
        result.push({
          nombre_viejo: key,
          nombre_nuevo: key,
        });
      }
    }

    return result;
  };

  //Esta función la utilizamos para enviar en "renombre_cabecera", sólo las selectedcolumns
  const validateHeader = (header: any) => {
    return header.filter((column: any) => {
      return selectedColumns
        .map((col: any) => {
          return col.value;
        })
        .includes(column.nombre_nuevo);
    });
  };

  /* Esta función la utilizamos para enviar en "validacion_columnas_numericas" y "validacion_columnas_vacias", sólo las selectedcolumns*/
  const validateColumnsValidations = (
    validations: any,
    validationType: string
  ) => {
    return validations.filter((val: any) => {
      return validateHeader(
        header && header.renombre && header.renombre.length > 0
          ? formatHeader(header.renombre[0])
          : header && header.data && header.data.length > 0
          ? formatHeader(header.data[0])
          : formatWithNoHeader(dataContext[0])
      )
        .map((c: any) => {
          return c.nombre_nuevo;
        })
        .includes(validationType === "numeric" ? val : val.columna);
    });
  };

  const preparePipelineToSend = () => {
    const rowsToDelete = prepareRowsToDelete(
      rows_to_delete?.rows_to_delete_before_header_setting,
      rows_to_delete?.rows_to_delete_after_header_setting
    );
    return {
      nombre: pipeline_name,
      archivo: file.id,
      hoja: selected_sheet.value,
      indice_cabecera: header.id != null ? parseInt(header.id) : undefined,
      sin_cabecera: no_header_sheet,
      renombre_cabecera: validateHeader(
        header && header.renombre && header.renombre.length > 0
          ? formatHeader(header.renombre[0])
          : []
      ),
      criterio_eliminacion:
        dataContext && dataContext.length > 0
          ? formatRowsToDelete(rowsToDelete, dataContext)
          : rowsToDelete,
      validacion_columnas_vacias: validateColumnsValidations(
        formatNullValidations(validations),
        "empty columns"
      ),
      validacion_columnas_numericas: validateColumnsValidations(
        validations
          .filter((val: any) => {
            return val.columnType === "Númerica";
          })
          .map((val: any) => {
            return val.column;
          }),
        "numeric"
      ),
      lista_columnas_guardar: selectedColumns.map((col: any) => {
        return col.value;
      }),
      deshacer_todo: pipelineToEdit ? undo_all : undefined,
    };
  };

  // ******************************************************************************************

  const { isLoading: isLoadingPostPipeline, callApi: postPipeline } = useApi(
    POST_PIPELINE_URL,
    "POST",
    texts.adminPipeline.sendPipeline.codes,
    preparePipelineToSend(),
    onSuccessSendPipeline,
    undefined,
    true
  );

  const { isLoading: isLoadingPutPipeline, callApi: putPipeline } = useApi(
    PIPELINE_BY_ID_URL(pipelineToEdit?.id),
    "PUT",
    texts.adminPipeline.sendPipeline.codes,
    preparePipelineToSend(),
    onSuccessSendPipeline,
    undefined,
    true
  );

  const confirmSendPipeline = () => {
    if (pipelineId === undefined) {
      postPipeline();
    } else {
      putPipeline();
    }
    toggleSavePipelineModal();
  };

  return (
    <ComponentLayout
      title="Configuración de pipelines"
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={() => {
            navigate("/consolidacion-archivos/administracion-pipelines");
            localStorage.removeItem("pipelineId");
            localStorage.removeItem("pipelineEditFile");
          }}
        />
      }
    >
      <Stack width="100%" height="94vh" alignItems="center">
        <CustomStepper activeStep={activeStep} data={data} />
        {activeStep === 1 && <Step1 pipelineToEdit={pipelineToEdit} />}
        {activeStep === 2 && <Step2 pipelineToEdit={pipelineToEdit} />}
        {activeStep === 3 && <Step3 pipelineToEdit={pipelineToEdit}/>}
        <SimpleBackdrop
          open={isLoadingPostPipeline || isLoadingPutPipeline}
          message={texts.adminPipeline.sendPipeline.loading}
        />
        <SimpleBackdrop
          open={isLoadingGetPipeline}
          message={texts.adminPipeline.getPipeline.loading}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Button
            title="Anterior"
            color="light-blue"
            type="button"
            onClick={handleBack}
            disabled={
              activeStep ===
              1 /*|| (activeStep === 2 && pipelineId !== undefined)*/
            }
          />
          <Tooltip title="Restablece el archivo a su estado original" arrow>
            <span>
              <Button
                title="Deshacer todo"
                color="grey"
                type="button"
                disabled={activeStep === 1}
                onClick={() => handleRestart("restart")}
              />
            </span>
          </Tooltip>

          <Button
            title="Finalizar"
            color="blue"
            type="button"
            onClick={toggleSavePipelineModal}
            disabled={
              (activeStep !== 3 && !replacedFile) ||
              selectedColumns.length === 0
            }
          />
          <Button
            title="Siguiente"
            color="light-blue"
            type="button"
            onClick={handleNext}
            disabled={
              !step1Complete ||
              activeStep === 3 ||
              (dataContext.length === 0 &&
                activeStep ===
                  2) /*|| (pipelineToEdit && (!pipelineToEdit.muestra ||!Array.isArray(pipelineToEdit?.muestra)) )*/
            }
          />
          <SaveNameModal
            open={openSavePipelineModal}
            handleClose={toggleSavePipelineModal}
            handleAccept={confirmSendPipeline}
            title={"Guardar pipeline"}
            text={"¿Confirma que desea guardar el pipeline?"}
            label={"Ingrese el nombre del pipeline"}
            placeholder={"Escribir nombre del pipeline"}
            dispatchFunction={pipelineDispatch}
            dispatchType={"SET_PIPELINE_NAME"}
            valueState={pipeline_name}
            namesList={
              pipelineId !== undefined && dataPipelines
                ? dataPipelines?.filter(
                    (pipeline: any) =>
                      pipeline.nombre !== pipelineToEdit?.nombre
                  )
                : dataPipelines
            }
          />
        </Box>
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigPipelines;
