import { createContext, useReducer } from "react";
import { SchemaAction, SchemaState } from "./types";
import { typeOptions } from "../components/schemas/constants";

const SchemasContext = createContext<any>({});

export const SchemasProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialSchemaState: SchemaState = {
    file: {
      name: "",
      id: 0,
      url: "",
    },
    allPipelines: [],
    pipelinesRestList: [],
    sheetsList: [],
    sheetsRestList: [],
    filterTypeRestList: typeOptions,
    schema: [],
    schemaName: "",
    step1Complete: false,
    message: "",
    pipelineSelectedOption: undefined
  };

  function schemaReducer(schemaState: SchemaState, action: any) {
    switch (action.type) {
      case "SET_URL":
        return {
          ...schemaState,
          file: { ...schemaState.file, url: action.payload },
        };
      case "SET_FILE_DATA":
        return {
          ...schemaState,
          file: {
            ...schemaState.file,
            name: action.payload.name,
            id: action.payload.id,
            url: action.payload.url
          },
        };
      case "SET_PIPELINES_REST_LIST":
        return { ...schemaState, pipelinesRestList: action.payload };
        case "SET_ALL_PIPELINES":
          return { ...schemaState, allPipelines: action.payload };
      case "SET_SHEETS_LIST":
        return { ...schemaState, sheetsList: action.payload };
      case "SET_SHEETS_REST_LIST":
        return { ...schemaState, sheetsRestList: action.payload };
      case "SET_FILTER_TYPE_REST_LIST":
        return { ...schemaState, filterTypeRestList: action.payload };
      case "SET_SCHEMA":
        return {
          ...schemaState,
          schema: action.payload,
        };
      case "SET_SCHEMA_NAME":
        return {
          ...schemaState,
          schemaName: action.payload,
        };
      case "SET_STEP1_COMPLETE":
        return {
          ...schemaState,
          step1Complete: action.payload,
        };
      case "SET_MESSAGE":
        return {
          ...schemaState,
          message: action.payload,
        };
        case "SET_PIPELINE_SELECTED_OPTION":
          return {
            ...schemaState,
            pipelineSelectedOption: action.payload,
          };
      default:
        throw new Error();
    }
  }

  const [schemaState, schemaDispatch] = useReducer<
    React.Reducer<SchemaState, SchemaAction>
  >(schemaReducer, initialSchemaState);

  return (
    <SchemasContext.Provider
      value={{
        initialSchemaState,
        schemaReducer,
        schemaState,
        schemaDispatch,
      }}
    >
      {children}
    </SchemasContext.Provider>
  );
};

export default SchemasContext;
