import { useContext, useEffect, useRef, useState } from "react";
import BaseModal from "./BaseModal";
import useApi from "../../../hooks/useApi";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import FlowsProvider from "../../../context/FlowsContext";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import { InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import ShadowBox from "./ShadowBox";
import { form_label } from "../../../styles/app-styles";
import {
  checkIfColumnNotExists,
  checkIfAllColumnNotExists,
  filterIdFromColumns,
  findBeforeElementPosition,
  isColumnNameRepeated,
  isColumnNameValid,
  searchStepper,
} from "../utils";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
import InfoIcon from "@mui/icons-material/Info";

interface DesdynamizeRowsProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url: string, body: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const DesdynamizeRows = ({
  open,
  handleClose,
  postItem,
  dataItem,
  putItem,
}: DesdynamizeRowsProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  const [selectedIndexColumns, setSelectedIndexColumns] = useState<number[]>(
    []
  );
  const [selectedDesdynamizeColumns, setSelectedDesdynamizeColumns] = useState<
    number[]
  >([]);
  const [categoricColumnHeader, setCategoricColumnHeader] = useState("");
  const [numericColumnHeader, setNumericColumnHeader] = useState("");

  /*********************************** CARGA DE DATOS ****************************************/
  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      getColumns();
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      setCategoricColumnHeader(dataItem.encabezado_categoria);
      setNumericColumnHeader(dataItem.encabezado_valor);
      setSelectedIndexColumns(
        dataItem.columnas_indice?.map((item: any) => {
          return { id: item.id, label: item.columna };
        })
      );
      setSelectedDesdynamizeColumns(
        dataItem.columnas_nuevas?.map((item: any) => {
          return { id: item.id, label: item.columna };
        })
      );
    }
  }, [dataItem]);

  /******************************* POSTEAR ITEM *********************************************/

  const handleConfirmDynamizeRows = () => {
    toggleConfirmationModal();
    if (dataItem) {
      putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
        tipo: "desdinamizar",
        columnas_indice: selectedIndexColumns
          .filter((col: any) => {
            return col.id !== "todas" && !checkIfColumnNotExists(col.label, dataColumns.map((col: any)=>{return {label: col.columna}}));
          })
          .map((col: any) => {
            return col.label;
          }),
        columnas_nuevas: selectedDesdynamizeColumns
          .filter((col: any) => {
            return col.id !== "todas" && !checkIfColumnNotExists(col.label, dataColumns.map((col: any)=>{return {label: col.columna}}));
          })
          .map((col: any) => {
            return col.label;
          }),
        encabezado_categoria: categoricColumnHeader,
        encabezado_valor: numericColumnHeader,
      });
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), {
        tipo: "desdinamizar",
        columnas_indice: selectedIndexColumns
          .filter((col: any) => {
            return col.id !== "todas";
          })
          .map((col: any) => {
            return col.label;
          }),
        columnas_nuevas: selectedDesdynamizeColumns
          .filter((col: any) => {
            return col.id !== "todas";
          })
          .map((col: any) => {
            return col.label;
          }),
        encabezado_categoria: categoricColumnHeader,
        encabezado_valor: numericColumnHeader,
      });
    }
    cleanAndClose();
  };

  const isFormCompleted = () => {
    return (
      !selectedIndexColumns ||
      selectedIndexColumns.length < 1 ||
      !selectedDesdynamizeColumns ||
      selectedDesdynamizeColumns.length < 1 ||
      categoricColumnHeader.length < 1 ||
      numericColumnHeader.length < 1 ||
      selectedIndexColumns.some((colIndex: any) => {
        return selectedDesdynamizeColumns.some((colDes: any) => {
          return colIndex.label === colDes.label;
        });
      }) ||
      !isColumnNameValid(categoricColumnHeader) ||
      isColumnNameRepeated(categoricColumnHeader, dataColumns) ||
      !isColumnNameValid(numericColumnHeader) ||
      isColumnNameRepeated(numericColumnHeader, dataColumns) ||
      categoricColumnHeader === numericColumnHeader
    ) || (selectedIndexColumns && dataColumns && checkIfAllColumnNotExists(selectedIndexColumns, dataColumns)) || (selectedDesdynamizeColumns && dataColumns && checkIfAllColumnNotExists(selectedDesdynamizeColumns, dataColumns))
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setSelectedIndexColumns([]);
    setSelectedDesdynamizeColumns([]);
    setCategoricColumnHeader("");
    setNumericColumnHeader("");
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

 
  return (
    <BaseModal
      open={open}
      title="Desdinamizar columnas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleConfirmDynamizeRows}
      confirmationModalMessage={"desdinamizar las columnas"}
      disabledAcceptButton={isFormCompleted()}
      hasResultsTable
      height="500px"
      width="900px"
    >
      <Stack
        gap={2}
        sx={{ width: "100%", height: "490px", marginBottom: "35px" }}
      >
        <ShadowBox>
        {selectedIndexColumns &&
          dataColumns &&
          selectedIndexColumns.filter((col: any)=> col.id !== "todas").some((col: any) =>
            checkIfColumnNotExists(
              col.label,
              dataColumns.map((item: any) => {
                return { id: item.id, label: item.columna };
              })
            )
          ) &&     <Typography sx={{ color: "var(--red)", marginLeft: "10px", marginBottom:"10px" }}>
          Las columnas no existentes en la fuente actual no se tendrán en cuenta
          y se borrarán en pasos posteriores.
        </Typography>}
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "10px" }}>
              {"Columnas índice"}
            </InputLabel>
            <Tooltip title="Las columnas utilizadas como columnas índice y las columnas a desdinamizar no pueden repetirse">
              <InfoIcon
                sx={{
                  color: "var(--blue)",
                  fontSize: "21px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
              />
            </Tooltip>
            {selectedIndexColumns.some((colIndex: any) => {
              return selectedDesdynamizeColumns.some((colDes: any) => {
                return colIndex.label === colDes.label;
              });
            }) && (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice y las columnas a desdinamizar no pueden
                coincidir{" "}
              </Typography>
            )}
          </Stack>
          <CheckmarkSelect
            label="Seleccionar columnas"
            selectedItems={selectedIndexColumns}
            setSelectedItems={setSelectedIndexColumns}
            items={filterIdFromColumns(dataColumns)?.map((item: any) => {
              return { id: item.id, label: item.columna };
            })}
            width="100%"
          />
        </ShadowBox>
        <ShadowBox>
        {selectedDesdynamizeColumns &&
          dataColumns &&
          selectedDesdynamizeColumns.filter((col: any)=> col.id !== "todas").some((col: any) =>
            checkIfColumnNotExists(
              col.label,
              dataColumns.map((item: any) => {
                return { id: item.id, label: item.columna };
              })
            )
          ) &&     <Typography sx={{ color: "var(--red)", marginLeft: "10px", marginBottom:"10px" }}>
          Las columnas no existentes en la fuente actual no se tendrán en cuenta
          y se borrarán en pasos posteriores.
        </Typography>}
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "10px" }}>
              {"Columnas a desdinamizar"}
            </InputLabel>
            {selectedIndexColumns.some((colIndex: any) => {
              return selectedDesdynamizeColumns.some((colDes: any) => {
                return colIndex.label === colDes.label;
              });
            }) && (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice y las columnas a desdinamizar no pueden
                coincidir{" "}
              </Typography>
            )}
          </Stack>
          <CheckmarkSelect
            label="Seleccionar columnas"
            selectedItems={selectedDesdynamizeColumns}
            setSelectedItems={setSelectedDesdynamizeColumns}
            items={filterIdFromColumns(dataColumns)?.map((item: any) => {
              return { id: item.id, label: item.columna };
            })}
            width="100%"
          />
        </ShadowBox>
        <ShadowBox>
          <InputLabel sx={form_label}>
            {"Encabezado columna categórica"}
          </InputLabel>
          <TextField
            id="columnCategoric"
            label={""}
            placeholder={"Nombre del encabezado"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              isColumnNameValid(categoricColumnHeader) ||
              categoricColumnHeader === ""
                ? isColumnNameRepeated(categoricColumnHeader, dataColumns) ||
                  (categoricColumnHeader !== "" &&
                    categoricColumnHeader === numericColumnHeader)
                  ? texts.flows.newColumn.helperText.repeatedName
                  : ""
                : texts.flows.newColumn.helperText.invalidName
            }
            error={
              !isColumnNameValid(categoricColumnHeader) ||
              categoricColumnHeader === "" ||
              isColumnNameRepeated(categoricColumnHeader, dataColumns)
            }
            fullWidth
            value={categoricColumnHeader}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCategoricColumnHeader(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>
        <ShadowBox>
          <InputLabel sx={form_label}>
            {"Encabezado columna numérica"}
          </InputLabel>
          <TextField
            id="numericColumnHeader"
            label={""}
            placeholder={"Nombre del encabezado"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              isColumnNameValid(numericColumnHeader) ||
              numericColumnHeader === ""
                ? isColumnNameRepeated(numericColumnHeader, dataColumns) ||
                  (numericColumnHeader !== "" &&
                    categoricColumnHeader === numericColumnHeader)
                  ? texts.flows.newColumn.helperText.repeatedName
                  : ""
                : texts.flows.newColumn.helperText.invalidName
            }
            error={
              !isColumnNameValid(numericColumnHeader) ||
              numericColumnHeader === "" ||
              isColumnNameRepeated(numericColumnHeader, dataColumns)
            }
            fullWidth
            value={numericColumnHeader}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNumericColumnHeader(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>
      </Stack>
    </BaseModal>
  );
};

export default DesdynamizeRows;
