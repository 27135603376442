import { useNavigate } from "react-router-dom";
import FixedAddButton from "../buttons/FixedAddButton";
import ComponentLayout from "../layout/ComponentLayout";
import BallotIcon from "@mui/icons-material/Ballot";
import { useState } from "react";
import useDialog from "../../hooks/useDialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { useApiQuery } from "../../hooks/useApiQuery";
import {DELETE_SCHEMA_URL, SCHEMAS_URL } from "../../api/axios";
import { texts } from "../../texts";
import BasicGrid from "../basicGrid/BasicGrid";
import SchemaCard from "./SchemaCard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import { ConfirmationModal as DeleteSchemaConfirmationModal } from "../dialog";
import { filterDataByName } from "../pipelines/utils";
import Stack from "@mui/material/Stack";
import SearchInput from "../forms/SearchInput";

const SchemaAdmin = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [schemaToDelete, setSchemaToDelete] = useState<number>();
  const [
    openDeleteSchemaConfirmationModal,
    handleToggleDeleteSchemaConfirmationModal,
  ] = useDialog();
  const {
    data: dataSchemas,
    isLoading: isLoadingSchemasData,
    refetch: refetchSchemas,
  } = useApiQuery(SCHEMAS_URL, true, texts.adminSchemas.getSchemas.error);

  const goToConfigSchema = () => {
    navigate("/consolidacion-archivos/configuracion-esquemas");
  };

  const handleEdit = (e: any, id: string) => {
    localStorage.setItem("schemaId", id);
    navigate("/consolidacion-archivos/configuracion-esquemas");
  };

  const handleCopy = (id: string) => {
    console.log("Copiando esquema con id: ", id);
  };

  const handleInfo = (id: string) => {
    console.log("Mostrando info de esquema con id: ", id);
  };

  const { isLoading: isLoadingDeleteSchema, callApi: deleteSchemaById } =
    useApi(
      DELETE_SCHEMA_URL(schemaToDelete),
      "DELETE",
      texts.adminPipeline.deletePipeline.codes,
      undefined,
      refetchSchemas
    );

  const handleDelete = () => {
    deleteSchemaById();
    handleToggleDeleteSchemaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setSchemaToDelete(id);
    handleToggleDeleteSchemaConfirmationModal();
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataSchemas, e.target.value, setFilteredData);
  };

  return (
    <ComponentLayout title="Administración de esquemas" icon={<BallotIcon />}>
      <SimpleBackdrop
        open={isLoadingSchemasData}
        message="Buscando esquemas..."
      />
      
      <SimpleBackdrop
        open={isLoadingDeleteSchema}
        message={texts.adminSchemas.deleteSchema.loading}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder="Búsqueda por nombre"
        />
      </Stack>
      {filteredData?.length || dataSchemas?.length ? (
        <BasicGrid
          data={filteredData ? filteredData : dataSchemas}
          Card={SchemaCard}
          handleEdit={handleEdit}
          /*handleCopy={handleCopy}*/
          /* handleInfo={handleInfo} */
          handleDelete={handleClickOnDelete}
          handleExport={true}
          showInfo={true}
        />
      ) : (
        dataSchemas &&
        !isLoadingSchemasData && (
          <Box
            sx={{
              width: "95%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
            >
              Aún no hay esquemas disponibles.
            </Typography>
          </Box>
        )
      )}
      <FixedAddButton handleClick={goToConfigSchema} />
      <DeleteSchemaConfirmationModal
        open={openDeleteSchemaConfirmationModal}
        handleClose={handleToggleDeleteSchemaConfirmationModal}
        handleAccept={handleDelete}
        message="eliminar el esquema"
        title="Confirmar"
      />
    </ComponentLayout>
  );
};

export default SchemaAdmin;
