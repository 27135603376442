import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
interface SearchInputProps {
  value: string;
  placeholder: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean
}

const SearchInput = ({ value, handleChange, placeholder, disabled }: SearchInputProps) => {
  return (
    <Box component="form" noValidate autoComplete="off" mb="15px" mt="15px">
      <TextField
        type="text"
        label={placeholder}
        variant="outlined"
        fullWidth
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </Box>
  );
};

export default SearchInput;
