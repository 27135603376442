import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getRoute } from "../flowConfiguration/Modals/AddFont/utils";
import Dialog from "../dialog/Dialog";
import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import { form_label } from "../../styles/app-styles";
import ExploreFolders from "./UploadFileModal/ExploreFolders";
import { Button } from "../buttons";
import MoveResourceWarningModal from "./MoveResourceWarningModal";
import useDialog from "../../hooks/useDialog";
import { formatLocation } from "../../utils/util";

interface MoveResourceModalProps {
  name: string | string[];
  type?: string;
  folderLocation?:string
  open: boolean;
  handleClose: () => void;
  handleAccept: (location: string) => void;
}

const MoveResourceModal = ({
  name,
  type,
  open,
  handleAccept,
  handleClose,
  folderLocation
}: MoveResourceModalProps) => {
  const [location, setLocation] = useState<string>("");
  const locationUrl = useLocation();
  const [openWarningModal, toggleWarningModal] =
  useDialog();


  /*************************** CARGA DE DATOS ***************************/

  const loadLocation = () => {
    /*if (locationUrl.pathname.split("/").pop() === "administracion-datos") {
      setLocation("Carpeta raíz");
    } else {
      setLocation(getRoute(locationUrl.pathname));
    }*/
    setLocation("Carpeta raíz");
  };

  //Seteamos la ubicación de la carpeta
  useEffect(() => {
    loadLocation();
  }, [locationUrl.pathname]);

  /*************************** HANDLERS ***************************/

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setLocation("Carpeta raíz");
    } else {
      setLocation(location);
    }
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const handleAcceptModal = () => {
    if (location.startsWith("PHI/")) {
      if (typeof name === "string") {
        if (!validateAllowedFilesNameFormat(name)) {
          toggleWarningModal();
          return;
        }
      }else{
        if(name.some((name: string)=> !validateAllowedFilesNameFormat(name))){
          toggleWarningModal();
          return;
        }
      }
    }

    
    handleAccept(location.endsWith("/") ? formatLocation(location, `${"Carpeta raíz"}/`) : formatLocation(location, `${"Carpeta raíz"}`) + (formatLocation(location, `${"Carpeta raíz"}`) === "" ? ""  :"/"));
    cleanAndClose();
  };

  const validateAllowedFilesNameFormat = (file: string) => {
    // Construir la expresión regular dinámicamente
    const regexString = `^${
      location.split("/")[
        location.endsWith("/")
          ? location.split("/").length - 2
          : location.split("/").length - 1
      ]
    }-\\d{2}-\\d{4}$`;
    const regex = new RegExp(regexString);
    // Verificar si el nombre de archivo cumple con el formato
    return regex.test(file);
  };

  const cleanAndClose = () => {
    handleClose();
    loadLocation();
  };

  return (
    <Dialog
      open={open}
      title={`Cambiar ubicación ${
        type !== undefined
          ? type === "archivo"
            ? "del archivo"
            : "de la carpeta"
          : "de los recursos"
      }`}
      handleClose={cleanAndClose}
      maxWidth="xl"
    >
      <Stack sx={{ width: "50vw", height: "30vw" }}>
        <>
          <Stack sx={{ marginTop: "10px" }}>
            <InputLabel sx={form_label}>
              Seleccione la nueva ubicación{" "}
              {type !== undefined
                ? type === "archivo"
                  ? "del archivo"
                  : "de la carpeta"
                : "de los recursos"}
            </InputLabel>
            <ExploreFolders
              handleChangeSelectedFolder={handleChangeSelectedFolder} desactivateFolder={folderLocation}
            />
          </Stack>
        </>

        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={cleanAndClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={handleAcceptModal}
            color="blue"
          />
        </Stack>
      </Stack>
      <MoveResourceWarningModal open={openWarningModal} handleAccept={toggleWarningModal}/>
    </Dialog>
  );
};

export default MoveResourceModal;
