import { Checkbox, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import { fileFormatOptions } from "../../utils/util";
import { form_label } from "../../styles/app-styles";
import ExploreFolders from "../dataManagement/UploadFileModal/ExploreFolders";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";
import { SingleValue } from "react-select";
import { IdOption } from "../forms/types";

interface SchemeConfigurationProps {
  selectedFile: string | string[];
  setSelectedFile: any;
  dataFileState: any;
  setDataFileState: any;
  handleSetSelectedFiles: any;
  handleChangeSelectedFolder: (selectedFolder: string) => void;
  formatSelectRef: any;
  setSelectedFileFolder: any;
  customInitialRoute?: string;
  formatSelectedOption: SingleValue<IdOption> | undefined;
  formatOptionsChangeHandler: any;
  filesFolderLocation?: string;
  configSelectedOption?: any;
  runValidations: boolean
  setRunValidations: any
}

const SchemeConfiguration = ({
  selectedFile,
  setSelectedFile,
  dataFileState,
  setDataFileState,
  formatSelectRef,
  handleChangeSelectedFolder,
  setSelectedFileFolder,
  handleSetSelectedFiles,
  formatOptionsChangeHandler,
  formatSelectedOption,
  customInitialRoute,
  configSelectedOption,
  filesFolderLocation,
  runValidations,
  setRunValidations
}: SchemeConfigurationProps) => {
  const [delayHelper, setDelayHelper] = useState<boolean>(true);


  useEffect(() => {
    setDelayHelper(false);
    setTimeout(() => {
      setDelayHelper(true);
    }, 3);
  }, [configSelectedOption]);

  return (
    <Stack width="80%" gap={2}>
    
      <Stack>
        <UploadFileContent
          areMultipleFiles={true}
          selectedFile={selectedFile}
          setSelectedFile={handleSetSelectedFiles}
          dataFileState={dataFileState}
          setDataFileState={setDataFileState}
          setSelectedFileFolder={setSelectedFileFolder}
          customInitialRoute={customInitialRoute}
          disableChooseFiles={true}
        />
        {delayHelper && (
          <Stack>
            <InputLabel id="format" sx={form_label}>
              Formato del archivo final
            </InputLabel>
            <Select
              reference={formatSelectRef}
              styles={selectStyles(formatSelectedOption)}
              options={fileFormatOptions}
              onChange={formatOptionsChangeHandler}
              closeMenuOnSelect
              placeholder="Seleccionar formato"
              isClearable
              isDisabled={false}
              defaultValue={formatSelectedOption}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction={"row"} alignItems={"center"} marginRight={"auto"}>
              <InputLabel id="credentials" sx={form_label}>
                Correr validaciones
              </InputLabel>
              <Checkbox
                checked={runValidations}
                sx={{
                  color: "#172D40",
                  "& svg": { stroke: "#172D40" },
                }}
                onChange={() => {
                  setRunValidations((prev: any) => !prev);
                }}
              />
            </Stack>
      <Stack sx={{ marginTop: "10px" }}>
        <InputLabel sx={form_label}>
          Seleccione la ubicación del archivo final
        </InputLabel>

        <ExploreFolders
          handleChangeSelectedFolder={handleChangeSelectedFolder}
          customFolder={filesFolderLocation}
        />
      </Stack>

    </Stack>
  );
};

export default SchemeConfiguration;
