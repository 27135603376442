import { Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import Dialog from "../dialog/Dialog";

interface MoveResourceWarningModalProps {
  open: boolean;
  handleAccept: () => void;
}

const MoveResourceWarningModal = ({
  open,
  handleAccept,

}: MoveResourceWarningModalProps) => {
  return (
    <Dialog open={open} handleClose={handleAccept} maxWidth="xl" title={"No es posible realizar esta acción"}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "#172D40",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          {"El nombre del recurso no tiene el formato adecuado para realizar esta acción"}
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Aceptar"
            type="button"
            onClick={handleAccept}
            color="blue-greeny"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default MoveResourceWarningModal;
