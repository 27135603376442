import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

interface ICustomToolbar {
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  fileName?: string;
}

function CustomToolbar({
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  fileName,
}: ICustomToolbar) {
  return (
    <GridToolbarContainer
      sx={{
        color: "#F90D4A",
        display: toolbar ? "flex" : "none",
        flexDirection: "row",
        alignItems: "center",
        height: "40px",
        width: "100%",
      }}
    >
      <GridToolbarColumnsButton
        sx={{
          display: columnsButton ? "flex" : "none",
          color: "#4D4C50",
          width: "90px",
          background: "transparent",
          margin: "2px 5px 2px 18px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            color: "#F90D4A",
          },
        }}
        onResize={undefined}
        onResizeCapture={undefined}
      ></GridToolbarColumnsButton>
      <GridToolbarFilterButton
        sx={{
          display: filterButton ? "flex" : "none",
          color: "#4D4C50",
          width: "90px",
          background: "transparent",
          margin: "2px 5px 2px 15px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            color: "#F90D4A",
          },
        }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarDensitySelector
        sx={{
          display: densitySelector ? "flex" : "none",
          color: "#4D4C50",
          width: "90px",
          background: "transparent",
          margin: "2px 5px 2px 15px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            color: "#F90D4A",
          },
        }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarExport
        csvOptions={{ fileName: fileName && fileName }}
        sx={{
          display: exportButton ? "flex" : "none",
          color: "#4D4C50",
          width: "90px",
          background: "transparent",
          margin: "2px 5px 2px 15px",
          fontSize: "14px",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiPaper-root":{color: "black"},
          "&:hover": {
            color: "#F90D4A",
          },
        }}
      />
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
