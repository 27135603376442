import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { changeName } from "../pipelines/utils";

/**
 * @name transformData
 * @description Función que transforma los datos de la tabla virtualizada. Se usa DataGrid Pro de Material-UI.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformData = (dataFilas: any, row?: any, newNames?: any) => {

  //Saco la columna _id para que no se renderice
  const columnsWithoutId = Object?.keys(dataFilas[0])?.filter(
    (key) => key !== "_id" && key !== "indice" && key !== "id"
  )

  const handleHeaderName = (originalValue: string) => {
    if (!row && !newNames) {
      return originalValue;
    } else if (row && !newNames) {
      return changeName(row, originalValue);
    } else if (!row && newNames) {
      return changeName(newNames,originalValue);
    } else if (row && newNames) {
      return changeName(newNames,changeName(row, originalValue));
    }
  };

  const arrayColumns = columnsWithoutId.map((columna: any) => ({
    field: columna,
    headerClassName: "es_header_column",
    description: columna,
    flex: 1,
    width: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography sx={{ color: "white", fontSize: "14px", fontWeight: "600" }}>      
        <span>{handleHeaderName(params.field)}</span>
      </Typography>
    ),
    renderCell: (params: any) => (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={`${params.value}`}
      >
        <p className="es_cellValue">{params.value}</p>
      </Tooltip>
    ),
  }));

  const arrayRows = dataFilas.map((fila: any) => {
    const obj: any = {};
    obj["id"] = fila.indice;
    for (const key in fila) {
      if (key !== "indice" && key !== "_id") {
        obj[key] = fila[key];
      }
    }
    return obj;
  });

  const columns: GridColDef[] = arrayColumns.map((col: any) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
