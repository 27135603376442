import Grid from "@mui/material/Grid";
import InputTitle from "../forms/InputTitle";

const SchemaRowTitles = () => {
  return (
    <>
      <Grid item xs={0.5}></Grid>
      <Grid item xs={4}>
        <InputTitle title="Pipeline" />
      </Grid>
      <Grid item xs={4.5}>
        <InputTitle title="Hojas" />
      </Grid>
      <Grid item xs={2}>
        <InputTitle
          title={"Tipo de filtro"}
          hasTooltip={true}
          tooltipText={
            "La opción 'Incluir' permite aplicar el pipeline sobre las hojas seleccionadas, mientras que la opción 'Excluir' permite aplicarlo sobre todas las hojas exceptuando las seleccionadas.  Además se toman en cuenta las siguientes pautas: No se permitirán agregar campos vacíos. Si no se elige ninguna hoja, por default quedarán seleccionadas todas. No se permitirá excluir todas las hojas. La opción 'Todas' (las hojas) solo se podrá utilizar en la primera línea, y una vez elegida, no se pueden agregar más líneas.  La opción 'Excluir' se podrá utilizar una sola vez.  Los pipelines y hojas se irán quitando del listado a medida que se usen. Además es posible eliminar lineas."
          }
        />
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default SchemaRowTitles;
