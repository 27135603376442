export const updateAuthAndCompany = (newCompany, setAuth, setCompanyContext) => {
    if (typeof newCompany !== "string" && newCompany?.value) {
      setAuth((prev) => ({
        ...prev,
        empresa: newCompany.value,
      }));
      setCompanyContext(newCompany);
    }
  };

  export const handleMenuAndNavigation = (menu, auth, handleCloseBasicModal, navigate, locationUrl) => {
    if (menu) {
      handleCloseBasicModal();
     locationUrl !== undefined ? navigate("/administracion-datos") : location.reload();
    } else {
      navigate("/");
    }
  };

  export const formatCompanyOptions = (options) => {
    return options
      ? options.map((option) => {
          return {
            value: option.id,
            label: option.nombre_visual,
          };
        })
      : [];
  };