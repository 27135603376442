import { Box, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import useLogout from "../../hooks/useLogout";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import useDialog from "../../hooks/useDialog";
import ConfirmationModal from "../dialog/ConfirmationModal";
import { appBar_buttons_container_styles } from "../../styles/app-styles";

const UserAvatar = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openConfirmLogoutModal, toggleConfirmLogoutModal] = useDialog();
  const [
    logout,
    isLoadingLogout,
    openSnackbar,
    handleToggleSnackbar,
    messageSnackbar,
    severity,
  ] = useLogout();
  const settings = ["Perfil", "Cuenta", "Dashboard", "Cerrar Sesión"];
  const { auth }: any = useAuth();

  const handleSetting = (setting: string) => {
    switch (setting) {
      case "Perfil":
        // navigate("/profile");
        break;
      case "Cuenta":
        // navigate("/account");
        break;
      case "Dashboard":
        // navigate("/dashboard");
        break;
      case "Cerrar Sesión":
        toggleConfirmLogoutModal();
        break;
      default:
        break;
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    toggleConfirmLogoutModal();
    localStorage.removeItem("pipelineId");
    localStorage.removeItem("pipelineEditFile");
    /*localStorage.removeItem("flowId");*/
    logout();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Ajustes">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {auth?.user && (
            <Stack sx={appBar_buttons_container_styles}>
              {auth?.user.split("@")[0]}
              <ExpandMoreIcon />
            </Stack>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography
              textAlign="center"
              sx={{
                color:
                  setting === "Cerrar Sesión"
                    ? "var(--greeny)"
                    : "var(--light-grey)",
              }}
              onClick={() => handleSetting(setting)}
            >
              {setting}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <SimpleBackdrop open={isLoadingLogout} message="Cerrando sesión..." />
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleToggleSnackbar}
        message={messageSnackbar}
        severity={severity}
      />
      <ConfirmationModal
        open={openConfirmLogoutModal}
        handleClose={toggleConfirmLogoutModal}
        handleAccept={handleLogout}
        message={`cerrar sesión`}
        title="Cerrar sesión"
      />
    </Box>
  );
};

export default UserAvatar;
