import { createContext, useReducer } from "react";
import { PipelineAction, PipelineState } from "./types";

const PipelinesStepperContext = createContext<any>({});

export const PipelinesStepperProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialPipelineState: PipelineState = {
    file: {
      name: "",
      id: 0,
      url: "",
    },
    sheets_list: [],
    selected_sheet: {
      label: "",
      value: 0,
    },
    no_header_sheet: false,
    data: [],
    newData: [],
    actualColumns: [],
    actualRows: [],
    header: {
      id: null,
      data: [],
      renombre: [],
    },
    rows_to_delete: {
      rows_to_delete_before_header_setting: [],
      rows_to_delete_after_header_setting: [],
    },
    pipeline_name: "",
    validations: [],
    step1Complete: false,
    selectedColumns: [],
    pipeline_to_edit_no_header_columns: [],
    undo_all : false,
    replacedFile: false
  };

  function pipelineReducer(pipelineState: PipelineState, action: any) {
    switch (action.type) {
      case "SET_URL":
        return {
          ...pipelineState,
          file: { ...pipelineState.file, url: action.payload },
        };
      case "SET_FILE_DATA":
        return {
          ...pipelineState,
          file: {
            ...pipelineState.file,
            name: action.payload.name,
            id: action.payload.id,
          },
        };
      case "SET_SHEETS_LIST":
        return { ...pipelineState, sheets_list: action.payload };
      case "SET_SELECTED_SHEET":
        return {
          ...pipelineState,
          selected_sheet: {
            ...pipelineState.selected_sheet,
            label: action.payload.label,
            value: action.payload.value,
          },
        };
      case "SET_NO_HEADER_SHEET":
        return {
          ...pipelineState,
          no_header_sheet: action.payload,
        };
      case "SET_DATA":
        return { ...pipelineState, data: action.payload };
      case "SET_NEW_DATA":
        return { ...pipelineState, newData: action.payload };
      case "SET_ACTUAL_COLUMNS":
        return { ...pipelineState, actualColumns: action.payload };
      case "SET_ACTUAL_ROWS":
        return { ...pipelineState, actualRows: action.payload };
      case "SET_HEADER":
        return {
          ...pipelineState,
          header: {
            ...pipelineState.header,
            id: action.payload.id,
            data: action.payload.data,
            renombre: action.payload.renombre,
          },
        };
      case "SET_ROWS_TO_DELETE":
        return { ...pipelineState, rows_to_delete: action.payload };
      case "SET_PIPELINE_NAME":
        return { ...pipelineState, pipeline_name: action.payload };
      case "SET_STEP1_COMPLETE":
        return { ...pipelineState, step1Complete: action.payload };
      case "SET_VALIDATIONS":
        return { ...pipelineState, validations: action.payload };
      case "SET_SELECTED_COLUMNS":
        return { ...pipelineState, selectedColumns: action.payload };
      case "SET_PIPELINE_TO_EDIT_NO_HEADER_COLUMNS":
        return { ...pipelineState, pipeline_to_edit_no_header_columns: action.payload };
        case "SET_UNDO_ALL":
          return { ...pipelineState, undo_all: action.payload };
          case "SET_REPLACED_FILE":
            return { ...pipelineState, replacedFile: action.payload };  
      default:
        throw new Error();
    }
  }

  const [pipelineState, pipelineDispatch] = useReducer<
    React.Reducer<PipelineState, PipelineAction>
  >(pipelineReducer, initialPipelineState);

  return (
    <PipelinesStepperContext.Provider
      value={{
        initialPipelineState,
        pipelineReducer,
        pipelineState,
        pipelineDispatch,
      }}
    >
      {children}
    </PipelinesStepperContext.Provider>
  );
};

export default PipelinesStepperContext;
