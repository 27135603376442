import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

interface CollapseOptionProps {
  OptionIcon: any;
  optionTitle: string;
  optionUrl: string;
  handleDrawerClose: any;
}

const CollapseOption = ({
  OptionIcon,
  optionTitle,
  optionUrl,
  handleDrawerClose,
}: CollapseOptionProps) => {
  const navigate = useNavigate();

  const onClickCollapseOption = () => {
    handleDrawerClose();
    if (optionUrl !== "/administracion-flujos/configuracion-flujos") {
      localStorage.removeItem("flowId");
    };
    if (optionUrl !== "/consolidacion-archivos/configuracion-esquemas") {
      localStorage.removeItem("schemaId");
    };
    if (optionUrl !== "/reporte-base/configuracion-resumenes") {
      localStorage.removeItem("summaryId");
    };
    if (optionUrl !== "/reporte-base/configuracion-validaciones") {
      localStorage.removeItem("validationId");
    };
    navigate(optionUrl);
  };

  return (
    <ListItemButton
      key={optionTitle}
      sx={{ pl: 4 }}
      onClick={() => onClickCollapseOption()}
    >
      <ListItemIcon sx={{ minWidth: "35px" }}>
        <OptionIcon
          sx={{
            color:
              location.pathname === optionUrl
                ? "var(--very-light-blue) !important"
                : "white",
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={optionTitle}
        sx={{
          "& .MuiTypography-root": {
            fontSize: "14px",
            color:
              location.pathname === optionUrl
                ? "var(--very-light-blue) !important"
                : "white",
          },
        }}
      />
    </ListItemButton>
  );
};

export default CollapseOption;
