import { Stack, Tooltip, Typography } from "@mui/material";
import Connections from "./ExternalConnections/Connections";
import ResourceLabelsConfig from "./ResourceLabels/ResourceLabelsConfig";

const Configs = () => {
  return (
    <Stack direction={"row"} marginLeft="auto" alignItems="center">
      <Connections />
      <ResourceLabelsConfig />
    </Stack>
  );
};

export default Configs;
