import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


type helpText = {
  title: string;
  summary: string;
  detail: string;
};
interface ControlledAccordionsProps {
  helpTexts: helpText[]
}

export default function ControlledAccordions({helpTexts}: ControlledAccordionsProps) {
  const [expanded, setExpanded] = React.useState<string | number | false>(false);

  const handleChange =
    (panel: string | number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {helpTexts.map((text, index) => (
        <Accordion
        key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0, color:"var(--blue)" }}>
              {text.title}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {text.summary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color:"var(--dark-grey)" }}>
              {text.detail}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
