import ComponentLayout from "../layout/ComponentLayout";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DataManagementProvider from "../../context/DataManagementContext";
import FolderCard from "./FolderCard";
import Grow from "@mui/material/Grow";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SearchInput from "../forms/SearchInput";
import { Item } from "../basicGrid/BasicGrid";
import { useApiQuery } from "../../hooks/useApiQuery";
import { DATA_URL, DOWNLOAD_FILE, RESOURCE_LABEL_URL } from "../../api/axios";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import FixedAddButton from "../buttons/FixedAddButton";
import UploadFileModal from "./UploadFileModal/UploadFileModal";
import { filterDataByResourceName, isResourceSelected, orderResourcesByName } from "../../utils/util";
import Filters from "./Filters";
import useApi from "../../hooks/useApi";
import SelectFiles from "./SelectFiles";
import SelectViewType from "./SelectViewType";
import ResourcesListHeader from "./ResourcesListHeader";
import ReloadResources from "./ReloadResources";
import Configs from "./Configs";

const DataManagement = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [viewType, setViewType] = useState<"list" | "cards">("cards");
  const [filteredData, setFilteredData] = useState<any[]>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [isLoadingPostFolder, setIsLoadingPostFolder] =
    useState<boolean>(false);
  const [isSelectedModeActive, setIsSelectedModeActive] =
    useState<boolean>(false);
  const [isLoadingSearchInfoLocal, setIsLoadingSearchInfoLocal] =
    useState<boolean>(false);
  const { DataState, dataDispatch } = useContext<
    React.ContextType<typeof DataManagementProvider>
  >(DataManagementProvider);
  const { openUploadModal, searchInfo, isLoadingSearchInfo } = DataState;

  /********************* LLAMADAS **************************/

  const onSuccesGetResources = (data: any) => {
    // Ordenamos la data de forma ascendente
    setFilteredData(orderResourcesByName(data));
    dataDispatch({
      type: "SET_FOLDER_FILES",
      payload: data,
    });
  };

  const {
    data: dataResources,
    isLoading: isLoadingDataResources,
    error: isErrorDataResources,
    callApi: getResources,
  } = useApi(
    `${DATA_URL}`,
    "GET",
    texts.dataManagement.getResources.codes,
    undefined,
    onSuccesGetResources,
    undefined,
    false
  );

  const refetchResources = () => {
    getResources(`${DATA_URL}?cache=false`);
  };

  const {
    data: dataLabels,
    isLoading: isLoadingGetLabels,
    refetch: refetchLabels,
    error: errorLabels,
  } = useApiQuery(
    RESOURCE_LABEL_URL,
    false,
    texts.dataManagement.getLabels.error
  );


  /********************* HANDLERS *******************************/
  const handleToggleUploadModal = () => {
    dataDispatch({
      type: "SET_OPEN_UPLOAD_MODAL",
      payload: !openUploadModal,
    });
  };

  const handleCloseModal = (tab: string | undefined) => {
    if (tab === "CARGAR ARCHIVOS") {
      refetchResources();
    }
    handleToggleUploadModal();
  };

  /*************************** CARGA DE DATOS ***********************************/

  useEffect(() => {
    getResources();
  }, []);

  useEffect(() => {
    if (!isLoadingSearchInfo && isLoadingSearchInfoLocal) {
      setIsLoadingSearchInfoLocal(false);
      filterDataByResourceName(searchInfo, searchValue, setFilteredData);
    }
  }, [isLoadingSearchInfo]);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      if (isLoadingSearchInfo) {
        setIsLoadingSearchInfoLocal(true);
      } else {
        filterDataByResourceName(searchInfo, e.target.value, setFilteredData);
      }
    } else {
      setFilteredData(dataResources);
    }
  };

  const handleClickOnResource = (file: any) => {
    if (selectedFiles.map((file: any) => file.name).includes(file.name)) {
      setSelectedFiles(
        selectedFiles.filter(
          (selectedFile: any) => selectedFile.name !== file.name
        )
      );
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  return (
    <ComponentLayout
      title="Administración de datos"
      icon={<UploadFileIcon />}
      rightComponent={<Configs />}
    >
      <SimpleBackdrop
        open={isLoadingDataResources}
        message={texts.dataManagement.getResources.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostFolder}
        message={texts.dataManagement.postFolder.loading}
      />
      <Stack width="100%">
        <Stack direction={"row"} width="100%">
          <SelectFiles
            selectedFiles={selectedFiles}
            setIsSelectedModeActive={setIsSelectedModeActive}
            setSelectedFiles={setSelectedFiles}
            isSelectedModeActive={isSelectedModeActive}
            refreshResources={refetchResources}
            resources={filteredData}
          />
          <Filters
            setFilteredData={setFilteredData}
            initialData={dataResources}
            filteredData={filteredData}
            valueToCleanValues={location.pathname}
          />
        </Stack>
        <Stack sx={{ flexDirection: "row", cursor: "pointer" }}>
          <ReloadResources refetchResources={refetchResources} />
          <SelectViewType viewType={viewType} setViewType={setViewType} />
        </Stack>

        {/*     
            <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder="Búsqueda por nombre"
            />
          </Stack>
         */}
        {!isLoadingDataResources && !isErrorDataResources && (
          <>
            <Stack width={"100%"} direction={"row"}>
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontSize: "19px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Carpetas
              </Typography>
            </Stack>

            <Stack
              width={"100%"}
              gap={viewType === "list" ? 0 : 3}
              flexWrap="wrap"
              marginBottom="30px"
              direction={viewType === "list" ? "column" : "row"}
            >
              {isLoadingSearchInfoLocal && (
                <Stack
                  sx={{
                    width: "100%",
                    height: "50VH",
                    margin: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}
              {viewType === "list" && <ResourcesListHeader />}
              {!isLoadingSearchInfoLocal &&
              filteredData &&
              filteredData?.length > 0 ? (
                filteredData
                  .filter((resource: any) => {
                    return resource.tipo === "carpeta";
                  })
                  .sort((a, b) =>
                    a.nombre === "PHI" ? -1 : b.nombre === "PHI" ? 1 : 0
                  )
                  .map((folder: any, index: number) => {
                    return (
                      <Grow
                        in={true}
                        {...{ timeout: 1000 + (index < 50 ? index : 50) * 500 }}
                        key={folder.nombre}
                      >
                        <Item
                          id="gridItem"
                          elevation={0}
                          sx={{
                            padding:
                              viewType === "list" ? "3px 0px" : undefined,
                          }}
                        >
                          <FolderCard
                            name={folder.nombre}
                            type={folder.tipo}
                            id={folder.id}
                            refreshResources={refetchResources}
                            folderLocation={folder.ubicacion}
                            label={folder?.etiqueta}
                            viewType={viewType}
                            date={folder.fecha_creacion}
                            setSelectedResource={handleClickOnResource}
                            isSelectedModeActive={isSelectedModeActive}
                            isSelectedGlobal={isResourceSelected(selectedFiles, folder)}

                          />
                        </Item>
                      </Grow>
                    );
                  })
              ) : (
                <Box
                  sx={{
                    width: "95%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
                  >
                    No hay carpetas disponibles.
                  </Typography>
                </Box>
              )}
            </Stack>

            {!isLoadingSearchInfoLocal &&
              filteredData &&
              filteredData?.length > 0 &&
              filteredData.filter((resource: any) => {
                return resource.tipo === "archivo";
              }).length > 0 && (
                <>
                  <Typography
                    sx={{
                      color: "var(--blue)",
                      fontSize: "19px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Archivos
                  </Typography>
                  {viewType === "list" && <ResourcesListHeader size={true} />}

                  <Stack
                    width={"100%"}
                    gap={viewType === "list" ? 0 : 3}
                    flexWrap="wrap"
                    marginBottom="30px"
                    direction={viewType === "list" ? "column" : "row"}
                  >
                    {filteredData && filteredData?.length > 0 ? (
                      filteredData
                        .filter((f: any) => {
                          return f.tipo === "archivo";
                        })
                        .map((file: any, index: number) => {
                          return (
                            <Grow
                              in={true}
                              {...{ timeout: 1000 + (index < 50 ? index : 50) * 500 }}
                              key={file.nombre}
                            >
                              <Item
                                id="gridItem"
                                elevation={0}
                                sx={{
                                  padding:
                                    viewType === "list" ? "3px 0px" : undefined,
                                }}
                              >
                                <FolderCard
                                  name={file.nombre}
                                  type={file.tipo}
                                  id={file.id}
                                  folderLocation={file.ubicacion}
                                  label={file?.etiqueta}
                                  refreshResources={refetchResources}
                                  date={file.fecha_creacion}
                                  size={file.peso}
                                  viewType={viewType}
                                  setSelectedResource={handleClickOnResource}
                                  isSelectedModeActive={isSelectedModeActive}
                                  isSelectedGlobal={isResourceSelected(selectedFiles, file)}
                                  isExternalResource={file.externo}
                                  resourceId={file.externo ? file.recurso_id : undefined}
                                />
                              </Item>
                            </Grow>
                          );
                        })
                    ) : (
                      <Box
                        sx={{
                          width: "95%",
                          height: "50vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
                        >
                          No hay archivos disponibles.
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </>
              )}
          </>
        )}
      </Stack>
      <FixedAddButton
        handleClick={handleToggleUploadModal}
        customColor="var(--blue)"
      />
      <UploadFileModal
        open={openUploadModal}
        handleClose={handleCloseModal}
        isDataManagementPage={true}
        onSuccessPostFolder={refetchResources}
        setIsLoadingPostFolder={setIsLoadingPostFolder}
        folders={dataResources?.filter((resource: any) => {
          return resource.tipo === "carpeta";
        })}
      />
    </ComponentLayout>
  );
};

export default DataManagement;
