import { Stack, Typography } from "@mui/material";
import Dialog from "../../../../dialog/Dialog";
import { Button } from "../../../../buttons";

interface SQLQueryIndicationsModalProps {
  open: boolean;
  handleClose: (resourceTabType?: string) => void;
  connectionType: string;
 table: string
 schema?:string
 catalog?:string
}

const SQLQueryIndicationsModal = ({
  open,
  handleClose,
  connectionType,
   table,
   schema,
   catalog
}: SQLQueryIndicationsModalProps) => {
  return (
    <Dialog
      open={open}
      title={"Indicaciones para las consultas SQL"}
      handleClose={handleClose}
      maxWidth="xl"
    >
      <Stack sx={{ width: "40vw", height: "20vw" }}>
      <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold"}}>
        Debes ingresar una consulta SQL válida de tipo SELECT que incluya {connectionType === "cluster"? "la tabla": "el catalogo, el esquema y la tabla."}
      </Typography>
      <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold"}}>
       Algunos ejemplos de consultas validas:
      </Typography>

      <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold", marginBottom:"20px", marginTop:"20px"}}>
       SELECT * FROM {connectionType === "cluster"? table :  `${catalog}.${schema}.${table}`} WHERE nombreColumna = "valor";
      </Typography>
      
      <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold", marginBottom:"20px", marginTop:"20px"}}>
       SELECT nombreColumna1, nombreColumna2, nombreColumna3 FROM {connectionType === "cluster"? table :  `${catalog}.${schema}.${table}`} ORDER BY nombreColumna1 ASC;
      </Typography>
      <Typography sx={{fontSize:"16px", color:"var(--blue)", fontWeight:"bold", marginBottom:"20px", marginTop:"20px"}}>
       SELECT * FROM {connectionType === "cluster"? table :  `${catalog}.${schema}.${table}`} LIMIT 20;
      </Typography>
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SQLQueryIndicationsModal;
