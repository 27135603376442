import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomNoRowsOverlay from "../tablesTools/NoRows";

interface DataGridWrapperProps {
  tabValue: string;
  tabConfigurations: any;
  rows: any[];
  columns: any[];
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
}

const DataGridWrapper = ({
  tabValue,
  tabConfigurations,
  rows,
  columns,
  pageSize,
  setPageSize,
}: DataGridWrapperProps) => {
  const currentTabConfig = tabConfigurations[tabValue];

  if (currentTabConfig && currentTabConfig.isLoading) {
    return <CustomSkeleton height={6} />;
  } else if (currentTabConfig && currentTabConfig.error) {
    return (
      <p>Se produjo un error y no fue posible descargar la información.</p>
    );
  }

  return (
    <Stack style={{ height: "500px", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[6, 15, 20, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        disableColumnMenu={true}
        hideFooter={false}
        checkboxSelection={false}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
        }}
      />
    </Stack>
  );
};

export default DataGridWrapper;
