import { InputLabel, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts.js";
import { Button } from "../../../buttons";
import useDialog from "../../../../hooks/useDialog";
import { ConfirmationModal } from "../../../dialog";
import InfoGrid from "../../../basicGrid/InfoGrid";
import SummaryRowTitles from "../../../summary/SummaryRowTitles";
import GroupByRowSelects from "./GroupByRowSelects";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../../api/axios";
import FlowsProvider from "../../../../context/FlowsContext";
import {
  filterIdFromColumns,
  findBeforeElementPosition,
  searchStepper,
  tranformDataGroupByToSend,
} from "../../utils";
import { form_label } from "../../../../styles/app-styles";
import { selectStyles } from "../../../../styles/select.styles";
import { formatColumns, formatColumnsGroupBy } from "../../../fileColumns/utils";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import RowGroupBy from "./RowGroupBy";
import { FUNCTION_LABELS } from "../../../summary/constants";
import BaseModal from "../BaseModal";
import ShadowBox from "../ShadowBox";
import MultiSelectSort from "../../../forms/DraggableMultiSelect";
import InfoIcon from "@mui/icons-material/Info";
import RenameGeneratedColumn from "./RenameGeneratedColumn";
interface AddFontProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const GroupBy = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: AddFontProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, groupByRows, itemToEdit } = flowState;
  const [openConfirmCreateGroupByModal, toggleConfirmCreateGroupByModal] =
    useDialog();
  const [openConfirmResetGroupByModal, toggleConfirmResetGroupByModal] =
    useDialog();
  const [message, setMessage] = useState<string>("");
  //Estado del draggable multi selct
  const [columnsSelected, setColumnsSelected] = useState<any>();
  const [columnsError, setColumnsError] = useState<any>([]);

  /********************************* CARGA DE DATOS ***********************************/

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (dataItem) {
      setColumnsSelected(dataItem?.columnas_agrupar?.map((col: any)=>{return {value: col.label, label: col.label}}));
      dataItem?.columnas_operaciones &&
        flowDispatch({
          type: "SET_GROUP_BY_ROWS",
          payload: dataItem?.columnas_operaciones?.map((item: any) => {
            return {
              column: item.columna,
              function: {
                value: item.operacion,
                label: FUNCTION_LABELS[item.operacion],
              },
              renombre: item?.renombre ? item.renombre : undefined
            };
          }),
        });
    }
  }, [dataItem]);

  useEffect(() => {
    if (open) {
      getColumns();
    }
  }, [open]);

  const handleRenameGeneratedColumns = (newName: string, id: any) =>{
    flowDispatch({
      type: "SET_GROUP_BY_ROWS",
      payload:  groupByRows.map((row: any)=>{return row.column.value === id ? {...row, renombre: newName} : row}),
    });
  
  }

  /******************************* POSTEAR ITEM *********************************************/

  const handleCreateGroupBy = () => {
    toggleConfirmCreateGroupByModal();
    if (dataItem) {
      putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
        tipo: "group_by",
        columnas_agrupar: columnsSelected?.map((column: any) => column.label),
        columnas_operaciones:
          groupByRows.length > 0
            ? tranformDataGroupByToSend(groupByRows)
            : undefined,
      });
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), {
        tipo: "group_by",
        columnas_agrupar: columnsSelected?.map((column: any) => column.label),
        columnas_operaciones:
          groupByRows.length > 0
            ? tranformDataGroupByToSend(groupByRows)
            : undefined,
      });
    }
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const handleResetData = () => {
    setColumnsSelected(undefined);
    flowDispatch({
      type: "SET_GROUP_BY_ROWS",
      payload: [],
    });
    setMessage("");
    toggleConfirmResetGroupByModal();
  };

  const cleanAndClose = () => {
    setColumnsSelected(undefined);
    flowDispatch({
      type: "SET_GROUP_BY_ROWS",
      payload: [],
    });
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    setMessage("");
    handleClose();
  };

  return (
    <BaseModal
      open={open}
      title="Group By"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmCreateGroupByModal}
      toggleConfirmationModal={toggleConfirmCreateGroupByModal}
      handleAccept={handleCreateGroupBy}
      confirmationModalMessage={"crear la agrupación con estos datos"}
      disabledAcceptButton={!columnsSelected || columnsSelected.length === 0}
      height="100%"
      hasResultsTable
    >
      <SimpleBackdrop
        open={isLoadingColumns}
        message={texts.flows.getFlowColumns.loading}
      />
      <Stack alignItems={"center"} gap={2} sx={{ pb: "20px" }}>
        <ShadowBox>
          <InputLabel sx={form_label}>{"Columnas"}</InputLabel>
          <MultiSelectSort
            styles={selectStyles(columnsSelected)}
            options={formatColumnsGroupBy(filterIdFromColumns(dataColumns))}
            name="Columna"
            selected={columnsSelected}
            setSelected={setColumnsSelected}
            placeholder="Seleccionar columnas"
            defaultValue={dataItem ? dataItem.columnas_agrupar : undefined}
            isClearable
          />
          <Stack sx={{ flexDirection: "row", gap: 1, pt: 1 }}>
            <InfoIcon
              sx={{
                color: "var(--blue)",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
            <Typography>
              Reordena las opciones seleccionadas para personalizar el orden de
              las columnas
            </Typography>
          </Stack>
        </ShadowBox>
        <Typography
          variant="subtitle1"
          color="error"
          sx={{ fontWeight: "bold" }}
        >
          {message}
        </Typography>
        <ShadowBox>
          <Stack
            sx={{
              pl: "10px",
              pr: "10px",
              overflow: "auto",
              height: "300px",
            }}
          >
            <InfoGrid
              info={groupByRows}
              RowTitles={SummaryRowTitles}
              RowSelects={GroupByRowSelects}
              RowInfo={RowGroupBy}
              columns={filterIdFromColumns(dataColumns)}
              setMessage={setMessage}
            />
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                mt: "10px",
              }}
            >
              <Button
                title="Resetear"
                color="grey"
                type="button"
                onClick={toggleConfirmResetGroupByModal}
                disabled={!groupByRows || groupByRows.length === 0}
              />
            </Stack>
          </Stack>
        </ShadowBox>
        {groupByRows && groupByRows?.length > 0 && (
          <ShadowBox>
            <Stack
              sx={{
                pl: "10px",
                pr: "10px",
                overflow: "auto",
                height: "200px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "var(--blue)" }}
              >
                Renombrar columnas generadas
              </Typography>
              <Typography>
              Elegir el nombre de una columna ya existente, causará que esta sea reemplazada.
            </Typography>
              {groupByRows?.map((row: any)=>{return <RenameGeneratedColumn key={row.column.value} row={row} newObjectNames={groupByRows} setColumnsError={setColumnsError} setNewObjectNames={handleRenameGeneratedColumns} existingColumns={dataColumns?.map((col: any)=> {return col.columna})}/>})}
            </Stack>
          </ShadowBox>
            )}
      </Stack>
      <ConfirmationModal
        open={openConfirmResetGroupByModal}
        handleClose={toggleConfirmResetGroupByModal}
        handleAccept={handleResetData}
        title="Resetear datos"
        message="borrar todos los datos"
      />
    </BaseModal>
  );
};

export default GroupBy;
