import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { ListFile } from "./types";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

interface LinearDeterminateProps {
  filesList: ListFile[] | undefined;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

export default function LinearDeterminate({
  filesList,
  progress,
  setProgress
}: LinearDeterminateProps) {  
  const totalFiles = filesList?.length || 0;
  const { t } = useLanguage();

  React.useEffect(() => {
    if (filesList) {
      setProgress(
        ((totalFiles -
          filesList.filter(
            (f: ListFile) => f.status === "pending" || f.status === "fetching"
          ).length) *
          100) /
          totalFiles
      );
    }
  }, [filesList]);

  return (
    <Stack sx={{ width: "100%" }}>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Typography
          variant="caption"
          sx={{
            color:
              progress === 100 ? "var(--blue-greeny)" : "var(--light-blue)",
          }}
        >
          {progress === 100
            ? t("dataManagementTexts.linearDeterminate.completedLoading")
            : t("dataManagementTexts.linearDeterminate.loading")+ " " + progress.toFixed() + "%"}
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress}
        color={progress === 100 ? "success" : "secondary"}
        sx={{
          color: progress === 100 ? "var(--blue-greeny)" : "var(--light-blue)",
        }}
      />
    </Stack>
  );
}
