import { Box, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { Button } from "../buttons";
import Dialog from "../dialog/Dialog";
import { useApiQuery } from "../../hooks/useApiQuery";
import { COPY_FLOW_URL, GET_ALL_COMPANIES_URL, GET_COMPANY_URL } from "../../api/axios";
import useLanguage from "../../context/LanguageProvider";
import useSingleSelect from "../../hooks/useSingleSelect";
import {
  container_100_styles,
  error_text_styles,
  form_label,
} from "../../styles/app-styles";
import Select from "../forms/Select";
import { formatCompanyOptions } from "../company/utils";
import { selectStyles } from "../../styles/select.styles";
import { useRef, useState } from "react";
import useInfoContext from "../../hooks/useInfoContext";

interface ExportModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (name: string, company: any, id?: any) => void;
  item: any;
  itemType: "flujo" | "esquema"
}

const ExportModal = ({
  open,
  handleClose,
  handleAccept,
  item,
  itemType
}: ExportModalProps) => {
  const { t } = useLanguage();
  const {  company: companyContext }: any =
  useInfoContext();
  const companySelectRef: any = useRef(null);
  const [itemName, setItemName] = useState<string>(item.nombre);

  const {
    data: dataGetAllCompanies,
    isLoading: isLoadingAllCompanies,
    isError: isErrorAllCompanies,
  } = useApiQuery(GET_ALL_COMPANIES_URL, true, t("company.getCompaniesError"));

  const [
    companySelectedOption,
    setCompanySelectedOption,
    companyOptionChangeHandler,
  ] = useSingleSelect();

    return (
    <>
 
        <Dialog open={open} handleClose={handleClose} title={`Exportar ${itemType}`}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Box sx={container_100_styles}>
          <InputLabel sx={form_label}>
            Seleccione la empresa donde desea exportar el {itemType}
          </InputLabel>
          <Select
            options={formatCompanyOptions(dataGetAllCompanies)}
            styles={selectStyles(companySelectedOption)}
            reference={companySelectRef}
            onChange={companyOptionChangeHandler}
            isClearable
            closeMenuOnSelect
            isSearchable
            placeholder={t("company.chooseCompany")}
          />
          <InputLabel sx={{...form_label, marginTop:"15px"}}>Nombre del {itemType}</InputLabel>
          <TextField
                  id="outlined-basic"
                  error={itemName === ""}
                  size="small"
                  variant="outlined"
                  fullWidth
                  label=""
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  placeholder={`Nombre del ${itemType}`}
                  autoComplete="off"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "var(--blue)",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                      margin: 0,
                      backgroundColor: "var(--very-very-light-grey)",
                    },
                  }}
                />
        </Box>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => handleAccept(itemName, companySelectedOption?.value,item.id)}
            color="blue-greeny"
            disabled={
              !companySelectedOption || companySelectedOption === undefined || itemName === ""
            }
          />
        </Stack>
      </Stack>
    </Dialog>
    </>
  
  );
};

export default ExportModal;
