import Dialog from "../../dialog/Dialog";
import Stack from "@mui/material/Stack";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import { Button } from "../../buttons";
import FlowsProvider from "../../../context/FlowsContext";
import { useContext, useEffect, useState } from "react";
import { searchStepper } from "../utils";
import useApi from "../../../hooks/useApi";
import { VIEW_RESULTS_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import SimpleTable from "../../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { Box, Tooltip } from "@mui/material";

interface BaseModalProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  cleanAndClose: () => void;
  openConfirmationModal: boolean;
  toggleConfirmationModal: () => void;
  handleAccept: () => void;
  disabledAcceptButton: boolean;
  confirmationModalMessage: string;
  customMessage?: boolean;
  width?: string;
  height?: string;
  hasResultsTable?: boolean;
  disableAcceptButtonMessage?: string;
}

const BaseModal = ({
  children,
  open,
  title,
  cleanAndClose,
  openConfirmationModal,
  toggleConfirmationModal,
  handleAccept,
  disabledAcceptButton,
  confirmationModalMessage,
  customMessage,
  hasResultsTable,
  disableAcceptButtonMessage,
}: BaseModalProps) => {
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;
  const [resultsDataState, setResultsDataState] = useState<any>(undefined);

  const onSuccesGetData = (data: any) => {
    setResultsDataState(data);
  };

  const onClose = () => {
    cleanAndClose();
    setResultsDataState(undefined);
  };

  useEffect(() => {
    if (open) {
      if (!(itemToEdit && itemToEdit.posee_error)) {
        hasResultsTable && getViewResults();
      }
    }
  }, [open]);

  const {
    data: viewResultsData,
    isLoading: isLoadingViewResults,
    callApi: getViewResults,
  } = useApi(
    VIEW_RESULTS_URL(
      data_flow?.id,
      active_stepper,
      itemToEdit
        ? itemToEdit.id
        : searchStepper(active_stepper, data_flow)?.items[
            searchStepper(active_stepper, data_flow).items?.length - 1
          ]?.id
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );

  return (
    <Dialog open={open} handleClose={onClose} title={title} maxWidth="xl">
      <Stack
        sx={{
          minWidth: "70vw",
          marginTop: "0px",
        }}
      >
        {children}
      </Stack>
      {itemToEdit && itemToEdit.posee_error
        ? ""
        : hasResultsTable &&
          active_stepper && (
            <>
              <SimpleBackdrop
                open={isLoadingViewResults}
                message={texts.flows.viewResults.loading}
              />
              <Stack
                width="100%"
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ minHeight: "500px", height: "100%", width: "100%" }}
              >
                <Stack sx={{ minWidth: "70vw", width: "100%" }}>
                  {resultsDataState && (
                    <SimpleTable
                      columns={
                        transformDataSimpleTable(resultsDataState).columns
                      }
                      rows={transformDataSimpleTable(resultsDataState).rows}
                      toolbar={false}
                      exportButton={false}
                      filterButton={false}
                    />
                  )}
                </Stack>
              </Stack>
            </>
          )}
      <Stack
        direction="row"
        sx={{
          alignSelf: "center",
          marginTop: "auto",
          justifyContent: "center",
        }}
      >
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={handleAccept}
          title={title}
          customMessage={customMessage}
          message={confirmationModalMessage}
        />
        <Button
          title="Cancelar"
          type="button"
          onClick={onClose}
          color="light-grey"
        />

        <Tooltip
          title={
            disabledAcceptButton && disableAcceptButtonMessage
              ? disableAcceptButtonMessage
              : ""
          }
        >
          <Box>
            <Button
              title="Aceptar"
              type="button"
              onClick={toggleConfirmationModal}
              disabled={disabledAcceptButton}
              color="blue-greeny"
            />
          </Box>
        </Tooltip>
      </Stack>
    </Dialog>
  );
};

export default BaseModal;
