import { Stack, Typography } from "@mui/material";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption, OnChangeValue } from "../forms/types";
import Select from "../forms/Select";
import { optionalSelectStyles } from "../../styles/select.styles";
import { useEffect, useRef } from "react";
import useMultiSelect from "../../hooks/useMultiSelect";
import useSingleSelect from "../../hooks/useSingleSelect";
import { formatOptions, orderTypeOptions } from "../../utils/util";
import { useApiQuery } from "../../hooks/useApiQuery";
import { RESOURCE_LABEL_URL } from "../../api/axios";
import { texts } from "../../texts";

interface FilterProps {
  initialData: any;
  filteredData: any;
  setFilteredData: (data: any) => void;
  valueToCleanValues: any;
  disabled?: boolean;
}

const Filters = ({
  initialData,
  filteredData,
  setFilteredData,
  valueToCleanValues,
  disabled,
}: FilterProps) => {
  const labelRef: any = useRef(null);
  const orderByRef: any = useRef(null);
  const orderTypeRef: any = useRef(null);

  const orderByOptions = [
    { value: "Etiqueta", label: "Etiqueta" },
    { value: "Fecha", label: "Fecha" },
    { value: "Orden alfabético", label: "Orden alfabético" },
  ];

  const [
    labelsSelectedOption,
    setLabelSelectedOption,
    labelsOptionsChangeHandler,
  ] = useMultiSelect();

  const [
    orderBySelectedOption,
    setOrderBySelectedOption,
    orderByOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    orderTypeSelectedOption,
    setOrderTypeSelectedOption,
    orderTypeOptionsChangeHandler,
  ] = useSingleSelect();

  useEffect(() => {
    setLabelSelectedOption([]);
    labelRef?.current?.clearValue();
    setOrderBySelectedOption(undefined);
    orderByRef?.current?.clearValue();
    setOrderTypeSelectedOption(undefined);
    orderTypeRef?.current?.clearValue();
  }, [valueToCleanValues]);

  /********************* LLAMADAS **************************/
  const {
    data: dataLabels,
    isLoading: isLoadingGetLabels,
    refetch: refetchLabels,
    error: errorLabels,
  } = useApiQuery(
    RESOURCE_LABEL_URL,
    false,
    texts.dataManagement.getLabels.error
  );

  /********************* HANDLERS *******************************/

  const hanldeChangeLabel = (
    newValue: OnChangeValue<IdOption, boolean>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    labelsOptionsChangeHandler(
      newValue as OnChangeValue<IdOption, boolean>,
      actionMeta
    );
    let data = [];
    if (newValue?.length === 0) {
      data = initialData;
    } else {
      data = initialData?.filter((resource: any) =>
        newValue
          ?.map((val: any) => val.label)
          .includes(resource?.etiqueta?.nombre)
      );
    }

    switch (orderBySelectedOption?.value) {
      case "Etiqueta":
        orderResourcesByLabel(orderTypeSelectedOption?.label, data);
        return;
      case "Fecha":
        orderResourcesByDate(orderTypeSelectedOption?.label, data);
        return;
      case "Orden alfabético":
        orderResourcesByName(orderTypeSelectedOption?.label, data);
        return;
      default:
        orderResourcesByName(orderTypeSelectedOption?.label, data);
        return;
    }
  };

  const orderResourcesByLabel = (orderType: string | undefined, data: any) => {
    // Agrupa los recursos por etiqueta
    const resourcesByLabel: any = data.reduce((acc: any, resource: any) => {
      const labelName = resource.etiqueta.nombre || "Sin Etiqueta";
      acc[labelName] = acc[labelName] || [];
      acc[labelName].push(resource);
      return acc;
    }, {});

    // Extrae las etiquetas ordenadas
    const orderedLabels = Object.keys(resourcesByLabel).sort();

    // Si se quiere ordenar en orden descendente, invierte el array
    if (orderType === "Ascendente" || orderType === undefined) {
      orderedLabels.reverse();
    }
    const orderedResources: any[] = [];

    orderedLabels.forEach((etiqueta: string) => {
      // Si la etiqueta es 'Sin Etiqueta', agrega los recursos al final
      if (etiqueta === "Sin Etiqueta") {
        orderedResources.push(...resourcesByLabel[etiqueta]);
      } else {
        // Si no, agrega los recursos al principio
        orderedResources.unshift(...resourcesByLabel[etiqueta]);
      }
    });

    setFilteredData(orderedResources);
  };

  const orderResourcesByDate = (orderType: string | undefined, data: any) => {
    const resourcesCopy = [...data];

    // Ordena los recursos por fecha de creación
    resourcesCopy.sort((a: any, b: any) => {
      const dateA = new Date(a.fecha_creacion);
      const dateB = new Date(b.fecha_creacion);

      if (orderType === "Descendente") {
        return dateB.getTime() - dateA.getTime();
      } else {
        return dateA.getTime() - dateB.getTime();
      }
    });

    setFilteredData(resourcesCopy);
  };

  const orderResourcesByName = (orderType: string | undefined, data: any) => {
    if (data?.length) {
      const resourcesCopy = [...data];

      // Ordena los recursos por el atributo "nombre"
      resourcesCopy.sort((a: any, b: any) => {
        const nameA = a.nombre.toUpperCase();
        const nameB = b.nombre.toUpperCase();

        if (orderType === "Descendente") {
          return nameB.localeCompare(nameA);
        } else {
          return nameA.localeCompare(nameB);
        }
      });
      setFilteredData(resourcesCopy);
    }
  };

  const handleChangeOrder = (
    select: string,
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    switch (select) {
      case "orderBy":
        orderByOptionsChangeHandler(
          newValue as SingleValue<IdOption>,
          actionMeta
        );
        switch (newValue?.value) {
          case "Etiqueta":
            orderResourcesByLabel(orderTypeSelectedOption?.label, filteredData);
            return;
          case "Fecha":
            orderResourcesByDate(orderTypeSelectedOption?.label, filteredData);
            return;
          case "Orden alfabético":
            orderResourcesByName(orderTypeSelectedOption?.label, filteredData);
            return;
          default:
            orderResourcesByName(orderTypeSelectedOption?.label, filteredData);
            return;
        }
      case "orderType":
        orderTypeOptionsChangeHandler(
          newValue as SingleValue<IdOption>,
          actionMeta
        );
        switch (orderBySelectedOption?.value) {
          case "Etiqueta":
            orderResourcesByLabel(newValue?.label, filteredData);
            return;
          case "Fecha":
            orderResourcesByDate(newValue?.label, filteredData);
            return;
          case "Orden alfabético":
            orderResourcesByName(newValue?.label, filteredData);
            return;
          default:
            orderResourcesByName(newValue?.label, filteredData);
            return;
        }
        return;
      default:
        return;
    }
  };

  return (
    <Stack direction={"row"} marginLeft="auto" alignItems="center" zIndex="1100">
      <Stack direction={"row"} alignItems="center">
        <Stack
          sx={{
            flexDirection: "row",
            marginRight: "10px",
            alignItems: "center",
            minWidth: "295px",
          }}
        >
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "8px",
            }}
          >
            Ordenar por:
          </Typography>
          <Stack width="60%">
            <Select
              options={orderByOptions}
              styles={optionalSelectStyles(orderBySelectedOption)}
              reference={orderByRef}
              onChange={(
                newValue: SingleValue<IdOption>,
                actionMeta: ActionMeta<IdOption>
              ) => handleChangeOrder("orderBy", newValue, actionMeta)}
              closeMenuOnSelect
              isSearchable
              placeholder={"Orden alfabético"}
              defaultValue={orderBySelectedOption}
              isDisabled={disabled}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            marginRight: "5px",
            alignItems: "center",
            minWidth: "250px",
          }}
        >
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "18px",
              fontWeight: "bold",
              marginRight: "8px",
            }}
          >
            Orden:
          </Typography>
          <Stack width="55%">
            <Select
              options={orderTypeOptions}
              styles={optionalSelectStyles(orderTypeSelectedOption)}
              reference={orderTypeRef}
              onChange={(
                newValue: SingleValue<IdOption>,
                actionMeta: ActionMeta<IdOption>
              ) => handleChangeOrder("orderType", newValue, actionMeta)}
              closeMenuOnSelect
              isSearchable
              placeholder={"Ascendente"}
              defaultValue={orderTypeSelectedOption}
              isDisabled={disabled}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"row"} alignItems="center" minWidth="350px">
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "18px",
            fontWeight: "bold",
            marginRight: "8px",
          }}
        >
          Filtrar por etiqueta:
        </Typography>
        <Stack sx={{ width: "50%" }}>
          {!isLoadingGetLabels && !errorLabels && (
            <Select
              options={formatOptions(dataLabels)}
              styles={optionalSelectStyles(labelsSelectedOption)}
              reference={labelRef}
              onChange={hanldeChangeLabel}
              closeMenuOnSelect
              isSearchable
              isMulti
              placeholder={"Todas"}
              defaultValue={labelsSelectedOption}
              isDisabled={disabled}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
export default Filters;
