import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import { form_label } from "../../styles/app-styles";
import {
  Avatar,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

interface OriginalFontProps {
    setReplaceFile?: ()=>void
    label:string
    fileName: string
    fileError?: boolean
   }

const OriginalFont = ({setReplaceFile,label,fileName,fileError }: OriginalFontProps) => {

  return (
    
            <Stack width="100%">
              <InputLabel sx={form_label}>{label}: </InputLabel>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  backgroundColor: "var(--very-very-light-grey)",
                  borderRadius: "5px",
                }}
              >
                <ListItem
                  key={fileName}
                  sx={{ width: "80%" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: !fileError
                          ? "#73AAB8 !important"
                          : "#fb6e92 !important",
                      }}
                    >
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      color: !fileError
                        ? "var(--blue-greeny) !important"
                        : "#fb6e92 !important",
                      fontWeight: "bold",
                      maxWidth: "380px",
                    }}
                    primary={
                        <p
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                            padding: 0,
                          }}
                        >{`${fileName}`}</p>
                      
                    }
                    secondary={
                      fileError
                        ? "El archivo utilizado ya no existe. Es necesario reemplazarlo"
                        : ""
                    }
                  />
                </ListItem>
                {setReplaceFile !== undefined &&   <Button
                  title="Reemplazar archivo"
                  color="blue"
                  type="button"
                  onClick={() => {
                    setReplaceFile()
                  }}
                />}
              
              </Stack>
            </Stack>
          )
              
};

export default OriginalFont;
