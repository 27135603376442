import { TaskType } from "./types";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tab } from "../colorTabs/types";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

export const tabs: Tab[] = [
  {
    value: "FLUJOS",
    label: "FLUJOS",
  },
  { value: "ESQUEMAS", label: "ESQUEMAS" },
  { value: "INFORMES", label: "INFORMES" },
];

export const sortTasksByDate = (tasks: TaskType[]) => {
  const sortedTasks = tasks.slice().sort((a: TaskType, b: TaskType) => {
    return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
  });
  return sortedTasks;
};

export const convertArrayToText = (array: string[]) => {
  let text = "";
  array.forEach((item, index) => {
    text += item + (index !== array.length - 1 ? ", " : "");
  });
  return text;
};

interface StateCellProps {
  startIcon: JSX.Element;
  text: string;
}

const StateCell = ({ startIcon, text }: StateCellProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {startIcon}
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {text}
      </Typography>
    </Stack>
  );
};

export const transformText = (text: string, length = 83) => {
  return text.length > length ? (
    <>
      {text.slice(0, length)}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={text}
        placement="top-end"
        enterDelay={50}
      >
        <span
          style={{ color: "var(--light-blue)", fontWeight: "bold" }}
          onMouseOver={(e) => {
            e.currentTarget.style.color = "var(--magenta)";
            e.currentTarget.style.cursor = "pointer";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.color = "var(--light-blue)";
          }}
        >
          ...ver más
        </span>
      </Tooltip>
    </>
  ) : (
    text
  );
};

export const formatDate = (date: Date) => {
  const newDate = new Date(date);
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
  const formattedDate = newDate.toLocaleDateString("es-AR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: resolvedOptions.timeZone,
  });

  return formattedDate;
};

export const renderTaskCell = (
  tabValue: string,
  column: string,
  params: any,
  buttonsActions: any
) => {
  if (
    tabValue === "ESQUEMAS" ||
    tabValue === "FLUJOS" ||
    tabValue === "INFORMES"
  ) {
    switch (column) {
      case "estado":
        switch (params.value.toLowerCase()) {
          case "pendiente":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text="Pendiente"
              />
            );
          case "corriendo":
            return (
              <StateCell
                startIcon={<CircularProgress size={20} color="secondary" />}
                text="Corriendo"
              />
            );
          case "finalizada":
            return params.row.posee_errores ? (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--magenta)" }} />
                }
                text="Finalizada con errores"
              />
            ) : (
              <StateCell
                startIcon={<DoneIcon sx={{ color: "var(--blue-greeny)" }} />}
                text="Finalizada con éxito"
              />
            );
          case "error":
            return (
              <StateCell
                startIcon={
                  <ErrorOutlineIcon sx={{ color: "var(--magenta)" }} />
                }
                text="Error al calcular"
              />
            );
          case "cancelada":
            return (
              <StateCell
                startIcon={<CloseIcon sx={{ color: "var(--magenta)" }} />}
                text="Cancelada"
              />
            );
          default:
            return (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {params.value}
              </Typography>
            );
        }
      case "acción":
        switch (params.row.estado.toLowerCase()) {
          case "pendiente":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--magenta)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  Cancelar
                </Button>
              </Stack>
            );
          case "corriendo":
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={<CloseIcon />}
                  variant="text"
                  sx={{ ":hover": { color: "var(--magenta)" } }}
                  onClick={() => buttonsActions.saveDeleteTask(params.row.id)}
                >
                  Cancelar
                </Button>
              </Stack>
            );
          case "finalizada":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--blue-greeny)" } }}
                onClick={() =>
                  tabValue === "INFORMES"
                    ? buttonsActions.viewReportModal({
                        body: params.row.resultado,
                        tipo: params.row.tipo,
                      })
                    : buttonsActions.saveLinkButton(params.row.enlace_archivo)
                }
              >
                {tabValue === "INFORMES" ? "Ver resultado" : "Ver detalle"}
              </Button>
            );
          case "cancelada":
            return <></>;
          case "error":
            return (
              <Button
                startIcon={<ZoomInIcon />}
                variant="text"
                sx={{ ":hover": { color: "var(--blue-greeny)" } }}
                onClick={() =>
                  buttonsActions.saveTaskError(params.row.detalle_error)
                }
              >
                Ver detalle
              </Button>
            );
          default:
            return params.value;
        }
      case "periodo":
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {transformText(convertArrayToText(params.value), 35)}
          </Typography>
        );
      case "fecha":
        return (
          <Typography variant="body2" sx={{ ml: 1 }}>
            {formatDate(params.value)}
          </Typography>
        );
      case "reporte":
        return (
          <Button
            startIcon={<ZoomInIcon />}
            disabled={
              params.row.estado.toLowerCase() !== "finalizada" &&
              params.row.estado.toLowerCase() !== "error"
            }
            variant="text"
            sx={{ ":hover": { color: "var(--blue-greeny)" } }}
            onClick={() =>
              tabValue === "FLUJOS"
                ? buttonsActions.viewFlowReportModal(params.row.ruta_informe)
                : buttonsActions.viewFileReportModal(params.row.id)
            }
          >
            Ver detalle
          </Button>
        );
      case "tipo":
        return (
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {params.row.tipo === "validacion_codigos"
              ? "Validación de códigos"
              : "Resumén de información"}
          </Typography>
        );
      default:
        return params.value;
    }
  }
};

export const truncateText = (text: string, maxLength: number): string => {
  if (text?.length <= maxLength) {
    return text;
  } else {
    return text?.slice(0, maxLength) + "...";
  }
};
