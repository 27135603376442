import Stack from "@mui/material/Stack";
import { useState } from "react";
import Dialog from "../dialog/Dialog";
import { Tooltip, Typography } from "@mui/material";
import BarChart from "../charts/BarChart";
import { truncateText } from "./utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

interface ViewSchemeReportModalProps {
  openReportModal: boolean;
  handleToggleReportModal: () => void;
  report: any;
}

const ViewSchemeReportModal = ({
  openReportModal,
  handleToggleReportModal,
  report,
}: ViewSchemeReportModalProps) => {
  const getTooltip = (value: any) => {
    return `<div><b>${value}${
      value < 2 ? " error de este tipo" : " errores de este tipo"
    } </b></div>`;
  };

  const [isIconClicked, setIsIconClicked] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(report?.ubicacion_reporte);
      setIsIconClicked(true);
      setTimeout(() => setIsIconClicked(false), 2000);
    } catch (error) {
      console.error("Error al copiar al portapapeles:", error);
    }
  };

  return (
    <Dialog
      open={openReportModal}
      handleClose={handleToggleReportModal}
      title={`Reporte de la ejecución del esquema ${report?.esquema}`}
      maxWidth="xl"
    >
      <Stack
        sx={{
          minWidth: "1000px",
          minHeight: "100px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="row" sx={{ width: "100%", justifyContent: "center" }}>
          <Stack sx={{ marginLeft: "20px" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "var(--dark-blue)",
                marginBottom: "15px",
              }}
            >
              Archivos ejecutados con error: {report?.archivos_con_error}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "var(--dark-blue)",
                marginBottom: "15px",
              }}
            >
              Hojas con error: {report?.hojas_con_error}
            </Typography>
            <Typography
              sx={{
                color: "var(--dark-blue)",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Ubicación del reporte generado:
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--light-blue)",
                  fontWeight: "bold",
                  fontSize: "20px",
                  wordBreak:"break-word"

                }}
              >
                {report?.ubicacion_reporte}
              </Typography>
              <Tooltip
                title={isIconClicked ? "Copiado!" : "Copiar"}
                placement={"right"}
                sx={{
                  pointerEvents: "fill",
                }}
              >
                {isIconClicked ? (
                  <DoneOutlineIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    color={"primary"}
                  />
                ) : (
                  <ContentCopyIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleCopyClick}
                    color={"primary"}
                  />
                )}
              </Tooltip>
            </Stack>
          </Stack>

          {/*   <Stack sx={{ alignItems:"center", marginLeft: "auto" }}>
            <Button
              type="button"
              color="blue"
              title="Descargar reporte"
            ></Button>
          
          </Stack>*/}
        </Stack>
      </Stack>
      <BarChart results={report?.grafico} tooltip={getTooltip} />
    </Dialog>
  );
};

export default ViewSchemeReportModal;
