import {
  Checkbox,
  InputLabel,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Select from "../../../forms/Select";
import { form_label } from "../../../../styles/app-styles";
import { useContext, useEffect, useState } from "react";
import { selectStyles } from "../../../../styles/select.styles";
import {
  fileFormatOptions,
  formatOptions,
  monthOptions,
  yearOptions,
} from "../../../../utils/util";
import FlowsProvider from "../../../../context/FlowsContext";
import { formatColumns } from "../../../fileColumns/utils";
import { filterIdFromColumns, getSteppersOptions } from "../../utils";
import CheckmarkSelect from "../../../forms/CheckmarkSelect";
import { SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import { AddButton } from "../../../buttons";
import SelectedPeriod from "./SelectedPeriod";
import AcceptedFiles from "../../../dataManagement/AcceptedFiles";
import { ALLOWED_FILES_URL } from "../../../../api/axios";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { texts } from "../../../../texts";
import ReplaceFont from "../../../flowExecution/ReplaceFont";

interface PhiTemplateConfigurationTabProps {
  formatSelectRef: any;
  stepperToDeconsolidateSelectRef: any;
  columnSelectRef: any;
  selectedSteppers?: any;
  templatePhiNameRef: any;
  monthSelectRef: any;
  yearSelectRef: any;
  setSelectedSteppers?: React.Dispatch<any>;
  stepperToDeconsolidateColumns: any;
  formatSelectedOption: SingleValue<IdOption> | undefined;
  templatePhiNameSelectedOption: SingleValue<IdOption> | undefined;
  templatePhiNameChangeHandler: any;
  monthSelectedOption: SingleValue<IdOption> | undefined;
  monthChangeHandler: any;
  yearSelectedOption: SingleValue<IdOption> | undefined;
  yearChangeHandler: any;
  formatOptionsChangeHandler: any;
  assignPeriods: string;
  setAssignPeriods: React.Dispatch<React.SetStateAction<string>>;
  conservatePeriods: string;
  setConservatePeriods: React.Dispatch<React.SetStateAction<string>>;
  selectedPeriods: string[];
  overwritePeriods: boolean;
  setOverwritePeriods: React.Dispatch<React.SetStateAction<boolean>>;
  deletePeriod: (period: string) => void;
  addPeriod: (() => void) | ((e: any) => void) | undefined;
  columnSelectedOption: SingleValue<IdOption> | undefined;
  columnOptionsChangeHandler: any;
  stepperToDeconsolidateSelectedOption: SingleValue<IdOption> | undefined;
  handleChangeItem?: (newItem: any) => void;
  validateAccept?: () => {
    result: boolean;
    resultMessage: string;
  };
  handleChangeStepperToDeconsolidate: any;
  dataFlow: any;
  fonts?: any[];
  handleChangeFontInput?: (fontName: string, archivo: any) => void;
  handleChangeSheet?: (
    sheet: string | number | undefined,
    fontName: string
  ) => void;
  handleChangeFontDataFile?: (fontName: string, dataFont: any) => void;
  configSelectedOption?: any;
}
const PhiTemplateConfigurationTab = ({
  formatSelectRef,
  stepperToDeconsolidateSelectRef,
  columnSelectRef,
  templatePhiNameRef,
  yearSelectRef,
  monthSelectRef,
  selectedSteppers,
  templatePhiNameSelectedOption,
  templatePhiNameChangeHandler,
  monthChangeHandler,
  monthSelectedOption,
  yearSelectedOption,
  yearChangeHandler,
  setSelectedSteppers,
  assignPeriods,
  setAssignPeriods,
  conservatePeriods,
  setConservatePeriods,
  selectedPeriods,
  overwritePeriods,
  setOverwritePeriods,
  deletePeriod,
  addPeriod,
  stepperToDeconsolidateColumns,
  formatSelectedOption,
  formatOptionsChangeHandler,
  columnSelectedOption,
  columnOptionsChangeHandler,
  stepperToDeconsolidateSelectedOption,
  handleChangeItem,
  validateAccept,
  handleChangeStepperToDeconsolidate,
  dataFlow,
  fonts,
  handleChangeFontInput,
  handleChangeSheet,
  handleChangeFontDataFile,
  configSelectedOption,
}: PhiTemplateConfigurationTabProps) => {
  const [delayHelper, setDelayHelper] = useState<boolean>(true);

  const {
    data: allowedFileNames,
    isLoading: isLoadingFilesNames,
    refetch: refetchFilesNames,
  } = useApiQuery(
    ALLOWED_FILES_URL,
    false,
    texts.dataManagement.getAllowedFileNames.error
  );

  useEffect(() => {
    setDelayHelper(false);
    setTimeout(() => {
      setDelayHelper(true);
    }, 3);
  }, [configSelectedOption]);
  return (
    <>
      <AcceptedFiles isButton={false} refetchAllowedFiles={refetchFilesNames} />
      <Stack sx={{ width: "100%", marginTop: "0px !important" }}>
        <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
          Elija el nombre del archivo
        </InputLabel>
        {delayHelper && (
          <Select
            reference={templatePhiNameRef}
            styles={selectStyles(templatePhiNameSelectedOption)}
            options={allowedFileNames?.map((option: string) => {
              return {
                value: option,
                label: option,
              };
            })}
            onChange={templatePhiNameChangeHandler}
            closeMenuOnSelect
            placeholder="Seleccionar nombre"
            defaultValue={templatePhiNameSelectedOption}
            isClearable
          />
        )}
      </Stack>
      {setSelectedSteppers !== undefined && (
        <Stack sx={{ width: "100%", marginTop: "10px" }}>
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "9px" }}>
              Elja los steppers a calcular{" "}
            </InputLabel>
          </Stack>
          <CheckmarkSelect
            label="Seleccionar steppers"
            allOptionsLabel="Seleccionar todos los steppers"
            selectedItems={selectedSteppers}
            setSelectedItems={setSelectedSteppers}
            items={getSteppersOptions(dataFlow)}
            width="100%"
            customHandleChangeItem={handleChangeItem}
          />
        </Stack>
      )}
      {fonts && fonts?.length > 0 && handleChangeFontInput !== undefined && (
        <Stack sx={{ width: "100%", marginTop: "10px" }}>
          <InputLabel
            id="fonts"
            sx={{
              ...form_label,
              fontSize: "17px",
              marginBottom: "5px",
            }}
          >
            Fuentes:
          </InputLabel>
          {fonts.map((font: any) => {
            return (
              <ReplaceFont
                key={font.nombre}
                label={font.nombre}
                setFont={handleChangeFontInput}
                error={font.error}
                setSheet={handleChangeSheet}
                setDataFont={handleChangeFontDataFile}
              />
            );
          })}
        </Stack>
      )}

      <Stack sx={{ width: "100%", marginTop: "25px" }}>
        <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
          Elija el formato del archivo
        </InputLabel>
        {delayHelper && (
          <Select
            reference={formatSelectRef}
            styles={selectStyles(formatSelectedOption)}
            options={fileFormatOptions}
            onChange={formatOptionsChangeHandler}
            closeMenuOnSelect
            placeholder="Seleccionar formato"
            isClearable
            defaultValue={formatSelectedOption}
          />
        )}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Stack>
            <Typography sx={form_label}>Asignar periodo</Typography>
            <Stack sx={{ width: "100%", flexDirection: "row" }}>
              <RadioGroup
                defaultValue=""
                name="empy-cells-options"
                sx={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Asignar periodo manualmente"
                  value={"manual"}
                  sx={{
                    color: assignPeriods === "" ? "red" : "black",
                  }}
                  checked={assignPeriods === "manual"}
                  disabled={false}
                  onChange={(e: any) => {
                    setAssignPeriods(e.target.value);
                  }}
                />
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Desconsolidar"
                  value={"deconsolidate"}
                  sx={{
                    color: assignPeriods === "" ? "red" : "black",
                  }}
                  checked={assignPeriods === "deconsolidate"}
                  disabled={false}
                  onChange={(e: any) => {
                    setAssignPeriods(e.target.value);
                  }}
                />
              </RadioGroup>
            </Stack>
          </Stack>
          <Stack
            sx={{
              marginTop: "0px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InputLabel id="credentials" sx={form_label}>
              Sobreescribir periodos existentes
            </InputLabel>
            <Checkbox
              checked={overwritePeriods}
              sx={{
                color: "#172D40",
                "& svg": { stroke: "#172D40" },
              }}
              onChange={() => {
                setOverwritePeriods((prev) => !prev);
              }}
            />
          </Stack>
        </Stack>
        {assignPeriods === "manual" && (
          <Stack sx={{ flexDirection: "row" }}>
            <Stack width="30%" marginRight={"30px"}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Mes{" "}
              </InputLabel>
              {delayHelper && (
                <Select
                  reference={monthSelectRef}
                  styles={selectStyles(monthSelectedOption)}
                  options={monthOptions}
                  onChange={monthChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar mes"
                  defaultValue={monthSelectedOption}
                  isClearable
                />
              )}
            </Stack>

            <Stack width="30%">
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Año{" "}
              </InputLabel>
              {delayHelper && (
                <Select
                  reference={yearSelectRef}
                  styles={selectStyles(yearSelectedOption)}
                  options={yearOptions}
                  onChange={yearChangeHandler}
                  closeMenuOnSelect
                  defaultValue={yearSelectedOption}
                  placeholder="Seleccionar año"
                  isClearable
                />
              )}
            </Stack>
          </Stack>
        )}
        {assignPeriods === "deconsolidate" && (
          <>
            <Typography sx={form_label}>Conservar periodos</Typography>
            <RadioGroup
              defaultValue=""
              name="empy-cells-options"
              sx={{ flexDirection: "row" }}
            >
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Asignar por columna"
                value={"column"}
                sx={{
                  color: conservatePeriods === "" ? "red" : "black",
                }}
                checked={conservatePeriods === "column"}
                disabled={false}
                onChange={(e: any) => {
                  setConservatePeriods(e.target.value);
                }}
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Elegir periodos a conservar"
                value={"select-periods"}
                sx={{
                  color: conservatePeriods === "" ? "red" : "black",
                }}
                checked={conservatePeriods === "select-periods"}
                disabled={false}
                onChange={(e: any) => {
                  setConservatePeriods(e.target.value);
                }}
              />
            </RadioGroup>
            {conservatePeriods === "select-periods" && (
              <>
                <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                  <Stack width="30%" marginRight={"30px"}>
                    <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                      Mes{" "}
                    </InputLabel>
                    {delayHelper && (
                      <Select
                        reference={monthSelectRef}
                        styles={selectStyles(monthSelectedOption)}
                        options={monthOptions}
                        onChange={monthChangeHandler}
                        defaultValue={monthSelectedOption}
                        closeMenuOnSelect
                        placeholder="Seleccionar mes"
                        isClearable
                      />
                    )}
                  </Stack>

                  <Stack width="30%">
                    <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                      Año{" "}
                    </InputLabel>
                    {delayHelper && (
                      <Select
                        reference={yearSelectRef}
                        styles={selectStyles(yearSelectedOption)}
                        options={yearOptions}
                        onChange={yearChangeHandler}
                        closeMenuOnSelect
                        placeholder="Seleccionar año"
                        defaultValue={yearSelectedOption}
                        isClearable
                      />
                    )}
                  </Stack>
                  <Stack sx={{ marginTop: "25px" }}>
                    <AddButton
                      onClick={addPeriod}
                      disabled={!monthSelectedOption || !yearSelectedOption}
                      width="little"
                    />
                  </Stack>
                </Stack>
                {selectedPeriods.length > 0 && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "var(--blue)",
                      fontWeight: "bold",
                      marginBottom: "2px",
                      marginTop: "8px",
                    }}
                  >
                    Periodos:
                  </Typography>
                )}
                {selectedPeriods.map((period: string) => {
                  return (
                    <SelectedPeriod
                      key={period}
                      onClick={deletePeriod}
                      period={period}
                    />
                  );
                })}
              </>
            )}
            <Stack sx={{ width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Elja el stepper a desconsolidar
              </InputLabel>
              {delayHelper && (
                <Select
                  reference={stepperToDeconsolidateSelectRef}
                  styles={selectStyles(stepperToDeconsolidateSelectedOption)}
                  options={
                    selectedSteppers !== undefined
                      ? selectedSteppers
                          .filter((s: any) => s.id !== "todas")
                          .map((s: any) => {
                            return { value: s.id, label: s.label };
                          })
                      : dataFlow?.steppers?.map((s: any) => {
                          return { value: s.id, label: s.nombre };
                        })
                  }
                  onChange={handleChangeStepperToDeconsolidate}
                  closeMenuOnSelect
                  placeholder="Seleccionar stepper"
                  defaultValue={stepperToDeconsolidateSelectedOption}
                  isDisabled={!dataFlow.desconsolidar}
                />
              )}
            </Stack>
            <Stack sx={{ width: "100%", marginTop: "5px" }}>
              <InputLabel
                id="format"
                sx={{
                  ...form_label,
                  color: "var(--blue)",
                }}
              >
                Selecciona la columna para desconsolidar
              </InputLabel>
              {delayHelper && (
                <Select
                  reference={columnSelectRef}
                  styles={selectStyles(columnSelectedOption)}
                  options={formatColumns(
                    filterIdFromColumns(stepperToDeconsolidateColumns)
                  )}
                  onChange={columnOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  defaultValue={columnSelectedOption}
                  isClearable
                  isDisabled={!dataFlow.desconsolidar}
                />
              )}
            </Stack>{" "}
          </>
        )}
      </Stack>

      {validateAccept && validateAccept().result ? (
        <Stack
          sx={{
            width: "640px",
            justifyContent: "center",
            alignItems: "center",
            pt: 3,
          }}
        >
          <Typography
            sx={{
              color: "var(--red)",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {validateAccept().resultMessage}
          </Typography>
        </Stack>
      ) : (
        ""
      )}
    </>
  );
};

export default PhiTemplateConfigurationTab;
