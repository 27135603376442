// ------------------------ Buttons ------------------------

export const button_styles = (
  width: string,
  bgcolor: string,
  disabled?: boolean,
  margin?: boolean
) => {
  return {
    alignItems: "center",
    backgroundColor: disabled
      ? "var(--dark-grey)"
      : bgcolor === "outlined"
      ? "white"
      : bgcolor,
    borderRadius: "30px",
    border: bgcolor === "outlined" ? "3px solid var(--blue)" : "none",
    color: disabled
      ? "var(--light-grey)"
      : bgcolor === "outlined"
      ? "var(--blue)"
      : "white",
    cursor: disabled ? "not-allowed" : "normal",
    display: "flex",
    fontSize: "14px",
    fontWeight: bgcolor === "outlined" ? "bold" : ("100" as any),
    height: "45px",
    justifyContent: "center",
    letterSpacing: "2px",
    margin: margin === false ? "0px" : "10px 10px",
    opacity: disabled ? "0.5" : "1",
    padding: "15px",
    textTransform: "uppercase" as any,
    transition: "all 0.4s",
    width: width,
    "&:hover": {
      backgroundColor: disabled
        ? "var(--dark-grey)"
        : bgcolor === "outlined"
        ? "var(--blue)"
        : "white",
      border: disabled
        ? "var(--light-grey)"
        : bgcolor === "outlined"
        ? "3px solid var(--blue)"
        : `2px solid ${bgcolor}`,
      color: disabled
        ? "var(--light-grey)"
        : bgcolor === "outlined"
        ? "white"
        : bgcolor,
      cursor: disabled ? "not-allowed" : "pointer",
      fontWeight: disabled ? "100" : "bold",
    },
    "&:active": {
      transform: disabled ? "none" : "scale(0.95)",
      transition: "0.3s all",
    },
  };
};

export const add_button_styles = (disabled?: boolean) => {
  return {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: disabled ? "var(--dark-grey)" : "var(--light-blue)",
    border: disabled ? "var(--light-grey)" : "1px solid var(--light-blue)",
    borderRadius: "50%",
    color: "white",
    cursor: disabled ? "not-allowed" : "normal",
    display: "flex",
    fontSize: "30px",
    height: "38px",
    opacity: disabled ? "0.5" : "1",
    textAlign: "center" as any,
    textTransform: "uppercase" as any,
    transition: "all 0.4s",
    verticalAlign: "middle",
    width: "38px",
    minWidth: "38px",
    margin: "0 0 0 10px",
    "&:hover": {
      backgroundColor: disabled ? "var(--dark-grey)" : "white",
      border: disabled
        ? "1px solid var(--light-grey)"
        : "1px solid var(--light-blue)",
      cursor: disabled ? "not-allowed" : "pointer",
      color: disabled ? "var(--light-grey)" : "var(--light-blue)",
      fontWeight: disabled ? "100" : "bold",
    },
    "&:active": {
      transform: "scale(0.95)",
      transition: "0.3s all",
    },
  };
};

// ------------------------ Flow Canva -------------------------

export const typographyh5_canva_styles = {
  color: "var(--blue)",
  textTransform: "capitalize",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  fontWeight: "bold",
  marginLeft: "30vw"

}

export const canva_title_container_styles = {
  width: "100%",
  color: "var(--blue)",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  flexDirection: "row",
}

export const canva_container_styles = (width?: any) => {
  return {
  width: "100%",
 /*  overflow: "auto", */
  alignItems: "center",
  backgroundColor: "var(--very-very-light-grey)",
  minHeight: width ? width : "65vh",
  borderRadius: "15px",
  pl: "15px",
  pr: "15px",
  pb: "20px",
  gap: "10px"
  }
}

export const canva_content_container_styles = {
  width: "97%",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}


export const canva_content_container_name = {
  flexDirection: "column",
  width:"100%",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px",
}

export const form_label_name = {
  color: "var(--blue)",
  fontWeight: "bold",
  fontSize: "var(--title)",
};

// ------------------------ Flow ToolsBar ------------------------

export const toolsbar_buttons_container_styles = {
  width: "100%",
  height: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
}

// ------------------------ Flow Modals ------------------------

export const bar_items_container_styles = (backgroundColor: any) => {
  return {
    alignItems: "center",
    backgroundColor: backgroundColor
      ? "var(--very-light-grey)"
      : "var(--very-light-blue)",
    height: "80%",
    padding: "8px",
    borderRadius: "20px",
    justifyContent: "center",
  };
};

export const item_container_styles = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  gap: "14px",
};

export const bar_container_styles = {
  alignItems: "center",
  backgroundColor: "rgba(216, 214, 214, 0.75)",  //"var(--very-light-grey)",
  borderRadius: "15px",
  boxShadow: "3px 3px 6px 2px rgba(153, 153, 153)",
  flexDirection: "row",
  gap: "4px",
  marginBottom: "8px",
  mb: "20px",
  padding: "10px 0 10px 0",
  position: "sticky",
  top: "85px",
  width: "100%",
  zIndex: 10,
};

export const modal_typography_title_styles = {
  width: "100%",
  fontSize: "18px",
  fontWeight: "600",
  color: "var(--blue)",
  textAlign: "center",
  pt: "10px",
  pb: "10px",
}

export const modal_selects_container_styles = {
  width: "100%",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-around",
}

export const shadow_box_styles  = (
  margin?: boolean,
) =>{  return {
  width: "100%",
  boxShadow:
    "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
  padding: "15px",
  borderRadius: "10px",
  marginBottom: margin? "20px" : "0",
  color: "var(--blue)",
}};

// ------------------------ Flow Stepper ------------------------
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,var(--light-blue) 0%,var(--very-light-blue) 50%,var(--very-very-light-grey) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,var(--light-blue) 0%,var(--very-light-blue) 50%,var(--very-very-light-grey) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

export const ColorlibStepIconRoot = styled("div")<{
  ownerState: {
    completed?: boolean;
    active?: boolean;
    isStepperActive?: boolean;
  };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: ownerState.isStepperActive
      ? "linear-gradient( 136deg, var(--greeny) 0%, var(--blue-greeny) 50%, var(--very-light-blue) 100%)"
      : "linear-gradient( 136deg, var(--very-light-grey) 0%, var(--very-light-blue) 50%, var(--very-light-grey) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: ownerState.isStepperActive
      ? "linear-gradient( 136deg, var(--magenta) 0%, var(--light-magenta) 50%, var(--light-red) 100%)"
      : "linear-gradient( 136deg, var(--very-light-grey) 0%, var(--very-light-magenta) 50%, var(--very-light-grey) 100%)",
  }),
}));

export const stepper_container_styles = (isActive: boolean) => {
  return {
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: isActive ? "white" : "#f3f3f3",
    position: "relative",
    borderRadius: "10px",
    boxShadow: isActive ? 2 : 0,
    "&:hover": {
      boxShadow: 8,
    },
    width: "100%",
    mb: "10px",
  };
};

export const pin_buttons_container_styles = {
  alignItems: "flex-start",
  height: "100%",
  justifyContent: "space-evenly",
  gap: "20px"
};

export const pin_button_styles = (size?: string, backgroundColor?: string) => {
  return {
    borderRadius: "50%",
    minWidth: "0",
    padding: "6px",
    background: backgroundColor
      ? backgroundColor
      : "linear-gradient(to bottom, #9a999e, #9a999ec2)",
    width: size ? size : "unset",
    height: size ? size : "unset",
    cursor: "pointer",
  };
};

export const stepper_name_styles = (isActive: boolean) => {
  return {
    color: isActive ? "var(--blue)" : "var(--light-grey)",
    margin: "0 0 8px 27px",
    textTransform: "uppercase",
    fontWeight: "bold",
  };
};

// ------------------------ Grid --------------------------

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "var(--cream)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// ------------------------ Menu --------------------------

import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";
const drawerWidth = 270;

export const appBar_buttons_container_styles = {
  borderRadius: "30px",
  border: "1px solid white",
  color: "white",
  backgroundColor: "var(--greeny)",        
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "uppercase",
  height: "40px",
  letterSpacing: "2px",
  margin: "10px",
  texTransform: "uppercase",
  transition: "all 0.4s",
  minWidth: "120px",
  padding: "0 10px",
  flexDirection:"row",
  alignItems: "center",
  justifyContent:"center",
  "&:hover": {
    border: "none",
    color: "var(--greeny)",
    backgroundColor: "white",
    cursor: "pointer"
  },
}

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const companySelectorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390,
  height: 280,
  bgcolor: "transparent",
  borderRadius: 50,
  "&:focus": {
    outline: "none",
    border: "none",
  },
};

export const menu_items_container = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const menu_logo_user_container = {
  display: "flex",
  alignItems: "center",
};

export const menu_logo_container = {
  display: "flex",
  alignItems: "center",
  marginRight: "20px",
  "&:hover": {
    cursor: "pointer",
  },
};

export const menu_logo = {
  height: "30px",
  marginRight: "10px",
};

export const menu_logo_alpha_styles = {
  marginRight: "5px",
  width: "90px",
  marginBottom: "5px",
};


export const menu_logo_little_alpha_styles = {
  marginRight: "5px",
  width: "90px",
};

export const menu_logo_phi_folder_styles = {
  marginRight: "5px",
  width: "115px",
  marginBottom: "5px",
};

export const menu_logo_container_header = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const menu_header_option_container = {
  display: "flex",
  "&:hover": {
    cursor: "pointer",
    color: "var(--very-light-blue)",
  },
};

export const menu_header_option = {
  margin: "0 15px",
  "&:hover": {
    cursor: "pointer",
    color: "var(--very-light-blue)",
  },
};

export const menu_header_company = {
  margin: "0 12px",
  "&:hover": {
    cursor: "pointer",
    color: "var(--very-light-blue)",
  },
};

export const menu_phi = {
  fontFamily: "'Stint Ultra Expanded', sans-serif",
  fontSize: "18px",
  fontWeight: 900,
  letterSpacing: "4px",
  color: "white",
};

export const company_box_styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390,
  height: 280,
  bgcolor: "transparent",
  borderRadius: 50,
  "&:focus": {
    outline: "none",
    border: "none",
  },
};

export const list_item_text_styles = (open: boolean) => {
  return {
    opacity: open ? 1 : 0,
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  };
};

export const list_item_button_styles = (open: boolean) => {
  return {
    minHeight: 20,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    color: "white",
  };
};

export const list_item_icon_styles = (open: boolean) => {
  return {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    margin: "0 8px 0 8px",
  };
};

export const list_item_selected_styles = (
  isOptionSelected: boolean | undefined
) => {
  return {
    display: "block",
    "& .MuiListItemButton-root": {
      margin: "1.5px 6px 0 5px",
      padding: "5px 5px",
      borderRadius: "10px",
      backgroundColor: isOptionSelected ? "var(--light-grey)" : "",
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "var(--light-grey)",
    },
  };
};

export const hovered_list_item_styles = {
  "& .MuiListItemButton-root:hover": {
    borderRadius: "10px",
    backgroundColor: "var(--blue-greeny)",
  },
};

// ------------------------ Tables ------------------------

export const columnTitleStyle = {
  color: "white",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "14px",
  textOverflow: "ellipsis",
  lineHeight: 1.2,
  overflowWrap: "break-word",
  textAlign: "center",
};

export const editIconStyle = {
  marginRight: "7px",
  marginBottom: "-3px",
  cursor: "pointer",
  color: "#a6a6a8",
  "& :hover": { color: "var(--blue)" },
};

export const titles_row_container_styles = {
  //reemplaza a .cc_external_row_container con un stack
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "5px",
};

export const column_styles = (bgcolor?: string, color?: string) => {
  return {
    backgroundColor: bgcolor ? bgcolor : "var(--light-blue)",
    color: color ? color : "white",
    fontWeight: "bold",
    borderRadius: "5px",
  };
};

export const text_styles = {
  color: "var(--blue)",
  padding: 0,
};

// ------------------------ Texts ------------------------

export const title_styles = {
  fontSize: "var(--title)",
  fontWeight: "bold",
};

export const form_label = {
  color: "var(--blue)",
  fontWeight: "bold",
  fontSize: "var(--text)",
};

// ------------------------ Verification ------------------------

export const verification_container_styles = {
  alignItems: "center",
  backgroundColor: "#172d40",
  color: "var(--blue)",
  flexDirection: "row",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const verification_form_styles = {
  alignItems: "center",
  background: "white",
  borderRadius: "10px",
  boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "35%",
  minWidth: "400px",
  paddingTop: "20px",
};

export const verification_form_box_styles = {
  alignItems: "center",
  color: "black",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "450px",
};

export const verification_logo_container = {
  margin: "15px 15px",
};

export const verification_description_styles = {
  textAlign: "justify",
  width: "70%",
  margin: "10px 0",
};

export const verification_password_container_styles = {
  position: "relative",
  width: "280px",
};

export const verificaction_form_input_styles = {
  appearance: "none" as any,
  borderRadius: "4px",
  border: "1px solid var(--blue)",
  color: "var(--blue)",
  display: "block",
  fontSize: "14px",
  marginBottom: "10px",
  padding: "10px 15px",
  width: "100%",
};

export const verification_message_styles = {
  color: "var(--blue-greeny)",
  fontSize: "14px",
  fontWeight: "bold",
  margin: "0",
  textAlign: "center",
  width: "400px",
  paddingTop: "3px",
};

export const verification_password_icon_styles = {
  bottom: "7px",
  color: "var(--blue)",
  position: "absolute",
  right: "10px",
  top: "7px",
  ":hover": { cursor: "pointer" },
  ":active": { cursor: "pointer" },
};

export const verification_resend_styles = {
  color: "#4d4c50",
  fontSize: "14px",
  marginTop: "10px",
  textAlign: "center",
  textDecoration: "underline",
  ":hover": { cursor: "pointer", textDecoration: "none" },
};

// ------------------------ Layout ------------------------

export const component_layout_container_styles = {
  justifyContent: "center",
  alignItems: "center",
  width: "95%",
  marginTop: "35px",
};

export const title_layout_container_styles = (rightComponent?: JSX.Element) => {
  return {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: rightComponent ? "space-between" : "flex-start",
    width: "100%",
    marginBottom: rightComponent ?"5px" :"20px",
    borderBottom: "1px var(--very-light-grey) solid",
  };
};

export const icon_layout_styles = {
  flexDirection: "row",
  alignItems: "center",
  color: "var(--blue)",
  gap: "10px",
};

export const title_layout_styles = {
  fontSize: "1.4rem",
  fontWeight: "600",
  color: "var(--blue)",
  textTransform: "uppercase",
  fontFamily: "Verdana",
  marginBottom: "8px",
};

export const layout_menu_container_styles = {
  width: "50px",
  zIndex: "10",
};

export const layout_container_styles = {
  display: "flex",
  alignItems: "baseline",
};

export const layout_outlet_container_styles = {
  zIndex: "1",
  width: "100%",
  display: "flex",
  flexdirection: "column",
  justifyContent: "center",
  alignItems: "center",
  top: "90px",
  marginTop: "40px",
};

// ------------------------ Login ------------------------

export const persist_login_container_styles = {
  width: "100vw",
  height: "100vh",
  background: "var(--blue)",
  justifyContent: "center",
  alignItems: "center",
};

export const login_container_styles = {
  alignItems: "center",
  backgroundColor: "#172d40",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const login_title_styles = {
  borderBottom: "1px solid #172d40",
  color: "#172d40",
  fontWeight: "100",
  textAlign: "center",
  fontSize: "2em",
  margin: "revert",
};

// ------------------------ Company -----------------------------

export const company_container_styles = {
  alignItems: "center",
  backgroundColor: "var(--blue)",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const company_form_container_styles = {
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
  margin: "0 auto",
  maxWidth: "35%",
  minWidth: "400px",
};

export const company_logo_container_styles = {
  margin: "15px 15px",
};

export const company_logo_styles = {
  marginTop: "20px",
  width: "240px",
};

export const company_title_styles = {
  color: "var(--blue)",
  marginTop:"0"
};

export const company_form_box_styles = {
  alignItems: "center",
  color: "black",
  margin: "0 auto",
  width: "80%",
};

export const container_100_styles = {
  width: "100%",
};

export const error_text_styles = {
  fontWeight: "bold",
  color: "var(--magenta)",
  textAlign: "center",
  mt: "10px",
};

export const company_buttons_container_styles = {
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "20px 0",
  width: "100%",
}