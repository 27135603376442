import { useContext } from "react";
import InfoContext from "../context/InfoProvider";

const useInfoContext = () => {
    return useContext(InfoContext);
}

export default useInfoContext;


