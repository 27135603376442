import { Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";


interface ReloadResourcesProps {
 refetchResources: ()=>void
 isSingleResource?: boolean
}

const ReloadResources = ({
    refetchResources,
    isSingleResource
}: ReloadResourcesProps) => {
 

  return (
    <Typography
    sx={{
      fontWeight: "600",
      color: "var(--blue)",
      fontSize:"14px",
      display: "flex",
      alignItems: "center",
      textTransform:"uppercase",
      cursor: "pointer"
    }}
    onClick={()=>refetchResources()}
  >
    Recargar {isSingleResource ? "recurso" : "recursos"}
    <CachedIcon sx={{ marginLeft: "3px", marginBottom:"2px" }} />
  </Typography>
  );
};

export default ReloadResources;