import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routes, Route } from "react-router-dom";
import { Layout } from "./components/layout";
import { Login } from "./components/login";
import { CompanySelector } from "./components/company";
import { Unauthorized } from "./components/unauthorized";
import Error from "./components/error";
import RequireAuth from "./components/auth/RequireAuth";
import PersistLogin from "./components/login/PersistLogin.component";
import { CodeVerification } from "./components/codeVerification";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SnackbarProvider } from "notistack";
import "./App.css";
import { InfoProvider } from "./context/InfoProvider";
import PipelineAdmin from "./components/pipelines/PipelineAdmin";
import { PipelinesStepperProvider } from "./context/PipelinesStepperProvider";
import SchemaAdmin from "./components/schemas/SchemaAdmin";
import { SchemasProvider } from "./context/SchemasContext";
import { FlowsProvider } from "./context/FlowsContext";
import { DataManagementProvider } from "./context/DataManagementContext";
import SchemeExecution from "./components/schemeExecution/SchemeExecution";
import ConfigPipelines from "./components/pipelines/ConfigPipelines";
import ConfigSchemas from "./components/schemas/ConfigSchemas";
import TaskManager from "./components/taskManager/TaskManager";
import InformationSummary from "./components/summary/InformationSummary";
import { SummaryProvider } from "./context/SummaryContext";
import CodeValidation from "./components/codeValidation/CodeValidation";
import ConfigFlows from "./components/flowConfiguration/ConfigFlows";
import FlowAdmin from "./components/flowAdmin/FlowAdmin";
import FlowExecution from "./components/flowExecution/FlowExecution";
import { LanguageProvider } from "./context/LanguageProvider";
import DataManagement from "./components/dataManagement/DataManagement";
import Folder from "./components/dataManagement/Folder";
import CodeValidationAdmin from "./components/codeValidation/CodeValidationAdmin";
import InformationSummaryAdmin from "./components/summary/InformationSummaryAdmin";

/**
 * @component
 * @name App
 * @description - Componente que muestra la aplicación con sus rutas.
 * @returns {JSX.Element}
 * @category App
 */

function App() {
  const queryClient = new QueryClient();
  LicenseInfo.setLicenseKey(
    "7d37cc8b460d3324f9c037b1c6de01e9Tz04MDAzMyxFPTE3MzM0MjUwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <SnackbarProvider maxSnack={3} className="">
          <InfoProvider>
          <DataManagementProvider>
            <Routes>
              <Route element={<PersistLogin />}>
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="unauthorized" element={<Unauthorized />} />

              <Route path="verificacion" element={<CodeVerification />} />

              <Route element={<PersistLogin />}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="empresa" element={<CompanySelector />} />
                </Route>
              </Route>

              <Route element={<PersistLogin />}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor", "cliente"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route path="/" element={<DataManagement />} />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="consolidacion-archivos/administracion-pipelines"
                      element={<PipelineAdmin />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor", "cliente"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="/administracion-datos"
                      element={<DataManagement />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor", "cliente"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="/administracion-datos/carpeta/*"
                      element={<Folder/>}
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="consolidacion-archivos/configuracion-pipelines"
                      element={
                        <PipelinesStepperProvider>
                          <ConfigPipelines />
                        </PipelinesStepperProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-flujos"
                      element={
                        <FlowsProvider>
                          <FlowAdmin />
                        </FlowsProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="ejecucion-flujos"
                      element={<FlowExecution />}
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-flujos/configuracion-flujos"
                      element={
                        <FlowsProvider>
                          <ConfigFlows />
                        </FlowsProvider>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="consolidacion-archivos/administracion-esquemas"
                      element={<SchemaAdmin />}
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="consolidacion-archivos/configuracion-esquemas"
                      element={
                        <SchemasProvider>
                          <ConfigSchemas />
                        </SchemasProvider>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route path="gestor-tareas" element={<TaskManager />} />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="consolidacion-archivos/ejecucion-esquema"
                      element={<SchemeExecution />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="reporte-base/administracion-resumenes"
                      element={
                        <SummaryProvider>
                          <InformationSummaryAdmin />
                        </SummaryProvider>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="reporte-base/configuracion-resumenes"
                      element={
                        <SummaryProvider>
                          <InformationSummary />
                        </SummaryProvider>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="reporte-base/administracion-validaciones"
                      element={<CodeValidationAdmin />}
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="reporte-base/configuracion-validaciones"
                      element={<CodeValidation/>}
                    />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["administrador", "consultor"]}
                    />
                  }
                >
                  <Route path="/*" element={<Error />} />
                </Route>
              </Route>
            </Routes>
            </DataManagementProvider>
          </InfoProvider>
        </SnackbarProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
