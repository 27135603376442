import Dialog from "../dialog/Dialog";
import Stack from "@mui/material/Stack";
import { Button } from "../buttons";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isValidName } from "../schemas/utils";
interface SavePipelineModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  title: string;
  text: string;
  label: string;
  placeholder: string;
  dispatchFunction?: any;
  dispatchType?: string;
  customValidationFunction?: (name:string)=> {error: boolean, message?: string},
  valueState: string;
  namesList: any[];
  editName?: string
}

const SaveNameModal = ({
  open,
  handleClose,
  handleAccept,
  title,
  text,
  label,
  placeholder,
  dispatchFunction,
  dispatchType,
  valueState,
  namesList,
  editName,
  customValidationFunction
}: SavePipelineModalProps) => {

  const handleSaveName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchType !== undefined ? dispatchFunction({
      type: dispatchType,
      payload: event.target.value,
    }) : dispatchFunction( event.target.value)
  };

  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="md" title={title}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "400px",
          alignItems: "center",
          minHeight: "130px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "20px",
            color: "#172D40",
            maxWidth: "400px",
            textAlign: "center",
            wordBreak:"break-word"
          }}
        >
          {text}
        </Typography>
        <TextField
          id="filled-textarea"
          label={label}
          placeholder={placeholder}
          size="small"
          multiline
          variant="outlined"
          helperText={
            !isValidName(namesList, valueState, editName !== undefined, editName) ? "El nombre ya existe" : (customValidationFunction !== undefined && customValidationFunction(valueState).error ?  customValidationFunction(valueState).message : "")
          }
          error={false}
          fullWidth
          value={valueState}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSaveName(event)
          }
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          required
        />

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={handleAccept}
            color="blue-greeny"
            disabled={!valueState || !isValidName(namesList, valueState, editName !== undefined, editName) || (customValidationFunction !== undefined && customValidationFunction(valueState).error) }
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SaveNameModal;
