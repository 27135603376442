import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
interface SchemaGridProps {
  info: any;
  RowTitles: any;
  RowSelects: any;
  RowInfo: any;
  setMessage?: (message: string) => void;
  columns?: any;
}

const InfoGrid = ({
  info,
  RowTitles,
  RowSelects,
  RowInfo,
  setMessage,
  columns,
}: SchemaGridProps) => {
  return (
    <Stack
      sx={{
        marginLeft: "0px",  
        color: "var(--blue) !important"      
      }}
      alignItems={"center"}
      width="100%"     
    >
      <Grid
        sx={{
          marginLeft: "0px",
        }}
        container
        spacing={2}
      >
        <RowTitles />
        <RowSelects setMessage={setMessage} columns={columns} />
        {info && info.length ? (
          info.map((row: any, index: number) => (
            <RowInfo key={index} row={row} />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
            >
              Aún no agregaste lineas
            </Typography>
          </Box>
        )}
      </Grid>
    </Stack>
  );
};

export default InfoGrid;
