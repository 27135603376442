import { Box, Checkbox, FormControlLabel, Stack, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { isNameRepeated } from "../../../pipelines/utils";

interface RenameGeneratedColumnProps {
  row: any;
  setColumnsError: React.Dispatch<React.SetStateAction<any[]>>;
  newObjectNames: any;
  setNewObjectNames: (newName: string, column: string) => void;
  existingColumns: string[]
}

const RenameGeneratedColumn = ({
  row,
  setColumnsError,
  newObjectNames,
  setNewObjectNames,
  existingColumns
}: RenameGeneratedColumnProps) => {
  const [inputValue, setInputValue] = useState(row?.renombre);
  const [error, setError] = useState<boolean | string>(false);
  const [isActive, setIsActive] = useState<boolean>(row?.renombre !== undefined);

  /**
   * @description Esta función se utiliza para manejar cambios en el valor de una columna. Actualiza el valor de entrada, realiza cambios en un objeto de nombres, y gestiona errores relacionados con la columna.
   *
   * @param {string} value - El nuevo valor que se desea asignar a la columna.
   * @returns {void} - Esta función no devuelve un valor explícito, ya que opera directamente sobre el estado interno y los errores de la columna.
   */

  const handleChange = (value: string) => {
    setInputValue(value);
    // Actualiza el objeto de nombres con el nuevo valor asignado a la columna
    setNewObjectNames(value, row.column.value);
    if (value.length < 1) {
      setError("El nombre de la columa no puede estar vacío");
      setColumnsError((prev: any[]) => {
        return [...prev, row.column.value];
      });
    } else {
      const isDuplicatedName = isNameRepeated(
        newObjectNames.map((col: any)=>{return col.renombre}),
        value
      );
      if (isDuplicatedName) {
        setError("Ya existe una columna con este nombre");
        setColumnsError((prev: any[]) => {
          return [...prev, row.column.value];
        });
      } else {
        setError(false);
        setColumnsError((prev: any[]) => {
          return prev?.filter((columna: any) => {
            return columna !== row.column.value;
          });
        });
      }
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Stack
      sx={{
        alignItems: "stretch",
        flexDirection: "row",
        margin: "13px 0px",
        width: "90%",
      }}
    >
      <FormControlLabel
        control={<Checkbox color="secondary" />}
        label=""
        checked={isActive}
        onChange={handleChangeIsActive}
      />
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "var(--blue)",
          borderRadius: "4px",
          color: "white",
          display: "flex",
          fontSize: row.column.label.length > 17 ? "14px" : "16px",
          justifyContent: "center",
          marginRight: "8px",
          minWidth: "350px",
          textAlign: "center",
          width: "50%",
          wordBreak: "break-all",
        }}
      >
        {`${row.column.label} (${row.function.label})`}
      </Box>
      <TextField
        sx={{
          height: "100%",
          minWidth: "300px",
          padding: "0 !important",
          width: "50%",
        }}
        size="small"
        multiline
        variant="outlined"
        disabled={!isActive}
        helperText={error}
        error={!!error}
        fullWidth
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value);
        }}
        FormHelperTextProps={{
          sx: {
            color: "var(--magenta)",
            margin: 0,
          },
        }}
        required
      />
    </Stack>
  );
};

export default RenameGeneratedColumn;
