import Stack from "@mui/material/Stack";
import FlowsProvider from "../../../context/FlowsContext";
import FlowStepper from "../FlowStepper/FlowStepper";
import { useContext, useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Box, InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import PinButton from "../FlowStepper/PinButton";
import useApi from "../../../hooks/useApi";
import {
  CHANGE_FLOW_FONTS_URL,
  FLOW_URL,
  GET_FLOW_URL,
  PUT_NAME_FLOW_URL,
} from "../../../api/axios";
import { texts } from "../../../texts.js";
import EditIcon from "@mui/icons-material/Edit";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CachedIcon from "@mui/icons-material/Cached";
import {
  canva_container_styles,
  canva_content_container_name,
  canva_content_container_styles,
  canva_title_container_styles,
  form_label,
  form_label_name,
  typographyh5_canva_styles,
} from "../../../styles/app-styles";
import { areFontsWithError, formatFonts, handleSetItemBarStatus } from "../utils";
import InfoIcon from "@mui/icons-material/Info";
import { useApiQuery } from "../../../hooks/useApiQuery";

interface FlowCanvaProps {
  isLoadingFlow: boolean;
}

const FlowCanva = ({ isLoadingFlow }: FlowCanvaProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemsBarStatus } = flowState;
  const [flowName, setFlowName] = useState<string>("");

  const [openInputChangeName, setOpenInputChangeName] =
    useState<boolean>(false);

  useEffect(() => {
    handleSetItemBarStatus(
      flowDispatch,
      data_flow,
      active_stepper,
      itemsBarStatus
    );
  }, [active_stepper, data_flow]);

  /* useEffect(() => {
    window.addEventListener('beforeunload', ()=>{
      localStorage.removeItem("flowId");
    })
    return () => {
      localStorage.removeItem("flowId");
    };
  }, []); */

  /******************************* LLAMADAS *********************************************/

  const onSuccessPostFlowName = (data: any) => {
    setOpenInputChangeName(false);
    flowDispatch({
      type: "SET_FLOW_NAME",
      payload: {
        nombre: flowName,
        id: data.id,
      },
    });
    localStorage.setItem("flowId", data.id);
  };

  const { isLoading: isLoadingPostFlowName, callApi: postFlowName } = useApi(
    FLOW_URL,
    "POST",
    texts.flows.postFlowName.codes,
    { nombre: flowName },
    onSuccessPostFlowName
  );

  const { isLoading: isLoadingPostNewFlowName, callApi: putNewFlowName } =
    useApi(
      PUT_NAME_FLOW_URL(data_flow?.id),
      "PUT",
      texts.flows.postFlowName.codes,
      { nombre: flowName },
      onSuccessPostFlowName
    );

  const { data: dataFlows } = useApiQuery(
    FLOW_URL,
    true,
    texts.flows.getFlows.error
  );

  const onSuccessGetFlow = (data: any) => {
    postChangeFonts(
      undefined,
      formatFonts(data).map((font: any) => {
        return {
          stepper: font.idStepper,
          hoja: font.hoja ? font.hoja : undefined,
        };
      })
    );
  };

  const {
    isLoading: isLoadingGetFlow,
    data: dataFlow,
    callApi: getDataFlow,
  } = useApi(
    GET_FLOW_URL(data_flow.id),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  const onSuccessPostItem = (data: any) => {
    flowDispatch({
      type: "SET_DATA_FLOW",
      payload: data,
    });
    handleSetItemBarStatus(flowDispatch, data, active_stepper, itemsBarStatus);
  };

  const { isLoading: isLoadingChangeFonts, callApi: postChangeFonts } = useApi(
    CHANGE_FLOW_FONTS_URL(data_flow.id),
    "PUT",
    texts.flows.recalculateFonts.codes,
    undefined,
    onSuccessPostItem
  );

  /******************************* HANDLERS *********************************************/

  const isNameDuplicated = () => {
    return (
      !!dataFlows &&
      !!dataFlows?.find(
        (flow: any) =>
          flow.nombre.toLowerCase().trim() === flowName.toLowerCase().trim()
      )
    );
  };

  const handleChangeInputFlowName = () => {
    setOpenInputChangeName(!openInputChangeName);
  };

  const handleRecalculateFonts = () => {
    getDataFlow()
  };

  return (
    <Stack sx={canva_container_styles(data_flow?.nombre === "" && "80vh")}>
      <SimpleBackdrop
        open={isLoadingPostFlowName}
        message={texts.flows.postFlowName.loading}
      />
      <SimpleBackdrop
        open={isLoadingPostNewFlowName}
        message={texts.flows.postFlowName.loading}
      />
      <SimpleBackdrop
        open={isLoadingGetFlow || isLoadingChangeFonts}
        message={texts.flows.recalculateFonts.loading}
      />
      {data_flow && !isLoadingFlow && (
        <Stack sx={canva_content_container_styles}>
          {data_flow?.id === "" ? (
            <Stack sx={canva_content_container_name}>
              {data_flow?.nombre === "" && (
                <InputLabel sx={form_label_name}>
                  Ingrese el nombre del flujo
                </InputLabel>
              )}
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  error={isNameDuplicated()}
                  helperText={
                    isNameDuplicated() && "Ya existe un flujo con ese nombre"
                  }
                  label="Nombre del flujo"
                  sx={{ width: "50%", mr: "20px", bgcolor: "white" }}
                  value={flowName}
                  onChange={(e) => setFlowName(e.target.value)}
                  placeholder="Nombre del flujo"
                  autoComplete="off"
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "var(--blue)",
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                      margin: 0,
                      backgroundColor: "var(--very-very-light-grey)",
                    },
                  }}
                />
                <PinButton
                  Icon={DoneIcon}
                  tooltipTitle={"Aceptar"}
                  size={"50px"}
                  backgroundColor={
                    isNameDuplicated()
                      ? "var(--light-grey)"
                      : "linear-gradient(to bottom, var(--greeny), var(--blue-greeny))"
                  }
                  /* disabled={isNameDuplicated() || flowName === ""} */
                  onClick={
                    isNameDuplicated() ? () => <></> : () => postFlowName()
                  }
                />
              </Stack>
            </Stack>
          ) : openInputChangeName ? (
            <Stack
              sx={{ ...canva_title_container_styles, alignItems: "flex-start" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Modificar nombre del flujo"
                error={isNameDuplicated()}
                helperText={
                  isNameDuplicated() && "Ya existe un flujo con ese nombre"
                }
                sx={{ width: "50%", mr: "20px", bgcolor: "white" }}
                value={flowName}
                onChange={(e) => setFlowName(e.target.value)}
                placeholder={data_flow?.nombre}
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "var(--blue)",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    margin: 0,
                    backgroundColor: "var(--very-very-light-grey)",
                  },
                }}
              />
              <Box sx={{ display: "flex", gap: 1, mt: "11px" }}>
                <PinButton
                  Icon={CheckCircleRoundedIcon}
                  tooltipTitle={"Confirmar"}
                  size={"30px"}
                  onClick={
                    isNameDuplicated() ? () => <></> : () => putNewFlowName()
                  }
                  tooltipPlacement={"bottom"}
                  backgroundColor={
                    isNameDuplicated()
                      ? "var(--light-grey)"
                      : "var(--blue-greeny)"
                  }
                />
                <PinButton
                  Icon={CancelRoundedIcon}
                  tooltipTitle={"Cancelar"}
                  size={"30px"}
                  onClick={handleChangeInputFlowName}
                  tooltipPlacement={"bottom"}
                  backgroundColor={"var(--dark-grey)"}
                />
              </Box>
            </Stack>
          ) : (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                  color: areFontsWithError(data_flow) ? "var(--light-grey)" : "var(--blue)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "uppercase",
                  width: "230px",
                  cursor:!areFontsWithError(data_flow) ?"pointer" : "initial"
                }}
                onClick={!areFontsWithError(data_flow) ? handleRecalculateFonts : undefined}
              >
                Recalcular fuentes
                <CachedIcon sx={{ marginLeft: "3px", marginBottom: "2px" }} />
                <Tooltip title="Actualiza las muestras si es necesario porque hubo un cambio en el archivo original">
                <InfoIcon
                  sx={{
                    color: "var(--blue)",
                    fontSize: "21px",
                    cursor: "pointer",
                    marginLeft: "3px",
                    marginBottom: "4px",
                  }}
                />
              </Tooltip>
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={typographyh5_canva_styles}
              >
                {data_flow?.nombre}
                <PinButton
                  Icon={EditIcon}
                  tooltipTitle={"Editar nombre"}
                  size={"30px"}
                  backgroundColor={"var(--very-light-blue)"}
                  onClick={handleChangeInputFlowName}
                />
              </Typography>
            </>
          )}
        </Stack>
      )}
      {/* {openInputChangeName &&
        dataFlows &&
        dataFlows?.find((flow: any) => flow.nombre === flowName) && (
          <Typography
            sx={{
              color: "var(--red)",
            }}
          >
            Ya existe un flujo con ese nombre
          </Typography>
        )} */}
      {data_flow?.id !== "" &&
        data_flow?.steppers?.map((stepper: any) => {
          return (
            <FlowStepper
              key={stepper.id}
              stepper={stepper}
              activeStepper={active_stepper}
            />
          );
        })}
    </Stack>
  );
};

export default FlowCanva;

