import { useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import { EXTERNAL_CONNECTION_CONFIGURATION_BY_ID, GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS } from "../../../../api/axios";
import { Box, Stack, Typography, Tooltip } from "@mui/material";
import { Button } from "../../../buttons";
import Dialog from "../../../dialog/Dialog";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useDialog from "../../../../hooks/useDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../../../dialog";
import IngestionConfigurationResume from "./IngestionConfigurationResume";


  interface IngestionConfigurationDetailProps {
    configuration: any | undefined
    open: boolean
    handleClose: ()=>void
  }
  
  const IngestionConfigurationDetail = ({
    configuration,
    open,
    handleClose
  }: IngestionConfigurationDetailProps) => {

  
    return (
    
        <Dialog
          open={open}
          handleClose={handleClose}
          maxWidth="xl"
          title={"Detalle de configuración"}
        >
       
           <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "30vw",
              alignItems: "center",
            }}
          >
                 <Typography
                    sx={{ color: "var(--blue)", fontSize: "18px", fontWeight:"bold" }}
                  >
                    Detalle de la configuración de ingesta
                  </Typography>
        
             <IngestionConfigurationResume configuration={configuration}/>
            </Box>
            <Stack
              direction="row"
              sx={{
                alignSelf: "center",
                marginTop: "auto",
                justifyContent: "center",
              }}
            >
              <Button
                title="Cerrar"
                type="button"
                onClick={handleClose}
                color="light-grey"
              />
              
            </Stack>
          </Box>
        </Dialog>
    );
  };
  
  export default IngestionConfigurationDetail;
  