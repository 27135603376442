import {
  Box,
  Stack,
} from "@mui/material";
import { useRef, useState } from "react";
import useDialog from "../../../../../hooks/useDialog";
import useApi from "../../../../../hooks/useApi";
import { texts } from "../../../../../texts";
import { useApiQuery } from "../../../../../hooks/useApiQuery";
import { Button } from "../../../../buttons";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";
import Dialog from "../../../../dialog/Dialog";
import { isValidName } from "../../../../schemas/utils";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import {
  EXTERNAL_CONNECTION_CONFIGURATION,
  GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS,
  GET_EXTERNAL_CONNECTION_COLUMNS,
} from "../../../../../api/axios";
import { Tab, TabValue } from "../../../../colorTabs/types";
import ColorTabs from "../../../../colorTabs/ColorTabs";
import useMultiSelect from "../../../../../hooks/useMultiSelect";
import { getOperator, validateSQLQuery } from "../../../../../utils/util";
import IngestionConfiguration from "./IngestionConfiguration";

interface CreateIngestionConfigurationModalProps {
  resource_id: number;
  table: string;
  schema?: string;
  catalog?: string;
  refetchConfigurations: (url: string) => void;
  configurationsNames: any[];
  connectionType: string;
}

const CreateIngestionConfigurationModal = ({
  resource_id,
  table,
  schema,
  catalog,
  refetchConfigurations,
  configurationsNames,
  connectionType,
}: CreateIngestionConfigurationModalProps) => {
  const [configurationName, setConfigurationName] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [SQLQuery, setSQLQuery] = useState<string>("");
  const [openCreateConfigurationModal, toggleCreateConfigurationModal] =
    useDialog();
  const [distinct, setDistinct] = useState(false);
  const [filterCondition, setFilterCondition] = useState<any[]>([]);
  const [aggregations, setAggregations] = useState<any[]>([]);
  const [limitValue, setLimitValue] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<TabValue>("MANUAL");

  const tabs: Tab[] = [
    {
      value: "MANUAL",
      label: "MANUAL",
    },
    {
      value: "CONSULTA SQL",
      label: "CONSULTA SQL",
    },
  ];

  const fileFormatRef: any = useRef(null);
  const conditionalColumnRef: any = useRef(null);
  const orderByColumnRef: any = useRef(null);
  const groupByColumnRef: any = useRef(null);
  const orderTypeRef: any = useRef(null);

  const [
    fileFormatSelectedOption,
    setFileFormatSelectedOption,
    fileFormatChangeHandler,
  ] = useSingleSelect();

  const [
    orderByColumnSelectedOption,
    setOrderByColumnSelectedOption,
    orderByColumnChangeHandler,
  ] = useSingleSelect();

  const [
    groupByColumnsSelectedOption,
    setGroupByColumnSelectedOption,
    groupByColumnChangeHandler,
  ] = useMultiSelect();

  const [
    orderTypeSelectedOption,
    setOrderTypeSelectedOption,
    orderTypeChangeHandler,
  ] = useSingleSelect();

  /*************************** LLAMADAS ***************************/

  const { data: columns, isLoading: isLoadingColumns } = useApiQuery(
    GET_EXTERNAL_CONNECTION_COLUMNS(resource_id, table, catalog, schema),
    false,
    texts.dataManagement.getExternalConnectionColumns.error
  );

  const {
    data: createdConf,
    isLoading: isLoadingPostConfiguration,
    callApi: postConfiguration,
    error: errorPostConfiguration,
  } = useApi(
    EXTERNAL_CONNECTION_CONFIGURATION,
    "POST",
    texts.dataManagement.postExternalConnectionConfiguration.codes,
    undefined,
    () =>
      refetchConfigurations(
        GET_EXTERNAL_CONNECTIONS_CONFIGURATIONS(resource_id)
      ),
    undefined,
    true
  );

  /*************************** HANDLERS **************************/

  const getOperations = () => {
    const operations: any[] = [
      {
        tipo: "select",
        columnas_incluir:
          selectedColumns
            ?.map((col: any) => col.id)
            .filter((col: string) => col !== "todas")?.length ===
          columns?.length
            ? []
            : selectedColumns
                ?.map((col: any) => col.id)
                .filter((col: string) => col !== "todas"),
      },
      {
        tipo: "limite",
        valor: limitValue ? parseInt(limitValue) : "",
      },
    ];
    if (
      filterCondition &&
      filterCondition.length > 0 &&
      filterCondition[0]?.columna_condicional?.value !== undefined
    ) {
      filterCondition.forEach((condition: any) => {
        if (
          condition.columna_condicional &&
          condition.operador &&
          condition.valor.valor_comparar
        ) {
          return operations.push({
            tipo: "filtro",
            funcion: condition.funcion ? condition.funcion : undefined,
            columna_inicio: condition.columna_condicional?.value,
            operador: getOperator(condition.operador?.label),
            tipo_comparacion: condition.valor.tipo,
            condicion_logica: condition.operador_filtro === "Y" ? "AND" : "OR",
            columna_valor_final:
              condition.valor.tipo === "columna"
                ? condition.valor.valor_comparar.value
                : condition.valor.valor_comparar,
          });
        }
      });
    }
    if (orderByColumnSelectedOption?.value) {
      operations.push({
        tipo: "orden",
        columna: orderByColumnSelectedOption?.value,
        tipo_orden:
          orderTypeSelectedOption?.value === "Ascendente" ? "ASC" : "DESC",
      });
    }
    if (
      groupByColumnsSelectedOption &&
      groupByColumnsSelectedOption?.length > 0
    ) {
      operations.push({
        tipo: "group_by",
        columnas_agrupar: groupByColumnsSelectedOption.map(
          (col: any) => col.value
        ),
      });
    }
    if (
      aggregations &&
      aggregations?.length > 0 &&
      groupByColumnsSelectedOption &&
      groupByColumnsSelectedOption.length > 0
    ) {
      aggregations.forEach((aggregation: any) => {
        if (aggregation.funcion && aggregation.columna_valor_funcion) {
          return operations.push({
            tipo: "function",
            funcion: aggregation.funcion,
            columna_valor_funcion: aggregation.columna_valor_funcion,
            columna_nueva: aggregation.columna_nueva,
          });
        }
      });
    }
    return operations;
  };

  const handleAccept = () => {
    postConfiguration(undefined, {
      nombre: configurationName,
      nombre_archivo: fileName,
      extension: fileFormatSelectedOption?.value,
      tipo: tabValue === "MANUAL" ? "manual" : "consulta_sql",
      distinct: distinct,
      recurso_databricks: resource_id,
      catalogo: catalog,
      esquema: schema,
      tabla: table,
      operaciones: tabValue === "MANUAL" ? getOperations() : undefined,
      consulta: tabValue !== "MANUAL" ? SQLQuery : undefined,
    });
    cleanAndClose();
  };

  /*************************** VALIDACIONES **************************/

  const isFormCompleted = () => {
    if (tabValue === "MANUAL") {
      return (
        configurationName.length > 0 && fileFormatSelectedOption !== undefined && 
        isValidName(configurationsNames, configurationName, false) &&
        selectedColumns.length > 0 &&
        (orderByColumnSelectedOption?.value
          ? orderByColumnSelectedOption?.value &&
            orderTypeSelectedOption?.value !== undefined
          : true) &&
        (filterCondition &&
        filterCondition?.length > 0 &&
        filterCondition[0]?.columna_condicional?.value !== undefined
          ? filterCondition[0]?.columna_condicional?.value !== undefined &&
            filterCondition[0]?.operador?.label !== undefined &&
            filterCondition[0]?.valor.valor_comparar !== undefined
          : true)  && (groupByColumnsSelectedOption && groupByColumnsSelectedOption?.length > 0 ? groupByColumnsSelectedOption?.length ===  selectedColumns
            ?.map((col: any) => col.id)
            .filter((col: string) => col !== "todas")?.length: true)
      );
    } else {
      return (
        configurationName.length > 0 && fileFormatSelectedOption !== undefined && 
        isValidName(configurationsNames, configurationName, false) &&
        validateSQLQuery(SQLQuery) &&
        SQLQuery.includes(table)
      );
    }
  };

  /*************************** LIMPIEZA **************************/

  const cleanAndClose = () => {
    toggleCreateConfigurationModal();
    setTabValue("MANUAL");
    setSQLQuery("");
    setConfigurationName("");
    setFileName("");
    setLimitValue("");
    setFilterCondition([]);
    setFileFormatSelectedOption(undefined);
    setOrderByColumnSelectedOption(undefined);
    setOrderTypeSelectedOption(undefined);
    setSelectedColumns([]);
    setGroupByColumnSelectedOption(undefined);
    setAggregations([]);
    setDistinct(false);
    fileFormatRef?.current?.clearValue();
    conditionalColumnRef?.current?.clearValue();
    orderTypeRef?.current?.clearValue();
    orderByColumnRef?.current?.clearValue();
    groupByColumnRef?.current?.clearValue();
  };

  return (
    <>
      <Stack direction={"row"} alignItems="center">
        <Button
          title={"Crear configuración"}
          type="button"
          onClick={toggleCreateConfigurationModal}
          color="blue"
          width={180}
        />
      </Stack>

      <Dialog
        open={openCreateConfigurationModal}
        handleClose={toggleCreateConfigurationModal}
        maxWidth="xl"
        title={"Nueva configuración"}
      >
        <SimpleBackdrop
          open={isLoadingPostConfiguration}
          message={
            texts.dataManagement.postExternalConnectionConfiguration.loading
          }
        />
        <SimpleBackdrop
          open={isLoadingColumns}
          message={texts.dataManagement.getExternalConnectionColumns.loading}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50vw",
            alignItems: "center",
          }}
        >
          <Stack width="100%" gap={2} justifyContent={"flex-start"}>
            <ColorTabs
              value={tabValue}
              handleChange={(event, newValue) => setTabValue(newValue)}
              tabs={tabs}
              marginBottom="10px"
            />
          </Stack>
         <IngestionConfiguration  resource_id={resource_id} table={table} schema={schema} catalog={catalog} configurationsNames={configurationsNames} connectionType={connectionType} tabValue={tabValue} configurationName={configurationName} setConfigurationName={setConfigurationName} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} fileName={fileName} setFileName={setFileName} fileFormatRef={fileFormatRef} fileFormatSelectedOption={fileFormatSelectedOption} fileFormatChangeHandler={fileFormatChangeHandler} orderByColumnRef={orderByColumnRef} orderByColumnSelectedOption={orderByColumnSelectedOption} orderByColumnChangeHandler={orderByColumnChangeHandler} groupByColumnRef={groupByColumnRef} groupByColumnsSelectedOption={groupByColumnsSelectedOption} groupByColumnChangeHandler={groupByColumnChangeHandler }orderTypeRef={orderTypeRef} orderTypeSelectedOption={orderTypeSelectedOption} orderTypeChangeHandler={orderTypeChangeHandler} SQLQuery={SQLQuery} setSQLQuery={setSQLQuery} distinct={distinct} setDistinct={setDistinct} filterCondition={filterCondition} setFilterCondition={setFilterCondition}aggregations={aggregations} setAggregations={setAggregations} limitValue={limitValue} setLimitValue={setLimitValue} />
          <Stack
            direction="row"
            sx={{
              alignSelf: "center",
              marginTop: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              title="Cerrar"
              type="button"
              onClick={toggleCreateConfigurationModal}
              color="light-grey"
            />
            <Button
              title="Aceptar"
              type="button"
              onClick={handleAccept}
              color="blue"
              disabled={!isFormCompleted()}
            />
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateIngestionConfigurationModal;
