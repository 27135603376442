import {
  InputLabel,
  Stack,
} from "@mui/material";
import Dialog from "../../../dialog/Dialog";
import Button from "../../../buttons/Button";
import Select from "../../../forms/Select";
import { form_label } from "../../../../styles/app-styles";
import { useContext, useEffect, useRef, useState } from "react";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { selectStyles } from "../../../../styles/select.styles";
import {
  formatOptions,
  formatTemplatePhiName,

} from "../../../../utils/util";
import FlowsProvider from "../../../../context/FlowsContext";
import useApi from "../../../../hooks/useApi";
import {
  CALC_FLOW_URL,
  FLOW_CONFIGURATION,
  FLOW_CONFIGURATION_BY_ID,
  GET_COLUMNS_FLOW_URL,
} from "../../../../api/axios";
import { texts } from "../../../../texts";
import { formatColumns } from "../../../fileColumns/utils";
import {
  filterIdFromColumns,
  getSteppersOptions,
  isFileNameValid,
} from "../../utils";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import CheckmarkSelect from "../../../forms/CheckmarkSelect";
import ExploreFolders from "../../../dataManagement/UploadFileModal/ExploreFolders";
import ColorTabs from "../../../colorTabs/ColorTabs";
import { Tab, TabValue } from "../../../colorTabs/types";
import SaveNameModal from "../../../dialog/SaveNameModal";
import useDialog from "../../../../hooks/useDialog";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import CalculationConfigs from "./CalculationConfigs";
import NewConfigurationGeneralTab from "./NewConfigurationGeneralTab";
import PhiTemplateConfigurationTab from "./PhiTemplateConfigurationTab";

interface FileFormatModalProps {
  open: boolean;
  handleClose: () => void;
  dataFlujos?: any;
}

const FileFormatModal = ({
  open,
  handleClose,
  dataFlujos,
}: FileFormatModalProps) => {
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow } = flowState;
  const formatSelectRef: any = useRef(null);
  const configurationRef: any = useRef(null);
  const stepperToDeconsolidateSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const templatePhiNameRef: any = useRef(null);
  const monthSelectRef: any = useRef(null);
  const yearSelectRef: any = useRef(null);
  const [desdinamizeFile, setDesdinamizeFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [selectedSteppers, setSelectedSteppers] = useState<any>([]);
  const [stepperToDeconsolidateColumns, setStepperToDeconsolidateColumns] =
    useState<any>([]);
  const [tabValue, setTabValue] = useState<TabValue>("NUEVA CONFIGURACIÓN");
  const [newConfigTabValue, setNewConfigTabValue] =
    useState<TabValue>("GENERAL");
  const [openSaveConfigModal, toggleOpenSaveConfigModal] = useDialog();
  const [editName, setEditName] = useState("");
  const [assignPeriods, setAssignPeriods] = useState("manual");
  const [conservatePeriods, setConservatePeriods] = useState("column");
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
  const [overwritePeriods, setOverwritePeriods] = useState(false);

  const tabs: Tab[] = [
    {
      value: "NUEVA CONFIGURACIÓN",
      label: "NUEVA CONFIGURACIÓN",
    },
    {
      value: "CONFIGURACIONES GUARDADAS",
      label: "CONFIGURACIONES GUARDADAS",
    },
  ];

  const newConfigTabs: Tab[] = [
    {
      value: "GENERAL",
      label: "GENERAL",
    },
    {
      value: "TEMPLATE PARA PHI",
      label: "TEMPLATE PARA PHI",
    },
  ];

  const navigate = useNavigate();

  const handleRunFlow = () => {
    if (
      newConfigTabValue === "TEMPLATE PARA PHI" ||
      (configurationSelectedOption &&
        dataConfigs.find(
          (config: any) => config.id === configurationSelectedOption?.value
        ).template_phi)
    ) {
      calcFlow(undefined, {
        id_configuracion: configurationSelectedOption?.value as number,
        nombre_archivo:
          assignPeriods === "manual"
            ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
            : templatePhiNameSelectedOption?.label,
        template_phi: true,
        url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
        formato_archivo: formatSelectedOption?.value,
        steppers: selectedSteppers
          .filter((s: any) => {
            return s.id !== "todas";
          })
          .map((stepper: any) => {
            return stepper.id;
          }),
        desconsolidar: assignPeriods === "deconsolidate",
        stepper_desconsolidar:
          assignPeriods === "deconsolidate"
            ? stepperToDeconsolidateSelectedOption?.value
            : undefined,
        columna_desconsolidar:
          assignPeriods === "deconsolidate"
            ? columnSelectedOption?.label
            : undefined,
        lista_periodos:
          assignPeriods === "deconsolidate" &&
          conservatePeriods === "select-periods"
            ? selectedPeriods
            : [],
        sobreescribir_periodos_existentes: overwritePeriods,
      });
    } else {
      calcFlow(undefined, {
        id_configuracion: configurationSelectedOption?.value as number,
        nombre_archivo: fileName,
        url_destino: selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
        formato_archivo: formatSelectedOption?.value,
        steppers: selectedSteppers
          .filter((s: any) => {
            return s.id !== "todas";
          })
          .map((stepper: any) => {
            return stepper.id;
          }),
        desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
        stepper_desconsolidar:
          desdinamizeFile || assignPeriods === "deconsolidate"
            ? stepperToDeconsolidateSelectedOption?.value
            : undefined,
        columna_desconsolidar:
          desdinamizeFile || assignPeriods === "deconsolidate"
            ? columnSelectedOption?.label
            : undefined,
      });
    }
  };

  const onSuccessCalcFlow = () => {
    localStorage.removeItem("flowId");
    navigate("/gestor-tareas");
  };

  const {
    data: dataConfigs,
    isLoading: isLoadingGetConfigs,
    callApi: getConfigs,
    error: errorGetConfigs,
  } = useApi(
    FLOW_CONFIGURATION,
    "GET",
    texts.flows.getFlowConfigs.codes,
    undefined,
    undefined, 
    undefined, 
    false,
    undefined,
    undefined
  );

  const { isLoading: isLoadingPostConfig, callApi: postConfig } = useApi(
    FLOW_CONFIGURATION,
    "POST",
    texts.flows.postFlowConfigs.codes,
    undefined,
    onSuccessCalcFlow,
    undefined,
    false
  );

  const { callApi: putConfig } = useApi(
    FLOW_CONFIGURATION,
    "PUT",
    texts.flows.postFlowConfigs.codes,
    undefined,
    onSuccessCalcFlow,
    handleClose,
    false
  );

  const { isLoading: isLoadingCalcFlow, callApi: calcFlow } = useApi(
    CALC_FLOW_URL(data_flow?.id),
    "POST",
    texts.flows.calcFlow.codes,
    undefined,
    onSuccessCalcFlow,
    handleClose,
    true
  );

  const [
    formatSelectedOption,
    setFormatSelectedOption,
    formatOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    stepperToDeconsolidateSelectedOption,
    setStepperToDeconsolidateSelectedOption,
    stepperToDeconsolidateChangeHandler,
  ] = useSingleSelect();

  const [
    configurationSelectedOption,
    setConfigurationSelectedOption,
    configurationChangeHandler,
  ] = useSingleSelect();

  const [
    templatePhiNameSelectedOption,
    setTemplatePhiNameSelectedOption,
    templatePhiNameChangeHandler,
  ] = useSingleSelect();

  const [monthSelectedOption, setMonthSelectedOption, monthChangeHandler] =
    useSingleSelect();

  const [yearSelectedOption, setYearSelectedOption, yearChangeHandler] =
    useSingleSelect();
  useEffect(() => {
    if (
      stepperToDeconsolidateSelectedOption &&
      stepperToDeconsolidateSelectedOption?.value
    ) {
      getColumns(
        GET_COLUMNS_FLOW_URL(
          data_flow?.id,
          stepperToDeconsolidateSelectedOption?.value as number,
          data_flow?.steppers[
            data_flow?.steppers.findIndex((s: any) => {
              return stepperToDeconsolidateSelectedOption?.value === s.id;
            })
          ].items[
            data_flow?.steppers[
              data_flow?.steppers.findIndex(
                (s: any) => stepperToDeconsolidateSelectedOption?.value === s.id
              )
            ].items.length - 1
          ]?.id
        )
      );
    } else {
      setStepperToDeconsolidateColumns([]);
    }
  }, [stepperToDeconsolidateSelectedOption]);

  useEffect(() => {
    if (configurationSelectedOption) {
      const configExists = dataConfigs.filter(
        (config: any) =>
          config.id === configurationSelectedOption?.value &&
          config.nombre === configurationSelectedOption?.label
      );
      if (configExists.length === 0) {
        setConfigurationSelectedOption(undefined);
        configurationRef?.current.clearValue();
      }
    }
  }, [dataConfigs]);

  useEffect(() => {
    if (open && data_flow) {
      getConfigs(FLOW_CONFIGURATION(data_flow?.id));
    }
  }, [open]);

  const closeAndClean = () => {
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setSelectedSteppers([]);
    setFileName("");
    setTabValue("NUEVA CONFIGURACIÓN");
    setConfigurationSelectedOption(undefined);
    setEditName("");
    handleClose();
  };

  const acceptAndClean = () => {
    handleRunFlow();
    closeAndClean();
  };

  const onSuccessGetDataColumns = (data: any) => {
    setStepperToDeconsolidateColumns(data);
  };

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetDataColumns,
    undefined,
    false
  );

  const validateAccept = () => {
    let result = false;
    let resultMessage = "";
    if (dataFlujos) {
      dataFlujos.forEach((item: any) => {
        if (item.nombre === data_flow.nombre && item.estado === "Corriendo") {
          result = true;
          resultMessage = "El flujo está en ejecución.";
        }
      });
    }
    if (newConfigTabValue !== "TEMPLATE PARA PHI") {
      if (
        fileName === "" ||
        formatSelectedOption === null ||
        formatSelectedOption === undefined ||
        selectedSteppers.length < 1 ||
        ((assignPeriods === "deconsolidate" || desdinamizeFile) &&
          (columnSelectedOption === null || columnSelectedOption === undefined))
      ) {
        result = true;
      }
    } else {
      if (
        templatePhiNameSelectedOption === undefined ||
        templatePhiNameSelectedOption === null ||
        formatSelectedOption === null ||
        formatSelectedOption === undefined ||
        selectedSteppers.length < 1 ||
        ((assignPeriods === "deconsolidate" || desdinamizeFile) &&
          (columnSelectedOption === null ||
            columnSelectedOption === undefined)) ||
        (conservatePeriods === "select-periods" &&
          selectedPeriods.length === 0) ||
        (assignPeriods === "manual" &&
          (monthSelectedOption === undefined ||
            monthSelectedOption === null ||
            yearSelectedOption === undefined ||
            yearSelectedOption === null))
      ) {
        result = true;
      }
    }

    return { result, resultMessage };
  };

  const handleChangeItem = (newItem: any) => {
    const totalOptions = getSteppersOptions(data_flow) &&
      getSteppersOptions(data_flow)?.length && [
        { id: "todas", label: "Seleccionar todas los steppers" },
        ...getSteppersOptions(data_flow),
      ];

    if (newItem.id === "todas") {
      if (
        !selectedSteppers
          .map((item: any) => {
            return item.id;
          })
          .includes(newItem.id)
      ) {
        setSelectedSteppers(totalOptions);
      } else {
        setSelectedSteppers([]);
      }
    } else {
      setSelectedSteppers(
        selectedSteppers
          .map((item: any) => {
            return item.id;
          })
          .includes(newItem.id)
          ? selectedSteppers.filter(
              (item: any) => item.id !== newItem.id && item.id !== "todas"
            )
          : [...selectedSteppers, newItem]
      );
    }
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setSelectedFolder("Carpeta raíz");
    } else {
      setSelectedFolder(location);
    }
  };

  const handleChangeTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTabValue(newValue);
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setSelectedSteppers([]);
    setFileName("");
    setConfigurationSelectedOption(undefined);
    setEditName("");
    setSelectedFolder("");
    setTemplatePhiNameSelectedOption(undefined)
    templatePhiNameRef?.current?.clearValue()
    formatSelectRef?.current?.clearValue()
    setAssignPeriods("manual")
    setOverwritePeriods(false)
    setMonthSelectedOption(undefined)
    monthSelectRef?.current?.clearValue()
    setYearSelectedOption(undefined)
    yearSelectRef?.current?.clearValue()
    setStepperToDeconsolidateColumns(undefined)
    stepperToDeconsolidateSelectRef?.current?.clearValue()
    columnSelectRef?.current?.clearValue()
    setSelectedPeriods([])

  };

  const handleChangeNewConfigTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setNewConfigTabValue(newValue);
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setSelectedSteppers([]);
    setFileName("");
    setConfigurationSelectedOption(undefined);
    setEditName("");
    setSelectedFolder("");
  };

  const handleChangeStepperToDeconsolidate = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    stepperToDeconsolidateChangeHandler(newValue, actionMeta);
    setColumnSelectedOption(undefined);
    columnSelectRef?.current.clearValue();
  };
  const handleChangeConfigSelectedOption = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (newValue !== null && newValue !== undefined) {
      const selectedConfig = dataConfigs.find(
        (config: any) => config.id === newValue?.value
      );
      if (selectedConfig.template_phi) {
        if (selectedConfig.desconsolidar) {
          setTemplatePhiNameSelectedOption({
            value: selectedConfig.nombre_archivo,
            label: selectedConfig.nombre_archivo,
          });
          setConservatePeriods(
            selectedConfig?.lista_periodos?.length === 0
              ? "column"
              : "select-periods"
          );
          setSelectedPeriods(selectedConfig?.lista_periodos);
        } else {
          setTemplatePhiNameSelectedOption({
            value: formatTemplatePhiName(selectedConfig.nombre_archivo),
            label: formatTemplatePhiName(selectedConfig.nombre_archivo),
          });
          setMonthSelectedOption({
            value: selectedConfig.nombre_archivo.split("-")[1],
            label: selectedConfig.nombre_archivo.split("-")[1],
          });
          setYearSelectedOption({
            value: selectedConfig.nombre_archivo.split("-")[2],
            label: selectedConfig.nombre_archivo.split("-")[2],
          });
        }
        setOverwritePeriods(selectedConfig.sobreescribir_periodos_existentes);
        setAssignPeriods(
          selectedConfig.desconsolidar ? "deconsolidate" : "manual"
        );
      }
      setEditName(selectedConfig.nombre);
      setFileName(selectedConfig.nombre_archivo);
      setColumnSelectedOption({
        value: selectedConfig.columna_desconsolidar,
        label: selectedConfig.columna_desconsolidar,
      });
      setSelectedFolder(selectedConfig.ubicacion_archivo);
      setFormatSelectedOption({
        value: selectedConfig.formato_archivo,
        label: selectedConfig.formato_archivo,
      });
      const steppersFormatted: any[] = [];
      selectedConfig.steppers.forEach((stepper: any) => {
        const stepperWithData = data_flow?.steppers?.find(
          (stepperFlow: any) => stepper?.value === stepperFlow?.id
        );
        steppersFormatted.push({
          id: stepperWithData.id,
          label: `${stepperWithData.nombre} - ${stepperWithData.items[0]?.tipo}`,
        });
      });
      setSelectedSteppers(steppersFormatted);
      setStepperToDeconsolidateSelectedOption(
        selectedConfig.stepper_desconsolidar
      );
      setDesdinamizeFile(selectedConfig.desconsolidar);
    }
    setTimeout(() => {
      configurationChangeHandler(newValue, actionMeta);
    }, 5);
  };

  const handleAcceptSaveConfig = () => {
    if (tabValue === "NUEVA CONFIGURACIÓN") {
      if (newConfigTabValue === "TEMPLATE PARA PHI") {
        postConfig(FLOW_CONFIGURATION(data_flow?.id), {
          nombre: editName,
          nombre_archivo:
            assignPeriods === "manual"
              ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
              : templatePhiNameSelectedOption?.label,
          template_phi: true,
          url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
          formato_archivo: formatSelectedOption?.value,
          steppers: selectedSteppers
            .filter((s: any) => {
              return s.id !== "todas";
            })
            .map((stepper: any) => {
              return stepper.id;
            }),
          desconsolidar: assignPeriods === "deconsolidate",
          stepper_desconsolidar:
            assignPeriods === "deconsolidate"
              ? stepperToDeconsolidateSelectedOption?.value
              : undefined,
          columna_desconsolidar:
            assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
          lista_periodos:
            assignPeriods === "deconsolidate" &&
            conservatePeriods === "select-periods"
              ? selectedPeriods
              : [],
          sobreescribir_periodos_existentes: overwritePeriods,
        });
      } else {
        postConfig(FLOW_CONFIGURATION(data_flow?.id), {
          nombre: editName,
          nombre_archivo: fileName,
          ubicacion_archivo:
            selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
          formato_archivo: formatSelectedOption?.value,
          steppers: selectedSteppers
            .filter((s: any) => {
              return s.id !== "todas";
            })
            .map((stepper: any) => {
              return stepper.id;
            }),
          desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
          stepper_desconsolidar:
            desdinamizeFile || assignPeriods === "deconsolidate"
              ? stepperToDeconsolidateSelectedOption?.value
              : undefined,
          columna_desconsolidar:
            desdinamizeFile || assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
        });
      }
      toggleOpenSaveConfigModal();
    } else {
      const selectedConfig: any = dataConfigs.find(
        (config: any) => config.id === configurationSelectedOption?.value
      );
      if (selectedConfig.template_phi) {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            data_flow?.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre: editName,
            nombre_archivo:
              assignPeriods === "manual"
                ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
                : templatePhiNameSelectedOption?.label,
            template_phi: true,
            url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
            formato_archivo: formatSelectedOption?.value,
            steppers: selectedSteppers
              .filter((s: any) => {
                return s.id !== "todas";
              })
              .map((stepper: any) => {
                return stepper.id;
              }),
            desconsolidar: assignPeriods === "deconsolidate",
            stepper_desconsolidar:
              assignPeriods === "deconsolidate"
                ? stepperToDeconsolidateSelectedOption?.value
                : undefined,
            columna_desconsolidar:
              assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
            lista_periodos:
              assignPeriods === "deconsolidate" &&
              conservatePeriods === "select-periods"
                ? selectedPeriods
                : [],
            sobreescribir_periodos_existentes: overwritePeriods,
          }
        );
      } else {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            data_flow?.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre: editName,
            nombre_archivo: fileName,
            ubicacion_archivo:
              selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
            formato_archivo: formatSelectedOption?.value,
            steppers: selectedSteppers
              .filter((s: any) => {
                return s.id !== "todas";
              })
              .map((stepper: any) => {
                return stepper.id;
              }),
            desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
            stepper_desconsolidar:
              desdinamizeFile || assignPeriods === "deconsolidate"
                ? stepperToDeconsolidateSelectedOption?.value
                : undefined,
            columna_desconsolidar:
              desdinamizeFile || assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
          }
        );
      }
    }
    acceptAndClean();
  };

  const addPeriod = () => {
    if (
      !selectedPeriods.includes(
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`
      )
    ) {
      setSelectedPeriods([
        ...selectedPeriods,
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`,
      ]);
      setMonthSelectedOption(undefined);
      monthSelectRef?.current?.clearValue();
      setYearSelectedOption(undefined);
      yearSelectRef?.current?.clearValue();
    }
  };

  const deletePeriod = (period: string) => {
    setSelectedPeriods(selectedPeriods.filter((p: any) => p !== period));
  };

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      maxWidth="xl"
      title={"Calcular el flujo"}
    >
      <SimpleBackdrop
        open={isLoadingCalcFlow}
        message={texts.flows.calcFlow.loading}
      />
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "flex-start",
          minHeight: "250px",
          minWidth: "950px",
        }}
      >
        <Stack width="100%" gap={2} justifyContent={"flex-start"}>
          <ColorTabs
            value={tabValue}
            handleChange={handleChangeTabs}
            tabs={tabs}
            marginBottom="10px"
          />
        </Stack>
        {tabValue === "NUEVA CONFIGURACIÓN" && (
          <>
            <Stack width="100%" gap={2} justifyContent={"flex-start"}>
              <ColorTabs
                value={newConfigTabValue}
                handleChange={handleChangeNewConfigTabs}
                tabs={newConfigTabs}
                marginBottom="10px"
              />
            </Stack>
            {newConfigTabValue === "GENERAL" && (
              <NewConfigurationGeneralTab
                formatSelectRef={formatSelectRef}
                stepperToDeconsolidateSelectRef={
                  stepperToDeconsolidateSelectRef
                }
                columnSelectRef={columnSelectRef}
                desdinamizeFile={desdinamizeFile}
                setDesdinamizeFile={setDesdinamizeFile}
                fileName={fileName}
                setFileName={setFileName}
                selectedSteppers={selectedSteppers}
                setSelectedSteppers={setSelectedSteppers}
                stepperToDeconsolidateColumns={stepperToDeconsolidateColumns}
                formatSelectedOption={formatSelectedOption}
                formatOptionsChangeHandler={formatOptionsChangeHandler}
                columnSelectedOption={columnSelectedOption}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
                stepperToDeconsolidateSelectedOption={
                  stepperToDeconsolidateSelectedOption
                }
                handleChangeSelectedFolder={handleChangeSelectedFolder}
                handleChangeItem={handleChangeItem}
                validateAccept={validateAccept}
                handleChangeStepperToDeconsolidate={
                  handleChangeStepperToDeconsolidate
                }
                selectedFolder={selectedFolder}
              />
            )}

            {newConfigTabValue === "TEMPLATE PARA PHI" && (
              <PhiTemplateConfigurationTab
                formatSelectRef={formatSelectRef}
                stepperToDeconsolidateSelectRef={
                  stepperToDeconsolidateSelectRef
                }
                columnSelectRef={columnSelectRef}
                selectedSteppers={selectedSteppers}
                setSelectedSteppers={setSelectedSteppers}
                stepperToDeconsolidateColumns={stepperToDeconsolidateColumns}
                formatSelectedOption={formatSelectedOption}
                formatOptionsChangeHandler={formatOptionsChangeHandler}
                columnSelectedOption={columnSelectedOption}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
                stepperToDeconsolidateSelectedOption={
                  stepperToDeconsolidateSelectedOption
                }
                handleChangeItem={handleChangeItem}
                validateAccept={validateAccept}
                handleChangeStepperToDeconsolidate={
                  handleChangeStepperToDeconsolidate
                }
                templatePhiNameRef={templatePhiNameRef}
                templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                monthSelectRef={monthSelectRef}
                monthChangeHandler={monthChangeHandler}
                monthSelectedOption={monthSelectedOption}
                yearSelectRef={yearSelectRef}
                yearSelectedOption={yearSelectedOption}
                yearChangeHandler={yearChangeHandler}
                addPeriod={addPeriod}
                deletePeriod={deletePeriod}
                assignPeriods={assignPeriods}
                setAssignPeriods={setAssignPeriods}
                conservatePeriods={conservatePeriods}
                setConservatePeriods={setConservatePeriods}
                selectedPeriods={selectedPeriods}
                overwritePeriods={overwritePeriods}
                setOverwritePeriods={setOverwritePeriods}
                dataFlow={data_flow}
                configSelectedOption={configurationSelectedOption?.value}

              />
            )}
          </>
        )}
        {tabValue === "CONFIGURACIONES GUARDADAS" && (
          <Stack sx={{ width: "100%", minHeight: "450px" }}>
            <CalculationConfigs
              dataConfigs={dataConfigs}
              refetchConfigs={getConfigs}
              dataFlow={data_flow}
            />
            <Stack sx={{ width: "100%" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Elija la configuración
              </InputLabel>
              <Select
                reference={configurationRef}
                styles={selectStyles(configurationSelectedOption)}
                options={formatOptions(dataConfigs)}
                onChange={handleChangeConfigSelectedOption}
                closeMenuOnSelect
                placeholder="Seleccionar configuración"
                isClearable
              />
            </Stack>
            {configurationSelectedOption &&
              (dataConfigs?.find(
                (config: any) =>
                  config.id === configurationSelectedOption?.value
              ).template_phi ? (
                <PhiTemplateConfigurationTab
                  formatSelectRef={formatSelectRef}
                  stepperToDeconsolidateSelectRef={
                    stepperToDeconsolidateSelectRef
                  }
                  columnSelectRef={columnSelectRef}
                  selectedSteppers={selectedSteppers}
                  setSelectedSteppers={setSelectedSteppers}
                  stepperToDeconsolidateColumns={stepperToDeconsolidateColumns}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  stepperToDeconsolidateSelectedOption={
                    stepperToDeconsolidateSelectedOption
                  }
                  handleChangeItem={handleChangeItem}
                  validateAccept={validateAccept}
                  handleChangeStepperToDeconsolidate={
                    handleChangeStepperToDeconsolidate
                  }
                  templatePhiNameRef={templatePhiNameRef}
                  templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                  templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                  monthSelectRef={monthSelectRef}
                  monthChangeHandler={monthChangeHandler}
                  monthSelectedOption={monthSelectedOption}
                  yearSelectRef={yearSelectRef}
                  yearSelectedOption={yearSelectedOption}
                  yearChangeHandler={yearChangeHandler}
                  addPeriod={addPeriod}
                  deletePeriod={deletePeriod}
                  assignPeriods={assignPeriods}
                  setAssignPeriods={setAssignPeriods}
                  conservatePeriods={conservatePeriods}
                  setConservatePeriods={setConservatePeriods}
                  selectedPeriods={selectedPeriods}
                  overwritePeriods={overwritePeriods}
                  setOverwritePeriods={setOverwritePeriods}
                  dataFlow={data_flow}
                  configSelectedOption={configurationSelectedOption?.value}
                />
              ) : (
                <NewConfigurationGeneralTab
                  formatSelectRef={formatSelectRef}
                  stepperToDeconsolidateSelectRef={
                    stepperToDeconsolidateSelectRef
                  }
                  columnSelectRef={columnSelectRef}
                  desdinamizeFile={desdinamizeFile}
                  setDesdinamizeFile={setDesdinamizeFile}
                  fileName={fileName}
                  setFileName={setFileName}
                  selectedSteppers={selectedSteppers}
                  setSelectedSteppers={setSelectedSteppers}
                  stepperToDeconsolidateColumns={stepperToDeconsolidateColumns}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  stepperToDeconsolidateSelectedOption={
                    stepperToDeconsolidateSelectedOption
                  }
                  handleChangeSelectedFolder={handleChangeSelectedFolder}
                  handleChangeItem={handleChangeItem}
                  validateAccept={validateAccept}
                  handleChangeStepperToDeconsolidate={
                    handleChangeStepperToDeconsolidate
                  }
                  selectedFolder={selectedFolder}
                />
              ))}
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            title="Cancelar"
            type="button"
            onClick={closeAndClean}
            color="light-grey"
          />
          <Button
            title="Calcular"
            type="button"
            onClick={acceptAndClean}
            disabled={validateAccept().result}
            color="blue-greeny"
          />
          <Button
            title="Calcular y guardar"
            type="button"
            onClick={
              tabValue === "NUEVA CONFIGURACIÓN"
                ? toggleOpenSaveConfigModal
                : handleAcceptSaveConfig
            }
            disabled={validateAccept().result}
            color="blue"
          />
        </Stack>
        <SaveNameModal
          open={openSaveConfigModal}
          handleClose={toggleOpenSaveConfigModal}
          handleAccept={handleAcceptSaveConfig}
          title={"Guardar configuración de cálculo"}
          text={"Nombre de la nueva configuración"}
          label={"Nombre de la nueva configuración"}
          placeholder={"Nombre de la nueva configuración"}
          dispatchFunction={setEditName}
          valueState={editName}
          namesList={dataConfigs}
        />
      </Stack>
    </Dialog>
  );
};

export default FileFormatModal;
