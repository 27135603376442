import { Stack, Typography } from "@mui/material";
import { convertArrayToText } from "../../../taskManager/utils";


interface IngestionConfigurationResumeProps {
 configuration: any | undefined
}

const IngestionConfigurationResume = ({
  configuration
}: IngestionConfigurationResumeProps) => {

const fileName= configuration?.nombre_archivo
const fileFormat= configuration?.extension
const selectedColumns = configuration?.operaciones?.find((op: any)=> op.tipo === "select")?.columnas_incluir?.map((col: any)=> col.columna);
const filters = configuration?.operaciones?.filter((op: any)=> op.tipo === "filtro");
const limit = configuration?.operaciones?.find((op: any)=> op.tipo === "limite");
const orderBy = configuration?.operaciones?.find((op: any)=> op.tipo === "orden");
const groupBy = configuration?.operaciones?.find((op: any)=> op.tipo === "group_by")?.columnas_agrupar?.map((col: any)=> col.columna);
const operations = configuration?.operaciones?.filter((op: any)=> op.tipo === "function");

  return (
      <Stack sx={{ marginTop:"20px" }}>
        {fileName &&     <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        {`Nombre del archivo: ${fileName}`}
      </Typography>}
      {fileFormat &&     <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        {`Formato del archivo: ${fileFormat}`}
      </Typography>}
        {configuration.tipo === "manual" && <>
        <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        {`Columnas a incluir: ${(!selectedColumns)? "Todas" : convertArrayToText(selectedColumns)}`}
      </Typography>
      {operations && operations?.length > 0 && <>
        <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Operaciones: `}
      </Typography>
      {operations.map((aggregation: any, index: number)=> {return <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
        key={index}
      >
       {`${aggregation.funcion}(${ aggregation.columna_valor_funcion}) ${aggregation.columna_nueva?.length > 0 ?"como "+aggregation?.columna_nueva :""}`}
      </Typography>} )}
      </>}
      {filters && filters?.length > 0 && <>
        <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Filtros: `}
      </Typography>
      {filters.map((filter: any, index: number)=> {return <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
        key={index}
      >
       {`${filter.funcion ?filter.funcion + "(" + filter.columna_inicio + ")" :filter.columna_inicio} ${filter.operador} ${filter.columna_valor_final} ${(filters.length > 1 && ((index +1) < filters?.length)) ? filter.condicion_logica :""}`}
      </Typography>} )}
      </>}
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {orderBy && `Ordenar por: ${orderBy.columna} ${orderBy.tipo_orden === "ASC" ? "Ascendente" :"Descendente"}`}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Límite de filas: ${configuration.completo ? "Todas las filas" : limit ? limit?.cantidad_filas : "Todas las filas"}`}
      </Typography>
        </>}
        {groupBy &&         <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "5px",
        }}
      >
        {`Agrupar por: ${ convertArrayToText(groupBy)}`}
      </Typography>}
  {configuration.tipo === "consulta_sql" && 
  <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Consulta SQL: ${configuration.consulta}`}
      </Typography>
  }
      </Stack>
  );
};

export default IngestionConfigurationResume;
