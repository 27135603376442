import {
  Box,
  Checkbox,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import Select from "../../forms/Select";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../styles/select.styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { form_label } from "../../../styles/app-styles";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
  POST_STEPPER_URL,
  STEPPER_URL,
} from "../../../api/axios";
import FlowsProvider from "../../../context/FlowsContext";
import useMultiSelect from "../../../hooks/useMultiSelect";
import {
  filterIdFromColumns,
  findBeforeElementPosition,
  formatMergeColumnControlOptions,
  formatMergeOption,
  getItemsOptions,
  isFileNameValid,
  isStepNameRepeated,
  searchStepper,
} from "../utils";
import { StepperType } from "../../../context/types";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import { formatColumns } from "../../fileColumns/utils";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useDialog from "../../../hooks/useDialog";
import ShadowBox from "./ShadowBox";
import BaseModal from "./BaseModal";
import { ConfirmationModal } from "../../dialog";
import HowMerge from "../../icons/HowMerge";

interface MergeProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const Merge = ({
  open,
  handleClose,
  postItem,
  putItem,
  dataItem,
}: MergeProps) => {
  const [name, setName] = useState<any>("");
  const [columnsFont1, setColumnsFont1] = useState<any>([]);
  const [columnsFont2, setColumnsFont2] = useState<any>([]);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const font1SelectRef: any = useRef(null);
  const font2SelectRef: any = useRef(null);
  const columnsToSaveFont1SelectRef: any = useRef(null);
  const columnsToSaveFont2SelectRef: any = useRef(null);
  const columnsToMerge1SelectRef: any = useRef(null);
  const columnsToMerge2SelectRef: any = useRef(null);
  const columnControlSelectRef: any = useRef(null);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [howMerge, setHowMerge] = useState("");
  const [hasIndicator, setHasIndicator] = useState(false);
  const [indicator, setIndicator] = useState("");
  const [hasControlColumn, setHasControlColumn] = useState(false);

  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;

  const [font1SelectedOption, setFont1SelectedOption, font1ChangeHandler] =
    useSingleSelect();

  const [font2SelectedOption, setFont2SelectedOption, font2ChangeHandler] =
    useSingleSelect();

  const [
    controlColumnSelectedOption,
    setControlColumSelectedOption,
    controlColumChangeHandler,
  ] = useSingleSelect();
  const [
    columnsToSaveFont1SelectedOption,
    setColumnsToSaveFont1SelectedOption,
    columnsToSaveFont1ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToSaveFont2SelectedOption,
    setColumnsToSaveFont2SelectedOption,
    columnsToSaveFont2ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToMerge1SelectedOption,
    setColumnsToMerge1SelectedOption,
    columnsToMerge1ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToMerge2SelectedOption,
    setColumnsToMerge2SelectedOption,
    columnsToMerge2ChangeHandler,
  ] = useMultiSelect();

  /****************************** CARGA DE DATOS *******************************/

  useEffect(() => {
    if (dataItem && open) {
      setName(searchStepper(active_stepper, data_flow).nombre);
      setHowMerge(dataItem.how);
      setFont1SelectedOption(
        formatMergeOption(dataItem?.izquierda?.stepper, data_flow)
      );
      setFont2SelectedOption(
        formatMergeOption(dataItem?.derecha?.stepper, data_flow)
      );
      setColumnsToMerge1SelectedOption(dataItem?.izquierda?.columnas);
      setColumnsToMerge2SelectedOption(dataItem?.derecha?.columnas);
      setColumnsToSaveFont2SelectedOption(dataItem?.derecha?.columnas_finales);
      setColumnsToSaveFont1SelectedOption(
        dataItem?.izquierda?.columnas_finales
      );
      dataItem.indicador && setHasIndicator(true);
      dataItem.indicador && setIndicator(dataItem.indicador);
      if (dataItem.columna_control) {
        setHasControlColumn(true);
        setControlColumSelectedOption({
          value: dataItem.columna_control.columna.id,
          label: `${dataItem.columna_control.columna.nombre} - ${dataItem.columna_control.stepper.nombre}`,
          stepper: dataItem.columna_control.stepper.id,
        } as IdOption);
      }

      if (dataItem?.izquierda?.stepper) {
        getColumnsFont1(
          GET_COLUMNS_FLOW_URL(
            data_flow.id,
            dataItem.izquierda.stepper,
            findBeforeElementPosition(
              data_flow?.steppers?.find((step: StepperType) => {
                return step.id === dataItem.izquierda.stepper;
              }).items
            )
          )
        );
      }
      if (dataItem?.derecha?.stepper) {
        getColumnsFont2(
          GET_COLUMNS_FLOW_URL(
            data_flow.id,
            dataItem?.derecha?.stepper,
            findBeforeElementPosition(
              data_flow?.steppers?.find((step: StepperType) => {
                return step.id === dataItem?.derecha?.stepper;
              })?.items
            )
          )
        );
      }
    }
  }, [dataItem]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 2);
    }
  }, [open]);

  const onSuccessGetColumnsFont1 = (data: any) => {
    setColumnsFont1(data);
  };

  const onSuccessGetColumnsFont2 = (data: any) => {
    setColumnsFont2(data);
  };

  const { callApi: getColumnsFont1 } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      parseInt(font1SelectedOption?.value as string),
      25
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetColumnsFont1,
    undefined,
    false
  );

  const { callApi: getColumnsFont2 } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      parseInt(font2SelectedOption?.value as string),
      30
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetColumnsFont2,
    undefined,
    false
  );

  const onSuccessPutStepper = () => {
    putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
      tipo: "merge",
      izquierda: {
        columnas: columnsToMerge1SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:
          columnsToSaveFont1SelectedOption === undefined ||
          columnsToSaveFont1SelectedOption?.length === 0
            ? formatColumns(filterIdFromColumns(columnsFont1))?.map(
                (column: any) => {
                  return column.label;
                }
              )
            : columnsToSaveFont1SelectedOption?.map((column: any) => {
                return column.label;
              }),
        stepper: font1SelectedOption?.value,
      },
      derecha: {
        columnas: columnsToMerge2SelectedOption?.map((column: any) => {
          return column.label;
        }),
        stepper: font2SelectedOption?.value,
        columnas_finales:
          columnsToSaveFont2SelectedOption === undefined ||
          columnsToSaveFont2SelectedOption?.length === 0
            ? formatColumns(filterIdFromColumns(columnsFont2))?.map(
                (column: any) => {
                  return column.label;
                }
              )
            : columnsToSaveFont2SelectedOption?.map((column: any) => {
                return column.label;
              }),
      },
      how_merge: howMerge,
      indicador: hasIndicator ? indicator : undefined,
      columna_control: hasControlColumn
        ? {
            stepper: (controlColumnSelectedOption as any).stepper,
            columna: controlColumnSelectedOption?.label,
          }
        : undefined,
    });
    cleanAndClose();
  };

  const { isLoading: isLoadingPutStepper, callApi: putStepper } = useApi(
    STEPPER_URL(data_flow.id, searchStepper(active_stepper, data_flow)?.id),
    "PUT",
    texts.flows.postStepper.codes,
    undefined,
    onSuccessPutStepper,
    undefined,
    false
  );

  const onFont1Change = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    font1ChangeHandler(newValue, actionMeta);
    setColumnsToMerge1SelectedOption(undefined);
    columnsToMerge1SelectRef.current.clearValue();
    if (newValue !== null) {
      getColumnsFont1(
        GET_COLUMNS_FLOW_URL(
          data_flow.id,
          parseInt(newValue?.value as string),
          findBeforeElementPosition(
            data_flow?.steppers?.find((step: StepperType) => {
              return step.id === newValue?.value;
            }).items
          )
        )
      );
    } else {
      setColumnsFont1([]);
    }
  };

  const onFont2Change = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    font2ChangeHandler(newValue, actionMeta);
    setColumnsToMerge2SelectedOption(undefined);
    columnsToMerge2SelectRef.current.clearValue();
    if (newValue !== null) {
      getColumnsFont2(
        GET_COLUMNS_FLOW_URL(
          data_flow.id,
          parseInt(newValue?.value as string),
          findBeforeElementPosition(
            data_flow?.steppers?.find((step: StepperType) => {
              return step.id === newValue?.value;
            }).items
          )
        )
      );
    } else {
      setColumnsFont2([]);
    }
  };

  /******************************* VALIDACIONES FORMULARIO ********************************/

  const isFormCompleted = () => {
    return (
      columnsToMerge1SelectedOption === undefined ||
      columnsToMerge1SelectedOption?.length === 0 ||
      columnsToMerge2SelectedOption === undefined ||
      columnsToMerge2SelectedOption?.length === 0 ||
      font1SelectedOption === undefined ||
      font1SelectedOption === null ||
      font2SelectedOption === undefined ||
      font2SelectedOption === null ||
      !isFileNameValid(name) ||
      (isStepNameRepeated(name, data_flow) && !dataItem) ||
      howMerge === "" ||
      columnsToMerge1SelectedOption.length !==
        columnsToMerge2SelectedOption.length ||
      (hasIndicator && indicator.length === 0) ||
      (hasIndicator && isColumnNameRepeated()) ||
      (hasControlColumn && controlColumnSelectedOption === undefined) ||
      (hasControlColumn && controlColumnSelectedOption === null)
    );
  };

  /******************************** POST STEPPER Y POST ITEM ***********************************/

  const saveMerge = () => {
    if (dataItem) {
      if (searchStepper(active_stepper, data_flow).nombre === name) {
        putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
          tipo: "merge",
          izquierda: {
            columnas: columnsToMerge1SelectedOption?.map((column: any) => {
              return column.label;
            }),
            columnas_finales:
              columnsToSaveFont1SelectedOption === undefined ||
              columnsToSaveFont1SelectedOption?.length === 0
                ? formatColumns(filterIdFromColumns(columnsFont1))?.map(
                    (column: any) => {
                      return column.label;
                    }
                  )
                : columnsToSaveFont1SelectedOption?.map((column: any) => {
                    return column.label;
                  }),
            stepper: font1SelectedOption?.value,
          },
          derecha: {
            columnas: columnsToMerge2SelectedOption?.map((column: any) => {
              return column.label;
            }),
            columnas_finales:
              columnsToSaveFont2SelectedOption === undefined ||
              columnsToSaveFont2SelectedOption?.length === 0
                ? formatColumns(filterIdFromColumns(columnsFont2))?.map(
                    (column: any) => {
                      return column.label;
                    }
                  )
                : columnsToSaveFont2SelectedOption?.map((column: any) => {
                    return column.label;
                  }),
            stepper: font2SelectedOption?.value,
          },
          how_merge: howMerge,
          indicador: hasIndicator ? indicator : undefined,
          columna_control: hasControlColumn
            ? {
                stepper: (controlColumnSelectedOption as any).stepper,
                columna: controlColumnSelectedOption?.label,
              }
            : undefined,
        });
        cleanAndClose();
      } else {
        putStepper(undefined, {
          nombre: name,
        });
      }
    } else {
      postStepper(undefined, {
        flow: data_flow.id,
        nombre: name,
      });
    }
  };

  const onSuccessPostStepper = (data: any) => {
    flowDispatch({
      type: "SET_ACTIVE_STEPPER",
      payload: data.id,
    });
    postItem(POST_ITEM_URL(data_flow.id, data.id), {
      tipo: "merge",
      izquierda: {
        columnas: columnsToMerge1SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:
          columnsToSaveFont1SelectedOption === undefined ||
          columnsToSaveFont1SelectedOption?.length === 0
            ? formatColumns(filterIdFromColumns(columnsFont1))?.map(
                (column: any) => {
                  return column.label;
                }
              )
            : columnsToSaveFont1SelectedOption?.map((column: any) => {
                return column.label;
              }),
        stepper: font1SelectedOption?.value,
      },
      derecha: {
        columnas: columnsToMerge2SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:
          columnsToSaveFont2SelectedOption === undefined ||
          columnsToSaveFont2SelectedOption?.length === 0
            ? formatColumns(filterIdFromColumns(columnsFont2))?.map(
                (column: any) => {
                  return column.label;
                }
              )
            : columnsToSaveFont2SelectedOption?.map((column: any) => {
                return column.label;
              }),
        stepper: font2SelectedOption?.value,
      },
      how_merge: howMerge,
      indicador: hasIndicator ? indicator : undefined,
      columna_control: hasControlColumn
        ? {
            stepper: (controlColumnSelectedOption as any).stepper,
            columna: controlColumnSelectedOption?.label,
          }
        : undefined,
    });
    cleanAndClose();
  };

  const { isLoading: isLoadingPostStepper, callApi: postStepper } = useApi(
    POST_STEPPER_URL(data_flow.id),
    "POST",
    texts.flows.postStepper.codes,
    undefined,
    onSuccessPostStepper,
    undefined,
    false
  );

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setName("");
    setColumnsFont1([]);
    setColumnsFont2([]);
    setColumnsToMerge1SelectedOption(undefined);
    font1SelectRef.current.clearValue();
    setColumnsToMerge2SelectedOption(undefined);
    font2SelectRef.current.clearValue();
    setColumnsToSaveFont1SelectedOption(undefined);
    columnsToSaveFont1SelectRef.current.clearValue();
    setColumnsToSaveFont2SelectedOption(undefined);
    columnsToSaveFont2SelectRef.current.clearValue();
    setControlColumSelectedOption(undefined);
    columnControlSelectRef.current.clearValue();
    columnsToSaveFont2SelectRef.current.clearValue();
    setHowMerge("");
    setDelayHelper(false);
    setIndicator("");
    setHasIndicator(false);
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
  };

  const isColumnNameRepeated = () => {
    return (
      columnsFont1?.some((col: any) => {
        return col.columna?.toLowerCase() === indicator?.toLowerCase();
      }) ||
      columnsFont2?.some((col: any) => {
        return col.columna?.toLowerCase() === indicator?.toLowerCase();
      })
    );
  };

  return (
    <>
      {getItemsOptions(data_flow).length > 1 ? (
        <BaseModal
          open={open}
          title="Merge"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
          handleAccept={saveMerge}
          confirmationModalMessage={
            !dataItem ? "agregar el merge" : "editar el merge"
          }
          disabledAcceptButton={isFormCompleted()}
          height="100%"
        >
          <SimpleBackdrop
            open={isLoadingPostStepper}
            message={texts.flows.postItem.loading}
          />
          <ShadowBox margin>
            <TextField
              id="fontName"
              label={"Nombre del paso"}
              placeholder={"Nombre del paso"}
              size="small"
              multiline
              variant="outlined"
              helperText={
                isFileNameValid(name) || name === ""
                  ? isStepNameRepeated(name, data_flow) && (dataItem === undefined || searchStepper(active_stepper, data_flow)?.nombre !== name)
                    ? texts.flows.concat.helperText.repeatedName
                    : ""
                  : texts.flows.concat.helperText.invalidName
              }
              error={!isFileNameValid(name) || name === ""}
              fullWidth
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />
          </ShadowBox>
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Fuente 1 </InputLabel>
                  <Select
                    reference={font1SelectRef}
                    styles={selectStyles(font1SelectedOption)}
                    options={getItemsOptions(data_flow).filter(
                      (option: any) => {
                        return dataItem
                          ? option.value !==
                              searchStepper(active_stepper, data_flow).id
                          : option;
                      }
                    )}
                    name="Fuente 1"
                    onChange={onFont1Change}
                    closeMenuOnSelect
                    placeholder="Seleccionar fuente 1"
                    isClearable
                    defaultValue={font1SelectedOption}
                    isDisabled={false}
                  />
                </Box>

                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Columnas fuente 1</InputLabel>
                  <Select
                    isMulti
                    reference={columnsToMerge1SelectRef}
                    styles={selectStyles(columnsToMerge1SelectedOption)}
                    options={formatColumns(filterIdFromColumns(columnsFont1))}
                    name="Columnas para el merge"
                    onChange={columnsToMerge1ChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas para el merge"
                    isClearable
                    defaultValue={columnsToMerge1SelectedOption}
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Fuente 2 </InputLabel>
                  <Select
                    reference={font2SelectRef}
                    styles={selectStyles(font2SelectedOption)}
                    options={getItemsOptions(data_flow).filter(
                      (option: any) => {
                        return dataItem
                          ? option.value !==
                              searchStepper(active_stepper, data_flow).id
                          : option;
                      }
                    )}
                    name="Fuente 2"
                    defaultValue={font2SelectedOption}
                    onChange={onFont2Change}
                    closeMenuOnSelect
                    placeholder="Seleccionar fuente 2"
                    isClearable
                    isDisabled={false}
                  />
                </Box>

                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Columnas fuente 2</InputLabel>
                  <Select
                    isMulti
                    reference={columnsToMerge2SelectRef}
                    styles={selectStyles(columnsToMerge2SelectedOption)}
                    options={formatColumns(filterIdFromColumns(columnsFont2))}
                    name="Columnas para el merge"
                    onChange={columnsToMerge2ChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas para el merge"
                    isClearable
                    isDisabled={false}
                    defaultValue={columnsToMerge2SelectedOption}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>

          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Conservar columnas fuente 1
                  </InputLabel>
                  <Select
                    reference={columnsToSaveFont1SelectRef}
                    styles={optionalSelectStyles(
                      columnsToSaveFont1SelectedOption
                    )}
                    options={formatColumns(filterIdFromColumns(columnsFont1))}
                    isMulti
                    name="Conservar columnas"
                    defaultValue={columnsToSaveFont1SelectedOption}
                    onChange={columnsToSaveFont1ChangeHandler}
                    closeMenuOnSelect
                    placeholder="Todas las columnas"
                    isClearable
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}

            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Conservar columnas fuente 2
                  </InputLabel>
                  <Select
                    reference={columnsToSaveFont2SelectRef}
                    styles={optionalSelectStyles(
                      columnsToSaveFont2SelectedOption
                    )}
                    options={formatColumns(filterIdFromColumns(columnsFont2))}
                    name="Conservar columnas"
                    isMulti
                    defaultValue={columnsToSaveFont2SelectedOption}
                    onChange={columnsToSaveFont2ChangeHandler}
                    closeMenuOnSelect
                    placeholder="Todas las columnas"
                    isClearable
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>
          <ShadowBox>
            <Stack
              sx={{
                width: "100%",
                marginTop: "0px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel
                id="format"
                sx={{
                  ...form_label,
                  color: "var(--blue)",
                }}
              >
                Incluir indicador
              </InputLabel>
              <Checkbox
                checked={hasIndicator}
                sx={{
                  color: "#172D40",
                  "& svg": { stroke: "#172D40" },
                }}
                onChange={() => {
                  setHasIndicator((prev) => !prev);
                }}
              />
            </Stack>
            <Stack sx={{ width: "100%", marginTop: "5px" }}>
              <InputLabel
                id="format"
                sx={{
                  ...form_label,
                  color: hasIndicator ? "var(--blue)" : "var(--light-grey)",
                }}
              >
                Nombre de la columna
              </InputLabel>
              <TextField
                id="columnName"
                label=""
                placeholder="Ej: Columna Merge"
                size="small"
                variant="outlined"
                error={indicator === "" || isColumnNameRepeated()}
                fullWidth
                value={indicator}
                disabled={!hasIndicator}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIndicator(event.target.value);
                }}
                helperText={
                  isColumnNameRepeated()
                    ? "Ya existe una columna con ese nombre"
                    : ""
                }
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </Stack>
          </ShadowBox>
          {delayHelper && (
            <ShadowBox>
              <Stack
                sx={{
                  width: "100%",
                  marginTop: "0px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputLabel
                  id="format"
                  sx={{
                    ...form_label,
                    color: "var(--blue)",
                  }}
                >
                  Incluir columna control
                </InputLabel>
                <Checkbox
                  checked={hasControlColumn}
                  sx={{
                    color: "#172D40",
                    "& svg": { stroke: "#172D40" },
                  }}
                  onChange={() => {
                    setHasControlColumn((prev) => !prev);
                  }}
                />
              </Stack>
              <Box sx={{ width: "100%", marginBottom: "10px" }}>
                <InputLabel
                  sx={{
                    ...form_label,
                    color: hasControlColumn
                      ? "var(--blue)"
                      : "var(--light-grey)",
                  }}
                >
                  Columna
                </InputLabel>
                <Select
                  reference={columnControlSelectRef}
                  styles={optionalSelectStyles(
                    columnsToSaveFont1SelectedOption
                  )}
                  options={formatMergeColumnControlOptions(
                    {
                      ...font1SelectedOption,
                      columnas: filterIdFromColumns(columnsFont1),
                    },
                    { ...font2SelectedOption, columnas: columnsFont2 }
                  )}
                  name="Columna-control"
                  defaultValue={controlColumnSelectedOption}
                  onChange={controlColumChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna control"
                  isClearable
                  isDisabled={
                    !hasControlColumn ||
                    font1SelectedOption === undefined ||
                    font2SelectedOption === undefined
                  }
                />
              </Box>
            </ShadowBox>
          )}

          <Stack
            sx={{
              gap: "10px",
              mt: "20px",
              width: "100%",
              flexDirection: "column",
              mb: "20px",
            }}
          >
            <ShadowBox>
              <Typography sx={form_label}>Tipo de Merge:</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <HowMerge
                  icon={
                    <JoinLeftIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="left"
                />
                <HowMerge
                  icon={
                    <JoinRightIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="right"
                />
                <HowMerge
                  icon={
                    <JoinInnerIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="inner"
                />
                <HowMerge
                  icon={
                    <JoinFullIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="outer"
                />
              </Stack>
            </ShadowBox>
          </Stack>
        </BaseModal>
      ) : (
        <ConfirmationModal
          open={open}
          handleClose={cleanAndClose}
          handleAccept={cleanAndClose}
          customMessage
          title={"No es posible agregar el item"}
          message={
            "No es posible agregar esta transformación al flow. Es necesario contar con al menos dos steps sin errores para poder agregarla."
          }
        />
      )}
    </>
  );
};

export default Merge;
