import { useContext, useEffect, useRef, useState } from "react";
import BaseModal from "./BaseModal";
import useApi from "../../../hooks/useApi";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import FlowsProvider from "../../../context/FlowsContext";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import { InputLabel, Tooltip, Typography } from "@mui/material";
import ShadowBox from "./ShadowBox";
import { form_label } from "../../../styles/app-styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import {
  checkIfAllColumnNotExists,
  checkIfColumnNotExists,
  filterIdFromColumns,
  findBeforeElementPosition,
  searchStepper,
} from "../utils";
import { formatColumns } from "../../fileColumns/utils";
import { NUMERIC_FUNCTIONS } from "../../summary/constants";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
import InfoIcon from "@mui/icons-material/Info";

interface DynamizeRowsProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url: string, body: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const DynamizeRows = ({
  open,
  handleClose,
  postItem,
  dataItem,
  putItem,
}: DynamizeRowsProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;

  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  const newColumnsSelectedRef: any = useRef(null);
  const columnValueSelectedRef: any = useRef(null);
  const functionSelectedRef: any = useRef(null);

  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [selectedIndexColumns, setSelectedIndexColumns] = useState<number[]>(
    dataItem ? dataItem.columnas_indice : []
  );
  const [
    newColumnsSelectedOption,
    setNewColumnsSelectedOption,
    newColumnsChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem ? dataItem.columnas_nuevas : undefined
  );
  const [
    columnValueSelectedOption,
    setColumnValueSelectedOption,
    columnValueChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem ? dataItem.columnas_valor : undefined
  );
  const [
    functionSelectedOption,
    setFunctionSelectedOption,
    functionChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem ? dataItem.funcion : undefined
  );

  /*********************************** CARGA DE DATOS ****************************************/
  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 1);
      getColumns();
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      setFunctionSelectedOption(dataItem.funcion);
      setColumnValueSelectedOption(dataItem.columnas_valores[0]);
      setNewColumnsSelectedOption(dataItem.columnas_nuevas[0]);
      setSelectedIndexColumns(
        dataItem.columnas_indice?.map((item: any) => {
          return { id: item.id, label: item.columna };
        })
      );
    }
  }, [dataItem]);

  /******************************* POSTEAR ITEM *********************************************/

  const handleConfirmDynamizeRows = () => {
    toggleConfirmationModal();
    if (dataItem) {
      putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
        tipo: "dinamizar",
        columnas_indice: selectedIndexColumns
          .filter((col: any) => {
            return (
              col.id !== "todas" &&
              !checkIfColumnNotExists(
                col.label,
                dataColumns.map((col: any) => {
                  return { label: col.columna };
                })
              )
            );
          })
          .map((col: any) => {
            return col.label;
          }),
        funcion: functionSelectedOption?.value,
        columnas_nuevas: [newColumnsSelectedOption?.label],
        columnas_valores: [columnValueSelectedOption?.label],
      });
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), {
        tipo: "dinamizar",
        columnas_indice: selectedIndexColumns
          .filter((col: any) => {
            return (
              col.id !== "todas" &&
              !checkIfColumnNotExists(
                col.label,
                dataColumns.map((col: any) => {
                  return { label: col.columna };
                })
              )
            );
          })
          .map((col: any) => {
            return col.label;
          }),
        funcion: functionSelectedOption?.value,
        columnas_nuevas: [newColumnsSelectedOption?.label],
        columnas_valores: [columnValueSelectedOption?.label],
      });
    }
    cleanAndClose();
  };

  const isFormCompleted = () => {
    return (
      !selectedIndexColumns ||
      selectedIndexColumns.length < 1 ||
      newColumnsSelectedOption === undefined ||
      newColumnsSelectedOption === null ||
      functionSelectedOption === undefined ||
      functionSelectedOption === null ||
      columnValueSelectedOption === undefined ||
      columnValueSelectedOption === null ||
      selectedIndexColumns.some((colIndex: any) => {
        return (
          colIndex.id === columnValueSelectedOption.value ||
          colIndex.id === newColumnsSelectedOption.value
        );
      }) ||
      (selectedIndexColumns &&
        dataColumns &&
        checkIfAllColumnNotExists(selectedIndexColumns, dataColumns)) ||
      columnValueSelectedOption.value === newColumnsSelectedOption.value
    );
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setSelectedIndexColumns([]);
    setDelayHelper(false);
    setColumnValueSelectedOption(undefined);
    setFunctionSelectedOption(undefined);
    setNewColumnsSelectedOption(undefined);
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

  return (
    <BaseModal
      open={open}
      title="Dinamizar columnas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleConfirmDynamizeRows}
      confirmationModalMessage={"dinamizar las columnas"}
      disabledAcceptButton={isFormCompleted()}
      height="450px"
      width="900px"
      hasResultsTable
    >
      <Stack
        gap={2}
        sx={{ width: "100%", height: "460px", marginBottom: "15px" }}
      >
        <ShadowBox>
        {selectedIndexColumns &&
          dataColumns &&
          selectedIndexColumns.filter((col: any)=> col.id !== "todas").some((col: any) =>
            checkIfColumnNotExists(
              col.label,
              dataColumns.map((item: any) => {
                return { id: item.id, label: item.columna };
              })
            )
          ) &&     <Typography sx={{ color: "var(--red)", marginLeft: "10px", marginBottom:"10px" }}>
          Las columnas no existentes en la fuente actual no se tendrán en cuenta
          y se borrarán en pasos posteriores.
        </Typography>}
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "8px" }}>
              {"Columnas índice"}
            </InputLabel>
            <Tooltip title="Las columnas utilizadas como columnas índice, columnas nuevas y columna valor, no pueden repetirse">
              <InfoIcon
                sx={{
                  color: "var(--blue)",
                  fontSize: "21px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
              />
            </Tooltip>
          </Stack>
          {delayHelper && (
            <CheckmarkSelect
              label="Seleccionar columnas"
              selectedItems={selectedIndexColumns}
              setSelectedItems={setSelectedIndexColumns}
              items={filterIdFromColumns(dataColumns)?.map((item: any) => {
                return { id: item.id, label: item.columna };
              })}
              width="100%"
            />
          )}
        </ShadowBox>
        <ShadowBox>
          <Stack direction="row">
            <InputLabel sx={form_label}>{"Columnas nuevas"}</InputLabel>
            {selectedIndexColumns.some((colIndex: any) => {
              return colIndex.id === newColumnsSelectedOption?.value;
            }) ? (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice, columnas nuevas y columna valor no pueden
                coincidir{" "}
              </Typography>
            ) : (
              columnValueSelectedOption !== undefined &&
              columnValueSelectedOption !== null &&
              columnValueSelectedOption?.value ===
                newColumnsSelectedOption?.value && (
                <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                  Las columnas nuevas y columna valor no pueden coincidir{" "}
                </Typography>
              )
            )}
          </Stack>
          {delayHelper && (
            <Select
              id="demo-multiple-chip"
              isClearable
              className="cc_select"
              onChange={newColumnsChangeHandler}
              reference={newColumnsSelectedRef}
              styles={selectStyles(newColumnsSelectedOption)}
              options={formatColumns(filterIdFromColumns(dataColumns))}
              closeMenuOnSelect={true}
              defaultValue={newColumnsSelectedOption}
              placeholder="Seleccionar columna"
            />
          )}
        </ShadowBox>
        <ShadowBox>
          <Stack direction="row">
            <InputLabel sx={form_label}>{"Valor"}</InputLabel>
            {selectedIndexColumns.some((colIndex: any) => {
              return colIndex.id === columnValueSelectedOption?.value;
            }) ? (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice, columnas nuevas y columna valor no pueden
                coincidir{" "}
              </Typography>
            ) : (
              columnValueSelectedOption !== undefined &&
              columnValueSelectedOption !== null &&
              columnValueSelectedOption?.value ===
                newColumnsSelectedOption?.value && (
                <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                  Las columnas nuevas y columna valor no pueden coincidir{" "}
                </Typography>
              )
            )}
          </Stack>
          {delayHelper && (
            <Select
              id="demo-multiple-chip"
              isClearable
              className="cc_select"
              onChange={columnValueChangeHandler}
              reference={columnValueSelectedRef}
              styles={selectStyles(columnValueSelectedOption)}
              options={formatColumns(filterIdFromColumns(dataColumns))}
              closeMenuOnSelect={true}
              placeholder="Seleccionar columna..."
              defaultValue={columnValueSelectedOption}
            />
          )}
        </ShadowBox>
        <ShadowBox>
          <InputLabel sx={form_label}>{"Función"}</InputLabel>
          {delayHelper && (
            <Select
              id="function"
              reference={functionSelectedRef}
              styles={selectStyles(functionSelectedOption)}
              options={NUMERIC_FUNCTIONS.filter(
                (func: any) => func.value !== "VALORES_UNICOS"
              )}
              name="function"
              onChange={functionChangeHandler}
              closeMenuOnSelect
              placeholder="Seleccionar función..."
              isClearable
              defaultValue={functionSelectedOption}
            />
          )}
        </ShadowBox>
      </Stack>
    </BaseModal>
  );
};

export default DynamizeRows;
