import { Outlet } from "react-router-dom";
import { Menu } from "../menu";
import "./styles.css";

const Layout = () => {  
  return (
    <div>
      <div className="layout_container">
        <div className="layout_menuContainer">
          <Menu />
        </div>
        <div className="layout_outletContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
