import { SingleValue } from "react-select";
import { IdOption } from "../forms/types";

export const formatRow = (
  columnSelectedOption: any,
  functionSelectedOption: any,
  isGroupBy?: boolean
) => {
  return isGroupBy ? columnSelectedOption.map((col: any) => {
    return { column: col, function: functionSelectedOption };
  }) : {column: columnSelectedOption, function: functionSelectedOption}
};

export const findTypeByColumn = (column: any, columnsWithType: any) => {
  const columnWithType = columnsWithType?.find(
    (columnWithType: any) => columnWithType.id === column?.value
  );
  return columnWithType?.tipo;
};

export const validateSummaryRow = (
  columnSelectedOption: SingleValue<IdOption> | undefined,
  functionSelectedOption: SingleValue<IdOption> | undefined,
  summaryRows: any[],
  summaryDispatch: any
) => {
  let result = false;
  if (columnSelectedOption && functionSelectedOption) {
    const repeatedRows = summaryRows.filter((row) => {
      return (
        row.column.value === columnSelectedOption?.value &&
        row.function.value === functionSelectedOption?.value
      );
    });
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      summaryDispatch({
        type: "SET_MESSAGE",
        payload: "No se pueden agregar filas repetidas",
      });
    } else {
      result = true;
    }
  } else {
    summaryDispatch({
      type: "SET_MESSAGE",
      payload: "Se deben completar todos los campos",
    });
  }

  return result;
};
