import {  Stack, Typography } from "@mui/material";
import { convertArrayToText } from "../../taskManager/utils";

interface TransformationsResumeProps {
  config: any;
}

const TransformationsResume = ({ config }: TransformationsResumeProps) => {

  return (
    <Stack
      sx={{
        border: "1px solid var(--blue)",
        padding:"1px",
        borderRadius: "20px",
        backgroundColor: "var(--very-very-light-grey)",
        paddingLeft:"20px",
        minWidth:"910px",
        marginLeft: "auto"
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginTop: "5px",
        }}
      >
        Transformaciones del pipeline original:
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Ruta del archivo: ${config?.archivo?.url !== undefined && config?.archivo?.url !== null? config?.archivo.url: "archivo eliminado"}`}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Hoja sin cabecera: ${config?.sin_cabecera ? "Sí" : "No"}`}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Fila de cabecera: ${
          config?.indice_cabecera !== undefined && config?.indice_cabecera !== null
            ? config?.indice_cabecera + 1
            : "no se modifico la cabecera"
        }`}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Filas eliminadas: ${
          config?.criterio_eliminacion?.length > 0
            ? convertArrayToText(config?.criterio_eliminacion.map((row: any)=>row < 0 ? row : row+1))
            : "no se eliminaron filas"
        }`}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          color: "var(--blue)",
          fontWeight: "bold",
          marginBottom: "1px",
        }}
      >
        {`Columnas renombradas: ${
          config?.renombre_cabecera?.length > 0
            ?  convertArrayToText(config?.renombre_cabecera.filter((col: any)=>col.nombre_nuevo !== col.nombre_viejo).map((col: any)=>{return  `${col.nombre_nuevo} (${col.nombre_viejo})`}))
            : "no se renombraron columnas"
        }`}
      </Typography>
    </Stack>
  );
};

export default TransformationsResume;
