export const FAQs = [
  {
    title: "¿Cómo puedo crear un pipeline?",
    summary:
      "Consolidación de archivos -> administración de pipelines -> click en el ícono '+'",
    detail:
      "Para crear un pipeline, ir a la opción de menú Consolidación de archivos -> administración de pipelines -> click en el ícono '+'. En primer lugar se debe pegar la url de un archivo y elegir una hoja, la cual se tomará como base de ejemplo para crear el pipeline.  Tener en cuenta que la creación de un pipeline NO lo ejecutará, sino que se guardará dicha configuración para que luego se pueda utilizar en la creación de un esquema. Al crear el pipeline se puede visualizar un resumen de la configuración realizada y se deberá colocar un nombre al guardar para luego poder identificarlo en la creación de un esquema.  Desde la vista de administración de pipelines se puede editar o eliminar un pipeline.",
  },
  {
    title: "¿Cómo puedo ejecutar un pipeline?",
    summary:
      "Crear un pipeline, luego crear un esquema que lo incluya y finalmente ejecutar ese esquema.",
    detail:
      "Para ejecutar un pipeline ya creado, se debe crear un esquema que lo incluya y finalmente ejecutar ese esquema.  Para crear un esquema, ir a la opción de menú Consolidación de archivos -> administración de esquemas -> click en el ícono '+'. Para ejecutarlo, ir a la opción de menú Consolidación de archivos -> ejecución de esquema. Primero, se deberá elegir si el esquema se aplicará sobre un archivo o un directorio, elegir los archivos en caso de haber elegido directorio, y hacer click en el botón 'Ejecutar esquema'.",
  },
  {
    title: "¿Cómo edito un pipeline?",
    summary:
      "Consolidación de archivos -> Administración de pipelines -> ícono 'Editar' (lápiz).",
    detail:
      "En la opción del menú Consolidación de archivos -> Administración de pipelines, se visualizarán todos los pipelines creados.  Para encontrar un pipeline fácilmente se puede filtrar por nombre.  Para editar un pipeline, hacer click en el ícono 'Editar' (lápiz).  Se abrirá la vista de creación de pipelines, donde se podrá modificar la configuración del mismo.  Al guardar los cambios, éste se actualizará.",
  },
  {
    title: "¿Cómo elimino un pipeline?",
    summary:
      "Consolidación de archivos -> Administración de pipelines -> ícono 'Eliminar' (cesto de basura).",
    detail:
      "En la opción del menú Consolidación de archivos -> Administración de pipelines, se visualizarán todos los pipelines creados.  Para encontrar un pipeline fácilmente se puede filtrar por nombre.  Para eliminar un pipeline, hacer click en el ícono 'Eliminar' (cesto de basura).  Se abrirá una ventana de confirmación, donde se deberá hacer click en el botón 'Aceptar' para confirmar la acción.  Al eliminar un pipeline, éste ya no se podrá utilizar en la creación de esquemas.  Si el pipeline ya está siendo usado en algún esquema, no se podrá eliminar hasta que no se elimine el esquema que lo incluye.",
  },
  {
    title: "¿Cómo puedo crear un esquema?",
    summary:
      "Consolidación de archivos -> administración de esquemas -> click en el ícono '+'",
    detail:
      "Para crear un esquema, ir a la opción de menú Consolidación de archivos -> Administración de esquemas -> click en el ícono '+'.  En primer lugar se pegar la url de un archivo (se aceptan los formatos xls, xlsx, csv, txt y parquet).  Luego, se deberá elegir un pipeline creado previamente. Para cada pipeline se deben seleccionar una o más hojas, y luego completar la condición 'Incluir' o 'Excluir' que determina si ese pipeline se aplicará sobre esas hojas, o si se aplicará sobre todas las hojas exceptuando esas. Luego hacer click en el ícono '+' para agregar otra línea con otro pipeline.  Tener en cuenta que la creación de un esquema NO lo ejecutará, sino que se guardará dicha configuración para luego poder ejecutarlo. Desde la vista de administración de esquemas se puede eliminar un esquema.",
  },
  {
    title: "¿Cómo funcionan las condiciones en la creación de un esquema?",
    summary:
      "'Incluir' permite aplicar el pipeline sobre las hojas seleccionadas, mientras que  'Excluir' permite aplicarlo sobre todas las hojas exceptuando las seleccionadas.",
    detail:
      "La opción 'Incluir' permite aplicar el pipeline sobre las hojas seleccionadas, mientras que la opción 'Excluir' permite aplicarlo sobre todas las hojas exceptuando las seleccionadas.  Además se toman en cuenta las siguientes pautas: No se permitirán agregar campos vacíos. Si no se elige ninguna hoja, por default quedarán seleccionadas todas. No se permitirá excluir todas las hojas. La opción 'Todas' (las hojas) solo se podrá utilizar en la primera línea, y una vez elegida, no se pueden agregar más líneas.  La opción 'Excluir' se podrá utilizar una sola vez.  Los pipelines y hojas se irán quitando del listado a medida que se usen. Además es posible eliminar lineas.",
  },
  {
    title: "¿Cómo puedo ejecutar un esquema?",
    summary: "Consolidación de archivos -> Ejecución de esquema",
    detail:
      "Para ejecutar un esquema ya creado, ir a la opción del menú Consolidación de archivos -> Ejecución de esquema. Primero, se deberá elegir si el esquema se aplicará sobre un archivo o un directorio, elegir los archivos en caso de haber elegido directorio, y hacer click en el botón 'Ejecutar esquema'.",
  },
  {
    title: "¿Cómo puedo eliminar un esquema?",
    summary:
      "Consolidación de archivos -> Administración de esquemas -> ícono 'Eliminar' (cesto de basura).",
    detail:
      "En la opción del menú Consolidación de archivos -> Administración de esquemas, se visualizarán todos los esquemas creados.  Para encontrar un esquema fácilmente se puede filtrar por nombre.  Para eliminar un esquema, hacer click en el ícono 'Eliminar' (cesto de basura).  Se abrirá una ventana de confirmación, donde se deberá hacer click en el botón 'Aceptar' para confirmar la acción.  Al eliminar un esquema, éste ya no se podrá utilizar en la ejecución de esquemas.",
  },
  {
    title: "¿Cómo puedo ver el historial de ejecución de esquemas?",
    summary: "Gestor de Tareas -> Pestaña esquemas",
    detail:
      "En la opción del menú Gestor de tareas -> Pestaña esquemas, se visualizarán todos los esquemas ejecutados junto con la fecha y hora de ejecución, el estado de la ejecución, y posibles acciones.",
  },

  {
    title: "¿Cómo se crea un nuevo ítem?",
    summary: "Seleccionar ícono de de la barra superior en el flujo.",
    detail:
      "Para crear un nuevo ítem, se debe hacer click en alguna de las opciones disponibles en la barra superior.  Al seleccionar el ítem, se abrirá una ventana con la configuración según el caso.  Completar los campos necesarios, y hacer click en el botón 'Aceptar' para confirmar la acción.  El flujo se autoguarda al aceptar los cambios.",
  },
  {
    title: "¿Cómo se crea un nuevo paso?",
    summary:
      "Seleccionar ícono de 'Nueva fuente', 'Merge' o 'Concat' en el flujo.",
    detail:
      "Para crear un nuevo paso, se debe hacer click en alguna de las opciones disponibles en la barra superior.  Al seleccionar el ítem, se abrirá una ventana con la configuración según el caso.  Completar los campos necesarios, y hacer click en el botón 'Aceptar' para confirmar la acción.  El flujo se autoguarda al aceptar los cambios.",
  },
  {
    title: "¿Cómo se modifica la configuración de un ítem?",
    summary:
      "Seleccionar ícono de editar (lápiz) que aparece al hacer click sobre el nombre del ítem.",
    detail:
      "Para modificar la configuración de un ítem, se debe hacer click en el ícono de editar (lápiz) que aparece al hacer click en el nombre del ítem del paso.  Se abrirá una ventana con la configuración del ítem previamente guardado, donde se podrán realizar las modificaciones necesarias.  El flujo se autoguarda al aceptar los cambios.",
  },
  {
    title: "¿Cómo se elimina un ítem?",
    summary:
      "Seleccionar ícono de eliminar (cesto de basura) que aparece al hacer click sobre el nombre del ítem.",
    detail:
      "Para eliminar un ítem, se debe hacer click en el ícono de eliminar (cesto de basura) que aparece al hacer click en el nombre del ítem del paso.  Se abrirá una ventana de confirmación, donde se deberá hacer click en el botón 'Aceptar' para confirmar la acción.  El flujo se autoguarda al aceptar los cambios.",
  },
  {
    title: "¿Cómo ver la vista previa del cálculo de un ítem?",
    summary:
      "Seleccionar ícono de vista previa (lupa) que aparece al hacer click sobre el nombre del ítem.",
    detail:
      "Para ver la vista previa del cálculo de un ítem, se debe hacer click en el ícono de vista previa (lupa) que aparece al hacer click en el nombre del ítem del paso.  Se abrirá una ventana con la vista previa del cálculo del ítem previamente guardado.  La vista previa muestra sólo las primeras 20 filas del archivo, y las últimas 5, con las cuales se hace un cálculo parcial, pero NO implica el cálculo completo.",
  },
  {
    title: "¿Cómo puedo editar un flujo?",
    summary: "Administración de flujos -> click en el ícono 'Editar' (lápiz).",
    detail:
      "En la opción del menú Administración de flujos, se visualizarán todos los flujos creados.  Para encontrar un flujo fácilmente se puede filtrar por nombre.  Para editar un flujo, hacer click en el ícono 'Editar' (lápiz).  Se abrirá una ventana con la configuración del flujo, donde se podrán realizar las modificaciones necesarias. El flujo se va autoguardando cada vez que se crea o edita un ítem.  Para volver a la vista de administración, hacer click en el botón 'Cerrar' ubicado en la parte superior derecha. Si el flujo no se cierra, quedará abierto para poder seguir modificándolo cada vez que se vuelva a esa pantalla.  Tener en cuenta que para que el flujo aplique los nuevos cambios, se debe volver a calcular el flujo completo. Para calcular el flujo completo, hacer click en el botón 'Calcular flujo'. Este botón redirige a la página 'Gestor de tareas' donde se podrá visualizar el estado del cálculo.",
  },
  {
    title: "¿Cómo puedo eliminar un flujo?",
    summary:
      "Administración de flujos -> click en el ícono 'Eliminar' (cesto de basura).",
    detail:
      "En la opción del menú Administración de flujos, se visualizarán todos los flujos creados.  Para encontrar un flujo fácilmente se puede filtrar por nombre.  Para eliminar un flujo, hacer click en el ícono 'Eliminar' (cesto de basura).  Se abrirá una ventana de confirmación, donde se deberá hacer click en el botón 'Aceptar' para confirmar la acción.",
  },
  {
    title: "¿Cómo puedo ver el historial de ejecución de flujos?",
    summary: "Gestor de Tareas -> Pestaña flujos",
    detail:
      "En la opción del menú Gestor de tareas -> Pestaña flujos, se visualizarán todos los flujos ejecutados junto con la fecha y hora de ejecución, el estado de la ejecución, y posibles acciones.",
  },
];
