import { Stack, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts";
import { EXTERNAL_CONNECTIONS_BY_ID } from "../../../api/axios";
import DeleteIcon from "@mui/icons-material/Delete";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CreateConnectionModal from "./CreateConnectionModal";
import useDialog from "../../../hooks/useDialog";
import EditIcon from "@mui/icons-material/Edit";

interface ConnectionProps {
  dataConnections: any
  connection: any;
  onClick?: (connection: any) => void;
  isDeletable?: boolean;
  isEditable?:boolean
  isSelected?: boolean;
  refetchConnections?: () => void;
}

const Connection = ({
  connection,
  dataConnections,
  onClick,
  isSelected,
  isDeletable,
  isEditable,
  refetchConnections,
}: ConnectionProps) => {
  const [openCreateConnectionModal, toggleCreateConnectionModal] = useDialog();


  const { isLoading: isLoadingDeleteConnection, callApi: deleteConnection } =
    useApi(
      EXTERNAL_CONNECTIONS_BY_ID(connection.recurso),
      "DELETE",
      texts.dataManagement.deleteConnection.codes,
      undefined,
      refetchConnections,
      undefined,
      true
    );

    
  const onSuccessPutConnection = ()=>{
    toggleCreateConnectionModal()
    refetchConnections && refetchConnections()
  }


  return (
    <Stack direction="row" alignItems={"center"}>
              <SimpleBackdrop
          open={isLoadingDeleteConnection}
          message={texts.dataManagement.deleteConnection.loading}
        />
      <Stack
        sx={{
          cursor: onClick !== undefined ? "pointer" : "default",
          justifyContent: "center",
          width: "100%",
          backgroundColor: "var(--very-very-light-grey)",
          padding: "5px 5px",
          borderRadius: "10px",
          boxShadow: "6px 13px 20px -10px rgba(0,0,0,01)",
          transition: "all 0.4s",
          border: isSelected ? "solid 2px var(--blue)" : undefined,
          "&:hover":
            onClick !== undefined
              ? {
                  backgroundColor: "var(--very-light-grey)",
                  transition: "0.3s all",
                }
              : undefined,
          margin: "8px 0px 15px 0px !important",
        }}
        onClick={() => onClick && onClick(connection)}
      >
        <Typography
          sx={{
            color: "white",
            backgroundColor: "var(--blue)",
            fontSize: "18px",
            textTransform: "uppercase",
            marginBottom: "3px",
            fontWeight: "bold",
            borderRadius: "5px",
            width: "fit-content",
            marginLeft: "5px",
            padding: "0px 8px",
          }}
        >
          {connection.nombre_conexion}
        </Typography>
        <Stack
          sx={{
            justifyContent: "center",
            width: "100%",
            padding: "5px 15px",
          }}
        >
          <Typography
            sx={{
              color: "var(--blue)",
              fontSize: "14px",
              textTransform: "uppercase",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            {`Tipo: ${connection.tipo ? connection.tipo : "Databricks"}`}
          </Typography>
          {connection.tipo_conexion && (
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {`Tipo de conexión: ${connection.tipo_conexion}`}
            </Typography>
          )}
          {connection.tipo === "databricks" && (
            <>
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                {`Host del servidor: ${connection.server_hostname}`}
              </Typography>
              <Typography
                sx={{
                  color: "var(--blue)",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                {`Ruta HTTP: ${connection.http_path}`}
              </Typography>
            </>
          )}
          {/*  <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                marginBottom: "10px",
                fontWeight:"bold"
              }}
            >
              { `Almacenar credenciales: ${connection.almacenar_credenciales ? "Si" : "NO"}`}
            </Typography> */}
        </Stack>
      </Stack>
      {isDeletable &&       <DeleteIcon
        sx={{
          fontSize: "36px",
          color: "var(--blue)",
          cursor: "pointer",
          marginLeft: "5px",
          marginBottom: "5px"
        }}
        onClick={()=>{deleteConnection()}}
      />}
            {isEditable &&       <EditIcon
        sx={{
          fontSize: "30px",
          color: "var(--blue)",
          cursor: "pointer",
          marginBottom: "5px"
        }}
        onClick={()=>{toggleCreateConnectionModal()}}
      />}
      <CreateConnectionModal
        open={openCreateConnectionModal}
        dataConnections={dataConnections}
        handleClose={toggleCreateConnectionModal}
        onSuccessSendConnection={refetchConnections as (() => void)}
        isEdit={true}
        connectionToEdit={connection}
        onSuccessPutConnection={onSuccessPutConnection}
      />
    </Stack>
  );
};

export default Connection;
