export const NUMERIC_FUNCTIONS = [
    {
        value: "SUMAR",
        label: "SUMAR"
    },
    {
        value: "PROMEDIAR",
        label: "PROMEDIAR"
    },
    {
        value: "MAXIMO",
        label: "MAXIMO"
    },
    {
        value: "MINIMO",
        label: "MINIMO"
    },
    {
        value: "CONTAR",
        label: "CONTAR"
    },
    {
        value: "CONTAR_UNICO",
        label: "CONTAR VALORES UNICOS"
    },
    {
        value: "PRIMERO",
        label: "PRIMERO"
    },
    {
        value: "ULTIMO",
        label: "ULTIMO"
    },
    {
        value: "VALORES_UNICOS",
        label: "VALORES UNICOS"
    }
]

export const CATEGORIC_FUNCTIONS = [
    {
        value: "CONTAR",
        label: "CONTAR"
    },
    {
        value: "CONTAR_UNICO",
        label: "CONTAR VALORES UNICOS"
    },
    {
        value: "PRIMERO",
        label: "PRIMERO"
    },
    {
        value: "ULTIMO",
        label: "ULTIMO"
    },
    {
        value: "VALORES_UNICOS",
        label: "VALORES UNICOS"
    }
]

type FunctionLabel = {
    [key: string]: string
}

export const FUNCTION_LABELS: FunctionLabel = {
    "SUMAR": "SUMAR",
    "PROMEDIAR": "PROMEDIAR",
    "MAXIMO": "MAXIMO",
    "MINIMO": "MINIMO",
    "CONTAR": "CONTAR",
    "CONTAR_UNICO": "CONTAR VALORES UNICOS",
    "PRIMERO": "PRIMERO",
    "ULTIMO": "ULTIMO",
    "VALORES_UNICOS": "VALORES UNICOS"
}

export const TYPE_OPTIONS: FunctionLabel = {
    "texto": "Texto",
    "decimal": "Número Decimal",
    "entero": "Número Entero",
    "fecha": "Fecha",
    "boolean": "Boolean",
}
