import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteForeverIconProps} from "./types";

const DeleteForever = ({ tooltipPlacement, onClick, color }: DeleteForeverIconProps) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title="Eliminar todos"
      placement={tooltipPlacement}
    >
      <DeleteForeverIcon
        onClick={onClick}
        sx={{
          color: color || "#9A999E",
          padding: "0",
          margin: "0",
          fontSize: "24px",
          "& :hover": { color: "#F90D4A", cursor: "pointer" },
          textAlign: "center"
        }}
      />
    </Tooltip>
  );
};

export default DeleteForever;
