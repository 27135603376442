import { Box, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { isNameRepeated } from "../utils";
import { isColumnNameValid } from "../../flowConfiguration/utils";
import { texts } from "../../../texts";
interface CustomColumnInputProps {
  column: string;
  setColumnsError: React.Dispatch<React.SetStateAction<string[]>>;
  newObjectNames: any;
  setNewObjectNames: React.Dispatch<React.SetStateAction<any>>;
  saveValueInObject: (object: any, param: any, value: any) => any;
}

const CustomColumnInput = ({
  column,
  setColumnsError,
  newObjectNames,
  setNewObjectNames,
  saveValueInObject,
}: CustomColumnInputProps) => {
  const [inputValue, setInputValue] = useState(column);
  const [error, setError] = useState<boolean | string>(false);

  const handleChange = (value: string) => {
    setInputValue(value);
    setNewObjectNames(saveValueInObject(newObjectNames, column, value));
    if (value.length < 1) {
      setError("El nombre de la columa no puede estar vacio");
      setColumnsError((prev: string[]) => {
        return [...prev, column];
      });
    } else {
      if(!isColumnNameValid(value)){
        setError(texts.flows.newColumn.helperText.invalidName);
        setColumnsError((prev: string[]) => {
          return [...prev, column];
        });
        return
      }
      const isDuplicatedName = isNameRepeated(
        Object.values(newObjectNames),
        value
      );
      
      if (isDuplicatedName) {
        setError("Ya existe una columna con este nombre");
        setColumnsError((prev: string[]) => {
          return [...prev, column];
        });
      } else {
        setError(false);
        setColumnsError((prev: string[]) => {
          return prev.filter((columna: string) => {
            return columna !== column;
          });
        });
      }
    }
  };

  return (
    <Stack
      sx={{ alignItems: "stretch", flexDirection: "row", margin: "13px 0px" }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "var(--blue)",
          borderRadius: "4px",
          color: "white",
          display: "flex",
          fontSize: column.length > 17 ? "14px" : "16px",
          justifyContent: "center",
          marginRight: "8px",
          minWidth: "350px",
          textAlign: "center",
          width: "50%",
          wordBreak: "break-all",
        }}
      >
        {column}
      </Box>
      <TextField
        sx={{
          height: "100%",
          minWidth: "300px",
          padding: "0 !important",
          width: "50%",
        }}
        size="small"
        multiline
        variant="outlined"
        helperText={error}
        error={!!error}
        fullWidth
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value);
        }}
        FormHelperTextProps={{
          sx: {
            color: "var(--magenta)",
            margin: 0,
          },
        }}
        required
      />
    </Stack>
  );
};

export default CustomColumnInput;