import { Stack, Tooltip, Typography } from "@mui/material";
import { DATA_URL, DOWNLOAD_FILE } from "../../api/axios";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { Button } from "../buttons";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import useApi from "../../hooks/useApi";
import { ConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import { formatResourceUsesData } from "../../utils/util";
import { useEffect, useState } from "react";
import MoveResourceModal from "./MoveResourceModal";

interface SelectFilesProps {
  isSelectedModeActive: boolean;
  setIsSelectedModeActive: (active: boolean) => void;
  setSelectedFiles: (files: any[]) => void;
  selectedFiles: any[];
  resources: any[] | undefined;
  refreshResources?: () => void;
}

const SelectFiles = ({
  isSelectedModeActive,
  setIsSelectedModeActive,
  selectedFiles,
  setSelectedFiles,
  resources,
  refreshResources,
}: SelectFilesProps) => {
  const [openConfirmDeleteResourceModal, toggleConfirmDeleteResourceModal] =
    useDialog();
  const [openDeleteWarningModal, toggleConfirmDeleteWarningModal] = useDialog();
  const [dataDelete, setDataDelete] = useState<any>(undefined);
  const [openMoveResourceModal, toggleOpenMoveResourceModal] = useDialog();

  useEffect(() => {
    if (dataDelete) {
      toggleConfirmDeleteWarningModal();
    }
  }, [dataDelete]);

  /*************************** LLAMADAS ***************************/

  const { isLoading: isLoadingDownloadResource, callApi: downloadResource } =
    useApi(
      DOWNLOAD_FILE,
      "POST",
      texts.dataManagement.downloadResource.codes,
      {
        lista_archivos: selectedFiles.map((file: any) => {
          return {
            tipo: file.type,
            ruta: `${file.folderLocation}${file.name}`,
          };
        }),
      },
      () => onSuccesDownloadResources(),
      undefined,
      false,
      undefined,
      true,
      {
        fileName: `${"archivos.zip"}`,
        fileType: `${"aplication/zip"}`,
      }
    );

  const { isLoading: isLoadingDeleteResource, callApi: deleteResource } =
    useApi(
      DATA_URL,
      "DELETE",
      texts.dataManagement.deleteResource.codes,
      {
        eliminar: false,
        lista_recursos: selectedFiles.map((file: any) => {
          return {
            directorio: file.type !== "archivo",
            id: file.id !== undefined && file.id !== null ? file.id : undefined,
            path:
              file.id === undefined || file.id === null
                ? `${file.folderLocation}${file.name}`
                : undefined,
          };
        }),
      },
      () => {
        onSuccessCall();
      },
      (data: any) => {
        onErrorDeleteResource(data);
      },
      false
    );

  const { isLoading: isLoadingMoveResources, callApi: moveResources } = useApi(
    DATA_URL,
    "PUT",
    texts.dataManagement.moveResources.codes,
    undefined,
    () => {
      onSuccessCall();
    },
    undefined,
    false
  );

  /*************************** HANDLERS ***************************/

  const onSuccessCall = () => {
    setSelectedFiles([]);
    refreshResources && refreshResources();
  };

  const handleClickOnButton = () => {
    if (isSelectedModeActive) {
      setSelectedFiles([]);
    }
    setIsSelectedModeActive(!isSelectedModeActive);
  };

  const handleDeleteResource = (isWarning: boolean) => {
    setIsSelectedModeActive(!isSelectedModeActive);
    if (isWarning) {
      toggleConfirmDeleteWarningModal();
      deleteResource(undefined, {
        eliminar: true,
        lista_recursos: selectedFiles.map((file: any) => {
          return {
            directorio: file.type !== "archivo",
            id: file.id !== undefined && file.id !== null ? file.id : undefined,
            path:
              file.id === undefined || file.id === null
                ? `${file.folderLocation}${file.name}`
                : undefined,
          };
        }),
      });
    } else {
      toggleConfirmDeleteResourceModal();
      deleteResource();
    }
  };

  const onErrorDeleteResource = (data: any) => {
    setDataDelete(data);
    if (
      !data?.mensaje?.startsWith("El/los recurso/s está/n siendo utilizado")
    ) {
      setSelectedFiles([]);
    }
  };

  const handleMoveResource = (newLocation: string) => {
    moveResources(undefined, {
      lista_recursos: selectedFiles.map((file: any) => {
        return {
          ruta: `${file.folderLocation}${file.name}`,
          nuevo_destino: `${newLocation === "Carpeta raíz" ? "" : newLocation}${
            file.name
          }`,
          tipo: file.type,
        };
      }),
    });
    setIsSelectedModeActive(!isSelectedModeActive);
  };

  const handleSelectAllFiles = () => {
    if (selectedFiles.length === resources?.length) {
      setSelectedFiles([]);
    } else {
      // Cambiamos el formato porque estamos seteando la data cruda que viene del back
      resources &&
        setSelectedFiles(
          resources.map((resource: any) => {
            return {
              type: resource.tipo,
              name: resource.nombre,
              folderLocation: resource.ubicacion,
              id: resource.id,
            };
          })
        );
    }
  };

  const onSuccesDownloadResources = () => {
    setSelectedFiles([]);
    setIsSelectedModeActive(false);
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingDownloadResource}
        message={texts.dataManagement.downloadResources.loading}
      />
      <SimpleBackdrop
        open={isLoadingDeleteResource}
        message={texts.dataManagement.deleteResource.loading}
      />
      <SimpleBackdrop
        open={isLoadingMoveResources}
        message={texts.dataManagement.moveResources.loading}
      />
      <Stack direction={"row"} alignItems="center" width="500px">
        <Button
          title={isSelectedModeActive ? "Cancelar" : "Seleccionar recursos"}
          type="button"
          onClick={handleClickOnButton}
          color="blue"
        />
        {isSelectedModeActive && (
          <Typography
            sx={{
              cursor: "pointer",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            onClick={handleSelectAllFiles}
          >
            {selectedFiles.length === resources?.length
              ? "Deseleccioanar todos"
              : "Seleccionar todos"}
          </Typography>
        )}
        {isSelectedModeActive && selectedFiles.length > 0 && (
          <>
            <Tooltip title={"Descargar recursos seleccionados"}>
              <DownloadIcon
                onClick={() => downloadResource()}
                sx={{
                  fontSize: "40px",
                  color: "var(--blue)",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                selectedFiles.some((file: any) =>
                  file.folderLocation.startsWith("PHI")
                )
                  ? "No es posible eliminar recursos en esta carpeta"
                  : "Eliminar recursos seleccionados"
              }
            >
              <DeleteIcon
                onClick={() =>
                  !selectedFiles.some((file: any) =>
                    file.folderLocation.startsWith("PHI")
                  ) && toggleConfirmDeleteResourceModal()
                }
                sx={{
                  fontSize: "36px",
                  color: selectedFiles.some((file: any) =>
                    file.folderLocation.startsWith("PHI")
                  )
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                selectedFiles.some((file: any) =>
                  file.folderLocation.startsWith("PHI")
                )
                  ? "No es posible mover recursos de esta carpeta"
                  : "Mover recursos seleccionados"
              }
            >
              <DriveFileMoveIcon
                onClick={() =>
                  !selectedFiles.some((file: any) =>
                    file.folderLocation.startsWith("PHI")
                  ) && toggleOpenMoveResourceModal()
                }
                sx={{
                  fontSize: "36px",
                  color: selectedFiles.some((file: any) =>
                    file.folderLocation.startsWith("PHI")
                  )
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              />
            </Tooltip>
          </>
        )}
      </Stack>
      <ConfirmationModal
        open={openConfirmDeleteResourceModal}
        handleClose={toggleConfirmDeleteResourceModal}
        handleAccept={() => {
          handleDeleteResource(false);
        }}
        title="Eliminar recursos"
        message={`eliminar ${
          selectedFiles?.length > 1
            ? "los recursos seleccionados"
            : "el recurso seleccionado"
        }`}
      />
      <ConfirmationModal
        open={openDeleteWarningModal}
        handleClose={toggleConfirmDeleteWarningModal}
        handleAccept={() => {
          handleDeleteResource(true);
        }}
        title="Eliminar recurso"
        message={`¿Confirma que desea eliminar ${
          selectedFiles?.length > 1
            ? "los recursos seleccionados"
            : "el recurso seleccionado"
        }? ${
          selectedFiles?.length > 1 ? "Estan " : "Esta "
        }siendo utilizado en: ${formatResourceUsesData(
          dataDelete?.modelos_en_uso
        )}`}
        customMessage
      />
      <MoveResourceModal
        name={selectedFiles.map((file: any) => file.name)}
        open={openMoveResourceModal}
        handleClose={toggleOpenMoveResourceModal}
        handleAccept={handleMoveResource}
      />
    </>
  );
};

export default SelectFiles;
