import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { DATA_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filterDataByResourceName } from "../../../utils/util";
import useApi from "../../../hooks/useApi";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";
import useMultiSelect from "../../../hooks/useMultiSelect";
import { truncateText } from "../../taskManager/utils";
import Filters from "../Filters";
import ReloadResources from "../ReloadResources";
import { menu_logo_little_alpha_styles } from "../../../styles/app-styles";
import logoPhi from "../../../assets/logoPhi.png";

interface ExploreFilesTabProps {
  selectedFile: string | any[];
  setSelectedFile: any;
  areMultipleFiles?: boolean;
  setSelectedFileFolder?: any;
  notUnselectFilesOnFolderChange?: boolean;
  customInitialRoute?: string;
}

const ExploreFilesTab = ({
  selectedFile,
  setSelectedFile,
  areMultipleFiles,
  setSelectedFileFolder,
  notUnselectFilesOnFolderChange,
  customInitialRoute,
}: ExploreFilesTabProps) => {
  const [selectedFolder, setSelectedFolder] = useState<string>(
    customInitialRoute ? customInitialRoute : ""
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>();
  const [initialData, setInitialData] = useState<any[]>();
  const [allFilesSelected, setAllFilesSelected] = useState<boolean>(false);
  const [
    labelsSelectedOption,
    setLabelSelectedOption,
    labelsOptionsChangeHandler,
  ] = useMultiSelect();
  const labelRef: any = useRef(null);

  const {
    data: initialDataResources,
    isLoading: isLoadingInitialDataResources,
    isError: isErrorInitialDataResources,
    refetch: refetchResources,
  } = useApiQuery(
    `${DATA_URL}?ruta=${
      customInitialRoute ? encodeURIComponent(customInitialRoute) : ""
    }`,
    false,
    texts.dataManagement.getResources.error
  );

  const onSuccessGetData = (data: any) => {
    setFilteredData(data);
  };

  const {
    data: dataResources,
    error: isErrorDataResources,
    isLoading: isLoadingDataResources,
    callApi: getResources,
  } = useApi(
    `${DATA_URL}?ruta=${encodeURIComponent(getRoute(location.pathname))}/`,
    "GET",
    texts.dataManagement.getResources.error,
    undefined,
    onSuccessGetData,
    undefined,
    false
  );

  /*************************** CARGA DE DATOS ***********************************/

  useEffect(() => {
    if (initialDataResources) {
      setFilteredData(initialDataResources);
      setInitialData(initialDataResources);
    }
  }, [initialDataResources]);

  useEffect(() => {
    if(customInitialRoute){
      setSelectedFolder(customInitialRoute as string);
    }
  }, [customInitialRoute]);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      filterDataByResourceName(
        initialDataResources,
        e.target.value,
        setFilteredData
      );
    } else {
      setFilteredData(initialDataResources);
    }
  };

  const goBack = () => {
    // Paso 1: Remover cualquier barra al final de la carpeta seleccionada
    const cleanedFolders = selectedFolder.replace(/\/+$/, "");

    // Paso 2: Formatear las carpetas en un array
    const formattedFolders = cleanedFolders.split("/");

    // Paso 3: Remover la última carpeta
    formattedFolders.pop();

    // Paso 4: Reconstruir la carpeta seleccionada y setearla
    const newSelectedFolder =
      formattedFolders.join("/") !== "" ? formattedFolders.join("/") + "/" : "";
    setSelectedFolder(newSelectedFolder);
    setSelectedFileFolder(newSelectedFolder);

    // Paso 5: Filtrar los datos
    if (formattedFolders.join("/") !== "") {
      getResources(`${DATA_URL}?ruta=${encodeURIComponent(newSelectedFolder)}`);
    } else {
      // Paso 5.3: Mantener todos los datos si no hay una carpeta seleccionada
      if (initialDataResources && !customInitialRoute) {
        setFilteredData(initialData);
      } else {
        getResources(`${DATA_URL}?ruta=`);
      }
    }
  };

  const handleClickOnFile = (file: any) => {
    if (isFileSelected(file.nombre)) {
      setAllFilesSelected(false);
      areMultipleFiles && typeof selectedFile !== "string"
        ? setSelectedFile(
            selectedFile.filter(
              (selectedFile: any) => selectedFile.nombre !== file.nombre
            ),
            file,
            true
          )
        : setSelectedFile("");
    } else {
      areMultipleFiles && typeof selectedFile !== "string"
        ? setSelectedFile([...selectedFile, file], file)
        : setSelectedFile(file.nombre, file);
    }
  };

  const isFileSelected = (file: string) => {
    return areMultipleFiles && Array.isArray(selectedFile)
      ? selectedFile.map((file: any) => file.nombre).includes(file)
      : file === selectedFile;
  };

  const handleClickOnFolder = (folder: any) => {
    setLabelSelectedOption([]);
    labelRef?.current?.clearValue();
    if (searchValue === "") {
      setSelectedFolder(
        selectedFolder === ""
          ? `${folder.nombre}/`
          : `${selectedFolder}${folder.nombre}/`
      );
      setSelectedFileFolder(
        selectedFolder === ""
          ? `${folder.nombre}/`
          : `${selectedFolder}${folder.nombre}/`
      );
      getResources(
        `${DATA_URL}?ruta=${encodeURIComponent(
          selectedFolder === ""
            ? `${folder.nombre}/`
            : `${selectedFolder}${folder.nombre}/`
        )}`
      );
    } else {
      setSelectedFolder(`${folder.ubicacion}${folder.nombre}/`);
      setSelectedFileFolder(`${folder.ubicacion}${folder.nombre}/`);
      getResources(
        `${DATA_URL}?ruta=${encodeURIComponent(
          folder.ubicacion
        )}${encodeURIComponent(folder.nombre)}/`
      );
    }
    if (selectedFile && notUnselectFilesOnFolderChange === undefined) {
      areMultipleFiles ? setSelectedFile([]) : setSelectedFile("");
    }
  };

  const handleSelectAllFiles = () => {
    if (allFilesSelected) {
      setSelectedFile([]);
      setAllFilesSelected(false);
    } else {
      setSelectedFile(
        filteredData
          ?.filter((f: any) => {
            return f.tipo === "archivo";
          })
          .map((file: any) => {
            return file;
          }),
        "all"
      );
      setAllFilesSelected(true);
    }
  };

  const refreshResources = () => {
    getResources(
      `${DATA_URL}?ruta=${encodeURIComponent(selectedFolder)}&cache=false/`
    );
  };


  return (
    <>
      <Stack sx={{ width: "100%", maxHeight: "500px", overflow: "auto" }}>
        {/*     
            <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder="Búsqueda por nombre"
            />
          </Stack>
         */}
        <Filters
          setFilteredData={setFilteredData}
          initialData={initialData}
          valueToCleanValues={location.pathname}
          filteredData={filteredData}
          disabled={isLoadingDataResources || isLoadingInitialDataResources}
        />
        <Stack sx={{ width: "100%" }}>
          {selectedFolder !== "" && (
            <Stack
              sx={{
                flexDirection: "row",
                width: "100px",
                alignItems: "center",
                marginBottom: "9px",
              }}
              onClick={isLoadingDataResources ? undefined : goBack}
            >
              <ArrowBackIcon
                sx={{
                  color: isLoadingDataResources
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  "& :hover": {
                    cursor: "pointer",
                    color: isLoadingDataResources
                      ? "var(--light-grey)"
                      : "#f90d4a",
                  },
                }}
              />
              <Typography
                sx={{
                  color: isLoadingDataResources
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  fontSize: "14px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginLeft: "5px",
                  "& :hover": { color: "#f90d4a" },
                }}
              >
                VOLVER
              </Typography>
            </Stack>
          )}
          {(isLoadingInitialDataResources || isLoadingDataResources) && (
            <Stack
              sx={{
                width: "100%",
                height: "150px",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          )}
          {!isLoadingInitialDataResources &&
            !isErrorInitialDataResources &&
            !isLoadingDataResources && (
              <>
                <Stack
                  direction="row"
                  width="98%"
                  justifyContent={"space-between"}
                  marginTop="5px"
                >
                  <Typography
                    sx={{
                      color: "var(--blue)",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    Carpeta seleccionada:{" "}
                    {selectedFolder === "" ? "Carpeta raíz" : selectedFolder}
                  </Typography>
                  <ReloadResources refetchResources={refreshResources} />
                </Stack>

                <Stack width={"100%"} direction={"row"}>
                  <Typography
                    sx={{
                      color: "var(--blue)",
                      fontSize: "16px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    Carpetas
                  </Typography>
                </Stack>

                <Stack
                  width={"100%"}
                  direction={"row"}
                  gap={3}
                  flexWrap="wrap"
                  marginBottom="15px"
                >
                  {filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.filter((f: any) => {
                    return f.tipo === "carpeta";
                  }).length > 0 ? (
                    filteredData
                      .filter((resource: any) => {
                        return resource.tipo === "carpeta";
                      })
                      .sort((a, b) =>
                        a.nombre === "PHI" ? -1 : b.nombre === "PHI" ? 1 : 0
                      )
                      .map((folder: any, index: number) => {
                        return (
                          <Stack
                            key={index}
                            sx={{
                              backgroundColor: "var(--very-very-light-grey)",
                              borderRadius: "10px",
                              cursor: "pointer",
                              padding: "5px",
                              width: "125px",
                              position: "relative",
                              justifyContent:
                                folder.nombre.toUpperCase() === "PHI"
                                  ? "center"
                                  : undefined,
                              "&:hover": {
                                backgroundColor: "var(--very-light-blue)",
                              },
                            }}
                            onClick={() => {
                              handleClickOnFolder(folder);
                            }}
                          >
                            {folder?.etiqueta?.nombre !== undefined && (
                              <Tooltip title={folder?.etiqueta?.nombre}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "white",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                    backgroundColor: folder?.etiqueta?.color,
                                    borderRadius: "5px",
                                    padding: "0px 2px",
                                  }}
                                >
                                  {truncateText(folder?.etiqueta?.nombre, 16)}
                                </Typography>
                              </Tooltip>
                            )}
                            <Stack
                              sx={{
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {folder.nombre.toUpperCase() === "PHI" ? (
                                <img
                                  style={menu_logo_little_alpha_styles}
                                  src={logoPhi}
                                  alt="logo"
                                />
                              ) : (
                                <FolderIcon
                                  style={{
                                    fontSize: 30,
                                    color: folder.etiqueta?.color
                                      ? folder.etiqueta?.color
                                      : "var(--dark-blue)",
                                  }}
                                />
                              )}
                              {folder.nombre.toUpperCase() !== "PHI" && (
                                <Typography
                                  sx={{
                                    fontSize:
                                      folder.nombre.length < 20
                                        ? "14px"
                                        : "12px",
                                    color: "var(--blue)",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {folder.nombre}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        );
                      })
                  ) : (
                    <Box
                      sx={{
                        width: "95%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "var(--dark-grey)",
                          fontSize: "16px",
                        }}
                      >
                        No hay carpetas disponibles.
                      </Typography>
                    </Box>
                  )}
                </Stack>
                <Typography
                  sx={{
                    color: "var(--blue)",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    marginTop: "15px",
                    marginBottom: "5px",
                  }}
                >
                  Archivos
                </Typography>
                {areMultipleFiles && (
                  <Typography
                    sx={{
                      color: "var(--blue)",
                      fontSize: "14px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "5px",
                      cursor: "pointer",
                      "& :hover": { color: "#f90d4a" },
                    }}
                    onClick={handleSelectAllFiles}
                  >
                    {allFilesSelected
                      ? "Deseleccionar todos"
                      : "Seleccionar todos"}
                  </Typography>
                )}

                <Stack
                  width={"100%"}
                  direction={"row"}
                  gap={3}
                  marginBottom="30px"
                  flexWrap="wrap"
                >
                  {filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.filter((f: any) => {
                    return f.tipo === "archivo";
                  }).length > 0 ? (
                    filteredData
                      .filter((f: any) => {
                        return f.tipo === "archivo";
                      })
                      .map((file: any, index: number) => {
                        return (
                          <Stack
                            key={index}
                            sx={{
                              backgroundColor: "var(--very-very-light-grey)",
                              border: isFileSelected(file.nombre)
                                ? "2px solid var(--blue)"
                                : undefined,
                              borderRadius: "10px",
                              position: "relative",
                              cursor: "pointer",
                              padding: "5px",
                              width: "125px",
                              "&:hover": {
                                backgroundColor: "var(--very-light-blue)",
                              },
                            }}
                            onClick={() => {
                              handleClickOnFile(file);
                            }}
                          >
                            {file?.etiqueta?.nombre !== undefined && (
                              <Tooltip title={file?.etiqueta?.nombre}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "white",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                    backgroundColor: file?.etiqueta?.color,
                                    borderRadius: "5px",
                                    padding: "0px 2px",
                                  }}
                                >
                                  {truncateText(file?.etiqueta?.nombre, 16)}
                                </Typography>
                              </Tooltip>
                            )}
                            <Stack
                              sx={{
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <InsertDriveFileIcon
                                style={{
                                  fontSize: 30,
                                  color: file.etiqueta?.color
                                    ? file.etiqueta?.color
                                    : "var(--dark-blue)",
                                }}
                              />

                              <Typography
                                sx={{
                                  fontSize:
                                    file.nombre.length < 20 ? "14px" : "12px",
                                  color: "var(--blue)",
                                  wordBreak: "break-all",
                                }}
                              >
                                {file.nombre}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      })
                  ) : (
                    <Box
                      sx={{
                        width: "95%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "var(--dark-grey)",
                          fontSize: "16px",
                        }}
                      >
                        No hay archivos disponibles.
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default ExploreFilesTab;
