import {
  StyledButton
} from "./styled";

interface ButtonProps {
  title: string;
  color:
    | "blue"
    | "light-blue"
    | "magenta"
    | "grey"
    | "blue-greeny"
    | "greeny"
    | "light-grey"
    | "outlined";
  type: "button" | "submit" | "reset" | undefined;
  onClick?: (() => void) | ((e: any) => void) | undefined;
  width?: number | undefined;
  margin?: boolean;
  disabled?: boolean;
}

const CustomButton = ({
  title,
  color,
  type,
  onClick,
  width,
  margin,
  disabled,
}: ButtonProps) => {
  const getColor = (
    color:
      | "blue"
      | "light-blue"
      | "magenta"
      | "grey"
      | "greeny"
      | "blue-greeny"
      | "light-grey"
      | "outlined"
  ) => {
    switch (color) {
      case "blue":
        return "var(--blue)";
        case "light-blue":
          return "var(--light-blue)";
        case "greeny":
          return "var(--greeny)";
      case "magenta":
        return "var(--magenta)";
      case "grey":
        return "var(--dark-grey)";
      case "blue-greeny":
        return "var(--blue-greeny)";
      case "light-grey":
        return "var(--light-grey)";
      case "outlined":
        return "outlined";
      default:
        return "#172D40";
    }
  };

  return (
    <StyledButton
      type={type}
      margin={margin}
      bgcolor={color ? getColor(color) : "var(--blue)"}
      width={ width ? `${width}px` : "150px"}    
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </StyledButton>
  );
};

export default CustomButton;
