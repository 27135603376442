export const enTranslations = {
  administratorPanel: {
    sendCode: {
      codes: {
        200: "The code has been successfully sent",
        400: "An error occurred and the code could not be sent",
      },
    },
    postCode: {
      codes: {
        200: "The password has been updated successfully",
        400: "The code is incorrect",
      },
    },
    companies: {
      createCompany: {
        codes: {
          200: "The company has been created successfully",
          201: "The company has been created successfully",
          400: "An error occurred and the company could not be created",
        },
      },
      editCompany: {
        codes: {
          200: "The company has been edited successfully",
          400: "An error occurred and the company could not be edited",
        },
      },
      loadingCompanies: "Loading companies...",
      searchCompanyPlaceholder: "Search by company name",
      activeCompanies: "Active companies",
      activeCompany: "Active company",
      inactiveCompanies: "Inactive companies",
      noActiveCompanies: "There are no active companies",
      noInactiveCompanies: "There are no inactive companies",
      switchLabelActive: "Active",
      switchLabelInactive: "Inactive",
      duplicatedCompanyName: "A company with that name already exists",
      companyNameFormat:
        "The name can only contain numbers, letters, and underscores (_)",
      newCompany: "New company registration",
      editCompanyLabel: "Edit company",
      createCompanyLabel: "Create company",
      creatingCompany: "Creating company...",
      updatingCompany: "Updating company...",
      copyingCompany: "Copying company...",
      companyInformation: "Company information",
      name: "Name",
      visualName: "Visual name",
      editCompanyAction: "edit company",
      createCompanyAction: "create company",
      cleanAll: "Clear all",
      finish: "Finish",
    },
    users: {
      createUser: {
        codes: {
          200: "User created successfully",
          201: "User created successfully",
          400: "An error occurred and the user could not be created",
        },
      },
      editUser: {
        codes: {
          200: "User edited successfully",
          400: "An error occurred and the user could not be edited",
        },
      },
      editPassword: {
        codes: {
          200: "Password updated successfully",
          400: "An error occurred and the password could not be updated",
        },
      },
      incorrectFormat: "Incorrect format",
      incorrectPasswordFormat: "Incorrect password format",
      duplicatedEmail: "A user with this email already exists",
      newPassword: "New password",
      repeatPassword: "Repeat password",
      notMatchingPasswords: "Passwords do not match",
      passwordFormatLabel:
        "*The password must be at least 8 characters long, including one uppercase letter, one lowercase letter, and at least one number or special character.",
      authCode: "Authentication code",
      authCodeSentToEmail:
        "We have sent an authentication code to your email address to validate your identity.",
      incorrectAuthCode: "The authentication code is incorrect",
      rol: "Role",
      active: "Active",
      inactive: "Inactive",
      notPossibleToDisableUserLabel: "It is not possible to disable your user",
      notPossibleToDisableUserError:
        "It is not possible to disable your own user. Only another administrator user can perform this action.",
      accept: "Accept",
      administrator: "Administrator",
      consultant: "Consultant",
      client: "Client",
      editUserLabel: "Edit user",
      newUserLabel: "New user registration",
      userInformation: "User information",
      optionalName: "Name (optional)",
      optionalLastName: "Last name (optional)",
      name: "Name",
      lastName: "Last name",
      email: "Email",
      generatePassword: "Generate password",
      companiesLabel: "Companies",
      userEnabledCompanies: "Companies enabled for the user",
      multipleOptions: "Choose one or more options...",
      assignedCompany: "Assigned company",
      chooseCompanyLabel: "Choose selected company...",
      enableUser: "Enable user",
      activeUser: "Active user",
      editPasswordLabel: "Edit password",
      editUserAction: "edit the user",
      createUserAction: "create the user",
      createUserLabel: "Create user",
      cleanAll: "Clear all",
      finish: "Finish",
      errorLoadingUsers:
        "An error occurred while loading users. Please try again later.",
      loadingUsers: "Loading users...",
      searchUserLabel: "Search by user's name or email",
      activeUsers: "Active users",
      inactiveUsers: "Inactive users",
      noActiveUsers: "There are no active users",
      noInactiveUsers: "There are no inactive users",
    },
    usersLabel: "USERS",
    companiesLabel: "COMPANIES",
    administratorPanel: "Administrator Panel",
  },
  appBarComponent: {
    adminDashboard: "Administrator Panel",
  },
  avatar: {
    logoutLoading: "Logging out...",
    logout: "Logout",
  },
  basicGrid: {
    noLinesLabel: "You haven't added lines yet",
    column: "Column",
    function: "Function",
  },
  buttons: {
    speedDialTooltipOpen: {
      edit: "Edit",
      duplicate: "Duplicate",
      seeMore: "See more",
      delete: "Delete",
    },
  },
  calculation: {
    getCompletedStages: {
      loading: "Getting stages...",
      error:
        "An error occurred and it was not possible to retrieve the stages.",
    },
  },
  charts: {
    allCharts: {
      groupsError:
        "An error occurred and the groups could not be downloaded. Please try again later.",
      chartsLabel: "Charts",
      periodsLabel: "Periods",
      downloadingGroups: "Downloading groups...",
      downloadingCharts: "Downloading charts...",
      groupsDownloadigError: "An error occurred while downloading the groups.",
      chartsDownloadingError: "An error occurred while downloading the charts.",
      noActiveChartsError: "There are no charts created or active yet.",
    },
    barInfiniteDrilldownChart: {
      originalExpensePercent: "Percentage relative to original expense",
    },
  },
  chartsManagement: {
    addChart: {
      codes: {
        201: "The chart has been created successfully",
        400: "An error occurred and the chart could not be created",
      },
    },
    editChart: {
      codes: {
        200: "The chart has been updated successfully",
        400: "An error occurred and the chart could not be edited",
      },
    },
    putState: {
      codes: {
        200: "The state has been successfully modified",
        201: "The state has been successfully modified",
        400: "An error occurred and the state could not be modified",
      },
    },
    getGroup: {
      error: "An error occurred and it was not possible to retrieve the groups",
    },
  },
  codeVerification: {
    twoStepsVerification: "Two-Step Verification",
    completeWithTheCodeSentToEmail:
      "Please complete with the code sent to your email. Do not share this code with anyone.",
    codeSentToEmail: "The code has been sent to your email inbox.",
    cancel: "Cancel",
    enter: "Enter",
  },
  company: {
    getCompanyError: "It was not possible to load the company",
    getCompaniesError: "It was not possible to load the companies",
    loadingCompanies: "Loading companies...",
    selectCompany: "Select your company",
    chooseCompany: "Choose company",
    userCompanyError: "It was not possible to get the company for this user",
    companiesError: "It was not possible to retrieve the companies",
    changeCompanyError:
      "An error occurred while changing the company, please try again",
    closingSession: "Closing session...",
    cancel: "Cancel",
    start: "Start",
    codes: {
      200: "The company has been successfully changed",
      400: "An error occurred and the company could not be changed",
      404: "An error occurred and the company could not be changed",
    },
  },
  columns: {
    action: "action",
    apportionment_criteria: "apportionment_criteria",
    bucket: "bucket",
    cause_error: "cause_error",
    column: "Column",
    criteria: "Criteria",
    date: "date",
    destination_receiver: "destination_receiver",
    destination: "destination",
    expenses_transferred: "expenses_transferred",
    file: "file",
    iteration: "iteration",
    mount: "amount",
    period: "period",
    previous_destination: "previous_destination",
    receiver_criteria: "receiver_criteria",
    stage: "stage",
    stages: "stages",
    state: "state",
    values: "Values",
  },
  configCriteria: {
    addTargetModal: {
      repeatedTargetNameError: "A target with that name already exists",
      emptyFieldsError: "Fields cannot be left empty",
      succesCreatedTarget: "Expense receiver has been successfully created",
      errorCreatedTarget:
        "An error occurred while creating the receiver, please try again.",
      loadingPostTarget: "Creating expense receiver...",
      newTarget: "New receiver",
      cancel: "Cancel",
      accept: "Accept",
      name: "Name",
      target: "Expense receiver",
      newTargetName: "Name of the new receiver",
      targetPlaceholder: "Column name from the file (e.g., CODE_ROUTE)",
      validatePreviousDestinity: "Validate previous destination",
    },
    configCriteriaComponent: {
      criteriaAdmin: "Criteria administration",
      nonAppliedFilters:
        "There are filters not added. Do you confirm you want to create the criteria anyway?",
      nonAppliedFilterWarning:
        "There are filters not added. Do you want to continue anyway?",
      previous: "Previous",
      resetSteps: "Reset all criteria steps",
      cleanAll: "Clear all",
      finish: "Finish",
      next: "Next",
      editCriteria: "edit the criteria",
      createCriteria: "create the criteria",
      editCriteriaTitle: "Edit criteria",
      createCriteriaTitle: "Create criteria",
      noFilters: "No filters",
      goBack: "Go back",
      continue: "Continue",
      incomingConditionOptions: ["and", "or"],
      incomingFilterTypeOptionsCriteria: [
        "INCLUIR",
        "EXCLUIR",
        "MENOR A",
        "MENOR O IGUAL A",
        "IGUAL A",
        "MAYOR A",
        "MAYOR O IGUAL A",
        "DIFERENTE A",
      ],
      incomingFilterTypeOptions: ["INCLUIR", "EXCLUIR"],
      incomingValuesOptions: [
        { value: "CROSSDOCKS" },
        { value: "PLANTS" },
        { value: "T1" },
      ],
      steps: [
        "Initial parameterization of criteria",
        "File filters",
        "Validation of EXPENSES file with file",
        "File filters with external information",
      ],
      filterMessages: {
        emptyError: "All fields must be completed to add a filter.",
        duplicatedError:
          "There are already filters with these validation criteria.",
        conditionTooltipInfo:
          "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
        duplicatedCriteriaNameError:
          "A criterion with that name already exists.",
      },
      tooltips: {
        conditionTooltip:
          "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
      },
    },
    filterRow: {
      filterWarning:
        "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
    },
    step1: {
      apportionment: "APPORTIONMENT",
      description: "Description",
      criteriaName: "Criterion name",
      applyIn: "Apply in",
      bucket: "Bucket",
      all: "All",
      expenseTargets: "Expense receivers",
      calcParameterization: "Calculation parameterization",
      file: "File",
      selectFile: "Select file...",
      column: "Column",
      selectColumn: "Select column...",
      function: "Function",
      selectFunction: "Select function...",
      countFunction: "COUNT",
      countUniqueFunction: "COUNT.UNIQUE",
      sumUniqueFunction: "SUMA.UNICO",
      sumProductFunction: "SUM.PRODUCT",
      sumProductFunctionLabel: "Product sum",
      sumUniqueFunctionLabel: "Unique sum",
      criteriaUniqueColumn: "Criterion unique column",
      notModifyValues: "Do not modify negative values",
      convertNegativesToPositives: "Convert negative values to positives",
      convertNegativesToZero: "Convert negative values to zero",
      columnToMultiply: "Column to multiply",
      selectOptions: "Select one or more options...",
      changeFileWarning:
        "Do you confirm that you want to edit the criterion's file? This action will reset steps 2, 3, and 4 of the criterion if they are used.",
      editFileLabel: "Edit criterion file",
      targetExpenses: "Expense Receivers",
    },
    step2: {
      repeatedFilterError:
        "There is already a filter for this column. Adding it will replace the previous filter.",
      include: "INCLUIR",
      exclude: "EXCLUIR",
      previousFilter: "Previous filters",
      in: " in ",
      condition: "Condition",
      filterType: "Filter type",
      type: "Type...",
      values: "Values",
      value: "Value",
      valuePlaceholder: "Enter a numerical value",
      noFiltersWarning: "You haven't added filters yet",
    },
    step3: {
      expensesColumn: "Expense column",
      noValidationsWarning: "You haven't added validations yet",
    },
    step4: {
      crossoverValues: "Crossover values",
      validationFile: "Validation file",
      columnValidationFile: "Validation file column",
      columnFile: "File column",
    },
    virtualSelect: {
      loading: "Loading...",
      noAvailableOptions: "No available options",
      selectValues: "Select one or more values...",
    },
    getCriteriaBucket: {
      error:
        "An error occurred and it was not possible to download the criteria buckets",
    },
    getCriteriaTarget: {
      error:
        "An error occurred and it was not possible to download the criteria receivers.",
    },
    getCriteriaFileColumn: {
      error:
        "An error occurred and it was not possible to download the criteria files.",
    },
    getCriteriaFunctions: {
      error:
        "An error occurred and it was not possible to download the criteria functions.",
    },
    getExpensesColumn: {
      error:
        "An error occurred and it was not possible to download the criteria expenses.",
    },
    sendCriteria: {
      loading: "Creating criteria...",
      codes: {
        200: "The criteria has been successfully created",
        201: "The criteria has been successfully created",
        400: "An error occurred and the criteria could not be created",
      },
    },
    putCriteria: {
      loading: "Modifying criteria...",
      codes: {
        200: "The criteria has been successfully modified",
        201: "The criteria has been successfully modified",
        400: "An error occurred and the criteria could not be modified",
      },
    },
  },
  copyGenericText: "_copy",
  criteriaManagement: {
    deleteCriteria: {
      loading: "Deleting criteria...",
      codes: {
        200: "The criteria has been successfully deleted",
        201: "The criteria has been successfully deleted",
        203: "The criteria has been successfully deleted",
        400: "An error occurred and the criteria could not be deleted",
      },
    },
    getCriteria: {
      loading: "Getting criteria...",
      error:
        "An error occurred and it was not possible to download the criteria.",
    },
  },
  criteriaManagementTexts: {
    criteriaCard: {
      receivers: "Receivers",
      filters: "Filters",
      validations: "Validations",
      inUseBuckets: "In use, associated with buckets",
      inUseBucket: "In use, associated with bucket",
      notUsed: "Not used",
    },
    criteriaManagement: {
      lookingCriteria: "Looking for criteria...",
      deletingCriteria: "Deleting criteria...",
      criteriaManagement: "Criteria management",
      sortAlphabet: "ALPHABETICALLY",
      sortDate: "BY DATE",
      filter: "Filter",
      searchByCriteriaBucket: "Search by criterion name or bucket",
      noCriteriaAvailable: "There are no criteria available yet.",
      notGetCriteria:
        "It was not possible to retrieve the criteria. Please try again later.",
      deleteCriteria: "delete the criteria",
      noActiveCriteria: "Inactive Criteria",
      activeCriteria: "Active Criteria",
      noFilters: "No filters",
    },
    viewInfoModal: {
      criteriaInfo: "General criterion information",
      criteriaBase: "Base for criterion:",
      criteriaColumn: "Criterion column:",
      aplicatedFunction: "Applied function:",
      convertNegativesToPositives: "Convert negatives to positives:",
      convertNegativesToZero: "Convert negatives to zero:",
      columnsToValidate: "Columns to validate as unique:",
      yes: "Yes",
      no: "No",
      columnsToMultiply: "Columns to multiply",
      filterInFile: "Filters in file",
      expensesCrossover: "Expenses value crossover with",
      filterBy: "Filtered by",
      withInfo: "with information from",
      previousFilters: "Previous filters in file",
      crossoverValues: "Crossover values of",
      with: "with",
      previousDestinitiesValidations: "Validation of previous destinations",
    },
  },
  dashboard: {
    duplicated: "A dashboard with this name already exists.",
    invalidLink: "The link must be a valid Power BI link.",
    noData: "There are no dashboards to show.",
    get: {
      loading: "Getting dashboards...",
      error: "An error occurred while getting dashboards, please try again.",
    },
    post: {
      codes: {
        200: "The dashboard has been successfully added.",
        201: "The dashboard has been successfully added.",
        400: "An error occurred while adding the dashboard, please try again.",
        500: "An error occurred while adding the dashboard, please try again.",
      },
    },
    delete: {
      success: "The dashboard has been successfully deleted.",
      error:
        "An error occurred while deleting the dashboard, please try again.",
      codes: {
        200: "The dashboard has been successfully deleted.",
        204: "The dashboard has been successfully deleted.",
        400: "An error occurred while deleting the dashboard, please try again.",
      },
    },
  },
  dashboardTexts: {
    dashboard: {
      addDashboard: "Edit Dashboard",
      addNewDashboard: "Add New Dashboard",
    },
    newDashboardModalContent: {
      deletingDashboard: "Deleting dashboard...",
      name: "Name:",
      dashboardName: "Dashboard name...",
      linkDashboard: "Dashboard link...",
      deleteDashboardFromListing: "Delete dashboard from listing.",
      close: "Close",
      deleteDashboard: "Delete Dashboard",
    },
  },
  dataManagement: {
    downloadFile: {
      loading: "Downloading file...",
      codes: {
        200: "The file has been successfully downloaded",
        201: "The file has been successfully downloaded",
        400: "An error occurred while downloading the file",
      },
      empty: "There are no files to download",
    },
    getRowsPreview: {
      loading: "Getting preview...",
      codes: {
        200: "Preview obtained successfully",
        400: "An error occurred while getting the preview",
      },
    },
    getColumnsFiles: {
      loading: "Getting columns...",
      error: "An error occurred while downloading the columns.",
    },
    getRowsFiles: {
      loading: "Getting rows...",
      error: "An error occurred while downloading the rows.",
    },
    getAllowedFileNames: {
      loading: "Getting allowed file names...",
      error: "An error occurred while getting the allowed file names.",
    },
    getCompletedStages: {
      codes: {
        400: "An error occurred while downloading the stages.",
      },
      error: "An error occurred while downloading the stages.",
    },
    deleteFile: {
      codes: {
        200: "The file has been successfully deleted.",
        400: "An error occurred while deleting the file.",
        500: "An error occurred while deleting the file.",
      },
      loading: "Deleting file...",
    },
    deletePeriod: {
      codes: {
        200: "The period has been successfully deleted.",
        400: "An error occurred while deleting the period.",
        500: "An error occurred while deleting the period.",
      },
    },
    uploadModal: {
      restartCalculation: "Restart calculation",
    },
  },
  dataManagementTexts: {
    allowFilesModalContent: {
      fileNameExists: "A file with this name already exists.",
      nameRules:
        "The name can only contain letters, numbers, hyphens, and be up to 30 characters long.",
      nameFileDeleted: "The file name has been successfully deleted.",
      errorAllowFileDeleting:
        "An error occurred while deleting the allowed file name, please try again.",
      successAllowFileDeleting:
        "The allowed file name has been successfully added.",
      errorAddAllowFile:
        "An error occurred while adding the allowed file name, please try again.",
      addNewAllowFile: "Add New Allowed File Name",
      fileName: "File Name...",
      allowedFiles: "Allowed Files",
      deleteAllowedFile: "Delete Allowed File",
      close: "Close",
      delete: "Delete",
      offAllowdFileNames: "off allowed file names",
      deleteFile: "Delete File",
    },
    notAllowedToAddFileModal: {
      cannotAddName: "Cannot add the name",
      notPossibleExpensesName:
        "Cannot add the name PRE_GASTOS to allowed file names.",
      accept: "Accept",
    },
    notAllowedToDeleteFileModal: {
      cannotAddName: "Cannot delete the name",
      notPossibleDelete_1: "Cannot delete ",
      notPossibleDelete_2:
        "from allowed file names because there are files loaded with this name. They need to be deleted first.",
      accept: "Accept",
    },
    dataManagement: {
      taskManager: "/gestor-tareas",
      fileStillLoading:
        "This file is still loading. Please wait a moment and try again.",
      cannotGetPreView: "Could not get the preview of this file.",
      deletingPeriodFiles: "Deleting period files...",
      downloadingFile: "Downloading file...",
      dataManagement: "Data Management",
      errorCouldNotDownload:
        "An error occurred and it was not possible to download the information.",
      uploadFiles: "Upload Files",
      allowedFiles: "Allowed Files",
      cannotGetAllowedFiles: "Could not retrieve allowed files.",
      fileUpload: "File Upload",
      close: "Close",
      deleteSelectedPeriod: "delete the selected period",
      deletePeriod: "Delete Period",
      confirmDeleteFile_1: "Are you sure you want to delete the file",
      confirmDeleteFile_2: "? This will also delete related tasks.",
      deleteFile: "Delete File",
      preview: "Preview",
      uploading: "Uploading",
      files: "files",
      closingWindowStopUpload:
        "Closing the window will stop the upload process, and files that haven't started uploading will be lost. Do you want to stop the upload?",
      closeFileUpload: "Close File Upload",
      fileDownload: "File Download",
      goDownloads: "Go to Downloads",
      downloadProccesStarted: "Download process has started.",
      viewDownloadStatus: `To view the download status and download the file, click on the "Go to Downloads" button. You can also see this from the "Task Manager" under Downloads.`,
    },
    linearDeterminate: {
      loading: "Loading",
      completedLoading: "Loading completed",
    },
    successUploadModalContent: {
      connectionError:
        "There was a connection error and the file could not be uploaded.",
      stopLoad:
        "Refreshing the page, closing this window, or closing the browser will stop the upload process and files that haven't started uploading will be lost.",
      charge: "upload",
      reTry: "Retry",
      cancel: "Cancel",
      chargeAbort: "Upload canceled",
      errorFileNotLoaded:
        "There was an error and the file could not be loaded.",
      loading: "Loading...",
      waiting: "Pending",
    },
    transformDataManagement: {
      downloadAllFilesFrom: "Download all files from",
      preview: "Preview",
      download: "Download",
      delete: "Delete",
      validated: "Validated",
      notValidated: "Not validated",
      notFound: "Not found",
      downloadAllFiles: "Download all files",
      downloadAllPeriodFiles: "Download all period files",
      deletePeriod: "Delete Period",
    },
    uploadModalContent: {
      fileNameFormat_1: "File names must follow the format",
      fileNameFormat_2: "NAME-MONTH-YEAR.csv or NAME-MONTH-YEAR.parquet",
      fileNameFormat_3: "e.g. SALES-02-2022.csv",
      allowedName: "Allowed file names:",
      cannotUploadNames: "Allowed file names have not been loaded.",
      dragSelectFiles: "Drag files here or click to select",
      filesToUpload: "Files to upload:",
      addAllowedFilesName: "Add allowed file names.",
      deleteFileFromListing: "Delete file from the listing.",
      fileAlreadyExistsRestatedCalculation:
        " - This file already exists and will replace the previous one. ",
      itWillBeRestarted: " Period calculation will be restarted.",
      fileAlreadyIncluded: " - This file is already included in the listing",
      fileNameNotAllowed: " - File name not allowed",
      cannotGetAllowedFiles: "Allowed files could not be retrieved",
      cancel: "Cancel",
      filledIllegalNames: "There are files with non-allowed names.",
      accept: "Accept",
    },
  },
  dataModel: {
    getMeasures: {
      error: "An error occurred and measures could not be retrieved.",
      codes: {
        200: "Measures were retrieved successfully.",
        400: "An error occurred and measures could not be retrieved.",
        404: "An error occurred and measures could not be retrieved.",
      },
    },
    getMeasure: {
      error: "An error occurred and the measure could not be retrieved.",
      codes: {
        200: "The measure was retrieved successfully.",
        400: "An error occurred and the measure could not be retrieved.",
        404: "An error occurred and the measure could not be retrieved.",
      },
    },
    getGroups: {
      error: "An error occurred and groups could not be retrieved.",
      codes: {
        200: "Groups were retrieved successfully.",
        400: "An error occurred and groups could not be retrieved.",
        404: "An error occurred and groups could not be retrieved.",
      },
    },
    getGroup: {
      error: "An error occurred and the group could not be retrieved.",
      codes: {
        200: "The group was retrieved successfully.",
        400: "An error occurred and the group could not be retrieved.",
        404: "An error occurred and the group could not be retrieved.",
      },
    },
    deleteGroup: {
      loading: "Deleting group...",
      codes: {
        200: "The group has been successfully deleted",
        204: "The group has been successfully deleted",
        400: "An error occurred and the group could not be deleted",
      },
    },
    deleteMeasure: {
      loading: "Deleting measure...",
      codes: {
        200: "The measure has been successfully deleted",
        400: "An error occurred and the measure could not be deleted",
      },
    },
    getAnalysis: {
      error: "An error occurred and analyses could not be retrieved",
      codes: {
        200: "Analyses were retrieved successfully",
        400: "An error occurred and analyses could not be retrieved",
        404: "An error occurred and analyses could not be retrieved",
      },
    },
    postNewAnalysis: {
      error: "An error occurred and ",
    },
    deleteAnalysis: {
      loading: "Creating analysis...",
      codes: {
        200: "The analysis has been successfully created",
        201: "The analysis has been successfully created",
        400: "An error occurred and the analysis could not be created",
      },
    },
    postCalculate: {
      codes: {
        200: "Calculation has been successfully initiated",
        201: "Calculation has been successfully initiated",
        400: "An error occurred and calculation could not be initiated",
      },
    },
    postNewMeasure: {
      loading: "Creating measure...",
      codes: {
        200: "The measure has been successfully created",
        201: "The measure has been successfully created",
        400: "An error occurred and the measure could not be created",
      },
    },
    putMeasure: {
      loading: "Modifying measure...",
      codes: {
        200: "The measure has been successfully modified",
        201: "The measure has been successfully modified",
        400: "An error occurred and the measure could not be modified",
      },
    },
    postNewGrouper: {
      loading: "Creating group...",
      codes: {
        200: "The group has been successfully created",
        201: "The group has been successfully created",
        400: "An error occurred and the group could not be created",
      },
    },
    putGrouper: {
      loading: "Editing group...",
      codes: {
        200: "The group has been successfully edited",
        201: "The group has been successfully edited",
        400: "An error occurred and the group could not be edited",
      },
    },
    postDownloadAnalysis: {
      loading: "Downloading report...",
      codes: {
        200: "The download has started successfully",
        201: "The download has started successfully",
        400: "An error occurred and the analysis download could not be started",
      },
    },
  },
  dataModelText: {
    analysisExecution: {
      analysisExecution: "Analysis Execution",
      startingCalculation: "Starting calculation...",
      anaylsis: "Analysis",
      selectAnalysisToRun: "Select analyses to run",
      periods: "Periods",
      selectPeriods: "Select periods",
      runSelectedAnalysisPlural: "run the selected analyses",
      runSelectedAnalysisSingular: "run the selected analysis",
      deleteData: "Delete data",
      runAnalysis: "Run analysis",
      noPeriods: "There are no periods with prorated buckets entirely.",
    },
    analysisManagement: {
      configAnalysis: {
        creatingAnalysis: "Creating analysis...",
        editingAnalysis: "Editing analysis...",
        analysisAlreadyCalculated:
          "This analysis has already been calculated. Editing it will delete the current calculation.",
        analysisName: "Analysis name",
        analysisAlreadyName: "A measure with that name already exists",
        groupers: "Groupers:",
        chooseGrouper: "Choose grouper...",
        measures: "Measures:",
        cancel: "Cancel",
        accept: "Accept",
        modifyAnalysis: "modify the analysis",
        createNewAnalysis: "create a new analysis",
        newAnalysis: "New analysis",
      },
      tranferList: {
        measureDisabled:
          "The measure is disabled because not all necessary primary measures were chosen.",
      },
      analysisAdmin: {
        searchingAnalysis: "Searching analysis...",
        analysisAdmin: "Analysis administration",
        periods: "Periods",
        searchByName: "Search by name",
        noAnalysisYet: "No analyses available yet.",
        deleteAnalysisConfirmCalculate:
          "Are you sure you want to delete the analysis? This action will also delete its calculation.",
        deleteAnalysisConfirm: "Are you sure you want to delete the analysis?",
        confirm: "Confirm",
        writeDelete: "Write the word 'delete'",
        delete: "delete",
      },
      analysisCard: {
        grouper: "Grouper:",
        measures: "MEASURES:",
        calculated: "Calculated",
        noCalculated: "Not calculated",
      },
      viewInfoAnalysisModal: {
        grouper: "GROUPER:",
        measures: "MEASURES:",
        analysisInfo: "General analysis information",
      },
    },
    downloadReport: {
      aggregationOperatorsLabels: {
        sum: "SUM",
        average: "AVERAGE",
        maximum: "MAXIMUM",
        minimum: "MINIMUM",
        count: "COUNT",
        count_unique: "COUNT_UNIQUE",
        first: "FIRST",
        last: "LAST",
        unique_values: "UNIQUE_VALUES",
      },
      year: "YEAR",
      month: "MONTH",
      monthly: "MONTHLY",
      quarterly: "QUARTERLY",
      biannual: "SEM-ANNUAL",
      annual: "ANUAL",
      downloadReport: "Download report",
      loading: "Loading...",
      referenceName: "Reference Name",
      notAnalysisExecuted: "No analyzes run yet",
      analysis: "Analysis",
      chooseAnalysisToDownload: "Select analysis to download...",
      filters: "Filters",
      columns: "Columns",
      chooseColumns: "Choose columns...",
      periodicity: "Periodicity",
      choosePeriodicity: "Choose periodicity...",
      measures: "Measures",
      chooseMeasures: "Choose measurements...",
      aggregationOperators: "Aggregation operators",
      chooseAggregationOperators: "Choose an operator...",
      deleteData: "Delete data",
      download: "Download",
      downloadAnalysisSelected: "download the selected analysis",
    },
    groupManagement: {
      step1: {
        addBaseButton: {
          newBase: "New Base",
        },
        mergeModal: {
          firstOcurrence: "first-occurrence",
          lastOcurrence: "last-occurrence",
          dontDelete: "do-not-delete",
          editMerge: "edit merge",
          addMerge: "add merge",
          file: "File",
          selectFile: "Select file",
          alreadyExistBaseWithFile:
            "A base with the selected file already exists",
          columns: "Columns",
          columnsMergePreviousBase: "Columns merge - Previous base",
          chooseColumns: "Choose columns...",
          columnsMergeNewBase: "Columns merge - New base",
          removeDuplicates: "Remove duplicates:",
          keepFirstOcurrence: "Keep first occurrence",
          keepLastOcurrence: "Keep last occurrence",
          doNotDelete: "Do not delete",
          notRecommended:
            "(Not recommended) Not deleting duplicates can cause duplicated values.",
        },
        newBaseFile: {
          columnBeingUsed:
            "The deleted column is being used in a merge, reconfigure error bases to proceed",
          file: "File",
          chooseFile: "Choose file...",
          columns: "Columns",
          chooseColumns: "Choose columns...",
          removeBase: "Remove base",
          confirmRemoveBase:
            "Are you sure you want to remove the base? This action will also remove the following bases, and you'll need to reconfigure step 2 if any of them were being used.",
          editBaseFile: "Edit base file",
          confirmEditFiles:
            "Are you sure you want to edit the chosen file for the base? This action will reset step 2 and needs to be reconfigured.",
          editBaseColumns: "Edit base columns",
          confirmEditColumns:
            "Are you sure you want to edit the chosen columns? This action will reset step 2 and needs to be reconfigured.",
        },
        step1: {
          grouperName: "Group Name",
          grouperNameAlreadyExists: "A group with this name already exists",
          nameRules: "The name must be between 3 and 60 characters",
        },
      },
      step2: {
        customColumnInput: {
          nameColumnEmpty: "Column name cannot be empty",
          columnsNameAlreadyExists: "A column with this name already exists",
        },
        modifyColumnsModal: {
          renameColumns: "Rename Columns",
          resetColumns: "Reset Columns",
          columnsOf: "Columns of",
          cancel: "Cancel",
          accept: "Accept",
        },
        step2: {
          columnToJoinResults: "Column to join with results",
          file: "File",
          chooseFile: "Choose file...",
          column: "Column",
          chooseColumn: "Choose column...",
          columnsToUse: "Columns to use",
          chooseColumns: "Choose columns...",
          renameColumns: "Rename Columns",
          preview: "Preview",
          resetColumnTitle: "Reset columns",
          resetColumnMessage: "reset columns",
        },
      },
      baseModal: {
        cancel: "Cancel",
        accept: "Accept",
      },
      configGroup: {
        newGroup: "New group",
        previous: "Previous",
        nonPossibleContinueTwoDataBases:
          "Cannot continue as there are two bases with the same file",
        finish: "Finish",
        next: "Next",
        editGroup: "Edit group",
        createGroup: "Create group",
      },
      groupAdmin: {
        groupsAdmin: "Group administration",
        searchByName: "Search by name",
        nonGroupsYet: "No groups available yet.",
        removeGroup: "remove the group",
        confirm: "Confirm",
      },
      groupCard: {
        usedColumns: "Used columns:",
      },
      viewGrouperInfo: {
        name: "Name:",
        base: "Base:",
        columns: "Columns:",
        column: "Column:",
        generalInfoGroup: "General group information",
        mergeColumnsPreviousBase: "Merge columns - previous base:",
        mergeColumnsCurrentBase: "Merge columns - current base:",
        removeDuplicates: "Remove duplicates:",
        finalColumns: "Final columns",
        usedColumns: "Used columns:",
      },
    },
    measureManagement: {
      configMeasure: {
        configCompounds: {
          operatorsCalculator: {
            operators: "Operators",
          },
        },
        configCompoundsMeasure: {
          parenthesesMatches: "Make sure all parentheses are properly matched",
          formulaFinishInOperator:
            "Ensure the formula does not end with an operator",
          measureName: "Measure Name",
          measureNameAlreadyExists: "A measure with that name already exists",
          formula: "Formula",
          enterFormula: "Enter the formula",
          measureExpenses: "Expense Measures",
          measureSales: "Sales Measures",
          measureCompounds: "Compound Measures",
          cancel: "Cancel",
          accept: "Accept",
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
        },
        configExpensesMeasure: {
          newMeasureName: "New Measure Name",
          cancel: "Cancel",
          accept: "Accept",
          searchByBucketGrouper: "Search by Bucket or Grouper",
          editMeasure: "Edit measure",
          newMeasure: "New measure",
        },
        configSalesMeasure: {
          tabs: {
            sales: "SALES",
            otherBase: "OTHER SOURCE",
          },
        },
        groupByRowSelects: {
          chooseColumn: "Choose column...",
          chooseFunction: "Choose function...",
        },
        otherFontStep1: {
          file: "File",
          selectFile: "Select file",
          mergeColumnsCurrentBase: "Merge columns - Current Base",
          chooseColumns: "Choose columns",
          mergeColumnsSalesBase: "Merge columns - SALES Base",
          numberColumnsSelectedEqualSalesBase:
            "The number of selected columns from the current base must be equal to the SALES base",
          columnsToAddFunctionAggregation:
            "Columns to add and aggregation function",
          avoidDuplicatingValues:
            "To avoid duplicating values, the added information will be grouped by using the chosen merge columns",
          reset: "Reset",
          resetData: "Reset data",
          deleteAllData: "Delete all data",
        },
        otherMeasureFont: {
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
          previous: "Previous",
          finish: "Finish",
          next: "Next",
        },
        salesMeasure: {
          loading: "Loading...",
          measureName: "Measure Name",
          newMeasureName: "New Measure Name",
          measureNameAlreadyExists: "A measure with that name already exists",
          operation: "Operation",
          chooseOperation: "Choose operation...",
          numerators: "Numerators",
          chooseColumns: "Choose columns...",
          chooseColumn: "Choose column...",
          denominators: "Denominators",
          columnsAddInfo:
            "The columns added in each input will be automatically summed.",
          columns: "Columns",
          column: "Column",
          modifyMeasure: "modify the measure",
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
          cancel: "Cancel",
          accept: "Accept",
        },
      },
      measureAdmin: {
        tabs: {
          expenses: "EXPENSES",
          sales: "SALES",
          compounds: "COMPOUNDS",
        },
        cannotDeleteMeasureAsBeingUsed:
          "This measure cannot be deleted as it is being used by a compound measure.",
        searchingMeasures: "Searching measures...",
        loadingMeasures: "Loading measure...",
        measureAdmin: "Measure administration",
        searchByName: "Search by name",
        notMeasuresYet: "No measures available yet.",
        removeMeasure: "remove the measure",
        confirm: "Confirm",
      },
      viewInfoCompoundsModal: {
        generalInfoMeasure: "General measure information",
        formula: "Formula",
      },
      viewInfoSalesModal: {
        file: "File",
        currentBaseColumns: "Current base columns:",
        baseColumnsSales: "Sales base columns:",
        operation: "Operation:",
        columns: "Columns:",
        denominatorColumns: "Denominator columns:",
        groupers: "Groupers",
      },
    },
  },
  dialog: {
    cofirmThat: "Confirm that you want to ",
    cancel: "Cancel",
    accept: "Accept",
    confirmTitle: "Confirm",
    filter: "Filter",
  },
  errorTexts: {
    pageNotExist: "This page does not exist",
    backToHome: "Back to home",
  },
  expensesSettings: {
    sendTransformations: {
      loading: "Saving transformations...",
      codes: {
        200: "Transformations have been successfully applied.",
        201: "Transformations have been successfully applied.",
        400: "An error occurred and transformations could not be applied.",
        500: "An error occurred and transformations could not be applied.",
      },
    },
    getExpenses: {
      error: "An error occurred and expenses could not be retrieved.",
    },
    getView: {
      error: "An error occurred and the view could not be retrieved.",
    },
    saveView: {
      loading: "Saving view...",
      codes: {
        200: "View has been successfully saved.",
        201: "View has been successfully saved.",
        400: "An error occurred and the view could not be saved.",
      },
    },
    expensesSettingsTexts: {
      expensesConfig: "Expenses Configuration",
      applyingTrans: "Applying transformations...",
      savingView: "Saving view...",
      filteredExpense: "Filtered Expense",
      totalExpense: "Total Expense",
      noRowsSelected: "No rows selected",
      modify: "Modify",
      errorDownload:
        "An error occurred and the information could not be downloaded.",
      unaddedTransf:
        "There are unadded transformations. Do you confirm that you want to apply transformations anyway?",
      wantToApply:
        "Do you confirm that you want to apply these transformations?",
    },
    modalContent: {
      valueExists: "*Value already exists",
      emptyFieldsCannotAdded: "*Empty fields cannot be added",
      chooseColumn: "Choose column...",
      add: "Add",
      chooseAddValue: "Choose or add a value...",
      newValue: "New value",
      deleteRow: "Delete row",
      noTransToApply: "No transformations to apply.",
      changeWillAffect: "This change will affect ",
      rows: "rows",
      cancel: "Cancel",
      accept: "Accept",
    },
  },
  forms: {
    text: "Text...",
    select: {
      loading: "Laoding...",
      noOptionsAvailable: "No options available",
    },
  },
  general: {
    emptyInputs: "All fields must be completed.",
    all: "All",
    seeMore: "see more",
    searchByName: "Search by name",
    cancel: "Cancel",
    accept: "Accept",
  },
  generatedDownloads: {
    downloads: "Downloads",
  },
  header: {
    settings: "Settings",
  },
  highCharts: {
    analysis: "Analysis",
    loading: "Loading...",
    filterLoading: "Filtering the anaylsis...",
    chooseAnalysis: "Choose analysis...",
    filters: "Filters",
  },
  icons: {
    delete: "Delete row",
    deleteAll: "Delete all",
  },
  indicators: {
    getGroups: {
      error: "An error occurred and indicator groups could not be retrieved.",
    },
  },
  indicatorsManagement: {
    addGroup: {
      error: "An error occurred and groups could not be retrieved.",
      codes: {
        200: "The group has been successfully added.",
        201: "The group has been successfully added.",
        400: "An error occurred and the group could not be added.",
      },
    },
    addIndicator: {
      codes: {
        201: "The indicator has been successfully created.",
        400: "An error occurred and the indicator could not be created.",
      },
    },
    editIndicator: {
      codes: {
        200: "The indicator has been successfully updated.",
        400: "An error occurred and the indicator could not be edited.",
      },
    },
    deleteIndicator: {
      codes: {
        200: "The indicator has been successfully deleted.",
        400: "An error occurred and the indicator could not be deleted.",
      },
    },
    putState: {
      codes: {
        200: "The state has been successfully modified.",
        201: "The state has been successfully modified.",
        400: "An error occurred and the state could not be modified.",
      },
    },
  },
  indicatorsManagementTexts: {
    addGroupModal: {
      groupNameAlreadyExists: "A group with this name already exists.",
      fieldCannotEmpty: "The field cannot be empty.",
      newGroup: "New group",
      cancel: "Cancel",
      accept: "Accept",
      name: "Name",
      newGroupName: "New group name",
    },
    chartCard: {
      modifyingChartState: "Modifying chart state...",
      baseFile: "Base file",
      axisY: "Y-axis",
      of: "of",
      graphicType: "Chart type",
    },
    configCharts: {
      none: "None",
      creatingGraphic: "Creating chart...",
      modifyingGraphic: "Modifying chart...",
      editGraphic: "Edit chart",
      createGraphic: "Create chart",
      editTheGraphic: "edit the chart",
      createTheGraphic: "create the chart",
      resetAllGraphSteps: "Reset all chart steps",
      cleanAll: "Clean all",
      finish: "Finish",
      next: "Next",
    },
    configIndicators: {
      exclude: "EXCLUIR",
      include: "INCLUIR",
      totalInExpense: "Total in expenses",
      totalInResult: "Total in results",
      totalTransferred: "Total transferred",
      bucketPeriodsWithError: "Bucket-periods with error",
      nameIndicatorExists: "An indicator with this name already exists.",
      creatingIndicator: "Creating indicator...",
      modifyingIndicator: "Modifying indicator...",
      editIndicator: "Edit indicator",
      createIndicator: "Create indicator",
      editTheIndicator: "edit the indicator",
      createTheIndicator: "create the indicator",
      former: "Former",
      resetAllIndicatorSteps: "Reset all indicator steps",
      cleanAll: "Clean all",
      finish: "Finish",
      next: "Next",
    },
    indicatorCard: {
      unitOfMeasurement: "Unit of measurement",
      group: "Group",
      active: "Active",
      inactive: "Inactive",
    },
    indicatorsManagement: {
      tabs: {
        indicators: "INDICATORS",
        graphics: "GRAPHS",
      },
      indicatorConfig: "Indicator Configuration",
      graphicConfig: "Graphic Configuration",
      indicatorAdmin: "Indicator Administration",
      uploadingIndicators: "Uploading indicators...",
      uploadingGráficos: "Uploading graphs...",
      deletingIndicator: "Deleting indicator...",
      deletingGraphics: "Deleting graph...",
      searchByNameIndicatorGroup: "Search by indicator name or group",
      activeIndicators: "ACTIVE INDICATORS",
      nonActiveIndicators: "No active indicators.",
      inactiveIndicators: "INACTIVE INDICATORS",
      nonInactiveIndicators: "No inactive indicators.",
      nonPossibleGetIndicators:
        "Unable to get indicators. Please try again later.",
      searchByNameGraphicCoord: "Search by graph name or coordinates",
      activeGraphics: "ACTIVE GRAPHS",
      nonActiveGraphics: "No active graphs.",
      inactiveGraphics: "INACTIVE GRAPHS",
      nonInactiveGraphics: "No inactive graphs.",
      nonPossibleGetGraphics: "Unable to get graphs. Please try again later.",
      deleteTheIndicator: "delete the indicator",
      deleteTheGraphic: "delete the graph",
      confirm: "Confirm",
    },
    step1: {
      coin: "Currency",
      percentage: "Percentage",
      units: "Units",
      creatingIndicatorGroup: "Creating indicator group...",
      description: "Description",
      indicatorName: "Indicator Name",
      unitOfMeasurement: "Unit of Measurement",
      chooseUnit: "Choose unit...",
      chooseCreateGroup: "Choose or create group...",
      parameterizationIndicator: "Indicator Parameterization",
      file: "File",
      chooseFile: "Choose file...",
      column: "Column",
      chooseColumn: "Choose column...",
      function: "Function",
      chooseFunction: "Choose function...",
      inactiveIndactor: "Inactive Indicator",
      singleSum: "Single Sum",
      uniqueCriteriaColumn: "Unique Criteria Column",
      productSum: "Product Sum",
      columnToMultiply: "Column to Multiply",
      chooseOneOrMoreOptions: "Choose one or more options...",
    },
    step1Charts: {
      aplicationOptions: "APPLICATION OPTIONS",
      periods: "periods",
      fileColumns: "FILE COLUMNS",
      creatingIndicatorGroup: "Creating indicator group...",
      graphParameterization: "Graph Parameterization",
      graphicTitle: "Graph Title",
      writeGraphicName: "Write graph name",
      graphicNameExists: "A graph with this name already exists",
      file: "File",
      chooseFile: "Choose file...",
      axisY: "Y-axis",
      chooseColumn: "Choose column...",
      baseColumn: "Base column",
      axisYFunction: "Y-axis function",
      baseColumnFunction: "Base column function",
      chooseFunction: "Choose function...",
      axisYNameOptional: "Y-axis name (optional)",
      writeAxisYName: "Write Y-axis name",
      axisX: "X-axis",
      chooseOption: "Choose an option...",
      axisXNameOptional: "X-axis name (optional)",
      writeAxisXName: "Write X-axis name",
      drillDownColumn: "Drilldown column",
      optional: "optional",
      serieColumnOptional: "Series column (optional)",
      none: "None",
      group: "Group",
      chooseCreateGroup: "Choose or create group...",
      activeGraphic: "Active Graph",
    },
    viewInfoModal: {
      columns: {
        column: "Column",
        values: "Values",
        criteria: "Criteria",
      },
      exlude: "Exclude",
      include: "Include",
      fileColumn: "File column",
      externFileColumn: "External file column",
      criteriaBaseColumn: "Base Criteria Column",
      generalInfoGraph: "General Graph Information",
      generalInfoIndicator: "General Indicator Information",
      unitOfMeasurement: "Unit of Measurement",
      graphType: "Graph Type",
      group: "Group",
      baseFile: "Base File",
      baseColumn: "Base Column",
      axisY: "Y-axis",
      axisYName: "Y-axis Name",
      appliedFunction: "Applied Function",
      validateUniqueColumn: "Validate Unique Column",
      multiplyColumn: "Multiply Columns",
      axisX: "X-axis",
      axisXName: "X-axis Name",
      drilldownColumn: "Drilldown Columns",
      serialColumns: "Series Columns",
      filtersOnFile: "Filters on File",
      filterOf_one: "Filtering of",
      filterOf_two: "with information from",
      prefilterOnFile: "Prefilters on File",
      crossoverValues: "Crossover Values of",
      with: "with",
    },
  },
  indicatorsView: {
    getIndicators: {
      error:
        "An error occurred and it was not possible to retrieve the indicators",
      loading: "Getting indicators...",
    },
    getIndicatorChart: {
      codes: {
        200: "Chart successfully retrieved",
        400: "An error occurred and it was not possible to retrieve the chart for the indicator",
      },
    },
    indicators: "Indicators",
    periods: "Periods",
    nonPossibleGetIndicators:
      "Unable to get the indicators. Please try again later.",
    noIndicatorsCreated: "No indicators have been created yet.",
  },
  language: {
    languages: "Languages",
  },
  login: {
    loginLoading: "Logging in...",
    getInto: "Log In",
    login: "Log In",
    email: "Email",
    password: "Password",
    loginError: "The username or password is incorrect.",
    loginProblem: "An error occurred while logging in. Please try again.",
    rememberAuth: "Remember authentication code",
    storeAuth:
      "Store authentication code for one week for two-step authentication",
    trust: "Trust this device",
    trustLogin:
      "Keep the session active on this device for 24 hours. Use only on non-public devices.",
  },
  menuItemsOptions: {
    dataManagement: {
      title: "Data Management",
      url: "administracion-datos",
    },
    validations: {
      title: "Validations",
      adminValidations: {
        title: "Validations Management",
        url: "administracion-validaciones",
      },
      configCodeMatching: {
        title: "Code Matching Configuration",
      },
      matchesDashboard: {
        title: "Matches Dashboard",
        url: "config/cruce-codigos",
      },
    },
    criteriaManagement: {
      title: "Criteria Management",
      url: "administracion-criterios",
    },
    preProrationParameterization: {
      title: "Pre-Proration Parameterization",
      url: "parametrizacion-preprorrateo",
    },
    configPreProratedExpenses: {
      title: "Configure Pre-Prorated Expenses",
      url: "configurar-gastos-preprorrateo",
    },
    configProRataExpenses: {
      title: "Configure Pro-Rata Expenses",
      url: "configuracion-gastos",
    },
    modelParameterization: {
      title: "Model Parameterization",
    },
    controlPanel: {
      title: "Control Panel",
      url: "tablero-control",
    },
    taskManager: {
      title: "Task Manager",
      url: "gestor-",
    },
    indicatorManagement: {
      title: "Indicator Management",
      url: "administracion-indicadores",
    },
    simulator: {
      title: "Simulator",
    },
    simulatorCollapseList: {
      simulatorAdmin: {
        title: "Simulation administration",
        url: "/administracion-simulacion",
      },
    },
    generatedDownloads: {
      title: "Downloads",
      url: "/descargas-generadas",
    },
    parameterModelCollapseList: {
      assignStages: {
        title: "Assign Stages",
        url: "/parametrizacion-modelo/asignacion-etapas",
      },
      criteriaCalculation: {
        title: "Criteria and Calculation",
        url: "/parametrizacion-modelo/calculo",
      },
      calculationReport: {
        title: "Calculation Report",
        url: "/parametrizacion-modelo/reporte",
      },
    },
    dashboardCollapseList: {
      indicators: {
        title: "Indicators",
        url: "/dashboard/indicadores",
      },
      graphics: {
        title: "Graphics",
        url: "/dashboard/graficos",
      },
    },
    dataExportModellingCollapseList: {
      measureManagement: {
        title: "Measure Management",
        url: "/data-modelling/administracion-medidas",
      },
      groupingsManagement: {
        title: "Groupings Management",
        url: "/data-modelling/administracion-agrupaciones",
      },
      analysisManagement: {
        title: "Analysis Management",
        url: "/data-modelling/administracion-analisis",
      },
      analysisExecution: {
        title: "Analysis Execution",
        url: "/data-modelling/ejecucion-analisis",
      },
      downloadReport: {
        title: "Download Report",
        url: "/data-modelling/descargar-reporte",
      },
    },
  },
  modelParameterization: {
    sendStages: {
      loading: "Saving stages...",
      codes: {
        200: "Stages were assigned successfully.",
        201: "Stages were assigned successfully.",
        400: "An error occurred and it was not possible to assign the stages.",
      },
    },
    postCalculateProrration: {
      codes: {
        200: "Calculation started successfully",
      },
    },
    postResetCalculation: {
      codes: {
        200: "Calculation reset successfully",
        400: "An error occurred and it was not possible to reset the calculation",
      },
    },
    propagateCriteria: {
      codes: {
        200: "Criteria propagated successfully",
        400: "An error occurred and it was not possible to propagate the criteria",
      },
    },
    deleteCriteria: {
      singularCodes: {
        200: "Criteria deleted successfully",
        400: "An error occurred and it was not possible to delete the criteria",
      },
      pluralCodes: {
        200: "Criteria deleted successfully",
        400: "An error occurred and it was not possible to delete the criteria",
      },
    },
    dataForDummy: {
      codes: {
        200: "Data loaded successfully",
        400: "An error occurred and it was not possible to load the data",
      },
    },
    getGrouperRows: {
      loading: "Getting information...",
      codes: {
        400: "An error occurred and it was not possible to download the rows.",
      },
    },
    getCriteriaData: {
      loading: "Getting information...",
      codes: {
        400: "An error occurred and it was not possible to download the criteria information.",
      },
    },
    getProrationResults: {
      codes: {
        200: "Results retrieved successfully.",
        400: "An error occurred and it was not possible to download the results",
        500: "An error occurred and it was not possible to download the results",
      },
      loading: "Getting results...",
    },
  },
  modelParameterizationTexts: {
    calculateBucketButton: {
      rowsWithoutAssignedCriteria:
        "All rows must have an assigned criteria to start Bucket calculation.",
      amountIsZero: "Bucket calculation cannot start if the expense is $0",
      calculated: "Calculated",
      notCalculated: "Calculate bucket",
      calculateActualBucket: "calculate the current bucket",
      calculateBucketWithNotTransferred:
        "calculate bucket with untransferred expenses",
    },
    calculations: {
      title: "Model Parameterization: Criteria Assignment and Calculation",
      periods: "Periods",
    },
    charts: {
      origin: "Origin",
      percentage: "Percentage",
      stage: "Stage",
      coin: "Currency",
      formatterTitle: "Percentage relative to the origin expense",
    },
    criteriaModelContent: {
      propagatingCriteria: "Propagating criteria...",
      selectReceiver: "Select receiver",
      selectCriteria: "Select criteria",
      apportionmentCriteria: "Proration Criteria",
      destinationCriteria: "Assign criteria differentiated by destination",
      only50rows: "Valid for up to 50 rows only",
      completeFields: "Complete all fields",
      finalRecipientOfExpense: "FINAL RECIPIENT OF EXPENSE",
      chooseReceiver: "Choose receiver...",
      confirm: "Confirm",
      cancel: "Cancel",
      confirmationMessage:
        "Confirm that you want to assign the criteria? This action will reset the calculation if it was already started.",
    },
    customModelCell: {
      errorDownload:
        "An error occurred and it was not possible to download the information.",
    },
    customToolbar: {
      confirmationWithRowsFirstPart:
        "Do you confirm you want to delete the criteria of ",
      confirmationWithRowsSecondPart:
        "rows? This action will reset the calculation.",
      confirmationsWithoutRows:
        "Do you confirm you want to delete the criteria of the row? This action will reset the calculation.",
      downloadingCSV: "Downloading CSV file...",
      deletenCriteria: "Deleting criteria",
      exportCSV: "Export to CSV",
      totalCost: "Total Expense",
      noCostDifInStageZero: "No differentiated expenses in stage 0.",
      noCostDif: "No groupers have differentiated expenses.",
      difByDestiny: "Differentiate by destination",
      noRowsSelectedWithCriterias: "No selected rows with criteria to delete.",
      deleteCriterias: "Delete criteria",
      noRowsSelected: "No rows selected.",
      difReceivers:
        "Criteria cannot be assigned to groupers with different receivers.",
      noCriteriaStage0: "Criteria cannot be assigned to stage 0.",
      assingCriteria: "Assign Criteria",
      confirm: "Confirm",
      close: "Close",
      accept: "Accept",
    },
    customTypographyTableText: {
      mount: "amount",
    },
    dummyGrouperGroup: {
      groupers: "Groupers",
      expenseColumn: "Expense Column",
      salesFileColumn: "Sales File Column",
    },
    iterationsButtons: {
      iterations: "ITERATIONS",
    },
    getGroupingColDef: {
      grouper: "Grouper",
      collapse: "Collapse",
      expand: "Expand",
    },
    calculationTabValue: {
      nonTransferredExpenses: "NON-TRANSFERRED EXPENSES",
    },
    modelTable: {
      succesEditName: "Name edited successfully",
      errorEditName: "An error occurred while editing the name",
      grouper: "grouper",
      all: "all",
      withoutCritera: "without criteria",
      editName: "Edit name",
      newName: "New name...",
      apply: "Apply",
      editingName: "Editing name...",
    },
    onChangeSelectValue: {
      stage: "stage",
      column: "column",
      doNotSend: "do not send",
    },
    useRadioGroup: {
      doNotValidate: "doNotValidate",
      doNotValidateLabel: "Do Not Validate",
      flaw: "flaw",
      flawLabel: "By Default",
      other: "other",
      otherLabel: "Other Column",
      file: "File",
      column: "Column",
      chooseColumn: "Choose column",
    },
    resetButton: {
      toReset: "Reset",
      resetOptions: "RESET OPTIONS",
      currentStageCalculation: "current stage calculation",
      resetCurrentStageCalculation: "Reset current stage calculation",
      allCalculation: "all calculation",
      resetAllCalculation: "Reset all calculation",
      calculationStageBucket: "calculation and bucket stages",
      resetCalculationStageBucket: "Reset calculation and bucket stages",
      allCalculationBucketStages: "all calculation and bucket stages",
      resetAllCalculationStages: "Reset all calculation and bucket stages",
      currentIterationCalculation: "current iteration calculation",
      resetCurrentIterationCalculation: "Reset current iteration calculation",
    },
    stagesButtons: {
      stage: "STAGE",
    },
    step2: {
      tabValueExpenses: "EXPENSES",
      tabValueExpensesNotTransferred: "NON-TRANSFERRED EXPENSES",
      calculateEverything: "calculate everything",
      calculateEverythingTitle: "Calculate Everything",
      startingCalculation: "Starting calculation...",
      resetting: "Resetting...",
      checkPreviousStageCalculationError:
        "There was an error and duplicate groupers were left. Check calculation from the previous stage.",
      assignCriteria: "Assign Criteria",
      resetCalcultatonStageBucket: "reset calculation and bucket stages",
      resetAllCalculationsStages: "reset all calculation and bucket stages",
      confirmPartOne: "Do you confirm you want to ",
      confirmPartTwo: " This action will redirect you to stage assignment",
      confirm: "Confirm",
      stillExpensesNotTransferredStage:
        "There are still non-transferred expenses in stage",
      confirmStartCalculationAnyway:
        "Do you confirm you want to start the calculation anyway",
      confirmStartCalculation: "Confirm calculation start",
      editCriteria: "Edit criteria",
      apportionment: "APPORTIONMENT",
      reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria:
        "reassign the new criteria in all groupers with the same receiver and old criteria",
      reassingCriteriaToRows: "Reassign criteria to rows",
      close: "close",
      resetCalculationCurrentIteration: "reset current iteration calculation",
      all: "all",
      calculateCurrentBucket: "calculate current bucket",
    },
    switchListSecondary: {
      onlyGroupersWithDifferentiatedExpenses:
        "Only groupers with differentiated expenses are shown.",
      recordedInGrouperMessagePartOne:
        "records in this grouper. For performance, the limit to differentiate expenses by destination is ",
      recordedInGrouperMessagePartTwo: "records",
      noGroupersWithDifferentiatedExpenses:
        "No groupers have differentiated expenses.",
    },
    totalAmount: {
      expense: "Expense",
    },
    transformData: {
      destination: "destination",
      oldReceiverCode: "old receiver code",
      totalExpense: "Total Expense",
    },
    nestedList: {
      flaw: "flaw",
      relatedGroupers: "Related Groupers:",
    },
    viewDummyModal: {
      expensesColumn: "Expense Column",
      fileSalesColumn: "File Sales Column",
      stage: "stage",
      dummyDirectTransfer: "Dummy Direct Transfer",
      criteriaInfo: "Criteria Information",
      finalRecieverExpense: "Final Receiver of Expense",
    },
    columnsTreeData: {
      getColumns: {
        grouper: "agrupador",
        amount: "monto",
        stages: "etapas",
        amountExpense: "MONTO GASTO",
      },
      getGroupingColDef: {
        bucketExpense: "BUCKET EXPENSE",
        collapse: "Collapse",
        expand: "Expand",
      },
    },
    formattedRows: {
      missingAssingStages: "Missing stage assignment",
      assignStages: "Assigned stages",
    },
    stages: {
      stages: "Stages",
      nowRowsSelected: "No rows selected",
      assignToSelected: "Assign to selected",
      stage: "stage",
    },
    stagesSettings: {
      stagesSettingTitle: "Model Parameterization: Stage Assignment",
      periods: "Periods",
    },
    step1: {
      stagesGreaterThan10: "The number of stages cannot be greater than 10",
      stagesGreaterThan0: "The number of stages must be greater than 0",
      missingToAssingStages: "Some groupers are missing stage assignments.",
      editingCauseCleared:
        "Editing the number of stages for a grouper will clear its criterion assignment if it had one.",
      assigningStages: "Assigning Stages",
    },
    treeDataCustomGroupingColumn: {
      successfullyEditName: "The name has been successfully edited",
      errorEditName: "An error occurred while editing the name",
      all: "all",
      noRows: "no rows",
      errorNotPossibleToDownload:
        "An error occurred and it was not possible to download the information",
      editName: "Edit name",
      newName: "New name...",
      apply: "Apply",
      unifiedBucketsGroupers:
        "If the new bucket name matches the name of an existing bucket, they and their groupers will be unified. This action cannot be undone.",
      editingName: "Editing name...",
    },
    customCheckbox: {
      all: "All",
      withoutCriteria: "without criteria",
      rowsWithoutCriteria: "Rows without assigned criteria",
      withoutRow: "without row",
      rowsWithoutStages: "Rows without assigned stage",
    },
    customGridTreeDataGroupingCell: {
      editName: "Edit name:",
    },
    utils: {},
    dummyTransfer: "DUMMY TRANSFER",
    validationDropdown: {
      codeValidation: "Code Validation",
      stage: "Stage",
    },
  },
  pageinProgress: {
    underDevelopment: "This page is still under development",
  },
  periods: {
    multipleSelectCheckmarks: {
      periods: "Periods",
    },
    enabledPeriodsTable: {
      year: "Year",
      enabledPeriods: "Enabled Periods",
      notEnabledPeriods: "Not Enabled Periods",
      nonExistingPeriods: "Non-existing Periods",
    },
    periodsDialogContent: {
      filterPeriodsSuccess: "Filters for periods applied successfully.",
      errorSendingPeriods: "Error sending periods",
      applyingPeriods: "Applying periods...",
      filter: "Filter",
      noPeriodsExported: "No periods exported",
      selectAll: "Select All",
      cleanAll: "Clean All",
      enabledPeriods: "Enabled Periods",
      onlyWithExpenses: "only those with expenses are shown",
      noPeriodsAvailableYet: "There are no periods available yet",
      cancel: "Cancel",
      apply: "Apply",
    },
    periodsFilter: {
      periods: "Periods",
    },
  },
  persistLogin: {
    logoutLoading: "Logging out...",
    role: "Client",
  },
  preprorration: {
    preprorrationCalculate: {
      loading: "Starting calculation...",
      codes: {
        200: "Calculation started successfully",
        201: "Calculation started successfully",
      },
    },
  },
  preprorationParameterization: {
    getPreprorationBuckets: {
      loading: "Getting buckets...",
      error:
        "An error occurred and it was not possible to download the buckets.",
    },
    getPreprorationGrouper: {
      loading: "Getting groupers...",
      error:
        "An error occurred and it was not possible to download the groupers.",
    },
    schemaInfoModal: {
      bucketExpenseUnderscore: "EXPENSE_BUCKET",
      bucketExpense: "EXPENSE BUCKET",
      grouper: "GROUPER",
      percentage: "Percentage",
      criteriaOutline: "Criteria Outline",
    },
    api: {
      getPreprorationCriterias: {
        errorReceiveCriteria: "Error receiving criteria",
      },
    },
    newSchemaContent: {
      criteria: "criterion",
      percentage: "Percentage",
      schemaWithNameAlreadyExists: "A schema with this name already exists",
      necessaryCompleteFields: "All fields must be completed",
      alreadyCriteriaApplied:
        "A criterion is already applied to the selected destination",
      percentageCannotLessOne: "Percentage cannot be less than 1%",
      alreadyPercentageApplied:
        "A percentage is already applied to the selected destination",
      percentageCannotExceedOneHundred:
        "The total percentage cannot exceed 100%",
      necessaryCompletFieldsForSchema:
        "All fields must be completed to create a schema",
      totalPercentageMustBeOneHundred:
        "The total percentage of criteria must be 100%",
      schemaName: "Schema Name",
      bucketExpense: "Expense Bucket",
      bucketDestination: "destination bucket",
      grouperDestination: "destination grouper",
      add: "Add",
      chooseAddValue: "Choose or add value...",
      chooseCriteria: "Choose criterion...",
      cancel: "Cancel",
      save: "Save",
      choosePercentage: "Choose percentage...",
    },
    preprorationParameterization: {
      tabs: {
        bucketExpense: "Expense Bucket",
        grouper: "Grouper",
        noExpensesToPreprorate: "There are no expenses to preprorate in",
      },
      schemaTabs: {
        selectSchema: "Select Schema",
        createSchema: "Create Schema",
      },
      criteria: "Criterion",
      successApplySchemas: "Schemas applied successfully.",
      successApplySchema: "Schema applied successfully.",
      errorApplySchema: "Error applying schema",
      successCreateSchema: "Schema created successfully",
      errorCreateSchema: "Error creating schema",
      bucketExpensesUnderscore: "EXPENSE_BUCKET",
      successReversedCalculation: "Calculation reversed successfully.",
      errorReversedCalculation: "Error reversing calculation",
      schemasToApplyMissing:
        "Calculation cannot be performed, missing schemas to apply",
      noDataToCalculation: "No data to perform calculation",
      applyingSchemas: "Applying schemas...",
      applyingSchema: "Applying schema...",
      creatingSchema: "Creating schema...",
      reversingCalculation: "Reversing calculation...",
      settingUpPreproration: "Preproration parameterization",
      periods: "Periods",
      allRowsNeedSchemas:
        "All rows must have applied schemas to start calculation",
      calculate: "Calculate",
      reverseCalculation: "Reverse calculation",
      assingSchema: "Assign schema",
      errorDownloadingInfo:
        "An error occurred and it was not possible to download the information.",
      noExpensesToPreprorate: "There are no expenses to preprorate in",
      criteriaOutline: "Criteria Outline",
      startCalculation: "start preproration calculation",
      resetCalculation: "reset preproration calculation",
      confirmCalculation: "Confirm calculation",
      confirmReset: "Confirm reset",
    },
    selectSchemaContent: {
      bucketExpense: "Expense Bucket",
      bucketExpenseUnderscore: "EXPENSE_BUCKET",
      grouper: "Grouper",
      criteria: "Criterion",
      chooseExistingSchema: "Choose an existing schema",
      selectSchema: "Select schema...",
      detail: "Detail",
      schemaWillAplly: "The schema will be applied to",
      row: "row",
      selected: "selected",
      cancel: "Cancel",
      apply: "Apply",
    },
  },
  report: {
    percentage: "Percentage",
    incorrectCalculation: "Incorrect Calculation",
    expensesTotal: "Total Expenses",
    resultsTotal: "Total Results",
    totalTransferred: "Total Transferred",
    bucketPeriodsError: "Bucket-Period Errors",
    getPercentages: {
      loading: "Getting period data...",
      error: "An error occurred while fetching period data, please try again.",
    },
    getKpis: {
      loading: "Getting KPIs...",
      error: "An error occurred while fetching KPIs, please try again.",
    },
  },
  reportTexts: {
    graphicsTab: {
      failedDownload: "Information could not be downloaded",
      downloadingIndicators: "Downloading indicators...",
      resultsIndicators: "RESULTS INDICATORS",
      period: "Period",
    },
    preprorrationErrorsTable: {
      failedDownload: "Information could not be downloaded",
      preProrrated: "PREPRORRATION",
    },
    preprorrationReportTab: {
      tabs: {
        transferredPercentage: "TRANSFERRED PERCENTAGE",
        destinationsByGroup: "DESTINATIONS BY GROUP",
      },
    },
    preprorrationSumTable: {
      failedDownload: "Information could not be downloaded",
      preProrrated: "PREPRORRATION",
    },
    transformDataForSumTable: {
      columns: {
        group: "Group",
        destination: "Destination",
        period: "Period",
        amountExpense: "Expense Amount",
        destinationType: "Destination Type",
        percentage: "Percentage",
        grouper: "Grouper",
        transferred: "Transferred",
        amountExpenseResult: "Result Expense Amount",
        amountExpenseOrigin: "Origin Expense Amount",
        relativeError: "Relative Error",
        correctCalculation: "Correct Calculation",
      },
      coin: "Currency",
    },
    prorrationDifTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationErrorTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationIterationTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationReportTab: {
      tabs: {
        percentageTransfer: "PERCENTAGE TRANSFER",
        notTransferredExpenses: "NOT TRANSFERRED EXPENSES",
        transferredPerIteraionExpenses: "TRANSFERRED EXPENSES PER ITERATION",
      },
    },
    transformDataForDifTable: {
      columns: {
        period: "Period",
        bucketExpense: "Expense Bucket",
        grouper: "Grouper",
        mount: "Amount",
        result: "Result",
        difference: "Difference",
        transferred: "Transferred",
      },
    },
    transformDataForErrorsTable: {
      destination1: "DESTINATION_1",
      destination: "DESTINATION",
      expenseMount: "Expense Amount",
      coin: "Currency",
    },
    getColumnsForErrorsTable: {
      columns: {
        bucket: "Bucket",
        destination: "Destination",
        stage: "Stage",
        period: "Period",
      },
    },
    transformDataForIterationTable: {
      columns: {
        solved: "Solved",
        expenseMount: "Expense Amount",
        coin: "Currency",
      },
    },
    getColumnsForIterationTable: {
      columns: {
        mount: "Amount",
        grouper: "Grouper",
        channel: "Channel",
        period: "Period",
        stage: "Stage",
        bucket: "Bucket",
        iteration: "Iteration",
      },
    },
    calculationReport: {
      tabs: {
        graphics: "GRAPHICS",
        approrateReport: "APPORTIONMENT REPORT",
        preProrateReport: "PREPRORATION REPORT",
      },
      calculationReport: "Calculation Report",
    },
    kpi: {
      coin: "Currency",
      units: "Units",
      totalInExpenses: "Total in Expenses",
      totalInResults: "Total in Results",
      totalTransferred: "Total Transferred",
      bucketPeriodsError: "Bucket-Period Errors",
    },
    kpiError: {
      incorrectCalculation: "Incorrect Calculation",
    },
    periodChart: {
      periods: "Periods",
      percentageExchange: "% Change",
      percentage: "Percentage",
    },
  },
  simulator: {
    simulationAdmin: {
      simulationAdmin: "Simulation Administration",
      searchByName: "Search by Name",
      noSimulationsYet: "No simulations yet available.",
    },
    simulationCard: {
      analysisToUse: "Analysis to Use:",
      measures: "Measures:",
    },
  },
  stagesSettings: {
    withoutStages: "There are groupers without assigned stages.",
    noFiles: "No files uploaded.",
  },
  tablesTools: {
    errorRows: "An error occurred and data could not be loaded.",
    noRows: "No data available.",
    transformDataSimpleTable: {
      message: "Message",
    },
  },
  tabs: {
    preproration: "PREPRORATION",
    proration: "PRORATION",
    downloads: "DOWNLOADS",
    datamodel: "DATAMODEL",
  },
  taskManager: {
    loadingCancelTask: "Cancelling task...",
    loadingDownloadFile: "Downloading...",
    error:
      "An error occurred and it was not possible to download the information.",
    errorDetail: "Error Detail",
    noDetailYet: "Aún no tenemos información detallada disponible...",
    cancelTitle: "Cancel Task",
    cancelThisTask: "Cancel this task",
    saveViewButtonTooltip:
      "Save column width and visibility configuration for this user.",
    getTasks: {
      error: "An error occurred and it was not possible to retrieve tasks",
    },
    getDownloads: {
      error: "An error occurred and it was not possible to retrieve downloads",
    },
    cancelTask: {
      codes: {
        200: "Task canceled successfully",
        201: "Task canceled successfully",
        204: "Task canceled successfully",
        400: "An error occurred and it was not possible to cancel the task",
        500: "An error occurred and it was not possible to cancel the task",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Task details retrieved",
        400: "An error occurred and it was not possible to retrieve task details",
      },
      loading: "Retrieving task details...",
    },
    states: {
      pending: "Pending",
      running: "Running",
      finishedWithErrors: "Finished with errors",
      finishedSuccessfully: "Finished successfully",
      error: "Calculation error",
      downloadError: "Download error",
      canceled: "Canceled",
    },
    actions: {
      viewDetail: "View Detail",
      notViewYet: "No detail yet",
      cancel: "Cancel",
      download: "Download",
    },
  },
  titles: {
    taskManager: "Task Manager",
  },
  validations: {
    validationsAdmin: {
      title: "Validations management",
      tabs: {
        codeMatching: "CODE MATCHING",
      },
      notCodeMatchingYet: "No code matching yet available.",
      getCodeMatching: {
        error: "An error occurred and it was not possible to retrieve codes",
        loading: "Getting codes...",
      },
      codeMatchingCard: {
        file1: "File 1:",
        file2: "File 2:",
        matchIndicator: "Indicator:",
        completeMatchIndicator: "Match Indicator:",
      },
      viewInfoModal: {
        title: "General Validation Information",
      },
      deleteValidation: {
        codes: {
          200: "Validation successfully deleted",
          400: "An error occurred, and the validation could not be deleted",
        },
        loading: "Deleting validation...",
        confirmationModal: {
          title: "Delete validation",
          message: "Do you confirm that you want to delete the validation?",
        },
      },
    },
    configValidations: {
      subtitleColumns: "Columns",
      titleIndicatorMatchSelect: "Indicator Match Select",
      matchName: "Code Matching Name",
      errorEmptyName: "The name cannot be empty",
      errorDuplicatedName: "The name already exists",
      textIndicatorMatchSelect: {
        percentaje: "% of codes from",
        in: "in",
      },
      placeholderIndicatorMatchSelect: "Select crossover indicator...",
      codes: {
        200: "Validation saved successfully.",
        201: "Validation saved successfully.",
        400: "An error occurred, and it was not possible to save the validation.",
      },
      calculate: {
        codes: {
          200: "Validation calculated successfully",
          201: "Validation calculated successfully",
          400: "An error occurred, and it was not possible to calculate the validation",
        },
      },
      loading: "Saving validation...",
      calculating: "Calculating...",
      confirmationModal: {
        title: "Save validation",
        message: "Do you confirm that you want to save the validation?",
      },
      notEqualColumnsLength:
        "The number of selected columns must be equal for both files.",
      getValidation: {
        codes: {
          200: "Validation obtained successfully",
          400: "An error occurred, and it was not possible to obtain the validation",
        },
        loading: "Obtaining validation...",
      },
    },
    matchCodeResults: {
      getValidations: {
        error:
          "An error occurred and it was not possible to retrieve validations",
      },
      getVennModalInfo: {
        codes: {
          200: "Information obtained successfully",
          400: "An error occurred, and it was not possible to obtain the information",
        },
        loading: "Obtaining information...",
      },
    },
  },
  verificationCode: {
    codes: {
      200: "The code is correct",
      400: "The code is incorrect",
      500: "There was an error and the code could not be sent, please try again",
    },
    loading: "Sending authentication code...",
    resend: {
      codes: {
        200: "The code has been successfully resent",
        400: "The code could not be resent",
        500: "There was an error and the code could not be resent, please try again",
      },
    },
  },
  virtualizedTable: {
    saveViewButton: "Save View",
    transformData: {
      appliedSchema: "APPLIED_SCHEMA",
      totalExpense: "TOTAL_EXPENSE",
    },
    toolbars: {
      columns: "Columns",
      selectColumns: "Select columns",
      findColumn: "Find column",
      columnTitle: "Column title",
      reorderColum: "Reorder column",
      columnsPanelShowAllButton: "Show all",
      columnsPanelHideAllButton: "Hide all",
      filters: "filters",
      filterPlaceHolder: "Filter values",
      addFilter: "Add filter",
      filterHide: "Hide filters",
      filterShow: "Show filters",
      filterOperator: "Operator",
      andFilter: "And",
      orFilter: "Or",
      filterInputLabel: "Value",
      density: "density",
      compact: "compact",
      standard: "standard",
      comfortable: "comfortable",
      export: "export",
      print: "Print",
      asCSV: "Download as CSV",
    },
  },
  unauthorized: {
    title: "Unauthorized",
    message: "You do not have permission to access this page",
    goBack: "Go Back to Homepage",
  },
  useLogout: {
    errorLogout: "Error signing out",
  },
};
