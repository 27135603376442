import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack, Typography } from "@mui/material";

interface ISimpleBackdrop {
  open: boolean;
  message?: string;
}

export default function SimpleBackdrop({ open, message }: ISimpleBackdrop) {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={open}
      >
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress sx={{ color: "var(--light-blue) !important" }} />
          <Typography
            variant="body2"
            sx={{ color: "white !important", marginTop: "15px" }}
          >
            {message}
          </Typography>
        </Stack>
      </Backdrop>
    </div>
  );
}
