import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { DATA_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import SearchInput from "../../forms/SearchInput";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filterDataByResourceName } from "../../../utils/util";
import useApi from "../../../hooks/useApi";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";
import useMultiSelect from "../../../hooks/useMultiSelect";
import { truncateText } from "../../taskManager/utils";
import Filters from "../Filters";
import { Button } from "../../buttons";
import ReloadResources from "../ReloadResources";

interface ExploreFoldersProps {
  handleChangeSelectedFolder: (selectedFolder: string) => void;
  customFolder?: string
  desactivateFolder?:string
  // Esta prop la utilizamos para setear los recursos actuales de la carpeta seleccionada en el padre
  setResourcesData?: any
}

const ExploreFolders = ({
  handleChangeSelectedFolder, customFolder, desactivateFolder, setResourcesData
}: ExploreFoldersProps) => {
  const [selectedFolder, setSelectedFolder] = useState<string>(customFolder ? customFolder :"");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>();
  const [initialData, setInitialData] = useState<any[]>();


  const [
    labelsSelectedOption,
    setLabelSelectedOption,
    labelsOptionsChangeHandler,
  ] = useMultiSelect();
  const labelRef: any = useRef(null);

  const {
    data: initialDataResources,
    isLoading: isLoadingInitialDataResources,
    isError: isErrorInitialDataResources,
    refetch: refetchResources,
  } = useApiQuery(
    `${DATA_URL}?ruta=${
      customFolder ? encodeURIComponent(customFolder) : ""
    }`,
    false,
    texts.dataManagement.getResources.error
  );

  const onSuccessGetData = (data: any) => {
    setResourcesData && setResourcesData(data)
    if (
      labelsSelectedOption?.length === 0 ||
      labelsSelectedOption === undefined
    ) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((resource: any) =>
          labelsSelectedOption
            ?.map((val: any) => val.label)
            .includes(resource?.etiqueta?.nombre)
        )
      );
    }
  };

  const {
    data: dataResources,
    error: isErrorDataResources,
    isLoading: isLoadingDataResources,
    callApi: getResources,
  } = useApi(
    `${DATA_URL}?ruta=${encodeURIComponent(getRoute(location.pathname))}/`,
    "GET",
    texts.dataManagement.getResources.error,
    undefined,
    onSuccessGetData,
    undefined,
    false
  );

  /*************************** CARGA DE DATOS ***********************************/

  useEffect(() => {
    if (initialDataResources) {
      setFilteredData(initialDataResources);
      setInitialData(initialDataResources);
      setResourcesData && setResourcesData(initialDataResources)
    }
  }, [initialDataResources]);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      filterDataByResourceName(
        initialDataResources,
        e.target.value,
        setFilteredData
      );
    } else {
      setFilteredData(initialDataResources);
    }
  };

  const goBack = () => {
    // Paso 1: Remover cualquier barra al final de la carpeta seleccionada
    const cleanedFolders = selectedFolder.replace(/\/+$/, "");

    // Paso 2: Formatear las carpetas en un array
    const formattedFolders = cleanedFolders.split("/");

    // Paso 3: Remover la última carpeta
    formattedFolders.pop();

    // Paso 4: Reconstruir la carpeta seleccionada y setearla
    const newSelectedFolder =
      formattedFolders.join("/") !== "" ? formattedFolders.join("/") + "/" : "";
    setSelectedFolder(newSelectedFolder);
    handleChangeSelectedFolder(newSelectedFolder);

    // Paso 5: Filtrar los datos
    if (formattedFolders.join("/") !== "") {
      getResources(`${DATA_URL}?ruta=${encodeURIComponent(newSelectedFolder)}`);
    } else {
      // Paso 5.3: Mantener todos los datos si no hay una carpeta seleccionada
        if(!customFolder){
          setFilteredData(initialDataResources);
        }else{
           getResources(`${DATA_URL}?ruta=`);
        }
      } 
  };

  const handleClickOnFolder = (folder: any) => {
    setLabelSelectedOption([]);
    labelRef?.current?.clearValue();
    if (searchValue === "") {
      setSelectedFolder(
        selectedFolder === ""
          ? `${folder.nombre}/`
          : `${selectedFolder}${folder.nombre}/`
      );
      handleChangeSelectedFolder(
        selectedFolder === ""
          ? `${folder.nombre}/`
          : `${selectedFolder}${folder.nombre}/`
      );
      getResources(
        `${DATA_URL}?ruta=${
          selectedFolder === ""
            ? `${encodeURIComponent(folder.nombre)}/`
            : `${encodeURIComponent(selectedFolder)}${encodeURIComponent(
                folder.nombre
              )}/`
        }`
      );
    } else {
      setSelectedFolder(`${folder.ubicacion}${folder.nombre}/`);
      handleChangeSelectedFolder(`${folder.ubicacion}${folder.nombre}/`);
      getResources(
        `${DATA_URL}?ruta=${encodeURIComponent(
          folder.ubicacion
        )}${encodeURIComponent(folder.nombre)}/`
      );
    }
  };

  const handleChooseFolder = () =>{
    handleChangeSelectedFolder(selectedFolder);
    getResources(
      `${DATA_URL}?ruta=${encodeURIComponent(
        selectedFolder
      )}${selectedFolder.endsWith("/") ? "":"/"}`
    );
  }

  const handleRefetchResources = ()=> {
    getResources(
      `${DATA_URL}?ruta=${encodeURIComponent(
        selectedFolder
      )}&cache=false/`
    );
  }
  
  return (
    <>
      <Stack width="100%">
        {/*     
            <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder="Búsqueda por nombre"
            />
          </Stack>
         */}
        <Stack direction={"row"} width="100%" alignItems={"center"}>
          <TextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            fullWidth
            label=""
            value={selectedFolder}
            placeholder={`Ubicación`}
            autoComplete="off"
            onChange={(e) => setSelectedFolder(e.target.value)}
            InputLabelProps={{
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
                color: "var(--blue)",
              },
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
                margin: 0,
                backgroundColor: "var(--very-very-light-grey)",
              },
            }}
          />
          <Button
            title={"Buscar carpeta"}
            color="blue-greeny"
            type="button"
            margin={true}
             onClick={handleChooseFolder}
            disabled={selectedFolder.length < 1}
          />
        </Stack>

        <Filters
          setFilteredData={setFilteredData}
          initialData={initialData}
          filteredData={filteredData}
          valueToCleanValues={location.pathname}
          disabled={isLoadingDataResources || isLoadingInitialDataResources}
        />
        <Stack sx={{marginTop:"15px"}}>
        <ReloadResources refetchResources={handleRefetchResources} />

        </Stack>

        <Stack sx={{ width: "100%" }}>
          {selectedFolder !== "" && (
            <Stack
              sx={{
                flexDirection: "row",
                width: "100px",
                alignItems: "center",
                marginBottom: "9px",
              }}
              onClick={isLoadingDataResources ? undefined : goBack}
            >
              <ArrowBackIcon
                sx={{
                  color: isLoadingDataResources
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  "& :hover": {
                    cursor: "pointer",
                    color: isLoadingDataResources
                      ? "var(--light-grey)"
                      : "#f90d4a",
                  },
                }}
              />
              <Typography
                sx={{
                  color: isLoadingDataResources
                    ? "var(--light-grey)"
                    : "var(--blue)",
                  fontSize: "14px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginLeft: "5px",
                  "& :hover": { color: "#f90d4a" },
                }}
              >
                VOLVER
              </Typography>
            </Stack>
          )}
          {(isLoadingInitialDataResources || isLoadingDataResources) && (
            <Stack
              sx={{
                width: "100%",
                height: "150px",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          )}
          {!isLoadingInitialDataResources &&
            !isErrorInitialDataResources &&
            !isLoadingDataResources && (
              <>
                <Stack width={"100%"} direction={"row"}>
                  <Typography
                    sx={{
                      color: "var(--blue)",
                      fontSize: "16px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      marginBottom: "5px",
                    }}
                  >
                    Carpetas
                  </Typography>
                </Stack>

                <Stack
                  width={"100%"}
                  direction={"row"}
                  gap={3}
                  flexWrap="wrap"
                  marginBottom="15px"
                >
                  {filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.filter((f: any) => {
                    return f.tipo === "carpeta";
                  }).length > 0 ? (
                    filteredData
                      .filter((resource: any) => {
                        return resource.tipo === "carpeta";
                      })
                      .map((folder: any, index: number) => {
                        return (
                          `${folder.ubicacion}${folder.nombre}` !== desactivateFolder && folder.nombre !== "PHI"? <Stack
                            key={index}
                            sx={{
                              backgroundColor: "var(--very-very-light-grey)",
                              borderRadius: "10px",
                              cursor: "pointer",
                              padding: "5px",
                              width: "125px",
                              position: "relative",
                              "&:hover": {
                                backgroundColor: "var(--very-light-blue)",
                              },
                            }}
                          >
                            {folder?.etiqueta?.nombre !== undefined && (
                              <Tooltip title={folder?.etiqueta?.nombre}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "white",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                    backgroundColor: folder?.etiqueta?.color,
                                    borderRadius: "5px",
                                    padding: "0px 2px",
                                  }}
                                >
                                  {truncateText(folder?.etiqueta?.nombre, 16)}
                                </Typography>
                              </Tooltip>
                            )}
                            <Stack
                              sx={{
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleClickOnFolder(folder);
                              }}
                            >
                              <FolderIcon
                                style={{
                                  fontSize: 30,
                                  color: folder.etiqueta?.color
                                    ? folder.etiqueta?.color
                                    : "var(--dark-blue)",
                                }}
                              />

                              <Typography
                                sx={{
                                  fontSize:
                                    folder.nombre.length < 20 ? "14px" : "12px",
                                  color: "var(--blue)",
                                  wordBreak: "break-all",
                                }}
                              >
                                {folder.nombre}
                              </Typography>
                            </Stack>
                          </Stack> : ""
                        );
                      })
                  ) : (
                    <Box
                      sx={{
                        width: "95%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "var(--dark-grey)",
                          fontSize: "16px",
                        }}
                      >
                        No hay carpetas disponibles.
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default ExploreFolders;
