import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Checkbox, ListItem } from "@mui/material";

interface DatabricksCatalogOptionProps {
  item: any;
  type: "catalog" | "schema";
  selectedTable: string;
  setSelectedTable: (table: string,catalog: string, schema:string) => void;
  catalogName: string
}

const DatabricksCatalogOption = ({
  item,
  type,
  selectedTable,
  setSelectedTable,
  catalogName
}: DatabricksCatalogOptionProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ padding: "3px !important" }}>
        <ListItemIcon
          sx={{
            color: "var(--blue)",
            fontsize: "40px !important",
            ml: type === "schema" ? "10px" : undefined,
          }}
        >
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={item.nombre} sx={{ fontsize: "20px" }} />
        {type === "catalog" &&
          item?.esquemas?.length > 0 &&
          (open ? <ExpandLess /> : <ExpandMore />)}
        {type === "schema" &&
          item?.tablas?.length > 0 &&
          (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {type === "catalog" ? (
        <>
          {item?.esquemas?.length > 0 &&
            item?.esquemas.map((schema: any) => (
              <Collapse
                in={open}
                timeout={10}
                unmountOnExit
                key={schema.nombre}
              >
                <List component="div" disablePadding>
                  <DatabricksCatalogOption
                    key={schema.nombre}
                    type="schema"
                    catalogName={item.nombre}
                    item={schema}
                    setSelectedTable={setSelectedTable}
                    selectedTable={selectedTable}
                  />
                </List>
              </Collapse>
            ))}
        </>
      ) : (
        <Collapse in={open} timeout={10} unmountOnExit>
          <List component="div" disablePadding>
            {item?.tablas?.length > 0 &&
              item?.tablas.map((table: string) => (
                <ListItem key={table} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => setSelectedTable(table, catalogName ,item.nombre)}
                    dense
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      {table === selectedTable ? (
                        <RadioButtonCheckedIcon
                          sx={{
                            fontSize: "22px",
                            color: "var(--blue)",
                            borderRadius: "50%",
                            backgroundColor: "white",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          sx={{
                            fontSize: "22px",
                            color: "var(--blue)",
                            borderRadius: "50%",
                            backgroundColor: "white",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id={table}
                      primary={table}
                      sx={{ fontsize: "20px" }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default DatabricksCatalogOption;
