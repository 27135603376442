import { useContext, useEffect, useState } from "react";
import BaseModal from "./BaseModal";
import useApi from "../../../hooks/useApi";
import { CHANGE_FLOW_FONTS_URL, GET_FLOW_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import FlowsProvider from "../../../context/FlowsContext";
import ReplaceFont from "../../flowExecution/ReplaceFont";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import { Typography } from "@mui/material";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { formatFonts } from "../utils";
interface ChangeFontsProps {
  open: boolean;
  handleClose: () => void;
  postItem: (url: string, body: any) => void;
}

const ChangeFonts = ({ open, handleClose, postItem }: ChangeFontsProps) => {
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow } = flowState;
  const [fonts, setFonts] = useState<any[]>([]);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  const onSuccessGetFlow = (data: any) => {
     setFonts(formatFonts(data));
  } 

  const {
    isLoading: isLoadingFlow,
    data: dataFlow,
    callApi: getDataFlow,
  } = useApi(
    GET_FLOW_URL(data_flow.id),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  /*useEffect(() => {
    if (
      dataFlow?.steppers?.filter((stepper: any) => {
        return stepper?.items[0]?.tipo === "nueva_fuente";
      }).length !==
      data_flow?.steppers?.filter((stepper: any) => {
        return stepper?.items[0]?.tipo === "nueva_fuente";
      }).length
    ) {
      getDataFlow();
    }
  });*/

  useEffect(() => {
    if(open){
      getDataFlow();
    }
  }, [open]);

  const handleChangeFontDataFile = (fontName: string, dataFont: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, dataFile: dataFont, error: false };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeFontInput = (fontName: string, archivo: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);
    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, archivo: archivo };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeSheet = (
    sheet: string | number | undefined,
    fontName: string
  ) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, hoja: sheet };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  /******************************* POSTEAR ITEM *********************************************/

  
  const handleConfirmChangeFonts = () => {
    toggleConfirmationModal();
    postItem(
      CHANGE_FLOW_FONTS_URL(data_flow.id),
      fonts.map((font) => {
        return {
          stepper: font.idStepper,
          hoja: font.hoja ? font.hoja : undefined,
        };
      })
    );
    handleClose();
  };

  const isFormCompleted = () => {
    return (
     fonts.some((font: any) => {
        return (
          font.dataFile === undefined
        );
      }) || fonts.some((font: any) => {
        return (
          font.dataFile !== "original" && font.hoja === undefined);
      }) || fonts.some((font: any) => {
        return (
           font.error);
      })
    );
  }

  return (
    <BaseModal
      open={open}
      title="Cambiar fuentes"
      cleanAndClose={handleClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleConfirmChangeFonts}
      confirmationModalMessage={"cambiar las fuentes"}
      disabledAcceptButton={isFormCompleted()}
      height="430px"
      width="900px"
    >
       <SimpleBackdrop
        open={isLoadingFlow}
        message={texts.flows.changeFonts.loadingFonts}
      />
      <Stack sx={{ width: "100%", height: "430px", overflowY: "scroll" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "700", color: "var(--blue)" }}
        >
          Por defecto, se muestran las fuentes anteriores. Si desea cambiarlas,
          ingrese las nuevas.
        </Typography>
        {dataFlow &&
          data_flow.id && !isLoadingFlow &&
          formatFonts(dataFlow).map((font: any) => {            
            return (
              <ReplaceFont
                key={font.nombre}
                label={font.nombre}
                setFont={handleChangeFontInput}
                error={font.error}
                idStepper={font.idStepper}
                setSheet={handleChangeSheet}
                setDataFont={handleChangeFontDataFile}
              />
            );
          })}
      </Stack>
    </BaseModal>
  );
};

export default ChangeFonts;
