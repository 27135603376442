import { makeStyles } from "@material-ui/core/styles";
import { FileType } from "./types";
/* eslint-disable no-useless-escape */

export const useStyles = makeStyles({
  icon: {
    color: "const(--blue)",
    marginRight: "0.5rem",
    animation: "$colorChange 2s linear infinite",
  },
  "@keyframes colorChange": {
    "0%": {
      color: "const(--blue)",
    },
    "25%": {
      color: "const(--light-blue)",
    },
    "50%": {
      color: "const(--light-grey)",
    },
    "75%": {
      color: "const(--light-blue)",
    },
    "100%": {
      color: "const(--blue)",
    },
  },
});

/**
 * Comprueba si un archivo está en una lista de archivos verificando coincidencias
 * en el periodo_id y el nombre del archivo.
 *
 * @param {Object} file - El archivo que se desea verificar.
 * @param {Array} list - La lista de archivos en la que se realizará la comprobación.
 * @returns {boolean} True si el archivo está en la lista; de lo contrario, false.
 */

export const checkFileInList = (file: any, list: any) => {
  if (!file || !list) return false;
  const totalNamesList: any[] = [];
  list.map((p: any) => {
    p.archivos.map((f: any) => totalNamesList.push(`${f.nombre}-${p.periodo}`));
  });
  const fileInList = totalNamesList.includes(
    file?.name.split(".")[0].toUpperCase()
  );
  return fileInList;
};

/**
 * Comprueba si un archivo está en una lista de archivos hasta un índice específico
 * y verifica si el nombre del archivo coincide con los archivos anteriores en la lista.
 *
 * @param {Object} file - El archivo que se desea verificar.
 * @param {number} index - El índice hasta el cual se debe buscar en la lista de archivos.
 * @param {Array} list - La lista de archivos en la que se realizará la comprobación.
 * @param {boolean} repeatedFiles - Indica si se han encontrado archivos repetidos.
 * @param {function(boolean): void} setRepeatedFiles - Una función para establecer el estado de archivos repetidos.
 * @returns {boolean} True si el archivo está en la lista y tiene el mismo nombre que un archivo anterior; de lo contrario, false.
 */

export const checkFileInUploadList = (
  file: File | null,
  index: number,
  list: Array<File>,
  repeatedFiles?: boolean,
  setRepeatedFiles?: (value: boolean) => void
) => {
  if (!file || !list) {
    return false;
  }
  const fileName = file.name.split(".")[0];
  const fileInList =
    list.slice(0, index).length > 0 &&
    list.slice(0, index).find((f: any) => {
      const fName = f.name.split(".")[0];
      const isSameName = fName === fileName;
      return isSameName;
    }) !== undefined;
  if (fileInList && !repeatedFiles) {
    setRepeatedFiles && setRepeatedFiles(true);
  }
  return fileInList;
};

/**
 * Comprueba si hay archivos duplicados en la lista de archivos para cargar.
 *
 * @param {Array<File> | null} filesToUpload - La lista de archivos para verificar.
 * @returns {boolean} True si se encuentran archivos duplicados en la lista; de lo contrario, false.
 */

export const checkDuplicatedFiles = (
  filesToUpload: Array<File> | null
): boolean => {
  if (filesToUpload) {
    filesToUpload.forEach((fileToUpload: any) => {
      const repeatedFile = filesToUpload.find((f: any) => {
        f.name.split(".")[0] === fileToUpload.name.split(".")[0];
      });
      if (repeatedFile) return true;
    });
    return false;
  } else {
    return false;
  }
};

/**
 * Obtiene el nombre del archivo (sin extensión) a partir de una cadena que contiene el nombre de archivo completo.
 *
 * @param {string} str - La cadena que contiene el nombre de archivo completo.
 * @returns {string} El nombre del archivo (sin extensión).
 */

export const getFileName = (str: string) => {
  let fileName = "";

  if (str.endsWith(".csv")) {
    // Verifica si la cadena termina con ".csv" y ajusta el nombre en consecuencia.
    fileName = str.slice(0, -12);
  } else if (str.endsWith(".parquet")) {
    // Verifica si la cadena termina con ".parquet" y ajusta el nombre en consecuencia.
    fileName = str.slice(0, -16);
  }
  return fileName;
};

/**
 * Comprueba si la fecha en el nombre de archivo cumple con un formato válido.
 *
 * @param {string} str - La cadena que contiene el nombre de archivo con la fecha.
 * @returns {boolean} True si la fecha en el nombre de archivo tiene un formato válido; de lo contrario, false.
 */

export const isDateFormatCorrect = (str: string) => {
  let fileEnd = "";

  // Verifica si la cadena termina con ".csv" y ajusta la extracción de la fecha en consecuencia.
  if (str.endsWith(".csv")) {
    fileEnd = str.slice(-11);
    // Verifica si la cadena termina con ".parquet" y ajusta la extracción de la fecha en consecuencia.
  } else if (str.endsWith(".parquet")) {
    fileEnd = str.slice(-15);
  }
  // Divide la parte de la fecha del nombre de archivo y verifica si sigue un formato válido.
  const date = fileEnd.split(".")[0];
  const validFormatRegExp = new RegExp(/^(0[1-9]|1[0-2])\-?([0-9]{4})$/gm);
  return validFormatRegExp.test(date);
};

/**
 * Verifica si los archivos que se van a cargar son permitidos y tienen nombres y formatos de fecha válidos.
 *
 * @param {Array<File> | null} filesToUpload - La lista de archivos que se van a cargar.
 * @param {Array<FileType> | undefined} allowedFileNames - La lista de nombres de archivos permitidos.
 * @param {function(boolean): void} setNotAllowedFiles - Una función para establecer el estado de archivos no permitidos.
 */

export const checkNotAllowedFiles = (
  filesToUpload: Array<File> | null,
  allowedFileNames: Array<FileType> | undefined,
  setNotAllowedFiles: (value: boolean) => void
) => {
  const allowedFileNamesList = allowedFileNames?.map(
    (file: FileType) => file.nombre
  );
  if (filesToUpload) {
    const notAllowedFiles = filesToUpload.filter(
      (file: any) =>
        !allowedFileNamesList?.includes(getFileName(file.path)) ||
        !isDateFormatCorrect(file.path)
    );
    if (notAllowedFiles.length > 0) {
      setNotAllowedFiles(true);
    } else {
      setNotAllowedFiles(false);
    }
  }
};

/**
 * Verifica si hay archivos repetidos en la lista de archivos para cargar.
 *
 * @param {any} filesToUpload - La lista de archivos que se van a cargar.
 * @param {Array<FileType> | undefined} allowedFileNames - La lista de nombres de archivos permitidos.
 * @param {boolean} repeatedFiles - Indica si ya se han encontrado archivos repetidos.
 * @param {function(boolean): void} setRepeatedFiles - Una función para establecer el estado de archivos repetidos.
 * @param {any} dataRowsFiles - Los datos de archivos ya cargados.
 * @returns {boolean} True si se encuentran archivos repetidos en la lista; de lo contrario, false.
 */

export const hasRepeatedFiles = (filesToUpload: any) => {
  const hasRepeated = filesToUpload?.some((file: any) => {
    const fileName = getFileName(file.path);

    // Verifica si el archivo actual está duplicado en la lista de archivos que se están cargando.
    const condition2 = checkFileInUploadList(
      file,
      filesToUpload.indexOf(file) + 1,
      filesToUpload
    );

    return condition2;
  });

  return hasRepeated;
};

export const getRoute = (url: string) => {
  const formattedUrl = url.endsWith("/") ? url : url + "/";
  // Use a regular expression to find all occurrences of "/carpeta/<text>"
  const regex = /\/carpeta\/([^\/]+)/g;

  // Use the match method to get all the matches
  const matches = formattedUrl.match(regex);

  // If no matches are found, return an empty string
  if (!matches) {
    return "";
  }

  // Map the matches to get only the text after "/carpeta/"
  const routes = matches.map(function (match) {
    return match.split("/").pop();
  });

  // Join all the routes with "/"
  const result = routes.join("/");

  return result;
};

export const getRouteWithFolder = (url: string) => {
  const formattedUrl = url.endsWith("/") ? url : url + "/";

  const folders = formattedUrl.split("/").slice(0, formattedUrl.split("/").length -1);

  // Join all the routes with "/"
  const result = folders.join("/carpeta/");
  return result;
};

/**
 * Función para cortar una URL eliminando el prefijo especificado.
 * @param {string} url - La URL que se va a cortar.
 * @returns {string} La URL sin el prefijo especificado, o la URL original si no comienza con el prefijo.
 */
export const cutUrl = (url:string) => {
  // Verifica si la URL comienza con el prefijo esperado
  const prefijo = "https://datalakedeprueba1.blob.core.windows.net/alpha/";
  if (url?.startsWith(prefijo)) {
    // Corta el prefijo y devuelve la parte restante de la URL
    return url?.slice(prefijo.length);
  } else {
    // Si la URL no comienza con el prefijo esperado, devuelve la URL original
    return url;
  }
}