import { Stack, Tooltip, Typography } from "@mui/material";
import { convertArrayToText } from "../../../taskManager/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../../../dialog";
import useDialog from "../../../../hooks/useDialog";

interface ConfigProps {
  config: any;
  handleDelete: (config: any) => void;
  isSchemeConfig?:boolean
}

const Config = ({ config, handleDelete, isSchemeConfig }: ConfigProps) => {
    const [openDeleteModal, toggleDeleteModal] = useDialog();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        width: "100%",
        backgroundColor: "var(--very-very-light-grey)",
        padding: "3px 3px",
        borderRadius: "10px",
        boxShadow: "6px 13px 20px -10px rgba(0,0,0,01)",
        transition: "all 0.4s",
        margin: "8px 0px 15px 0px !important",
      }}
    >
      <Stack sx={{ flexDirection: "row", width: "100%", alignItems:"center", justifyContent:"space-between" }}>
        <Typography
          sx={{
            color: "white",
            backgroundColor: "var(--blue)",
            fontSize: "18px",
            textTransform: "uppercase",
            marginBottom: "3px",
            fontWeight: "bold",
            borderRadius: "5px",
            width: "fit-content",
            marginLeft: "5px",
            padding: "0px 8px",
          }}
        >
          {config.nombre}
        </Typography>
        <Tooltip title={"Eliminar configuración"}>
          <DeleteIcon
            onClick={() => toggleDeleteModal()}
            sx={{
              fontSize: "32px",
              color: "var(--blue)",
              cursor: "pointer",
              marginBottom: "5px",
              marginRight:"3px"
            }}
          />
        </Tooltip>
      </Stack>
{isSchemeConfig ? <Stack
        sx={{
          justifyContent: "center",
          width: "100%",
          padding: "5px 15px",
        }}
      >
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          {`Ubicación de los archivos a calcular: ${config. ubicacion_archivos}`}
        </Typography>

        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          {`Formato del archivo generado: ${config.formato_archivo}`}
        </Typography>

        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          {`Ubicación del archivo final: ${config.ubicacion_destino === "" ? "Carpeta raíz" : config.ubicacion_destino}`}
        </Typography>
      </Stack> : <Stack
        sx={{
          justifyContent: "center",
          width: "100%",
          padding: "5px 15px",
        }}
      >
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          {`Nombre del archivo: ${config.nombre_archivo}`}
        </Typography>

        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "5px",
            fontWeight: "bold",
          }}
        >
          {`Ubicación: ${config.ubicacion_archivo}`}
        </Typography>
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "14px",
            textTransform: "uppercase",
            marginBottom: "10px",
            fontWeight: "bold",
          }}
        >
          {`Steppers: ${`${convertArrayToText(
            config.steppers.map((config: any) => {
              return config.label;
            })
          )}`}`}
        </Typography>
        {config.desconsolidar && (
          <>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {`Stepper a desconsolidar: ${config.stepper_desconsolidar.label}`}
            </Typography>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              {`Nombre de los archivos (desconsolidados): ${config.nombre_archivo_desconsolidado}`}
            </Typography>
            <Typography
              sx={{
                color: "var(--blue)",
                fontSize: "14px",
                textTransform: "uppercase",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              {`Columna para desconsolidar: ${config.columna_desconsolidar}`}
            </Typography>
          </>
        )}
      </Stack>}
      
      <ConfirmationModal
        open={openDeleteModal}
        handleClose={toggleDeleteModal}
        handleAccept={() => {
            handleDelete(config.id)
            toggleDeleteModal()
        }}
        title="Eliminar configuración"
        message={"eliminar la configuración"}
      />
    </Stack>
  );
};

export default Config;
