import { InputLabel, Stack, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import { form_label } from "../../../styles/app-styles";
import {
  RESOURCE_LABEL_BY_ID_URL,
  RESOURCE_LABEL_URL,
} from "../../../api/axios";
import { texts } from "../../../texts";
import useSingleSelect from "../../../hooks/useSingleSelect";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import { isNameRepeated } from "../../pipelines/utils";
import Option from "./CustomOptionSelect";
import useApi from "../../../hooks/useApi";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { Label } from "./types";

interface CreateLabelModalProps {
  open: boolean;
  handleClose: () => void;
  dataLabels: any[];
  onSuccessSendLabel: () => void;
  setIsLoadingPostLabel?: (isLoading: boolean) => void;
  isEdit?: boolean;
  labelToEdit?: Label;
}

const CreateLabelModal = ({
  open,
  handleClose,
  dataLabels,
  onSuccessSendLabel,
  isEdit,
  labelToEdit,
  setIsLoadingPostLabel
}: CreateLabelModalProps) => {
  const [labelName, setLabelName] = useState<string>("");
  const [delayHelper, setDelayHelper] = useState<boolean>(false);

  const labelColorRef: any = useRef(null);
  const [
    labelColorSelectedOption,
    setLabelColorSelectedOption,
    labelColorOptionsChangeHandler,
  ] = useSingleSelect();

  const colorSelectOptions = [
    { color: "#F26B9C", value: "#F26B9C", label: "Rosa apagado" },
    { color: "#24A68E", value: "#24A68E", label: "Turquesa" },
    { color: "#BF0637", value: "#BF0637", label: "Rojo oscuro" },
    { color: "#147588", value: "#147588", label: "Azul profundo" },
    { color: "#4D4E6A", value: "#4D4E6A", label: "Gris azulado" },
    { color: "#166C62", value: "#166C62", label: "Verde esmeralda" },
    { color: "#82D6EE", value: "#82D6EE", label: "Celeste claro" },
    { color: "#272731", value: "#272731", label: "Gris carbón" },
    { color: "#083844", value: "#083844", label: "Azul petróleo" },
  ];

  /********************************* CARGA DE DATOS ************************************************/

  useEffect(() => {
    if (open) {
      if (labelToEdit) {
        setLabelName(labelToEdit?.nombre);
        setLabelColorSelectedOption({
          value: labelToEdit?.color,
          label: colorSelectOptions.find(
            (color: any) => color.value === labelToEdit?.color
          )?.label as string,
        });
      }
      setDelayHelper(true);
    }
  }, [open]);

  /********************************* LLAMADAS ************************************************/

  const {
    data: dataPostLabel,
    error: errorPostLabel,
    isLoading: isLoadingPostLabel,
    callApi: sendLabel,
  } = useApi(
    RESOURCE_LABEL_URL,
    "POST",
    texts.dataManagement.postLabel.codes,
    {
      nombre: labelName,
      color: labelColorSelectedOption?.value,
    },
    onSuccessSendLabel,
    undefined,
    false
  );

  useEffect(() => {
    setIsLoadingPostLabel && setIsLoadingPostLabel(isLoadingPostLabel);
   }, [isLoadingPostLabel])

  const { isLoading: isLoadingPutLabel, callApi: editLabel } = useApi(
    RESOURCE_LABEL_BY_ID_URL,
    "PUT",
    texts.dataManagement.putLabel.codes,
    {
      nombre: labelName,
      color: labelColorSelectedOption?.value,
    },
    onSuccessSendLabel,
    undefined,
    false
  );

  useEffect(() => {
    setIsLoadingPostLabel && setIsLoadingPostLabel(isLoadingPutLabel);
   }, [isLoadingPutLabel]);

  /******************************* HANDLERS *******************************/

  const handleSendLabel = () => {
    if (isEdit && labelToEdit?.id) {
      editLabel(RESOURCE_LABEL_BY_ID_URL(labelToEdit?.id));
    } else {
      sendLabel();
    }
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/
  const isFormCompleted = () => {
    return (
      labelName.length < 1 ||
      (dataLabels !== undefined &&
        isNameRepeated(
          isEdit
            ? dataLabels
                .filter(
                  (label: Label) => label.nombre !== labelToEdit?.nombre
                )
                .map((label: Label) => label.nombre)
            : dataLabels.map((label: Label) => label.nombre),
          labelName
        )) ||
      labelColorSelectedOption === undefined ||
      labelColorSelectedOption === null
    );
  };

  const cleanAndClose = () => {
    handleClose();
    setLabelName("");
    setLabelColorSelectedOption(undefined);
    setDelayHelper(false);
  };

  return (
    <>

      <Dialog
        open={open}
        handleClose={cleanAndClose}
        title={isEdit ? "Editar etiqueta" : "Crear etiqueta"}
        maxWidth="xl"
      >
        {dataLabels !== undefined && (
          <Stack sx={{ width: "500px", minHeight: "260px" }}>
            <Stack sx={{ marginTop: "5px", marginBottom: "10px" }}>
              <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                Nombre de la etiqueta
              </InputLabel>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                error={isNameRepeated(
                  isEdit
                    ? dataLabels
                        .filter(
                          (label: Label) => label.nombre !== labelToEdit?.nombre
                        )
                        .map((label: Label) => label.nombre)
                    : dataLabels.map((label: Label) => label.nombre),
                  labelName
                )}
                helperText={
                  isNameRepeated(
                    isEdit
                      ? dataLabels
                          .filter(
                            (label: Label) => label.nombre!==labelToEdit?.nombre
                          )
                          .map((label: Label) => label.nombre)
                      : dataLabels.map((label: Label) => label.nombre),
                    labelName
                  ) && "Ya existe una etiqueta con este nombre"
                }
                label="Nombre de la etiqueta"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
                placeholder="Nombre de la etiqueta"
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    color: "var(--blue)",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    margin: 0,
                    marginTop: "5px",
                  },
                }}
              />
            </Stack>
            {delayHelper && (
              <Stack sx={{ marginTop: "10px" }}>
                <InputLabel sx={form_label}>Color de la etiqueta</InputLabel>
                <Select
                  reference={labelColorRef}
                  styles={selectStyles(labelColorSelectedOption)}
                  options={colorSelectOptions}
                  name="Color"
                  onChange={labelColorOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar color de la etiqueta"
                  isClearable
                  Option={Option}
                  defaultValue={labelColorSelectedOption}
                />
              </Stack>
            )}
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            justifyContent: "center",
          }}
        >
          <Button
            title="Cerrar"
            type="button"
            onClick={cleanAndClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              handleSendLabel();
            }}
            disabled={isFormCompleted()}
            color="blue"
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateLabelModal;
