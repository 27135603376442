import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItem from "./ListItem";
import { checkIfColumnNotExists } from "../flowConfiguration/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 270,
    },
  },
};

interface IMultipleSelectCheckmarks {
  label: string
  items: any;
  selectedItems: any[];
  setSelectedItems: (selectedItems: any[]) => void;
  width?: string;
  allOptionsLabel?: string;
  customHandleChangeItem?: (newItem: any) => void;
}

export default function MultipleSelectCheckmarks({
  label,
  items,
  selectedItems,
  setSelectedItems,
  width,
  allOptionsLabel,
  customHandleChangeItem
}: IMultipleSelectCheckmarks) {

  const totalOptions = items && items?.length && [{id:"todas", label: allOptionsLabel ? allOptionsLabel : "Seleccionar todas las columnas"}, ...items]
   
  const handleChangeItem = (newItem: any) => { 
    if(newItem.id === "todas"){
      if(!selectedItems.map((item: any)=>{return item.label}).includes(newItem.label)){
        setSelectedItems(totalOptions);
      }else{
        setSelectedItems([]);
      }
    }else{
      setSelectedItems(
        selectedItems.map((item: any)=>{return item.label}).includes(newItem.label)
          ? selectedItems.filter((item: any) => item.label !== newItem.label && item.id !== "todas")
          : [...selectedItems, newItem]
      );
    }
  };

  return (
    <FormControl sx={{ m: 1, width: width ? width : 300, margin: 0 }}>
      {items && items.length > 0 && (
        <>
          <InputLabel sx={{ top: "-5px" }} id="demo-multiple-checkbox-label">
            {label}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedItems.filter((item: any)=>{return item.id !== "todas"}).map((item: any)=>{return item.label})}
            input={<OutlinedInput label={""} />}
            renderValue={(selected) =>
              selected.map((item: any, index: number) =>
               selected.length > 1 
                  ? (checkIfColumnNotExists(item, items) ?item + " (no existente en fuente actual)" : item)+
                    (index !== selected.length - 1 ? ", " : "")
                  : checkIfColumnNotExists(selected[0], items) ?  selected[0] + " (no existente en fuente actual)": selected[0]
              )
            }
            MenuProps={MenuProps}
            sx={{
              height: "45px",
              verticalAlign: "center",
              marginRight: "10px",
            }}
          >
            {totalOptions.map((item: any, index: number) => {
                return (
                  <ListItem
                    key={item + index}
                    item={item}
                    handleChangeItem={customHandleChangeItem ? customHandleChangeItem :handleChangeItem}
                    selectedItems={selectedItems}
                  />
                );
             
            })}
          </Select>
        </>
      )}
    </FormControl>
  );
}
