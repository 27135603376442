import { createContext, useReducer } from "react";
import { DataAction, DataState } from "./types";

const DataManagementContext = createContext<any>({});

export const DataManagementProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialDataState: DataState = {
    searchInfo: undefined,
    isLoadingSearchInfo: false,
    openUploadModal:false,
    folderFiles: [],
  };

  function dataReducer(DataState: DataState, action: any) {
    switch (action.type) {
      case "SET_SEARCH_INFO":
        return {
          ...DataState,
          searchInfo: action.payload,
        };
        case "SET_FOLDER_FILES":
          return {
            ...DataState,
            folderFiles: action.payload,
          };
        case "SET_IS_LOADING_SEARCH_INFO":
          return {
            ...DataState,
            isLoadingSearchInfo: action.payload,
          };
        case "SET_OPEN_UPLOAD_MODAL":
          return {
            ...DataState,
            openUploadModal: action.payload,
          };
         
      default:
        throw new Error();
    }
  }

  const [DataState, dataDispatch] = useReducer<
    React.Reducer<DataState, DataAction>
  >(dataReducer, initialDataState);

  return (
    <DataManagementContext.Provider
      value={{
        initialDataState,
        dataReducer,
        DataState,
        dataDispatch,
      }}
    >
      {children}
    </DataManagementContext.Provider>
  );
};

export default DataManagementContext;
