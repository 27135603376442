export const labels: { [index: string]: string } = {
  nueva_fuente: "Nueva Fuente",
  merge: "Merge",
  concat: "Concatenar",
  group_by: "Group By",
  nueva_columna: "Nueva Columna",
  eliminar_columna: "Eliminar Columna",
  modificar_columna: "Modificar Columna",
  renombrar_columnas: "Renombrar",
  eliminar_filas: "Eliminar Filas",
  filtrar: "Filtrar",
  dinamizar: "Dinamizar columnas",
  desdinamizar: "Desdinamizar columnas",
  setear_cabecera: "Setear cabecera"
};
