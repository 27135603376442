import { Stack } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { useContext, useEffect, useState } from "react";
import { texts } from "../../../texts.js";
import FlowsProvider from "../../../context/FlowsContext";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import SimpleTable from "../../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { VIEW_RESULTS_URL } from "../../../api/axios";

interface ViewModalProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}

const ViewModal = ({ open, handleClose, id }: ViewModalProps) => {
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow } = flowState;
  const [resultsDataState, setResultsDataState] = useState<any>(
    undefined
  );

  const onSuccesGetData = (data: any) => {
    setResultsDataState(data);
  }

  const onClose = () => {
    setResultsDataState(undefined);
    handleClose()
  }

  useEffect(() => {
    if (open) {
      getViewResults();
    }
  }, [open]);

  const {
    data: viewResultsData,
    isLoading: isLoadingViewResults,
    callApi: getViewResults,
  } = useApi(
    VIEW_RESULTS_URL(data_flow?.id, active_stepper, id),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false  );

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title="Ver resultados"
      maxWidth="xl"
    >
      <SimpleBackdrop
        open={isLoadingViewResults}
        message={texts.flows.viewResults.loading}
      />
      <Stack
        width="100%"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ minHeight: "500px", height: "100%", width: "100%", minWidth: "81vw" }}
      >
        <Stack sx={{ minWidth: "80vw" }}>
          {resultsDataState && (
            <SimpleTable
              columns={transformDataSimpleTable(resultsDataState).columns}
              rows={transformDataSimpleTable(resultsDataState).rows}
              toolbar={false}
              exportButton={false}
              filterButton={false}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ViewModal;
