import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import { memo, useRef } from "react";
import { Stack } from "@mui/material";
import useLanguage from "../../context/LanguageProvider";

interface Props {
  results: any;
  tooltip?: (value: any)=> string;
}
const BarDrilldownChart = memo((props: Props) => {

  const { results, tooltip } = props;
  const chartRef: any = useRef(null);
  const { t } = useLanguage();

  drilldown(Highcharts);

  const options = {
      chart: {
        type: "bar",
      },
      title: {
        text: results?.titulo,
      },
      xAxis: {
        type: "category",
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        
        title: {
          text: results?.nombre_eje_y,
          align: "high",
        },

        labels: {
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        y: 0,
        useHTML: tooltip,
        formatter: function () {
          return tooltip
            ? tooltip(this.y)
            : `<p>${this.y}</p> `;
        },
      },

      credits: {
        enabled: false,
      },
      //CAMBIAR EN EL FUTURO SI HAY GRAFICOS CON MÁS DE UNA SERIE
      series: [{  showInLegend: false, ...results?.series[0]}]
    }
  
  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      <Stack
        sx={{ alignSelf: "flex-end", height: "35px", margin: "0px !important" }}
      >
      </Stack>
     <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

export default BarDrilldownChart;
