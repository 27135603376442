import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { form_label } from "../../styles/app-styles";

interface HowMergeIconProps {
    icon: React.ReactNode,
    onClick: (value: string) => void,
    mergeType: string,
    mergeValue: string,
}

const HowMerge = ({ icon, onClick, mergeType, mergeValue }: HowMergeIconProps) => {
  return (
      <FormControlLabel
        control={<Radio color="primary" sx={{mr: "10px"}}/>}
        label={
        <Stack sx={{flexDirection: "row", alignItems: "center", gap: "20px", width: "200px", 
          }}>
            {icon}
            <Typography sx={mergeType === "" ? {color: "red"} : form_label}>
                {mergeValue?.toUpperCase()}
            </Typography>
        </Stack>
        }
        value={mergeValue}

        checked={mergeType === mergeValue}
        onChange={(e: any) => {
            onClick(e.target.value);
        }}
      />
  );
};

export default HowMerge;
