import { Stack, Typography } from "@mui/material";

interface HeaderProps {
   size?: boolean 
  }

const ResourcesListHeader = ({size}:HeaderProps) => {
  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: "row",
        backgroundColor: "var(--very-very-light-grey)",
        borderRadius: "15px",
        marginBottom: "3px",
      }}
    >
      <Typography
        sx={{
          marginLeft: "50px",
          textTransform: "uppercase",
          fontSize: "14px",
          color: "var(--blue)",
          fontWeight: "bold",
        }}
      >
        Tipo
      </Typography>
      <Typography
        sx={{
          marginLeft: "80px",
          textTransform: "uppercase",
          fontSize: "14px",
          color: "var(--blue)",
          fontWeight: "bold",
          width: "345px",
        }}
      >
        Nombre
      </Typography>
      <Typography
        sx={{
          marginLeft: "0px",
          textTransform: "uppercase",
          fontSize: "14px",
          color: "var(--blue)",
          fontWeight: "bold",
          width: "250px",
        }}
      >
        Etiqueta
      </Typography>
      <Typography
        sx={{
          marginLeft: "0px",
          textTransform: "uppercase",
          fontSize: "14px",
          color: "var(--blue)",
          fontWeight: "bold",
          width: "270px",
        }}
      >
        Fecha de creación
      </Typography>
      {size &&       <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: "14px",
          color: "var(--blue)",
          fontWeight: "bold",
        }}
      >
        Peso
      </Typography>}
    </Stack>
  );
};

export default ResourcesListHeader;
