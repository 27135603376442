import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useContext, useEffect, useState } from "react";
import SimpleTable from "../../tablesTools/SimpleTable";
import useApi from "../../../hooks/useApi";
import { VIEW_RESULTS_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import FlowsProvider from "../../../context/FlowsContext";
import { searchStepper } from "../utils";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";

const ViewResultsTableAccordion = () => {
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow } = flowState;
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [resultsDataState, setResultsDataState] = useState<any>(undefined);

  const onSuccesGetData = (data: any) => {
    setResultsDataState(data);
  };

  useEffect(() => {
    // Si el item NO tiene error, hacemos el llamado
    if (
      !searchStepper(active_stepper, data_flow)?.items[
        searchStepper(active_stepper, data_flow).items?.length - 1
      ]?.posee_error
    ) {
      getViewResults();
    }
  }, [active_stepper, data_flow]);

  const {
    data: viewResultsData,
    isLoading: isLoadingViewResults,
    callApi: getViewResults,
  } = useApi(
    VIEW_RESULTS_URL(
      data_flow?.id,
      active_stepper,
      searchStepper(active_stepper, data_flow)?.items[
        searchStepper(active_stepper, data_flow).items?.length - 1
      ]?.id
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );

  return (
    <>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <Typography
          sx={{ color: "var(--blue)", fontSize: "14px", fontWeight: "bold" }}
        >
          VER RESULTADOS
        </Typography>
        {!openDetail ? (
          <ExpandMoreIcon
            sx={{
              color: "var(--blue)",
              fontSize: "28px",
              marginLeft: "2px",
              cursor: "pointer",
              marginBottom: "1px",
            }}
            onClick={() => setOpenDetail(true)}
          />
        ) : (
          <ExpandLessIcon
            sx={{
              color: "var(--blue)",
              fontSize: "28px",
              marginLeft: "2px",
              cursor: "pointer",
              marginBottom: "1px",
            }}
            onClick={() => setOpenDetail(false)}
          />
        )}
      </Stack>
      <Stack
        sx={{
          height: openDetail ? "520px" : "0px",
          transition: "all 1s",
          width: "100%",
          alignItems: "center",
          overflowY: openDetail ? "scroll" : "hidden",
          margin: 0,
        }}
      >
        {
          <Stack
            width="100%"
            alignItems="center"
            justifyContent={"space-between"}
            sx={{
              minHeight: "500px",
              height: "100%",
              width: "100%",
              margin: 0,
            }}
          >
            <Stack sx={{ minWidth: "80vw" }}>
              {resultsDataState && (
                <SimpleTable
                  columns={transformDataSimpleTable(resultsDataState).columns}
                  rows={transformDataSimpleTable(resultsDataState).rows}
                  toolbar={false}
                  exportButton={false}
                  filterButton={false}
                />
              )}
            </Stack>
          </Stack>
        }
      </Stack>
    </>
  );
};

export default ViewResultsTableAccordion;
