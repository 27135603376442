import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "../../dialog/Dialog";

interface FormatModalProps {
  open: boolean;
  handleClose: () => void;
}

const FormatModal = ({ open, handleClose }: FormatModalProps) => {
  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title="¿Cómo realizar el split de columna?"
        maxWidth="xl"
      >
        <Stack sx={{ width: "600px" }}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "bold", color: "var(--blue)" }}
          >
            Mantener valores desde la izquierda/derecha
          </Typography>
          <Typography sx={{ fontSize: "18px", marginBottom:"14px", color: "var(--blue)" }}>
            Ingresar el número de caracteres que se desea conservar desde la izquierda o desde la derecha según corresponda.
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "bold", color: "var(--blue)" }}
          >
            Mantener valores intermedios
          </Typography>
          <Typography sx={{ fontSize: "18px", marginBottom:"14px", color: "var(--blue)" }}>
            Ingresar la posición inicial y final de los carácteres que desea conservar. El valor míninmo para ambos campos es 1. Por ejemplo: Si tenemos la palabra "música" e ingresamos como posición inicial "2" y como posición final "3", obtendremos el valor "ús".
          </Typography>
        </Stack>
      </Dialog>
    </div>
  );
};

export default FormatModal;
