import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { ALLOWED_FILES_URL, DATA_URL, DOWNLOAD_FILE } from "../../api/axios";
import { texts } from "../../texts";
import { AddButton, Button } from "../buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import useApi from "../../hooks/useApi";
import { ConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import { useRef, useState } from "react";
import { FileType } from "../flowConfiguration/Modals/AddFont/types";
import Dialog from "../dialog/Dialog";
import { isValidName } from "../schemas/utils";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { useApiQuery } from "../../hooks/useApiQuery";

interface AcceptedFilesProps {
  refetchAllowedFiles: () => void;
  isButton: boolean;
}

const AcceptedFiles = ({
  refetchAllowedFiles,
  isButton,
}: AcceptedFilesProps) => {
  const inputFileNameRef: any = useRef(null);
  const [fileName, setFileName] = useState<string>("");
  const [fileToDelete, setFileToDelete] = useState<FileType>();
  const [openConfirmDeleteNameModal, toggleConfirmDeleteFileModal] =
    useDialog();
  const [openAllowedFilesModal, toggleAllowedFilesModal] = useDialog();

  /*************************** LLAMADAS ***************************/

  const {
    data: dataCreatedFile,
    isLoading: isLoadingPostFileName,
    callApi: postFile,
    error: errorPostFile,
  } = useApi(
    ALLOWED_FILES_URL,
    "POST",
    texts.dataManagement.postAllowedFileName.codes,
    { nombre: fileName },
    refetchAllowedFiles,
    undefined,
    true
  );

  const {
    data: allowedFileNames,
    isLoading: isLoadingFilesNames,
    refetch: refetchLocalFilesNames,
  } = useApiQuery(
    ALLOWED_FILES_URL,
    false,
    texts.dataManagement.getAllowedFileNames.error
  );

  /*************************** HANDLERS **************************/

  const handleDeleteConfirmation = (file: any) => {
    setFileToDelete(file);
    toggleConfirmDeleteFileModal();
  };

  const handleAddFileName = () => {
    postFile();
    setFileName("");
  };

  return (
    <>
      {isButton ? (
        <Stack direction={"row"} alignItems="center">
          <Button
            title={"Archivos permitidos"}
            type="button"
            onClick={toggleAllowedFilesModal}
            color="blue"
          />
        </Stack>
      ) : (
        <Typography
          sx={{
            fontWeight: "600",
            color: "var(--blue)",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
            marginBottom: "0 !important",
            marginTop: "5px",
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={toggleAllowedFilesModal}
        >
          Archivos permitidos
        </Typography>
      )}
      <Dialog
        open={openAllowedFilesModal}
        handleClose={toggleAllowedFilesModal}
        maxWidth="xl"
        title={"Archivos permitidos"}
        draggable={true}
        sx={{ zIndex: "100000" }}
      >
        <SimpleBackdrop
          open={isLoadingPostFileName}
          message={texts.dataManagement.postAllowedFileName.loading}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "440px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70px",
              marginBottom: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                width: "90%",
                "& ::placeholder": { textTransform: "initial" },
                "& input": { textTransform: "uppercase" },
              }}
            >
              <TextField
                id="fileName"
                label={" Agregar nuevo nombre de archivo permitido:"}
                placeholder={"Nombre del archivo..."}
                size="small"
                multiline
                variant="outlined"
                helperText={
                  !isValidName(allowedFileNames?.map((name: string)=>{return {nombre: name}}), fileName, false)
                    ? "Ya existe un archivo permitido con este nombre"
                    : ""
                }
                error={!isValidName(allowedFileNames?.map((name: string)=>{return {nombre: name}}), fileName, false)}
                fullWidth
                value={fileName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFileName(event.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </Box>
            <Stack
              justifyContent={"space-between"}
              sx={{
                margin: 0,
                justifyContent: "center",
                display: "flex",
                height: "90px",
              }}
            >
              <AddButton
                onClick={handleAddFileName}
                disabled={
                  !isValidName(allowedFileNames?.map((name: string)=>{return {nombre: name}}), fileName, false) || !fileName
                }
              />
            </Stack>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginLeft: "14px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "var(--blue)",
                fontWeight: "600",
                fontSize: "18px",
                marginBottom: "10px",
                marginRight: "auto",
                marginTop:"5px"
              }}
            >
              Archivos permitidos
            </Typography>
            {allowedFileNames?.map((file: string) => {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0"
                  padding="0"
                  width={"97%"}
                  key={file}
                  sx={{
                    border: "1px solid rgb(0 0 0 / 20%)",
                    marginBottom: "5px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    sx={{ color: "var(--text-info)", fontSize: "15px" }}
                  >
                    {file}
                  </Typography>
                  {/*file.nombre !== "VENTAS" && file.nombre !== "GASTOS" && (
                    <Tooltip title={"Eliminar archivo permitido"}>
                      <DeleteIcon
                        onClick={() => {
                          handleDeleteConfirmation(file);
                        }}
                        sx={{
                          color: "var(--icon-info) !important",
                          cursor: "pointer",
                          transition: "all 1s linear",
                          "& :hover": { color: "var(--icon-error)" },
                        }}
                      />
                    </Tooltip>
                  )*/}
                </Box>
              );
            })}
          </Box>
          <Button
            title={"Cerrar"}
            color="grey"
            type="button"
            onClick={toggleAllowedFilesModal}
          />
          <ConfirmationModal
            open={openConfirmDeleteNameModal}
            handleClose={toggleConfirmDeleteFileModal}
            handleAccept={toggleConfirmDeleteFileModal}
            message={`eliminar el nombre de archivo permitido ${fileToDelete?.nombre}`}
            title={"Eliminar archivo permitido"}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AcceptedFiles;
