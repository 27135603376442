import Highcharts from "highcharts";
import VennModule from "highcharts/modules/venn.js";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { convertUnit } from "../../utils/util";
import TableModal from "../codeValidation/TableModal";
import useDialog from "../../hooks/useDialog";

VennModule(Highcharts);
Highcharts.AST.allowedAttributes.push('onclick');

const VennChart = (data: any) => {
  const [dataChart, setDataChart] = useState<any>(null);
  const [tableData, setTableData] = useState<any>({set: null, data: null});
  const [openModal, handleToggleOpenModal] = useDialog();

  
 useEffect(() => {
 if(data){
  setDataChart(data.data)
 }
}, [data]);


(window as any).dataAmbos = dataChart?.ambos?.data;
(window as any).dataDerecha = dataChart?.derecha?.data;
(window as any).dataIzquierda = dataChart?.izquierda?.data;

(window as any).handleOpenTable = function (set: string, data: any){
setTableData({data: data, set: set})
handleToggleOpenModal()
}


  const options = dataChart ? {
    chart: {
      backgroundColor: "#f2f2f2",
    },
    tooltip: {
      useHTML: true,
      style: {
        pointerEvents: 'auto'
      },
      hideDelay: 1000,
      point: {
        sets: [""],
        percentage: "",
        dataTable:""
      },
      formatter: function () {
        if(this.point.sets[1]==='Presentes sólo en archivo 2'){
          return `<div class="tooltipContainerStyles"> <b class="tooltipLabel"> Presentes en ambos:  ${this.point?.percentage}</b>
          <button type="button" class="buttonTooltip" onclick="handleOpenTable('Presentes en ambos', dataAmbos)">Ver detalle</button> <div>`;
        }else{
          if(this.point.sets[0] === "Presentes sólo en archivo 1"){
            return `<div class="tooltipContainerStyles"> <b class="tooltipLabel"> ${this.point.sets[0]}: ${this.point?.percentage}</b>
            <button type="button" class="buttonTooltip" onclick="handleOpenTable('Presentes sólo en archivo 1', dataIzquierda)">Ver detalle</button> <div>`;
          }else{
            return `<div class="tooltipContainerStyles"> <b class="tooltipLabel"> ${this.point.sets[0]}: ${this.point?.percentage}</b>
            <button type="button" class="buttonTooltip" onclick="handleOpenTable('Presentes sólo en archivo 2', dataDerecha)">Ver detalle</button> <div>`;
          }
        }
      }
  },
    plotOptions: {
      venn: {
        dataLabels: {
          enabled: true,
          style: {
            width: 50,
            fontSize: "24px",
            color: "#0D3940"
          },
        },
      },
    },
    legend: {
      layout: 'vertical',
      options: {
        sets: [0],
      },
      
      labelFormatter: function () {
        if(this.options.sets.length > 1){
          return "Presentes en ambos"
        }else{
          return this.options.sets[0]
  
        }
    },
      align: 'left',
      verticalAlign: 'middle',
      itemMarginTop: 10,
      itemMarginBottom: 10
    },
    series: [
      {
        type: "venn",
        showInLegend: true,
        name: null,
        data: [
          {
            sets: ["Presentes sólo en archivo 1"],
            percentage: data.isReport ? convertUnit("porcentaje", dataChart.izquierda.porcentaje/100) : convertUnit("porcentaje", dataChart.izquierda.porcentaje),
            value: 2,
            name: dataChart.izquierda.filas.toString(),
            color:  "var(--light-violet)",
            dataTable: dataChart.izquierda.data,
            events: {
              series: {
                points: [
                  {
                    name: "",
                    graphic: {
                      hide: () => {
                        console.log("prueba");
                      },
                      show: () => {
                        console.log("prueba");
                      },
                    },
                  },
                ],
              },
              mouseOver: function () {
                // Obtener la referencia a los puntos (círculos) del gráfico
                const points = this.series.points;
  
                // Iterar sobre los puntos y ocultar los que no pertenecen al círculo de la izquierda (A)
                for (let i = 0; i < points.length; i++) {
                  if (points[i].name !== "A") {
                    points[i].graphic.hide();
                  }
                }
              },
              mouseOut: function () {
                // Obtener la referencia a los puntos (círculos) del gráfico
                const points = this.series.points;
  
                // Mostrar todos los puntos nuevamente al salir del hover
                for (let i = 0; i < points.length; i++) {
                  points[i].graphic.show();
                }
              },
            },
          },
          {
            events: {
              series: {
                points: [
                  {
                    name: "",
                    graphic: {
                      hide: () => {
                        console.log("prueba");
                      },
                      show: () => {
                        console.log("prueba");
                      },
                    },
                  },
                ],
              },
              mouseOver: function () {
            
                const points = this.series.points;
                for (let i = 0; i < points.length; i++) {
                  if (points[i].name !== "B") {
                    points[i].graphic.hide();
                  }
                }
              },
              mouseOut: function () {
                const points = this.series.points;
                for (let i = 0; i < points.length; i++) {
                  points[i].graphic.show();
                }
              },
            },
            sets: ["Presentes sólo en archivo 2"],
            percentage: data.isReport ? convertUnit("porcentaje", dataChart.derecha.porcentaje/100) : convertUnit("porcentaje", dataChart.derecha.porcentaje),
            color: "var(--light-blue)",
            value: 2,
            name: dataChart.derecha.filas.toString(),
            dataTable: dataChart.derecha.data
          },
          {
            sets: ["Presentes sólo en archivo 1", "Presentes sólo en archivo 2"],
            percentage: data.isReport ? convertUnit("porcentaje", dataChart.ambos.porcentaje/100) : convertUnit("porcentaje", dataChart.ambos.porcentaje),
            value: 1,
            color: "var(--light-magenta)",
            name: dataChart.ambos.filas.toString(),
            dataTable: dataChart.ambos.data
          },
        ],
      },
    ],
    title: {
      text: null,
    },
  } : null;

  return (
    <div style={{ width: data.customWidth ? data.customWidth : "50%" }}>
      {dataChart &&  <HighchartsReact highcharts={Highcharts} options={options}/>}
      <TableModal data={tableData.data} open={openModal} handleClose={handleToggleOpenModal} title={tableData.set ? tableData.set :"Algo"}/>
    </div>
  );
};

export default VennChart;
