import FlowsProvider from "../../../context/FlowsContext";
import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../api/axios";
import {
  filterIdFromColumns,
  findBeforeElementPosition,
  searchStepper,
} from "../../flowConfiguration/utils";
import { texts } from "../../../texts";
import BaseModal from "./BaseModal";
import useDialog from "../../../hooks/useDialog";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CustomColumnInputFlow from "../CustomColumnInputFlow";
interface ModifyColumnsModalProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const RenameColumns = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: ModifyColumnsModalProps) => {
  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { active_stepper, data_flow, itemToEdit } = flowState;
  const [columnsError, setColumnsError] = useState<string[]>([]);
  const [newObjectNames, setNewObjectNames] = useState<any>();
  const [columns, setColumns] = useState<string[]>([]);
  const [deletedColumns, setDeletedColumns] = useState<string[]>([]);
  const [nonExistentColumns, setNonExistentColumns] = useState<string[]>([]);
  const [openConfirmationModal, handleConfirmationModal] = useDialog();

  /************************************* CARGA DE DATOS ********************************/

  // Esta función se encarga de filtrar los datos de la respuesta de la API para obtener un array de nombres de columnas.
  const onSuccessGetColumnsData = (data: any) => {
    if (dataItem) {
      const nonExistentColumns: string[] = [];
      const existentColumns = filterIdFromColumns(data).map((col: any) => {
        return col.columna;
      });
      Object.keys(dataItem.renombre_columnas).forEach((col: any) => {
        if (!existentColumns.includes(col)) {
          nonExistentColumns.push(col);
        }
      });
      setNonExistentColumns(nonExistentColumns);
    } else {
      const columnsNames = filterIdFromColumns(data).map((column: any) => {
        return { nombre_viejo: column.columna, nombre_nuevo: column.columna };
      });
      setColumns(columnsNames);
      const newObject: any = {};
      for (const column of columnsNames) {
        newObject[column.nombre_viejo] = column.nombre_viejo;
      }
      setNewObjectNames(newObject);
    }
  };

  const { isLoading: isLoadingColumnsData, callApi: getColumnsData } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccessGetColumnsData,
    undefined,
    false
  );

  // Este useEffect se activa cuando la variable `open` cambia de estado. Si `open` está activo, se realiza una serie de operaciones para configurar las columnas y los datos según la situación actual. Si `dataItem` está disponible, se construye un array de nuevas columnas basadas en sus claves y valores y se configura el estado de las columnas y los nombres de objetos nuevos. Si `dataItem` no está disponible, se llama a la función `getColumnsData` para obtener los datos de las columnas.
  useEffect(() => {
    if (open) {
      getColumnsData();
      if (dataItem) {
        const newColumnsArray: any[] = [];
        for (const key in dataItem.renombre_columnas) {
          newColumnsArray.push({
            nombre_viejo: key,
            nombre_nuevo: dataItem.renombre_columnas[key],
          });
        }
        setColumns(newColumnsArray);
        setNewObjectNames(dataItem.renombre_columnas);
        setDeletedColumns(dataItem.columnas_eliminadas);
      }
    }
  }, [open, dataItem]);

  /********************************* GUARDAR INFO DE INPUTS *****************************/

  /**
   * @description Esta función se utiliza para crear una copia de un objeto y asignar un nuevo valor a una propiedad específica identificada por su nombre (clave). La función toma un objeto, una clave (nombre de propiedad) y un nuevo valor, y devuelve una nueva copia del objeto con la propiedad actualizada o sin cambios si la clave no se encuentra en el objeto original.
   * @param {object} object - El objeto original que se clonará y actualizará.
   * @param {string} param - El nombre de la propiedad (clave) que se desea actualizar en el objeto.
   * @param {any} value - El nuevo valor que se asignará a la propiedad identificada por la clave.
   * @returns {object} - Una nueva copia del objeto original con la propiedad actualizada o sin cambios si la clave no se encuentra en el objeto original.
   */

  function saveValueInObject(object: any, param: any, value: any) {
    const newObject = { ...object };
    const clave = Object.keys(newObject).find((key) => key === param);
    if (clave) {
      newObject[clave] = value;
    }
    return newObject;
  }

  /********************************* GUARDAR Y ENVIAR DATOS *****************************/

  const saveRenameColumns = () => {
    handleConfirmationModal();
    const columnsRename = [];
    if (newObjectNames) {
      for (const key in newObjectNames) {
        if (newObjectNames.hasOwnProperty(key)) {
          columnsRename.push({
            nombre_viejo: key,
            nombre_nuevo: newObjectNames[key],
          });
        }
      }
    }
    const completedInfo = {
      tipo: "renombrar_columnas",
      renombre_columnas: columnsRename.filter((col: any) => {
        return (
          !deletedColumns.includes(col.nombre_viejo) &&
          !nonExistentColumns.includes(col.nombre_viejo)
        );
      }),
      columnas_eliminadas: deletedColumns.filter((col: any) => {
        return !nonExistentColumns.includes(col);
      }),
    };

    if (dataItem) {
      putItem(
        ITEM_URL(data_flow.id, active_stepper, itemToEdit.id),
        completedInfo
      );
    } else {
      postItem(POST_ITEM_URL(data_flow.id, active_stepper), completedInfo);
    }
    cleanAndClose();
  };

  /**************************** LIMPIEZA Y CIERRE *****************************/

  const cleanAndClose = () => {
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
    handleClose();
    setNewObjectNames({});
    setColumns([]);
  };

  const showColumns = () =>
    itemToEdit
      ? true
      : !searchStepper(active_stepper, data_flow)?.items?.find(
          (item: any) => item?.tipo === "renombrar_columnas"
        )
      ? true
      : false;

  const handleDeleteColumn = (col: string) => {
    if (deletedColumns.includes(col)) {
      setDeletedColumns(
        deletedColumns.filter((column: any) => {
          return col !== column;
        })
      );
    } else {
      setDeletedColumns([...deletedColumns, col]);
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingColumnsData}
        message={texts.flows.getFlowColumns.loading}
      />
      {!isLoadingColumnsData && (
        <BaseModal
          open={open}
          title="Renombrar columnas"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={handleConfirmationModal}
          handleAccept={saveRenameColumns}
          confirmationModalMessage={"renombrar las columnas"}
          disabledAcceptButton={columnsError.length > 0 || !showColumns()}
          width={"100%"}
          height={"100%"}
          hasResultsTable
        >
          <Stack
            sx={{
              margin: "0 15px 5px 15px",
              width: "95%",
              alignItems: "center",
              minHeight: "150px",
              justifyContent: "space-evenly",
            }}
          >
            <Typography
              color={"var(--blue)"}
              mb="10px"
              sx={{ fontSize: "0.9rem" }}
            >
              La función de renombre solo está disponible una vez en cada paso.
              Por lo tanto, se sugiere reservar esta opción para el final. Si se
              necesitan realizar cambios, se puede editar o eliminar el item y
              crear uno nuevo al final del paso.
            </Typography>
            {showColumns() && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: "10px",
                      color: "#172D40",
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                      alignSelf: "flex-start",
                      marginLeft: "15px",
                    }}
                  >
                    Columnas:
                  </Typography>
                </Box>
                {!!columns &&
                  columns.length > 0 &&
                  columns.map((column: any) => {
                    return (
                      <CustomColumnInputFlow
                        key={column.nombre_viejo}
                        column={column}
                        setColumnsError={setColumnsError}
                        newObjectNames={newObjectNames}
                        setNewObjectNames={setNewObjectNames}
                        saveValueInObject={saveValueInObject}
                        isDeleted={deletedColumns.includes(column.nombre_viejo)}
                        setDeletedColumns={handleDeleteColumn}
                        nonExistentColumn={
                          dataItem &&
                          nonExistentColumns.includes(column.nombre_viejo)
                        }
                        nonExistentColumns={nonExistentColumns}
                      />
                    );
                  })}
              </>
            )}
          </Stack>
        </BaseModal>
      )}
    </>
  );
};

export default RenameColumns;
