import { useNavigate } from "react-router-dom";
import FixedAddButton from "../buttons/FixedAddButton";
import ComponentLayout from "../layout/ComponentLayout";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useState } from "react";
import useDialog from "../../hooks/useDialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import { useApiQuery } from "../../hooks/useApiQuery";
import {
  GET_REPORT_BY_TYPE,
  REPORT_BY_ID,
} from "../../api/axios";
import { texts } from "../../texts";
import BasicGrid from "../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useApi from "../../hooks/useApi";
import { ConfirmationModal as DeleteSummaryConfirmationModal } from "../dialog";
import { filterDataByName } from "../pipelines/utils";
import Stack from "@mui/material/Stack";
import SearchInput from "../forms/SearchInput";
import SummaryCard from "./SummaryCard";

const InformationSummaryAdmin = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>(undefined);
  const [summaryToDelete, setSummaryToDelete] = useState<number>();

  const [
    openDeleteSummaryConfirmationModal,
    handleToggleDeleteSummaryConfirmationModal,
  ] = useDialog();

  const { data: dataSummaries, isLoading: isLoadingSummaries, refetch: refetchSummaries } = useApiQuery(
    GET_REPORT_BY_TYPE("resumen"),
    false,
    texts.summary.getSummaries.error
  );

  const goToConfigSummaries = () => {
    navigate("/reporte-base/configuracion-resumenes");
  };

  const handleEdit = (e: any, id: string) => {
    localStorage.setItem("summaryId", id);
    goToConfigSummaries();
  };


  const {
    isLoading: isLoadingDeleteSummary,
    callApi: deleteSummaryById,
  } = useApi(
    REPORT_BY_ID(summaryToDelete),
    "DELETE",
    texts.summary.deleteSummary.codes,
    undefined,
    refetchSummaries
  );

  const handleDelete = () => {
    deleteSummaryById();
    handleToggleDeleteSummaryConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setSummaryToDelete(id);
    handleToggleDeleteSummaryConfirmationModal();
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataSummaries, e.target.value, setFilteredData);
  };

  return (
    <ComponentLayout
      title="Administración de resumenes"
      icon={<TableChartIcon />}
    >
      <SimpleBackdrop
        open={isLoadingSummaries}
        message={texts.summary.getSummaries.loading}      />

      <SimpleBackdrop
        open={isLoadingDeleteSummary}
        message={texts.summary.deleteSummary.loading}
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <SearchInput
          value={searchValue}
          handleChange={handleSearch}
          placeholder="Búsqueda por nombre"
        />
      </Stack>
      {filteredData?.length || dataSummaries?.length ? (
        <BasicGrid
          data={filteredData !== undefined ? filteredData : dataSummaries}
          Card={SummaryCard}
          handleEdit={handleEdit}
          /*handleCopy={handleCopy}*/
          /* handleInfo={handleInfo} */
          handleDelete={handleClickOnDelete}
          handleExport={true}
          showInfo={true}
        />
      ) : (
        dataSummaries &&
        !isLoadingSummaries && (
          <Box
            sx={{
              width: "95%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--dark-grey)" }}
            >
              Aún no hay resumenes disponibles.
            </Typography>
          </Box>
        )
      )}
      <FixedAddButton handleClick={goToConfigSummaries} />
      <DeleteSummaryConfirmationModal
        open={openDeleteSummaryConfirmationModal}
        handleClose={handleToggleDeleteSummaryConfirmationModal}
        handleAccept={handleDelete}
        message="eliminar el resumen"
        title="Confirmar"
      />
    </ComponentLayout>
  );
};

export default InformationSummaryAdmin;
