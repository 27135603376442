import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

interface InfoProps {
  color?: "blue" | "white";
  text: string;
}

const Info = ({ text, color }: InfoProps) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={text}
      sx={{ verticalAlign: "center", fontSize: "16px" }}
    >
      <InfoOutlinedIcon
        sx={{
          color: color === "blue" ? "#172D40" : "white",
          height: "16px",
          width: "22px",
          paddingLeft: "5px",
          fontSize: "12px",
          verticalAlign: "center",
        }}
      />
    </Tooltip>
  );
};

export default Info;
