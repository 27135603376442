import "./step.styles.css";
interface FormSelectProps {
  title?: string;
  children: React.ReactNode;
  required?: boolean;    
}

const FormSelectLayout = ({
  title,
  children,
  required
}: FormSelectProps) => {
  return (
    <div className="cc_step_form_select">
      <label className="cc_step_form_label" htmlFor={title} aria-label={title}>
        {required &&  <span>*</span>}       
        {title && title}
      </label>   
      {children}
    </div>
  );
};

export default FormSelectLayout;
