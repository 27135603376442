import { useContext, useEffect, useRef, useState } from "react";
import PipelinesStepperProvider from "../../../context/PipelinesStepperProvider";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import StepLayout from "../StepLayout";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { sheetOptions } from "../utils";
import useApi from "../../../hooks/useApi";
import { FILE_BY_ID, FILE_INFO_URL } from "../../../api/axios";
import { texts } from "../../../texts.js";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import UploadFileContent from "../../dataManagement/UploadFileModal/UploadFileContent";

interface Step1Props {
  pipelineToEdit?: any;
}

const Step1 = ({ pipelineToEdit }: Step1Props) => {  const { pipelineState, pipelineDispatch } = useContext<
    React.ContextType<typeof PipelinesStepperProvider>
  >(PipelinesStepperProvider);
  const {
    file,
    selected_sheet,
    sheets_list,
    data: dataContext,
    no_header_sheet,
  } = pipelineState;
  const sheetSelectRef: any = useRef(null);
  const [noHeader, setNoHeader] = useState<boolean>(no_header_sheet);
  const [dataFileState, setDataFileState] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>("");


  const onSuccessGetFileInfo = (data: any) => {
    pipelineDispatch({
      type: "SET_FILE_DATA",
      payload: {
        name: data.nombre,
        id: data.id,
      },
    });
    pipelineDispatch({
      type: "SET_SHEETS_LIST",
      payload: sheetOptions(data.hojas),
    });
    setDataFileState([data]);
  };

  const onErrorGetFileInfo = (status: any) => {
    pipelineDispatch({
      type: "SET_SHEETS_LIST",
      payload: [],
    });
    setDataFileState([]);
  };

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: selectedFileFolder,
      directorio: false,
      tipo: "azure",
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );
  
  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile(fileState);
    setSheetSelectedOption(undefined)
    sheetSelectRef?.current?.clearValue();
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if(clickedFile.id === null || clickedFile.id === undefined){
            getFilesInfo(undefined, {
              path_archivo: clickedFile === "chooseFile" ? selectedFile :`${selectedFileFolder}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              actualizar_archivo: true
            });
          }else{
            getFilesInfoById(FILE_BY_ID(clickedFile.id));          }
        } else {
          clickedFile !== "typingFile" &&  setDataFileState([]);
        }
      }
    }
  };


  const onChangeSheetSelectValue = (newValue?: any) => {
    pipelineDispatch({
      type: "SET_SELECTED_SHEET",
      payload: {
        label: newValue?.label,
        value: newValue?.value,
      },
    });
   pipelineDispatch({
      type: "SET_NEW_DATA",
      payload: [],
    });
    pipelineDispatch({
      type: "SET_DATA",
      payload: [],
    });
    if (dataContext.length > 0) {
    /* 
    
      pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          id: null,
          data: [],
          renombre: [],
        },
      });
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_SELECTED_COLUMNS",
        payload: [],
      });*/
    }
    if(pipelineToEdit){
     /* pipelineDispatch({
        type: "SET_UNDO_ALL",
        payload: true,
      });
      pipelineDispatch({
        type: "SET_VALIDATIONS",
        payload: [],
      });*/
      pipelineDispatch({
        type: "SET_REPLACED_FILE",
        payload: true,
      })
    }
  };
  
  const onChangeClearValue: any = () => {
    pipelineDispatch({
      type: "SET_SELECTED_SHEET",
      payload: {
        label: undefined,
        value: undefined,
      },
    });
    if (dataContext.length > 0) {
      pipelineDispatch({
        type: "SET_DATA",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_NEW_DATA",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_HEADER",
        payload: {
          id: null,
          data: [],
          renombre: [],
        },
      });
      pipelineDispatch({
        type: "SET_ROWS_TO_DELETE",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_ACTUAL_COLUMNS",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_ACTUAL_ROWS",
        payload: [],
      });
      pipelineDispatch({
        type: "SET_SELECTED_COLUMNS",
        payload: [],
      });
    }
  };

  useEffect(() => {
    sheetSelectRef?.current?.clearValue();
  }, [file]);

  const [
    sheetSelectedOption,
    setSheetSelectedOption,
    sheetOptionsChangeHandler,
  ] = useSingleSelect(
    onChangeSheetSelectValue,
    /*onChangeClearValue*/ undefined,
    selected_sheet
  );

  const handleChangeNoHeader = () => {
    setNoHeader(!noHeader);
    pipelineDispatch({
      type: "SET_NO_HEADER_SHEET",
      payload: !noHeader,
    });
  };


  return (
    <StepLayout stepTitle="Carga del archivo">
      <Stack width="90%" height="100%" gap="5px">
        <SimpleBackdrop
          open={isLoadingFileInfo || isLoadingFileInfoById}
          message={texts.adminPipeline.getFileUrl.loading}
        />
        <Stack width="100%" height="68%" overflow="auto">
          <UploadFileContent
            areMultipleFiles={false}
            selectedFile={selectedFile}
            setSelectedFile={handleSelectFile}
            dataFileState={dataFileState}
            setDataFileState={setDataFileState}
            setSelectedFileFolder={setSelectedFileFolder}
            onSuccessChargeLocalFiles={onSuccessGetFileInfo}
          />
        </Stack>
        <Stack sx={{ width: "100%", alignItems: "flex-start" }}>
          {!errorFile &&
            (dataFileState || (sheets_list && sheets_list.length > 0)) && (
              <>
                <InputLabel id="demo-simple-select-filled-label">
                  Hoja
                </InputLabel>
                <Select
                  reference={sheetSelectRef}
                  styles={selectStyles(sheetSelectedOption)}
                  options={
                    (dataFileState && sheetOptions(dataFileState[0]?.hojas)) ||
                    (sheets_list && sheets_list.length > 0 ? sheets_list : [])
                  }
                  form="cc_form"
                  className="cc_select"
                  name="Columna"
                  onChange={sheetOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar hoja del archivo"
                  isClearable
                  defaultValue={selected_sheet?.value ? selected_sheet : null}
                />
                <Stack sx={{ width: "100%", alignItems: "flex-start" }}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    labelPlacement="start"
                    label="Hoja sin cabecera"
                    checked={noHeader}
                    onChange={handleChangeNoHeader}
                    sx={{
                      marginLeft: "0px !important",
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                  />
                </Stack>
              </>
            )}
        </Stack>
      </Stack>
    </StepLayout>
  );
};
export default Step1;
