import React, { MouseEventHandler } from "react";

import Select, {
  components,
  GroupBase,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  OptionsOrGroups,
  Props,
  PropsValue,
  StylesConfig,
} from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";
import { IdOption } from "./types";

interface DraggableMultiSelectProps {
  id?: string;
  selected: any,
  setSelected: any,
  options: OptionsOrGroups<IdOption, GroupBase<IdOption>> | undefined;
  className?: string;
  name?: string;
  styles?: StylesConfig<IdOption, true, GroupBase<IdOption>> | undefined;
  placeholder: string;
  defaultValue?: PropsValue<IdOption> | undefined;
  isDisabled?: boolean | undefined;
  isClearable?: boolean | undefined;
  isSearchable?: boolean | undefined;
}

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props: MultiValueProps<any>) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<any, true> & SortableContainerProps
>;

const customSelectStyles = {
  multiValue: (provided: any) => ({
    ...provided,
    cursor: "move",
    zIndex: 1500
  }),
};

export default function MultiSelectSort({
  id,
  selected,
  setSelected,
  options,
  className,
  styles,
  name,
  placeholder,
  isDisabled,
  isClearable,
  isSearchable,
}: DraggableMultiSelectProps) {

  const onChange = (selectedOptions: OnChangeValue<any, true>) =>{
    if(selectedOptions[selectedOptions.length -1]?.label === "Seleccionar todas las columnas"){
      setSelected(options)
    }else{
      setSelected(selectedOptions);
    }
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

const formatOptions = ()=> {
  if(options && options?.length > 0){
    if(options?.length === selected?.length){
      return options
    }else{
      return [{value: "todas", label:"Seleccionar todas las columnas"}, ...options as Array<any>]
    }
  }else{
    return options
  }
}
  return (
    <SortableSelect
      id={id}
      styles={{ ...styles, multiValue: customSelectStyles.multiValue }}
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={formatOptions()}
      value={selected}
      onChange={onChange}
      components={{
        //  We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue as any,
        MultiValueLabel: SortableMultiValueLabel as any,
      }}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      className={className}
      name={name}
      placeholder={placeholder}
    />
  );
}
