import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts.js";
import Select from "../../../forms/Select";
import { selectStyles } from "../../../../styles/select.styles";
import { form_label } from "../../../../styles/app-styles";
import {
  GET_COLUMNS_FLOW_URL,
  ITEM_URL,
  POST_ITEM_URL,
} from "../../../../api/axios";
import FlowsProvider from "../../../../context/FlowsContext";
import {
  filterIdFromColumns,
  findBeforeElementPosition,
  isColumnNameRepeated,
  isColumnNameValid,
  searchStepper,
} from "../../utils";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import { StepperType } from "../../../../context/types";
import { formatColumns } from "../../../fileColumns/utils";
import useDialog from "../../../../hooks/useDialog";
import BaseModal from "../BaseModal";
import ShadowBox from "../ShadowBox";
import InfoIcon from "@mui/icons-material/Info";
import FormatModal from "../FormatModal";
import ConditionalColumn from "./ConditionalColumn";

interface NewColumnProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  postItem: (url?: string, data?: any) => void;
  putItem: (url?: string, data?: any) => void;
}

const NewColumn = ({
  open,
  handleClose,
  dataItem,
  postItem,
  putItem,
}: NewColumnProps) => {
  const [name, setName] = useState<any>("");
  const [DelayHelper, setDelayHelper] = useState<boolean>(false);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [constant, setConstant] = useState<any>("");
  const [delimiter, setDelimiter] = useState<any>("");
  const [characterNumber, setCharacterNumber] = useState<any>("");
  const [initialPosition, setInitialPosition] = useState<any>("");
  const [delimiterConcat, setDelimiterConcat] = useState<any>("");
  const [conditions, setConditions] = useState<any[]>([]);
  const [conditionalOperator, setConditionalOperator] = useState("O");
  const [defaultCondition, setDefaultCondition] = useState<any>();
  const [occurrences, setOccurrences] = useState("allOccurrences");
  const [occurrencesNumber, setOccurrencesNumber] = useState("");
  const [openModal, handleToggleOpenModal] = useDialog();
  const valueTypeRef: any = useRef(null);
  const columnsToConcatSelectRef: any = useRef(null);
  const firstColumnOperationRef: any = useRef(null);
  const secondColumnOperationRef: any = useRef(null);
  const operatorRef: any = useRef(null);
  const columnToDuplicateRef: any = useRef(null);
  const columnToSplitRef: any = useRef(null);
  const columnToSplitValueRef: any = useRef(null);

  const { flowState, flowDispatch } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const { data_flow, active_stepper, itemToEdit } = flowState;

  /************************************* CARGA DE DATOS ********************************/

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 1);
      const stepItems = data_flow?.steppers?.find((step: StepperType) => {
        return step.id === active_stepper;
      }).items;
      if (stepItems.length) {
        getColumnsFont(
          GET_COLUMNS_FLOW_URL(
            data_flow.id,
            active_stepper,
            findBeforeElementPosition(
              searchStepper(active_stepper, data_flow)?.items,
              dataItem ? itemToEdit.id : undefined
            )
          )
        );
      }
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      setName(dataItem.columna.label);
      preloadValues(
        dataItem?.tipo_columna.accion?.value,
        dataItem.tipo_columna,
        dataItem?.caracteres && dataItem?.caracteres,
        dataItem["posicion-inicial"] && dataItem["posicion-inicial"]
      );
    }
  }, [dataItem]);

  const preloadValues = (
    value: string | number | undefined,
    data: any,
    caracteres?: string,
    initialPosition?: string
  ) => {
    switch (value) {
      case "constante":
        setValueTypeSelectedOption(data.accion);
        setConstant(data.valor);
        return;
      case "concat":
        setValueTypeSelectedOption(data.accion);
        setDelimiterConcat(data.delimitador);
        setColumnsToConcatSelectedOption(data.valor);
        return;
      case "operacion":
        setValueTypeSelectedOption(data.accion);
        setOperatorSelectedOption(data.operacion);
        setFirstColumnOperationSelectedOption(data.valor[0]);
        setSecondColumnOperationSelectedOption(data.valor[1]);
        return;
      case "duplicar":
        setValueTypeSelectedOption(data.accion);
        setColumnToDuplicateSelectedOption(data.valor);
        return;
      case "split":
        setValueTypeSelectedOption(data.accion);
        setColumnToSplitSelectedOption(data.valor[0]);
        setDelimiter(data.delimitador);
        data.cantidad_ocurrencias &&
          setOccurrencesNumber(data.cantidad_ocurrencias);
        data.izquierda !== undefined
          ? data.izquierda
            ? setOccurrences("leftOccurrences")
            : setOccurrences("rightOccurrences")
          : setOccurrences("allOccurrences");
        return;
      case "valores-izquierda":
      case "valores-derecha":
        setValueTypeSelectedOption(data.accion);
        caracteres && setCharacterNumber(parseInt(caracteres));
        setColumnToSplitValueSelectedOption(data.valor[0]);
        return;
      case "valores-intermedios":
        setValueTypeSelectedOption(data.accion);
        caracteres && setCharacterNumber(parseInt(caracteres));
        initialPosition && setInitialPosition(parseInt(initialPosition));
        setColumnToSplitValueSelectedOption(data.valor[0]);
        return;
      case "columna-condicional":
        setValueTypeSelectedOption(data.accion);
        setConditionalOperator(data.operador_nexo)
        setDefaultCondition(data.default);
        if(data.operador_nexo === "Y"){
          setConditions(data.condiciones.map((c: any)=>{return c.id === data.condiciones[data.condiciones.length -1].id ? {...c, salida: data.salida}: {...c, salida: {valor_comparar: undefined }}}));
        }else{
          setConditions(data.condiciones);
        }
        return;
      default:
        return null;
    }
  };

  const {
    data: dataColumnsFont,
    error: errorColumnsFont,
    isLoading: isLoadingColumnsFont,
    callApi: getColumnsFont,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      data_flow.id,
      active_stepper,
      findBeforeElementPosition(
        searchStepper(active_stepper, data_flow)?.items,
        dataItem ? itemToEdit.id : undefined
      )
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /***************************** HOOKS SELECTS ***************************************/

  const [
    valueTypeSelectedOption,
    setValueTypeSelectedOption,
    valueTypeChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.accion : undefined
  );

  const [
    columnsToConcatSelectedOption,
    setColumnsToConcatSelectedOption,
    columnsToConcatChangeHandler,
  ] = useMultiSelect(
    dataItem && open ? dataItem.tipo_columna[0]?.valor : undefined
  );

  const [
    firstColumnOperationSelectedOption,
    setFirstColumnOperationSelectedOption,
    firstColumnOperationChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.valor[0] : undefined
  );

  const [
    secondColumnOperationSelectedOption,
    setSecondColumnOperationSelectedOption,
    secondColumnOperationChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.valor[1] : undefined
  );

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.operacion : undefined
  );

  const [
    columnToDuplicateSelectedOption,
    setColumnToDuplicateSelectedOption,
    columnToDuplicateChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.valor : undefined
  );

  const [
    columnToSplitSelectedOption,
    setColumnToSplitSelectedOption,
    columnToSplitChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.valor : undefined
  );

  const [
    columnToSplitValueSelectedOption,
    setColumnToSplitValueSelectedOption,
    columnToSplitValueChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    dataItem && open ? dataItem.tipo_columna[0]?.valor : undefined
  );

  /******************************* POSTEAR ITEM *********************************************/

  const handleCreateNewColumn = () => {
    if (dataItem) {
      putItem(ITEM_URL(data_flow.id, active_stepper, itemToEdit.id), {
        tipo: "nueva_columna",
        nombre: name,
        tipo_columna: formatBody(valueTypeSelectedOption?.value),
      });
    } else {
      saveNewColumn();
    }
    cleanAndClose();
  };

  const saveNewColumn = () => {
    postItem(POST_ITEM_URL(data_flow.id, active_stepper), {
      tipo: "nueva_columna",
      nombre: name,
      tipo_columna: formatBody(valueTypeSelectedOption?.value),
    });
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setName("");
    setDelayHelper(false);
    setConstant("");
    setDelimiter("");
    setDelimiterConcat("");
    setColumnToDuplicateSelectedOption(undefined);
    setColumnToSplitSelectedOption(undefined);
    setColumnsToConcatSelectedOption(undefined);
    setValueTypeSelectedOption(undefined);
    setOperatorSelectedOption(undefined);
    setFirstColumnOperationSelectedOption(undefined);
    setSecondColumnOperationSelectedOption(undefined);
    setCharacterNumber("");
    setOccurrences("allOccurrences");
    setOccurrencesNumber("");
    setInitialPosition("");
    setConditions([]);
    setDefaultCondition(undefined);
    setColumnToSplitValueSelectedOption(undefined);
    setConditionalOperator("O");
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
    flowDispatch({
      type: "SET_ITEM_TO_EDIT",
      payload: undefined,
    });
  };

  /******************************* VALIDACIONES DEL FORM *******************************/

  const handleChangeCharacterNumber = (value: string) => {
    const validateNumber = /^([0-9])*$/;
    if (value === "" || (validateNumber.test(value) && parseInt(value) > 0)) {
      setCharacterNumber(value);
    }
  };

  const handleChangeOccurrencesNumber = (value: string) => {
    const validateNumber = /^([0-9])*$/;
    if (value === "" || (validateNumber.test(value) && parseInt(value) > 0)) {
      setOccurrencesNumber(value);
    }
  };

  const handleChangeInitialPosition = (value: string) => {
    const validateNumber = /^([0-9])*$/;
    if (value === "" || (validateNumber.test(value) && parseInt(value) > 0)) {
      setInitialPosition(value);
    }
  };

  const isFormCompleted = () => {
    if (
      isColumnNameValid(name) &&
      name.length > 0 /*
      !isColumnNameRepeated(name, dataColumnsFont) &&*/ &&
      valueTypeSelectedOption !== null &&
      valueTypeSelectedOption !== undefined
    ) {
      switch (valueTypeSelectedOption?.value) {
        case "constante":
          return constant.length > 0;
        case "concat":
          return (
            columnsToConcatSelectedOption &&
            columnsToConcatSelectedOption?.length > 0
          );
        case "operacion":
          return (
            firstColumnOperationSelectedOption !== null &&
            firstColumnOperationSelectedOption !== undefined &&
            secondColumnOperationSelectedOption !== null &&
            secondColumnOperationSelectedOption !== undefined &&
            operatorSelectedOption !== null &&
            operatorSelectedOption !== undefined
          );
        case "duplicar":
          return (
            columnToDuplicateSelectedOption !== null &&
            columnToDuplicateSelectedOption !== undefined
          );
        case "split":
          return (
            delimiter.length > 0 &&
            columnToSplitSelectedOption !== null &&
            columnToSplitSelectedOption !== undefined &&
            (occurrences !== "allOccurrences" ? occurrencesNumber : true)
          );
        case "valores-izquierda":
          return (
            characterNumber !== "" &&
            columnToSplitValueSelectedOption !== null &&
            columnToSplitValueSelectedOption !== undefined
          );
        case "valores-derecha":
          return (
            characterNumber !== "" &&
            columnToSplitValueSelectedOption !== null &&
            columnToSplitValueSelectedOption !== undefined
          );
        case "valores-intermedios":
          return (
            characterNumber !== "" &&
            initialPosition !== "" &&
            columnToSplitValueSelectedOption !== null &&
            columnToSplitValueSelectedOption !== undefined
          );
        case "columna-condicional":
        
          return (
            defaultCondition &&
            defaultCondition.valor_comparar !== undefined &&
            defaultCondition?.valor_comparar !== null &&
            defaultCondition.valor_comparar !== "" &&
            conditions?.length && (conditionalOperator === "O" ?  !conditions.some((c: any) => {
              return (
                c.columna_condicional === undefined ||
                c.columna_condicional === null ||
                c.operador === undefined ||
                c.operador === null ||
                c?.salida?.valor_comparar === undefined ||
                c?.salida?.valor_comparar === null ||
                c?.salida?.valor_comparar === "" ||
                c.valor.valor_comparar === undefined ||
                c.valor.valor_comparar === null ||
                c.valor.valor_comparar === ""
              );
            }): (!conditions.some((c: any) => {
              return (
                c.columna_condicional === undefined ||
                c.columna_condicional === null ||
                c.operador === undefined ||
                c.operador === null ||
                c.valor.valor_comparar === undefined ||
                c.valor.valor_comparar === null ||
                c.valor.valor_comparar === ""
              );
            }) && (conditions[conditions.length-1].salida?.valor_comparar !== undefined && conditions[conditions.length-1].salida?.valor_comparar !== null && conditions[conditions.length-1].salida?.valor_comparar !== "" )))
          );
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const valueOptions = [
    { value: "constante", label: "Valor fijo" },
    { value: "concat", label: "Concatenación de columnas" },
    { value: "split", label: "Split de columna" },
    { value: "operacion", label: "Operación matematica" },
    { value: "duplicar", label: "Duplicar columna" },
    { value: "valores-izquierda", label: "Valores desde la izquierda" },
    { value: "valores-derecha", label: "Valores desde la derecha" },
    { value: "valores-intermedios", label: "Valores intermedios" },
    { value: "columna-condicional", label: "Columna condicional" },
  ];

  const operatorOptions = [
    { value: "SUMAR", label: "+" },
    { value: "MULTIPLICAR", label: "x" },
    { value: "DIVIDIR", label: "/" },
    { value: "RESTAR", label: "-" },
  ];

  const formatBody = (value: string | number | undefined) => {
    switch (value) {
      case "constante":
        return {
          accion: "constante",
          valor: [constant],
        };
      case "concat":
        return {
          accion: "concat",
          delimitador: delimiterConcat,
          valor: columnsToConcatSelectedOption?.map((column: any) => {
            return column.label
          }),
        };
      case "operacion":
        return {
          accion: "operacion",
          valor: [
            firstColumnOperationSelectedOption?.label,
            secondColumnOperationSelectedOption?.label,
          ],
          operacion: operatorSelectedOption?.value,
        };
      case "duplicar":
        return {
          accion: "duplicar",
          valor: [columnToDuplicateSelectedOption?.label],
        };
      case "split":
        return {
          accion: "split",
          delimitador: delimiter,
          valor: [columnToSplitSelectedOption?.label],
          izquierda:
            occurrences === "allOccurrences"
              ? undefined
              : occurrences === "leftOccurrences",
          cantidad_ocurrencias:
            occurrences === "allOccurrences" ? undefined : occurrencesNumber,
        };
      case "valores-izquierda":
        return {
          accion: "valores-izquierda",
          valor: [columnToSplitValueSelectedOption?.label],
          caracteres: characterNumber,
        };
      case "valores-derecha":
        return {
          accion: "valores-derecha",
          valor: [columnToSplitValueSelectedOption?.label],
          caracteres: characterNumber,
        };
      case "valores-intermedios":
        return {
          accion: "valores-intermedios",
          valor: [columnToSplitValueSelectedOption?.label],
          caracteres: characterNumber,
          "posicion-inicial": initialPosition,
        };
      case "columna-condicional":
        return {
          accion: "columna-condicional",
          condiciones: conditions.map((condition: any) => {
            return {
              columna_condicional: condition.columna_condicional.label,
              operador: condition.operador.value,
              valor: {
                tipo: condition.valor.tipo,
                valor_comparar:
                  typeof condition.valor.valor_comparar === "string"
                    ? condition.valor.valor_comparar
                    : condition.valor.valor_comparar.label,
              },
              salida:
                conditionalOperator === "O"
                  ? {
                      tipo: condition.salida.tipo,
                      valor_comparar:
                        typeof condition.salida.valor_comparar === "string"
                          ? condition.salida.valor_comparar
                          : condition.salida.valor_comparar.label,
                    }
                  : {},
            };
          }),
          operador_nexo: conditionalOperator,
          salida:
            conditionalOperator === "O"
              ? undefined
              : {
                  tipo: conditions[conditions.length -1].salida.tipo,
                  valor_comparar:
                    typeof conditions[conditions.length -1].salida.valor_comparar === "string"
                      ? conditions[conditions.length -1].salida.valor_comparar
                      : conditions[conditions.length -1].salida.valor_comparar.label,
                },
          default: {
            tipo: defaultCondition.tipo,
            valor_comparar:
              typeof defaultCondition.valor_comparar === "string"
                ? defaultCondition.valor_comparar
                : defaultCondition.valor_comparar.label,
          },
        };
      default:
        return null;
    }
  };

  return (
    <BaseModal
      open={open}
      title="Agregar nueva columa"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleCreateNewColumn}
      confirmationModalMessage={
        dataItem
          ? `¿Confirma que desea editar la configuración de la nueva columna? ${
              isColumnNameRepeated(name, dataColumnsFont)
                ? "Utilizar el nombre de una columna existente causará que esta sea reemplazada."
                : ""
            }`
          : `¿Confirma que desea agregar la columna? ${
              isColumnNameRepeated(name, dataColumnsFont)
                ? "Utilizar el nombre de una columna existente causará que esta sea reemplazada."
                : ""
            }`
      }
      customMessage
      disabledAcceptButton={!isFormCompleted()}
      width="870px"
      hasResultsTable
    >
      <Stack gap="15px">
        <ShadowBox>
        <Typography
              color={"var(--blue)"}
              mb="10px"
              sx={{ fontSize: "1rem" }}
            >
              Utilizar el nombre de una columna existente ocasionará que esta sea reemplazada
            </Typography>
          <TextField
            id="columnName"
            label={"Nombre de la columna"}
            placeholder={"Nombre de la columna"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              isColumnNameValid(name) || name === ""
                ? /*isColumnNameRepeated(name, dataColumnsFont)
                  ? texts.flows.newColumn.helperText.repeatedName
                  : */ ""
                : texts.flows.newColumn.helperText.invalidName
            }
            error={
              !isColumnNameValid(name) || name === "" /*||
              isColumnNameRepeated(name, dataColumnsFont)*/
            }
            fullWidth
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>
        <Stack
          sx={{
            width: "100%",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        ></Stack>
        <ShadowBox>
          {DelayHelper && (
            <Box sx={{ width: "100%", marginBottom: "8px" }}>
              <InputLabel sx={form_label}>Valores</InputLabel>
              <Select
                reference={valueTypeRef}
                styles={selectStyles(valueTypeSelectedOption)}
                options={valueOptions}
                name="Elegir valores"
                onChange={valueTypeChangeHandler}
                closeMenuOnSelect
                placeholder="Selecciona valores"
                isClearable
                isDisabled={false}
                defaultValue={valueTypeSelectedOption}
              />
            </Box>
          )}
        </ShadowBox>
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "constante" && (
            <ShadowBox>
              <TextField
                id="constant"
                label={"Valor fijo"}
                placeholder={"Valor fijo"}
                size="small"
                multiline
                variant="outlined"
                helperText={
                  constant.length > 40
                    ? "El valor de la constante no puede tener más de 40 caracteres"
                    : ""
                }
                error={constant.length > 40 || constant === ""}
                fullWidth
                value={constant}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setConstant(event.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </ShadowBox>
          )}
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "concat" && (
            <>
              <ShadowBox>
                <Box sx={{ width: "100%", marginBottom: "0px" }}>
                  <InputLabel sx={form_label}>
                    Selecciona las columnas a concatenar
                  </InputLabel>
                  <Select
                    isMulti
                    reference={columnsToConcatSelectRef}
                    styles={selectStyles(columnsToConcatSelectedOption)}
                    options={formatColumns(
                      filterIdFromColumns(dataColumnsFont)
                    )}
                    name="Columnas a concatenar"
                    onChange={columnsToConcatChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas a concatenar"
                    isClearable
                    isDisabled={false}
                    defaultValue={columnsToConcatSelectedOption}
                  />
                </Box>
              </ShadowBox>
              <ShadowBox>
                <TextField
                  id="delimiterConcat"
                  label={"Delimitador"}
                  placeholder={"Delimitador"}
                  size="small"
                  multiline
                  variant="outlined"
                  helperText={
                    delimiterConcat.length > 15
                      ? "El valor del delimitador no puede tener más de 15 caracteres"
                      : ""
                  }
                  error={delimiterConcat.length > 15 || delimiterConcat === ""}
                  fullWidth
                  value={delimiterConcat}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDelimiterConcat(event.target.value);
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                    },
                  }}
                  required
                />
              </ShadowBox>
            </>
          )}
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "operacion" && (
            <Box
              sx={{
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                padding: "18px",
                borderRadius: "10px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ width: "35%", marginBottom: "0px" }}>
                <InputLabel sx={form_label}>
                  Selecciona la primera columna
                </InputLabel>
                <Select
                  reference={firstColumnOperationRef}
                  styles={selectStyles(firstColumnOperationSelectedOption)}
                  options={formatColumns(filterIdFromColumns(dataColumnsFont))}
                  name="Primera columna"
                  onChange={firstColumnOperationChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  isClearable
                  defaultValue={firstColumnOperationSelectedOption}
                />
              </Box>
              <Box sx={{ width: "18%", marginBottom: "0px" }}>
                <InputLabel sx={form_label}>Operación</InputLabel>
                <Select
                  reference={operatorRef}
                  styles={selectStyles(operatorSelectedOption)}
                  options={operatorOptions}
                  name="Operación"
                  onChange={operatorChangeHandler}
                  closeMenuOnSelect
                  placeholder="Operación"
                  isClearable
                  defaultValue={operatorSelectedOption}
                />
              </Box>
              <Box sx={{ width: "35%", marginBottom: "0px" }}>
                <InputLabel sx={form_label}>
                  Selecciona la segunda columna
                </InputLabel>
                <Select
                  reference={secondColumnOperationRef}
                  styles={selectStyles(secondColumnOperationSelectedOption)}
                  options={formatColumns(filterIdFromColumns(dataColumnsFont))}
                  name="Segunda columna"
                  onChange={secondColumnOperationChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  isClearable
                  defaultValue={secondColumnOperationSelectedOption}
                />
              </Box>
            </Box>
          )}
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "duplicar" && (
            <ShadowBox>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <InputLabel sx={form_label}>
                  Selecciona la columna a duplicar
                </InputLabel>
                <Select
                  reference={columnToDuplicateRef}
                  styles={selectStyles(columnToDuplicateSelectedOption)}
                  options={formatColumns(filterIdFromColumns(dataColumnsFont))}
                  name="Columna para duplicar"
                  onChange={columnToDuplicateChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  isClearable
                  defaultValue={columnToDuplicateSelectedOption}
                />
              </Box>
            </ShadowBox>
          )}
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "split" && (
            <Box
              sx={{
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                padding: "18px",
                borderRadius: "10px",
                marginBottom: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-end",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "flex-end",
                }}
              >
                <Box sx={{ width: "60%", marginBottom: "0px" }}>
                  <InputLabel sx={form_label}>
                    Selecciona la columna para realizar el split
                  </InputLabel>
                  <Select
                    reference={columnToSplitRef}
                    styles={selectStyles(columnToSplitSelectedOption)}
                    options={formatColumns(
                      filterIdFromColumns(dataColumnsFont)
                    )}
                    name="Columna para split"
                    onChange={columnToSplitChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar columna"
                    isClearable
                    defaultValue={columnToSplitSelectedOption}
                  />
                </Box>
                <Box
                  sx={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    id="delimiter"
                    label={"Delimitador"}
                    placeholder={"Delimitador"}
                    size="small"
                    multiline
                    variant="outlined"
                    helperText={
                      delimiter.length > 15
                        ? "El valor del delimitador no puede tener más de 15 caracteres"
                        : ""
                    }
                    error={delimiter.length > 15 || delimiter === ""}
                    fullWidth
                    value={delimiter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDelimiter(event.target.value);
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "99%",
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "10px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label="Aplicar en todas las ocurrencias"
                    value={"allOccurrences"}
                    sx={{
                      color: "var(--blue)",
                    }}
                    checked={occurrences === "allOccurrences"}
                    onChange={(e: any) => {
                      setOccurrences(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label="Ocurrencias desde la izquierda"
                    value={"leftOccurrences"}
                    sx={{
                      color: "var(--blue)",
                    }}
                    checked={occurrences === "leftOccurrences"}
                    onChange={(e: any) => {
                      setOccurrences(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label="Ocurrencias desde la derecha"
                    value={"rightOccurrences"}
                    sx={{
                      color: "var(--blue)",
                    }}
                    checked={occurrences === "rightOccurrences"}
                    onChange={(e: any) => {
                      setOccurrences(e.target.value);
                    }}
                  />
                </RadioGroup>
              </Box>
              {(occurrences === "leftOccurrences" ||
                occurrences === "rightOccurrences") && (
                <Box
                  sx={{
                    width: "90%",
                    marginTop: "5px",
                    marginRight: "auto",
                    marginLeft: "10px",
                  }}
                >
                  <TextField
                    id="caracteres"
                    label={"Número de ocurrencias"}
                    placeholder={"Número de ocurrencias"}
                    size="small"
                    multiline
                    variant="outlined"
                    type="number"
                    error={occurrencesNumber === ""}
                    fullWidth
                    value={occurrencesNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeOccurrencesNumber(event.target.value);
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Box>
              )}
            </Box>
          )}
        {valueTypeSelectedOption &&
          (valueTypeSelectedOption.value === "valores-izquierda" ||
            valueTypeSelectedOption.value === "valores-derecha") && (
            <Box
              sx={{
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                padding: "18px",
                borderRadius: "10px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ width: "60%", marginBottom: "0px" }}>
                <Box sx={{ display: "flex" }}>
                  {" "}
                  <InputLabel sx={form_label}>
                    Selecciona la columna para conservar el valor
                  </InputLabel>
                  <InfoIcon
                    onClick={handleToggleOpenModal}
                    sx={{
                      color: "var(--blue)",
                      fontSize: "20px",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  />
                </Box>
                <Select
                  reference={columnToSplitValueRef}
                  styles={selectStyles(columnToSplitValueSelectedOption)}
                  options={formatColumns(filterIdFromColumns(dataColumnsFont))}
                  name="Columna para split"
                  onChange={columnToSplitValueChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  isClearable
                  defaultValue={columnToSplitValueSelectedOption}
                />
              </Box>
              <Box
                sx={{
                  width: "35%",
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="caracteres"
                  label={"Número de cáracteres"}
                  placeholder={"Número de cáracteres"}
                  size="small"
                  multiline
                  variant="outlined"
                  type="number"
                  error={characterNumber === ""}
                  fullWidth
                  value={characterNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeCharacterNumber(event.target.value);
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                    },
                  }}
                  required
                />
              </Box>
            </Box>
          )}
        {valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "valores-intermedios" && (
            <Box
              sx={{
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                padding: "18px",
                borderRadius: "10px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Box sx={{ display: "flex" }}>
                  <InputLabel sx={form_label}>
                    Selecciona la columna para conservar el valor
                  </InputLabel>

                  <InfoIcon
                    onClick={handleToggleOpenModal}
                    sx={{
                      color: "var(--blue)",
                      fontSize: "20px",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  />
                </Box>
                <Select
                  reference={columnToSplitValueRef}
                  styles={selectStyles(columnToSplitValueSelectedOption)}
                  options={formatColumns(filterIdFromColumns(dataColumnsFont))}
                  name="Columna para split"
                  onChange={columnToSplitValueChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna"
                  isClearable
                  defaultValue={columnToSplitValueSelectedOption}
                />
              </Box>
              <Box sx={{ width: "100%", display: "flex", marginTop: "15px" }}>
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    marginRight: "20px",
                  }}
                >
                  <TextField
                    id="initialPosition"
                    label={"Posición inicial (inclusive)"}
                    placeholder={"Posición inicial (inclusive)"}
                    size="small"
                    multiline
                    variant="outlined"
                    type="number"
                    error={initialPosition === ""}
                    fullWidth
                    value={initialPosition}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeInitialPosition(event.target.value);
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Box>
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    id="caracteres"
                    label={"Posición final"}
                    placeholder={"Posición final"}
                    type="number"
                    size="small"
                    multiline
                    variant="outlined"
                    error={characterNumber === ""}
                    fullWidth
                    value={characterNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeCharacterNumber(event.target.value);
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Box>
              </Box>
            </Box>
          )}
        {DelayHelper &&
          valueTypeSelectedOption &&
          valueTypeSelectedOption.value === "columna-condicional" && (
            <Box
              sx={{
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                padding: "18px",
                borderRadius: "10px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <ConditionalColumn
                columns={formatColumns(filterIdFromColumns(dataColumnsFont))}
                itemConditions={conditions.length ? conditions : undefined}
                setConditions={setConditions}
                dataItem={dataItem}
                defaultCondition={defaultCondition}
                setDefaultCondition={setDefaultCondition}
                operatorType={conditionalOperator}
                setOperator={setConditionalOperator}
              />
            </Box>
          )}
        <FormatModal open={openModal} handleClose={handleToggleOpenModal} />
      </Stack>
    </BaseModal>
  );
};

export default NewColumn;
