import Stack from "@mui/material/Stack";
import React, { useRef, useState } from "react";
import { sheetOptions } from "../pipelines/utils";
import {
  Avatar,
  Box,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { FILE_BY_ID, FILE_INFO_URL } from "../../api/axios";
import { texts } from "../../texts.js";
import Select from "../forms/Select";
import { form_label } from "../../styles/app-styles";
import { selectStyles } from "../../styles/select.styles";
import useSingleSelect from "../../hooks/useSingleSelect";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import FolderIcon from "@mui/icons-material/Folder";
import UploadFileContent from "../dataManagement/UploadFileModal/UploadFileContent";
import OriginalFont from "../dataManagement/OriginalFont";

interface ReplaceFontInputProps {
  label: string;
  setFont: (fontName: string, archivo: any) => void;
  setSheet?: (sheet: string | number | undefined, fontName: string) => void;
  setDataFont?: (fontName: string, dataFont: any) => void;
  idStepper?: number;
  error?: boolean;
  isAddFont?: any;
}

const ReplaceFontInput = ({
  label,
  setFont,
  setSheet,
  setDataFont,
  error,
  isAddFont,
}: ReplaceFontInputProps) => {
  const [replaceFile, setReplaceFile] = useState<boolean>(false);
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [selectedFileFolder, setSelectedFileFolder] = useState<string>("");
  const [fileToReplaceWith, setFileToReplaceWith] = useState<any>(undefined);


  const sheetSelectRef: any = useRef(null);

  const handleChangeSheet = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    sheetOptionsChangeHandler(newValue, actionMeta);
    setSheet && setSheet(newValue?.value, label);
  };

  const [
    sheetSelectedOption,
    setSheetSelectedOption,
    sheetOptionsChangeHandler,
  ] = useSingleSelect();

  const onSuccessGetFileInfo = (data: any) => {
    setDataFileState([data]);
    if (data?.hojas?.length && setSheet) {
      setSheet(undefined, label);
    }
    setFont(label, data);
    setDataFont && setDataFont(label, data);
    replaceFile && setFileToReplaceWith(data)
  };

  const onErrorGetFileInfo = () => {
    setDataFileState([]);
    setFileToReplaceWith(undefined)
  };

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFile(fileState);
    setSheet && setSheet(undefined, label);
    setSheetSelectedOption(undefined);
    sheetSelectRef?.current?.clearValue();
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            getFilesInfo(undefined, {
              path_archivo:
                clickedFile === "chooseFile"
                  ? selectedFile
                  : `${selectedFileFolder}${clickedFile.nombre}`,
              directorio: false,
              tipo: "azure",
              actualizar_archivo: true
            });
          } else {
            getFilesInfoById(FILE_BY_ID(clickedFile.id));
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const {
    data: dataFile,
    isLoading: isLoadingFileInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    undefined,
    undefined
  );

  return (
    <Stack alignItems="center" width="100%">
      <SimpleBackdrop
        open={isLoadingFileInfo || isLoadingFileInfoById}
        message={texts.adminPipeline.sendFileUrl.loading}
      />
      <OriginalFont
        fileName={label}
        fileError={error}
        setReplaceFile={() => {
          setReplaceFile(true);
        }}
        label="Archivo original"
      />
      {fileToReplaceWith && (
        <OriginalFont
          label={"Nuevo archivo"}
          fileName={fileToReplaceWith.nombre}
        />
      )}
      {replaceFile && (
        <UploadFileContent
          areMultipleFiles={false}
          selectedFile={selectedFile}
          setSelectedFile={handleSelectFile}
          dataFileState={dataFileState}
          setDataFileState={setDataFileState}
          setSelectedFileFolder={setSelectedFileFolder}
          onSuccessChargeLocalFiles={onSuccessGetFileInfo}
          customWidth="600px"
        />
      )}
      <Stack
        sx={{
          marginBottom: "50px",
          marginTop: "0px",
          width: "100%",
          marginRight: "auto",
        }}
      >
        {dataFileState && dataFileState[0]?.hojas?.length > 0 && !isAddFont && (
          <>
            <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
              Hoja
            </InputLabel>
            <Select
              reference={sheetSelectRef}
              styles={selectStyles(sheetSelectedOption)}
              options={sheetOptions(dataFileState[0]?.hojas)}
              form="cc_form"
              className="cc_select"
              name="Hoja"
              onChange={handleChangeSheet}
              closeMenuOnSelect
              placeholder="Seleccionar hoja del archivo"
              isDisabled={isLoadingFileInfo}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ReplaceFontInput;
