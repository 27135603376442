import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteIconProps } from "./types";

const Delete = ({ tooltipPlacement, onClick, ref1, ref2 }: DeleteIconProps) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title="Eliminar fila"
      placement={tooltipPlacement}
    >
      <DeleteIcon
        onClick={() => onClick(ref1, ref2)}
        sx={{
          color: "#9A999E",
          padding: "0",
          margin: "0",
          "& :hover": { color: "#F90D4A", cursor: "pointer" },
          textAlign: "center"
        }}
      />
    </Tooltip>
  );
};

export default Delete;
