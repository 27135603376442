export const texts = {
  verificationCode: {
    codes: {
      200: "El código es correcto",
      400: "El código es incorrecto",
    },
    loading: "Enviando el código de autenticación",
    resend: {
      codes: {
        200: "El código ha sido reenviado a su correo",
        201: "El código ha sido reenviado a su correo",
        400: "Hubo un error y no fue posible reenviar el código, intentelo nuevamente",
      },
    },
  },
  adminPipeline: {
    sendFileUrl: {
      loading: "Cargando archivo...",
      loadingFolder: "Cargando archivos...",
      codes: {
        200: "La url se ha cargado con éxito",
        201: "La url se ha cargado con éxito",
        400: "Se produjo un error y no fue posible cargar el archivo",
        401: "No fue posible obtener el archivo. Es posible que el archivo ya no exista o que haya surgido un error interno.",
        500: "No fue posible obtener el archivo. Es posible que el archivo ya no exista o que haya surgido un error interno.",
      },
    },
    getFileUrl: {
      loading: "Cargando archivo...",
      codes: {
        200: "El archivo se ha cargado con éxito",
        201: "El archivo se ha cargado con éxito",
        400: "Se produjo un error y no fue posible cargar el archivo",
      },
    },
    sendPipeline: {
      loading: "Guardando...",
      codes: {
        200: "El pipeline se ha guardado con éxito",
        201: "El pipeline se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar el pipeline",
      },
    },
    getPipelines: {
      loading: "Obteniendo pipelines...",
      codes: {
        200: "Se obtuvieron los pipelines con éxito",
        400: "Se produjo un error y no fue posible obtener los pipelines",
      },
      error: "Se produjo un error y no fue posible obtener los pipelines",
    },
    getPipeline: {
      loading: "Obteniendo pipeline...",
      codes: {
        200: "Se obtuvo el pipeline con éxito",
        400: "Se produjo un error y no fue posible obtener el pipeline",
      },
      error: "Se produjo un error y no fue posible obtener el pipeline",
    },
    deletePipeline: {
      loading: "Eliminando pipeline...",
      codes: {
        200: "El pipeline se ha eliminado con éxito",
        400: "El criterio tiene esquemas asociados",
      },
    },
  },
  adminSchemas: {
    addSchemaRow: {
      errorTodasUsed:
        "No se pueden agregar más filas, ya que seleccionaron todas las hojas",
      /* "No se puede agrega la fila con 'Todas' las hojas ya que hay hojas siendo usadas.", */
      errorTodasExcluir:
        "No se puede usar todas las hojas para el tipo 'Excluir'",
      errorTodasFirst:
        /* "No se pueden agregar más filas, ya que seleccionaron todas las hojas", */
        "No se puede agregar la fila con la opción 'Todas' las hojas, ya que hay hojas siendo usadas.",
      errorPipelineEmpty: "Completar el campo 'Pipeline'",
      errorFilterTypeEmpty: "Completar el campo 'Tipo de filtro'",
      deletedSheets:
        "Una o más hojas utilizadas han sido eliminadas. Es necesario reemplazarlas.",
    },
    sendSchema: {
      loading: "Guardando esquema...",
      codes: {
        200: "El esquema se ha guardado con éxito",
        201: "El esquema se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar el esquema",
      },
    },
    exportSchema: {
      loading: "Exportando esquema...",
      error: "Se produjo un error y no fue posible exportar el esquema",
      codes: {
        201: "El esquema fue exportado con éxito",
      },
    },
    getSchemas: {
      loading: "Obteniendo esquemas...",
      codes: {
        200: "Se obtuvieron los esquemas con éxito",
        400: "Se produjo un error y no fue posible obtener los esquemas",
      },
      error: "Se produjo un error y no fue posible obtener los esquemas",
    },
    getSchema: {
      loading: "Obteniendo esquema...",
      codes: {
        200: "Se obtuvo el esquema con éxito",
        400: "Se produjo un error y no fue posible obtener el esquema",
      },
      error: "Se produjo un error y no fue posible obtener el esquema",
    },
    deleteSchema: {
      loading: "Eliminando esquema...",
      codes: {
        200: "El esquema se ha eliminado con éxito",
        204: "El esquema se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el esquema",
      },
    },
  },
  dataManagement: {
    getResources: {
      loading: "Cargando recursos...",
      codes: {
        400: "Se produjo un error y no fue posible obtener los recursos",
      },
      error: "Se produjo un error y no fue posible obtener los recursos",
    },
    getLabels: {
      loading: "Cargando etiquetas...",
      error: "Se produjo un error y no fue posible obtener las etiquetas",
    },
    postLabel: {
      loading: "Guardando etiqueta...",
      codes: {
        200: "La etiqueta se ha creado con éxito",
        201: "La etiqueta se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la etiqueta",
      },
    },
    deleteLabel: {
      loading: "Eliminando etiqueta...",
      codes: {
        200: "La etiqueta se ha eliminado con éxito",
        204: "La etiqueta se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la etiqueta",
      },
    },
    putLabel: {
      loading: "Guardando etiqueta...",
      codes: {
        200: "La etiqueta se ha editado con éxito",
        201: "La etiqueta se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar la etiqueta",
      },
    },
    postFolder: {
      loading: "Guardando carpeta...",
      codes: {
        200: "La carpeta se ha creado con éxito",
        201: "La carpeta se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la carpeta",
      },
    },
    deleteResource: {
      loading: "Eliminando recurso...",
      codes: {
        200: "El recurso ha sido eliminado con éxito",
        400: "El recurso está siendo utilizado en otra funcionalidad y no está permitido eliminarlo",
      },
    },
    editResource: {
      loading: "Editando recurso...",
      codes: {
        200: "El recurso ha sido editado con éxito",
        400: "Se produjo un error y no fue posible editar el recurso",
      },
    },
    downloadResource: {
      loading: "Descargando recurso...",
      codes: {
        200: "El recurso ha sido descargado con éxito",
        400: "Se produjo un error y no fue posible descargar el recurso",
      },
    },
    downloadResources: {
      loading: "Descargando recursos...",
      codes: {
        200: "Los recursos han sido descargado con éxito",
        400: "Se produjo un error y no fue posible descargar los recursos",
      },
    },
    moveResources: {
      loading: "Moviendo recursos...",
      codes: {
        200: "Los recursos se han trasladado con éxito",
        400: "Se produjo un error y no fue posible trasladar los recursos",
      },
    },
    getConnections: {
      loading: "Cargando conexiones...",
      error: "Se produjo un error y no fue posible obtener las conexiones",
    },
    postConnection: {
      loading: "Guardando conexión...",
      codes: {
        200: "La conexión se ha guardado con éxito",
        201: "La conexión se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar la conexión",
      },
    },
    deleteConnection: {
      loading: "Eliminando conexión...",
      codes: {
        200: "La conexión se ha eliminado con éxito",
        204: "La conexión se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la conexión",
      },
    },
    loadConnectionTable: {
      loading: "Cargando tablas...",
      codes: {
        200: "Las tablas se han cargado con éxito",
        400: "Se produjo un error y no fue posible cargar las tablas",
      },
    },
    postConnectionResource: {
      loading: "Subiendo tabla...",
      codes: {
        200: "La tabla se ha guardado con éxito",
        201: "La tabla se ha guardado con éxito",
      //  400: "Se produjo un error y no fue posible guardar la tabla",
      },
    },
    postAllowedFileName:{
      loading: "Guardando nombre...",
      codes: {
        200: "El nombre de archivo permitido se ha guardado con éxito",
        201:"El nombre de archivo permitido se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar el nombre de archivo permitido",
      },
    },
    getAllowedFileNames: {
      loading: "Cargando nombres de archivos permitidos...",
      codes: {
        200: "Nombres de archivos permitidos obtenidos con éxito",
        400: "Se produjo un error y no fue posible obtener los nombres de archivos permitidos",
      },
      error: "Se produjo un error y no fue posible obtener los nombres de archivos permitidos",
    },
    getExternalConnectionColumns: {
      loading: "Cargando columnas...",
      error: "Se produjo un error y no fue posible obtener las columnas del recurso",
    },
    getExternalConnectionConfigurations: {
      loading: "Cargando configuraciones...",
   //   error: "Se produjo un error y no fue posible obtener las configuraciones",
      codes: {
        200: "La configuración se han cargado con éxito",
        400: "Se produjo un error y no fue posible obtener las configuraciones",
      },
    },
    postExternalConnectionConfiguration:{
      loading: "Guardando configuración de ingesta...",
      codes: {
        200: "La configuración se ha guardado con éxito",
        201:"La configuración se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar la configuración",
      },
    },
    deleteExternalConnectionConfiguration:{
      loading: "Eliminando configuración...",
      codes: {
        200: "La configuración se ha eliminado con éxito",
        204:"La configuración se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la configuración",
      },
    },
  },
  executeSchemas: {
    loading: "Ejecutando esquema...",
    codes: {
      200: "Se ha creado exitosamente la tarea de ejecución del esquema",
      201: "Se ha creado exitosamente la tarea de ejecución del esquema",
      400: "Se produjo un error y no fue posible crear la tarea para ejecutar el esquema",
    },
  },
  summary: {
    sendSummary: {
      loading: "Creando resumen...",
      codes: {
        200: "El resumen se ha creado con éxito",
        201: "El resumen se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el resumen",
      },
    },
    getSummaries: {
      loading: "Cargando resumenes",
      error: "Se produjo un error y no fue posible obtener los resumenes",
    },
    getSummary: {
      loading: "Cargando resumen",
      codes: {
        200: "Se obtuvo el resumen con éxito",
        400: "Se produjo un error y no fue posible obtener el resumen",
      },
      error: "Se produjo un error y no fue posible obtener el resumen",
    },
    deleteSummary: {
      loading: "Eliminando resumen...",
      codes: {
        200: "El resumen se ha eliminado con éxito",
        204: "El resumen se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el resumen",
        500: "Se produjo un error y no fue posible eliminar el resumen",
      },
    },
  },
  taskManager: {
    getTasks: {
      error: "Se produjo un error y no fue posible obtener las tareas",
    },
    cancelTask: {
      loading: "Cancelando tarea...",
      codes: {
        200: "La tarea se ha cancelado con éxito",
        201: "La tarea se ha cancelado con éxito",
        204: "La tarea se ha cancelado con éxito",
        400: "Se produjo un error y no fue posible cancelar la tarea",
        500: "Se produjo un error y no fue posible cancelar la tarea",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Se obtuvieron los detalles de la tarea",
        400: "Se produjo un error y no fue posible obtener los detalles de la tarea",
      },
      loading: "Obteniendo detalles de la tarea...",
    },
  },
  codeValidation: {
    postGenerateReport: {
      loading: "Enviando reporte...",
      codes: {
        200: "El cálculo del reporte se está generando con éxito",
        201: "El cálculo del reporte se está generando con éxito",
        400: "Se produjo un error y no fue posible iniciar el cálculo",
        500: "Se produjo un error y no fue posible iniciar el cálculo",
      },
    },
    deleteValidation: {
      loading: "Eliminando validación...",
      codes: {
        200: "La validación se ha eliminado con éxito",
        204: "La validación se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la validación",
        500: "Se produjo un error y no fue posible eliminar la validación",
      },
    },
    getValidations: {
      error: "Se produjo un error y no fue posible obtener las validaciones.",
    },
    getValidation: {
      loading: "Cargando validación",
      codes: {
        200: "Se obtuvo la validación con éxito",
        400: "Se produjo un error y no fue posible obtener la validación",
      },
      error: "Se produjo un error y no fue posible obtener la validación",
    },
  },
  flows: {
    concat: {
      helperText: {
        repeatedName: "Ya existe un paso con este nombre",
        invalidName:
          "El nombre sólo puede contener letras, números, guiones, puntos, espacios y una longitud de hasta 50 caracteres",
      },
    },
    newColumn: {
      helperText: {
        repeatedName: "Ya existe una columna con este nombre",
        invalidName:
          "El nombre no puede contener comas y sólo una longitud de hasta 40 carácteres",
      },
    },
    deleteRows: {
      loading: "Eliminando filas...",
      codes: {
        200: "Las filas se han eliminado con éxito",
        201: "Las filas se están eliminando con éxito",
        400: "Se produjo un error y no fue posible eliminar las filas",
        500: "Se produjo un error y no fue posible eliminar las filas",
      },
    },
    /* modifyColumn: {
      loading: "Modificando la columna...",
      codes: {
        200: "La columna se ha modificado con éxito",
        201: "La columna se está modificando con éxito",
        400: "Se produjo un error y no fue posible modificar la columna",
        500: "Se produjo un error y no fue posible modificar la columna",
      },
    }, */
    postFlowName: {
      loading: "Guardando...",
      codes: {
        200: "El flujo se ha guardado con éxito",
        201: "El flujo se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar el flujo",
      },
    },
    postStepper: {
      loading: "Guardando...",
      codes: {
        200: "El paso se ha guardado con éxito",
        201: "El paso se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar el paso",
      },
    },
    putStepper: {
      loading: "Guardando...",
      codes: {
        200: "El nombre se ha editado con éxito",
        201: "El nombre se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar el nombre de la fuente",
      },
    },
    getFlowColumns: {
      loading: "Obteniendo datos...",
      codes: {
        200: "Se obtuvieron las columnas con éxito",
        400: "Se produjo un error y no fue posible obtener las columnas",
      },
    },
    postItem: {
      loading: "Guardando...",
      codes: {
        200: "El item se ha guardado con éxito",
        201: "El item se ha guardado con éxito",
        400: "Se produjo un error y no fue posible agregar el item",
      },
    },
    getFlow: {
      loading: "Obteniendo flujo y verificando existencia de fuentes...",
      error: "Se produjo un error y no fue posible obtener el flujo",
      codes: {
        200: "Se obtuvo el flujo con éxito",
        400: "Se produjo un error y no fue posible obtener el flujo",
      },
    },
    getFlowConfigs: {
      loading: "Cargando configuraciones..",
      error: "Se produjo un error y no fue posible cargar las configuraciones",
      codes: {
        200: "Se cargaron las configuraciones con éxito",
        400: "Se produjo un error y no fue posible cargar las configuraciones",
      },
    },
    postFlowConfigs: {
      loading: "Guardando..",
      error: "Se produjo un error y no fue posible guardar la configuración",
      codes: {
        200: "La configuración se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar la configuración",
      },
    },
    deleteFlowConfigs: {
      loading: "Eliminando configuración...",
      codes: {
        200: "La configuración se ha eliminado con éxito",
        204: "La configuración se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la configuración",
      },
    },
    exportFlow: {
      loading: "Exportando flujo...",
      error: "Se produjo un error y no fue posible exportar el flujo",
      codes: {
        201: "El flujo fue exportado con éxito",
      },
    },
    deleteItem: {
      loading: "Eliminando item...",
      codes: {
        200: "El item se ha eliminado con éxito",
        204: "El item se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el item",
      },
      confirmation:
        "¿Está seguro de que quiere eliminar este item? Si elimina este item, puede afectar a los items posteriores, lo que podría invalidar las transformaciones.",
    },
    deleteStepper: {
      loading: "Eliminando stepper...",
      codes: {
        200: "El stepper se ha eliminado con éxito",
        204: "El stepper se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el stepper",
      },
      confirmation:
        "¿Está seguro de que quiere eliminar este paso? Si elimina este paso, puede afectar a los pasos posteriores, lo que podría invalidar las transformaciones.",
    },
    duplicateStepper: {
      loading: "Duplicando stepper...",
      codes: {
        200: "El stepper se ha duplicado con éxito",
        201: "El stepper se ha duplicado con éxito",
        400: "Se produjo un error y no fue posible duplicar el stepper",
      },
    },
    viewResults: {
      loading: "Obteniendo resultados...",
      codes: {
        200: "Se obtuvieron los resultados con éxito",
        400: "Se produjo un error y no fue posible obtener los resultados",
      },
    },
    getFilterData: {
      loading: "Obteniendo datos...",
      codes: {
        200: "Se obtuvieron los datos con éxito",
        400: "Se produjo un error y no fue posible obtener los datos",
      },
    },
    getFlows: {
      loading: "Obteniendo flujos...",
      codes: {
        200: "Se obtuvieron los flujos con éxito",
        400: "Se produjo un error y no fue posible obtener los flujos",
      },
      error: "Se produjo un error y no fue posible obtener los flujos",
    },
    deleteFlow: {
      loading: "Eliminando flujo...",
      codes: {
        200: "El flujo se ha eliminado con éxito",
        204: "El flujo se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el flujo",
      },
    },
    calcFlow: {
      loading: "Calculando...",
      codes: {
        200: "El cálculo se ha iniciado con éxito",
        201: "El cálculo se ha iniciado con éxito",
        400: "Se produjo un error y no fue posible iniciar el cálculo",
      },
    },
    postFlowToCopy: {
      loading: "Copiando...",
      codes: {
        200: "El flujo se ha copiado con éxito",
        201: "El flujo se ha copiado con éxito",
        400: "Se produjo un error y no fue posible copiar el flujo",
      },
    },
    changeFonts: {
      loading: "Cambiando fuentes...",
      loadingFonts: "Cargando fuentes...",
      codes: {
        200: "Las fuentes se han cambiado con éxito",
        201: "Las fuentes se han cambiado con éxito",
        400: "Se produjo un error y no fue posible cambiar las fuentes",
      },
    },
    recalculateFonts: {
      loading: "Recalculando fuentes...",
      codes: {
        200: "Las fuentes se han calculado con éxito",
        201: "Las fuentes se han calculado con éxito",
        400: "Se produjo un error y no fue posible recalcular las fuentes",
      },
    },
    executeFlow: {
      loading: "Ejecutando flow...",
      codes: {
        200: "Se ha creado exitosamente la tarea de ejecución del flow",
        201: "Se ha creado exitosamente la tarea de ejecución del flow",
        400: "Se produjo un error y no fue posible crear la tarea para ejecutar el flow",
      },
    },
  },
};
